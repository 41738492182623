import {Layout} from "../common/Layout";
import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {adminPpPetApi, adminPpUserApi} from "../../api/Api";
import {AssetsControllerUploadImageResponse, PpPetControllerCreateRequest, TypesPpPetData, TypesPpUserData} from "../../generated";
import {PpPetForm} from "./PpPetForm";
import {PpPetConfirm} from "./PpPetConfirm";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {H1, H3} from "../common/Headline";
import {LoginInfo} from "../pp_user/LoginInfo";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {PpUserDetailMessageType} from "../pp_user/PpUserDetail";

export const PpPetEdit = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();
    const [ppUser, setPpUser] = useState<TypesPpUserData | null>(null);
    const [ppPet, setPpPet] = useState<TypesPpPetData | null>(null);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [step, setStep] = useState<number>(1);
    const [petReq, setPetReq] = useState<PpPetControllerCreateRequest | null>(null);
    const [petIconRes, setPetIconRes] = useState<AssetsControllerUploadImageResponse | null>(null);
    const [hasTest, setHasTest] = useState<boolean>(true);

    useEffect(() => {

        const id = params.id;
        const ppUserId = params.ppUserId;

        if (!ppUserId) {
            setDangerMessage("パラメータが正しくありません。");
            navigate("/pp_user");
            return;
        }

        getMember(ppUserId);

        if (id) {
            // 編集時、ペット情報を取得する
            getPet(ppUserId, id);
            setIsEdit(true);
        } else {
            // 新規登録時は種別を変更可能にする
            setHasTest(false);
        }

    }, [params]);

    // 会員情報の取得
    const getMember = (memberId: string) => {

        setShowSpinner(true);

        adminPpUserApi.v1AdminPpUserGetIdGet(memberId)
            .then(({data}) => {
                setPpUser(data.pp_user ?? null);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/pp_user");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    // 編集時、ペット情報を取得
    const getPet = (ppUserId: string, id: string) => {

        setShowSpinner(true);

        adminPpPetApi.v1AdminPpPetGetPpUserIdIdGet(ppUserId, id)
            .then(({data}) => {
                setPpPet(data.pp_pet ?? null);
                checkHasTest(id);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/pp_user");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    // 申し込み完了したテストがあるか
    const checkHasTest = (id: string) => {

        setShowSpinner(true);

        adminPpPetApi.v1AdminPpPetHasTestPpPetIdGet(parseInt(id))
            .then(({data}) => {
                setHasTest(data.has_test ?? null);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });

    }

    if (!ppUser) {
        // 会員が取得できるまではダミー表示
        return <Layout title={"会員検索"}/>;
    }

    const onBack = () => {
        navigate(`/pp_user/detail/${ppUser?.id}`);
    }

    // 入力画面に戻る
    const onStepBack = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setStep(1);

        if (ppPet && petReq) {

            // 修正したリクエストから入力内容を復元
            const next = ppPet;

            next.pet_name = petReq.pet_name ?? "";
            next.animal_type = petReq.animal_type ?? "";
            next.breed_id = petReq.breed_id ?? 0;
            next.breed_of_parents_1 = petReq.breed_of_parents_1 ?? null;
            next.breed_of_parents_2 = petReq.breed_of_parents_2 ?? null;
            next.accuracy_of_pet_birthday = petReq.accuracy_of_pet_birthday ?? "0";
            next.birthday = petReq.birthday ?? null;
            next.pet_sex = petReq.pet_sex ?? "";

            if (petIconRes) {
                next.icon = petIconRes.path;
                next.icon_name = petIconRes.original_filename;
                next.img_url = petIconRes.view_path;
            }

            setPpPet(next);
        }


        window.scrollTo(0, 0);
    };

    // バリデーション成功時のコールバック
    const afterValidate = (req: PpPetControllerCreateRequest, imgRes: AssetsControllerUploadImageResponse | null) => {

        setPetReq(req);
        setPetIconRes(imgRes);

        if (!imgRes && ppPet?.icon && req?.icon) {
            // 画像がアップロードされていないくても、以前の画像あれば設定する。
            // ただし、リクエストから画像が消えている場合は復元しない
            const tmp: AssetsControllerUploadImageResponse = {
                view_path: ppPet.img_url ?? "",
                path: ppPet.icon ?? "",
                original_filename: ppPet.icon_name ?? "",
            };
            setPetIconRes(tmp);
        }

        setStep(2);
        window.scrollTo(0, 0);
    }

    // 保存処理
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        setShowSpinner(true);

        if (!petReq) {
            return;
        }

        petReq.pp_user_id = ppUser.id;
        petReq.is_only_validate = false;

        let promise;

        if (isEdit) {
            // ------------- 編集時
            promise = adminPpPetApi.v1AdminPpPetEditIdPost(String(ppPet?.id), petReq);
        } else {
            // ------------- 新規登録
            promise = adminPpPetApi.v1AdminPpPetCreatePost(petReq);
        }

        promise
            .then(({data}) => {
                // セッションストレージから削除
                sessionStorage.removeItem("pet_create_info")
                sessionStorage.removeItem("pet_img_info")

                if (isEdit) {
                    // 編集時は会員詳細に戻す
                    navigate(`/pp_user/detail/${ppUser?.id}?message_type=${PpUserDetailMessageType.PetEditComplete}`);
                } else {
                    // 新規登録時は完了画面へ
                    navigate(`/pp_pet/create-complete/${ppUser?.id}/${data.pp_pet?.id}`);
                }

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onDeletePetImg = () => {
        const next = {...ppPet} as TypesPpPetData;
        next.icon = null;
        next.icon_name = null;
        next.img_url = null;
        setPpPet(next);
        setPetIconRes(null);
    }

    if (isEdit && !ppPet) {
        // 編集時、ペット情報が取得できない間は処理しない
        return <Layout title={"会員検索"}/>;
    }

    return <Layout title={"会員検索"}>

        <H1 title={isEdit ? "ペット情報の変更" : "ペット情報の新規登録"} subtitle={isEdit ? "edit" : "registration"}/>

        <StyledForm>
            <LoginInfo email={ppUser.email ?? ""} showReq={false}/>
        </StyledForm>

        {step === 1 && <PpPetForm
            onBack={onBack}
            afterValidate={afterValidate}
            ppPet={ppPet}
            backBtnText={"キャンセル"}
            h3Text="ペット情報"
            isEdit={isEdit}
            onDeletePetImg={onDeletePetImg}
            hasTest={hasTest}
        />}

        {step === 2 && petReq && <StyledPetsEdit>

            <div className="box">
                <H3 title="ペット情報" subtitle="pet"/>
                <StyledForm onSubmit={onSubmit}>
                    <PpPetConfirm
                        ppPet={petReq}
                        petIcon={petIconRes}
                    />
                    <div className="btn-area">
                        <Button color={ButtonColor.Gray} icon={ButtonIcon.Arrow} type="button" onClick={onStepBack}>前の画面に戻る</Button>
                        <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>{isEdit ? "修正を保存する" : "登録する"}</Button>
                    </div>
                </StyledForm>
            </div>
        </StyledPetsEdit>}


    </Layout>

};

const StyledPetsEdit = styled.div`
`;

