import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {H1, H3} from "../common/Headline";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {ErrorMessage} from "../common/ErrorMessage";
import {GetDateList, GetMonthList, GetYearList} from "../../utility/Utility";
import {PpPetForm} from "../pp_pet/PpPetForm";
import {AssetsControllerUploadImageResponse, PpPetControllerCreateRequest, PpUserControllerCreateRequest} from "../../generated";
import {PpUserConfirm} from "./PpUserConfirm";
import {useNavigate} from "react-router-dom";
import {LoginInfo} from "./LoginInfo";
import {adminPpPetApi, adminPpUserApi} from "../../api/Api";
import {AddressSet} from "../common/AddressSet";
import {RegistrationSteps} from "./RegistrationSteps";
import {MainColor} from "../common/Colors";
import rightWhite from "../../images/right-white.svg";

export const PpUserCreate = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [userFamilyName, setUserFamilyName] = useState<string>("");
    const [userFirstName, setUserFirstName] = useState<string>("");
    const [userFamilyFurigana, setUserFamilyFurigana] = useState<string>("");
    const [userFirstFurigana, setUserFirstFurigana] = useState<string>("");
    const [birthYear, setBirthYear] = useState<string>("");
    const [birthMonth, setBirthMonth] = useState<string>("");
    const [birthDate, setBirthDate] = useState<string>("");
    const [birthday, setBirthday] = useState<string>("");
    const [gender, setGender] = useState<string>("");
    const [postalCode1, setPostalCode1] = useState<string>("");
    const [postalCode2, setPostalCode2] = useState<string>("");
    const [postalCode, setPostalCode] = useState<string>("");
    const [prefCode, setPrefCode] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [street, setStreet] = useState<string>("");
    const [building, setBuilding] = useState<string>("");
    const [telNo, setTelNo] = useState<string>("");
    const [userPolicyConsent, setUserPolicyConsent] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [step, setStep] = useState<number>(1);
    const [ppUserCreateReq, setPpUserCreateReq] = useState<PpUserControllerCreateRequest | null>(null);
    const [ppPetCreateReq, setPpPetCreateReq] = useState<PpPetControllerCreateRequest | null>(null);
    const [petIconRes, setPetIconRes] = useState<AssetsControllerUploadImageResponse | null>(null);
    const navigate = useNavigate();

    useEffect(() => {

        // 読み込み時、sessionStorageに以前のデータがあったら復旧
        const oldData = sessionStorage.getItem("member_create_info");

        if (oldData) {
            const j = JSON.parse(oldData) as PpUserControllerCreateRequest;

            setUserFirstName(j.user_first_name ?? "");
            setUserFamilyName(j.user_family_name ?? "");
            setUserFirstFurigana(j.user_first_furigana ?? "");
            setUserFamilyFurigana(j.user_family_furigana ?? "");
            setEmail(j.email ?? "");
            setPassword(j.password ?? "");
            setBirthday(j.birthday ?? "");
            setGender(j.gender ?? "");
            setPostalCode(j.postal_code ?? "");
            setPrefCode(j.pref_code ?? "");
            setCity(j.city ?? "");
            setStreet(j.street ?? "");
            setBuilding(j.building ?? "");
            setTelNo(j.tel_no ?? "");
            setUserPolicyConsent(j.user_policy_consent ?? false);

            if (j.birthday) {
                const parts = j.birthday.split("-");
                if (parts.length === 3) {
                    setBirthYear(parts[0]);
                    setBirthMonth(parts[1]);
                    setBirthDate(parts[2]);
                }
            }

            if (j.postal_code) {
                const parts = j.postal_code.split("-");
                if (parts.length === 2) {
                    setPostalCode1(parts[0]);
                    setPostalCode2(parts[1]);
                }
            }
        }

    }, []);

    // 会員情報の送信
    const onSubmitMember = (e: React.FormEvent<HTMLFormElement>, isOnlyValidate: boolean) => {

        e.preventDefault();

        setShowSpinner(true);

        // 新規登録時
        const req: PpUserControllerCreateRequest = {
            email: email,
            password: password,
            user_family_name: userFamilyName,
            user_first_name: userFirstName,
            user_family_furigana: userFamilyFurigana,
            user_first_furigana: userFirstFurigana,
            birthday: birthday,
            gender: gender,
            postal_code: postalCode,
            pref_code: prefCode,
            city: city,
            street: street,
            building: building,
            tel_no: telNo,
            user_policy_consent: userPolicyConsent,
            is_only_validate: isOnlyValidate,
        };

        adminPpUserApi.v1AdminPpUserCreatePost(req)
            .then(() => {
                setErrors({});

                // バリデーションエラーがなければ、次のステップへ
                setStep(2);
                window.scrollTo(0, 0);

                // セッションに登録する
                sessionStorage.setItem("member_create_info", JSON.stringify(req))

                // リクエストを保持しておく
                setPpUserCreateReq(req)

            })
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setDangerMessage("ご入力内容にエラーがありました。");
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    // ペット情報のバリデーション完了後コールバック
    const afterValidatePet = (req: PpPetControllerCreateRequest, imgRes: AssetsControllerUploadImageResponse | null) => {

        // 次のステップへ
        setStep(3);
        window.scrollTo(0, 0);

        // リクエストを保持しておく
        setPpPetCreateReq(req);
        setPetIconRes(imgRes);
    }

    const onDeletePetImg = () => {
        setPetIconRes(null);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {

        switch (e.currentTarget.name) {
            case "email":
                setEmail(e.currentTarget.value);
                break;
            case "password":
                setPassword(e.currentTarget.value);
                break;
            case "user_family_name":
                setUserFamilyName(e.currentTarget.value);
                break;
            case "user_first_name":
                setUserFirstName(e.currentTarget.value);
                break;
            case "user_family_furigana":
                setUserFamilyFurigana(e.currentTarget.value);
                break;
            case "user_first_furigana":
                setUserFirstFurigana(e.currentTarget.value);
                break;
            case "birth_year":
                setBirthYear(e.currentTarget.value);
                setBirthday(`${e.currentTarget.value}-${birthMonth}-${birthDate}`);
                break;
            case "birth_month":
                setBirthMonth(e.currentTarget.value);
                setBirthday(`${birthYear}-${e.currentTarget.value}-${birthDate}`);
                break;
            case "birth_date":
                setBirthDate(e.currentTarget.value);
                setBirthday(`${birthYear}-${birthMonth}-${e.currentTarget.value}`);
                break;
            case "gender":
                setGender(e.currentTarget.value);
                break;
            case "postal_code1":
                setPostalCode1(e.currentTarget.value);
                setPostalCode(`${e.currentTarget.value}-${postalCode2}`);
                break;
            case "postal_code2":
                setPostalCode2(e.currentTarget.value);
                setPostalCode(`${postalCode1}-${e.currentTarget.value}`);
                break;
            case "pref_code":
                setPrefCode(e.currentTarget.value);
                break;
            case "city":
                setCity(e.currentTarget.value);
                break;
            case "street":
                setStreet(e.currentTarget.value);
                break;
            case "building":
                setBuilding(e.currentTarget.value);
                break;
            case "tel_no":
                setTelNo(e.currentTarget.value);
                break;
        }
    };

    // チェックボックスのo切り替えハンドラー
    const onCheck = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.currentTarget.name) {
            case "user_policy_consent":
                setUserPolicyConsent(e.currentTarget.checked);
                break;
        }
    }

    // 確認画面から各設定ページへ戻る
    const onBackConfirm = (e: React.MouseEvent<HTMLButtonElement>, step: number) => {
        e.preventDefault();
        setStep(step);
        window.scrollTo(0, 0);
    }

    // 確認画面でのデータ送信（=登録処理）
    const onSubmitConfirm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!ppUserCreateReq || !ppPetCreateReq) {
            return;
        }

        // バリデーションのみフラグをoffにする
        ppUserCreateReq.is_only_validate = false;
        ppPetCreateReq.is_only_validate = false;

        setShowSpinner(true);

        adminPpUserApi.v1AdminPpUserCreatePost(ppUserCreateReq)
            .then(({data}) => {

                // 会員IDを付与してリクエスト送信
                ppPetCreateReq.pp_user_id = data.pp_user?.id;
                return adminPpPetApi.v1AdminPpPetCreatePost(ppPetCreateReq);
            })
            .then(({data}) => {

                // キャッシュを削除
                sessionStorage.removeItem("member_create_info");
                sessionStorage.removeItem("pet_create_info");
                sessionStorage.removeItem("pet_img_info");

                // 完了画面に遷移
                navigate(`/pp_user/create-complete/${data.pp_pet?.pp_user_id}`);

            })
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setDangerMessage("ご入力内容にエラーがありました。");
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onBackStep1 = () => {
        setStep(1);
        window.scrollTo(0, 0);
    };

    if (step === 2) {
        // ---------------------------------------- step2 / ペット情報の登録
        return <Layout title={"会員情報登録"}>

            <H1 title="会員情報の新規登録" subtitle="registration"/>

            <RegistrationSteps offset={1}/>

            <StyledMemberCreate>
                <LoginInfo email={email} showReq={true}/>
            </StyledMemberCreate>

            <PpPetForm
                onBack={onBackStep1}
                afterValidate={afterValidatePet}
                ppPet={null}
                backBtnText={"飼い主情報の登録に戻る"}
                h3Text={"ペット情報"}
                isEdit={false}
                onDeletePetImg={onDeletePetImg}
                hasTest={false}
            />

        </Layout>
    }

    if (step === 3) {
        // ---------------------------------------- step3 / 入力内容の確認
        return <PpUserConfirm
            ppUser={ppUserCreateReq}
            ppPet={ppPetCreateReq}
            petIcon={petIconRes}
            onBack={onBackConfirm}
            onSubmit={onSubmitConfirm}
        />
    }

    // ---------------------------------------- step1 / 初期画面
    return <Layout title={"会員情報登録"}>

        <H1 title="会員情報の新規登録" subtitle="registration"/>

        <RegistrationSteps offset={0}/>

        <StyledMemberCreate onSubmit={(e) => onSubmitMember(e, true)}>

            <div className="box">

                <H3 title="ログイン情報" subtitle="login"/>

                <div className="form-wrapper">

                    <div className="form-group email-area">
                        <label className="req">ログインID</label>
                        <div className="form-object">
                            <input type="email" name="email" maxLength={255} value={email} className="medium" onChange={onChange} placeholder={"例：example@mail.jp"}/>
                            <div className="hint">※半角英数字。</div>
                            <ErrorMessage message={errors["email"]}/>
                        </div>
                        <div className="hint-balloon">
                            <span className="red">* ログインIDはメールアドレスをご入力ください。</span><br/>
                            * ご登録はフリーメール（GmailやYahooメールなど）をお勧めしております。<br/>
                            * 携帯キャリア発行のメールアドレスは、迷惑メールとして振り分けられる可能性があるためご注意ください。<br/>
                            * <span className="red">@pitpet.jp</span>からのメールが受信できるよう、受信設定をご確認ください。<br/>
                        </div>
                    </div>

                    <div className="form-group password-area">
                        <label className="req">パスワード</label>
                        <div className="form-object">
                            <input type="password" name={"password"} maxLength={50} value={password} onChange={onChange}/>
                            <div className="hint">※半角英数字。</div>
                            <ErrorMessage message={errors["password"]}/>
                        </div>
                        <div className="hint-balloon">
                            * パスワードは<span className="red">半角英数字8文字以上</span>（大文字英字A〜Z、小文字英字a〜z、数字0〜9をそれぞれ1文字以上使用してください）。<br/>
                            * パスワードは必ず<span className="red">メモやスクリーンショット</span>などでお控えのうえ、ご自身で管理をお願いいたします。<br/>
                        </div>
                    </div>

                </div>

            </div>

            <div className="box">

                <H3 title="オーナー情報" subtitle="owner"/>

                <div className="form-wrapper">

                    <div className="form-group">
                        <label className="req">お名前</label>
                        <div>
                            <div className="inline">
                                <span>姓</span>
                                <input type="text" name="user_family_name" maxLength={40} value={userFamilyName} onChange={onChange} placeholder={"例：プラウメッド"}/>
                                <span>名</span>
                                <input type="text" name="user_first_name" maxLength={40} value={userFirstName} onChange={onChange} placeholder={"例：太郎"}/>
                            </div>
                            <ErrorMessage message={errors["user_family_name"]}/>
                            <ErrorMessage message={errors["user_first_name"]} left={320}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">お名前（フリガナ）</label>
                        <div>
                            <div className="inline">
                                <span>セイ</span>
                                <input type="text" name="user_family_furigana" maxLength={60} value={userFamilyFurigana} onChange={onChange} placeholder={"例：プラウメッド"}/>
                                <span>メイ</span>
                                <input type="text" name="user_first_furigana" maxLength={60} value={userFirstFurigana} onChange={onChange} placeholder={"例：タロウ"}/>
                            </div>
                            <ErrorMessage message={errors["user_family_furigana"]}/>
                            <ErrorMessage message={errors["user_first_furigana"]} left={320}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">生年月日</label>
                        <div>
                            <div className="inline">
                                <select name="birth_year" value={birthYear} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetYearList(false).map((d, i) => {
                                        return <option key={i} value={d}>{d}</option>
                                    })}
                                </select>
                                <span>年</span>
                                <select name="birth_month" value={birthMonth} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetMonthList().map((d, i) => {
                                        return <option key={i} value={d}>{d}</option>
                                    })}                            </select>
                                <span>月</span>
                                <select name="birth_date" value={birthDate} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetDateList().map((d, i) => {
                                        return <option key={i} value={d}>{d}</option>
                                    })}
                                </select>
                                <span>日</span>

                            </div>
                            <ErrorMessage message={errors["birthday"]}/>
                        </div>
                    </div>

                    <div className="form-group check-area">
                        <label className="req">性別</label>
                        <div>
                            <div className="check">
                                <label>
                                    <input type="radio" name="gender" value="1" onChange={onChange} checked={gender === "1"}/>
                                    男性
                                </label>
                                <label>
                                    <input type="radio" name="gender" value="2" onChange={onChange} checked={gender === "2"}/>
                                    女性
                                </label>
                                <label>
                                    <input type="radio" name="gender" value="3" onChange={onChange} checked={gender === "3"}/>
                                    その他
                                </label>
                            </div>
                            <ErrorMessage message={errors["gender"]}/>
                        </div>
                    </div>

                    <AddressSet
                        postalCode={postalCode}
                        postalCode1={postalCode1}
                        postalCode2={postalCode2}
                        prefCode={prefCode}
                        city={city}
                        street={street}
                        building={building}
                        onChange={onChange}
                        isRequired={true}
                        errors={errors}
                        setPrefCode={setPrefCode}
                        setCity={setCity}
                        setStreet={setStreet}
                        setBuilding={setBuilding}/>


                    <div className="form-group" style={{marginBottom: "60px"}}>
                        <label className="req">電話番号</label>
                        <div>
                            <input type="tel" name="tel_no" maxLength={12} value={telNo} onChange={onChange} placeholder={"例：0311112222"}/>
                            <div className="hint">※半角数字。ハイフンの入力は不要。</div>
                            <ErrorMessage message={errors["tel_no"]}/>
                        </div>
                    </div>

                </div>

            </div>

            <div className="form-group check-area accept-area">
                <label className="req">利用規約の確認</label>
                <div>
                    <div className="check">
                        <label>
                            <input type="checkbox" name="user_policy_consent" value="1" onChange={onCheck} checked={userPolicyConsent}/>
                            同意する
                        </label>
                        <a href={`${process.env.REACT_APP_FRONTEND_URL}/assets/document/terms_20240301.pdf`} download="terms.pdf" className="btn-terms">利用規約を開く</a>
                    </div>
                    <ErrorMessage message={errors["user_policy_consent"]}/>
                </div>
            </div>

            <div className="btn-area">
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>ペット情報の登録へ</Button>
            </div>

        </StyledMemberCreate>


    </Layout>

};

export const StyledMemberCreate = styled(StyledForm)`

  // メール、パスワードエリア
  .email-area, .password-area {
    justify-content: flex-start;

    .form-object {
      width: 350px !important;
      margin-right: 30px;
      flex: 0 0 auto;

      input {
        width: 100% !important;
      }
    }

    .hint-balloon {
      position: relative;
      top: -10px;
      width: 430px;
      flex: 0 0 auto;
    }
  }

  // メールエリア
  .password-area {
    .hint-balloon {
      top: -10px;
      width: 495px;
      flex: 0 0 auto;
    }
  }

  // 同意エリアだけ短い
  .accept-area {
    background-color: rgb(240, 232, 217);
    width: 500px;
  }

  // 確認画面テキスト
  .confirmation-note {
    line-height: 62px;
    margin: 0 -40px 30px -40px;
    color: #fff;
    padding: 0 64px;
    background-color: rgb(3, 171, 144);
  }

  // 利用規約ボタン
  .btn-terms {
    font-size: 12px;
    height: 24px;
    width: auto;
    padding: 0 30px 0 20px;
    margin: 0;
    cursor: pointer;
    display: inline-block;
    letter-spacing: 1px;
    border-radius: 24px;
    background-color: ${MainColor};
    color: #fff;
    text-decoration: none;
    line-height: 24px;
    background-position: top 50% right 15px;
    background-size: 5px auto;
    background-repeat: no-repeat;
    background-image: url(${rightWhite});
  }

`;
