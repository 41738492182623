import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import {Counter} from "../common/Counter";
import {Links} from "../common/Links";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {adminPpMstSkuApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";
import {AccesstokenRole, TypesPpMstSkuData} from "../../generated";
import {Table} from "../common/Table";
import {Paginator} from "../common/Paginator";
import {Zerofill} from "../../utility/Utility";
import styled from "styled-components";
import {MainColor} from "../common/Colors";
import rightWhite from "../../images/right-white.svg";
import {CompleteMessage} from "../common/CompleteMessage";

export const PpMstSkuIndex = () => {

    const {setShowSpinner, setDangerMessage, setCompleteMessage, role} = useContext(AppContext);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [ppMstSkus, setPpMstSkus] = useState<TypesPpMstSkuData[]>([]);
    const navigate = useNavigate();
    const location = useLocation();

    const size = 200;

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const messageType = params.get("message_type") ?? "";
        const skuCode = params.get("sku_code") ?? "";

        if (messageType === "create_complete") {
            setCompleteMessage(`SKUコード：${skuCode}を登録いたしました。`);
        }

        if (messageType === "edit_complete") {
            setCompleteMessage(`SKUコード：${skuCode}を変更いたしました。`);
        }

    }, []);

    useEffect(() => {
        getSkus();
    }, [page]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get("page")) {
            setPage(Number(params.get("page")));
        }
    }, [location.search]);

    const getSkus = () => {

        setShowSpinner(true);

        adminPpMstSkuApi.v1AdminPpMstSkuGet("", page, size)
            .then(({data}) => {
                setPpMstSkus(data.pp_mst_skus);
                setTotal(data.total ?? 0);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onDelete = (e: React.MouseEvent<HTMLSpanElement>, ppMstSku: TypesPpMstSkuData) => {

        e.preventDefault();

        if (!window.confirm(`${ppMstSku.sku_code} を削除します。よろしいですか？`)) {
            return;
        }

        setShowSpinner(true);
        adminPpMstSkuApi.v1AdminPpMstSkuDeleteIdDelete(String(ppMstSku.id))
            .then(() => {
                setCompleteMessage(`SKUコード：${ppMstSku.sku_code}を削除いたしました。`);
                getSkus();
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onPageChange = (page: number) => {
        setPage(page);
        navigate(`/pp_mst_sku?page=${page}`);
    }

    return <Layout title="SKUコード管理">

        <CompleteMessage/>

        <StyledPpMstSkuIndex className="box">

            <H3 title="SKUコード一覧" subtitle="list" counter={<Counter total={total} size={size} current={page}/>}>
                {role === AccesstokenRole.RoleMaster && <Links>
                    <NavLink to={"./create"}>新規追加</NavLink>
                </Links>}
            </H3>

            <Table>
                <thead>
                <tr>
                    <th>No.</th>
                    <th>SKUコード</th>
                    <th>商品付帯有無</th>
                    <th>商品リコメンド<br />オプション有無</th>
                    <th>説明</th>
                    {role === AccesstokenRole.RoleMaster && <>
                        <th></th>
                        <th></th>
                    </>}
                </tr>
                </thead>

                <tbody>

                {ppMstSkus.map((d, i) => {

                    return <tr key={`sku-${i}`}>
                        <td>{Zerofill(i + 1, 2)}</td>
                        <td>{d.sku_code}</td>
                        <td>{d.food_included ? "1" : "0"}：{d.food_included ? "付帯あり" : "付帯なし"}</td>
                        <td>{d.rcmnd_availability}：{d.rcmnd_availability === "1" ? "あり" : "なし"}</td>
                        <td>{d.description}</td>
                        {role === AccesstokenRole.RoleMaster && <>
                            <td><NavLink to={`/pp_mst_sku/edit/${d.id}`} className="btn-link">編集</NavLink></td>
                            <td><span onClick={(e) => onDelete(e, d)} className="btn-del">削除</span></td>
                        </>}
                    </tr>
                })}

                </tbody>

            </Table>

            <Paginator onClick={onPageChange} pages={Math.ceil(total / size)} page={page}/>

        </StyledPpMstSkuIndex>

    </Layout>
}


const StyledPpMstSkuIndex = styled.div`

  tr {
    td:nth-child(6), td:nth-child(7) {
      text-align: center !important;
      width: 120px;
    }
  }

  .btn-link, .btn-del {
    background-color: ${MainColor};
    color: #fff;
    font-size: 12px !important;
    line-height: 24px;
    display: inline-block;
    text-decoration: none !important;
    padding: 0 30px 0 20px;
    letter-spacing: 1px;
    border-radius: 24px;
    background-image: url(${rightWhite});
    background-repeat: no-repeat;
    background-position: top 50% right 15px;
    background-size: 5px auto;
  }

  .btn-del {
    background-color: #ccc;
  }

  .modal-inner {
    background-color: #fff;

    .confirmation {
      background-color: #fff;
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #efefef;
      padding: 20px 0;
      align-items: flex-end;
      margin: 0 20px;

      span {
        margin-right: 23px;
      }

      strong {
        font-size: 40px;
        color: #FF9871;
        font-weight: 400;
        line-height: 1;
      }
    }

    p {
      padding-top: 20px !important;

      span {
        background: linear-gradient(transparent 50%, rgba(217, 62, 76, 0.2) 20%);

        strong {
          background: none !important;
        }
      }
    }
  }

`;
