import {Layout} from "../common/Layout";
import {SearchForm} from "./SearchForm";
import {H3} from "../common/Headline";
import {useState} from "react";
import {TypesPpTestKitData} from "../../generated";
import {Table} from "../common/Table";
import {Zerofill} from "../../utility/Utility";
import styled from "styled-components";

export const ForcedTestIndex = () => {

    const [ppTestKits, setPpTestKits] = useState<TypesPpTestKitData[]>([]);
    const sessionKey = "forced_session_key";

    // see: https://docs.google.com/spreadsheets/d/13QiutXdbPDBauMAyPIqSJnMxwbogJDl4sS4zr28fN7w/edit#gid=1030842939
    const maxNum = 1; // 一度の受付は1件のみ

    return <Layout title="強制検査受付">

        <SearchForm
            sessionKey={sessionKey}
            maxNum={maxNum}
            setPpTestKits={setPpTestKits}
        />

        <StyledReceptionIndex className="box">

            <H3 title="検体情報一覧" subtitle="list"/>

            <Table>

                <thead>
                <tr>
                    <th>No.</th>
                    <th>検体ID</th>
                    <th>Flow Cell ID</th>
                    <th>検体位置</th>
                    <th>Labバーコード</th>
                    <th>検査申込有無</th>
                    <th>ステータス</th>
                </tr>
                </thead>

                <tbody>
                {ppTestKits.map((d, i) => {

                    return <tr key={`pp-test-kit-${d.sample_id}`}>
                        <td>{Zerofill(i + 1, 2)}</td>
                        <td>{d.sample_id}</td>
                        <td>{d.flowcell_id}</td>
                        <td>{d.position}</td>
                        <td>{d.lab_barcode_id}</td>
                        <td>{d.is_confirmed ? "あり" : "なし"}</td>
                        <td>強制検査受付</td>
                    </tr>


                })}
                </tbody>

            </Table>

        </StyledReceptionIndex>

    </Layout>

};


const StyledReceptionIndex = styled.div`

  table {
    tbody {
      tr.not-yet {
        td:nth-child(5) {
          background-color: rgba(217, 62, 76, 0.1);
          color: #D93E4C;
        }
      }

      tr.completed {
        td {
          background-color: #E5E5E5 !important;
          color: #828282;
        }
      }
    }
  }

`;