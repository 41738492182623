import styled from "styled-components";
import React from "react";

interface Props {
    message: string | undefined;
    left?: number;
    top?: number;
    wrap?: boolean;
}

export const ErrorMessage = ({message, left, top, wrap}: Props) => {

    if (message === undefined) {
        return null;
    }

    const style: React.CSSProperties = {};

    if (left) {
        style.left = `${left}px`;
    }

    if (top) {
        style.top = `${top}px`;
    }

    if (wrap) {
        style.whiteSpace = "normal";
    }

    return <React.Fragment>
        <div/>
        <StyledErrorMessage style={style}>
            {message}
        </StyledErrorMessage>
    </React.Fragment>

};

const StyledErrorMessage = styled.div`
  background-color: #D93E4C;
  color: #fff;
  padding: 3px 10px;
  width: auto;
  display: inline-block;
  margin: 5px;
  position: absolute;
  top: -40px;
  border-radius: 4px;
  font-size: 13px;
  line-height: 1.7;
  white-space: nowrap;

  &::before {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 20px;
    margin-left: -15px;
    border: 8px solid transparent;
    border-top: 8px solid #D93E4C;
  }

`;