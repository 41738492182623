import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {H1, H3} from "../common/Headline";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {AxiosResponse} from "axios";
import {ErrorMessage} from "../common/ErrorMessage";
import {adminPpMstRcmndProductApi} from "../../api/Api";
import {PpMstRcmndProductControllerCreateRequest, PpmstrcmndproductRecommendType, PpmstrcmndproductstitleDisplayLocation, TypesPpMstRcmndProductData} from "../../generated";
import {GetIntestinalEnvironmentTypes, GetRcmndTitleDisplayLocationStr, GetRecommendTypes} from "../../utility/Utility";
import {ImageForm} from "./partial/ImageForm";
import {ImageModal} from "./partial/ImageModal";
import {useRcmndProductTitle} from "../../hooks/useRcmndProductTitle";

export const PpMstRcmndProductEdit = () => {

    const {setShowSpinner, setDangerMessage, setCompleteMessage} = useContext(AppContext);
    const [ppMatRcmndProduct, setPpMstRcmndProduct] = useState<TypesPpMstRcmndProductData | null>(null)
    const [rcmndProductsTitleId, setRcmndProductsTitleId] = useState<string>("");
    const [recommendType, setRecommendType] = useState<string>("");
    const [intestinalEnvironmentType, setIntestinalEnvironmentType] = useState<string>("");
    const [priority, setPriority] = useState<string>("");
    const [productCode, setProductCode] = useState<string>("");
    const [productType, setProductType] = useState<string>("");
    const [productName, setProductName] = useState<string>("");
    const [productDescription, setProductDescription] = useState<string>("");
    const [productImage01, setProductImage01] = useState<string>("");
    const [productImage02, setProductImage02] = useState<string>("");
    const [productImage03, setProductImage03] = useState<string>("");
    const [productImage04, setProductImage04] = useState<string>("");
    const [productImage05, setProductImage05] = useState<string>("");
    const [productImage06, setProductImage06] = useState<string>("");
    const [productImage07, setProductImage07] = useState<string>("");
    const [productImage08, setProductImage08] = useState<string>("");
    const [productImage09, setProductImage09] = useState<string>("");
    const [productImage10, setProductImage10] = useState<string>("");
    const [ecsiteLink1, setEcsiteLink1] = useState<string>("");
    const [ecsiteLink2, setEcsiteLink2] = useState<string>("");
    const params = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isModalImg, setIsModalImg] = React.useState<boolean>(false);
    const [viewPath, setViewPath] = React.useState<string>("");
    const [modalTitle, setModalTitle] = React.useState<string>("");
    const rcmndProductsTitles = useRcmndProductTitle();

    useEffect(() => {

        if (params.id) {
            // IDが指定されている場合はデータを取得する
            getRcmndProduct(params.id);
        }

    }, [params]);

    const getRcmndProduct = (id: string) => {

        setShowSpinner(true);

        adminPpMstRcmndProductApi
            .v1AdminPpMstRcmndProductGetIdGet(id)
            .then(({data}) => {
                setPpMstRcmndProduct(data.pp_mst_rcmnd_product);

                setRcmndProductsTitleId(String(data.pp_mst_rcmnd_product.rcmnd_products_tittle_id));
                setRecommendType(data.pp_mst_rcmnd_product.recommend_type);
                setIntestinalEnvironmentType(data.pp_mst_rcmnd_product.intestinal_environment_type);
                setPriority(String(data.pp_mst_rcmnd_product.priority));
                setProductType(data.pp_mst_rcmnd_product.product_type ?? "");
                setProductCode(data.pp_mst_rcmnd_product.product_code);
                setProductName(data.pp_mst_rcmnd_product.product_name ?? "");
                setProductDescription(data.pp_mst_rcmnd_product.product_description);
                setProductImage01(data.pp_mst_rcmnd_product.product_image_01);
                setProductImage02(data.pp_mst_rcmnd_product.product_image_02 ?? "");
                setProductImage03(data.pp_mst_rcmnd_product.product_image_03 ?? "");
                setProductImage04(data.pp_mst_rcmnd_product.product_image_04 ?? "");
                setProductImage05(data.pp_mst_rcmnd_product.product_image_05 ?? "");
                setProductImage06(data.pp_mst_rcmnd_product.product_image_06 ?? "");
                setProductImage07(data.pp_mst_rcmnd_product.product_image_07 ?? "");
                setProductImage08(data.pp_mst_rcmnd_product.product_image_08 ?? "");
                setProductImage09(data.pp_mst_rcmnd_product.product_image_09 ?? "");
                setProductImage10(data.pp_mst_rcmnd_product.product_image_10 ?? "");
                setEcsiteLink1(data.pp_mst_rcmnd_product.ecsite_link_1 ?? "");
                setEcsiteLink2(data.pp_mst_rcmnd_product.ecsite_link_2 ?? "");

            })
            .catch((err) => {
                console.log(err);

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/pp_mst_rcmnd_product");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };


    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const req: PpMstRcmndProductControllerCreateRequest = {
            rcmnd_products_title_id: parseInt(rcmndProductsTitleId),
            recommend_type: recommendType,
            intestinal_environment_type: intestinalEnvironmentType,
            priority: parseInt(priority),
            product_type: productType as PpmstrcmndproductRecommendType,
            product_code: productCode,
            product_name: productName,
            product_description: productDescription,
            product_image_01: productImage01,
            product_image_02: productImage02,
            product_image_03: productImage03,
            product_image_04: productImage04,
            product_image_05: productImage05,
            product_image_06: productImage06,
            product_image_07: productImage07,
            product_image_08: productImage08,
            product_image_09: productImage09,
            product_image_10: productImage10,
            ecsite_link_1: ecsiteLink1,
            ecsite_link_2: ecsiteLink2,
        };

        setShowSpinner(true);

        let promise: Promise<void | AxiosResponse<any, any>>;

        if (ppMatRcmndProduct) {
            // 編集時
            promise = adminPpMstRcmndProductApi
                .v1AdminPpMstRcmndProductEditIdPost(String(ppMatRcmndProduct.id), req)
                .then(() => {
                    setCompleteMessage("ペットフード情報を変更いたしました。");
                    setErrors({});
                    navigate(`/pp_mst_rcmnd_product?message_type=edit_complete`);
                });

        } else {
            // 新規登録時
            promise = adminPpMstRcmndProductApi
                .v1AdminPpMstRcmndProductCreatePost(req)
                .then(() => {
                    setCompleteMessage("ペットフード情報を新規登録いたしました。");
                    setErrors({});
                    navigate("/pp_mst_rcmnd_product?message_type=create_complete");
                });
        }

        promise
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setDangerMessage("ご入力内容にエラーがありました。");
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {

        switch (e.currentTarget.name) {
            case "rcmnd_products_title_id":
                setRcmndProductsTitleId(e.currentTarget.value);
                setPriority("");
                break;
            case "recommend_type":
                setRecommendType(e.currentTarget.value);
                break;
            case "intestinal_environment_type":
                setIntestinalEnvironmentType(e.currentTarget.value);
                break;
            case "priority":
                setPriority(e.currentTarget.value);
                break;
            case "product_code":
                setProductCode(e.currentTarget.value);
                break;
            case "product_type":
                setProductType(e.currentTarget.value);
                break;
            case "product_name":
                setProductName(e.currentTarget.value);
                break;
            case "product_description":
                setProductDescription(e.currentTarget.value);
                break;
            case "ecsite_link_1":
                setEcsiteLink1(e.currentTarget.value);
                break;
            case "ecsite_link_2":
                setEcsiteLink2(e.currentTarget.value);
                break;
        }
    };

    // 前の画面に戻る
    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(-1);
    };

    const onSetImage = (column: string, filename: string): void => {

        console.log("onSetImage", column, filename);
        switch (column) {
            case "product_image_01":
                setProductImage01(filename);
                break;
            case "product_image_02":
                setProductImage02(filename);
                break;
            case "product_image_03":
                setProductImage03(filename);
                break;
            case "product_image_04":
                setProductImage04(filename);
                break;
            case "product_image_05":
                setProductImage05(filename);
                break;
            case "product_image_06":
                setProductImage06(filename);
                break;
            case "product_image_07":
                setProductImage07(filename);
                break;
            case "product_image_08":
                setProductImage08(filename);
                break;
            case "product_image_09":
                setProductImage09(filename);
                break;
            case "product_image_10":
                setProductImage10(filename);
                break;
        }
    };

    const showImgModal = (title: string, viewPath: string) => {
        setModalTitle(title);
        setViewPath(viewPath);
        setIsModalImg(true);
    }

    const getDisplayLocation = (): string => {
        if (!rcmndProductsTitleId) {
            return "-";
        }

        const rcmndProductsTitle = rcmndProductsTitles.find((d) => {
            return d.id === parseInt(rcmndProductsTitleId);
        });

        if (!rcmndProductsTitle) {
            return "-";
        }

        return rcmndProductsTitle?.display_location ?? "-";
    }

    // レコメンド要否区分
    const recommendTypes = GetRecommendTypes();
    const recommendTypeKeys = Object.keys(recommendTypes);

    // 腸内環境タイプ
    const intestinalEnvironmentTypes = GetIntestinalEnvironmentTypes();
    const intestinalEnvironmentTypeKeys = Object.keys(intestinalEnvironmentTypes);

    console.log(getDisplayLocation());

    // 基本の優先順位は1〜10
    let priorities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const displayLocation = getDisplayLocation();

    if (displayLocation === PpmstrcmndproductstitleDisplayLocation.DisplayLocation1) {
        // 縦（1）の場合は、優先順位は1〜5
        priorities = [1, 2, 3, 4, 5];
    }

    const horizontalDisabled = displayLocation !== PpmstrcmndproductstitleDisplayLocation.DisplayLocation1;

    return <Layout title={"おすすめ商品管理"}>

        <H1 title={ppMatRcmndProduct ? "おすすめ商品の編集" : "おすすめ商品の新規登録"} subtitle="product data"/>

        <div className="box">

            <H3 title="おすすめ商品" subtitle="product"/>

            <StyledPpMstPetFoodEdit onSubmit={onSubmit}>

                <div className="form-wrapper">

                    <div className="form-group">
                        <label className="req">リコメンド商品< br/>タイトルID</label>
                        <div>
                            <select name="rcmnd_products_title_id" value={rcmndProductsTitleId} onChange={onChange} className={errors["rcmnd_products_title_id"] ? "has-error" : ""}>
                                <option value="">---選択してください</option>
                                {rcmndProductsTitles.map((d, i) => {
                                    return <option value={d.id} key={`rpt-${i}`}>{d.id}：{d.title}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["rcmnd_products_title_id"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">リコメンド要否区分</label>
                        <div>
                            <select name="recommend_type" value={recommendType} onChange={onChange} className={errors["recommend_type"] ? "has-error" : ""}>
                                <option value="">---選択してください</option>
                                {recommendTypeKeys.map((key, i) => {
                                    return <option value={key} key={`recommend-type-${i}`}>{key}：{recommendTypes[key]}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["recommend_type"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">腸内環境タイプ</label>
                        <div>
                            <select name="intestinal_environment_type" value={intestinalEnvironmentType} onChange={onChange} className={errors["intestinal_environment_type"] ? "has-error" : ""}>
                                <option value="">---選択してください</option>
                                {intestinalEnvironmentTypeKeys.map((key, i) => {
                                    return <option value={key} key={`iet-${i}`}>{key}：{intestinalEnvironmentTypes[key]}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["intestinal_environment_type"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">優先順位</label>
                        <div>
                            <select name="priority" value={priority} onChange={onChange} className={errors["priority"] ? "has-error" : ""}>
                                <option value="">---選択してください</option>
                                {priorities.map((key, i) => {
                                    return <option value={key} key={`iet-${i}`}>{key}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["priority"]}/>
                        </div>
                    </div>

                    <div className="form-group center">
                        <label className="req">リコメンドタイトル<br/>表示箇所</label>
                        <div>
                            {GetRcmndTitleDisplayLocationStr(getDisplayLocation())}
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">商品区分</label>
                        <div>
                            <input type="text" name="product_type" value={productType} onChange={onChange} maxLength={6} className={errors["product_type"] ? "has-error" : ""} placeholder="例：シャンプー"/>
                            <ErrorMessage message={errors["product_type"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">商品コード</label>
                        <div>
                            <input
                                type="text" name="product_code" value={productCode} onChange={onChange} maxLength={50} className={errors["product_code"] ? "has-error" : ""} placeholder="例：DP001(半角のみ)"/>
                            <ErrorMessage message={errors["product_code"]}/>
                        </div>
                    </div>

                    <ImageForm
                        column="product_image_01"
                        title={`商品画像（1）`}
                        error={errors["product_image_01"]}
                        onSetImage={onSetImage}
                        showHint={true}
                        required={true}
                        showModal={showImgModal}
                        oldFilename={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_01_name : null}
                        oldViewPath={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_01_url : null}
                    />

                    <ImageForm
                        column="product_image_02"
                        title={`商品画像（2）`}
                        error={errors["product_image_02"]}
                        onSetImage={onSetImage}
                        showHint={false}
                        required={false}
                        showModal={showImgModal}
                        oldFilename={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_02_name : null}
                        oldViewPath={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_02_url : null}
                        disabled={horizontalDisabled}
                    />

                    <ImageForm
                        column="product_image_03"
                        title={`商品画像（3）`}
                        error={errors["product_image_03"]}
                        onSetImage={onSetImage}
                        showHint={false}
                        required={false}
                        showModal={showImgModal}
                        oldFilename={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_03_name : null}
                        oldViewPath={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_03_url : null}
                        disabled={horizontalDisabled}
                    />

                    <ImageForm
                        column="product_image_04"
                        title={`商品画像（4）`}
                        error={errors["product_image_04"]}
                        onSetImage={onSetImage}
                        showHint={false}
                        required={false}
                        showModal={showImgModal}
                        oldFilename={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_04_name : null}
                        oldViewPath={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_04_url : null}
                        disabled={horizontalDisabled}
                    />

                    <ImageForm
                        column="product_image_05"
                        title={`商品画像（5）`}
                        error={errors["product_image_05"]}
                        onSetImage={onSetImage}
                        showHint={false}
                        required={false}
                        showModal={showImgModal}
                        oldFilename={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_05_name : null}
                        oldViewPath={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_05_url : null}
                        disabled={horizontalDisabled}
                    />

                    <ImageForm
                        column="product_image_06"
                        title={`商品画像（6）`}
                        error={errors["product_image_06"]}
                        onSetImage={onSetImage}
                        showHint={false}
                        required={false}
                        showModal={showImgModal}
                        oldFilename={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_06_name : null}
                        oldViewPath={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_06_url : null}
                        disabled={horizontalDisabled}
                    />

                    <ImageForm
                        column="product_image_07"
                        title={`商品画像（7）`}
                        error={errors["product_image_07"]}
                        onSetImage={onSetImage}
                        showHint={false}
                        required={false}
                        showModal={showImgModal}
                        oldFilename={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_07_name : null}
                        oldViewPath={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_07_url : null}
                        disabled={horizontalDisabled}
                    />

                    <ImageForm
                        column="product_image_08"
                        title={`商品画像（8）`}
                        error={errors["product_image_08"]}
                        onSetImage={onSetImage}
                        showHint={false}
                        required={false}
                        showModal={showImgModal}
                        oldFilename={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_08_name : null}
                        oldViewPath={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_08_url : null}
                        disabled={horizontalDisabled}
                    />

                    <ImageForm
                        column="product_image_09"
                        title={`商品画像（9）`}
                        error={errors["product_image_09"]}
                        onSetImage={onSetImage}
                        showHint={false}
                        required={false}
                        showModal={showImgModal}
                        oldFilename={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_09_name : null}
                        oldViewPath={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_09_url : null}
                        disabled={horizontalDisabled}
                    />

                    <ImageForm
                        column="product_image_10"
                        title={`商品画像（10）`}
                        error={errors["product_image_10"]}
                        onSetImage={onSetImage}
                        showHint={false}
                        required={false}
                        showModal={showImgModal}
                        oldFilename={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_10_name : null}
                        oldViewPath={ppMatRcmndProduct ? ppMatRcmndProduct.product_image_10_url : null}
                        disabled={horizontalDisabled}
                    />


                    <div className="form-group">
                        <label>商品名</label>
                        <div>
                            <input type="text" name="product_name" value={productName} onChange={onChange} maxLength={50} className={errors["product_name"] ? "w560 has-error" : "w560"}/>
                            <ErrorMessage message={errors["product_name"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>商品説明</label>
                        <div>
                            <textarea name="product_description" value={productDescription} onChange={onChange} maxLength={1000} className={errors["product_description"] ? "w560 has-error" : "w560"}/>
                            <ErrorMessage message={errors["product_description"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">商品リンク
                            <div className="hint">
                                * ＊どちらか、または両方を<br/>
                                入力してください。
                            </div>
                        </label>

                        <div>
                            <label>商品付き検査キットボタン用URL</label>
                            <div className="mb15">
                                <input type="text" name="ecsite_link_1" value={ecsiteLink1} onChange={onChange} maxLength={100} className={errors["ecsite_link_1"] ? "w640 has-error" : "w640"}/>
                                <ErrorMessage message={errors["ecsite_link_1"]}/>
                            </div>
                            <label>商品のみボタン用URL</label>
                            <div>
                                <input
                                    type="text"
                                    name="ecsite_link_2"
                                    value={ecsiteLink2}
                                    onChange={onChange}
                                    maxLength={100}
                                    className={errors["ecsite_link_2"] ? "w640 has-error" : "w640"}
                                    disabled={horizontalDisabled}
                                />
                                <ErrorMessage message={errors["ecsite_link_2"]} top={55}/>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type={"button"} onClick={onClickBack} icon={ButtonIcon.Arrow}>前の画面に戻る</Button>
                    <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
                </div>
            </StyledPpMstPetFoodEdit>

        </div>

        {isModalImg && viewPath && <ImageModal title={modalTitle} img={viewPath} onClose={() => setIsModalImg(false)}/>}

    </Layout>

};

const StyledPpMstPetFoodEdit = styled(StyledForm)`

    .w560 {
        width: 560px !important;
    }

    .w640 {
        width: 640px !important;
    }


    select, input[type=text] {
        width: 400px;
    }


    textarea {
        height: 120px;
    }

    .form-group {
        > div {
            label {
                display: block;
                font-size: 14px;
                margin-bottom: 3px;
            }
        }
    }

`;
