import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {H1, H3} from "../common/Headline";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {AxiosResponse} from "axios";
import {ErrorMessage} from "../common/ErrorMessage";
import {adminPpMstGenericRcmndGroupApi} from "../../api/Api";
import {PpMstGenericRcmndGroupControllerCreateRequest, TypesPpMstGenericRcmndGroupData} from "../../generated";
import {GenericRcmndDisplayMethod} from "../../utility/Utility";

export const PpMstGenericRcmndGroupEdit = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppMstGenericRcmndGroup, setPpMstGenericRcmndGroup] = useState<TypesPpMstGenericRcmndGroupData | null>(null)
    const [genericRcmndDisplayMethod, setGenericRcmndDisplayMethod] = useState<string>("");
    const [genericRcmndGroupTitle, setGenericRcmndGroupTitle] = useState<string>("");

    const params = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {

        if (params.id) {
            // IDが指定されている場合はデータを取得する
            getData(params.id);
        }

    }, [params]);

    const getData = (id: string) => {

        setShowSpinner(true);

        adminPpMstGenericRcmndGroupApi
            .v1AdminPpMstGenericRcmndGroupGetIdGet(id)
            .then(({data}) => {
                setPpMstGenericRcmndGroup(data.pp_mst_generic_rcmnd_group);
                setGenericRcmndDisplayMethod(data.pp_mst_generic_rcmnd_group.generic_rcmnd_display_method);
                setGenericRcmndGroupTitle(String(data.pp_mst_generic_rcmnd_group.generic_rcmnd_group_title));
            })
            .catch((err) => {
                console.log(err);

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/pp_mst_generic_rcmnd_group");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };


    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const req: PpMstGenericRcmndGroupControllerCreateRequest = {
            generic_rcmnd_display_method: genericRcmndDisplayMethod,
            generic_rcmnd_group_title: genericRcmndGroupTitle,
        };

        setShowSpinner(true);

        let promise: Promise<void | AxiosResponse<any, any>>;

        if (ppMstGenericRcmndGroup) {
            // 編集時
            promise = adminPpMstGenericRcmndGroupApi
                .v1AdminPpMstGenericRcmndGroupEditIdPost(String(ppMstGenericRcmndGroup.id), req)
                .then(() => {
                    setErrors({});
                    navigate(`/pp_mst_generic_rcmnd_group?message_type=edit_complete`);
                });

        } else {
            // 新規登録時
            promise = adminPpMstGenericRcmndGroupApi
                .v1AdminPpMstGenericRcmndGroupCreatePost(req)
                .then(() => {
                    setErrors({});
                    navigate(`/pp_mst_generic_rcmnd_group?message_type=create_complete`);
                });
        }

        promise
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {

        switch (e.currentTarget.name) {
            case "generic_rcmnd_display_method":
                setGenericRcmndDisplayMethod(e.currentTarget.value);
                break;
            case "generic_rcmnd_group_title":
                setGenericRcmndGroupTitle(e.currentTarget.value);
                break;
        }
    };

    // 前の画面に戻る
    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(-1);
    };

    return <Layout title={"汎用リコメンドグループ管理"}>

        <H1 title={ppMstGenericRcmndGroup ? "汎用リコメンドグループの編集" : "汎用リコメンドグループの新規登録"} subtitle="data entry"/>

        <div className="box">

            <H3 title="汎用リコメンドグループ" subtitle="data"/>

            <StyledSkuEdit onSubmit={onSubmit}>

                <div className="form-wrapper">

                    <div className="form-group">
                        <label className="req">汎用リコメンド<br/>表示方式</label>
                        <div>
                            <select name="generic_rcmnd_display_method" value={genericRcmndDisplayMethod} onChange={onChange} className={errors["generic_rcmnd_display_method"] ? "error" : ""}>
                                <option value="">---選択してください</option>
                                {GenericRcmndDisplayMethod.map((d, i) => {
                                    return <option value={d.id} key={`gr-${i}`}>{d.id}：{d.name}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["generic_rcmnd_display_method"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">汎用リコメンド<br/>グループタイトル</label>
                        <div>
                            <input name="generic_rcmnd_group_title" type="text" maxLength={30} placeholder="例：オリゴ糖" value={genericRcmndGroupTitle} onChange={onChange} className={errors["generic_rcmnd_group_title"] ? "error" : ""}/>
                            <ErrorMessage message={errors["generic_rcmnd_group_title"]}/>
                        </div>
                    </div>

                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type={"button"} onClick={onClickBack} icon={ButtonIcon.Arrow}>前の画面に戻る</Button>
                    <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
                </div>
            </StyledSkuEdit>

        </div>

    </Layout>;

};

const StyledSkuEdit = styled(StyledForm)`

    select, input {
        width: 400px !important;
    }

`;
