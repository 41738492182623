import {useContext} from "react";
import {AppContext} from "../../contexts/AppContext";
import styled from "styled-components";

export const Spinner = () => {

    const {showSpinner} = useContext(AppContext);

    if (!showSpinner) {
        return null;
    }

    return <SpinnerOverlay>
        <SpinnerInner>
            <StyledSpinner viewBox="0 0 50 50">
                <circle
                    className="path"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="4"
                />
            </StyledSpinner>
        </SpinnerInner>

    </SpinnerOverlay>
};

const SpinnerOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;


const SpinnerInner = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
`;

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  margin: 5px 0 0 5px;
  width: 40px;
  height: 40px;

  .path {
    stroke: #03b9a0;
    stroke-linecap: square;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;