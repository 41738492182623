import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {H1, H3} from "../common/Headline";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {AxiosResponse} from "axios";
import {ErrorMessage} from "../common/ErrorMessage";
import {adminPpMstSkuApi} from "../../api/Api";
import {PpMstSkuControllerEditRequest, TypesPpMstSkuData} from "../../generated";

export const PpMstSkuEdit = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppMstSku, setPpMstSku] = useState<TypesPpMstSkuData | null>(null)
    const [skuCode, setSkuCode] = useState<string>("");
    const [foodIncluded, setFoodIncluded] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [rcmndAvailability, setRcmndAvailability] = useState<string>("");
    const params = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {

        if (params.id) {
            // IDが指定されている場合は、SKUを取得する
            getSku(params.id);
        }

    }, [params]);

    const getSku = (id: string) => {

        setShowSpinner(true);

        adminPpMstSkuApi.v1AdminPpMstSkuGetIdGet(id)
            .then(({data}) => {
                setPpMstSku(data.pp_mst_sku);

                setSkuCode(data.pp_mst_sku.sku_code);
                setFoodIncluded(data.pp_mst_sku.food_included ? "1" : "0");
                setRcmndAvailability(data.pp_mst_sku.rcmnd_availability);
                setDescription(data.pp_mst_sku.description ?? "");


            })
            .catch((err) => {
                console.log(err);

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/pp_mst_sku");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };


    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const req: PpMstSkuControllerEditRequest = {
            sku_code: skuCode,
            food_included: foodIncluded,
            rcmnd_availability: rcmndAvailability,
            description: description,
        };

        setShowSpinner(true);

        let promise: Promise<void | AxiosResponse<any, any>>;

        if (ppMstSku) {
            // 編集時
            promise = adminPpMstSkuApi.v1AdminPpMstSkuEditIdPost(String(ppMstSku.id), req)
                .then(() => {
                    setErrors({});
                    navigate(`/pp_mst_sku?message_type=edit_complete&sku_code=${skuCode}`);
                });

        } else {
            // 新規登録時
            promise = adminPpMstSkuApi.v1AdminPpMstSkuCreatePost(req)
                .then(() => {
                    setErrors({});
                    navigate(`/pp_mst_sku?message_type=create_complete&sku_code=${skuCode}`);
                });
        }

        promise
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {

        switch (e.currentTarget.name) {
            case "sku_code":
                setSkuCode(e.currentTarget.value);
                break;
            case "food_included":
                setFoodIncluded(e.currentTarget.value);
                break;
            case "rcmnd_availability":
                setRcmndAvailability(e.currentTarget.value);
                break;
            case "description":
                setDescription(e.currentTarget.value);
                break;
        }
    };

    // 前の画面に戻る
    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(-1);
    };

    return <Layout title={"SKUコード管理"}>

        <H1 title={ppMstSku ? "SKUコードの編集" : "SKUコードの新規登録"} subtitle="SKU data"/>

        <div className="box">

            <H3 title="SKUコード情報" subtitle="SKU"/>

            <StyledSkuEdit onSubmit={onSubmit}>

                <div className="form-wrapper">

                    <div className="form-group">
                        <label className="req">SKUコード</label>
                        <div>
                            <input type="text" name="sku_code" value={skuCode} onChange={onChange} placeholder={"例：DA001"} maxLength={5} className={errors["sku_code"] !== undefined ? "w240 has-error" : "w240"}/>
                            <ErrorMessage message={errors["sku_code"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">商品付帯有無</label>
                        <div>
                            <select name="food_included" value={foodIncluded} onChange={onChange} className={errors["food_included"] !== undefined ? "w240 has-error" : "w240"}>
                                <option value="">---選択してください</option>
                                <option value="0">付帯なし</option>
                                <option value="1">付帯あり</option>
                            </select>
                            <ErrorMessage message={errors["food_included"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">商品リコメンド<br />オプション有無</label>
                        <div>
                            <select name="rcmnd_availability" value={rcmndAvailability} onChange={onChange} className={errors["rcmnd_availability"] !== undefined ? "w240 has-error" : "w240"}>
                                <option value="">---選択してください</option>
                                <option value="0">なし</option>
                                <option value="1">あり</option>
                            </select>
                            <ErrorMessage message={errors["rcmnd_availability"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>説明</label>
                        <div>
                            <input type="text" name="description" value={description} onChange={onChange} placeholder={"例：P-Pet 犬用フード 1kg"} maxLength={500} className={errors["description"] !== undefined ? "w640 has-error" : "w640"}/>
                            <ErrorMessage message={errors["description"]}/>
                        </div>
                    </div>

                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type={"button"} onClick={onClickBack} icon={ButtonIcon.Arrow}>前の画面に戻る</Button>
                    <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
                </div>
            </StyledSkuEdit>

        </div>

    </Layout>;

};

const StyledSkuEdit = styled(StyledForm)`

    .w240 {
        width: 240px !important;
    }

    .w640 {
        width: 640px !important;
    }

`;
