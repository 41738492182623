import styled from "styled-components";
import {Button, ButtonColor, ButtonIcon} from "../../common/Button";
import {MainColor} from "../../common/Colors";
import dog1 from "../../../images/body/dog_1.svg";
import dog2 from "../../../images/body/dog_2.svg";
import dog3 from "../../../images/body/dog_3.svg";
import dog4 from "../../../images/body/dog_4.svg";
import dog5 from "../../../images/body/dog_5.svg";
import React from "react";

interface Props {
    onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Dog = ({onClose}: Props) => {

    return <StyledDog>


        <div className="modal">

            <h4>犬の体型の基準</h4>

            <div className="shape">
                <h5>痩せている</h5>
                <img src={dog1} alt="体型画像"/>

                <ul>
                    <li>
                        <span>肋骨</span>
                        <span>外から容易に見える、触れる</span>
                    </li>
                    <li>
                        <span>腰のくびれ</span>
                        <span>顕著</span>
                    </li>
                    <li>
                        <span>腹部の吊り上がり</span>
                        <span>顕著</span>
                    </li>
                </ul>
            </div>


            <div className="shape">
                <h5>やや痩せている</h5>
                <img src={dog2} alt="体型画像"/>

                <ul>
                    <li>
                        <span>肋骨</span>
                        <span>容易に触れる</span>
                    </li>
                    <li>
                        <span>腰のくびれ</span>
                        <span>顕著</span>
                    </li>
                    <li>
                        <span>腹部の吊り上がり</span>
                        <span>はっきりしている</span>
                    </li>
                </ul>
            </div>


            <div className="shape">
                <h5>標準</h5>
                <img src={dog3} alt="体型画像"/>

                <ul>
                    <li>
                        <span>肋骨</span>
                        <span>触ることができる</span>
                    </li>
                    <li>
                        <span>腰のくびれ</span>
                        <span>肋骨のうしろに見られる</span>
                    </li>
                    <li>
                        <span>腹部の吊り上がり</span>
                        <span>ある</span>
                    </li>
                </ul>
            </div>


            <div className="shape">
                <h5>やや肥満</h5>
                <img src={dog4} alt="体型画像"/>

                <ul>
                    <li>
                        <span>肋骨</span>
                        <span>触りづらい</span>
                    </li>
                    <li>
                        <span>腰のくびれ</span>
                        <span>ややわかりにく</span>
                    </li>
                    <li>
                        <span>腹部の吊り上がり</span>
                        <span>ややある</span>
                    </li>
                </ul>
            </div>


            <div className="shape">
                <h5>肥満</h5>
                <img src={dog5} alt="体型画像"/>

                <ul>
                    <li>
                        <span>肋骨</span>
                        <span>触ることができない</span>
                    </li>
                    <li>
                        <span>腰のくびれ</span>
                        <span>ないか、ほとんど見られない</span>
                    </li>
                    <li>
                        <span>腹部の吊り上がり</span>
                        <span>ないか、むしろ垂れ下がっている</span>
                    </li>
                </ul>
            </div>


            <div className="footer">
                <Button color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={onClose} type="button">元の画面に戻る</Button>
            </div>

        </div>


    </StyledDog>

}


export const StyledDog = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
  padding: 30px 0;


  .modal {
    margin: 0 auto;
    width: 450px;
    border-radius: 16px;
    overflow: hidden;
    background-color: #fff;

    h4 {
      background-color: ${MainColor};
      text-align: center;
      font-size: 18px;
      font-weight: normal;
      color: #fff;
      line-height: 48px;
    }

    .shape {

      h5 {
        background-color: #eee;
        text-align: center;
        color: #4D8E82;
        font-size: 14px;
        font-weight: normal;
        line-height: 40px;
      }

      img {
        max-width: 70%;
        height: auto;
        display: block;
        margin: 20px auto 30px auto;
      }

      ul {
        list-style-type: none;
        margin-bottom: 40px;
        padding: 0 40px;

        li {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 11px;
          align-items: center;

          span {
            font-size: 14px;

            &:first-child {
              font-size: 12px;
              margin-right: 15px;
              text-align: center;
              width: 130px;
              background-color: #E7F1F4;
              line-height: 24px;
              border-radius: 21px;
            }
          }
        }
      }

    }


    .footer {
      padding: 24px 0;
      background-color: #EFEFEF;
    }
  }

`;