import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import {Counter} from "../common/Counter";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {adminPpMstRcmndProductsTitleApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";
import {AccesstokenRole, PpMstRcmndProductsTitleControllerIndexRequest, TypesPpMstRcmndProductsTitleData} from "../../generated";
import {Table} from "../common/Table";
import {Paginator} from "../common/Paginator";
import styled from "styled-components";
import {MainColor} from "../common/Colors";
import rightWhite from "../../images/right-white.svg";
import {CompleteMessage} from "../common/CompleteMessage";
import {SearchForm} from "./SearchForm";
import {GetRcmndTitleDisplayLocationStr} from "../../utility/Utility";

export const PpMstRcmndProductsTitleIndex = () => {

    const {setShowSpinner, setDangerMessage, setCompleteMessage, role} = useContext(AppContext);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [ppMstRcmndProductsTitles, setPpMstRcmndProductsTitles] = useState<TypesPpMstRcmndProductsTitleData[]>([]);
    const navigate = useNavigate();
    const location = useLocation();

    const size = 200;

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const messageType = params.get("message_type") ?? "";

        if (messageType === "create_complete") {
            setCompleteMessage(`リコメンド表示パターンの新規追加が完了いたしました。`);
        }

        if (messageType === "edit_complete") {
            setCompleteMessage(`リコメンド表示パターンの編集が完了いたしました`);
        }

    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get("page")) {
            setPage(Number(params.get("page")));
        }

        getData();
    }, [location.search]);

    const getData = () => {

        setShowSpinner(true);

        const params = new URLSearchParams(location.search);

        const displayLocation = params.get("display_location") ?? "";
        const rcmndMethodId = params.get("rcmnd_method_id") ?? "";
        const recommendTitle = params.get("recommend_title") ?? "";

        const req: PpMstRcmndProductsTitleControllerIndexRequest = {
            page: page,
            size: size,
            display_location: displayLocation,
            rcmnd_method_id: parseInt(rcmndMethodId),
            recommend_title: recommendTitle,
        };

        adminPpMstRcmndProductsTitleApi
            .v1AdminPpMstRcmndProductsTitlePost(req)
            .then(({data}) => {
                setPpMstRcmndProductsTitles(data.pp_mst_rcmnd_products_titles);
                setTotal(data.total ?? 0);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onDelete = (e: React.MouseEvent<HTMLSpanElement>, ppMstRcmndProductsTitle: TypesPpMstRcmndProductsTitleData) => {

        e.preventDefault();

        if (!window.confirm(`${ppMstRcmndProductsTitle.recommend_title} を削除します。よろしいですか？`)) {
            return;
        }

        setShowSpinner(true);

        adminPpMstRcmndProductsTitleApi
            .v1AdminPpMstRcmndProductsTitleDeleteIdDelete(String(ppMstRcmndProductsTitle.id))
            .then(() => {
                setCompleteMessage(`個別商品リコメンドタイトル：${ppMstRcmndProductsTitle.recommend_title}を削除いたしました。`);
                getData();
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onPageChange = (page: number) => {
        setPage(page);
        navigate(`/pp_mst_rcmnd_products_title?page=${page}`);
    }

    return <Layout title="個別商品リコメンドタイトル管理">

        <SearchForm/>

        <CompleteMessage/>

        <StyledPpMstSkuIndex className="box">

            <H3 title="タイトル一覧" subtitle="list" counter={<Counter total={total} size={size} current={page}/>}/>

            <Table>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>リコメンド方式ID</th>
                    <th>リコメンドタイトル表示箇所</th>
                    <th>リコメンドタイトル</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>

                <tbody>

                {ppMstRcmndProductsTitles.map((d, i) => {

                    return <tr key={`sku-${i}`}>
                        <td>{d.id}</td>
                        <td>{d.pp_mst_rcmnd_method ? `${d.pp_mst_rcmnd_method.id}：${d.pp_mst_rcmnd_method.rcmnd_method_label ?? "ラベル未設定"}` : "-"}</td>
                        <td>{GetRcmndTitleDisplayLocationStr(d.display_location)}</td>
                        <td>{d.recommend_title}</td>
                        {role === AccesstokenRole.RoleMaster && <>
                            <td><NavLink to={`/pp_mst_rcmnd_products_title/edit/${d.id}`} className="btn-link">編集</NavLink></td>
                            <td><span onClick={(e) => onDelete(e, d)} className="btn-del">削除</span></td>
                        </>}
                    </tr>
                })}

                </tbody>

            </Table>

            <Paginator onClick={onPageChange} pages={Math.ceil(total / size)} page={page}/>

        </StyledPpMstSkuIndex>

    </Layout>
}


const StyledPpMstSkuIndex = styled.div`

    tr {
        td:nth-child(5), td:nth-child(6) {
            text-align: center !important;
            width: 120px;
        }
    }

    .btn-link, .btn-del {
        background-color: ${MainColor};
        color: #fff;
        font-size: 12px !important;
        line-height: 24px;
        display: inline-block;
        text-decoration: none !important;
        padding: 0 30px 0 20px;
        letter-spacing: 1px;
        border-radius: 24px;
        background-image: url(${rightWhite});
        background-repeat: no-repeat;
        background-position: top 50% right 15px;
        background-size: 5px auto;
    }

    .btn-del {
        background-color: #ccc;
    }
`;
