import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import {Counter} from "../common/Counter";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {adminPpMstRcmndProductApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";
import {AccesstokenRole, PpMstRcmndProductControllerIndexRequest, TypesPpMstRcmndProductData} from "../../generated";
import {Table} from "../common/Table";
import {Paginator} from "../common/Paginator";
import {GetRcmndTitleDisplayLocationStr, GetRecommendTypes} from "../../utility/Utility";
import {SearchForm} from "./SearchForm";
import styled from "styled-components";
import {MainColor} from "../common/Colors";
import rightWhite from "../../images/right-white.svg";
import {Modal, ModalColor} from "../result/Modal";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {CompleteMessage} from "../common/CompleteMessage";

export const PpMstRcmndProductIndex = () => {

    const {setShowSpinner, setDangerMessage, setCompleteMessage, role} = useContext(AppContext);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [ppMstRcmndProducts, setPpMstRcmndProducts] = useState<TypesPpMstRcmndProductData[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [isModal, setIsModal] = useState<boolean>(false);
    const [ppMstRcmndProduct, setPpMstRcmndProduct] = useState<TypesPpMstRcmndProductData | null>(null);

    const size = 1000; // 現実的に全件となるよう調整

    useEffect(() => {
        getIndex();
        const params = new URLSearchParams(location.search);

        const messageType = params.get("message_type") ?? "";

        if (messageType === "create_complete") {
            setCompleteMessage("おすすめ商品情報を新規登録いたしました。");
        }

        if (messageType === "edit_complete") {
            setCompleteMessage("おすすめ商品情報を変更いたしました。");
        }

    }, [location.search]);

    const getIndex = () => {

        const params = new URLSearchParams(location.search);

        const rcmndProductsTitleId = params.get("rcmnd_products_title_id") ?? "";
        const displayLocation = params.get("display_location") ?? "";
        const recommendType = params.get("recommend_type") ?? "";
        const intestinalEnvironmentType = params.get("intestinal_environment_type") ?? "";
        const productCode = params.get("product_code") ?? "";
        const productName = params.get("product_name") ?? "";

        setShowSpinner(true);

        const req: PpMstRcmndProductControllerIndexRequest = {
            rcmnd_products_title_id: parseInt(rcmndProductsTitleId),
            recommend_type: recommendType,
            intestinal_environment_type: intestinalEnvironmentType,
            display_location: displayLocation,
            product_code: productCode,
            product_name: productName,
            page: page,
            size: size,
        };

        adminPpMstRcmndProductApi
            .v1AdminPpMstRcmndProductPost(req)
            .then(({data}) => {
                setPpMstRcmndProducts(data.pp_mst_rcmnd_products);
                setTotal(data.total ?? 0);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onDeleteConfirm = (e: React.MouseEvent<HTMLSpanElement>, ppMstRcmndProduct: TypesPpMstRcmndProductData) => {
        e.preventDefault();
        setIsModal(true);
        setPpMstRcmndProduct(ppMstRcmndProduct);
    };

    const onDeleteCancel = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        setIsModal(false);
        setPpMstRcmndProduct(null);
    };

    const onDelete = (e: React.MouseEvent<HTMLSpanElement>) => {

        e.preventDefault();

        if (!ppMstRcmndProduct) {
            return;
        }

        setShowSpinner(true);
        adminPpMstRcmndProductApi.v1AdminPpMstRcmndProductDeleteIdDelete(String(ppMstRcmndProduct.id))
            .then(() => {
                setCompleteMessage("おすすめ商品情報を削除いたしました。");
                setIsModal(false);
                setPpMstRcmndProduct(null);
                getIndex();
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onPageChange = (page: number) => {
        setPage(page);
        const params = new URLSearchParams(location.search);
        params.set("page", String(page));
        navigate(`/pp_mst_rcmnd_product?${params.toString()}`);
    }

    // レコメンド要否区分
    const recommendTypes = GetRecommendTypes();

    return <Layout title="おすすめ商品管理">

        <SearchForm/>

        <CompleteMessage/>

        <StyledPpMstRcmndProductIndex className="box">

            <H3 title="おすすめ商品一覧" subtitle="list" counter={<Counter total={total} size={size} current={page}/>}/>

            <Table>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>リコメンド商品タイトルID</th>
                    <th>リコメンド要否区分</th>
                    <th>腸内環境タイプ</th>
                    <th>優先順位</th>
                    <th>画面表示形式</th>
                    <th>商品コード</th>
                    <th>商品名</th>
                    {role === AccesstokenRole.RoleMaster && <>
                        <th></th>
                        <th></th>
                    </>}
                </tr>
                </thead>

                <tbody>

                {ppMstRcmndProducts.map((d, i) => {

                    return <tr key={`sku-${i}`}>
                        <td>{d.id}</td>
                        <td>{d.pp_mst_rcmnd_products_title ? `${d.pp_mst_rcmnd_products_title.id}：${d.pp_mst_rcmnd_products_title.recommend_title}` : "-"}</td>
                        <td>{d.recommend_type}：{recommendTypes[d.recommend_type]}</td>
                        <td>{d.intestinal_environment_type}</td>
                        <td>{d.priority}</td>
                        <td>{GetRcmndTitleDisplayLocationStr(d.pp_mst_rcmnd_products_title ? d.pp_mst_rcmnd_products_title.display_location : "")}</td>
                        <td>{d.product_code}</td>
                        <td>{d.product_name !== "" ? d.product_name : "-"}</td>
                        {role === AccesstokenRole.RoleMaster && <>
                            <td style={{textAlign: "center"}}>
                                <NavLink to={`/pp_mst_rcmnd_product/edit/${d.id}`} className="btn-link">編集</NavLink>
                            </td>
                            <td style={{textAlign: "center"}}>
                                <span onClick={(e) => onDeleteConfirm(e, d)} className="btn-del">削除</span>
                            </td>
                        </>}
                    </tr>
                })}

                </tbody>

            </Table>

            <Paginator onClick={onPageChange} pages={Math.ceil(total / size)} page={page}/>

            {isModal && ppMstRcmndProduct && <Modal color={ModalColor.Danger}>
                <h4>おすすめ商品情報の削除</h4>
                <h5>対象の情報を削除してよろしいですか？</h5>

                <div className="confirmation">
                    <span>対象の商品コード</span>
                    <strong>{ppMstRcmndProduct.product_code}</strong>
                </div>

                <p>
                    おすすめ商品情報の削除を行なった場合、<br/>
                    情報を元に戻すことができません。<br/>
                    <span><strong>商品コードに間違いがないか</strong>確認の上、削除してください。</span>
                </p>

                <div className="btn-area">
                    <Button type="button" color={ButtonColor.Gray} icon={ButtonIcon.Arrow} onClick={onDeleteCancel}>戻る</Button>
                    <Button type="button" color={ButtonColor.Danger} icon={ButtonIcon.Arrow} onClick={onDelete}>削除</Button>
                </div>
            </Modal>}


        </StyledPpMstRcmndProductIndex>

    </Layout>
}


const StyledPpMstRcmndProductIndex = styled.div`

    .btn-link, .btn-del {
        background-color: ${MainColor};
        color: #fff;
        font-size: 12px !important;
        line-height: 24px;
        display: inline-block;
        text-decoration: none !important;
        padding: 0 30px 0 20px;
        letter-spacing: 1px;
        border-radius: 24px;
        background-image: url(${rightWhite});
        background-repeat: no-repeat;
        background-position: top 50% right 15px;
        background-size: 5px auto;
    }

    .btn-del {
        background-color: #ccc;
    }

    .modal-inner {
        background-color: #fff;

        .confirmation {
            background-color: #fff;
            display: flex;
            justify-content: center;
            border-bottom: 1px solid #efefef;
            padding: 20px 0;
            align-items: flex-end;
            margin: 0 20px;

            span {
                margin-right: 23px;
            }

            strong {
                font-size: 40px;
                color: #FF9871;
                font-weight: 400;
                line-height: 1;
            }
        }

        p {
            padding-top: 20px !important;

            span {
                background: linear-gradient(transparent 50%, rgba(217, 62, 76, 0.2) 20%);

                strong {
                    background: none !important;
                }
            }
        }
    }

`;
