import React from "react";
import styled from "styled-components";
import {MainColor} from "./Colors";
import rightWhite from "../../images/right-white.svg";
import closeWhite from "../../images/close-white.svg";

export enum ButtonColor {
    Green = "green",
    Orange = "orange",
    Gray = "gray",
    Danger = "danger",
    White = "white",
}

export enum ButtonSize {
    Small = "small",
    Medium = "medium",
}

export enum ButtonIcon {
    Arrow = "arrow",
    Close = "close",
}

interface Props extends React.PropsWithChildren {
    color: ButtonColor;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void | undefined;
    type?: 'submit' | 'reset' | 'button' | undefined;
    icon?: ButtonIcon;
    size?: ButtonSize;
    className?: string;
    disabled?: boolean;
}

export const Button = (props: Props) => {

    const classes: string[] = [String(props.color)];

    if (props.icon !== undefined && props.icon) {
        classes.push(props.icon);
    }

    if (props.size !== undefined) {
        classes.push(props.size);
    }

    if (props.className) {
        classes.push(props.className);
    }

    return <StyledButton className={classes.join(" ")} type={props.type} onClick={props.onClick} disabled={props.disabled}>
        {props.children}
    </StyledButton>

};

const StyledButton = styled.button`
  border: none;
  border-radius: 48px;
  height: 40px;
  color: #fff;
  display: block;
  margin: auto;
  font-size: 16px;
  padding: 0 40px 0 40px;
  letter-spacing: 1.5px;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
  
  &.small {
    font-size: 12px;
    height: auto;
    width: auto;
    padding: 5px 30px 5px 20px;
    line-height: 1.3;
    margin: 0;
    cursor: pointer;
    display: inline-block;
    letter-spacing: 1px;

    &.arrow {
      background-position: top 50% right 15px;
      padding-right: 30px;
      background-size: 5px auto;
    }
    &.close {
      background-position: top 50% right 15px;
      padding-right: 30px;
      background-size: 8px auto;
    }

  }
  
  &.medium {
    font-size: 14px;
    height: 30px;
    width: auto;
    padding: 0 30px 0 20px;
    margin: 0;
    cursor: pointer;
    display: inline-block;
    letter-spacing: 1px;

    &.arrow {
      background-position: top 50% right 15px;
      padding-right: 30px;
      background-size: 5px auto;
    }
    &.close {
      background-position: top 50% right 15px;
      padding-right: 30px;
      background-size: 8px auto;
    }

  }

  &.green {
    background-color: ${MainColor};
  }

  &.orange {
    background-color: #FF9871;
  }

  &.gray {
    background-color: #CCCCCC;
  }

  &.danger {
    background-color: #cc2222;
  }

  &.white {
    background-color: #fff;
    color: ${MainColor};
  }

  &.arrow {
    background-image: url(${rightWhite});
    background-repeat: no-repeat;
    background-position: top 50% right 30px;
    padding-right: 50px;
  }

  &.close {
    background-image: url(${closeWhite});
    background-repeat: no-repeat;
    background-position: top 50% right 20px;
    padding-right: 50px;
  }
  
  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }

`;