import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import React, {useContext, useEffect, useRef, useState} from "react";
import {H3} from "../common/Headline";
import {ErrorMessage} from "../common/ErrorMessage";
import {BirthTypeConfirmed, BirthTypeEstimated, BirthTypeUnknown, GetDateList, GetMonthList, GetYearList, SpeciesCat, SpeciesDog} from "../../utility/Utility";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "../common/Button";
import {AppContext} from "../../contexts/AppContext";
import {adminPpPetApi, assetsApi, ppMstBreedApi} from "../../api/Api";
import {AssetsControllerUploadImageResponse, PpMstBreedModelGetListResponse, PpPetControllerCreateRequest, TypesPpPetData} from "../../generated";
import {PetModal} from "../common/PetModal";
import {BreedInput} from "../common/BreedInput";
import ObjectIconCropModal from "./IconCropModal/IconCropModal";

interface Props extends React.PropsWithChildren {
    backBtnText: string;
    h3Text: string;
    onBack: () => void;
    afterValidate: (req: PpPetControllerCreateRequest, imgRes: AssetsControllerUploadImageResponse | null) => void;
    ppPet: TypesPpPetData | null;
    isEdit: boolean;
    onDeletePetImg: () => void;
    hasTest: boolean;
}

export const PpPetForm = ({onBack, afterValidate, backBtnText, ppPet, h3Text, isEdit, onDeletePetImg, hasTest}: Props) => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [karteId, setKarteId] = useState<string>("");
    const [petName, setPetName] = useState<string>("");
    const [animalType, setAnimalType] = useState<string>("");
    const [accuracyOfPetBirthday, setAccuracyOfPetBirthday] = useState<string>("");
    const [birthYear, setBirthYear] = useState<string>("");
    const [birthMonth, setBirthMonth] = useState<string>("");
    const [birthDate, setBirthDate] = useState<string>("");
    const [birthday, setBirthday] = useState<string>("");
    const [petSex, setPetSex] = useState<string>("");
    const [breedId, setBreedId] = useState<number>(0);
    const [breedOfParents1, setBreedOfParents1] = useState<number | null>(null);
    const [breedOfParents2, setBreedOfParents2] = useState<number | null>(null);
    const [icon, setIcon] = useState<string>("");
    const [viewPath, setViewPath] = useState<string>("");
    const [filename, setFilename] = useState<string>("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isBirthdayActive, setIsBirthdayActive] = useState<boolean>(true);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isModalImg, setIsModalImg] = React.useState<boolean>(false);
    const [ppMstBreeds, setPpMstBreeds] = useState<PpMstBreedModelGetListResponse[]>([]);
    const [file, setFile] = useState<File | null>(null);

    useEffect(() => {

        if (isEdit && ppPet) {
            // ---------------------------- 編集時
            // 編集時はセッションをクリア
            sessionStorage.removeItem("pet_create_info");
            sessionStorage.removeItem("pet_img_info");

            setKarteId(ppPet.karte_id ?? "");
            setPetName(ppPet.pet_name ?? "");
            setAnimalType(ppPet.animal_type ?? "");
            setAccuracyOfPetBirthday(ppPet.accuracy_of_pet_birthday ?? "");
            setBirthday(ppPet.birthday ?? "");
            setPetSex(ppPet.pet_sex ?? "");
            setBreedId(ppPet.breed_id ?? 0);
            setIcon(ppPet.icon ?? "");
            setFilename(ppPet.icon_name ?? "");
            setViewPath(ppPet.img_url ?? "");
            setIsBirthdayActive(ppPet.accuracy_of_pet_birthday !== BirthTypeUnknown);
            setBreedOfParents1(ppPet.breed_of_parents_1 ?? null);
            setBreedOfParents2(ppPet.breed_of_parents_2 ?? null);

            if (ppPet.birthday) {
                const birthParts = ppPet.birthday.split("-");
                if (birthParts.length === 3) {
                    setBirthYear(birthParts[0]);
                    setBirthMonth(birthParts[1]);
                    setBirthDate(birthParts[2]);
                }
            }

        } else {
            // ---------------------------- 新規登録時
            // 読み込み時、sessionStorageに以前のデータがあったら復旧
            const oldData = sessionStorage.getItem("pet_create_info");

            if (oldData) {
                const j = JSON.parse(oldData) as PpPetControllerCreateRequest;

                setKarteId(j.karte_id ?? "");
                setPetName(j.pet_name ?? "");
                setAnimalType(j.animal_type ?? "");
                setAccuracyOfPetBirthday(j.accuracy_of_pet_birthday ?? "");
                setBirthday(j.birthday ?? "");
                setPetSex(j.pet_sex ?? "");
                setBreedId(j.breed_id ?? 0);
                setIcon(j.icon ?? "");
                setIsBirthdayActive(j.accuracy_of_pet_birthday !== BirthTypeUnknown);
                setBreedOfParents1(j.breed_of_parents_1 ?? null);
                setBreedOfParents2(j.breed_of_parents_2 ?? null);

                if (j.birthday) {
                    const birthParts = j.birthday.split("-");
                    if (birthParts.length === 3) {
                        setBirthYear(birthParts[0]);
                        setBirthMonth(birthParts[1]);
                        setBirthDate(birthParts[2]);
                    }
                }
            }

            // 同様に画像も。
            const oldData2 = sessionStorage.getItem("pet_img_info");

            if (oldData2) {
                const j = JSON.parse(oldData2) as AssetsControllerUploadImageResponse;
                setFilename(j.original_filename ?? "");
                setViewPath(j.view_path ?? "");
            }
        }

        // 品種リスト取得
        getBreeds();

    }, []);

    const getBreeds = () => {

        setShowSpinner(true);

        ppMstBreedApi.v1PpMstBreedListGet()
            .then(({data}) => {
                setPpMstBreeds(data);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        switch (e.currentTarget.name) {

            case "karte_id":
                setKarteId(e.currentTarget.value);
                break;
            case "pet_name":
                setPetName(e.currentTarget.value);
                break;
            case "animal_type":
                setAnimalType(e.currentTarget.value);
                setBreedId(0); // 品種をクリア
                break;
            case "accuracy_of_pet_birthday":
                setAccuracyOfPetBirthday(e.currentTarget.value);
                setIsBirthdayActive(e.currentTarget.value !== BirthTypeUnknown);

                if (e.currentTarget.value === BirthTypeUnknown) {
                    // 不明の場合、生年月日をクリア
                    setBirthYear("");
                    setBirthMonth("");
                    setBirthDate("");
                    setBirthday("");
                }

                break;
            case "birth_year":
                setBirthYear(e.currentTarget.value);
                setBirthday(`${e.currentTarget.value}-${birthMonth}-${birthDate}`);
                break;
            case "birth_month":
                setBirthMonth(e.currentTarget.value);
                setBirthday(`${birthYear}-${e.currentTarget.value}-${birthDate}`);
                break;
            case "birth_date":
                setBirthDate(e.currentTarget.value);
                setBirthday(`${birthYear}-${birthMonth}-${e.currentTarget.value}`);
                break;
            case "pet_sex":
                setPetSex(e.currentTarget.value);
                break;
        }
    }

    const onFileSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {

        e.preventDefault();

        const req: PpPetControllerCreateRequest = {
            pp_user_id: null,
            karte_id: karteId,
            pet_name: petName,
            animal_type: animalType,
            accuracy_of_pet_birthday: accuracyOfPetBirthday,
            birthday: birthday,
            breed_id: breedId,
            breed_of_parents_1: breedOfParents1,
            breed_of_parents_2: breedOfParents2,
            pet_sex: petSex,
            icon: icon,
            is_only_validate: true,
        };

        setShowSpinner(true);

        let promise;

        if (isEdit) {
            // ---------------------------------------------------- 編集時
            promise = adminPpPetApi.v1AdminPpPetEditIdPost(String(ppPet?.id), req);

        } else {
            // ---------------------------------------------------- 新規登録時
            promise = adminPpPetApi.v1AdminPpPetCreatePost(req);

        }

        promise
            .then(() => {
                setErrors({});

                // セッションストレージに一時保存
                sessionStorage.setItem("pet_create_info", JSON.stringify(req));

                const imgSaved = sessionStorage.getItem("pet_img_info");

                console.log("imgSaved", imgSaved);

                // 画像のレスポンスも展開
                let imgRes: AssetsControllerUploadImageResponse | null = null;
                if (imgSaved) {
                    imgRes = JSON.parse(imgSaved) as AssetsControllerUploadImageResponse;
                }

                // コールバック実行
                afterValidate(req, imgRes);

            })
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setDangerMessage("ご入力内容にエラーがありました。");
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });

    };

    // 画像アップロード
    const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {

        e.preventDefault();

        const files = e.currentTarget.files;

        if (!files) {
            return;
        }

        if (files.length === 0) {
            setDangerMessage("ファイルが未選択です。");
            return;
        }

        const file = files.item(0);

        if (!file) {
            return;
        }

        const allowedMimeTypes = [
            "image/jpeg",
            "image/gif",
            "image/png",
            "image/heic",
        ];

        if (allowedMimeTypes.indexOf(file.type) === -1) {
            setDangerMessage(`ファイル形式が不正です。 ${file.type} はアップロードできません。`);
            return;
        }

        // ファイルをステートに保持（編集モーダルを開くため）
        setFile(file);
    };

    const onImageComplete = (croppedImg: File | null | undefined) => {

        if (!croppedImg) {
            return;
        }

        setShowSpinner(true);

        assetsApi
            .v1UploadImagePost(croppedImg)
            .then(({data}) => {
                setIcon(data.path ?? "");

                // セッションストレージに追加
                sessionStorage.setItem("pet_img_info", JSON.stringify(data));
                setViewPath(data.view_path ?? "");
                setFilename(data.original_filename ?? "");

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const isMix = (breedId: number): boolean => {

        let isMix = false;

        ppMstBreeds.forEach((d) => {
            if (d.id === breedId && d.breed_name?.toLowerCase().indexOf("mix") !== -1) {
                isMix = true;
            }
        });

        return isMix;
    };

    const onBreedChange = (name: string, id: number | null) => {

        switch (name) {
            case "breed_id":
                setBreedId(id ?? 0);
                if (!isMix(Number(id))) {
                    setBreedOfParents1(null);
                    setBreedOfParents2(null);
                }
                break;
            case "breed_of_parents_1":
                setBreedOfParents1(id);
                break;
            case "breed_of_parents_2":
                setBreedOfParents2(id);
                break;
        }
    };

    // 画像削除
    const onDeleteImg = (e: React.MouseEvent<HTMLDivElement>): void => {
        e.preventDefault();
        setIcon("");
        setViewPath("");
        setFilename("");
        sessionStorage.removeItem("pet_img_info");
        onDeletePetImg();
    };

    return <StyledPetCreate onSubmit={onSubmit}>

        <div className="box">

            <H3 title={h3Text} subtitle="pet"/>

            <div className="form-wrapper">

                <div className="form-group">
                    <label>カルテID</label>
                    <div>
                        <input type="text" name="karte_id" maxLength={100} value={karteId} onChange={onChange} placeholder={"例：あ-1234-P1"}/>
                        <ErrorMessage message={errors["karte_id"]}/>
                        <div className="hint-balloon">
                            ※カルテIDをお持ちのペットの場合ご入力ください。
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label className="req">お名前</label>
                    <div>
                        <input type="text" name="pet_name" maxLength={50} value={petName} onChange={onChange} placeholder={"例：にゃんにゃん"}/>
                        <ErrorMessage message={errors["pet_name"]}/>
                    </div>
                </div>

                <div className="form-group check-area">
                    <label className="req">種別</label>
                    <div>
                        {hasTest && <>
                            {animalType === SpeciesDog && "犬"}
                            {animalType === SpeciesCat && "猫"}
                            （種別は変更できません）
                        </>}
                        {!hasTest && <div className="check">
                            <label>
                                <input type="radio" name="animal_type" value={SpeciesDog} onChange={onChange} checked={animalType === SpeciesDog} disabled={hasTest}/>
                                犬
                            </label>
                            <label>
                                <input type="radio" name="animal_type" value={SpeciesCat} onChange={onChange} checked={animalType === SpeciesCat} disabled={hasTest}/>
                                猫
                            </label>
                        </div>}
                        <ErrorMessage message={errors["animal_type"]}/>
                    </div>
                </div>

                <div className="form-group">
                    <label className="req">品種</label>
                    <div>
                        <BreedInput
                            name="breed_id"
                            animalType={animalType}
                            onChange={onBreedChange}
                            ppMstBreeds={ppMstBreeds}
                            value={breedId}
                        />

                        <ErrorMessage message={errors["breed_id"]}/>
                    </div>
                </div>

                {isMix(breedId) && <div className="form-group">
                    <label className="req">両親の品種1</label>
                    <div>
                        <BreedInput
                            name="breed_of_parents_1"
                            animalType={animalType}
                            onChange={onBreedChange}
                            ppMstBreeds={ppMstBreeds}
                            value={breedOfParents1}
                        />

                        <ErrorMessage message={errors["breed_of_parents_1"]}/>
                    </div>
                </div>}

                {isMix(breedId) && <div className="form-group">
                    <label className="req">両親の品種2</label>
                    <div>
                        <BreedInput
                            name="breed_of_parents_2"
                            animalType={animalType}
                            onChange={onBreedChange}
                            ppMstBreeds={ppMstBreeds}
                            value={breedOfParents2}
                        />

                        <ErrorMessage message={errors["breed_of_parents_2"]}/>
                    </div>
                </div>}

                <div className="form-group check-area">
                    <label className="req">生年月日</label>
                    <div>
                        <div className="check" style={{marginBottom: "15px"}}>
                            <label>
                                <input type="radio" name="accuracy_of_pet_birthday" value={BirthTypeConfirmed} onChange={onChange} checked={accuracyOfPetBirthday === BirthTypeConfirmed}/>
                                確定
                            </label>
                            <label>
                                <input type="radio" name="accuracy_of_pet_birthday" value={BirthTypeEstimated} onChange={onChange} checked={accuracyOfPetBirthday === BirthTypeEstimated}/>
                                推定
                            </label>
                            <label>
                                <input type="radio" name="accuracy_of_pet_birthday" value={BirthTypeUnknown} onChange={onChange} checked={accuracyOfPetBirthday === BirthTypeUnknown}/>
                                不明
                            </label>
                        </div>
                        <ErrorMessage message={errors["accuracy_of_pet_birthday"]}/>

                        <div className="inline">
                            <select name="birth_year" value={birthYear} onChange={onChange} disabled={!isBirthdayActive}>
                                <option value="">----</option>
                                {GetYearList(false).map((d, i) => {
                                    return <option key={i} value={d}>{d}</option>
                                })}
                            </select>
                            <span>年</span>
                            <select name="birth_month" value={birthMonth} onChange={onChange} disabled={!isBirthdayActive}>
                                <option value="">----</option>
                                {GetMonthList().map((d, i) => {
                                    return <option key={i} value={d}>{d}</option>
                                })}                            </select>
                            <span>月</span>
                            <select name="birth_date" value={birthDate} onChange={onChange} disabled={!isBirthdayActive}>
                                <option value="">----</option>
                                {GetDateList().map((d, i) => {
                                    return <option key={i} value={d}>{d}</option>
                                })}
                            </select>
                            <span>日</span>

                        </div>
                        <ErrorMessage message={errors["birthday"]}/>
                    </div>

                </div>


                <div className="form-group check-area">
                    <label className="req">性別</label>
                    <div>
                        <div className="check">
                            <label>
                                <input type="radio" name="pet_sex" value="1" onChange={onChange} checked={petSex === "1"}/>
                                オス
                            </label>
                            <label>
                                <input type="radio" name="pet_sex" value="2" onChange={onChange} checked={petSex === "2"}/>
                                メス
                            </label>
                        </div>
                        <ErrorMessage message={errors["pet_sex"]}/>
                    </div>
                </div>

                <div className="form-group check-area">
                    <label>アイコン</label>
                    <div>
                        <Button type="button" color={ButtonColor.Green} size={ButtonSize.Small} icon={ButtonIcon.Arrow} onClick={onFileSelect}>画像をアップロード</Button>
                        <input type="file" ref={fileInputRef} onChange={onUpload}/>

                        {filename && <div className="filename">
                            <span onClick={() => setIsModalImg(true)}>{filename}（クリックして確認）</span>
                            <div className="btn-delete" onClick={onDeleteImg}>&times; 画像を削除</div>
                        </div>}

                        <div className="hint">
                            * アップロード可能な画像形式：jpg、png、gif<br/>
                            * アップロードする画像は5MB以下でお願いいたします。<br/>
                            * 画像をアップロードしない場合、アイコンはデフォルトの画像になります。<br/>
                        </div>

                        <ErrorMessage message={errors["img"]}/>
                    </div>
                </div>

            </div>

        </div>

        <div className="btn-area">
            <Button color={ButtonColor.Gray} icon={ButtonIcon.Arrow} type="button" onClick={onBack}>{backBtnText}</Button>
            <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>入力内容の確認へ</Button>
        </div>


        {isModalImg && viewPath && <PetModal img={viewPath} onClose={() => setIsModalImg(false)}/>}

        {file && <ObjectIconCropModal file={file} getCroppedPetIcon={onImageComplete}/>}

    </StyledPetCreate>


}

const StyledPetCreate = styled(StyledForm)`

    input[type=file] {
        display: none;
    }

    .hint-balloon {
        left: 330px !important;
        top: 0 !important;
        color: #D93E4C;
        font-size: 12px !important;
        padding: 10px !important;
        width: 350px !important;
        flex: 1;
        position: absolute !important;
    }

`;