import {Layout} from "../common/Layout";
import {NewForm} from "./NewForm";
import {H3} from "../common/Headline";
import React, {useContext, useEffect, useState} from "react";
import {PpTestKitControllerIndexRequest, PpteststatusStatus, TypesPpTestKitData} from "../../generated";
import {AppContext} from "../../contexts/AppContext";
import {adminPpTestKitApi} from "../../api/Api";
import {Table} from "../common/Table";
import {CheckIsReceptionOK, GetPlatePositions, Zerofill} from "../../utility/Utility";
import styled from "styled-components";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "../common/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {AddForm} from "./AddForm";
import {AddRegister} from "./AddRegister";

enum InputType {
    New = "new",
    Add = "add",
    None = "none",
}

export const ReceptionInput = () => {

        const {setShowSpinner, setDangerMessage} = useContext(AppContext);
        const [ppTestKits, setPpTestKits] = useState<TypesPpTestKitData[]>([]);
        const [platePositions, setPlatePositions] = useState<{ [key: string]: string }>({});
        const [searchError, setSearchError] = useState<string>("");
        const [activeRecordNum, setActiveRecordNum] = useState<number>(0);
        const [realActiveRecordNum, setRealActiveRecordNum] = useState<number>(0);
        const sessionKey = "reception_session_key";
        const [isAllFilled, setIsAllFilled] = useState<boolean>(false);
        const [inputType, setInputType] = useState<InputType>(InputType.None);
        const [previousSampleIds, setPreviousSampleIds] = useState<string[]>([]); // 追加受付時の以前の受付済み検体ID
        const [isAddModal, setIsAddModal] = useState<boolean>(false);
        const [flowcellId, setFlowcellId] = useState<string>("");
        const [wellplateId, setWellplateId] = useState<string>("");
        const location = useLocation();
        const navigate = useNavigate();

        useEffect(() => {

            let inputType: InputType = InputType.None;

            switch (location.pathname) {
                case "/reception/new":
                    inputType = InputType.New;
                    setInputType(InputType.New);
                    break;
                case "/reception/add":
                    inputType = InputType.Add;
                    setInputType(InputType.Add);
                    break;
            }

            const old = getOldSampleIds(inputType);
            if (old.length > 0) {
                onSearch(null);
            }

        }, [location, inputType]);

        useEffect(() => {
            if (flowcellId !== "") {
                onSearch(null);
            }
        }, [flowcellId]);

        // 検体IDの配列を返す
        const getOldSampleIds = (inputType: InputType): string[] => {
            const old = sessionStorage.getItem(String(inputType) + sessionKey);
            return JSON.parse(old ?? "[]");
        };

        // 検索処理
        const onSearch = (_sampleIds: string[] | null) => {

            const sampleIds = getOldSampleIds(inputType);

            if (_sampleIds !== null && _sampleIds.length > 0) {

                _sampleIds.forEach((sampleId) => {
                    if(!sampleIds.includes(sampleId)) {
                        sampleIds.push(sampleId);
                    }
                });
            }

            if (sampleIds.length === 0) {
                setIsAllFilled(false);
                setPpTestKits([]);
                setActiveRecordNum(0);
                return;
            }

            setShowSpinner(true);

            const req: PpTestKitControllerIndexRequest = {
                size: 500,
                page: 1,
                sample_ids: sampleIds,
                is_confirmed: false, // 申し込みのないデータも含める
                has_medic_inst: true, // 病院の紐付きがあるものに限定する
            };

            adminPpTestKitApi.v1AdminPpTestKitPost(req)
                .then(({data}) => {
                    if (data.pp_test_kits.length !== sampleIds.length) {
                        // リクエストとした件数と一致しない場合、データが見つからなかったケース。
                        // 何もしないで終了する（A-04-011 ③-2）
                        // https://docs.google.com/spreadsheets/d/1JBOHIe0yBBrXF-vTX8L8Kp8-vCqH0WslwK_7Aa3AmBs/edit#gid=1030842939
                        setSearchError("受付対象外の検体です");
                        return;
                    }

                    const ppTestKits: TypesPpTestKitData[] = [];
                    const platePositions: { [key: string]: string } = {};

                    const positionMst = GetPlatePositions();

                    let activeRecordNum = 0;
                    let realActiveRecordNum = 0;

                    let o = 0;
                    // 指定した検体IDの順番に並び替える
                    sampleIds.forEach((sampleId) => {
                        for (let i = 0; i <= data.pp_test_kits.length; i++) {

                            if (data.pp_test_kits[i].sample_id === sampleId) {

                                ppTestKits.push(data.pp_test_kits[i]);

                                const isReceptionOK = CheckIsReceptionOK(data.pp_test_kits[i].status);

                                if (isReceptionOK || (inputType === InputType.Add && previousSampleIds.includes(data.pp_test_kits[i].sample_id))) {
                                    // 追加受付時は以前受付済みなら採番する
                                    // 受付対象のみ採番する
                                    platePositions[sampleId] = positionMst[o];
                                    activeRecordNum++;
                                    o++;
                                } else {
                                    // 受付対象でない場合
                                    platePositions[sampleId] = "";
                                }

                                if (isReceptionOK) {
                                    // 追加受付時の追加可能検体数を保持
                                    realActiveRecordNum++;
                                }
                                break;
                            }
                        }
                    });

                    setIsAllFilled(o >= positionMst.length);
                    setPpTestKits(ppTestKits);
                    setPlatePositions(platePositions);
                    setActiveRecordNum(activeRecordNum);
                    setRealActiveRecordNum(realActiveRecordNum);

                    // 正常に登録されている場合、検体IDをセッションに保存する
                    sessionStorage.setItem(String(inputType) + sessionKey, JSON.stringify(sampleIds));

                    // 若干時間差（ステート更新を待って）で末尾にスクロール
                    setTimeout(() => {
                        const tableWrapper = document.getElementsByClassName("table-wrapper");

                        if (tableWrapper) {
                            // テーブルをスクロール
                            tableWrapper[0].scrollTo(0, tableWrapper[0].scrollHeight);
                        }
                    }, 50);

                })
                .catch((err) => {
                    if (err.response.data.message) {
                        setDangerMessage(err.response.data.message);
                    } else {
                        setDangerMessage("通信時にエラーが発生しました");
                    }
                })
                .finally(() => {
                    setShowSpinner(false);
                });
        }

        // 一覧に表示するステータス文字列を返す
        const getStatusStr = (ppTestKit: TypesPpTestKitData) => {

            if (!ppTestKit.status) {
                return "-";
            }

            if ((ppTestKit.status === PpteststatusStatus.Status00 || ppTestKit.status === PpteststatusStatus.Status01) && !ppTestKit.re_test) {
                // 4-1.「検査未依頼」または「検査未受付」かつ再検査フラグが未設定の場合、「新規受付」を設定
                return "新規受付";
            }

            if ((ppTestKit.status === PpteststatusStatus.Status00 || ppTestKit.status === PpteststatusStatus.Status01) && ppTestKit.re_test) {
                // 4-2.「検査未依頼」または「検査未受付」で再検査フラグありの場合、再検査受付
                return "再検査受付";
            }

            if (ppTestKit.status === PpteststatusStatus.Status02 || ppTestKit.status === PpteststatusStatus.Status12 || ppTestKit.status === PpteststatusStatus.Status22) {
                // 4-3.検体の検査状況が「検査中」「再検査中」「強制検査中」のいずれかの場合、「受付済」を設定
                return "受付済";
            }

            if (ppTestKit.status === PpteststatusStatus.Status50) {
                // 4-4.検体の検査状況が「検査完了」の場合、「検査完了」を設定
                return "検査完了";
            }

            return `不明なステータス（${ppTestKit.status}）`;
        };

        const onClear = () => {
            sessionStorage.removeItem(String(inputType) + sessionKey);
            setPpTestKits([]);
            setIsAllFilled(false);
            setActiveRecordNum(0);
            setFlowcellId("");
            setWellplateId("");
            setPreviousSampleIds([]);
        }

        const onClearLast = () => {
            const sessData = sessionStorage.getItem(String(inputType) + sessionKey);

            if (!sessData) {
                // ないことはないが、何もしない
                return;
            }

            const sampleIds = JSON.parse(sessData);
            sampleIds.pop();
            sessionStorage.setItem(String(inputType) + sessionKey, JSON.stringify(sampleIds));

            const next: TypesPpTestKitData[] = [...ppTestKits];
            next.pop();
            setPpTestKits(next);
            onSearch(null);
        };

        // 追加受付時、Flow Cell検索成功コールバック
        const onFlowcellIDSuccess = (sampleIds: string[], flowcellId: string, wellplateId: string) => {

            // セッションストレージにセット
            sessionStorage.setItem(String(inputType) + sessionKey, JSON.stringify(sampleIds));

            // 以前受付した検体リストを保存
            setActiveRecordNum(0);
            setIsAllFilled(false);
            setRealActiveRecordNum(0);
            setPreviousSampleIds(sampleIds);
            setFlowcellId(flowcellId);
            setWellplateId(wellplateId);
        };

        const toggleAddConfirm = () => {
            // 追加確認モーダル表示
            setIsAddModal(!isAddModal);
        }

        const onAddComplete = () => {
            sessionStorage.removeItem(String(inputType) + sessionKey);
            sessionStorage.removeItem("add_flowcell_id");
            setFlowcellId("");
            setWellplateId("");
            setPreviousSampleIds([]);

            navigate("/reception");
        }

        return <Layout title="検査受付">

            {inputType === InputType.New && <NewForm
                sessionKey={inputType + sessionKey}
                isAllFilled={isAllFilled}
                onSearch={onSearch}
                onClear={onClear}
                error={searchError}
                activeRecordNum={activeRecordNum}
            />}

            {inputType === InputType.Add && <AddForm
                sessionKey={inputType + sessionKey}
                isAllFilled={isAllFilled}
                onSearch={onSearch}
                onClear={onClear}
                error={searchError}
                activeRecordNum={activeRecordNum}
                onFlowcellIDSuccess={onFlowcellIDSuccess}
                realActiveRecordNum={realActiveRecordNum}
                onAddConfirm={toggleAddConfirm}
            />}

            <StyledReceptionIndex className="box">

                <H3 title="検体情報一覧" subtitle="list"/>

                <Table>

                    <thead>
                    <tr>
                        <th>No.</th>
                        <th>検体ID</th>
                        <th>検体位置</th>
                        <th>病院名</th>
                        <th>検査申込有無</th>
                        <th>ステータス</th>
                    </tr>
                    </thead>

                    <tbody>
                    {ppTestKits.map((d, i) => {

                        // 受付対象でない場合は行をグレーに網掛けする
                        const isGray = !CheckIsReceptionOK(d.status);

                        const cls: string[] = [];
                        if (!d.is_confirmed) {
                            cls.push("not-yet");
                        }

                        let showClearButton = i === ppTestKits.length - 1;

                        if (inputType === InputType.Add && previousSampleIds.indexOf(d.sample_id) !== -1) {
                            // 追加受付時、以前受付した検体IDはクリアボタンを表示しない
                            showClearButton = false;
                        }

                        if (isGray) {
                            return <tr key={`pp-test-kit-${d.sample_id}`} className="completed">
                                <td>
                                    {showClearButton && <Button color={ButtonColor.Orange} icon={ButtonIcon.Close} size={ButtonSize.Small} onClick={onClearLast}>クリア</Button>}
                                    {Zerofill(i + 1, 2)}
                                </td>
                                <td>{d.sample_id}</td>
                                <td>{platePositions[d.sample_id] ? platePositions[d.sample_id] : ""}</td>
                                <td/>
                                <td/>
                                <td>{getStatusStr(d)}</td>
                            </tr>

                        } else {

                            return <tr key={`pp-test-kit-${d.sample_id}`} className={cls.join(" ")}>
                                <td>
                                    {showClearButton && <Button color={ButtonColor.Orange} icon={ButtonIcon.Close} size={ButtonSize.Small} onClick={onClearLast}>クリア</Button>}
                                    {Zerofill(i + 1, 2)}
                                </td>
                                <td>{d.sample_id}</td>
                                <td>{platePositions[d.sample_id]}</td>
                                <td>{d.medic_inst_name ?? "-"}</td>
                                <td>{d.is_confirmed ? "あり" : "なし"}</td>
                                <td>{getStatusStr(d)}</td>
                            </tr>
                        }

                    })}
                    </tbody>

                </Table>

                {isAddModal && <AddRegister
                    wellplateId={wellplateId}
                    flowcellId={flowcellId}
                    toggleAddConfirm={toggleAddConfirm}
                    sampleIds={getOldSampleIds(inputType)}
                    previousSampleIds={previousSampleIds}
                    onComplete={onAddComplete}
                />}

            </StyledReceptionIndex>

        </Layout>

    }
;


const StyledReceptionIndex = styled.div`

  .table-wrapper {
    // 高さを固定
    height: 450px;
    overflow-y: scroll;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    position: relative;
    scroll-behavior: smooth;
  }

  table {

    thead {
      position: sticky;
      top: 0;
      left: 0;
    }

    tbody {
      tr.not-yet {
        td:nth-child(5) {
          background-color: rgba(217, 62, 76, 0.1);
          color: #D93E4C;
        }
      }

      tr {
        td:first-child {
          width: 150px;
          text-align: center;

          button {
            margin-right: 10px;
          }
        }
      }

      tr.completed {
        td {
          background-color: #E5E5E5 !important;
          color: #828282;
        }
      }
    }
  }

`;