import styled from "styled-components";

interface Props {
    total: number;
}

export const CounterSimple = ({total}: Props) => {

    return <StyledCounterSimple className="counter">
        <span>全</span>
        <strong>{total}</strong>
        <span>件</span>
    </StyledCounterSimple>

};

const StyledCounterSimple = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;


  span {
    color: #333;
    font-size: 12px;
    margin: 0;
    line-height: 0;
  }

  strong {
    font-size: 24px;
    font-weight: normal;
    margin: 0 3px;
    line-height: 0;
  }

`;