import React, {useContext, useEffect, useState} from "react";
import {BirthTypeUnknown, CreateJaYmd, GetAnimalType, GetBirthdayType, GetPetGender} from "../../utility/Utility";
import {AssetsControllerUploadImageResponse, PpMstBreedModelGetListResponse, PpPetControllerCreateRequest} from "../../generated";
import {PetModal} from "../common/PetModal";
import {AppContext} from "../../contexts/AppContext";
import {ppMstBreedApi} from "../../api/Api";

interface Props extends React.PropsWithChildren {
    ppPet: PpPetControllerCreateRequest;
    petIcon: AssetsControllerUploadImageResponse | null;
}

export const PpPetConfirm = ({ppPet, petIcon}: Props) => {

    const [isModalImg, setIsModalImg] = React.useState<boolean>(false);
    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppMstBreeds, setPpMstBreeds] = useState<PpMstBreedModelGetListResponse[]>([]);

    useEffect(() => {
        getBreeds();
    }, []);

    // 品種取得
    const getBreeds = () => {

        setShowSpinner(true);

        ppMstBreedApi.v1PpMstBreedListGet()
            .then(({data}) => {
                setPpMstBreeds(data);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });

    }

    const getBreedName = (id: number | null): string => {
        if (id) {
            const breed = ppMstBreeds.find((breed) => breed.id === id);
            if (breed) {
                return breed.breed_name ?? "-";
            }
        }

        return "-";
    };

    return <React.Fragment>

        <div className="form-wrapper">

            <div className="form-group center">
                <label>カルテID</label>
                <div>
                    {ppPet.karte_id ? ppPet.karte_id : "カルテIDなし"}
                </div>
            </div>

            <div className="form-group center">
                <label>お名前</label>
                <div>
                    {ppPet.pet_name}
                </div>
            </div>

            <div className="form-group center">
                <label>種別</label>
                <div>
                    {GetAnimalType(ppPet.animal_type ?? "")}
                </div>
            </div>

            <div className="form-group center">
                <label>品種</label>
                <div>
                    {getBreedName(ppPet.breed_id ?? null)}
                </div>
            </div>

            {ppPet.breed_of_parents_1 && <div className="form-group center">
                <label>両親の品種1</label>
                <div>
                    {getBreedName(ppPet.breed_of_parents_1 ?? null)}
                </div>
            </div>}

            {ppPet.breed_of_parents_2 && <div className="form-group center">
                <label>両親の品種2</label>
                <div>
                    {getBreedName(ppPet.breed_of_parents_2 ?? null)}
                </div>
            </div>}


            <div className="form-group center">
                <label>生年月日</label>
                <div>
                    {ppPet.birthday && CreateJaYmd(ppPet.birthday ?? "")}
                    {ppPet.accuracy_of_pet_birthday !== BirthTypeUnknown && `（${GetBirthdayType(ppPet.accuracy_of_pet_birthday ?? "")}）`}
                    {ppPet.accuracy_of_pet_birthday === BirthTypeUnknown && "不明"}
                </div>
            </div>

            <div className="form-group center">
                <label>性別</label>
                <div>
                    {GetPetGender(ppPet.pet_sex ?? "")}
                </div>
            </div>

            <div className="form-group center">
                <label>アイコン</label>
                <div>
                    {petIcon && <div className="filename"><span onClick={() => setIsModalImg(true)}>{petIcon.original_filename}（クリックして確認）</span></div>}
                    {!petIcon && <div className="upload-none">未設定</div>}
                </div>
            </div>

        </div>

        {isModalImg && petIcon && <PetModal img={petIcon.view_path ?? ""} onClose={() => setIsModalImg(false)}/>}

    </React.Fragment>
}
