/* tslint:disable */
/* eslint-disable */
/**
 * PiTPET API
 * PiTPET APIに関する仕様書 管理画面、フロントサイド、それぞれで使用するAPIです。<br> 管理画面は、/v1/admin/ から始まります。<br> フロントサイドは、/v1/front から始まります。<br> それ以外のルーティングは認証がかけられていない公開状態のAPIです。<br> 認証が必要なAPIではリクエストヘッダーに `Authorization: Bearer [ログイン時に発行されたトークン]` を指定してください。<br> なお、Basic認証がかけられているSTG環境では`Authorization`ヘッダーが重複してしまい正常に認証ができません。<br> そのため、STG環境では`X-API-KEY: [ログイン時に発行されたトークン]`ヘッダーを指定してください。<br> 2023/11/09追記、PITPET_SYS_2023-176 の自動ログアウト仕様追加により、管理画面はCookie認証に変更しています。<br>Cookieはログイン時に発行されているので、そのまま送れば認証されます。<br> なお、各認証を経由したAPIにおいては、`Sephirahモジュール`の共通の認証結果、`sephirahAuthCertificate`プロパティが追加で返却されます。<br> `sephirahAuthCertificate`のプロパティに関しては、admin_authのログイン認証のレスポンスを参照してください。<br>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AccessTokenModelLoginType = {
    LoginTypePpMstAdmin: 1,
    LoginTypePpMstPartner: 2,
    LoginTypePpUser: 3,
    LoginTypeAgency: 4
} as const;

export type AccessTokenModelLoginType = typeof AccessTokenModelLoginType[keyof typeof AccessTokenModelLoginType];


/**
 * 
 * @export
 * @enum {string}
 */

export const AccesstokenRole = {
    RoleOperator: 'operator',
    RoleLaboratory: 'laboratory',
    RoleMedical: 'medical',
    RolePartner: 'partner',
    RoleUser: 'user',
    RoleAgency: 'agency',
    RoleCs: 'cs',
    RoleMaster: 'master'
} as const;

export type AccesstokenRole = typeof AccesstokenRole[keyof typeof AccesstokenRole];


/**
 * 
 * @export
 * @interface AdminAuthControllerChangePasswordRequest
 */
export interface AdminAuthControllerChangePasswordRequest {
    /**
     * 新しいパスワード（確認用）
     * @type {string}
     * @memberof AdminAuthControllerChangePasswordRequest
     */
    'confirmation_password'?: string;
    /**
     * 現在のパスワード
     * @type {string}
     * @memberof AdminAuthControllerChangePasswordRequest
     */
    'old_password'?: string;
    /**
     * 新しいパスワード
     * @type {string}
     * @memberof AdminAuthControllerChangePasswordRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface AdminAuthControllerForgotRequest
 */
export interface AdminAuthControllerForgotRequest {
    /**
     * メールアドレス
     * @type {string}
     * @memberof AdminAuthControllerForgotRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface AdminAuthControllerLoginRequest
 */
export interface AdminAuthControllerLoginRequest {
    /**
     * メールアドレス
     * @type {string}
     * @memberof AdminAuthControllerLoginRequest
     */
    'email'?: string;
    /**
     * 平文パスワード
     * @type {string}
     * @memberof AdminAuthControllerLoginRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface AdminAuthControllerLoginResponse
 */
export interface AdminAuthControllerLoginResponse {
    /**
     * ログイン種別 1 or 2
     * @type {AccessTokenModelLoginType}
     * @memberof AdminAuthControllerLoginResponse
     */
    'login_type': AccessTokenModelLoginType;
    /**
     * 
     * @type {AdminAuthControllerLoginResponseSephirahAuthCertificate}
     * @memberof AdminAuthControllerLoginResponse
     */
    'sephirahAuthCertificate': AdminAuthControllerLoginResponseSephirahAuthCertificate;
}
/**
 * Sephirah認証結果
 * @export
 * @interface AdminAuthControllerLoginResponseSephirahAuthCertificate
 */
export interface AdminAuthControllerLoginResponseSephirahAuthCertificate {
    /**
     * アクセストークン セッションストレージに保存し、以後`X-Sp-Api-Token` としてリクエストヘッダーに設定する
     * @type {string}
     * @memberof AdminAuthControllerLoginResponseSephirahAuthCertificate
     */
    'apiToken': string;
    /**
     * 認証分類 1:一般ユーザー 2:病院・提携機関 3:運営(PROUMED)
     * @type {string}
     * @memberof AdminAuthControllerLoginResponseSephirahAuthCertificate
     */
    'authGroup': string;
    /**
     * 結果 0:OK、1:ログインエラー、2:sessionタイムアウトエラー、3:電子署名不正なりすまし、4:トークン不正、5:二重ログインエラー、9:システムエラー
     * @type {string}
     * @memberof AdminAuthControllerLoginResponseSephirahAuthCertificate
     */
    'result': string;
    /**
     * トークンキー セッションストレージに保存し、以後`X-Sp-Token-Key` としてリクエストヘッダーに設定する
     * @type {string}
     * @memberof AdminAuthControllerLoginResponseSephirahAuthCertificate
     */
    'tokenKey': string;
}
/**
 * 
 * @export
 * @interface AdminAuthControllerMeResponse
 */
export interface AdminAuthControllerMeResponse {
    /**
     * ログイン種別(管理画面では1 or 2 が入る) 1:運営ユーザー 2:提携機関ユーザー
     * @type {AccessTokenModelLoginType}
     * @memberof AdminAuthControllerMeResponse
     */
    'login_type': AccessTokenModelLoginType;
    /**
     * 
     * @type {AdminAuthControllerMeResponsePpMstUserAdmin}
     * @memberof AdminAuthControllerMeResponse
     */
    'pp_mst_user_admin': AdminAuthControllerMeResponsePpMstUserAdmin;
    /**
     * 
     * @type {AdminAuthControllerMeResponsePpMstUserPartner}
     * @memberof AdminAuthControllerMeResponse
     */
    'pp_mst_user_partner': AdminAuthControllerMeResponsePpMstUserPartner;
    /**
     * ロール
     * @type {ConstsRole}
     * @memberof AdminAuthControllerMeResponse
     */
    'role': ConstsRole;
}
/**
 * 運営管理ユーザー
 * @export
 * @interface AdminAuthControllerMeResponsePpMstUserAdmin
 */
export interface AdminAuthControllerMeResponsePpMstUserAdmin {
    /**
     * 権限
     * @type {string}
     * @memberof AdminAuthControllerMeResponsePpMstUserAdmin
     */
    'access': string;
    /**
     * 所属
     * @type {string}
     * @memberof AdminAuthControllerMeResponsePpMstUserAdmin
     */
    'belongs': string | null;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof AdminAuthControllerMeResponsePpMstUserAdmin
     */
    'created': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof AdminAuthControllerMeResponsePpMstUserAdmin
     */
    'email': string;
    /**
     * 主キー
     * @type {number}
     * @memberof AdminAuthControllerMeResponsePpMstUserAdmin
     */
    'id': number;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof AdminAuthControllerMeResponsePpMstUserAdmin
     */
    'last_login': string | null;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof AdminAuthControllerMeResponsePpMstUserAdmin
     */
    'last_login_ip': string | null;
    /**
     * 名前
     * @type {string}
     * @memberof AdminAuthControllerMeResponsePpMstUserAdmin
     */
    'name': string;
}
/**
 * 提携機関ユーザー
 * @export
 * @interface AdminAuthControllerMeResponsePpMstUserPartner
 */
export interface AdminAuthControllerMeResponsePpMstUserPartner {
    /**
     * 提携法人ユーザーのアクセス権限 1：一般提携法人ユーザー、2：研究機関ユーザー、3：顧問研究者ユーザー
     * @type {number}
     * @memberof AdminAuthControllerMeResponsePpMstUserPartner
     */
    'access': number;
    /**
     * 提携法人ユーザーのアクセス権限の文字列表現 general, lab, advisor
     * @type {TypesAccessType}
     * @memberof AdminAuthControllerMeResponsePpMstUserPartner
     */
    'access_type': TypesAccessType | null;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof AdminAuthControllerMeResponsePpMstUserPartner
     */
    'created': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof AdminAuthControllerMeResponsePpMstUserPartner
     */
    'email': string;
    /**
     * 主キー
     * @type {number}
     * @memberof AdminAuthControllerMeResponsePpMstUserPartner
     */
    'id': number;
    /**
     * 識別コード
     * @type {string}
     * @memberof AdminAuthControllerMeResponsePpMstUserPartner
     */
    'identification_cd': string | null;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof AdminAuthControllerMeResponsePpMstUserPartner
     */
    'last_login': string | null;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof AdminAuthControllerMeResponsePpMstUserPartner
     */
    'last_login_ip': string | null;
    /**
     * 名前
     * @type {string}
     * @memberof AdminAuthControllerMeResponsePpMstUserPartner
     */
    'name': string;
    /**
     * 提携機関区分 1:病院 2:提携法人
     * @type {PpmstuserpartnerPartnerInstDivision}
     * @memberof AdminAuthControllerMeResponsePpMstUserPartner
     */
    'partner_inst_division': PpmstuserpartnerPartnerInstDivision;
    /**
     * 所属機関ID
     * @type {number}
     * @memberof AdminAuthControllerMeResponsePpMstUserPartner
     */
    'partner_inst_id': number;
    /**
     * 所属機関名
     * @type {string}
     * @memberof AdminAuthControllerMeResponsePpMstUserPartner
     */
    'partner_inst_name': string;
    /**
     * 
     * @type {TypesPpMstUserPartnerDataPpMstPartnerInst}
     * @memberof AdminAuthControllerMeResponsePpMstUserPartner
     */
    'pp_mst_partner_inst': TypesPpMstUserPartnerDataPpMstPartnerInst | null;
    /**
     * 
     * @type {TypesPpMstRcmndMethodDataMedicInst}
     * @memberof AdminAuthControllerMeResponsePpMstUserPartner
     */
    'pp_mst_user_medical': TypesPpMstRcmndMethodDataMedicInst | null;
}
/**
 * 
 * @export
 * @interface AdminAuthControllerResetPasswordRequest
 */
export interface AdminAuthControllerResetPasswordRequest {
    /**
     * 新しいパスワード(確認用)
     * @type {string}
     * @memberof AdminAuthControllerResetPasswordRequest
     */
    'confirmation_password'?: string;
    /**
     * 新しいパスワード
     * @type {string}
     * @memberof AdminAuthControllerResetPasswordRequest
     */
    'password'?: string;
    /**
     * リセット用トークン
     * @type {string}
     * @memberof AdminAuthControllerResetPasswordRequest
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface AgencyLoginControllerAdminGenerateRequest
 */
export interface AgencyLoginControllerAdminGenerateRequest {
    /**
     * ログイン対象の会員ID
     * @type {number}
     * @memberof AgencyLoginControllerAdminGenerateRequest
     */
    'pp_user_id'?: number;
}
/**
 * 
 * @export
 * @interface AgencyLoginControllerAdminGenerateResponse
 */
export interface AgencyLoginControllerAdminGenerateResponse {
    /**
     * 代行ログインのためのワンタイムパスワード
     * @type {string}
     * @memberof AgencyLoginControllerAdminGenerateResponse
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface AgencyLoginControllerFrontLoginRequest
 */
export interface AgencyLoginControllerFrontLoginRequest {
    /**
     * クエリストリングで渡されたトークン
     * @type {string}
     * @memberof AgencyLoginControllerFrontLoginRequest
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface AgencyLoginControllerFrontLoginResponse
 */
export interface AgencyLoginControllerFrontLoginResponse {
    /**
     * ログイン種別 4固定
     * @type {AccessTokenModelLoginType}
     * @memberof AgencyLoginControllerFrontLoginResponse
     */
    'login_type': AccessTokenModelLoginType;
    /**
     * 
     * @type {AdminAuthControllerLoginResponseSephirahAuthCertificate}
     * @memberof AgencyLoginControllerFrontLoginResponse
     */
    'sephirahAuthCertificate': AdminAuthControllerLoginResponseSephirahAuthCertificate;
}
/**
 * 
 * @export
 * @interface AssetsControllerUploadImageResponse
 */
export interface AssetsControllerUploadImageResponse {
    /**
     * 元のファイル名
     * @type {string}
     * @memberof AssetsControllerUploadImageResponse
     */
    'original_filename': string;
    /**
     * 保存されたファイルパス（登録時サーバに送信するデータ）
     * @type {string}
     * @memberof AssetsControllerUploadImageResponse
     */
    'path': string;
    /**
     * 閲覧用ファイルパス
     * @type {string}
     * @memberof AssetsControllerUploadImageResponse
     */
    'view_path': string;
}
/**
 * 
 * @export
 * @interface AwscloudwatchLogMessage
 */
export interface AwscloudwatchLogMessage {
    /**
     * IPアドレス
     * @type {string}
     * @memberof AwscloudwatchLogMessage
     */
    'ip_address'?: string;
    /**
     * 論理処理名
     * @type {string}
     * @memberof AwscloudwatchLogMessage
     */
    'logical_name'?: string;
    /**
     * リクエストメソッド
     * @type {string}
     * @memberof AwscloudwatchLogMessage
     */
    'method'?: string;
    /**
     * 運営アカウントID
     * @type {number}
     * @memberof AwscloudwatchLogMessage
     */
    'pp_mst_user_admin_id'?: number;
    /**
     * 提携機関ユーザーID
     * @type {number}
     * @memberof AwscloudwatchLogMessage
     */
    'pp_mst_user_partner_id'?: number;
    /**
     * 会員ID
     * @type {number}
     * @memberof AwscloudwatchLogMessage
     */
    'pp_user_id'?: number;
    /**
     * リクエストURI(ex: /v1/admin/logs)
     * @type {string}
     * @memberof AwscloudwatchLogMessage
     */
    'request_uri'?: string;
    /**
     * レスポンスのステータスコード
     * @type {number}
     * @memberof AwscloudwatchLogMessage
     */
    'status'?: number;
    /**
     * タイムスタンプ
     * @type {number}
     * @memberof AwscloudwatchLogMessage
     */
    'timestamp': number;
    /**
     * ログ種別
     * @type {AwscloudwatchLogMessageType}
     * @memberof AwscloudwatchLogMessage
     */
    'type'?: AwscloudwatchLogMessageType;
    /**
     * 作業者名
     * @type {string}
     * @memberof AwscloudwatchLogMessage
     */
    'user'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AwscloudwatchLogMessageType = {
    TypeRead: 'read',
    TypeCreate: 'create',
    TypeUpdate: 'update',
    TypeDelete: 'delete',
    TypeUnknown: 'unknown'
} as const;

export type AwscloudwatchLogMessageType = typeof AwscloudwatchLogMessageType[keyof typeof AwscloudwatchLogMessageType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ConstsRole = {
    RoleOperator: 'operator',
    RoleLaboratory: 'laboratory',
    RoleMedical: 'medical',
    RolePartner: 'partner',
    RoleUser: 'user',
    RoleAgency: 'agency',
    RoleCs: 'cs',
    RoleMaster: 'master'
} as const;

export type ConstsRole = typeof ConstsRole[keyof typeof ConstsRole];


/**
 * 
 * @export
 * @interface EntAccessToken
 */
export interface EntAccessToken {
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntAccessToken
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntAccessTokenEdges}
     * @memberof EntAccessToken
     */
    'edges'?: EntAccessTokenEdges;
    /**
     * 有効期限
     * @type {string}
     * @memberof EntAccessToken
     */
    'expires'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntAccessToken
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntAccessToken
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntAccessToken
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntAccessToken
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntAccessToken
     */
    'ins_ymd'?: string;
    /**
     * 管理ユーザーID
     * @type {number}
     * @memberof EntAccessToken
     */
    'pp_mst_user_admin_id'?: number;
    /**
     * 提携期間ユーザーID
     * @type {number}
     * @memberof EntAccessToken
     */
    'pp_mst_user_partner_id'?: number;
    /**
     * 会員ID
     * @type {number}
     * @memberof EntAccessToken
     */
    'pp_user_id'?: number;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntAccessToken
     */
    'revision'?: number;
    /**
     * ロール
     * @type {AccesstokenRole}
     * @memberof EntAccessToken
     */
    'role'?: AccesstokenRole;
    /**
     * トークン文字列
     * @type {string}
     * @memberof EntAccessToken
     */
    'token'?: string;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntAccessToken
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntAccessToken
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntAccessToken
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntAccessToken
     */
    'upd_ymd'?: string;
}
/**
 * Edges holds the relations/edges for other nodes in the graph. The values are being populated by the AccessTokenQuery when eager-loading is set.
 * @export
 * @interface EntAccessTokenEdges
 */
export interface EntAccessTokenEdges {
    /**
     * 
     * @type {EntAccessTokenEdgesPpMstUserAdmin}
     * @memberof EntAccessTokenEdges
     */
    'pp_mst_user_admin'?: EntAccessTokenEdgesPpMstUserAdmin;
    /**
     * 
     * @type {EntAccessTokenEdgesPpMstUserPartner}
     * @memberof EntAccessTokenEdges
     */
    'pp_mst_user_partner'?: EntAccessTokenEdgesPpMstUserPartner;
    /**
     * 
     * @type {EntAccessTokenEdgesPpUser}
     * @memberof EntAccessTokenEdges
     */
    'pp_user'?: EntAccessTokenEdgesPpUser;
}
/**
 * PpMstUserAdmin holds the value of the pp_mst_user_admin edge.
 * @export
 * @interface EntAccessTokenEdgesPpMstUserAdmin
 */
export interface EntAccessTokenEdgesPpMstUserAdmin {
    /**
     * アクセス権限
     * @type {PpmstuseradminAccess}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'access'?: PpmstuseradminAccess;
    /**
     * 所属
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'belongs'?: string;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'del'?: boolean;
    /**
     * ログイン電子署名
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'digital_signature'?: string;
    /**
     * 
     * @type {EntPpMstUserAdminEdges}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'edges'?: EntPpMstUserAdminEdges;
    /**
     * メールアドレス
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'email'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'ins_ymd'?: string;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'last_login'?: string;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'last_login_ip'?: string;
    /**
     * 氏名
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'name'?: string;
    /**
     * パスワード（暗号化済み）
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'password'?: string;
    /**
     * パスワードリセットトークン
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'reset_token'?: string;
    /**
     * パスワードリセットトークン有効期限
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'reset_token_expires'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserAdmin
     */
    'upd_ymd'?: string;
}
/**
 * PpMstUserPartner holds the value of the pp_mst_user_partner edge.
 * @export
 * @interface EntAccessTokenEdgesPpMstUserPartner
 */
export interface EntAccessTokenEdgesPpMstUserPartner {
    /**
     * アクセス権限 1：一般提携法人ユーザー、2：研究機関ユーザー、3：顧問研究者ユーザー
     * @type {number}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'access'?: number;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpMstUserPartnerEdges}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'edges'?: EntPpMstUserPartnerEdges;
    /**
     * メールアドレス
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'email'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'id'?: number;
    /**
     * 識別コード
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'identification_cd'?: string;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'ins_ymd'?: string;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'last_login'?: string;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'last_login_ip'?: string;
    /**
     * ユーザー名
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'name'?: string;
    /**
     * 提携機関区分
     * @type {PpmstuserpartnerPartnerInstDivision}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'partner_inst_division'?: PpmstuserpartnerPartnerInstDivision;
    /**
     * 所属機関ID
     * @type {number}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'partner_inst_id'?: number;
    /**
     * パスワード（暗号化済み）
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'password'?: string;
    /**
     * パスワードリセットトークン
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'reset_token'?: string;
    /**
     * パスワードリセットトークン有効期限
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'reset_token_expires'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntAccessTokenEdgesPpMstUserPartner
     */
    'upd_ymd'?: string;
}
/**
 * PpUser holds the value of the pp_user edge.
 * @export
 * @interface EntAccessTokenEdgesPpUser
 */
export interface EntAccessTokenEdgesPpUser {
    /**
     * 生年月日
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'birthday'?: string;
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'building'?: string;
    /**
     * 市区町村
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'city'?: string;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpUserEdges}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'edges'?: EntPpUserEdges;
    /**
     * メールアドレス
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'email'?: string;
    /**
     * 性別
     * @type {PpuserGender}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'gender'?: PpuserGender;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'ins_ymd'?: string;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'last_login'?: string;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'last_login_ip'?: string;
    /**
     * パスワード
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'password'?: string;
    /**
     * 本人認証
     * @type {boolean}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'personal_auth'?: boolean;
    /**
     * 郵便番号
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'postal_code'?: string;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'pref_code'?: string;
    /**
     * 登録病院ID
     * @type {number}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'register_medic_inst_id'?: number;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'revision'?: number;
    /**
     * 丁目・番地
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'street'?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'tel_no'?: string;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'upd_ymd'?: string;
    /**
     * 姓フリガナ
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'user_family_furigana'?: string;
    /**
     * 姓ローマ字
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'user_family_latin'?: string;
    /**
     * 姓
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'user_family_name'?: string;
    /**
     * 名フリガナ
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'user_first_furigana'?: string;
    /**
     * 名ローマ字
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'user_first_latin'?: string;
    /**
     * 名
     * @type {string}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'user_first_name'?: string;
    /**
     * 利用規約同意
     * @type {boolean}
     * @memberof EntAccessTokenEdgesPpUser
     */
    'user_policy_consent'?: boolean;
}
/**
 * 
 * @export
 * @interface EntPpMstBreed
 */
export interface EntPpMstBreed {
    /**
     * ペットの種類
     * @type {PpmstbreedAnimalType}
     * @memberof EntPpMstBreed
     */
    'animal_type'?: PpmstbreedAnimalType;
    /**
     * 品種名
     * @type {string}
     * @memberof EntPpMstBreed
     */
    'breed_name'?: string;
    /**
     * コード番号
     * @type {number}
     * @memberof EntPpMstBreed
     */
    'code_id'?: number;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpMstBreed
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpMstBreedEdges}
     * @memberof EntPpMstBreed
     */
    'edges'?: EntPpMstBreedEdges;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpMstBreed
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpMstBreed
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstBreed
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpMstBreed
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstBreed
     */
    'ins_ymd'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpMstBreed
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpMstBreed
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstBreed
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpMstBreed
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstBreed
     */
    'upd_ymd'?: string;
}
/**
 * Edges holds the relations/edges for other nodes in the graph. The values are being populated by the PpMstBreedQuery when eager-loading is set.
 * @export
 * @interface EntPpMstBreedEdges
 */
export interface EntPpMstBreedEdges {
    /**
     * PpPets holds the value of the pp_pets edge.
     * @type {Array<EntPpPet>}
     * @memberof EntPpMstBreedEdges
     */
    'pp_pets'?: Array<EntPpPet>;
    /**
     * PpPetsParents1 holds the value of the pp_pets_parents_1 edge.
     * @type {Array<EntPpPet>}
     * @memberof EntPpMstBreedEdges
     */
    'pp_pets_parents_1'?: Array<EntPpPet>;
    /**
     * PpPetsParents2 holds the value of the pp_pets_parents_2 edge.
     * @type {Array<EntPpPet>}
     * @memberof EntPpMstBreedEdges
     */
    'pp_pets_parents_2'?: Array<EntPpPet>;
}
/**
 * 
 * @export
 * @interface EntPpMstIdentificationCd
 */
export interface EntPpMstIdentificationCd {
    /**
     * アクセス権限レベル
     * @type {PpmstidentificationcdAuthorityLevel}
     * @memberof EntPpMstIdentificationCd
     */
    'authority_level'?: PpmstidentificationcdAuthorityLevel;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpMstIdentificationCd
     */
    'del'?: boolean;
    /**
     * 提携法人ID
     * @type {string}
     * @memberof EntPpMstIdentificationCd
     */
    'description'?: string;
    /**
     * 
     * @type {EntPpMstIdentificationCdEdges}
     * @memberof EntPpMstIdentificationCd
     */
    'edges'?: EntPpMstIdentificationCdEdges;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpMstIdentificationCd
     */
    'id'?: number;
    /**
     * 識別コード
     * @type {string}
     * @memberof EntPpMstIdentificationCd
     */
    'identification_cd'?: string;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpMstIdentificationCd
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstIdentificationCd
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpMstIdentificationCd
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstIdentificationCd
     */
    'ins_ymd'?: string;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof EntPpMstIdentificationCd
     */
    'partner_inst_id'?: number;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpMstIdentificationCd
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpMstIdentificationCd
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstIdentificationCd
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpMstIdentificationCd
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstIdentificationCd
     */
    'upd_ymd'?: string;
}
/**
 * Edges holds the relations/edges for other nodes in the graph. The values are being populated by the PpMstIdentificationCdQuery when eager-loading is set.
 * @export
 * @interface EntPpMstIdentificationCdEdges
 */
export interface EntPpMstIdentificationCdEdges {
    /**
     * 
     * @type {EntPpMstIdentificationCdEdgesPpMstPartnerInst}
     * @memberof EntPpMstIdentificationCdEdges
     */
    'pp_mst_partner_inst'?: EntPpMstIdentificationCdEdgesPpMstPartnerInst;
}
/**
 * PpMstPartnerInst holds the value of the pp_mst_partner_inst edge.
 * @export
 * @interface EntPpMstIdentificationCdEdgesPpMstPartnerInst
 */
export interface EntPpMstIdentificationCdEdgesPpMstPartnerInst {
    /**
     * 建物・部屋番号
     * @type {string}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'building'?: string;
    /**
     * 市区町村
     * @type {string}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'city'?: string;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpMstPartnerInstEdges}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'edges'?: EntPpMstPartnerInstEdges;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'ins_ymd'?: string;
    /**
     * 提携法人名
     * @type {string}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'partner_inst_name'?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'postal_code'?: string;
    /**
     * 営業取引種別ID
     * @type {number}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'pp_mst_partner_supplier_type_id'?: number;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'pref_code'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'revision'?: number;
    /**
     * 丁目番地号
     * @type {string}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'street'?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'tel_no'?: string;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstIdentificationCdEdgesPpMstPartnerInst
     */
    'upd_ymd'?: string;
}
/**
 * 
 * @export
 * @interface EntPpMstPartnerInst
 */
export interface EntPpMstPartnerInst {
    /**
     * 建物・部屋番号
     * @type {string}
     * @memberof EntPpMstPartnerInst
     */
    'building'?: string;
    /**
     * 市区町村
     * @type {string}
     * @memberof EntPpMstPartnerInst
     */
    'city'?: string;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpMstPartnerInst
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpMstPartnerInstEdges}
     * @memberof EntPpMstPartnerInst
     */
    'edges'?: EntPpMstPartnerInstEdges;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpMstPartnerInst
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpMstPartnerInst
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstPartnerInst
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpMstPartnerInst
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstPartnerInst
     */
    'ins_ymd'?: string;
    /**
     * 提携法人名
     * @type {string}
     * @memberof EntPpMstPartnerInst
     */
    'partner_inst_name'?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof EntPpMstPartnerInst
     */
    'postal_code'?: string;
    /**
     * 営業取引種別ID
     * @type {number}
     * @memberof EntPpMstPartnerInst
     */
    'pp_mst_partner_supplier_type_id'?: number;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof EntPpMstPartnerInst
     */
    'pref_code'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpMstPartnerInst
     */
    'revision'?: number;
    /**
     * 丁目番地号
     * @type {string}
     * @memberof EntPpMstPartnerInst
     */
    'street'?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof EntPpMstPartnerInst
     */
    'tel_no'?: string;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpMstPartnerInst
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstPartnerInst
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpMstPartnerInst
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstPartnerInst
     */
    'upd_ymd'?: string;
}
/**
 * Edges holds the relations/edges for other nodes in the graph. The values are being populated by the PpMstPartnerInstQuery when eager-loading is set.
 * @export
 * @interface EntPpMstPartnerInstEdges
 */
export interface EntPpMstPartnerInstEdges {
    /**
     * PpMstIdentificationCds holds the value of the pp_mst_identification_cds edge.
     * @type {Array<EntPpMstIdentificationCd>}
     * @memberof EntPpMstPartnerInstEdges
     */
    'pp_mst_identification_cds'?: Array<EntPpMstIdentificationCd>;
    /**
     * 
     * @type {EntPpMstPartnerInstEdgesPpMstPartnerSupplierType}
     * @memberof EntPpMstPartnerInstEdges
     */
    'pp_mst_partner_supplier_type'?: EntPpMstPartnerInstEdgesPpMstPartnerSupplierType;
    /**
     * PpMstRcmndMethods holds the value of the pp_mst_rcmnd_methods edge.
     * @type {Array<EntPpMstRcmndMethod>}
     * @memberof EntPpMstPartnerInstEdges
     */
    'pp_mst_rcmnd_methods'?: Array<EntPpMstRcmndMethod>;
    /**
     * PpTestKits holds the value of the pp_test_kits edge.
     * @type {Array<EntPpTestKit>}
     * @memberof EntPpMstPartnerInstEdges
     */
    'pp_test_kits'?: Array<EntPpTestKit>;
}
/**
 * PpMstPartnerSupplierType holds the value of the pp_mst_partner_supplier_type edge.
 * @export
 * @interface EntPpMstPartnerInstEdgesPpMstPartnerSupplierType
 */
export interface EntPpMstPartnerInstEdgesPpMstPartnerSupplierType {
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpMstPartnerInstEdgesPpMstPartnerSupplierType
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpMstPartnerSupplierTypeEdges}
     * @memberof EntPpMstPartnerInstEdgesPpMstPartnerSupplierType
     */
    'edges'?: EntPpMstPartnerSupplierTypeEdges;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpMstPartnerInstEdgesPpMstPartnerSupplierType
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpMstPartnerInstEdgesPpMstPartnerSupplierType
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstPartnerInstEdgesPpMstPartnerSupplierType
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpMstPartnerInstEdgesPpMstPartnerSupplierType
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstPartnerInstEdgesPpMstPartnerSupplierType
     */
    'ins_ymd'?: string;
    /**
     * 営業取引種別名
     * @type {string}
     * @memberof EntPpMstPartnerInstEdgesPpMstPartnerSupplierType
     */
    'partner_supplier_name'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpMstPartnerInstEdgesPpMstPartnerSupplierType
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpMstPartnerInstEdgesPpMstPartnerSupplierType
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstPartnerInstEdgesPpMstPartnerSupplierType
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpMstPartnerInstEdgesPpMstPartnerSupplierType
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstPartnerInstEdgesPpMstPartnerSupplierType
     */
    'upd_ymd'?: string;
}
/**
 * 
 * @export
 * @interface EntPpMstPartnerSupplierType
 */
export interface EntPpMstPartnerSupplierType {
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpMstPartnerSupplierType
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpMstPartnerSupplierTypeEdges}
     * @memberof EntPpMstPartnerSupplierType
     */
    'edges'?: EntPpMstPartnerSupplierTypeEdges;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpMstPartnerSupplierType
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpMstPartnerSupplierType
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstPartnerSupplierType
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpMstPartnerSupplierType
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstPartnerSupplierType
     */
    'ins_ymd'?: string;
    /**
     * 営業取引種別名
     * @type {string}
     * @memberof EntPpMstPartnerSupplierType
     */
    'partner_supplier_name'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpMstPartnerSupplierType
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpMstPartnerSupplierType
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstPartnerSupplierType
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpMstPartnerSupplierType
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstPartnerSupplierType
     */
    'upd_ymd'?: string;
}
/**
 * Edges holds the relations/edges for other nodes in the graph. The values are being populated by the PpMstPartnerSupplierTypeQuery when eager-loading is set.
 * @export
 * @interface EntPpMstPartnerSupplierTypeEdges
 */
export interface EntPpMstPartnerSupplierTypeEdges {
    /**
     * PpMstPartnerInst holds the value of the pp_mst_partner_inst edge.
     * @type {Array<EntPpMstPartnerInst>}
     * @memberof EntPpMstPartnerSupplierTypeEdges
     */
    'pp_mst_partner_inst'?: Array<EntPpMstPartnerInst>;
}
/**
 * 
 * @export
 * @interface EntPpMstRcmndMethod
 */
export interface EntPpMstRcmndMethod {
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpMstRcmndMethod
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpMstRcmndMethodEdges}
     * @memberof EntPpMstRcmndMethod
     */
    'edges'?: EntPpMstRcmndMethodEdges;
    /**
     * 汎用リコメンドタイトル
     * @type {string}
     * @memberof EntPpMstRcmndMethod
     */
    'generic_rcmnd_title'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpMstRcmndMethod
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpMstRcmndMethod
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstRcmndMethod
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpMstRcmndMethod
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstRcmndMethod
     */
    'ins_ymd'?: string;
    /**
     * 病院ID
     * @type {number}
     * @memberof EntPpMstRcmndMethod
     */
    'medic_inst_id'?: number;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof EntPpMstRcmndMethod
     */
    'partner_inst_id'?: number;
    /**
     * リコメンド表示パターン 0：表示なし、1：汎用のみ、2：個別おすすめ商品のみ(横表示)、3：個別おすすめ商品のみ(縦表示)、4：汎用＋個別おすすめ商品(横表示)、5：汎用＋個別おすすめ商品(縦表示)、6：個別おすすめ商品のみ(縦表示＋横表示)、7：フル表示
     * @type {PpmstrcmndmethodRcmndDisplayPattern}
     * @memberof EntPpMstRcmndMethod
     */
    'rcmnd_display_pattern'?: PpmstrcmndmethodRcmndDisplayPattern;
    /**
     * リコメンド方式ラベル
     * @type {string}
     * @memberof EntPpMstRcmndMethod
     */
    'rcmnd_method_label'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpMstRcmndMethod
     */
    'revision'?: number;
    /**
     * SKUコード
     * @type {string}
     * @memberof EntPpMstRcmndMethod
     */
    'sku_code'?: string;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpMstRcmndMethod
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstRcmndMethod
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpMstRcmndMethod
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstRcmndMethod
     */
    'upd_ymd'?: string;
}
/**
 * Edges holds the relations/edges for other nodes in the graph. The values are being populated by the PpMstRcmndMethodQuery when eager-loading is set.
 * @export
 * @interface EntPpMstRcmndMethodEdges
 */
export interface EntPpMstRcmndMethodEdges {
    /**
     * 
     * @type {EntPpMstIdentificationCdEdgesPpMstPartnerInst}
     * @memberof EntPpMstRcmndMethodEdges
     */
    'pp_mst_partner_inst'?: EntPpMstIdentificationCdEdgesPpMstPartnerInst;
    /**
     * PpMstRcmndProductsTitles holds the value of the pp_mst_rcmnd_products_titles edge.
     * @type {Array<EntPpMstRcmndProductsTitle>}
     * @memberof EntPpMstRcmndMethodEdges
     */
    'pp_mst_rcmnd_products_titles'?: Array<EntPpMstRcmndProductsTitle>;
    /**
     * 
     * @type {EntPpMstRcmndMethodEdgesPpMstUserMedical}
     * @memberof EntPpMstRcmndMethodEdges
     */
    'pp_mst_user_medical'?: EntPpMstRcmndMethodEdgesPpMstUserMedical;
}
/**
 * PpMstUserMedical holds the value of the pp_mst_user_medical edge.
 * @export
 * @interface EntPpMstRcmndMethodEdgesPpMstUserMedical
 */
export interface EntPpMstRcmndMethodEdgesPpMstUserMedical {
    /**
     * 建物・部屋番号
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'building'?: string;
    /**
     * 市区町村
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'city'?: string;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpMstUserMedicalEdges}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'edges'?: EntPpMstUserMedicalEdges;
    /**
     * メールアドレス
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'email'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'ins_ymd'?: string;
    /**
     * 病院名
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'medic_inst_name'?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'postal_code'?: string;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'pref_code'?: string;
    /**
     * 検査結果メール送信対象
     * @type {PpmstusermedicalResultSendTarget}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'result_send_target'?: PpmstusermedicalResultSendTarget;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'revision'?: number;
    /**
     * 丁目番地号
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'street'?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'tel_no'?: string;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstRcmndMethodEdgesPpMstUserMedical
     */
    'upd_ymd'?: string;
}
/**
 * 
 * @export
 * @interface EntPpMstRcmndProduct
 */
export interface EntPpMstRcmndProduct {
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpMstRcmndProduct
     */
    'del'?: boolean;
    /**
     * 商品リンク1「商品付き検査キット」ボタンに対応するリンク先
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'ecsite_link_1'?: string;
    /**
     * 商品リンク2「商品のみ」ボタンに対応するリンク先
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'ecsite_link_2'?: string;
    /**
     * 
     * @type {EntPpMstRcmndProductEdges}
     * @memberof EntPpMstRcmndProduct
     */
    'edges'?: EntPpMstRcmndProductEdges;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpMstRcmndProduct
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'ins_ymd'?: string;
    /**
     * 腸内環境タイプ A：現状維持型、B：多様性補強型、C：善玉菌補強型、D：多様性／善玉菌補強型、Z：腸内環境タイプ不問
     * @type {PpmstrcmndproductIntestinalEnvironmentType}
     * @memberof EntPpMstRcmndProduct
     */
    'intestinal_environment_type'?: PpmstrcmndproductIntestinalEnvironmentType;
    /**
     * 優先順位
     * @type {number}
     * @memberof EntPpMstRcmndProduct
     */
    'priority'?: number;
    /**
     * 商品コード
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'product_code'?: string;
    /**
     * 商品説明
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'product_description'?: string;
    /**
     * 商品画像1
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'product_image_01'?: string;
    /**
     * 商品画像2
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'product_image_02'?: string;
    /**
     * 商品画像3
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'product_image_03'?: string;
    /**
     * 商品画像4
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'product_image_04'?: string;
    /**
     * 商品画像5
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'product_image_05'?: string;
    /**
     * 商品画像6
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'product_image_06'?: string;
    /**
     * 商品画像7
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'product_image_07'?: string;
    /**
     * 商品画像8
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'product_image_08'?: string;
    /**
     * 商品画像9
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'product_image_09'?: string;
    /**
     * 商品画像10
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'product_image_10'?: string;
    /**
     * 商品名
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'product_name'?: string;
    /**
     * 商品区分
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'product_type'?: string;
    /**
     * リコメンド商品タイトルID
     * @type {number}
     * @memberof EntPpMstRcmndProduct
     */
    'rcmnd_products_title_id'?: number;
    /**
     * リコメンド要否区分 0：商品おすすめなし、1：腸内環境タイプ別商品おすすめ、2：腸内環境タイプ共通商品おすすめ
     * @type {PpmstrcmndproductRecommendType}
     * @memberof EntPpMstRcmndProduct
     */
    'recommend_type'?: PpmstrcmndproductRecommendType;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpMstRcmndProduct
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstRcmndProduct
     */
    'upd_ymd'?: string;
}
/**
 * Edges holds the relations/edges for other nodes in the graph. The values are being populated by the PpMstRcmndProductQuery when eager-loading is set.
 * @export
 * @interface EntPpMstRcmndProductEdges
 */
export interface EntPpMstRcmndProductEdges {
    /**
     * 
     * @type {EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle}
     * @memberof EntPpMstRcmndProductEdges
     */
    'pp_mst_rcmnd_products_title'?: EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle;
}
/**
 * PpMstRcmndProductsTitle holds the value of the pp_mst_rcmnd_products_title edge.
 * @export
 * @interface EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
 */
export interface EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle {
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
     */
    'del'?: boolean;
    /**
     * リコメンドタイトル表示箇所 1：おすすめ商品_縦、2：おすすめ商品_横1段目、3：おすすめ商品_横2段目、4：おすすめ商品_横3段目
     * @type {PpmstrcmndproductstitleDisplayLocation}
     * @memberof EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
     */
    'display_location'?: PpmstrcmndproductstitleDisplayLocation;
    /**
     * 
     * @type {EntPpMstRcmndProductsTitleEdges}
     * @memberof EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
     */
    'edges'?: EntPpMstRcmndProductsTitleEdges;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
     */
    'ins_ymd'?: string;
    /**
     * リコメンド方式ID
     * @type {number}
     * @memberof EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
     */
    'rcmnd_method_id'?: number;
    /**
     * リコメンドタイトル
     * @type {string}
     * @memberof EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
     */
    'recommend_title'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstRcmndProductEdgesPpMstRcmndProductsTitle
     */
    'upd_ymd'?: string;
}
/**
 * 
 * @export
 * @interface EntPpMstRcmndProductsTitle
 */
export interface EntPpMstRcmndProductsTitle {
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpMstRcmndProductsTitle
     */
    'del'?: boolean;
    /**
     * リコメンドタイトル表示箇所 1：おすすめ商品_縦、2：おすすめ商品_横1段目、3：おすすめ商品_横2段目、4：おすすめ商品_横3段目
     * @type {PpmstrcmndproductstitleDisplayLocation}
     * @memberof EntPpMstRcmndProductsTitle
     */
    'display_location'?: PpmstrcmndproductstitleDisplayLocation;
    /**
     * 
     * @type {EntPpMstRcmndProductsTitleEdges}
     * @memberof EntPpMstRcmndProductsTitle
     */
    'edges'?: EntPpMstRcmndProductsTitleEdges;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpMstRcmndProductsTitle
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitle
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitle
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitle
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitle
     */
    'ins_ymd'?: string;
    /**
     * リコメンド方式ID
     * @type {number}
     * @memberof EntPpMstRcmndProductsTitle
     */
    'rcmnd_method_id'?: number;
    /**
     * リコメンドタイトル
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitle
     */
    'recommend_title'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpMstRcmndProductsTitle
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitle
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitle
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitle
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitle
     */
    'upd_ymd'?: string;
}
/**
 * Edges holds the relations/edges for other nodes in the graph. The values are being populated by the PpMstRcmndProductsTitleQuery when eager-loading is set.
 * @export
 * @interface EntPpMstRcmndProductsTitleEdges
 */
export interface EntPpMstRcmndProductsTitleEdges {
    /**
     * 
     * @type {EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod}
     * @memberof EntPpMstRcmndProductsTitleEdges
     */
    'pp_mst_rcmnd_method'?: EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod;
    /**
     * PpMstRcmndProducts holds the value of the pp_mst_rcmnd_products edge.
     * @type {Array<EntPpMstRcmndProduct>}
     * @memberof EntPpMstRcmndProductsTitleEdges
     */
    'pp_mst_rcmnd_products'?: Array<EntPpMstRcmndProduct>;
}
/**
 * PpMstRcmndMethod holds the value of the pp_mst_rcmnd_method edge.
 * @export
 * @interface EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
 */
export interface EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod {
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpMstRcmndMethodEdges}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'edges'?: EntPpMstRcmndMethodEdges;
    /**
     * 汎用リコメンドタイトル
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'generic_rcmnd_title'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'ins_ymd'?: string;
    /**
     * 病院ID
     * @type {number}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'medic_inst_id'?: number;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'partner_inst_id'?: number;
    /**
     * リコメンド表示パターン 0：表示なし、1：汎用のみ、2：個別おすすめ商品のみ(横表示)、3：個別おすすめ商品のみ(縦表示)、4：汎用＋個別おすすめ商品(横表示)、5：汎用＋個別おすすめ商品(縦表示)、6：個別おすすめ商品のみ(縦表示＋横表示)、7：フル表示
     * @type {PpmstrcmndmethodRcmndDisplayPattern}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'rcmnd_display_pattern'?: PpmstrcmndmethodRcmndDisplayPattern;
    /**
     * リコメンド方式ラベル
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'rcmnd_method_label'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'revision'?: number;
    /**
     * SKUコード
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'sku_code'?: string;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstRcmndProductsTitleEdgesPpMstRcmndMethod
     */
    'upd_ymd'?: string;
}
/**
 * 
 * @export
 * @interface EntPpMstUserAdmin
 */
export interface EntPpMstUserAdmin {
    /**
     * アクセス権限
     * @type {PpmstuseradminAccess}
     * @memberof EntPpMstUserAdmin
     */
    'access'?: PpmstuseradminAccess;
    /**
     * 所属
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'belongs'?: string;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpMstUserAdmin
     */
    'del'?: boolean;
    /**
     * ログイン電子署名
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'digital_signature'?: string;
    /**
     * 
     * @type {EntPpMstUserAdminEdges}
     * @memberof EntPpMstUserAdmin
     */
    'edges'?: EntPpMstUserAdminEdges;
    /**
     * メールアドレス
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'email'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpMstUserAdmin
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'ins_ymd'?: string;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'last_login'?: string;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'last_login_ip'?: string;
    /**
     * 氏名
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'name'?: string;
    /**
     * パスワード（暗号化済み）
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'password'?: string;
    /**
     * パスワードリセットトークン
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'reset_token'?: string;
    /**
     * パスワードリセットトークン有効期限
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'reset_token_expires'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpMstUserAdmin
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstUserAdmin
     */
    'upd_ymd'?: string;
}
/**
 * Edges holds the relations/edges for other nodes in the graph. The values are being populated by the PpMstUserAdminQuery when eager-loading is set.
 * @export
 * @interface EntPpMstUserAdminEdges
 */
export interface EntPpMstUserAdminEdges {
    /**
     * AccessTokens holds the value of the access_tokens edge.
     * @type {Array<EntAccessToken>}
     * @memberof EntPpMstUserAdminEdges
     */
    'access_tokens'?: Array<EntAccessToken>;
}
/**
 * 
 * @export
 * @interface EntPpMstUserMedical
 */
export interface EntPpMstUserMedical {
    /**
     * 建物・部屋番号
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'building'?: string;
    /**
     * 市区町村
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'city'?: string;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpMstUserMedical
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpMstUserMedicalEdges}
     * @memberof EntPpMstUserMedical
     */
    'edges'?: EntPpMstUserMedicalEdges;
    /**
     * メールアドレス
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'email'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpMstUserMedical
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'ins_ymd'?: string;
    /**
     * 病院名
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'medic_inst_name'?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'postal_code'?: string;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'pref_code'?: string;
    /**
     * 検査結果メール送信対象
     * @type {PpmstusermedicalResultSendTarget}
     * @memberof EntPpMstUserMedical
     */
    'result_send_target'?: PpmstusermedicalResultSendTarget;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpMstUserMedical
     */
    'revision'?: number;
    /**
     * 丁目番地号
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'street'?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'tel_no'?: string;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstUserMedical
     */
    'upd_ymd'?: string;
}
/**
 * Edges holds the relations/edges for other nodes in the graph. The values are being populated by the PpMstUserMedicalQuery when eager-loading is set.
 * @export
 * @interface EntPpMstUserMedicalEdges
 */
export interface EntPpMstUserMedicalEdges {
    /**
     * PpMstRcmndMethods holds the value of the pp_mst_rcmnd_methods edge.
     * @type {Array<EntPpMstRcmndMethod>}
     * @memberof EntPpMstUserMedicalEdges
     */
    'pp_mst_rcmnd_methods'?: Array<EntPpMstRcmndMethod>;
    /**
     * PpTestKits holds the value of the pp_test_kits edge.
     * @type {Array<EntPpTestKit>}
     * @memberof EntPpMstUserMedicalEdges
     */
    'pp_test_kits'?: Array<EntPpTestKit>;
}
/**
 * 
 * @export
 * @interface EntPpMstUserPartner
 */
export interface EntPpMstUserPartner {
    /**
     * アクセス権限 1：一般提携法人ユーザー、2：研究機関ユーザー、3：顧問研究者ユーザー
     * @type {number}
     * @memberof EntPpMstUserPartner
     */
    'access'?: number;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpMstUserPartner
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpMstUserPartnerEdges}
     * @memberof EntPpMstUserPartner
     */
    'edges'?: EntPpMstUserPartnerEdges;
    /**
     * メールアドレス
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'email'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpMstUserPartner
     */
    'id'?: number;
    /**
     * 識別コード
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'identification_cd'?: string;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'ins_ymd'?: string;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'last_login'?: string;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'last_login_ip'?: string;
    /**
     * ユーザー名
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'name'?: string;
    /**
     * 提携機関区分
     * @type {PpmstuserpartnerPartnerInstDivision}
     * @memberof EntPpMstUserPartner
     */
    'partner_inst_division'?: PpmstuserpartnerPartnerInstDivision;
    /**
     * 所属機関ID
     * @type {number}
     * @memberof EntPpMstUserPartner
     */
    'partner_inst_id'?: number;
    /**
     * パスワード（暗号化済み）
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'password'?: string;
    /**
     * パスワードリセットトークン
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'reset_token'?: string;
    /**
     * パスワードリセットトークン有効期限
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'reset_token_expires'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpMstUserPartner
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpMstUserPartner
     */
    'upd_ymd'?: string;
}
/**
 * Edges holds the relations/edges for other nodes in the graph. The values are being populated by the PpMstUserPartnerQuery when eager-loading is set.
 * @export
 * @interface EntPpMstUserPartnerEdges
 */
export interface EntPpMstUserPartnerEdges {
    /**
     * AccessTokens holds the value of the access_tokens edge.
     * @type {Array<EntAccessToken>}
     * @memberof EntPpMstUserPartnerEdges
     */
    'access_tokens'?: Array<EntAccessToken>;
}
/**
 * 
 * @export
 * @interface EntPpPet
 */
export interface EntPpPet {
    /**
     * 生年月日確度
     * @type {PppetAccuracyOfPetBirthday}
     * @memberof EntPpPet
     */
    'accuracy_of_pet_birthday'?: PppetAccuracyOfPetBirthday;
    /**
     * ペットの種類
     * @type {PppetAnimalType}
     * @memberof EntPpPet
     */
    'animal_type'?: PppetAnimalType;
    /**
     * 品種
     * @type {number}
     * @memberof EntPpPet
     */
    'breed_id'?: number;
    /**
     * 両親の品種1
     * @type {number}
     * @memberof EntPpPet
     */
    'breed_of_parents_1'?: number;
    /**
     * 両親の品種2
     * @type {number}
     * @memberof EntPpPet
     */
    'breed_of_parents_2'?: number;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpPet
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpPetEdges}
     * @memberof EntPpPet
     */
    'edges'?: EntPpPetEdges;
    /**
     * アイコン画像
     * @type {string}
     * @memberof EntPpPet
     */
    'icon'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpPet
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpPet
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpPet
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpPet
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpPet
     */
    'ins_ymd'?: string;
    /**
     * カルテID
     * @type {string}
     * @memberof EntPpPet
     */
    'karte_id'?: string;
    /**
     * 生年月日
     * @type {string}
     * @memberof EntPpPet
     */
    'pet_birthday'?: string;
    /**
     * ペット名
     * @type {string}
     * @memberof EntPpPet
     */
    'pet_name'?: string;
    /**
     * ペット名フリガナ
     * @type {string}
     * @memberof EntPpPet
     */
    'pet_name_furigana'?: string;
    /**
     * ペット名ローマ字
     * @type {string}
     * @memberof EntPpPet
     */
    'pet_name_romaji'?: string;
    /**
     * 性別
     * @type {PppetPetSex}
     * @memberof EntPpPet
     */
    'pet_sex'?: PppetPetSex;
    /**
     * ユーザーID（飼い主ID）
     * @type {number}
     * @memberof EntPpPet
     */
    'pp_user_id'?: number;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpPet
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpPet
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpPet
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpPet
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpPet
     */
    'upd_ymd'?: string;
}
/**
 * Edges holds the relations/edges for other nodes in the graph. The values are being populated by the PpPetQuery when eager-loading is set.
 * @export
 * @interface EntPpPetEdges
 */
export interface EntPpPetEdges {
    /**
     * 
     * @type {EntPpPetEdgesParentBreed1}
     * @memberof EntPpPetEdges
     */
    'parent_breed_1'?: EntPpPetEdgesParentBreed1;
    /**
     * 
     * @type {EntPpPetEdgesParentBreed2}
     * @memberof EntPpPetEdges
     */
    'parent_breed_2'?: EntPpPetEdgesParentBreed2;
    /**
     * 
     * @type {EntPpPetEdgesPpMstBreed}
     * @memberof EntPpPetEdges
     */
    'pp_mst_breed'?: EntPpPetEdgesPpMstBreed;
    /**
     * PpTestKits holds the value of the pp_test_kits edge.
     * @type {Array<EntPpTestKit>}
     * @memberof EntPpPetEdges
     */
    'pp_test_kits'?: Array<EntPpTestKit>;
    /**
     * 
     * @type {EntAccessTokenEdgesPpUser}
     * @memberof EntPpPetEdges
     */
    'pp_user'?: EntAccessTokenEdgesPpUser;
}
/**
 * ParentBreed1 holds the value of the parent_breed_1 edge.
 * @export
 * @interface EntPpPetEdgesParentBreed1
 */
export interface EntPpPetEdgesParentBreed1 {
    /**
     * ペットの種類
     * @type {PpmstbreedAnimalType}
     * @memberof EntPpPetEdgesParentBreed1
     */
    'animal_type'?: PpmstbreedAnimalType;
    /**
     * 品種名
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed1
     */
    'breed_name'?: string;
    /**
     * コード番号
     * @type {number}
     * @memberof EntPpPetEdgesParentBreed1
     */
    'code_id'?: number;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpPetEdgesParentBreed1
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpMstBreedEdges}
     * @memberof EntPpPetEdgesParentBreed1
     */
    'edges'?: EntPpMstBreedEdges;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpPetEdgesParentBreed1
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed1
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed1
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed1
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed1
     */
    'ins_ymd'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpPetEdgesParentBreed1
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed1
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed1
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed1
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed1
     */
    'upd_ymd'?: string;
}
/**
 * ParentBreed2 holds the value of the parent_breed_2 edge.
 * @export
 * @interface EntPpPetEdgesParentBreed2
 */
export interface EntPpPetEdgesParentBreed2 {
    /**
     * ペットの種類
     * @type {PpmstbreedAnimalType}
     * @memberof EntPpPetEdgesParentBreed2
     */
    'animal_type'?: PpmstbreedAnimalType;
    /**
     * 品種名
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed2
     */
    'breed_name'?: string;
    /**
     * コード番号
     * @type {number}
     * @memberof EntPpPetEdgesParentBreed2
     */
    'code_id'?: number;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpPetEdgesParentBreed2
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpMstBreedEdges}
     * @memberof EntPpPetEdgesParentBreed2
     */
    'edges'?: EntPpMstBreedEdges;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpPetEdgesParentBreed2
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed2
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed2
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed2
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed2
     */
    'ins_ymd'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpPetEdgesParentBreed2
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed2
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed2
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed2
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpPetEdgesParentBreed2
     */
    'upd_ymd'?: string;
}
/**
 * PpMstBreed holds the value of the pp_mst_breed edge.
 * @export
 * @interface EntPpPetEdgesPpMstBreed
 */
export interface EntPpPetEdgesPpMstBreed {
    /**
     * ペットの種類
     * @type {PpmstbreedAnimalType}
     * @memberof EntPpPetEdgesPpMstBreed
     */
    'animal_type'?: PpmstbreedAnimalType;
    /**
     * 品種名
     * @type {string}
     * @memberof EntPpPetEdgesPpMstBreed
     */
    'breed_name'?: string;
    /**
     * コード番号
     * @type {number}
     * @memberof EntPpPetEdgesPpMstBreed
     */
    'code_id'?: number;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpPetEdgesPpMstBreed
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpMstBreedEdges}
     * @memberof EntPpPetEdgesPpMstBreed
     */
    'edges'?: EntPpMstBreedEdges;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpPetEdgesPpMstBreed
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpPetEdgesPpMstBreed
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpPetEdgesPpMstBreed
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpPetEdgesPpMstBreed
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpPetEdgesPpMstBreed
     */
    'ins_ymd'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpPetEdgesPpMstBreed
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpPetEdgesPpMstBreed
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpPetEdgesPpMstBreed
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpPetEdgesPpMstBreed
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpPetEdgesPpMstBreed
     */
    'upd_ymd'?: string;
}
/**
 * 
 * @export
 * @interface EntPpTestKit
 */
export interface EntPpTestKit {
    /**
     * 生年月日確度
     * @type {PptestkitBiAccuracyOfPetBirthday}
     * @memberof EntPpTestKit
     */
    'bi_accuracy_of_pet_birthday'?: PptestkitBiAccuracyOfPetBirthday;
    /**
     * 動物種類
     * @type {PptestkitBiAnimalType}
     * @memberof EntPpTestKit
     */
    'bi_animal_type'?: PptestkitBiAnimalType;
    /**
     * 品種
     * @type {number}
     * @memberof EntPpTestKit
     */
    'bi_breed'?: number;
    /**
     * 両親の品種1
     * @type {number}
     * @memberof EntPpTestKit
     */
    'bi_breed_of_parents_1'?: number;
    /**
     * 両親の品種2
     * @type {number}
     * @memberof EntPpTestKit
     */
    'bi_breed_of_parents_2'?: number;
    /**
     * アイコン
     * @type {string}
     * @memberof EntPpTestKit
     */
    'bi_icon'?: string;
    /**
     * 生年月日
     * @type {string}
     * @memberof EntPpTestKit
     */
    'bi_pet_birthday'?: string;
    /**
     * ペット名
     * @type {string}
     * @memberof EntPpTestKit
     */
    'bi_pet_name'?: string;
    /**
     * ペット名フリガナ
     * @type {string}
     * @memberof EntPpTestKit
     */
    'bi_pet_name_furigana'?: string;
    /**
     * ペット名ローマ字
     * @type {string}
     * @memberof EntPpTestKit
     */
    'bi_pet_name_romaji'?: string;
    /**
     * 性別
     * @type {PptestkitBiPetSex}
     * @memberof EntPpTestKit
     */
    'bi_pet_sex'?: PptestkitBiPetSex;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpTestKit
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpTestKitEdges}
     * @memberof EntPpTestKit
     */
    'edges'?: EntPpTestKitEdges;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpTestKit
     */
    'id'?: number;
    /**
     * 識別コード
     * @type {string}
     * @memberof EntPpTestKit
     */
    'identification_cd'?: string;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpTestKit
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpTestKit
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpTestKit
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpTestKit
     */
    'ins_ymd'?: string;
    /**
     * 攻撃性(家族に対して)
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_aggression_for_family'?: string;
    /**
     * 攻撃性(見知らぬ人に対して)
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_aggression_for_stranger'?: string;
    /**
     * アレルギー（その他内容）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_allergy_comment'?: string;
    /**
     * アレルギー／食物アレルギー（消化器）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_allergy_food_digestive_organ'?: string;
    /**
     * アレルギー／食物アレルギー（皮膚）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_allergy_food_skin'?: string;
    /**
     * アレルギー／その他
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_allergy_other'?: string;
    /**
     * 食事関連/食欲
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_appetite'?: string;
    /**
     * 体型
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_body_figure'?: string;
    /**
     * 脳疾患/脊髄疾患／脳疾患（原因が判明していない）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_brain_spinal_disease_brain_disease'?: string;
    /**
     * 脳疾患/脊髄疾患（その他内容）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_brain_spinal_disease_comment'?: string;
    /**
     * 脳疾患/脊髄疾患／てんかん
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_brain_spinal_disease_epilepsy'?: string;
    /**
     * 脳疾患/脊髄疾患／椎間板ヘルニア
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_brain_spinal_disease_herniated_disc'?: string;
    /**
     * 脳疾患/脊髄疾患／その他
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_brain_spinal_disease_other'?: string;
    /**
     * 申し込み完了フラグ
     * @type {boolean}
     * @memberof EntPpTestKit
     */
    'is_confirmed'?: boolean;
    /**
     * 検査に関する同意
     * @type {boolean}
     * @memberof EntPpTestKit
     */
    'is_consent_for_testing'?: boolean;
    /**
     * 下痢有無（直近3カ月）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_diarrhea'?: string;
    /**
     * 下痢の発症時期
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_diarrhea_timing'?: string;
    /**
     * 消化器疾患（その他内容）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_digestive_disease_comment'?: string;
    /**
     * 消化器疾患／慢性腸症/炎症性腸疾患
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_digestive_disease_ibd'?: string;
    /**
     * 消化器疾患／その他
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_digestive_disease_other'?: string;
    /**
     * 消化器疾患／膵炎
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_digestive_disease_pancreatitis'?: string;
    /**
     * 消化器疾患／膵外分泌不全
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_digestive_disease_pei'?: string;
    /**
     * 病歴有無（直近3カ月）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_disease'?: string;
    /**
     * 飼育環境
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_environment'?: string;
    /**
     * 1日の運動時間(お散歩を含む)
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_exercise_time_oneday'?: string;
    /**
     * 眼科疾患／白内障
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_eye_disease_cataract'?: string;
    /**
     * 眼科疾患（その他内容）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_eye_disease_comment'?: string;
    /**
     * 眼科疾患／乾性核結膜炎（ドライアイ）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_eye_disease_dry_eyes'?: string;
    /**
     * 眼科疾患／緑内障
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_eye_disease_glaucoma'?: string;
    /**
     * 眼科疾患／その他
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_eye_disease_other'?: string;
    /**
     * 目やに／涙やけ有無（直近3カ月）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_eyemucus_tearsstain'?: string;
    /**
     * 恐怖心(他の犬or猫に対して)
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_fear_for_other_pets'?: string;
    /**
     * 恐怖心(他の人に対して)
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_fear_for_stranger'?: string;
    /**
     * 恐怖心(慣れない環境)
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_fear_unfamiliar_environment'?: string;
    /**
     * ブラッシング頻度
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_frequency_brushing'?: string;
    /**
     * うんち/回数(1日)
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_frequency_excrement'?: string;
    /**
     * シャンプー頻度
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_frequency_shampoo'?: string;
    /**
     * 散歩の頻度
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_frequency_walking'?: string;
    /**
     * 心臓疾患／心筋症
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_heart_disease_cardiomyopathy'?: string;
    /**
     * 心臓疾患（その他内容）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_heart_disease_comment'?: string;
    /**
     * 心臓疾患／心不全（原因が判明していない）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_heart_disease_heart_failure'?: string;
    /**
     * 心臓疾患／僧帽弁閉鎖不全症
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_heart_disease_mr'?: string;
    /**
     * 心臓疾患／その他
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_heart_disease_other'?: string;
    /**
     * 心臓疾患／肺高血圧症
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_heart_disease_ph'?: string;
    /**
     * ごはんが手作りの場合の種類（その他内容）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_homemade_mainfood_type_comment'?: string;
    /**
     * ごはんが手作りの場合の種類／魚
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_homemade_mainfood_type_fish'?: string;
    /**
     * ごはんが手作りの場合の種類／果物
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_homemade_mainfood_type_fruit'?: string;
    /**
     * ごはんが手作りの場合の種類／肉
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_homemade_mainfood_type_meat'?: string;
    /**
     * ごはんが手作りの場合の種類／その他
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_homemade_mainfood_type_other'?: string;
    /**
     * ごはんが手作りの場合の種類／野菜
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_homemade_mainfood_type_vegetable'?: string;
    /**
     * ホルモン・代謝異常（その他内容）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_hormone_metabolic_disorder_comment'?: string;
    /**
     * ホルモン・代謝異常／クッシング症候群
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_hormone_metabolism_cushing_syndrome'?: string;
    /**
     * ホルモン・代謝異常／糖尿病
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_hormone_metabolism_diabetes'?: string;
    /**
     * ホルモン・代謝異常／高脂血症
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_hormone_metabolism_dl'?: string;
    /**
     * ホルモン・代謝異常／その他
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_hormone_metabolism_other'?: string;
    /**
     * 高血圧
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_hypertension'?: string;
    /**
     * 腎臓疾患／慢性腎不全
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_kidney_disease_ckd'?: string;
    /**
     * 腎臓疾患（その他内容）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_kidney_disease_comment'?: string;
    /**
     * 腎臓疾患／腎結石
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_kidney_disease_kidney_stone'?: string;
    /**
     * 腎臓疾患／腎炎
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_kidney_disease_nephritis'?: string;
    /**
     * 腎臓疾患／その他
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_kidney_disease_other'?: string;
    /**
     * 腎臓疾患／蛋白漏出性腎症
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_kidney_disease_pln'?: string;
    /**
     * 泌乳した子供の数
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_lactated_children'?: string;
    /**
     * 泌乳経験
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_lactation_experience'?: string;
    /**
     * 泌乳期間
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_lactation_weeks'?: string;
    /**
     * 日常的な乳酸菌サプリ摂取有無
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_lactic_supplement'?: string;
    /**
     * 乳酸菌サプリ接種量
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_lactic_supplement_amount'?: string;
    /**
     * 乳酸菌サプリ接種頻度
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_lactic_supplement_frequency'?: string;
    /**
     * 肝・胆道疾患／胆泥症
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_liver_bile_disease_biliary_sludge'?: string;
    /**
     * 肝・胆道疾患（その他内容）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_liver_bile_disease_comment'?: string;
    /**
     * 肝・胆道疾患／胆嚢粘液嚢腫
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_liver_bile_disease_gallbladder_mucocele'?: string;
    /**
     * 肝・胆道疾患／肝障害
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_liver_bile_disease_liver_damage'?: string;
    /**
     * 肝・胆道疾患／その他
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_liver_bile_disease_other'?: string;
    /**
     * ごはん
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_main_food'?: string;
    /**
     * ごはん（その他内容）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_main_food_comment'?: string;
    /**
     * 抗生物質投与有無（直近3カ月）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_medicine_antibiotics'?: string;
    /**
     * 抗生物質以外の薬物投与有無（直近3カ月）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_medicine_except_antibiotics'?: string;
    /**
     * 泌乳した子供の数
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_num_of_children'?: string;
    /**
     * 整形疾患／関節炎
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_orthopedic_disease_arthritis'?: string;
    /**
     * 整形疾患（その他内容）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_orthopedic_disease_comment'?: string;
    /**
     * 整形疾患／股関節異形成
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_orthopedic_disease_hd'?: string;
    /**
     * 整形疾患／その他
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_orthopedic_disease_other'?: string;
    /**
     * 整形疾患／膝蓋骨脱臼
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_orthopedic_disease_patella'?: string;
    /**
     * その他病歴
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_other_comment'?: string;
    /**
     * 体重
     * @type {number}
     * @memberof EntPpTestKit
     */
    'is_pet_weight'?: number;
    /**
     * 妊娠・妊娠の可能性有無
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_pregnancy'?: string;
    /**
     * 妊娠期間
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_pregnancy_weeks'?: string;
    /**
     * 研究への参加の同意
     * @type {boolean}
     * @memberof EntPpTestKit
     */
    'is_research_participation_consent'?: boolean;
    /**
     * 呼吸器疾患／喘息
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_respiratory_disease_asthma'?: string;
    /**
     * 呼吸器疾患／気管気管支炎
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_respiratory_disease_bronchitis'?: string;
    /**
     * 呼吸器疾患（その他内容）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_respiratory_disease_comment'?: string;
    /**
     * 呼吸器疾患／その他
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_respiratory_disease_other'?: string;
    /**
     * 呼吸器疾患／鼻炎
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_respiratory_disease_rhinitis'?: string;
    /**
     * 呼吸器疾患／気管虚脱
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_respiratory_disease_tracheal_collapse'?: string;
    /**
     * 皮膚疾患（その他内容）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_disease_comment'?: string;
    /**
     * 皮膚疾患／皮膚炎（原因が判明していない）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_disease_eczema'?: string;
    /**
     * 皮膚疾患／外耳炎
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_disease_external_otitisis'?: string;
    /**
     * 皮膚疾患／その他
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_disease_other'?: string;
    /**
     * 皮膚疾患／膿皮症
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_disease_pyoderma'?: string;
    /**
     * 皮膚疾患／脂漏症
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_disease_seforrhoea'?: string;
    /**
     * 皮膚トラブル有無（直近3カ月）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_trouble'?: string;
    /**
     * 皮膚トラブル内容（その他内容）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_trouble_symptoms_comment'?: string;
    /**
     * 皮膚トラブル内容／フケ
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_trouble_symptoms_dandruff'?: string;
    /**
     * 皮膚トラブル内容／乾燥
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_trouble_symptoms_dry'?: string;
    /**
     * 皮膚トラブル内容／脱毛
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_trouble_symptoms_hairloss'?: string;
    /**
     * 皮膚トラブル内容／かゆみ
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_trouble_symptoms_itch'?: string;
    /**
     * 皮膚トラブル内容／その他
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_trouble_symptoms_other'?: string;
    /**
     * 皮膚トラブル内容／発疹
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_trouble_symptoms_rash'?: string;
    /**
     * 皮膚トラブル内容／赤み
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_trouble_symptoms_sore'?: string;
    /**
     * 皮膚トラブル発症時期
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_skin_trouble_timing'?: string;
    /**
     * 避妊・去勢手術有無
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_spayed_neutered'?: string;
    /**
     * 泌乳した期間の合計
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_total_lactation_duration'?: string;
    /**
     * 腫瘍
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_tumor_comment'?: string;
    /**
     * 分離不安(器物破損、大小便）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_uneasiness_damage_to_property'?: string;
    /**
     * 分離不安(飼い主と分離時の不安感)
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_uneasiness_separated_from_owner'?: string;
    /**
     * 基礎情報入力日
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_upd_ymd'?: string;
    /**
     * 泌尿器疾患／膀胱結石
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_urology_disease_bladder_stone'?: string;
    /**
     * 泌尿器疾患（その他内容）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_urology_disease_comment'?: string;
    /**
     * 泌尿器疾患／特発性膀胱炎
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_urology_disease_idiopathic_cystitis'?: string;
    /**
     * 泌尿器疾患／その他
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_urology_disease_other'?: string;
    /**
     * 泌尿器疾患／細菌性/その他膀胱炎
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_urology_disease_other_cystitis'?: string;
    /**
     * 嘔吐有無（直近3カ月）
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_vomit'?: string;
    /**
     * 嘔吐の発症時期
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_vomit_timing'?: string;
    /**
     * 過去の妊娠の有無
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_was_pregnant_past'?: string;
    /**
     * 気になること／その他
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_any_other'?: string;
    /**
     * 気になること／その他内容
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_any_other_comment'?: string;
    /**
     * 気になること／口臭が気になる
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_bad_breath'?: string;
    /**
     * 気になること／毛ヅヤが悪い気がする
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_bad_hair'?: string;
    /**
     * 気になること／体臭が気になる
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_body_odor'?: string;
    /**
     * 気になること／歯石が気になる
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_dental_tartar'?: string;
    /**
     * 気になること／食糞する
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_eat_excrement'?: string;
    /**
     * 気になること／おしっこの回数が多い
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_frequent_urination'?: string;
    /**
     * 気になること／よだれが垂れる
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_increased_salivation'?: string;
    /**
     * 気になること／耳を痒がっている
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_itchy_ears'?: string;
    /**
     * 気になること／体重が増えにくい/痩せている
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_lost_weight'?: string;
    /**
     * 気になること／特になし
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_nothing'?: string;
    /**
     * 気になること／耳の匂いが気になる
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_smell_of_ears'?: string;
    /**
     * 気になること／便が臭い
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_smell_of_excrement'?: string;
    /**
     * 気になること／イボがある
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_warts'?: string;
    /**
     * 気になること／体重が増えやすい
     * @type {string}
     * @memberof EntPpTestKit
     */
    'is_worry_weight_easily'?: string;
    /**
     * カルテID
     * @type {string}
     * @memberof EntPpTestKit
     */
    'karte_id'?: string;
    /**
     * 病院ID
     * @type {number}
     * @memberof EntPpTestKit
     */
    'medic_inst_id'?: number;
    /**
     * 検体ID（数値化したもの）
     * @type {number}
     * @memberof EntPpTestKit
     */
    'number_of_sample_id'?: number;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof EntPpTestKit
     */
    'partner_inst_id'?: number;
    /**
     * ペットID
     * @type {number}
     * @memberof EntPpTestKit
     */
    'pp_pet_id'?: number;
    /**
     * ユーザーD
     * @type {number}
     * @memberof EntPpTestKit
     */
    'pp_user_id'?: number;
    /**
     * リコメンド要否区分 0：商品おすすめなし、1：腸内環境タイプ別商品おすすめ、2：腸内環境タイプ共通商品おすすめ
     * @type {PptestkitRecommendType}
     * @memberof EntPpTestKit
     */
    'recommend_type'?: PptestkitRecommendType;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpTestKit
     */
    'revision'?: number;
    /**
     * 検体ID
     * @type {string}
     * @memberof EntPpTestKit
     */
    'sample_id'?: string;
    /**
     * 検体採取日
     * @type {string}
     * @memberof EntPpTestKit
     */
    'sampling_date'?: string;
    /**
     * SKUコード
     * @type {string}
     * @memberof EntPpTestKit
     */
    'sku_code'?: string;
    /**
     * 検査区分
     * @type {PptestkitTestDivision}
     * @memberof EntPpTestKit
     */
    'test_division'?: PptestkitTestDivision;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpTestKit
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpTestKit
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpTestKit
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpTestKit
     */
    'upd_ymd'?: string;
}
/**
 * Edges holds the relations/edges for other nodes in the graph. The values are being populated by the PpTestKitQuery when eager-loading is set.
 * @export
 * @interface EntPpTestKitEdges
 */
export interface EntPpTestKitEdges {
    /**
     * 
     * @type {EntPpMstIdentificationCdEdgesPpMstPartnerInst}
     * @memberof EntPpTestKitEdges
     */
    'pp_mst_partner_inst'?: EntPpMstIdentificationCdEdgesPpMstPartnerInst;
    /**
     * 
     * @type {EntPpMstRcmndMethodEdgesPpMstUserMedical}
     * @memberof EntPpTestKitEdges
     */
    'pp_mst_user_medical'?: EntPpMstRcmndMethodEdgesPpMstUserMedical;
    /**
     * 
     * @type {EntPpTestKitEdgesPpPet}
     * @memberof EntPpTestKitEdges
     */
    'pp_pet'?: EntPpTestKitEdgesPpPet;
    /**
     * 
     * @type {EntAccessTokenEdgesPpUser}
     * @memberof EntPpTestKitEdges
     */
    'pp_user'?: EntAccessTokenEdgesPpUser;
}
/**
 * PpPet holds the value of the pp_pet edge.
 * @export
 * @interface EntPpTestKitEdgesPpPet
 */
export interface EntPpTestKitEdgesPpPet {
    /**
     * 生年月日確度
     * @type {PppetAccuracyOfPetBirthday}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'accuracy_of_pet_birthday'?: PppetAccuracyOfPetBirthday;
    /**
     * ペットの種類
     * @type {PppetAnimalType}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'animal_type'?: PppetAnimalType;
    /**
     * 品種
     * @type {number}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'breed_id'?: number;
    /**
     * 両親の品種1
     * @type {number}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'breed_of_parents_1'?: number;
    /**
     * 両親の品種2
     * @type {number}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'breed_of_parents_2'?: number;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpPetEdges}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'edges'?: EntPpPetEdges;
    /**
     * アイコン画像
     * @type {string}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'icon'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'ins_ymd'?: string;
    /**
     * カルテID
     * @type {string}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'karte_id'?: string;
    /**
     * 生年月日
     * @type {string}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'pet_birthday'?: string;
    /**
     * ペット名
     * @type {string}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'pet_name'?: string;
    /**
     * ペット名フリガナ
     * @type {string}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'pet_name_furigana'?: string;
    /**
     * ペット名ローマ字
     * @type {string}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'pet_name_romaji'?: string;
    /**
     * 性別
     * @type {PppetPetSex}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'pet_sex'?: PppetPetSex;
    /**
     * ユーザーID（飼い主ID）
     * @type {number}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'pp_user_id'?: number;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'revision'?: number;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpTestKitEdgesPpPet
     */
    'upd_ymd'?: string;
}
/**
 * 
 * @export
 * @interface EntPpTestResult
 */
export interface EntPpTestResult {
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpTestResult
     */
    'del'?: boolean;
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof EntPpTestResult
     */
    'flowcell_id'?: string;
    /**
     * 強制検査
     * @type {boolean}
     * @memberof EntPpTestResult
     */
    'forced_test'?: boolean;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpTestResult
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpTestResult
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpTestResult
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpTestResult
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpTestResult
     */
    'ins_ymd'?: string;
    /**
     * Lab barcode ID
     * @type {string}
     * @memberof EntPpTestResult
     */
    'lab_barcode_id'?: string;
    /**
     * 検査回数
     * @type {number}
     * @memberof EntPpTestResult
     */
    'number_of_test'?: number;
    /**
     * 検体位置
     * @type {string}
     * @memberof EntPpTestResult
     */
    'position'?: string;
    /**
     * 再検査
     * @type {boolean}
     * @memberof EntPpTestResult
     */
    're_test'?: boolean;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpTestResult
     */
    'revision'?: number;
    /**
     * 検体ID
     * @type {string}
     * @memberof EntPpTestResult
     */
    'sample_id'?: string;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpTestResult
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpTestResult
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpTestResult
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpTestResult
     */
    'upd_ymd'?: string;
    /**
     * PCR試験プレートID
     * @type {string}
     * @memberof EntPpTestResult
     */
    'wellplate_id'?: string;
}
/**
 * 
 * @export
 * @interface EntPpTestResultRiskscoreCat
 */
export interface EntPpTestResultRiskscoreCat {
    /**
     * 急性下痢リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'acute_diarrhea_risk_score'?: string;
    /**
     * 膵炎リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'acute_pancreatitis_risk_score'?: string;
    /**
     * アトピー性皮膚炎リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'atopic_dermatitis_risk_score'?: string;
    /**
     * 自己免疫疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'autoimmune_disease_risk_score'?: string;
    /**
     * 胆管炎/胆管肝炎リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'bile_duct_hepatitis_risk_score'?: string;
    /**
     * 脳腫瘍リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'brain_tumor_rsk_score'?: string;
    /**
     * 循環器疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'cardiology_disease_risk_score'?: string;
    /**
     * 悪玉菌の細菌種数
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'cell_count_of_bad_bacteria'?: string;
    /**
     * 善玉菌の細菌種数
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'cell_count_of_good_bacteria'?: string;
    /**
     * その他の細菌種数
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'cell_count_of_other'?: string;
    /**
     * 腫瘍性疾患_消化器型小細胞性リンパ腫リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'cell_lymphoma_risk_score'?: string;
    /**
     * 脳梗塞リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'cerebral_infarction_risk_score'?: string;
    /**
     * 慢性下痢リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'chronic_diarrhea_risk_score'?: string;
    /**
     * 慢性腸症リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'chronic_enteropathy_risk_score'?: string;
    /**
     * 慢性心不全リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'chronic_heart_failure_risk_score'?: string;
    /**
     * 慢性腎不全リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'ckd_risk_score'?: string;
    /**
     * 便秘リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'constipation_risk_score'?: string;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'del'?: boolean;
    /**
     * 認知症リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'dementia_risk_score'?: string;
    /**
     * 皮膚疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'dermatological_disease_risk_score'?: string;
    /**
     * 下痢リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'diarrhea_risk_score'?: string;
    /**
     * 消化器疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'digestive_disorders_risk_score'?: string;
    /**
     * 多様性指数
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'diversity_index'?: string;
    /**
     * 糖尿病リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'dm_risk_score'?: string;
    /**
     * てんかんリスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'epilepsy_score'?: string;
    /**
     * 食物アレルギーリスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'food_allergy_risk_score'?: string;
    /**
     * 胆石リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'gallstones_risk_score'?: string;
    /**
     * 善玉菌/悪玉菌指数
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'good_bacteria_bad_bacteria_index'?: string;
    /**
     * クッシング症候群リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'hyperadrenocorticism_risk_score'?: string;
    /**
     * 高血圧リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'hypertention_risk_score'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'id'?: number;
    /**
     * 炎症性腸疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'inflammatory_bowel_disease_risk_score'?: string;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'ins_ymd'?: string;
    /**
     * 腸内環境スコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'intestinal_environment_score'?: string;
    /**
     * 腸内環境タイプ
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'intestinal_environment_type'?: string;
    /**
     * 腎結石リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'kidney_stones_risk_score'?: string;
    /**
     * 肝疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'liver_disease_risk_score'?: string;
    /**
     * 代謝・内分泌疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'metabolic_and_endocrine_diseases_risk_score'?: string;
    /**
     * 神経疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'neurologycal_disease_risk_score'?: string;
    /**
     * 腸内にいた細菌種数
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'number_of_bacteria_in_the_intestine'?: string;
    /**
     * 検査回数
     * @type {number}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'number_of_test'?: number;
    /**
     * 肥満リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'obesity_risk_score'?: string;
    /**
     * 眼科疾患_緑内障リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'ophthalmology_disease_glaucoma_risk_score'?: string;
    /**
     * 整形疾患_変形性関節症リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'orthopedic_disease_chronic_arthritis_risk_score'?: string;
    /**
     * 悪玉菌の割合
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'percentage_of_bad_bacteria'?: string;
    /**
     * 善玉菌の割合
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'percentage_of_good_bacteria'?: string;
    /**
     * その他の割合
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'percentage_of_other'?: string;
    /**
     * ペットID
     * @type {number}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'pp_pet_id'?: number;
    /**
     * 呼吸器疾患_喘息リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'respiratory_disease_asthma_risk_score'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'revision'?: number;
    /**
     * 検体ID
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'sample_id'?: string;
    /**
     * 検体採取日
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'sampling_date'?: string;
    /**
     * 類似度指数
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'similarity_index'?: string;
    /**
     * 潰瘍性大腸炎リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'ulcerative_colitis_risk_score'?: string;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'upd_ymd'?: string;
    /**
     * 腎泌尿器疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreCat
     */
    'urologycal_disease_risk_score'?: string;
}
/**
 * 
 * @export
 * @interface EntPpTestResultRiskscoreDog
 */
export interface EntPpTestResultRiskscoreDog {
    /**
     * 急性下痢リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'acute_diarrhea_risk_score'?: string;
    /**
     * 膵炎リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'acute_pancreatitis_risk_score'?: string;
    /**
     * 攻撃性リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'aggression_risk_score'?: string;
    /**
     * アトピー性皮膚炎リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'atopic_dermatitis_risk_score'?: string;
    /**
     * 自己免疫性脳炎リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'autoimmune_encephalitis_risk_score'?: string;
    /**
     * 自己免疫疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'autoimmune_risk_score'?: string;
    /**
     * 胆管炎/胆管肝炎リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'bile_duct_hepatitis_risk_score'?: string;
    /**
     * 脳腫瘍リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'brain_tumor_risk_score'?: string;
    /**
     * 循環器疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'cardiology_disease_risk_score'?: string;
    /**
     * 悪玉菌の細菌種数
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'cell_count_of_bad_bacteria'?: string;
    /**
     * 善玉菌の細菌種数
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'cell_count_of_good_bacteria'?: string;
    /**
     * その他の細菌種数
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'cell_count_of_other'?: string;
    /**
     * 脳梗塞リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'cerebral_infarction_risk_score'?: string;
    /**
     * 慢性関節炎リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'chronic_arthritis_risk_score'?: string;
    /**
     * 慢性下痢リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'chronic_diarrhea_risk_score'?: string;
    /**
     * 慢性腎不全リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'ckd_risk_score'?: string;
    /**
     * うっ血性心不全リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'congestive_heart_failure_risk_score'?: string;
    /**
     * 皮膚型T細胞性リンパ腫リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'cutaneous_t_cell_lymphoma_risk_score'?: string;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'del'?: boolean;
    /**
     * 認知症リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'dementia_risk_score'?: string;
    /**
     * 皮膚疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'dermatological_disease_risk_score'?: string;
    /**
     * 下痢リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'diarrhea_risk_score'?: string;
    /**
     * 消化器疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'digestive_disease_risk_score'?: string;
    /**
     * 拡張型心筋症リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'dilated_cardiomyopathy_risk_score'?: string;
    /**
     * 多様性指数
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'diversity_index'?: string;
    /**
     * 糖尿病リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'dm_risk_score'?: string;
    /**
     * 膵外分泌不全リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'epi_risk_score'?: string;
    /**
     * てんかんリスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'epilepsy_score'?: string;
    /**
     * 食物アレルギーリスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'food_allergy_risk_score'?: string;
    /**
     * 胆石リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'gallstones_risk_score'?: string;
    /**
     * 善玉菌/悪玉菌指数
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'good_bacteria_bad_bacteria_index'?: string;
    /**
     * クッシング症候群リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'hyperadrenocorticism_risk_score'?: string;
    /**
     * 高血圧リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'hypertention_risk_score'?: string;
    /**
     * 炎症性腸疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'ibd_risk_score'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'id'?: number;
    /**
     * 腸リンパ管拡張症リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'il_risk_score'?: string;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'ins_ymd'?: string;
    /**
     * 腸内環境スコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'intestinal_environment_score'?: string;
    /**
     * 腸内環境タイプ
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'intestinal_environment_type'?: string;
    /**
     * かゆみリスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'itch_risk_score'?: string;
    /**
     * 腎結石リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'kidney_stones_risk_score'?: string;
    /**
     * 肝疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'liver_disease_risk_score'?: string;
    /**
     * リンパ腫リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'lymphoma_risk_score'?: string;
    /**
     * 代謝・内分泌疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'metabolic_and_endocrine_diseases_risk_score'?: string;
    /**
     * 僧帽弁閉鎖不全症リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'mitral_regurgitation_risk_score'?: string;
    /**
     * 多中心型リンパ腫リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'multicentric_lymphoma_risk_score'?: string;
    /**
     * 髄膜脳脊髄炎リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'muo_risk_score'?: string;
    /**
     * 腫瘍性疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'neoplastic_disease_risk_score'?: string;
    /**
     * 神経疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'neurologycal_disease_risk_score'?: string;
    /**
     * 腸内にいた細菌種数
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'number_of_bacteria_in_the_intestine'?: string;
    /**
     * 検査回数
     * @type {number}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'number_of_test'?: number;
    /**
     * 肥満リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'obesity_risk_score'?: string;
    /**
     * 眼科疾患_緑内障リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'ophthalmology_disease_glaucoma_risk_score'?: string;
    /**
     * 整形疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'orthopedic_disease_risk_score'?: string;
    /**
     * 変形性関節症リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'osteoarthritis_risk_score'?: string;
    /**
     * 悪玉菌の割合
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'percentage_of_bad_bacteria'?: string;
    /**
     * 善玉菌の割合
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'percentage_of_good_bacteria'?: string;
    /**
     * その他の割合
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'percentage_of_other'?: string;
    /**
     * 恐怖リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'phobia_risk_score'?: string;
    /**
     * ペットID
     * @type {number}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'pp_pet_id'?: number;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'revision'?: number;
    /**
     * 検体ID
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'sample_id'?: string;
    /**
     * 検体採取日
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'sampling_date'?: string;
    /**
     * 類似度指数
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'similarity_index'?: string;
    /**
     * ストレスのリスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'stress_risk_score'?: string;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'upd_ymd'?: string;
    /**
     * 腎泌尿器疾患リスクスコア
     * @type {string}
     * @memberof EntPpTestResultRiskscoreDog
     */
    'urologycal_disease_risk_score'?: string;
}
/**
 * 
 * @export
 * @interface EntPpTestStatus
 */
export interface EntPpTestStatus {
    /**
     * 検査完了時刻
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'completion_hms'?: string;
    /**
     * 検査完了年月日
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'completion_ymd'?: string;
    /**
     * 現在検査回数
     * @type {number}
     * @memberof EntPpTestStatus
     */
    'current_number_of_test'?: number;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpTestStatus
     */
    'del'?: boolean;
    /**
     * 菌組織情報取込
     * @type {boolean}
     * @memberof EntPpTestStatus
     */
    'get_bacteria_info'?: boolean;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpTestStatus
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'ins_ymd'?: string;
    /**
     * メタデータ出力
     * @type {boolean}
     * @memberof EntPpTestStatus
     */
    'metadata_out'?: boolean;
    /**
     * メタデータ出力時間
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'metadata_out_time'?: string;
    /**
     * メタデータ出力ユーザー名
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'metadata_out_user_name'?: string;
    /**
     * メタデータ出力日
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'metadata_out_ymd'?: string;
    /**
     * 検査依頼登録日
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'request_ymd'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpTestStatus
     */
    'revision'?: number;
    /**
     * 検体ID
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'sample_id'?: string;
    /**
     * 検査開始日
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'start_ymd'?: string;
    /**
     * 状況
     * @type {PpteststatusStatus}
     * @memberof EntPpTestStatus
     */
    'status'?: PpteststatusStatus;
    /**
     * 状況（ユーザー用）
     * @type {PpteststatusStatusForUser}
     * @memberof EntPpTestStatus
     */
    'status_for_user'?: PpteststatusStatusForUser;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpTestStatus
     */
    'upd_ymd'?: string;
}
/**
 * 
 * @export
 * @interface EntPpUser
 */
export interface EntPpUser {
    /**
     * 生年月日
     * @type {string}
     * @memberof EntPpUser
     */
    'birthday'?: string;
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof EntPpUser
     */
    'building'?: string;
    /**
     * 市区町村
     * @type {string}
     * @memberof EntPpUser
     */
    'city'?: string;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpUser
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpUserEdges}
     * @memberof EntPpUser
     */
    'edges'?: EntPpUserEdges;
    /**
     * メールアドレス
     * @type {string}
     * @memberof EntPpUser
     */
    'email'?: string;
    /**
     * 性別
     * @type {PpuserGender}
     * @memberof EntPpUser
     */
    'gender'?: PpuserGender;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpUser
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpUser
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpUser
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpUser
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpUser
     */
    'ins_ymd'?: string;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof EntPpUser
     */
    'last_login'?: string;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof EntPpUser
     */
    'last_login_ip'?: string;
    /**
     * パスワード
     * @type {string}
     * @memberof EntPpUser
     */
    'password'?: string;
    /**
     * 本人認証
     * @type {boolean}
     * @memberof EntPpUser
     */
    'personal_auth'?: boolean;
    /**
     * 郵便番号
     * @type {string}
     * @memberof EntPpUser
     */
    'postal_code'?: string;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof EntPpUser
     */
    'pref_code'?: string;
    /**
     * 登録病院ID
     * @type {number}
     * @memberof EntPpUser
     */
    'register_medic_inst_id'?: number;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpUser
     */
    'revision'?: number;
    /**
     * 丁目・番地
     * @type {string}
     * @memberof EntPpUser
     */
    'street'?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof EntPpUser
     */
    'tel_no'?: string;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpUser
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpUser
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpUser
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpUser
     */
    'upd_ymd'?: string;
    /**
     * 姓フリガナ
     * @type {string}
     * @memberof EntPpUser
     */
    'user_family_furigana'?: string;
    /**
     * 姓ローマ字
     * @type {string}
     * @memberof EntPpUser
     */
    'user_family_latin'?: string;
    /**
     * 姓
     * @type {string}
     * @memberof EntPpUser
     */
    'user_family_name'?: string;
    /**
     * 名フリガナ
     * @type {string}
     * @memberof EntPpUser
     */
    'user_first_furigana'?: string;
    /**
     * 名ローマ字
     * @type {string}
     * @memberof EntPpUser
     */
    'user_first_latin'?: string;
    /**
     * 名
     * @type {string}
     * @memberof EntPpUser
     */
    'user_first_name'?: string;
    /**
     * 利用規約同意
     * @type {boolean}
     * @memberof EntPpUser
     */
    'user_policy_consent'?: boolean;
}
/**
 * 
 * @export
 * @interface EntPpUserAuthCode
 */
export interface EntPpUserAuthCode {
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof EntPpUserAuthCode
     */
    'del'?: boolean;
    /**
     * 
     * @type {EntPpUserAuthCodeEdges}
     * @memberof EntPpUserAuthCode
     */
    'edges'?: EntPpUserAuthCodeEdges;
    /**
     * メールアドレス
     * @type {string}
     * @memberof EntPpUserAuthCode
     */
    'email'?: string;
    /**
     * 有効期限
     * @type {string}
     * @memberof EntPpUserAuthCode
     */
    'expiration_time'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof EntPpUserAuthCode
     */
    'id'?: number;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof EntPpUserAuthCode
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof EntPpUserAuthCode
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof EntPpUserAuthCode
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpUserAuthCode
     */
    'ins_ymd'?: string;
    /**
     * 本人認証コード(SHA256)
     * @type {string}
     * @memberof EntPpUserAuthCode
     */
    'personal_auth_code'?: string;
    /**
     * ユーザーID
     * @type {number}
     * @memberof EntPpUserAuthCode
     */
    'pp_user_id'?: number;
    /**
     * リビジョン
     * @type {number}
     * @memberof EntPpUserAuthCode
     */
    'revision'?: number;
    /**
     * 状況
     * @type {PpuserauthcodeStatus}
     * @memberof EntPpUserAuthCode
     */
    'status'?: PpuserauthcodeStatus;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof EntPpUserAuthCode
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof EntPpUserAuthCode
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof EntPpUserAuthCode
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof EntPpUserAuthCode
     */
    'upd_ymd'?: string;
}
/**
 * Edges holds the relations/edges for other nodes in the graph. The values are being populated by the PpUserAuthCodeQuery when eager-loading is set.
 * @export
 * @interface EntPpUserAuthCodeEdges
 */
export interface EntPpUserAuthCodeEdges {
    /**
     * 
     * @type {EntAccessTokenEdgesPpUser}
     * @memberof EntPpUserAuthCodeEdges
     */
    'pp_user'?: EntAccessTokenEdgesPpUser;
}
/**
 * Edges holds the relations/edges for other nodes in the graph. The values are being populated by the PpUserQuery when eager-loading is set.
 * @export
 * @interface EntPpUserEdges
 */
export interface EntPpUserEdges {
    /**
     * AccessTokens holds the value of the access_tokens edge.
     * @type {Array<EntAccessToken>}
     * @memberof EntPpUserEdges
     */
    'access_tokens'?: Array<EntAccessToken>;
    /**
     * PpPets holds the value of the pp_pets edge.
     * @type {Array<EntPpPet>}
     * @memberof EntPpUserEdges
     */
    'pp_pets'?: Array<EntPpPet>;
    /**
     * PpTestKits holds the value of the pp_test_kits edge.
     * @type {Array<EntPpTestKit>}
     * @memberof EntPpUserEdges
     */
    'pp_test_kits'?: Array<EntPpTestKit>;
    /**
     * PpUserAuthCodes holds the value of the pp_user_auth_codes edge.
     * @type {Array<EntPpUserAuthCode>}
     * @memberof EntPpUserEdges
     */
    'pp_user_auth_codes'?: Array<EntPpUserAuthCode>;
}
/**
 * 
 * @export
 * @interface FrontAuthControllerForgotRequest
 */
export interface FrontAuthControllerForgotRequest {
    /**
     * メールアドレス
     * @type {string}
     * @memberof FrontAuthControllerForgotRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface FrontAuthControllerLoginRequest
 */
export interface FrontAuthControllerLoginRequest {
    /**
     * メールアドレス
     * @type {string}
     * @memberof FrontAuthControllerLoginRequest
     */
    'email'?: string;
    /**
     * 平文パスワード
     * @type {string}
     * @memberof FrontAuthControllerLoginRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface FrontAuthControllerLoginResponse
 */
export interface FrontAuthControllerLoginResponse {
    /**
     * ログイン種別 3固定
     * @type {AccessTokenModelLoginType}
     * @memberof FrontAuthControllerLoginResponse
     */
    'login_type': AccessTokenModelLoginType;
    /**
     * 
     * @type {AdminAuthControllerLoginResponseSephirahAuthCertificate}
     * @memberof FrontAuthControllerLoginResponse
     */
    'sephirahAuthCertificate': AdminAuthControllerLoginResponseSephirahAuthCertificate;
}
/**
 * 
 * @export
 * @interface FrontAuthControllerMeResponse
 */
export interface FrontAuthControllerMeResponse {
    /**
     * ログイン種別。通常のログイン時は`3`、代行ログイン時は`4`が入ります。
     * @type {AccessTokenModelLoginType}
     * @memberof FrontAuthControllerMeResponse
     */
    'login_type': AccessTokenModelLoginType;
    /**
     * 
     * @type {FrontAuthControllerMeResponsePpUser}
     * @memberof FrontAuthControllerMeResponse
     */
    'pp_user': FrontAuthControllerMeResponsePpUser;
}
/**
 * 会員（飼い主）データ
 * @export
 * @interface FrontAuthControllerMeResponsePpUser
 */
export interface FrontAuthControllerMeResponsePpUser {
    /**
     * 生年月日
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'birthday': string;
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'building': string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'city': string;
    /**
     * 登録日
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'created': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'email': string;
    /**
     * 性別 male or female or other
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'gender': string;
    /**
     * 飼い主（会員）ID
     * @type {number}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'id': number;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'last_login': string | null;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'last_login_ip': string | null;
    /**
     * 郵便番号（000-1111）
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'postal_code': string;
    /**
     * 飼い主（会員）が登録しているペット情報
     * @type {Array<TypesPpPetData>}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'pp_pets'?: Array<TypesPpPetData>;
    /**
     * 都道府県
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'pref_code': string;
    /**
     * ステータス active or inactive
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'status': string;
    /**
     * 丁目・番地
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'street': string;
    /**
     * 電話番号
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'tel_no': string;
    /**
     * 姓カナ
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'user_family_furigana': string;
    /**
     * 姓
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'user_family_name': string;
    /**
     * 名カナ
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'user_first_furigana': string;
    /**
     * 名
     * @type {string}
     * @memberof FrontAuthControllerMeResponsePpUser
     */
    'user_first_name': string;
}
/**
 * 
 * @export
 * @interface FrontAuthControllerResetPasswordRequest
 */
export interface FrontAuthControllerResetPasswordRequest {
    /**
     * 新しいパスワード(確認用)
     * @type {string}
     * @memberof FrontAuthControllerResetPasswordRequest
     */
    'confirmation_password'?: string;
    /**
     * 新しいパスワード
     * @type {string}
     * @memberof FrontAuthControllerResetPasswordRequest
     */
    'password'?: string;
    /**
     * リセット用トークン
     * @type {string}
     * @memberof FrontAuthControllerResetPasswordRequest
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface HelloControllerIndexResponse
 */
export interface HelloControllerIndexResponse {
    /**
     * ブランチ
     * @type {string}
     * @memberof HelloControllerIndexResponse
     */
    'branch': string;
    /**
     * 起動タイプ
     * @type {string}
     * @memberof HelloControllerIndexResponse
     */
    'mode': string;
    /**
     * コミットハッシュ
     * @type {string}
     * @memberof HelloControllerIndexResponse
     */
    'revision': string;
    /**
     * バージョン
     * @type {string}
     * @memberof HelloControllerIndexResponse
     */
    'version': string;
}
/**
 * 
 * @export
 * @interface LogControllerIndexRequest
 */
export interface LogControllerIndexRequest {
    /**
     * CSVダウンロードの場合はtrueを指定する
     * @type {boolean}
     * @memberof LogControllerIndexRequest
     */
    'csv'?: boolean;
    /**
     * 取得開始(YYYY-MM-DD HH:mm:ss)
     * @type {string}
     * @memberof LogControllerIndexRequest
     */
    'from'?: string;
    /**
     * 取得件数
     * @type {number}
     * @memberof LogControllerIndexRequest
     */
    'limit'?: number;
    /**
     * ページ指定のトークン
     * @type {string}
     * @memberof LogControllerIndexRequest
     */
    'next_token'?: string | null;
    /**
     * 取得終了(YYYY-MM-DD HH:mm:ss)
     * @type {string}
     * @memberof LogControllerIndexRequest
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface LogControllerIndexResponse
 */
export interface LogControllerIndexResponse {
    /**
     * ログ一覧
     * @type {Array<AwscloudwatchLogMessage>}
     * @memberof LogControllerIndexResponse
     */
    'logs': Array<AwscloudwatchLogMessage>;
    /**
     * 次ページ指定のトークン
     * @type {string}
     * @memberof LogControllerIndexResponse
     */
    'next_token': string;
}
/**
 * 
 * @export
 * @interface PpAnnouncementAdminControllerCreateRequest
 */
export interface PpAnnouncementAdminControllerCreateRequest {
    /**
     * 本文
     * @type {string}
     * @memberof PpAnnouncementAdminControllerCreateRequest
     */
    'announcement_detail'?: string;
    /**
     * タイトル
     * @type {string}
     * @memberof PpAnnouncementAdminControllerCreateRequest
     */
    'announcement_title'?: string;
    /**
     * 表示開始 2023-04-11
     * @type {string}
     * @memberof PpAnnouncementAdminControllerCreateRequest
     */
    'display_period_from'?: string;
    /**
     * 表示終了 2023-04-20
     * @type {string}
     * @memberof PpAnnouncementAdminControllerCreateRequest
     */
    'display_period_to'?: string;
    /**
     * 表示日時 YYYY-MM-DD
     * @type {string}
     * @memberof PpAnnouncementAdminControllerCreateRequest
     */
    'display_ymd'?: string;
}
/**
 * 
 * @export
 * @interface PpAnnouncementAdminControllerEditRequest
 */
export interface PpAnnouncementAdminControllerEditRequest {
    /**
     * 本文
     * @type {string}
     * @memberof PpAnnouncementAdminControllerEditRequest
     */
    'announcement_detail'?: string;
    /**
     * タイトル
     * @type {string}
     * @memberof PpAnnouncementAdminControllerEditRequest
     */
    'announcement_title'?: string;
    /**
     * 表示開始 2023-04-11
     * @type {string}
     * @memberof PpAnnouncementAdminControllerEditRequest
     */
    'display_period_from'?: string;
    /**
     * 表示終了 2023-04-20
     * @type {string}
     * @memberof PpAnnouncementAdminControllerEditRequest
     */
    'display_period_to'?: string;
    /**
     * 表示日時 YYYY-MM-DD
     * @type {string}
     * @memberof PpAnnouncementAdminControllerEditRequest
     */
    'display_ymd'?: string;
}
/**
 * 
 * @export
 * @interface PpAnnouncementAdminControllerGetResponse
 */
export interface PpAnnouncementAdminControllerGetResponse {
    /**
     * 
     * @type {PpAnnouncementAdminControllerGetResponsePpAnnouncementAdmin}
     * @memberof PpAnnouncementAdminControllerGetResponse
     */
    'pp_announcement_admin': PpAnnouncementAdminControllerGetResponsePpAnnouncementAdmin;
}
/**
 * お知らせデータ
 * @export
 * @interface PpAnnouncementAdminControllerGetResponsePpAnnouncementAdmin
 */
export interface PpAnnouncementAdminControllerGetResponsePpAnnouncementAdmin {
    /**
     * 本文
     * @type {string}
     * @memberof PpAnnouncementAdminControllerGetResponsePpAnnouncementAdmin
     */
    'announcement_detail': string;
    /**
     * タイトル
     * @type {string}
     * @memberof PpAnnouncementAdminControllerGetResponsePpAnnouncementAdmin
     */
    'announcement_title': string;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpAnnouncementAdminControllerGetResponsePpAnnouncementAdmin
     */
    'created': string;
    /**
     * 表示開始 2023-04-11
     * @type {string}
     * @memberof PpAnnouncementAdminControllerGetResponsePpAnnouncementAdmin
     */
    'display_period_from': string;
    /**
     * 表示終了 2023-04-20
     * @type {string}
     * @memberof PpAnnouncementAdminControllerGetResponsePpAnnouncementAdmin
     */
    'display_period_to': string;
    /**
     * 表示日時 2023-04-11
     * @type {string}
     * @memberof PpAnnouncementAdminControllerGetResponsePpAnnouncementAdmin
     */
    'display_ymd': string;
    /**
     * 主キー
     * @type {number}
     * @memberof PpAnnouncementAdminControllerGetResponsePpAnnouncementAdmin
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PpAnnouncementAdminControllerIndexResponse
 */
export interface PpAnnouncementAdminControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpAnnouncementAdminData>}
     * @memberof PpAnnouncementAdminControllerIndexResponse
     */
    'pp_announcement_admins': Array<TypesPpAnnouncementAdminData>;
    /**
     * 全件数
     * @type {number}
     * @memberof PpAnnouncementAdminControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpAnnouncementUserControllerCreateRequest
 */
export interface PpAnnouncementUserControllerCreateRequest {
    /**
     * 本文
     * @type {string}
     * @memberof PpAnnouncementUserControllerCreateRequest
     */
    'announcement_detail'?: string;
    /**
     * タイトル
     * @type {string}
     * @memberof PpAnnouncementUserControllerCreateRequest
     */
    'announcement_title'?: string;
    /**
     * 表示開始 2023-04-11
     * @type {string}
     * @memberof PpAnnouncementUserControllerCreateRequest
     */
    'display_period_from'?: string;
    /**
     * 表示終了 2023-04-20
     * @type {string}
     * @memberof PpAnnouncementUserControllerCreateRequest
     */
    'display_period_to'?: string;
    /**
     * 表示日時 YYYY-MM-DD
     * @type {string}
     * @memberof PpAnnouncementUserControllerCreateRequest
     */
    'display_ymd'?: string;
}
/**
 * 
 * @export
 * @interface PpAnnouncementUserControllerEditRequest
 */
export interface PpAnnouncementUserControllerEditRequest {
    /**
     * 本文
     * @type {string}
     * @memberof PpAnnouncementUserControllerEditRequest
     */
    'announcement_detail'?: string;
    /**
     * タイトル
     * @type {string}
     * @memberof PpAnnouncementUserControllerEditRequest
     */
    'announcement_title'?: string;
    /**
     * 表示開始 2023-04-11
     * @type {string}
     * @memberof PpAnnouncementUserControllerEditRequest
     */
    'display_period_from'?: string;
    /**
     * 表示終了 2023-04-20
     * @type {string}
     * @memberof PpAnnouncementUserControllerEditRequest
     */
    'display_period_to'?: string;
    /**
     * 表示日時 YYYY-MM-DD
     * @type {string}
     * @memberof PpAnnouncementUserControllerEditRequest
     */
    'display_ymd'?: string;
}
/**
 * 
 * @export
 * @interface PpAnnouncementUserControllerFrontUnreadResponse
 */
export interface PpAnnouncementUserControllerFrontUnreadResponse {
    /**
     * 未読数
     * @type {number}
     * @memberof PpAnnouncementUserControllerFrontUnreadResponse
     */
    'unread': number;
}
/**
 * 
 * @export
 * @interface PpAnnouncementUserControllerGetResponse
 */
export interface PpAnnouncementUserControllerGetResponse {
    /**
     * 
     * @type {PpAnnouncementUserControllerGetResponsePpAnnouncementUser}
     * @memberof PpAnnouncementUserControllerGetResponse
     */
    'pp_announcement_user': PpAnnouncementUserControllerGetResponsePpAnnouncementUser;
}
/**
 * お知らせデータ
 * @export
 * @interface PpAnnouncementUserControllerGetResponsePpAnnouncementUser
 */
export interface PpAnnouncementUserControllerGetResponsePpAnnouncementUser {
    /**
     * 本文
     * @type {string}
     * @memberof PpAnnouncementUserControllerGetResponsePpAnnouncementUser
     */
    'announcement_detail': string;
    /**
     * お知らせタイトル
     * @type {string}
     * @memberof PpAnnouncementUserControllerGetResponsePpAnnouncementUser
     */
    'announcement_title': string;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpAnnouncementUserControllerGetResponsePpAnnouncementUser
     */
    'created': string;
    /**
     * 表示開始 2023-04-11
     * @type {string}
     * @memberof PpAnnouncementUserControllerGetResponsePpAnnouncementUser
     */
    'display_period_from': string;
    /**
     * 表示終了 2023-04-20
     * @type {string}
     * @memberof PpAnnouncementUserControllerGetResponsePpAnnouncementUser
     */
    'display_period_to': string;
    /**
     * 表示日時 2023-04-11
     * @type {string}
     * @memberof PpAnnouncementUserControllerGetResponsePpAnnouncementUser
     */
    'display_ymd': string;
    /**
     * 主キー
     * @type {number}
     * @memberof PpAnnouncementUserControllerGetResponsePpAnnouncementUser
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PpAnnouncementUserControllerIndexResponse
 */
export interface PpAnnouncementUserControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpAnnouncementUserData>}
     * @memberof PpAnnouncementUserControllerIndexResponse
     */
    'pp_announcement_users': Array<TypesPpAnnouncementUserData>;
    /**
     * 全件数
     * @type {number}
     * @memberof PpAnnouncementUserControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpContactControllerFrontCreateRequest
 */
export interface PpContactControllerFrontCreateRequest {
    /**
     * 問い合わせ内容
     * @type {string}
     * @memberof PpContactControllerFrontCreateRequest
     */
    'body'?: string;
    /**
     * 再入力メールアドレス
     * @type {string}
     * @memberof PpContactControllerFrontCreateRequest
     */
    'confirmation_email'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PpContactControllerFrontCreateRequest
     */
    'email'?: string;
    /**
     * バリデーションのみ行う場合trueを指定
     * @type {boolean}
     * @memberof PpContactControllerFrontCreateRequest
     */
    'is_only_validate'?: boolean;
    /**
     * 問い合わせ種別（例：ログインについて）
     * @type {string}
     * @memberof PpContactControllerFrontCreateRequest
     */
    'subject'?: string;
    /**
     * 姓(フリガナ)
     * @type {string}
     * @memberof PpContactControllerFrontCreateRequest
     */
    'user_family_furigana'?: string;
    /**
     * 姓
     * @type {string}
     * @memberof PpContactControllerFrontCreateRequest
     */
    'user_family_name'?: string;
    /**
     * 名(フリガナ)
     * @type {string}
     * @memberof PpContactControllerFrontCreateRequest
     */
    'user_first_furigana'?: string;
    /**
     * 名
     * @type {string}
     * @memberof PpContactControllerFrontCreateRequest
     */
    'user_first_name'?: string;
    /**
     * 利用規約への同意
     * @type {boolean}
     * @memberof PpContactControllerFrontCreateRequest
     */
    'user_policy_consent'?: boolean;
}
/**
 * 
 * @export
 * @interface PpFaqCateogryControllerCreateRequest
 */
export interface PpFaqCateogryControllerCreateRequest {
    /**
     * 表示フラグ
     * @type {boolean}
     * @memberof PpFaqCateogryControllerCreateRequest
     */
    'active'?: boolean;
    /**
     * カテゴリー名称
     * @type {string}
     * @memberof PpFaqCateogryControllerCreateRequest
     */
    'category_name'?: string;
}
/**
 * 
 * @export
 * @interface PpFaqCateogryControllerGetResponse
 */
export interface PpFaqCateogryControllerGetResponse {
    /**
     * 
     * @type {PpFaqCateogryControllerGetResponsePpFaqCategory}
     * @memberof PpFaqCateogryControllerGetResponse
     */
    'pp_faq_category': PpFaqCateogryControllerGetResponsePpFaqCategory;
}
/**
 * FAQカテゴリーデータ
 * @export
 * @interface PpFaqCateogryControllerGetResponsePpFaqCategory
 */
export interface PpFaqCateogryControllerGetResponsePpFaqCategory {
    /**
     * 表示フラグ
     * @type {boolean}
     * @memberof PpFaqCateogryControllerGetResponsePpFaqCategory
     */
    'active': boolean;
    /**
     * カテゴリ名
     * @type {string}
     * @memberof PpFaqCateogryControllerGetResponsePpFaqCategory
     */
    'category_name': string;
    /**
     * 主キー
     * @type {number}
     * @memberof PpFaqCateogryControllerGetResponsePpFaqCategory
     */
    'id': number;
    /**
     * 表示順
     * @type {number}
     * @memberof PpFaqCateogryControllerGetResponsePpFaqCategory
     */
    'sort_order': number;
}
/**
 * 
 * @export
 * @interface PpFaqCateogryControllerIndexResponse
 */
export interface PpFaqCateogryControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpFaqCategoryData>}
     * @memberof PpFaqCateogryControllerIndexResponse
     */
    'pp_faq_categories': Array<TypesPpFaqCategoryData>;
    /**
     * 全件数
     * @type {number}
     * @memberof PpFaqCateogryControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpFaqControllerCreateRequest
 */
export interface PpFaqControllerCreateRequest {
    /**
     * 表示フラグ
     * @type {boolean}
     * @memberof PpFaqControllerCreateRequest
     */
    'active'?: boolean;
    /**
     * 回答
     * @type {string}
     * @memberof PpFaqControllerCreateRequest
     */
    'answer'?: string;
    /**
     * カテゴリーID
     * @type {number}
     * @memberof PpFaqControllerCreateRequest
     */
    'faq_category_id'?: number;
    /**
     * 質問
     * @type {string}
     * @memberof PpFaqControllerCreateRequest
     */
    'question'?: string;
}
/**
 * 
 * @export
 * @interface PpFaqControllerFrontIndexResponse
 */
export interface PpFaqControllerFrontIndexResponse {
    /**
     * カテゴリー一覧(FAQデータも同梱)
     * @type {Array<TypesFrontPpFaqCategoryData>}
     * @memberof PpFaqControllerFrontIndexResponse
     */
    'faq_categories': Array<TypesFrontPpFaqCategoryData>;
}
/**
 * 
 * @export
 * @interface PpFaqControllerGetResponse
 */
export interface PpFaqControllerGetResponse {
    /**
     * 
     * @type {PpFaqControllerGetResponsePpFaq}
     * @memberof PpFaqControllerGetResponse
     */
    'pp_faq': PpFaqControllerGetResponsePpFaq;
}
/**
 * FAQデータ
 * @export
 * @interface PpFaqControllerGetResponsePpFaq
 */
export interface PpFaqControllerGetResponsePpFaq {
    /**
     * 表示フラグ
     * @type {boolean}
     * @memberof PpFaqControllerGetResponsePpFaq
     */
    'active': boolean;
    /**
     * 回答
     * @type {string}
     * @memberof PpFaqControllerGetResponsePpFaq
     */
    'answer': string;
    /**
     * カテゴリー名称
     * @type {string}
     * @memberof PpFaqControllerGetResponsePpFaq
     */
    'category_name': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof PpFaqControllerGetResponsePpFaq
     */
    'created': string;
    /**
     * カテゴリーID
     * @type {number}
     * @memberof PpFaqControllerGetResponsePpFaq
     */
    'faq_category_id': number;
    /**
     * 主キー
     * @type {number}
     * @memberof PpFaqControllerGetResponsePpFaq
     */
    'id': number;
    /**
     * 質問
     * @type {string}
     * @memberof PpFaqControllerGetResponsePpFaq
     */
    'question': string;
    /**
     * 表示順
     * @type {number}
     * @memberof PpFaqControllerGetResponsePpFaq
     */
    'sort_order': number;
}
/**
 * 
 * @export
 * @interface PpFaqControllerIndexRequest
 */
export interface PpFaqControllerIndexRequest {
    /**
     * 回答
     * @type {string}
     * @memberof PpFaqControllerIndexRequest
     */
    'answer'?: string;
    /**
     * ページ番号
     * @type {number}
     * @memberof PpFaqControllerIndexRequest
     */
    'page'?: number;
    /**
     * カテゴリーID
     * @type {number}
     * @memberof PpFaqControllerIndexRequest
     */
    'pp_faq_category_id'?: number;
    /**
     * 質問
     * @type {string}
     * @memberof PpFaqControllerIndexRequest
     */
    'question'?: string;
    /**
     * ページあたりの取得件数
     * @type {number}
     * @memberof PpFaqControllerIndexRequest
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface PpFaqControllerIndexResponse
 */
export interface PpFaqControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpFaqData>}
     * @memberof PpFaqControllerIndexResponse
     */
    'pp_faqs': Array<TypesPpFaqData>;
    /**
     * 全件数
     * @type {number}
     * @memberof PpFaqControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpFaqControllerSortRequest
 */
export interface PpFaqControllerSortRequest {
    /**
     * 新しい表示順設定のID配列
     * @type {Array<number>}
     * @memberof PpFaqControllerSortRequest
     */
    'ids'?: Array<number>;
}
/**
 * 
 * @export
 * @interface PpFlowcellControllerGetResponse
 */
export interface PpFlowcellControllerGetResponse {
    /**
     * 
     * @type {PpFlowcellControllerGetResponsePpFlowcell}
     * @memberof PpFlowcellControllerGetResponse
     */
    'pp_flowcell': PpFlowcellControllerGetResponsePpFlowcell;
}
/**
 * Flow Cellデータ
 * @export
 * @interface PpFlowcellControllerGetResponsePpFlowcell
 */
export interface PpFlowcellControllerGetResponsePpFlowcell {
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof PpFlowcellControllerGetResponsePpFlowcell
     */
    'flowcell_id': string;
    /**
     * ステータス 0:未使用、1:検査中、2:検査完了
     * @type {PpflowcellStatus}
     * @memberof PpFlowcellControllerGetResponsePpFlowcell
     */
    'status': PpflowcellStatus;
}
/**
 * 
 * @export
 * @interface PpMaintenanceControllerGetResponse
 */
export interface PpMaintenanceControllerGetResponse {
    /**
     * 
     * @type {PpMaintenanceControllerGetResponsePpMaintenance}
     * @memberof PpMaintenanceControllerGetResponse
     */
    'pp_maintenance': PpMaintenanceControllerGetResponsePpMaintenance;
}
/**
 * メンテナンス時刻データ
 * @export
 * @interface PpMaintenanceControllerGetResponsePpMaintenance
 */
export interface PpMaintenanceControllerGetResponsePpMaintenance {
    /**
     * 開始時刻 HH:MM:SS
     * @type {string}
     * @memberof PpMaintenanceControllerGetResponsePpMaintenance
     */
    'mainte_from_hms': string | null;
    /**
     * 終了時刻 HH:MM:SS
     * @type {string}
     * @memberof PpMaintenanceControllerGetResponsePpMaintenance
     */
    'mainte_to_hms': string | null;
    /**
     * メンテナンス日付 YYYY-MM-DD
     * @type {string}
     * @memberof PpMaintenanceControllerGetResponsePpMaintenance
     */
    'mainte_ymd': string | null;
}
/**
 * 
 * @export
 * @interface PpMaintenanceControllerSetRequest
 */
export interface PpMaintenanceControllerSetRequest {
    /**
     * メンテナンス開始時刻 HH:MM:SS
     * @type {string}
     * @memberof PpMaintenanceControllerSetRequest
     */
    'mainte_hms_from'?: string;
    /**
     * メンテナンス終了時刻 HH:MM:SS
     * @type {string}
     * @memberof PpMaintenanceControllerSetRequest
     */
    'mainte_hms_to'?: string;
    /**
     * メンテナンス開始日時 YYYY-MM-DD
     * @type {string}
     * @memberof PpMaintenanceControllerSetRequest
     */
    'mainte_ymd'?: string;
}
/**
 * 
 * @export
 * @interface PpMstBreedControllerGetResponse
 */
export interface PpMstBreedControllerGetResponse {
    /**
     * 
     * @type {PpMstBreedControllerGetResponsePpMstBreed}
     * @memberof PpMstBreedControllerGetResponse
     */
    'pp_mst_breed': PpMstBreedControllerGetResponsePpMstBreed;
}
/**
 * 品種情報
 * @export
 * @interface PpMstBreedControllerGetResponsePpMstBreed
 */
export interface PpMstBreedControllerGetResponsePpMstBreed {
    /**
     * ペットの種類
     * @type {string}
     * @memberof PpMstBreedControllerGetResponsePpMstBreed
     */
    'animal_type': string;
    /**
     * 品種名
     * @type {string}
     * @memberof PpMstBreedControllerGetResponsePpMstBreed
     */
    'breed_name': string;
    /**
     * コード番号
     * @type {number}
     * @memberof PpMstBreedControllerGetResponsePpMstBreed
     */
    'code_id': number;
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstBreedControllerGetResponsePpMstBreed
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PpMstBreedModelGetListResponse
 */
export interface PpMstBreedModelGetListResponse {
    /**
     * ペットの種類 1->犬 2->猫
     * @type {string}
     * @memberof PpMstBreedModelGetListResponse
     */
    'animal_type'?: string;
    /**
     * 品種名
     * @type {string}
     * @memberof PpMstBreedModelGetListResponse
     */
    'breed_name'?: string;
    /**
     * 品種別コード
     * @type {number}
     * @memberof PpMstBreedModelGetListResponse
     */
    'code_id'?: number;
    /**
     * 主キー サーバに送信するbreed_id
     * @type {number}
     * @memberof PpMstBreedModelGetListResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface PpMstGenericRcmndGroupControllerCreateRequest
 */
export interface PpMstGenericRcmndGroupControllerCreateRequest {
    /**
     * 汎用リコメンド表示方式 1：個別に表示、2：グループ単位にまとめて表示
     * @type {string}
     * @memberof PpMstGenericRcmndGroupControllerCreateRequest
     */
    'generic_rcmnd_display_method'?: string;
    /**
     * 汎用リコメンドグループタイトル
     * @type {string}
     * @memberof PpMstGenericRcmndGroupControllerCreateRequest
     */
    'generic_rcmnd_group_title'?: string;
}
/**
 * 
 * @export
 * @interface PpMstGenericRcmndGroupControllerGetResponse
 */
export interface PpMstGenericRcmndGroupControllerGetResponse {
    /**
     * 
     * @type {PpMstGenericRcmndGroupControllerGetResponsePpMstGenericRcmndGroup}
     * @memberof PpMstGenericRcmndGroupControllerGetResponse
     */
    'pp_mst_generic_rcmnd_group': PpMstGenericRcmndGroupControllerGetResponsePpMstGenericRcmndGroup;
}
/**
 * 汎用リコメンドグループデータ
 * @export
 * @interface PpMstGenericRcmndGroupControllerGetResponsePpMstGenericRcmndGroup
 */
export interface PpMstGenericRcmndGroupControllerGetResponsePpMstGenericRcmndGroup {
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpMstGenericRcmndGroupControllerGetResponsePpMstGenericRcmndGroup
     */
    'created': string;
    /**
     * 汎用リコメンド表示方式 1：個別に表示、2：グループ単位にまとめて表示
     * @type {PpmstgenericrcmndgroupGenericRcmndDisplayMethod}
     * @memberof PpMstGenericRcmndGroupControllerGetResponsePpMstGenericRcmndGroup
     */
    'generic_rcmnd_display_method': PpmstgenericrcmndgroupGenericRcmndDisplayMethod;
    /**
     * 汎用リコメンドグループタイトル
     * @type {string}
     * @memberof PpMstGenericRcmndGroupControllerGetResponsePpMstGenericRcmndGroup
     */
    'generic_rcmnd_group_title': string;
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstGenericRcmndGroupControllerGetResponsePpMstGenericRcmndGroup
     */
    'id': number;
    /**
     * 更新日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpMstGenericRcmndGroupControllerGetResponsePpMstGenericRcmndGroup
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface PpMstGenericRcmndGroupControllerIndexRequest
 */
export interface PpMstGenericRcmndGroupControllerIndexRequest {
    /**
     * 汎用リコメンド表示方式 1：個別に表示、2：グループ単位にまとめて表示
     * @type {string}
     * @memberof PpMstGenericRcmndGroupControllerIndexRequest
     */
    'generic_rcmnd_display_method'?: string;
    /**
     * 汎用リコメンドグループタイトル
     * @type {string}
     * @memberof PpMstGenericRcmndGroupControllerIndexRequest
     */
    'generic_rcmnd_group_title'?: string;
    /**
     * ページ番号
     * @type {number}
     * @memberof PpMstGenericRcmndGroupControllerIndexRequest
     */
    'page'?: number;
    /**
     * ページあたりの取得件数
     * @type {number}
     * @memberof PpMstGenericRcmndGroupControllerIndexRequest
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface PpMstGenericRcmndGroupControllerIndexResponse
 */
export interface PpMstGenericRcmndGroupControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpMstGenericRcmndGroupData>}
     * @memberof PpMstGenericRcmndGroupControllerIndexResponse
     */
    'pp_mst_generic_rcmnd_groups': Array<TypesPpMstGenericRcmndGroupData>;
    /**
     * 件数
     * @type {number}
     * @memberof PpMstGenericRcmndGroupControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpMstGenericRcmndGroupControllerListResponse
 */
export interface PpMstGenericRcmndGroupControllerListResponse {
    /**
     * リストデータ
     * @type {Array<PpMstGenericRcmndGroupModelGetListResponse>}
     * @memberof PpMstGenericRcmndGroupControllerListResponse
     */
    'data': Array<PpMstGenericRcmndGroupModelGetListResponse>;
}
/**
 * 
 * @export
 * @interface PpMstGenericRcmndGroupModelGetListResponse
 */
export interface PpMstGenericRcmndGroupModelGetListResponse {
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstGenericRcmndGroupModelGetListResponse
     */
    'id'?: number;
    /**
     * タイトル
     * @type {string}
     * @memberof PpMstGenericRcmndGroupModelGetListResponse
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface PpMstGenericRcmndImageControllerCreateRequest
 */
export interface PpMstGenericRcmndImageControllerCreateRequest {
    /**
     * 動物種類 1：犬、2：猫
     * @type {string}
     * @memberof PpMstGenericRcmndImageControllerCreateRequest
     */
    'animal_type'?: string;
    /**
     * 表示順位
     * @type {number}
     * @memberof PpMstGenericRcmndImageControllerCreateRequest
     */
    'display_order'?: number;
    /**
     * 汎用リコメンドグループID
     * @type {number}
     * @memberof PpMstGenericRcmndImageControllerCreateRequest
     */
    'generic_rcmnd_group_id'?: number;
    /**
     * イメージ概要
     * @type {string}
     * @memberof PpMstGenericRcmndImageControllerCreateRequest
     */
    'image_overview'?: string;
    /**
     * 画像ファイル保存先パス
     * @type {string}
     * @memberof PpMstGenericRcmndImageControllerCreateRequest
     */
    'image_path'?: string;
    /**
     * 腸内環境タイプ A：現状維持型、B：多様性補強型、C：善玉菌補強型、D：多様性／善玉菌補強型、Z：腸内環境タイプ不問
     * @type {string}
     * @memberof PpMstGenericRcmndImageControllerCreateRequest
     */
    'intestinal_environment_type'?: string;
}
/**
 * 
 * @export
 * @interface PpMstGenericRcmndImageControllerGetResponse
 */
export interface PpMstGenericRcmndImageControllerGetResponse {
    /**
     * 
     * @type {PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage}
     * @memberof PpMstGenericRcmndImageControllerGetResponse
     */
    'pp_mst_generic_rcmnd_image': PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage;
}
/**
 * 汎用リコメンドイメージデータ
 * @export
 * @interface PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage
 */
export interface PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage {
    /**
     * 動物種類 1：犬、2：猫
     * @type {PpmstgenericrcmndimageAnimalType}
     * @memberof PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage
     */
    'animal_type': PpmstgenericrcmndimageAnimalType;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage
     */
    'created': string;
    /**
     * 表示順位
     * @type {number}
     * @memberof PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage
     */
    'display_order': number;
    /**
     * 汎用リコメンドグループID
     * @type {number}
     * @memberof PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage
     */
    'generic_rcmnd_group_id': number;
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage
     */
    'id': number;
    /**
     * 画像ファイル保存先パス ファイル名
     * @type {string}
     * @memberof PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage
     */
    'image_name': string;
    /**
     * イメージ概要
     * @type {string}
     * @memberof PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage
     */
    'image_overview': string;
    /**
     * 画像ファイル保存先パス
     * @type {string}
     * @memberof PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage
     */
    'image_path': string;
    /**
     * 画像ファイル保存先パス 閲覧URL
     * @type {string}
     * @memberof PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage
     */
    'image_url': string;
    /**
     * 腸内環境タイプ A：現状維持型、B：多様性補強型、C：善玉菌補強型、D：多様性／善玉菌補強型、Z：腸内環境タイプ不問
     * @type {PpmstgenericrcmndimageIntestinalEnvironmentType}
     * @memberof PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage
     */
    'intestinal_environment_type': PpmstgenericrcmndimageIntestinalEnvironmentType;
    /**
     * 
     * @type {PpMstGenericRcmndGroupControllerGetResponsePpMstGenericRcmndGroup}
     * @memberof PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage
     */
    'pp_mst_generic_rcmnd_group': PpMstGenericRcmndGroupControllerGetResponsePpMstGenericRcmndGroup;
    /**
     * 更新日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpMstGenericRcmndImageControllerGetResponsePpMstGenericRcmndImage
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface PpMstGenericRcmndImageControllerIndexRequest
 */
export interface PpMstGenericRcmndImageControllerIndexRequest {
    /**
     * 動物種類 1：犬、2：猫
     * @type {string}
     * @memberof PpMstGenericRcmndImageControllerIndexRequest
     */
    'animal_type'?: string;
    /**
     * イメージ概要
     * @type {string}
     * @memberof PpMstGenericRcmndImageControllerIndexRequest
     */
    'image_overview'?: string;
    /**
     * 腸内環境タイプ A：現状維持型、B：多様性補強型、C：善玉菌補強型、D：多様性／善玉菌補強型、Z：腸内環境タイプ不問
     * @type {string}
     * @memberof PpMstGenericRcmndImageControllerIndexRequest
     */
    'intestinal_environment_type'?: string;
    /**
     * ページ番号
     * @type {number}
     * @memberof PpMstGenericRcmndImageControllerIndexRequest
     */
    'page'?: number;
    /**
     * ページあたりの取得件数
     * @type {number}
     * @memberof PpMstGenericRcmndImageControllerIndexRequest
     */
    'size'?: number;
    /**
     * 汎用リコメンドグループのタイトル
     * @type {string}
     * @memberof PpMstGenericRcmndImageControllerIndexRequest
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface PpMstGenericRcmndImageControllerIndexResponse
 */
export interface PpMstGenericRcmndImageControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpMstGenericRcmndImageData>}
     * @memberof PpMstGenericRcmndImageControllerIndexResponse
     */
    'pp_mst_generic_rcmnd_images': Array<TypesPpMstGenericRcmndImageData>;
    /**
     * 件数
     * @type {number}
     * @memberof PpMstGenericRcmndImageControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpMstIdentificationCdControllerCreateRequest
 */
export interface PpMstIdentificationCdControllerCreateRequest {
    /**
     * 権限レベル
     * @type {PpmstidentificationcdAuthorityLevel}
     * @memberof PpMstIdentificationCdControllerCreateRequest
     */
    'authority_level'?: PpmstidentificationcdAuthorityLevel;
    /**
     * 識別コード_番号
     * @type {string}
     * @memberof PpMstIdentificationCdControllerCreateRequest
     */
    'code_number'?: string;
    /**
     * 説明
     * @type {string}
     * @memberof PpMstIdentificationCdControllerCreateRequest
     */
    'description'?: string;
    /**
     * 識別コード_グルーピングコード
     * @type {string}
     * @memberof PpMstIdentificationCdControllerCreateRequest
     */
    'grouping_code'?: string;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof PpMstIdentificationCdControllerCreateRequest
     */
    'partner_inst_id'?: number;
}
/**
 * 
 * @export
 * @interface PpMstIdentificationCdControllerGetResponse
 */
export interface PpMstIdentificationCdControllerGetResponse {
    /**
     * 
     * @type {PpMstIdentificationCdControllerGetResponsePpMstIdentificationCd}
     * @memberof PpMstIdentificationCdControllerGetResponse
     */
    'pp_mst_identification_cd': PpMstIdentificationCdControllerGetResponsePpMstIdentificationCd;
}
/**
 * 識別コードデータ
 * @export
 * @interface PpMstIdentificationCdControllerGetResponsePpMstIdentificationCd
 */
export interface PpMstIdentificationCdControllerGetResponsePpMstIdentificationCd {
    /**
     * 権限レベル
     * @type {PpmstidentificationcdAuthorityLevel}
     * @memberof PpMstIdentificationCdControllerGetResponsePpMstIdentificationCd
     */
    'authority_level': PpmstidentificationcdAuthorityLevel;
    /**
     * 識別コード_番号
     * @type {string}
     * @memberof PpMstIdentificationCdControllerGetResponsePpMstIdentificationCd
     */
    'code_number': string;
    /**
     * 登録日
     * @type {string}
     * @memberof PpMstIdentificationCdControllerGetResponsePpMstIdentificationCd
     */
    'created': string;
    /**
     * 説明
     * @type {string}
     * @memberof PpMstIdentificationCdControllerGetResponsePpMstIdentificationCd
     */
    'description': string | null;
    /**
     * 識別コード_グルーピングコード
     * @type {string}
     * @memberof PpMstIdentificationCdControllerGetResponsePpMstIdentificationCd
     */
    'grouping_code': string;
    /**
     * ID
     * @type {number}
     * @memberof PpMstIdentificationCdControllerGetResponsePpMstIdentificationCd
     */
    'id': number;
    /**
     * 識別コード
     * @type {string}
     * @memberof PpMstIdentificationCdControllerGetResponsePpMstIdentificationCd
     */
    'identification_cd': string;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof PpMstIdentificationCdControllerGetResponsePpMstIdentificationCd
     */
    'partner_inst_id': number;
    /**
     * 提携法人名
     * @type {string}
     * @memberof PpMstIdentificationCdControllerGetResponsePpMstIdentificationCd
     */
    'partner_inst_name': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof PpMstIdentificationCdControllerGetResponsePpMstIdentificationCd
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface PpMstIdentificationCdControllerIndexRequest
 */
export interface PpMstIdentificationCdControllerIndexRequest {
    /**
     * 権限レベル
     * @type {string}
     * @memberof PpMstIdentificationCdControllerIndexRequest
     */
    'authority_level'?: string;
    /**
     * 説明
     * @type {string}
     * @memberof PpMstIdentificationCdControllerIndexRequest
     */
    'description'?: string;
    /**
     * 識別コード
     * @type {string}
     * @memberof PpMstIdentificationCdControllerIndexRequest
     */
    'identification_cd'?: string;
    /**
     * ページ番号
     * @type {number}
     * @memberof PpMstIdentificationCdControllerIndexRequest
     */
    'page'?: number;
    /**
     * パートナー施設ID
     * @type {number}
     * @memberof PpMstIdentificationCdControllerIndexRequest
     */
    'partner_inst_id'?: number;
    /**
     * ページあたりの取得件数
     * @type {number}
     * @memberof PpMstIdentificationCdControllerIndexRequest
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface PpMstIdentificationCdControllerIndexResponse
 */
export interface PpMstIdentificationCdControllerIndexResponse {
    /**
     * 識別コードデータの配列
     * @type {Array<TypesPpMstIdentificationCdData>}
     * @memberof PpMstIdentificationCdControllerIndexResponse
     */
    'pp_mst_identification_cds': Array<TypesPpMstIdentificationCdData>;
    /**
     * 全件数
     * @type {number}
     * @memberof PpMstIdentificationCdControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpMstMeatureCommentModelPagingRequest
 */
export interface PpMstMeatureCommentModelPagingRequest {
    /**
     * 
     * @type {number}
     * @memberof PpMstMeatureCommentModelPagingRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpMstMeatureCommentModelPagingRequest
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface PpMstMeatureCommentsControllerCreateRequest
 */
export interface PpMstMeatureCommentsControllerCreateRequest {
    /**
     * 対象カテゴリ
     * @type {string}
     * @memberof PpMstMeatureCommentsControllerCreateRequest
     */
    'measure_category'?: string;
    /**
     * 対策コメント表示位置コード
     * @type {string}
     * @memberof PpMstMeatureCommentsControllerCreateRequest
     */
    'measure_comment_position_id'?: string;
    /**
     * 対策コメント_内容
     * @type {string}
     * @memberof PpMstMeatureCommentsControllerCreateRequest
     */
    'measure_comment_text'?: string;
}
/**
 * 
 * @export
 * @interface PpMstMeatureCommentsControllerGetAllResponse
 */
export interface PpMstMeatureCommentsControllerGetAllResponse {
    /**
     * 対策コメントデータ
     * @type {Array<TypesPpMstMeatureCommentData>}
     * @memberof PpMstMeatureCommentsControllerGetAllResponse
     */
    'pp_mst_meature_comments': Array<TypesPpMstMeatureCommentData>;
}
/**
 * 
 * @export
 * @interface PpMstMeatureCommentsControllerGetResponse
 */
export interface PpMstMeatureCommentsControllerGetResponse {
    /**
     * 
     * @type {PpMstMeatureCommentsControllerGetResponsePpMstMeatureComment}
     * @memberof PpMstMeatureCommentsControllerGetResponse
     */
    'pp_mst_meature_comment': PpMstMeatureCommentsControllerGetResponsePpMstMeatureComment;
}
/**
 * 対策コメントデータ
 * @export
 * @interface PpMstMeatureCommentsControllerGetResponsePpMstMeatureComment
 */
export interface PpMstMeatureCommentsControllerGetResponsePpMstMeatureComment {
    /**
     * 作成日時
     * @type {string}
     * @memberof PpMstMeatureCommentsControllerGetResponsePpMstMeatureComment
     */
    'created': string;
    /**
     * ID
     * @type {number}
     * @memberof PpMstMeatureCommentsControllerGetResponsePpMstMeatureComment
     */
    'id': number;
    /**
     * 対象カテゴリ
     * @type {string}
     * @memberof PpMstMeatureCommentsControllerGetResponsePpMstMeatureComment
     */
    'measure_category': string;
    /**
     * 対策コメント表示位置コード
     * @type {string}
     * @memberof PpMstMeatureCommentsControllerGetResponsePpMstMeatureComment
     */
    'measure_comment_position_id': string;
    /**
     * 対策コメント_内容
     * @type {string}
     * @memberof PpMstMeatureCommentsControllerGetResponsePpMstMeatureComment
     */
    'measure_comment_text': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof PpMstMeatureCommentsControllerGetResponsePpMstMeatureComment
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface PpMstMeatureCommentsControllerIndexResponse
 */
export interface PpMstMeatureCommentsControllerIndexResponse {
    /**
     * 対策コメント一覧
     * @type {Array<TypesPpMstMeatureCommentSingleData>}
     * @memberof PpMstMeatureCommentsControllerIndexResponse
     */
    'pp_mst_meature_comments': Array<TypesPpMstMeatureCommentSingleData>;
    /**
     * データ総数
     * @type {number}
     * @memberof PpMstMeatureCommentsControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpMstPartnerInstControllerCreateRequest
 */
export interface PpMstPartnerInstControllerCreateRequest {
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof PpMstPartnerInstControllerCreateRequest
     */
    'building'?: string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof PpMstPartnerInstControllerCreateRequest
     */
    'city'?: string;
    /**
     * 企業名
     * @type {string}
     * @memberof PpMstPartnerInstControllerCreateRequest
     */
    'partner_inst_name'?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof PpMstPartnerInstControllerCreateRequest
     */
    'postal_code'?: string;
    /**
     * 営業取引種別ID
     * @type {number}
     * @memberof PpMstPartnerInstControllerCreateRequest
     */
    'pp_mst_partner_supplier_type_id'?: number;
    /**
     * 都道府県
     * @type {string}
     * @memberof PpMstPartnerInstControllerCreateRequest
     */
    'pref_code'?: string;
    /**
     * 番地
     * @type {string}
     * @memberof PpMstPartnerInstControllerCreateRequest
     */
    'street'?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof PpMstPartnerInstControllerCreateRequest
     */
    'tel_no'?: string;
}
/**
 * 
 * @export
 * @interface PpMstPartnerInstControllerEditRequest
 */
export interface PpMstPartnerInstControllerEditRequest {
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof PpMstPartnerInstControllerEditRequest
     */
    'building'?: string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof PpMstPartnerInstControllerEditRequest
     */
    'city'?: string;
    /**
     * 企業名
     * @type {string}
     * @memberof PpMstPartnerInstControllerEditRequest
     */
    'partner_inst_name'?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof PpMstPartnerInstControllerEditRequest
     */
    'postal_code'?: string;
    /**
     * 営業取引種別ID
     * @type {number}
     * @memberof PpMstPartnerInstControllerEditRequest
     */
    'pp_mst_partner_supplier_type_id'?: number;
    /**
     * 都道府県
     * @type {string}
     * @memberof PpMstPartnerInstControllerEditRequest
     */
    'pref_code'?: string;
    /**
     * 番地
     * @type {string}
     * @memberof PpMstPartnerInstControllerEditRequest
     */
    'street'?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof PpMstPartnerInstControllerEditRequest
     */
    'tel_no'?: string;
}
/**
 * 
 * @export
 * @interface PpMstPartnerInstControllerGetResponse
 */
export interface PpMstPartnerInstControllerGetResponse {
    /**
     * 
     * @type {PpMstPartnerInstControllerGetResponsePpMstPartnerInst}
     * @memberof PpMstPartnerInstControllerGetResponse
     */
    'pp_mst_partner_inst': PpMstPartnerInstControllerGetResponsePpMstPartnerInst;
}
/**
 * 企業データ
 * @export
 * @interface PpMstPartnerInstControllerGetResponsePpMstPartnerInst
 */
export interface PpMstPartnerInstControllerGetResponsePpMstPartnerInst {
    /**
     * ビル名
     * @type {string}
     * @memberof PpMstPartnerInstControllerGetResponsePpMstPartnerInst
     */
    'building': string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof PpMstPartnerInstControllerGetResponsePpMstPartnerInst
     */
    'city': string | null;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpMstPartnerInstControllerGetResponsePpMstPartnerInst
     */
    'created': string;
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstPartnerInstControllerGetResponsePpMstPartnerInst
     */
    'id': number;
    /**
     * 提携法人名
     * @type {string}
     * @memberof PpMstPartnerInstControllerGetResponsePpMstPartnerInst
     */
    'partner_inst_name': string;
    /**
     * 
     * @type {TypesPpMstPartnerInstDataPartnerSupplierType}
     * @memberof PpMstPartnerInstControllerGetResponsePpMstPartnerInst
     */
    'partner_supplier_type': TypesPpMstPartnerInstDataPartnerSupplierType;
    /**
     * 営業取引種別ID
     * @type {number}
     * @memberof PpMstPartnerInstControllerGetResponsePpMstPartnerInst
     */
    'partner_supplier_type_id': number;
    /**
     * 郵便番号
     * @type {string}
     * @memberof PpMstPartnerInstControllerGetResponsePpMstPartnerInst
     */
    'postal_code': string | null;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof PpMstPartnerInstControllerGetResponsePpMstPartnerInst
     */
    'pref_code': string | null;
    /**
     * 番地
     * @type {string}
     * @memberof PpMstPartnerInstControllerGetResponsePpMstPartnerInst
     */
    'street': string | null;
    /**
     * TEL
     * @type {string}
     * @memberof PpMstPartnerInstControllerGetResponsePpMstPartnerInst
     */
    'tel_no': string | null;
}
/**
 * 
 * @export
 * @interface PpMstPartnerInstControllerIndexResponse
 */
export interface PpMstPartnerInstControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpMstPartnerInstData>}
     * @memberof PpMstPartnerInstControllerIndexResponse
     */
    'pp_mst_partner_insts': Array<TypesPpMstPartnerInstData>;
    /**
     * 全件数
     * @type {number}
     * @memberof PpMstPartnerInstControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpMstPartnerInstControllerListResponse
 */
export interface PpMstPartnerInstControllerListResponse {
    /**
     * リストデータ
     * @type {Array<PpMstPartnerInstModelGetListResponse>}
     * @memberof PpMstPartnerInstControllerListResponse
     */
    'data': Array<PpMstPartnerInstModelGetListResponse>;
}
/**
 * 
 * @export
 * @interface PpMstPartnerInstModelGetListResponse
 */
export interface PpMstPartnerInstModelGetListResponse {
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstPartnerInstModelGetListResponse
     */
    'id'?: number;
    /**
     * 企業名
     * @type {string}
     * @memberof PpMstPartnerInstModelGetListResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PpMstPartnerSupplierTypeControllerGetResponse
 */
export interface PpMstPartnerSupplierTypeControllerGetResponse {
    /**
     * 
     * @type {PpMstPartnerSupplierTypeControllerGetResponsePpMstPartnerSupplierType}
     * @memberof PpMstPartnerSupplierTypeControllerGetResponse
     */
    'pp_mst_partner_supplier_type': PpMstPartnerSupplierTypeControllerGetResponsePpMstPartnerSupplierType;
}
/**
 * 営業取引種別情報
 * @export
 * @interface PpMstPartnerSupplierTypeControllerGetResponsePpMstPartnerSupplierType
 */
export interface PpMstPartnerSupplierTypeControllerGetResponsePpMstPartnerSupplierType {
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstPartnerSupplierTypeControllerGetResponsePpMstPartnerSupplierType
     */
    'id': number;
    /**
     * 営業取引種別名
     * @type {string}
     * @memberof PpMstPartnerSupplierTypeControllerGetResponsePpMstPartnerSupplierType
     */
    'partner_supplier_name': string;
}
/**
 * 
 * @export
 * @interface PpMstPartnerSupplierTypeControllerListResponse
 */
export interface PpMstPartnerSupplierTypeControllerListResponse {
    /**
     * リストデータ
     * @type {Array<PpMstPartnerSupplierTypeModelGetListResponse>}
     * @memberof PpMstPartnerSupplierTypeControllerListResponse
     */
    'data': Array<PpMstPartnerSupplierTypeModelGetListResponse>;
}
/**
 * 
 * @export
 * @interface PpMstPartnerSupplierTypeModelGetListResponse
 */
export interface PpMstPartnerSupplierTypeModelGetListResponse {
    /**
     * 主キー 営業取引種別ID
     * @type {number}
     * @memberof PpMstPartnerSupplierTypeModelGetListResponse
     */
    'id'?: number;
    /**
     * 営業取引種別名
     * @type {string}
     * @memberof PpMstPartnerSupplierTypeModelGetListResponse
     */
    'partner_supplier_name'?: string;
}
/**
 * 
 * @export
 * @interface PpMstRcmndMethodControllerCreateRequest
 */
export interface PpMstRcmndMethodControllerCreateRequest {
    /**
     * 汎用リコメンドタイトル
     * @type {string}
     * @memberof PpMstRcmndMethodControllerCreateRequest
     */
    'generic_rcmnd_title'?: string;
    /**
     * 病院ID
     * @type {number}
     * @memberof PpMstRcmndMethodControllerCreateRequest
     */
    'medic_inst_id'?: number;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof PpMstRcmndMethodControllerCreateRequest
     */
    'partner_inst_id'?: number;
    /**
     * リコメンド表示パターン 0：表示なし、1：汎用のみ、2：個別おすすめ商品のみ(横表示)、3：個別おすすめ商品のみ(縦表示)、4：汎用＋個別おすすめ商品(横表示)、5：汎用＋個別おすすめ商品(縦表示)、6：個別おすすめ商品のみ(縦表示＋横表示)、7：フル表示
     * @type {string}
     * @memberof PpMstRcmndMethodControllerCreateRequest
     */
    'rcmnd_display_pattern'?: string;
    /**
     * リコメンド方式ラベル
     * @type {string}
     * @memberof PpMstRcmndMethodControllerCreateRequest
     */
    'rcmnd_method_label'?: string;
    /**
     * SKU
     * @type {string}
     * @memberof PpMstRcmndMethodControllerCreateRequest
     */
    'sku_code'?: string;
}
/**
 * 
 * @export
 * @interface PpMstRcmndMethodControllerGetResponse
 */
export interface PpMstRcmndMethodControllerGetResponse {
    /**
     * 
     * @type {PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod}
     * @memberof PpMstRcmndMethodControllerGetResponse
     */
    'pp_mst_rcmnd_method': PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod;
}
/**
 * リコメンド方式データ
 * @export
 * @interface PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod
 */
export interface PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod {
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod
     */
    'created': string;
    /**
     * 汎用リコメンドタイトル
     * @type {string}
     * @memberof PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod
     */
    'generic_rcmnd_title': string | null;
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod
     */
    'id': number;
    /**
     * 
     * @type {TypesPpMstRcmndMethodDataMedicInst}
     * @memberof PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod
     */
    'medic_inst'?: TypesPpMstRcmndMethodDataMedicInst | null;
    /**
     * 病院ID
     * @type {number}
     * @memberof PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod
     */
    'medic_inst_id': number;
    /**
     * 
     * @type {TypesPpMstRcmndMethodDataPartnerInst}
     * @memberof PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod
     */
    'partner_inst'?: TypesPpMstRcmndMethodDataPartnerInst;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod
     */
    'partner_inst_id': number | null;
    /**
     * リコメンド表示パターン 0：表示なし、1：汎用のみ、2：個別おすすめ商品のみ(横表示)、3：個別おすすめ商品のみ(縦表示)、4：汎用＋個別おすすめ商品(横表示)、5：汎用＋個別おすすめ商品(縦表示)、6：個別おすすめ商品のみ(縦表示＋横表示)、7：フル表示
     * @type {PpmstrcmndmethodRcmndDisplayPattern}
     * @memberof PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod
     */
    'rcmnd_display_pattern': PpmstrcmndmethodRcmndDisplayPattern;
    /**
     * リコメンド方式ラベル
     * @type {string}
     * @memberof PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod
     */
    'rcmnd_method_label': string | null;
    /**
     * SKU
     * @type {string}
     * @memberof PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod
     */
    'sku_code': string;
    /**
     * 更新日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface PpMstRcmndMethodControllerIndexRequest
 */
export interface PpMstRcmndMethodControllerIndexRequest {
    /**
     * 汎用リコメンドタイトル
     * @type {string}
     * @memberof PpMstRcmndMethodControllerIndexRequest
     */
    'generic_rcmnd_title'?: string;
    /**
     * 病院ID
     * @type {number}
     * @memberof PpMstRcmndMethodControllerIndexRequest
     */
    'medic_inst_id'?: number;
    /**
     * ページ番号
     * @type {number}
     * @memberof PpMstRcmndMethodControllerIndexRequest
     */
    'page'?: number;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof PpMstRcmndMethodControllerIndexRequest
     */
    'partner_inst_id'?: number;
    /**
     * リコメンド表示パターン 0：表示なし、1：汎用のみ、2：個別おすすめ商品のみ(横表示)、3：個別おすすめ商品のみ(縦表示)、4：汎用＋個別おすすめ商品(横表示)、5：汎用＋個別おすすめ商品(縦表示)、6：個別おすすめ商品のみ(縦表示＋横表示)、7：フル表示
     * @type {string}
     * @memberof PpMstRcmndMethodControllerIndexRequest
     */
    'rcmnd_display_pattern'?: string;
    /**
     * ページあたりの取得件数
     * @type {number}
     * @memberof PpMstRcmndMethodControllerIndexRequest
     */
    'size'?: number;
    /**
     * SKU
     * @type {string}
     * @memberof PpMstRcmndMethodControllerIndexRequest
     */
    'sku_code'?: string;
}
/**
 * 
 * @export
 * @interface PpMstRcmndMethodControllerIndexResponse
 */
export interface PpMstRcmndMethodControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpMstRcmndMethodData>}
     * @memberof PpMstRcmndMethodControllerIndexResponse
     */
    'pp_mst_rcmnd_methods': Array<TypesPpMstRcmndMethodData>;
    /**
     * 件数
     * @type {number}
     * @memberof PpMstRcmndMethodControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpMstRcmndMethodControllerListResponse
 */
export interface PpMstRcmndMethodControllerListResponse {
    /**
     * リストデータ
     * @type {Array<PpMstRcmndMethodModelGetListResponse>}
     * @memberof PpMstRcmndMethodControllerListResponse
     */
    'data': Array<PpMstRcmndMethodModelGetListResponse>;
}
/**
 * 
 * @export
 * @interface PpMstRcmndMethodModelGenericCard
 */
export interface PpMstRcmndMethodModelGenericCard {
    /**
     * 画像
     * @type {Array<PpMstRcmndMethodModelGenericImage>}
     * @memberof PpMstRcmndMethodModelGenericCard
     */
    'images'?: Array<PpMstRcmndMethodModelGenericImage>;
    /**
     * カードタイトル
     * @type {string}
     * @memberof PpMstRcmndMethodModelGenericCard
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface PpMstRcmndMethodModelGenericImage
 */
export interface PpMstRcmndMethodModelGenericImage {
    /**
     * 画像URL
     * @type {string}
     * @memberof PpMstRcmndMethodModelGenericImage
     */
    'image_url'?: string;
    /**
     * 画像ラベル
     * @type {string}
     * @memberof PpMstRcmndMethodModelGenericImage
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface PpMstRcmndMethodModelGenericRcmndData
 */
export interface PpMstRcmndMethodModelGenericRcmndData {
    /**
     * カード
     * @type {Array<PpMstRcmndMethodModelGenericCard>}
     * @memberof PpMstRcmndMethodModelGenericRcmndData
     */
    'cards'?: Array<PpMstRcmndMethodModelGenericCard>;
    /**
     * エリアタイトル
     * @type {string}
     * @memberof PpMstRcmndMethodModelGenericRcmndData
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface PpMstRcmndMethodModelGetListResponse
 */
export interface PpMstRcmndMethodModelGetListResponse {
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstRcmndMethodModelGetListResponse
     */
    'id'?: number;
    /**
     * タイトル
     * @type {string}
     * @memberof PpMstRcmndMethodModelGetListResponse
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface PpMstRcmndMethodModelGetRcmndDataResponse
 */
export interface PpMstRcmndMethodModelGetRcmndDataResponse {
    /**
     * 
     * @type {PpMstRcmndMethodModelGetRcmndDataResponseGenericRcmndData}
     * @memberof PpMstRcmndMethodModelGetRcmndDataResponse
     */
    'generic_rcmnd_data'?: PpMstRcmndMethodModelGetRcmndDataResponseGenericRcmndData | null;
    /**
     * 横型リコメンドデータ
     * @type {Array<PpMstRcmndMethodModelHorizontalRcmndData>}
     * @memberof PpMstRcmndMethodModelGetRcmndDataResponse
     */
    'horizontal_rcmnd_data'?: Array<PpMstRcmndMethodModelHorizontalRcmndData>;
    /**
     * 縦型リコメンドデータ
     * @type {Array<PpMstRcmndMethodModelVerticalRcmndData>}
     * @memberof PpMstRcmndMethodModelGetRcmndDataResponse
     */
    'vertical_rcmnd_data'?: Array<PpMstRcmndMethodModelVerticalRcmndData>;
}
/**
 * 汎用リコメンドデータ
 * @export
 * @interface PpMstRcmndMethodModelGetRcmndDataResponseGenericRcmndData
 */
export interface PpMstRcmndMethodModelGetRcmndDataResponseGenericRcmndData {
    /**
     * カード
     * @type {Array<PpMstRcmndMethodModelGenericCard>}
     * @memberof PpMstRcmndMethodModelGetRcmndDataResponseGenericRcmndData
     */
    'cards'?: Array<PpMstRcmndMethodModelGenericCard>;
    /**
     * エリアタイトル
     * @type {string}
     * @memberof PpMstRcmndMethodModelGetRcmndDataResponseGenericRcmndData
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface PpMstRcmndMethodModelHorizontalCard
 */
export interface PpMstRcmndMethodModelHorizontalCard {
    /**
     * ECサイトリンク1
     * @type {string}
     * @memberof PpMstRcmndMethodModelHorizontalCard
     */
    'ec_site_link_1': string | null;
    /**
     * 画像URL
     * @type {string}
     * @memberof PpMstRcmndMethodModelHorizontalCard
     */
    'image': string;
    /**
     * 商品区分
     * @type {string}
     * @memberof PpMstRcmndMethodModelHorizontalCard
     */
    'product_type': string;
}
/**
 * 
 * @export
 * @interface PpMstRcmndMethodModelHorizontalRcmndData
 */
export interface PpMstRcmndMethodModelHorizontalRcmndData {
    /**
     * カード
     * @type {Array<PpMstRcmndMethodModelHorizontalCard>}
     * @memberof PpMstRcmndMethodModelHorizontalRcmndData
     */
    'cards'?: Array<PpMstRcmndMethodModelHorizontalCard>;
    /**
     * エリアタイトル
     * @type {string}
     * @memberof PpMstRcmndMethodModelHorizontalRcmndData
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface PpMstRcmndMethodModelVerticalCard
 */
export interface PpMstRcmndMethodModelVerticalCard {
    /**
     * ECサイトリンク1
     * @type {string}
     * @memberof PpMstRcmndMethodModelVerticalCard
     */
    'ec_site_link_1': string | null;
    /**
     * ECサイトリンク1
     * @type {string}
     * @memberof PpMstRcmndMethodModelVerticalCard
     */
    'ec_site_link_2': string | null;
    /**
     * 画像URLの配列
     * @type {Array<string>}
     * @memberof PpMstRcmndMethodModelVerticalCard
     */
    'images': Array<string>;
    /**
     * 商品説明
     * @type {string}
     * @memberof PpMstRcmndMethodModelVerticalCard
     */
    'product_description': string | null;
}
/**
 * 
 * @export
 * @interface PpMstRcmndMethodModelVerticalRcmndData
 */
export interface PpMstRcmndMethodModelVerticalRcmndData {
    /**
     * カード
     * @type {Array<PpMstRcmndMethodModelVerticalCard>}
     * @memberof PpMstRcmndMethodModelVerticalRcmndData
     */
    'cards'?: Array<PpMstRcmndMethodModelVerticalCard>;
    /**
     * エリアタイトル
     * @type {string}
     * @memberof PpMstRcmndMethodModelVerticalRcmndData
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface PpMstRcmndProductControllerCreateRequest
 */
export interface PpMstRcmndProductControllerCreateRequest {
    /**
     * 商品リンク1「フード付き検査キット」ボタンに対応するリンク先
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'ecsite_link_1'?: string | null;
    /**
     * 商品リンク2「フードのみ」ボタンに対応するリンク先
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'ecsite_link_2'?: string | null;
    /**
     * 腸内環境タイプ
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'intestinal_environment_type'?: string;
    /**
     * 優先順位
     * @type {number}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'priority'?: number;
    /**
     * 商品コード
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'product_code'?: string;
    /**
     * 商品説明
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'product_description'?: string | null;
    /**
     * 商品画像1
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'product_image_01'?: string | null;
    /**
     * 商品画像2
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'product_image_02'?: string | null;
    /**
     * 商品画像3
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'product_image_03'?: string | null;
    /**
     * 商品画像4
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'product_image_04'?: string | null;
    /**
     * 商品画像5
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'product_image_05'?: string | null;
    /**
     * 商品画像6
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'product_image_06'?: string | null;
    /**
     * 商品画像7
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'product_image_07'?: string | null;
    /**
     * 商品画像8
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'product_image_08'?: string | null;
    /**
     * 商品画像9
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'product_image_09'?: string | null;
    /**
     * 商品画像10
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'product_image_10'?: string | null;
    /**
     * 商品名
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'product_name'?: string | null;
    /**
     * 商品区分 1：おすすめ商品、2：サプリ
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'product_type'?: string | null;
    /**
     * リコメンド商品タイトルID
     * @type {number}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'rcmnd_products_title_id'?: number;
    /**
     * リコメンド要否区分
     * @type {string}
     * @memberof PpMstRcmndProductControllerCreateRequest
     */
    'recommend_type'?: string;
}
/**
 * 
 * @export
 * @interface PpMstRcmndProductControllerGetResponse
 */
export interface PpMstRcmndProductControllerGetResponse {
    /**
     * 
     * @type {PpMstRcmndProductControllerGetResponsePpMstRcmndProduct}
     * @memberof PpMstRcmndProductControllerGetResponse
     */
    'pp_mst_rcmnd_product': PpMstRcmndProductControllerGetResponsePpMstRcmndProduct;
}
/**
 * おすすめ商品データ
 * @export
 * @interface PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
 */
export interface PpMstRcmndProductControllerGetResponsePpMstRcmndProduct {
    /**
     * 商品リンク1「フード付き検査キット」ボタンに対応するリンク先
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'ecsite_link_1': string | null;
    /**
     * 商品リンク2「フードのみ」ボタンに対応するリンク先
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'ecsite_link_2': string | null;
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'id': number;
    /**
     * 腸内環境タイプ A：現状維持型、B：多様性補強型、C：善玉菌補強型、D：多様性／善玉菌補強型、Z：腸内環境タイプ不
     * @type {PpmstrcmndproductIntestinalEnvironmentType}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'intestinal_environment_type': PpmstrcmndproductIntestinalEnvironmentType;
    /**
     * 
     * @type {TypesPpMstRcmndProductDataPpMstRcmndProductsTitle}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'pp_mst_rcmnd_products_title'?: TypesPpMstRcmndProductDataPpMstRcmndProductsTitle;
    /**
     * 優先順位
     * @type {number}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'priority': number;
    /**
     * 商品コード
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_code': string;
    /**
     * 商品説明
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_description': string;
    /**
     * 商品画像1
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_01': string;
    /**
     * 商品画像1 ファイル名
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_01_name': string;
    /**
     * 商品画像1 閲覧URL
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_01_url': string;
    /**
     * 商品画像2
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_02': string;
    /**
     * 商品画像2 ファイル名
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_02_name': string;
    /**
     * 商品画像2 閲覧URL
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_02_url': string;
    /**
     * 商品画像3
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_03': string;
    /**
     * 商品画像3 ファイル名
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_03_name': string;
    /**
     * 商品画像3 閲覧URL
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_03_url': string;
    /**
     * 商品画像4
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_04': string;
    /**
     * 商品画像4 ファイル名
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_04_name': string;
    /**
     * 商品画像4 閲覧URL
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_04_url': string;
    /**
     * 商品画像5
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_05': string;
    /**
     * 商品画像5 ファイル名
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_05_name': string;
    /**
     * 商品画像5 閲覧URL
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_05_url': string;
    /**
     * 商品画像6
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_06': string;
    /**
     * 商品画像6 ファイル名
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_06_name': string;
    /**
     * 商品画像6 閲覧URL
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_06_url': string;
    /**
     * 商品画像7
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_07': string;
    /**
     * 商品画像7 ファイル名
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_07_name': string;
    /**
     * 商品画像7 閲覧URL
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_07_url': string;
    /**
     * 商品画像8
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_08': string;
    /**
     * 商品画像8 ファイル名
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_08_name': string;
    /**
     * 商品画像8 閲覧URL
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_08_url': string;
    /**
     * 商品画像9
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_09': string;
    /**
     * 商品画像9 ファイル名
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_09_name': string;
    /**
     * 商品画像9 閲覧URL
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_09_url': string;
    /**
     * 商品画像10
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_10': string;
    /**
     * 商品画像10 ファイル名
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_10_name': string;
    /**
     * 商品画像10 閲覧URL
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_image_10_url': string;
    /**
     * 商品名
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_name': string;
    /**
     * 商品区分
     * @type {string}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'product_type': string;
    /**
     * リコメンド商品タイトルID
     * @type {number}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'rcmnd_products_tittle_id': number;
    /**
     * リコメンド要否区分 0：商品おすすめなし、1：腸内環境タイプ別商品おすすめ、2：腸内環境タイプ共通商品おすすめ
     * @type {PpmstrcmndproductRecommendType}
     * @memberof PpMstRcmndProductControllerGetResponsePpMstRcmndProduct
     */
    'recommend_type': PpmstrcmndproductRecommendType;
}
/**
 * 
 * @export
 * @interface PpMstRcmndProductControllerIndexRequest
 */
export interface PpMstRcmndProductControllerIndexRequest {
    /**
     * 表示箇所
     * @type {string}
     * @memberof PpMstRcmndProductControllerIndexRequest
     */
    'display_location'?: string;
    /**
     * 腸内環境タイプ
     * @type {string}
     * @memberof PpMstRcmndProductControllerIndexRequest
     */
    'intestinal_environment_type'?: string;
    /**
     * ページ番号
     * @type {number}
     * @memberof PpMstRcmndProductControllerIndexRequest
     */
    'page'?: number;
    /**
     * 商品コード
     * @type {string}
     * @memberof PpMstRcmndProductControllerIndexRequest
     */
    'product_code'?: string;
    /**
     * 商品名
     * @type {string}
     * @memberof PpMstRcmndProductControllerIndexRequest
     */
    'product_name'?: string;
    /**
     * リコメンド商品タイトルID
     * @type {number}
     * @memberof PpMstRcmndProductControllerIndexRequest
     */
    'rcmnd_products_title_id'?: number;
    /**
     * リコメンド要否区分
     * @type {string}
     * @memberof PpMstRcmndProductControllerIndexRequest
     */
    'recommend_type'?: string;
    /**
     * ページあたりの取得件数
     * @type {number}
     * @memberof PpMstRcmndProductControllerIndexRequest
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface PpMstRcmndProductControllerIndexResponse
 */
export interface PpMstRcmndProductControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpMstRcmndProductData>}
     * @memberof PpMstRcmndProductControllerIndexResponse
     */
    'pp_mst_rcmnd_products': Array<TypesPpMstRcmndProductData>;
    /**
     * 件数
     * @type {number}
     * @memberof PpMstRcmndProductControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpMstRcmndProductsTitleControllerCreateRequest
 */
export interface PpMstRcmndProductsTitleControllerCreateRequest {
    /**
     * リコメンドタイトル表示箇所 1：おすすめ商品_縦、2：おすすめ商品_横1段目、3：おすすめ商品_横2段目、4：おすすめ商品_横3段目
     * @type {string}
     * @memberof PpMstRcmndProductsTitleControllerCreateRequest
     */
    'display_location'?: string;
    /**
     * リコメンド方式ID
     * @type {number}
     * @memberof PpMstRcmndProductsTitleControllerCreateRequest
     */
    'rcmnd_method_id'?: number;
    /**
     * リコメンドタイトル
     * @type {string}
     * @memberof PpMstRcmndProductsTitleControllerCreateRequest
     */
    'recommend_title'?: string;
}
/**
 * 
 * @export
 * @interface PpMstRcmndProductsTitleControllerGetResponse
 */
export interface PpMstRcmndProductsTitleControllerGetResponse {
    /**
     * 
     * @type {PpMstRcmndProductsTitleControllerGetResponsePpMstRcmndProductsTitle}
     * @memberof PpMstRcmndProductsTitleControllerGetResponse
     */
    'pp_mst_rcmnd_products_title': PpMstRcmndProductsTitleControllerGetResponsePpMstRcmndProductsTitle;
}
/**
 * 個別商品リコメンドタイトルデータ
 * @export
 * @interface PpMstRcmndProductsTitleControllerGetResponsePpMstRcmndProductsTitle
 */
export interface PpMstRcmndProductsTitleControllerGetResponsePpMstRcmndProductsTitle {
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpMstRcmndProductsTitleControllerGetResponsePpMstRcmndProductsTitle
     */
    'created': string;
    /**
     * リコメンドタイトル表示箇所 1：おすすめ商品_縦、2：おすすめ商品_横1段目、3：おすすめ商品_横2段目、4：おすすめ商品_横3段目
     * @type {PpmstrcmndproductstitleDisplayLocation}
     * @memberof PpMstRcmndProductsTitleControllerGetResponsePpMstRcmndProductsTitle
     */
    'display_location': PpmstrcmndproductstitleDisplayLocation;
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstRcmndProductsTitleControllerGetResponsePpMstRcmndProductsTitle
     */
    'id': number;
    /**
     * 
     * @type {PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod}
     * @memberof PpMstRcmndProductsTitleControllerGetResponsePpMstRcmndProductsTitle
     */
    'pp_mst_rcmnd_method': PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod;
    /**
     * リコメンド方式ID
     * @type {number}
     * @memberof PpMstRcmndProductsTitleControllerGetResponsePpMstRcmndProductsTitle
     */
    'rcmnd_method_id': number;
    /**
     * リコメンドタイトル
     * @type {string}
     * @memberof PpMstRcmndProductsTitleControllerGetResponsePpMstRcmndProductsTitle
     */
    'recommend_title': string;
    /**
     * 更新日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpMstRcmndProductsTitleControllerGetResponsePpMstRcmndProductsTitle
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface PpMstRcmndProductsTitleControllerIndexRequest
 */
export interface PpMstRcmndProductsTitleControllerIndexRequest {
    /**
     * リコメンドタイトル表示箇所 1：おすすめ商品_縦、2：おすすめ商品_横1段目、3：おすすめ商品_横2段目、4：おすすめ商品_横3段目
     * @type {string}
     * @memberof PpMstRcmndProductsTitleControllerIndexRequest
     */
    'display_location'?: string;
    /**
     * ページ番号
     * @type {number}
     * @memberof PpMstRcmndProductsTitleControllerIndexRequest
     */
    'page'?: number;
    /**
     * リコメンド方式ID
     * @type {number}
     * @memberof PpMstRcmndProductsTitleControllerIndexRequest
     */
    'rcmnd_method_id'?: number;
    /**
     * リコメンドタイトル
     * @type {string}
     * @memberof PpMstRcmndProductsTitleControllerIndexRequest
     */
    'recommend_title'?: string;
    /**
     * ページあたりの取得件数
     * @type {number}
     * @memberof PpMstRcmndProductsTitleControllerIndexRequest
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface PpMstRcmndProductsTitleControllerIndexResponse
 */
export interface PpMstRcmndProductsTitleControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpMstRcmndProductsTitleData>}
     * @memberof PpMstRcmndProductsTitleControllerIndexResponse
     */
    'pp_mst_rcmnd_products_titles': Array<TypesPpMstRcmndProductsTitleData>;
    /**
     * 件数
     * @type {number}
     * @memberof PpMstRcmndProductsTitleControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpMstRcmndProductsTitleControllerListResponse
 */
export interface PpMstRcmndProductsTitleControllerListResponse {
    /**
     * リストデータ
     * @type {Array<PpMstRcmndProductsTitleModelGetListResponse>}
     * @memberof PpMstRcmndProductsTitleControllerListResponse
     */
    'data': Array<PpMstRcmndProductsTitleModelGetListResponse>;
}
/**
 * 
 * @export
 * @interface PpMstRcmndProductsTitleModelGetListResponse
 */
export interface PpMstRcmndProductsTitleModelGetListResponse {
    /**
     * 表示箇所
     * @type {PpmstrcmndproductstitleDisplayLocation}
     * @memberof PpMstRcmndProductsTitleModelGetListResponse
     */
    'display_location'?: PpmstrcmndproductstitleDisplayLocation;
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstRcmndProductsTitleModelGetListResponse
     */
    'id'?: number;
    /**
     * レコメンドタイトル
     * @type {string}
     * @memberof PpMstRcmndProductsTitleModelGetListResponse
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface PpMstSkuControllerCreateRequest
 */
export interface PpMstSkuControllerCreateRequest {
    /**
     * 説明
     * @type {string}
     * @memberof PpMstSkuControllerCreateRequest
     */
    'description'?: string;
    /**
     * 商品付帯有無
     * @type {string}
     * @memberof PpMstSkuControllerCreateRequest
     */
    'food_included'?: string;
    /**
     * 商品リコメンドオプション有無 0: なし、1: あり
     * @type {string}
     * @memberof PpMstSkuControllerCreateRequest
     */
    'rcmnd_availability'?: string;
    /**
     * SKUコード
     * @type {string}
     * @memberof PpMstSkuControllerCreateRequest
     */
    'sku_code'?: string;
}
/**
 * 
 * @export
 * @interface PpMstSkuControllerEditRequest
 */
export interface PpMstSkuControllerEditRequest {
    /**
     * 説明
     * @type {string}
     * @memberof PpMstSkuControllerEditRequest
     */
    'description'?: string;
    /**
     * 商品付帯有無
     * @type {string}
     * @memberof PpMstSkuControllerEditRequest
     */
    'food_included'?: string;
    /**
     * 商品リコメンドオプション有無 0: なし、1: あり
     * @type {string}
     * @memberof PpMstSkuControllerEditRequest
     */
    'rcmnd_availability'?: string;
    /**
     * SKUコード
     * @type {string}
     * @memberof PpMstSkuControllerEditRequest
     */
    'sku_code'?: string;
}
/**
 * 
 * @export
 * @interface PpMstSkuControllerGetResponse
 */
export interface PpMstSkuControllerGetResponse {
    /**
     * 
     * @type {PpMstSkuControllerGetResponsePpMstSku}
     * @memberof PpMstSkuControllerGetResponse
     */
    'pp_mst_sku': PpMstSkuControllerGetResponsePpMstSku;
}
/**
 * SKUデータ
 * @export
 * @interface PpMstSkuControllerGetResponsePpMstSku
 */
export interface PpMstSkuControllerGetResponsePpMstSku {
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpMstSkuControllerGetResponsePpMstSku
     */
    'created': string;
    /**
     * 説明
     * @type {string}
     * @memberof PpMstSkuControllerGetResponsePpMstSku
     */
    'description': string;
    /**
     * 商品付帯有無
     * @type {boolean}
     * @memberof PpMstSkuControllerGetResponsePpMstSku
     */
    'food_included': boolean;
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstSkuControllerGetResponsePpMstSku
     */
    'id': number;
    /**
     * 商品リコメンドオプション有無 0: なし、1: あり
     * @type {PpmstskuRcmndAvailability}
     * @memberof PpMstSkuControllerGetResponsePpMstSku
     */
    'rcmnd_availability': PpmstskuRcmndAvailability;
    /**
     * SKU
     * @type {string}
     * @memberof PpMstSkuControllerGetResponsePpMstSku
     */
    'sku_code': string;
    /**
     * 更新日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpMstSkuControllerGetResponsePpMstSku
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface PpMstSkuControllerIndexResponse
 */
export interface PpMstSkuControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpMstSkuData>}
     * @memberof PpMstSkuControllerIndexResponse
     */
    'pp_mst_skus': Array<TypesPpMstSkuData>;
    /**
     * 件数
     * @type {number}
     * @memberof PpMstSkuControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpMstSkuControllerListRequest
 */
export interface PpMstSkuControllerListRequest {
    /**
     * 商品リコメンドオプション有無が「1：あり」だけに絞り込む場合true
     * @type {boolean}
     * @memberof PpMstSkuControllerListRequest
     */
    'only_option_1'?: boolean;
}
/**
 * 
 * @export
 * @interface PpMstSkuControllerListResponse
 */
export interface PpMstSkuControllerListResponse {
    /**
     * リストデータ
     * @type {Array<PpMstSkuModelGetListResponse>}
     * @memberof PpMstSkuControllerListResponse
     */
    'data': Array<PpMstSkuModelGetListResponse>;
}
/**
 * 
 * @export
 * @interface PpMstSkuModelGetListResponse
 */
export interface PpMstSkuModelGetListResponse {
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstSkuModelGetListResponse
     */
    'id'?: number;
    /**
     * SKUコード
     * @type {string}
     * @memberof PpMstSkuModelGetListResponse
     */
    'sku_code'?: string;
}
/**
 * 
 * @export
 * @interface PpMstTestKitControllerIndexRequest
 */
export interface PpMstTestKitControllerIndexRequest {
    /**
     * ページ番号
     * @type {number}
     * @memberof PpMstTestKitControllerIndexRequest
     */
    'page'?: number;
    /**
     * 検体ID
     * @type {string}
     * @memberof PpMstTestKitControllerIndexRequest
     */
    'sample_id'?: string;
    /**
     * ページあたりの取得件数
     * @type {number}
     * @memberof PpMstTestKitControllerIndexRequest
     */
    'size'?: number;
    /**
     * 検索分 1->犬 2->猫
     * @type {string}
     * @memberof PpMstTestKitControllerIndexRequest
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface PpMstTestKitControllerIndexResponse
 */
export interface PpMstTestKitControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpMstTestKitData>}
     * @memberof PpMstTestKitControllerIndexResponse
     */
    'pp_mst_test_kits': Array<TypesPpMstTestKitData>;
    /**
     * 件数
     * @type {number}
     * @memberof PpMstTestKitControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpMstThresholdCatModelGetEvaluationMapResponse
 */
export interface PpMstThresholdCatModelGetEvaluationMapResponse {
    /**
     * リスクスコアのカラム名
     * @type {string}
     * @memberof PpMstThresholdCatModelGetEvaluationMapResponse
     */
    'column_name'?: string;
    /**
     * 評価値 1〜5 1:低い 5:高い
     * @type {PpmstthresholdcatRiskEvaluation}
     * @memberof PpMstThresholdCatModelGetEvaluationMapResponse
     */
    'risk_evaluation'?: PpmstthresholdcatRiskEvaluation;
    /**
     * リスク名
     * @type {string}
     * @memberof PpMstThresholdCatModelGetEvaluationMapResponse
     */
    'risk_name'?: string;
}
/**
 * 
 * @export
 * @interface PpMstThresholdCatModelGetThresholdMapResponse
 */
export interface PpMstThresholdCatModelGetThresholdMapResponse {
    /**
     * 評価レベル（1、3、5のいずれか）
     * @type {number}
     * @memberof PpMstThresholdCatModelGetThresholdMapResponse
     */
    'risk_evaluation': number;
    /**
     * リスクスコアFrom
     * @type {number}
     * @memberof PpMstThresholdCatModelGetThresholdMapResponse
     */
    'risk_score_from': number;
    /**
     * リスクスコアTo
     * @type {number}
     * @memberof PpMstThresholdCatModelGetThresholdMapResponse
     */
    'risk_score_to': number;
}
/**
 * 
 * @export
 * @interface PpMstThresholdDogModelGetEvaluationMapResponse
 */
export interface PpMstThresholdDogModelGetEvaluationMapResponse {
    /**
     * リスクスコアのカラム名
     * @type {string}
     * @memberof PpMstThresholdDogModelGetEvaluationMapResponse
     */
    'column_name'?: string;
    /**
     * 評価値 1〜5 1:低い 5:高い
     * @type {PpmstthresholddogRiskEvaluation}
     * @memberof PpMstThresholdDogModelGetEvaluationMapResponse
     */
    'risk_evaluation'?: PpmstthresholddogRiskEvaluation;
    /**
     * リスク名
     * @type {string}
     * @memberof PpMstThresholdDogModelGetEvaluationMapResponse
     */
    'risk_name'?: string;
}
/**
 * 
 * @export
 * @interface PpMstUserAdminControllerCreateRequest
 */
export interface PpMstUserAdminControllerCreateRequest {
    /**
     * ユーザーの種別 1->管理者 2->検査技師
     * @type {string}
     * @memberof PpMstUserAdminControllerCreateRequest
     */
    'access'?: PpMstUserAdminControllerCreateRequestAccessEnum;
    /**
     * 所属
     * @type {string}
     * @memberof PpMstUserAdminControllerCreateRequest
     */
    'belongs'?: string;
    /**
     * 平文パスワード（確認用）
     * @type {string}
     * @memberof PpMstUserAdminControllerCreateRequest
     */
    'confirmation_password'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PpMstUserAdminControllerCreateRequest
     */
    'email'?: string;
    /**
     * 氏名
     * @type {string}
     * @memberof PpMstUserAdminControllerCreateRequest
     */
    'name'?: string;
    /**
     * 平文パスワード
     * @type {string}
     * @memberof PpMstUserAdminControllerCreateRequest
     */
    'password'?: string;
}

export const PpMstUserAdminControllerCreateRequestAccessEnum = {
    _1: '1',
    _2: '2'
} as const;

export type PpMstUserAdminControllerCreateRequestAccessEnum = typeof PpMstUserAdminControllerCreateRequestAccessEnum[keyof typeof PpMstUserAdminControllerCreateRequestAccessEnum];

/**
 * 
 * @export
 * @interface PpMstUserAdminControllerEditRequest
 */
export interface PpMstUserAdminControllerEditRequest {
    /**
     * ユーザーの種別 1->管理者 2->検査技師
     * @type {string}
     * @memberof PpMstUserAdminControllerEditRequest
     */
    'access'?: PpMstUserAdminControllerEditRequestAccessEnum;
    /**
     * 所属
     * @type {string}
     * @memberof PpMstUserAdminControllerEditRequest
     */
    'belongs'?: string;
    /**
     * 平文パスワード（確認用）
     * @type {string}
     * @memberof PpMstUserAdminControllerEditRequest
     */
    'confirmation_password'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PpMstUserAdminControllerEditRequest
     */
    'email'?: string;
    /**
     * メールアドレスを変更する場合true
     * @type {boolean}
     * @memberof PpMstUserAdminControllerEditRequest
     */
    'is_email_change'?: boolean;
    /**
     * パスワードを変更する場合true
     * @type {boolean}
     * @memberof PpMstUserAdminControllerEditRequest
     */
    'is_password_change'?: boolean;
    /**
     * 氏名
     * @type {string}
     * @memberof PpMstUserAdminControllerEditRequest
     */
    'name'?: string;
    /**
     * 平文パスワード
     * @type {string}
     * @memberof PpMstUserAdminControllerEditRequest
     */
    'password'?: string;
}

export const PpMstUserAdminControllerEditRequestAccessEnum = {
    _1: '1',
    _2: '2'
} as const;

export type PpMstUserAdminControllerEditRequestAccessEnum = typeof PpMstUserAdminControllerEditRequestAccessEnum[keyof typeof PpMstUserAdminControllerEditRequestAccessEnum];

/**
 * 
 * @export
 * @interface PpMstUserAdminControllerGetResponse
 */
export interface PpMstUserAdminControllerGetResponse {
    /**
     * 
     * @type {PpMstUserAdminControllerGetResponsePpMstUserAdmin}
     * @memberof PpMstUserAdminControllerGetResponse
     */
    'pp_mst_user_admin': PpMstUserAdminControllerGetResponsePpMstUserAdmin;
}
/**
 * 管理者ユーザーデータ
 * @export
 * @interface PpMstUserAdminControllerGetResponsePpMstUserAdmin
 */
export interface PpMstUserAdminControllerGetResponsePpMstUserAdmin {
    /**
     * 権限
     * @type {string}
     * @memberof PpMstUserAdminControllerGetResponsePpMstUserAdmin
     */
    'access': string;
    /**
     * 所属
     * @type {string}
     * @memberof PpMstUserAdminControllerGetResponsePpMstUserAdmin
     */
    'belongs': string | null;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpMstUserAdminControllerGetResponsePpMstUserAdmin
     */
    'created': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PpMstUserAdminControllerGetResponsePpMstUserAdmin
     */
    'email': string;
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstUserAdminControllerGetResponsePpMstUserAdmin
     */
    'id': number;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof PpMstUserAdminControllerGetResponsePpMstUserAdmin
     */
    'last_login': string | null;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof PpMstUserAdminControllerGetResponsePpMstUserAdmin
     */
    'last_login_ip': string | null;
    /**
     * 名前
     * @type {string}
     * @memberof PpMstUserAdminControllerGetResponsePpMstUserAdmin
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PpMstUserAdminControllerIndexResponse
 */
export interface PpMstUserAdminControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpMstUserAdminData>}
     * @memberof PpMstUserAdminControllerIndexResponse
     */
    'pp_mst_user_admins': Array<TypesPpMstUserAdminData>;
    /**
     * 件数
     * @type {number}
     * @memberof PpMstUserAdminControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpMstUserMedicalControllerCreateRequest
 */
export interface PpMstUserMedicalControllerCreateRequest {
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof PpMstUserMedicalControllerCreateRequest
     */
    'building'?: string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof PpMstUserMedicalControllerCreateRequest
     */
    'city'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PpMstUserMedicalControllerCreateRequest
     */
    'email'?: string;
    /**
     * 病院名
     * @type {string}
     * @memberof PpMstUserMedicalControllerCreateRequest
     */
    'medic_inst_name'?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof PpMstUserMedicalControllerCreateRequest
     */
    'postal_code'?: string;
    /**
     * 都道府県
     * @type {string}
     * @memberof PpMstUserMedicalControllerCreateRequest
     */
    'pref_code'?: string;
    /**
     * 検査結果メール送信対象 0: 対象外、1: 病院のみ通知、2: ユーザーのみ通知、3: 両方通知
     * @type {string}
     * @memberof PpMstUserMedicalControllerCreateRequest
     */
    'result_send_target'?: string;
    /**
     * 番地
     * @type {string}
     * @memberof PpMstUserMedicalControllerCreateRequest
     */
    'street'?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof PpMstUserMedicalControllerCreateRequest
     */
    'tel_no'?: string;
}
/**
 * 
 * @export
 * @interface PpMstUserMedicalControllerEditRequest
 */
export interface PpMstUserMedicalControllerEditRequest {
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof PpMstUserMedicalControllerEditRequest
     */
    'building'?: string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof PpMstUserMedicalControllerEditRequest
     */
    'city'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PpMstUserMedicalControllerEditRequest
     */
    'email'?: string;
    /**
     * 病院名
     * @type {string}
     * @memberof PpMstUserMedicalControllerEditRequest
     */
    'medic_inst_name'?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof PpMstUserMedicalControllerEditRequest
     */
    'postal_code'?: string;
    /**
     * 都道府県
     * @type {string}
     * @memberof PpMstUserMedicalControllerEditRequest
     */
    'pref_code'?: string;
    /**
     * 検査結果メール送信対象 0: 対象外、1: 病院のみ通知、2: ユーザーのみ通知、3: 両方通知
     * @type {string}
     * @memberof PpMstUserMedicalControllerEditRequest
     */
    'result_send_target'?: string;
    /**
     * 番地
     * @type {string}
     * @memberof PpMstUserMedicalControllerEditRequest
     */
    'street'?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof PpMstUserMedicalControllerEditRequest
     */
    'tel_no'?: string;
}
/**
 * 
 * @export
 * @interface PpMstUserMedicalControllerGetResponse
 */
export interface PpMstUserMedicalControllerGetResponse {
    /**
     * 
     * @type {PpMstUserMedicalControllerGetResponsePpMstUserMedical}
     * @memberof PpMstUserMedicalControllerGetResponse
     */
    'pp_mst_user_medical': PpMstUserMedicalControllerGetResponsePpMstUserMedical;
}
/**
 * 販病院データ
 * @export
 * @interface PpMstUserMedicalControllerGetResponsePpMstUserMedical
 */
export interface PpMstUserMedicalControllerGetResponsePpMstUserMedical {
    /**
     * ビル名
     * @type {string}
     * @memberof PpMstUserMedicalControllerGetResponsePpMstUserMedical
     */
    'building': string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof PpMstUserMedicalControllerGetResponsePpMstUserMedical
     */
    'city': string;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpMstUserMedicalControllerGetResponsePpMstUserMedical
     */
    'created': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PpMstUserMedicalControllerGetResponsePpMstUserMedical
     */
    'email': string;
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstUserMedicalControllerGetResponsePpMstUserMedical
     */
    'id': number;
    /**
     * 病院名
     * @type {string}
     * @memberof PpMstUserMedicalControllerGetResponsePpMstUserMedical
     */
    'medic_inst_name': string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof PpMstUserMedicalControllerGetResponsePpMstUserMedical
     */
    'postal_code': string;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof PpMstUserMedicalControllerGetResponsePpMstUserMedical
     */
    'pref_code': string;
    /**
     * 検査結果メール送信対象 0: 対象外、1: 病院のみ通知、2: ユーザーのみ通知、3: 両方通知
     * @type {string}
     * @memberof PpMstUserMedicalControllerGetResponsePpMstUserMedical
     */
    'result_send_target': string;
    /**
     * 番地
     * @type {string}
     * @memberof PpMstUserMedicalControllerGetResponsePpMstUserMedical
     */
    'street': string;
    /**
     * TEL
     * @type {string}
     * @memberof PpMstUserMedicalControllerGetResponsePpMstUserMedical
     */
    'tel_no': string;
}
/**
 * 
 * @export
 * @interface PpMstUserMedicalControllerIndexResponse
 */
export interface PpMstUserMedicalControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpMstUserMedicalData>}
     * @memberof PpMstUserMedicalControllerIndexResponse
     */
    'pp_mst_user_medicals': Array<TypesPpMstUserMedicalData>;
    /**
     * 全件数
     * @type {number}
     * @memberof PpMstUserMedicalControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpMstUserMedicalControllerListResponse
 */
export interface PpMstUserMedicalControllerListResponse {
    /**
     * リストデータ
     * @type {Array<PpMstUserMedicalModelGetListResponse>}
     * @memberof PpMstUserMedicalControllerListResponse
     */
    'data': Array<PpMstUserMedicalModelGetListResponse>;
}
/**
 * 
 * @export
 * @interface PpMstUserMedicalModelGetListResponse
 */
export interface PpMstUserMedicalModelGetListResponse {
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstUserMedicalModelGetListResponse
     */
    'id': number;
    /**
     * 企業名
     * @type {string}
     * @memberof PpMstUserMedicalModelGetListResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PpMstUserPartnerControllerCreateRequest
 */
export interface PpMstUserPartnerControllerCreateRequest {
    /**
     * アクセス権限
     * @type {number}
     * @memberof PpMstUserPartnerControllerCreateRequest
     */
    'access'?: number;
    /**
     * 識別コード_番号
     * @type {string}
     * @memberof PpMstUserPartnerControllerCreateRequest
     */
    'code_number'?: string | null;
    /**
     * 平文パスワード（確認用）
     * @type {string}
     * @memberof PpMstUserPartnerControllerCreateRequest
     */
    'confirmation_password'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PpMstUserPartnerControllerCreateRequest
     */
    'email'?: string;
    /**
     * 識別コード_グルーピングコード
     * @type {string}
     * @memberof PpMstUserPartnerControllerCreateRequest
     */
    'grouping_code'?: string | null;
    /**
     * 識別コード
     * @type {string}
     * @memberof PpMstUserPartnerControllerCreateRequest
     */
    'identification_cd'?: string | null;
    /**
     * 識別コードの新規追加フラグ
     * @type {boolean}
     * @memberof PpMstUserPartnerControllerCreateRequest
     */
    'is_new_cd'?: boolean;
    /**
     * 氏名
     * @type {string}
     * @memberof PpMstUserPartnerControllerCreateRequest
     */
    'name'?: string;
    /**
     * 提携機関区分 1:病院、2:提携法人
     * @type {string}
     * @memberof PpMstUserPartnerControllerCreateRequest
     */
    'partner_inst_division'?: string;
    /**
     * 所属機関ID(病院のIDか提携法人のIDとなる)
     * @type {number}
     * @memberof PpMstUserPartnerControllerCreateRequest
     */
    'partner_inst_id'?: number;
    /**
     * 平文パスワード
     * @type {string}
     * @memberof PpMstUserPartnerControllerCreateRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface PpMstUserPartnerControllerEditRequest
 */
export interface PpMstUserPartnerControllerEditRequest {
    /**
     * アクセス権限
     * @type {number}
     * @memberof PpMstUserPartnerControllerEditRequest
     */
    'access'?: number;
    /**
     * 識別コード_番号
     * @type {string}
     * @memberof PpMstUserPartnerControllerEditRequest
     */
    'code_number'?: string | null;
    /**
     * 平文パスワード（確認用）
     * @type {string}
     * @memberof PpMstUserPartnerControllerEditRequest
     */
    'confirmation_password'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PpMstUserPartnerControllerEditRequest
     */
    'email'?: string;
    /**
     * 識別コード_グルーピングコード
     * @type {string}
     * @memberof PpMstUserPartnerControllerEditRequest
     */
    'grouping_code'?: string | null;
    /**
     * 識別コード
     * @type {string}
     * @memberof PpMstUserPartnerControllerEditRequest
     */
    'identification_cd'?: string | null;
    /**
     * メールアドレスを変更する場合true
     * @type {boolean}
     * @memberof PpMstUserPartnerControllerEditRequest
     */
    'is_email_change'?: boolean;
    /**
     * 識別コードの新規追加フラグ
     * @type {boolean}
     * @memberof PpMstUserPartnerControllerEditRequest
     */
    'is_new_cd'?: boolean;
    /**
     * パスワードを変更する場合true
     * @type {boolean}
     * @memberof PpMstUserPartnerControllerEditRequest
     */
    'is_password_change'?: boolean;
    /**
     * 氏名
     * @type {string}
     * @memberof PpMstUserPartnerControllerEditRequest
     */
    'name'?: string;
    /**
     * 提携機関区分 1:病院、2:提携法人
     * @type {string}
     * @memberof PpMstUserPartnerControllerEditRequest
     */
    'partner_inst_division'?: string;
    /**
     * 所属機関ID(病院のIDか提携法人のIDとなる)
     * @type {number}
     * @memberof PpMstUserPartnerControllerEditRequest
     */
    'partner_inst_id'?: number;
    /**
     * 平文パスワード
     * @type {string}
     * @memberof PpMstUserPartnerControllerEditRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface PpMstUserPartnerControllerGetResponse
 */
export interface PpMstUserPartnerControllerGetResponse {
    /**
     * 
     * @type {PpMstUserPartnerControllerGetResponsePpMstUserPartner}
     * @memberof PpMstUserPartnerControllerGetResponse
     */
    'pp_mst_user_partner': PpMstUserPartnerControllerGetResponsePpMstUserPartner;
}
/**
 * 提携機関ユーザーデータ
 * @export
 * @interface PpMstUserPartnerControllerGetResponsePpMstUserPartner
 */
export interface PpMstUserPartnerControllerGetResponsePpMstUserPartner {
    /**
     * 提携法人ユーザーのアクセス権限 1：一般提携法人ユーザー、2：研究機関ユーザー、3：顧問研究者ユーザー
     * @type {number}
     * @memberof PpMstUserPartnerControllerGetResponsePpMstUserPartner
     */
    'access': number;
    /**
     * 提携法人ユーザーのアクセス権限の文字列表現 general, lab, advisor
     * @type {TypesAccessType}
     * @memberof PpMstUserPartnerControllerGetResponsePpMstUserPartner
     */
    'access_type': TypesAccessType | null;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof PpMstUserPartnerControllerGetResponsePpMstUserPartner
     */
    'created': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PpMstUserPartnerControllerGetResponsePpMstUserPartner
     */
    'email': string;
    /**
     * 主キー
     * @type {number}
     * @memberof PpMstUserPartnerControllerGetResponsePpMstUserPartner
     */
    'id': number;
    /**
     * 識別コード
     * @type {string}
     * @memberof PpMstUserPartnerControllerGetResponsePpMstUserPartner
     */
    'identification_cd': string | null;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof PpMstUserPartnerControllerGetResponsePpMstUserPartner
     */
    'last_login': string | null;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof PpMstUserPartnerControllerGetResponsePpMstUserPartner
     */
    'last_login_ip': string | null;
    /**
     * 名前
     * @type {string}
     * @memberof PpMstUserPartnerControllerGetResponsePpMstUserPartner
     */
    'name': string;
    /**
     * 提携機関区分 1:病院 2:提携法人
     * @type {PpmstuserpartnerPartnerInstDivision}
     * @memberof PpMstUserPartnerControllerGetResponsePpMstUserPartner
     */
    'partner_inst_division': PpmstuserpartnerPartnerInstDivision;
    /**
     * 所属機関ID
     * @type {number}
     * @memberof PpMstUserPartnerControllerGetResponsePpMstUserPartner
     */
    'partner_inst_id': number;
    /**
     * 所属機関名
     * @type {string}
     * @memberof PpMstUserPartnerControllerGetResponsePpMstUserPartner
     */
    'partner_inst_name': string;
    /**
     * 
     * @type {TypesPpMstUserPartnerDataPpMstPartnerInst}
     * @memberof PpMstUserPartnerControllerGetResponsePpMstUserPartner
     */
    'pp_mst_partner_inst': TypesPpMstUserPartnerDataPpMstPartnerInst | null;
    /**
     * 
     * @type {TypesPpMstRcmndMethodDataMedicInst}
     * @memberof PpMstUserPartnerControllerGetResponsePpMstUserPartner
     */
    'pp_mst_user_medical': TypesPpMstRcmndMethodDataMedicInst | null;
}
/**
 * 
 * @export
 * @interface PpMstUserPartnerControllerIndexResponse
 */
export interface PpMstUserPartnerControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpMstUserPartnerData>}
     * @memberof PpMstUserPartnerControllerIndexResponse
     */
    'pp_mst_user_partners': Array<TypesPpMstUserPartnerData>;
    /**
     * 件数
     * @type {number}
     * @memberof PpMstUserPartnerControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpPetControllerCreateRequest
 */
export interface PpPetControllerCreateRequest {
    /**
     * 生年月日種別 1=確定 2=推定 3=不明
     * @type {string}
     * @memberof PpPetControllerCreateRequest
     */
    'accuracy_of_pet_birthday'?: string;
    /**
     * 種別 1=dog 2=cat
     * @type {string}
     * @memberof PpPetControllerCreateRequest
     */
    'animal_type'?: string;
    /**
     * 生年月日 YYYY-MM-DD 不明の場合は指定しない
     * @type {string}
     * @memberof PpPetControllerCreateRequest
     */
    'birthday'?: string | null;
    /**
     * 品種ID
     * @type {number}
     * @memberof PpPetControllerCreateRequest
     */
    'breed_id'?: number;
    /**
     * 両親の品種1
     * @type {number}
     * @memberof PpPetControllerCreateRequest
     */
    'breed_of_parents_1'?: number | null;
    /**
     * 両親の品種2
     * @type {number}
     * @memberof PpPetControllerCreateRequest
     */
    'breed_of_parents_2'?: number | null;
    /**
     * 画像
     * @type {string}
     * @memberof PpPetControllerCreateRequest
     */
    'icon'?: string;
    /**
     * バリデーションのみ行う場合trueを指定
     * @type {boolean}
     * @memberof PpPetControllerCreateRequest
     */
    'is_only_validate'?: boolean;
    /**
     * カルテID
     * @type {string}
     * @memberof PpPetControllerCreateRequest
     */
    'karte_id'?: string;
    /**
     * お名前
     * @type {string}
     * @memberof PpPetControllerCreateRequest
     */
    'pet_name'?: string;
    /**
     * お名前フリガナ
     * @type {string}
     * @memberof PpPetControllerCreateRequest
     */
    'pet_name_furigana'?: string;
    /**
     * お名前ローマ字
     * @type {string}
     * @memberof PpPetControllerCreateRequest
     */
    'pet_name_romaji'?: string;
    /**
     * 性別 1=オス 2=メス
     * @type {string}
     * @memberof PpPetControllerCreateRequest
     */
    'pet_sex'?: string;
    /**
     * 会員ID（保存時のみ必須）
     * @type {number}
     * @memberof PpPetControllerCreateRequest
     */
    'pp_user_id'?: number | null;
}
/**
 * 
 * @export
 * @interface PpPetControllerEditRequest
 */
export interface PpPetControllerEditRequest {
    /**
     * 生年月日種別 1=確定 2=推定 3=不明
     * @type {string}
     * @memberof PpPetControllerEditRequest
     */
    'accuracy_of_pet_birthday'?: string;
    /**
     * 種別 1=dog 2=cat
     * @type {string}
     * @memberof PpPetControllerEditRequest
     */
    'animal_type'?: string;
    /**
     * 生年月日 YYYY-MM-DD 不明の場合は指定しない
     * @type {string}
     * @memberof PpPetControllerEditRequest
     */
    'birthday'?: string | null;
    /**
     * 品種ID
     * @type {number}
     * @memberof PpPetControllerEditRequest
     */
    'breed_id'?: number;
    /**
     * 両親の品種1
     * @type {number}
     * @memberof PpPetControllerEditRequest
     */
    'breed_of_parents_1'?: number | null;
    /**
     * 両親の品種2
     * @type {number}
     * @memberof PpPetControllerEditRequest
     */
    'breed_of_parents_2'?: number | null;
    /**
     * 画像
     * @type {string}
     * @memberof PpPetControllerEditRequest
     */
    'icon'?: string;
    /**
     * バリデーションのみ行う場合trueを指定
     * @type {boolean}
     * @memberof PpPetControllerEditRequest
     */
    'is_only_validate'?: boolean;
    /**
     * カルテID
     * @type {string}
     * @memberof PpPetControllerEditRequest
     */
    'karte_id'?: string;
    /**
     * お名前
     * @type {string}
     * @memberof PpPetControllerEditRequest
     */
    'pet_name'?: string;
    /**
     * お名前フリガナ
     * @type {string}
     * @memberof PpPetControllerEditRequest
     */
    'pet_name_furigana'?: string;
    /**
     * お名前ローマ字
     * @type {string}
     * @memberof PpPetControllerEditRequest
     */
    'pet_name_romaji'?: string;
    /**
     * 性別 1=オス 2=メス
     * @type {string}
     * @memberof PpPetControllerEditRequest
     */
    'pet_sex'?: string;
}
/**
 * 
 * @export
 * @interface PpPetControllerGetResponse
 */
export interface PpPetControllerGetResponse {
    /**
     * 
     * @type {PpPetControllerGetResponsePpPet}
     * @memberof PpPetControllerGetResponse
     */
    'pp_pet': PpPetControllerGetResponsePpPet;
}
/**
 * ペットデータ
 * @export
 * @interface PpPetControllerGetResponsePpPet
 */
export interface PpPetControllerGetResponsePpPet {
    /**
     * 生年月日種別 1：確定、2：推定、3：不明
     * @type {string}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'accuracy_of_pet_birthday': string;
    /**
     * 種類 1：dog 2：cat
     * @type {string}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'animal_type': string;
    /**
     * 生年月日
     * @type {string}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'birthday': string | null;
    /**
     * 品種ID
     * @type {number}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'breed_id': number;
    /**
     * 品種名
     * @type {string}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'breed_name': string;
    /**
     * 両親の品種名1
     * @type {string}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'breed_name_parent_1': string | null;
    /**
     * 両親の品種名2
     * @type {string}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'breed_name_parent_2': string | null;
    /**
     * 両親の品種ID1
     * @type {number}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'breed_of_parents_1': number | null;
    /**
     * 両親の品種ID2
     * @type {number}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'breed_of_parents_2': number | null;
    /**
     * 登録日
     * @type {string}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'created': string;
    /**
     * アイコン画像（保存されているディレクトリ名を含む）
     * @type {string}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'icon': string | null;
    /**
     * アイコン画像（ディレクトリを省いてbasenameとした画像名）
     * @type {string}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'icon_name': string | null;
    /**
     * ペットID
     * @type {number}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'id': number;
    /**
     * 閲覧用アイコン画像URL
     * @type {string}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'img_url': string | null;
    /**
     * カルテID
     * @type {string}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'karte_id': string | null;
    /**
     * ペットの名前
     * @type {string}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'pet_name': string;
    /**
     * ペットの名前フリガナ
     * @type {string}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'pet_name_furigana': string;
    /**
     * ペットの名前ローマ字
     * @type {string}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'pet_name_romaji': string;
    /**
     * 性別 1：男の子、2：女の子
     * @type {string}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'pet_sex': string;
    /**
     * 会員ID(飼い主ID)
     * @type {number}
     * @memberof PpPetControllerGetResponsePpPet
     */
    'pp_user_id': number;
}
/**
 * 
 * @export
 * @interface PpPetControllerHasTestResponse
 */
export interface PpPetControllerHasTestResponse {
    /**
     * 申し込み完了した検査があるか？（ある場合は種別の変更を不可にする）
     * @type {boolean}
     * @memberof PpPetControllerHasTestResponse
     */
    'has_test': boolean;
}
/**
 * 
 * @export
 * @interface PpPetControllerIndexResponse
 */
export interface PpPetControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpPetData>}
     * @memberof PpPetControllerIndexResponse
     */
    'pp_pets': Array<TypesPpPetData>;
    /**
     * 全件数
     * @type {number}
     * @memberof PpPetControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpPetControllerSetKarteIDRequest
 */
export interface PpPetControllerSetKarteIDRequest {
    /**
     * カルテID
     * @type {string}
     * @memberof PpPetControllerSetKarteIDRequest
     */
    'karte_id'?: string;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerAddReceptionRequest
 */
export interface PpTestKitControllerAddReceptionRequest {
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof PpTestKitControllerAddReceptionRequest
     */
    'flowcell_id'?: string;
    /**
     * 検体IDの配列
     * @type {Array<string>}
     * @memberof PpTestKitControllerAddReceptionRequest
     */
    'sample_ids'?: Array<string>;
    /**
     * ウェルプレートID（PCR試験プレートID）
     * @type {string}
     * @memberof PpTestKitControllerAddReceptionRequest
     */
    'wellplate_id'?: string;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerAddReceptionResponse
 */
export interface PpTestKitControllerAddReceptionResponse {
    /**
     * 追加件数
     * @type {number}
     * @memberof PpTestKitControllerAddReceptionResponse
     */
    'added_num': number;
    /**
     * 検査未申込件数
     * @type {number}
     * @memberof PpTestKitControllerAddReceptionResponse
     */
    'not_confirmed_num': number;
    /**
     * 全件数
     * @type {number}
     * @memberof PpTestKitControllerAddReceptionResponse
     */
    'total_num': number;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerApplyRequest
 */
export interface PpTestKitControllerApplyRequest {
    /**
     * 招待コード（=識別コード）
     * @type {string}
     * @memberof PpTestKitControllerApplyRequest
     */
    'identification_cd'?: string | null;
    /**
     * パスワード
     * @type {string}
     * @memberof PpTestKitControllerApplyRequest
     */
    'password'?: string;
    /**
     * ペットID
     * @type {number}
     * @memberof PpTestKitControllerApplyRequest
     */
    'pp_pet_id'?: number;
    /**
     * 会員ID（ユーザー画面からの申し込み時はnull化）
     * @type {number}
     * @memberof PpTestKitControllerApplyRequest
     */
    'pp_user_id'?: number | null;
    /**
     * 検体ID (例：PC01-0001-NGS-0001)
     * @type {string}
     * @memberof PpTestKitControllerApplyRequest
     */
    'sample_id'?: string;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerApplyResponse
 */
export interface PpTestKitControllerApplyResponse {
    /**
     * 検体ID（以降の基礎情報の回答時などはこの検体IDを添えてリクエストを送信する。）
     * @type {string}
     * @memberof PpTestKitControllerApplyResponse
     */
    'sample_id'?: string;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerDebugRequest
 */
export interface PpTestKitControllerDebugRequest {
    /**
     * 検体ID
     * @type {string}
     * @memberof PpTestKitControllerDebugRequest
     */
    'sample_id'?: string;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerDebugResponse
 */
export interface PpTestKitControllerDebugResponse {
    /**
     * 管理明細
     * @type {Array<EntPpTestKit>}
     * @memberof PpTestKitControllerDebugResponse
     */
    'pp_test_kits': Array<EntPpTestKit>;
    /**
     * 結果データ
     * @type {Array<EntPpTestResult>}
     * @memberof PpTestKitControllerDebugResponse
     */
    'pp_test_results': Array<EntPpTestResult>;
    /**
     * 状況データ
     * @type {Array<EntPpTestStatus>}
     * @memberof PpTestKitControllerDebugResponse
     */
    'pp_test_statuses': Array<EntPpTestStatus>;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerEachData
 */
export interface PpTestKitControllerEachData {
    /**
     * 対象の検体IDの範囲開始 例：PC01-0001-NGS-0001
     * @type {string}
     * @memberof PpTestKitControllerEachData
     */
    'from'?: string;
    /**
     * 病院ID 管理画面での実行が前提なので、制限はゆるい。
     * @type {number}
     * @memberof PpTestKitControllerEachData
     */
    'medic_inst_id'?: number | null;
    /**
     * 提携法人ID(任意)
     * @type {number}
     * @memberof PpTestKitControllerEachData
     */
    'partner_inst_id'?: number | null;
    /**
     * リコメンド要否区分
     * @type {string}
     * @memberof PpTestKitControllerEachData
     */
    'recommend_type'?: string;
    /**
     * SKUコード
     * @type {string}
     * @memberof PpTestKitControllerEachData
     */
    'sku_code'?: string;
    /**
     * 対象の検体IDの範囲終了 例：PC01-0001-NGS-0002
     * @type {string}
     * @memberof PpTestKitControllerEachData
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerEditRequest
 */
export interface PpTestKitControllerEditRequest {
    /**
     * 生年月日種別 1=確定 2=推定 3=不明
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'bi_accuracy_of_pet_birthday'?: string;
    /**
     * 種別 1=dog 2=cat
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'bi_animal_type'?: string;
    /**
     * 品種ID
     * @type {number}
     * @memberof PpTestKitControllerEditRequest
     */
    'bi_breed'?: number;
    /**
     * 両親の品種1
     * @type {number}
     * @memberof PpTestKitControllerEditRequest
     */
    'bi_breed_of_parents_1'?: number | null;
    /**
     * 両親の品種2
     * @type {number}
     * @memberof PpTestKitControllerEditRequest
     */
    'bi_breed_of_parents_2'?: number | null;
    /**
     * 画像
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'bi_icon'?: string;
    /**
     * 生年月日 YYYY-MM-DD 不明の場合は指定しない
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'bi_pet_birthday'?: string | null;
    /**
     * お名前
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'bi_pet_name'?: string;
    /**
     * お名前フリガナ
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'bi_pet_name_furigana'?: string;
    /**
     * お名前ローマ字
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'bi_pet_name_romaji'?: string;
    /**
     * 性別 1=オス 2=メス
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'bi_pet_sex'?: string;
    /**
     * 攻撃性(家族に対して) 1：よく攻撃する、2：時々攻撃する、3：ほとんど攻撃しない
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_aggression_for_family'?: string;
    /**
     * 攻撃性(見知らぬ人に対して) 1：よく攻撃する、2：時々攻撃する、3：ほとんど攻撃しない
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_aggression_for_stranger'?: string;
    /**
     * アレルギー（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_allergy_comment'?: string;
    /**
     * アレルギー：食物（消化器） 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_allergy_food_digestive_organ'?: string;
    /**
     * アレルギー：食物（皮膚） 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_allergy_food_skin'?: string;
    /**
     * アレルギー：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_allergy_other'?: string;
    /**
     * 食欲 1：完食する、2：完食するが食いつきが悪い、3：食べ残す、4：安定しない
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_appetite'?: string;
    /**
     * 体型 1：痩せている、2：やや痩せている、3：標準、4：やや肥満、5：肥満
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_body_figure'?: string;
    /**
     * 脳・脊髄疾患：脳疾患（原因不明） 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_brain_spinal_disease_brain_disease'?: string;
    /**
     * 脳・脊髄疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_brain_spinal_disease_comment'?: string;
    /**
     * 脳・脊髄疾患：てんかん 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_brain_spinal_disease_epilepsy'?: string;
    /**
     * 脳・脊髄疾患：椎間板ヘルニア 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_brain_spinal_disease_herniated_disc'?: string;
    /**
     * 脳・脊髄疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_brain_spinal_disease_other'?: string;
    /**
     * 検査に関する同意(trueが必須)
     * @type {boolean}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_consent_for_testing'?: boolean;
    /**
     * 下痢 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_diarrhea'?: string;
    /**
     * 下痢の発症時期 1：3日以内、2：1週間以内、3：2週間以内、4：１ヶ月以内、5：１ヶ月以上前
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_diarrhea_timing'?: string;
    /**
     * 消化器疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_digestive_disease_comment'?: string;
    /**
     * 消化器疾患：慢性腸症/炎症性腸疾患 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_digestive_disease_ibd'?: string;
    /**
     * 消化器疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_digestive_disease_other'?: string;
    /**
     * 消化器疾患：膵炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_digestive_disease_pancreatitis'?: string;
    /**
     * 消化器疾患：膵外分泌不全 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_digestive_disease_pei'?: string;
    /**
     * 病歴 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_disease'?: string;
    /**
     * 飼育環境 1：屋外、2：屋内、3：自由に行き来している
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_environment'?: string;
    /**
     * 1日の運動時間 1：1時間未満、2：1-2時間、3：2-3時間、4：3-4時間、5：4時間以上
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_exercise_time_oneday'?: string;
    /**
     * 眼科疾患：白内障 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_eye_disease_cataract'?: string;
    /**
     * 眼科疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_eye_disease_comment'?: string;
    /**
     * 眼科疾患：乾性核結膜炎（ドライアイ） 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_eye_disease_dry_eyes'?: string;
    /**
     * 眼科疾患：緑内障 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_eye_disease_glaucoma'?: string;
    /**
     * 眼科疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_eye_disease_other'?: string;
    /**
     * 目脂または涙やけ 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_eyemucus_tearsstain'?: string;
    /**
     * 恐怖心(他の犬or猫に対して) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_fear_for_other_pets'?: string;
    /**
     * 恐怖心(他の人に対して) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_fear_for_stranger'?: string;
    /**
     * 恐怖心(慣れない環境) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_fear_unfamiliar_environment'?: string;
    /**
     * ブラッシング頻度 1：毎日、2：週3-4回、3：週1-2回、4：月1-2回、5：ほとんどしない
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_frequency_brushing'?: string;
    /**
     * うんち/回数(1日) 1：４回以上、2：１-３回、3：１回するかしないか、4：２日以上空く
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_frequency_excrement'?: string;
    /**
     * シャンプー頻度 1：週１-２回、2：月１-２回、3：２-３ヶ月に１回、4：ほとんどしない
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_frequency_shampoo'?: string;
    /**
     * 散歩頻度 1：毎日、2：週3-4回、3：週1-2回、4：月1-2回、5：ほとんどしない
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_frequency_walking'?: string;
    /**
     * 心臓疾患：心筋症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_heart_disease_cardiomyopathy'?: string;
    /**
     * 心臓疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_heart_disease_comment'?: string;
    /**
     * 心臓疾患：心不全 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_heart_disease_heart_failure'?: string;
    /**
     * 心臓疾患：僧帽弁閉鎖不全症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_heart_disease_mr'?: string;
    /**
     * 心臓疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_heart_disease_other'?: string;
    /**
     * 心臓疾患：肺高血圧症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_heart_disease_ph'?: string;
    /**
     * ごはんが手作りの場合の種類：その他内容
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_homemade_mainfood_type_comment'?: string;
    /**
     * ごはんが手作りの場合の種類：魚 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_homemade_mainfood_type_fish'?: string;
    /**
     * ごはんが手作りの場合の種類：果物 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_homemade_mainfood_type_fruit'?: string;
    /**
     * ごはんが手作りの場合の種類：肉 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_homemade_mainfood_type_meat'?: string;
    /**
     * ごはんが手作りの場合の種類：その他 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_homemade_mainfood_type_other'?: string;
    /**
     * ごはんが手作りの場合の種類：野菜 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_homemade_mainfood_type_vegetable'?: string;
    /**
     * ホルモン・代謝疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_hormone_metabolic_disorder_comment'?: string;
    /**
     * ホルモン・代謝疾患：クッシング症候群 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_hormone_metabolism_cushing_syndrome'?: string;
    /**
     * ホルモン・代謝疾患：糖尿病 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_hormone_metabolism_diabetes'?: string;
    /**
     * ホルモン・代謝疾患：高脂血症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_hormone_metabolism_dl'?: string;
    /**
     * ホルモン・代謝疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_hormone_metabolism_other'?: string;
    /**
     * 高血圧 0：なし 1：基礎疾患による高血圧 2：原因の判明していない高血圧
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_hypertension'?: string;
    /**
     * 腎臓疾患：慢性腎不全 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_kidney_disease_ckd'?: string;
    /**
     * 腎臓疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_kidney_disease_comment'?: string;
    /**
     * 腎臓疾患：腎結石 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_kidney_disease_kidney_stone'?: string;
    /**
     * 腎臓疾患：腎炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_kidney_disease_nephritis'?: string;
    /**
     * 腎臓疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_kidney_disease_other'?: string;
    /**
     * 腎臓疾患：蛋白漏出性腎症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_kidney_disease_pln'?: string;
    /**
     * 泌乳した子供の数 0：不明 0~99匹選択
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_lactated_children'?: string;
    /**
     * 泌乳経験 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_lactation_experience'?: string;
    /**
     * 日常的な乳酸菌サプリ摂取有無 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_lactic_supplement'?: string;
    /**
     * 肝・胆道疾患：胆泥症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_liver_bile_disease_biliary_sludge'?: string;
    /**
     * 肝・胆道疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_liver_bile_disease_comment'?: string;
    /**
     * 肝・胆道疾患：胆嚢粘液嚢胞 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_liver_bile_disease_gallbladder_mucocele'?: string;
    /**
     * 肝・胆道疾患：肝障害 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_liver_bile_disease_liver_damage'?: string;
    /**
     * 肝・胆道疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_liver_bile_disease_other'?: string;
    /**
     * ごはん 1：ドライフード、2：ウエットフード、3：手作り、4：その他（自由記述）
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_main_food'?: string;
    /**
     * ごはん（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_main_food_comment'?: string;
    /**
     * 抗生物質の投与 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_medicine_antibiotics'?: string;
    /**
     * 抗生物質以外の薬の投与 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_medicine_except_antibiotics'?: string;
    /**
     * 整形疾患：関節炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_orthopedic_disease_arthritis'?: string;
    /**
     * 整形疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_orthopedic_disease_comment'?: string;
    /**
     * 整形疾患：股関節異形成 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_orthopedic_disease_hd'?: string;
    /**
     * 整形疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_orthopedic_disease_other'?: string;
    /**
     * 整形疾患：膝蓋骨脱臼 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_orthopedic_disease_patella'?: string;
    /**
     * その他病歴
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_other_comment'?: string;
    /**
     * 体重
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_pet_weight'?: string;
    /**
     * 現在の妊娠・妊娠の可能性 1：はい、2：いいえ、0：不明
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_pregnancy'?: string;
    /**
     * 妊娠期間 0：なし 0~99日選択
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_pregnancy_weeks'?: string;
    /**
     * 研究参加に関する同意(trueが必須)
     * @type {boolean}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_research_participation_consent'?: boolean;
    /**
     * 呼吸器疾患：喘息 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_respiratory_disease_asthma'?: string;
    /**
     * 呼吸器疾患：気管支炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_respiratory_disease_bronchitis'?: string;
    /**
     * 呼吸器疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_respiratory_disease_comment'?: string;
    /**
     * 呼吸器疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_respiratory_disease_other'?: string;
    /**
     * 呼吸器疾患：鼻炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_respiratory_disease_rhinitis'?: string;
    /**
     * 呼吸器疾患：気管虚脱 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_respiratory_disease_tracheal_collapse'?: string;
    /**
     * 皮膚疾患：その他のコメント
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_disease_comment'?: string;
    /**
     * 皮膚疾患：皮膚炎（原因が判明していない）0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_disease_eczema'?: string;
    /**
     * 皮膚疾患：外耳炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_disease_external_otitisis'?: string;
    /**
     * 皮膚疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_disease_other'?: string;
    /**
     * 皮膚疾患：膿皮症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_disease_pyoderma'?: string;
    /**
     * 皮膚疾患：脂漏症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_disease_seforrhoea'?: string;
    /**
     * 皮膚トラブル 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_trouble'?: string;
    /**
     * 皮膚トラブル（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_trouble_symptoms_comment'?: string;
    /**
     * 皮膚トラブル：フケ 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_trouble_symptoms_dandruff'?: string;
    /**
     * 皮膚トラブル：乾燥 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_trouble_symptoms_dry'?: string;
    /**
     * 皮膚トラブル：脱毛 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_trouble_symptoms_hairloss'?: string;
    /**
     * 皮膚トラブル：かゆみ 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_trouble_symptoms_itch'?: string;
    /**
     * 皮膚トラブル：その他 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_trouble_symptoms_other'?: string;
    /**
     * 皮膚トラブル：発疹 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_trouble_symptoms_rash'?: string;
    /**
     * 皮膚トラブル：赤み 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_trouble_symptoms_sore'?: string;
    /**
     * 皮膚トラブル発症時期 1：3日以内、2：1週間以内、3：2週間以内、4：１ヶ月以内、5：１ヶ月以上前
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_skin_trouble_timing'?: string;
    /**
     * 避妊・去勢の手術経験 1：はい、2：いいえ、0：不明
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_spayed_neutered'?: string;
    /**
     * 泌乳した期間の合計 0：不明 0~99週間選択
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_total_lactation_duration'?: string;
    /**
     * 腫瘍
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_tumor_comment'?: string;
    /**
     * 分離不安(器物破損、大小便） 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_uneasiness_damage_to_property'?: string;
    /**
     * 分離不安(飼い主と分離時の不安感) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_uneasiness_separated_from_owner'?: string;
    /**
     * 泌尿器疾患：膀胱結石 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_urology_disease_bladder_stone'?: string;
    /**
     * 泌尿器疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_urology_disease_comment'?: string;
    /**
     * 泌尿器疾患：特発性膀胱炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_urology_disease_idiopathic_cystitis'?: string;
    /**
     * 泌尿器疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_urology_disease_other'?: string;
    /**
     * 泌尿器疾患：その他の膀胱炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_urology_disease_other_cystitis'?: string;
    /**
     * 嘔吐 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_vomit'?: string;
    /**
     * 嘔吐の発症時期 1：3日以内、2：1週間以内、3：2週間以内、4：１ヶ月以内、5：１ヶ月以上前
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_vomit_timing'?: string;
    /**
     * 過去の妊娠の有無 1：はい、2：いいえ、0：不明
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_was_pregnant_past'?: string;
    /**
     * 気になること／その他
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_any_other'?: string;
    /**
     * 気になること／その他コメント
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_any_other_comment'?: string;
    /**
     * 気になること／口臭が気になる 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_bad_breath'?: string;
    /**
     * 気になること／毛ヅヤが悪い気がする 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_bad_hair'?: string;
    /**
     * 気になること／体臭が気になる 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_body_odor'?: string;
    /**
     * 気になること／歯石が気になる 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_dental_tartar'?: string;
    /**
     * 気になること／食糞する 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_eat_excrement'?: string;
    /**
     * 気になること／おしっこの回数が多い 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_frequent_urination'?: string;
    /**
     * 気になること／よだれが垂れる 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_increased_salivation'?: string;
    /**
     * 気になること／耳がかゆい 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_itchy_ears'?: string;
    /**
     * 気になること／体重が減りやすい 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_lost_weight'?: string;
    /**
     * 気になること／特になし 0：気になることあり、1：気になること特になし
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_nothing'?: string;
    /**
     * 気になること／耳の臭いが気になる 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_smell_of_ears'?: string;
    /**
     * 気になること／便が臭い 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_smell_of_excrement'?: string;
    /**
     * 気になること／イボがある 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_warts'?: string;
    /**
     * 気になること／体重が増えやすい 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'is_worry_weight_easily'?: string;
    /**
     * 検体採取日
     * @type {string}
     * @memberof PpTestKitControllerEditRequest
     */
    'sampling_date'?: string;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerFromTo
 */
export interface PpTestKitControllerFromTo {
    /**
     * 範囲開始の検体ID
     * @type {string}
     * @memberof PpTestKitControllerFromTo
     */
    'from': string;
    /**
     * 病院ID
     * @type {number}
     * @memberof PpTestKitControllerFromTo
     */
    'medic_inst_id'?: number;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof PpTestKitControllerFromTo
     */
    'partner_inst_id'?: number;
    /**
     * リコメンド要否区分
     * @type {string}
     * @memberof PpTestKitControllerFromTo
     */
    'recommend_type'?: string;
    /**
     * SKUコード
     * @type {string}
     * @memberof PpTestKitControllerFromTo
     */
    'sku_code'?: string;
    /**
     * 範囲終了の検体ID
     * @type {string}
     * @memberof PpTestKitControllerFromTo
     */
    'to': string;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerGetByFlowcellIDResponse
 */
export interface PpTestKitControllerGetByFlowcellIDResponse {
    /**
     * FlowcellID
     * @type {string}
     * @memberof PpTestKitControllerGetByFlowcellIDResponse
     */
    'flowcell_id': string;
    /**
     * 検体ID一覧
     * @type {Array<string>}
     * @memberof PpTestKitControllerGetByFlowcellIDResponse
     */
    'sample_ids': Array<string>;
    /**
     * WellplateID
     * @type {string}
     * @memberof PpTestKitControllerGetByFlowcellIDResponse
     */
    'wellplate_id': string;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerGetResponse
 */
export interface PpTestKitControllerGetResponse {
    /**
     * 
     * @type {TypesPpTestKitData}
     * @memberof PpTestKitControllerGetResponse
     */
    'pp_test_kit': TypesPpTestKitData;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerGetSampleIDRequest
 */
export interface PpTestKitControllerGetSampleIDRequest {
    /**
     * 検体ID範囲の配列
     * @type {Array<PpTestKitControllerFromTo>}
     * @memberof PpTestKitControllerGetSampleIDRequest
     */
    'sample_ids'?: Array<PpTestKitControllerFromTo>;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerGetSampleIDResponse
 */
export interface PpTestKitControllerGetSampleIDResponse {
    /**
     * 検体ID情報の配列
     * @type {Array<TypesPpTestKitSampleData>}
     * @memberof PpTestKitControllerGetSampleIDResponse
     */
    'pp_test_kits': Array<TypesPpTestKitSampleData>;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerIndexRequest
 */
export interface PpTestKitControllerIndexRequest {
    /**
     * 名
     * @type {string}
     * @memberof PpTestKitControllerIndexRequest
     */
    'first_name'?: string;
    /**
     * メイ
     * @type {string}
     * @memberof PpTestKitControllerIndexRequest
     */
    'first_name_kana'?: string;
    /**
     * 検査完了日 開始 YYYY-MM-DD
     * @type {string}
     * @memberof PpTestKitControllerIndexRequest
     */
    'from'?: string;
    /**
     * 検査受付日 開始 YYYY-MM-DD
     * @type {string}
     * @memberof PpTestKitControllerIndexRequest
     */
    'from_start'?: string;
    /**
     * 病院の紐付きがある検体のみ対象とする場合true
     * @type {boolean}
     * @memberof PpTestKitControllerIndexRequest
     */
    'has_medic_inst'?: boolean;
    /**
     * 申込済（=確認済み）のみにする場合trueを指定
     * @type {boolean}
     * @memberof PpTestKitControllerIndexRequest
     */
    'is_confirmed'?: boolean;
    /**
     * CSVダウンロードの場合true
     * @type {boolean}
     * @memberof PpTestKitControllerIndexRequest
     */
    'is_csv'?: boolean;
    /**
     * 依頼注文情報用CSVダウンロードの場合true
     * @type {boolean}
     * @memberof PpTestKitControllerIndexRequest
     */
    'is_order_csv'?: boolean;
    /**
     * カルテID
     * @type {string}
     * @memberof PpTestKitControllerIndexRequest
     */
    'karte_id'?: string;
    /**
     * 姓
     * @type {string}
     * @memberof PpTestKitControllerIndexRequest
     */
    'last_name'?: string;
    /**
     * セイ
     * @type {string}
     * @memberof PpTestKitControllerIndexRequest
     */
    'last_name_kana'?: string;
    /**
     * 病院ID
     * @type {number}
     * @memberof PpTestKitControllerIndexRequest
     */
    'medic_inst_id'?: number;
    /**
     * ページ番号
     * @type {number}
     * @memberof PpTestKitControllerIndexRequest
     */
    'page'?: number;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof PpTestKitControllerIndexRequest
     */
    'partner_inst_id'?: number;
    /**
     * ペットの名前
     * @type {string}
     * @memberof PpTestKitControllerIndexRequest
     */
    'pet_name'?: string;
    /**
     * 検体ID
     * @type {string}
     * @memberof PpTestKitControllerIndexRequest
     */
    'sample_id'?: string;
    /**
     * 検体IDの配列
     * @type {Array<string>}
     * @memberof PpTestKitControllerIndexRequest
     */
    'sample_ids'?: Array<string>;
    /**
     * ページあたりの取得件数
     * @type {number}
     * @memberof PpTestKitControllerIndexRequest
     */
    'size'?: number;
    /**
     * ステータス
     * @type {string}
     * @memberof PpTestKitControllerIndexRequest
     */
    'status'?: string;
    /**
     * 種類
     * @type {string}
     * @memberof PpTestKitControllerIndexRequest
     */
    'test_division'?: string;
    /**
     * 検査完了日 終了 YYYY-MM-DD
     * @type {string}
     * @memberof PpTestKitControllerIndexRequest
     */
    'to'?: string;
    /**
     * 検査受付日 終了 YYYY-MM-DD
     * @type {string}
     * @memberof PpTestKitControllerIndexRequest
     */
    'to_start'?: string;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerIndexResponse
 */
export interface PpTestKitControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpTestKitData>}
     * @memberof PpTestKitControllerIndexResponse
     */
    'pp_test_kits': Array<TypesPpTestKitData>;
    /**
     * 全件数
     * @type {number}
     * @memberof PpTestKitControllerIndexResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerSetIdentificationCdRequest
 */
export interface PpTestKitControllerSetIdentificationCdRequest {
    /**
     * 識別コード
     * @type {string}
     * @memberof PpTestKitControllerSetIdentificationCdRequest
     */
    'identification_cd'?: string;
    /**
     * 検体ID
     * @type {string}
     * @memberof PpTestKitControllerSetIdentificationCdRequest
     */
    'sample_id'?: string;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerSetSampleIDRequest
 */
export interface PpTestKitControllerSetSampleIDRequest {
    /**
     * 設定するデータ
     * @type {Array<PpTestKitControllerEachData>}
     * @memberof PpTestKitControllerSetSampleIDRequest
     */
    'data'?: Array<PpTestKitControllerEachData>;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerSetWellplateIDRequest
 */
export interface PpTestKitControllerSetWellplateIDRequest {
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof PpTestKitControllerSetWellplateIDRequest
     */
    'flowcell_id'?: string;
    /**
     * 検体IDの配列
     * @type {Array<string>}
     * @memberof PpTestKitControllerSetWellplateIDRequest
     */
    'sample_ids'?: Array<string>;
    /**
     * ウェルプレートID（PCR試験プレートID）
     * @type {string}
     * @memberof PpTestKitControllerSetWellplateIDRequest
     */
    'wellplate_id'?: string;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerStep1Request
 */
export interface PpTestKitControllerStep1Request {
    /**
     * アレルギー（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_allergy_comment'?: string;
    /**
     * アレルギー：食物（消化器） 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_allergy_food_digestive_organ'?: string;
    /**
     * アレルギー：食物（皮膚） 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_allergy_food_skin'?: string;
    /**
     * アレルギー：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_allergy_other'?: string;
    /**
     * 脳・脊髄疾患：脳疾患（原因不明） 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_brain_spinal_disease_brain_disease'?: string;
    /**
     * 脳・脊髄疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_brain_spinal_disease_comment'?: string;
    /**
     * 脳・脊髄疾患：てんかん 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_brain_spinal_disease_epilepsy'?: string;
    /**
     * 脳・脊髄疾患：椎間板ヘルニア 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_brain_spinal_disease_herniated_disc'?: string;
    /**
     * 脳・脊髄疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_brain_spinal_disease_other'?: string;
    /**
     * 下痢 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_diarrhea'?: string;
    /**
     * 下痢の発症時期 1：3日以内、2：1週間以内、3：2週間以内、4：１ヶ月以内、5：１ヶ月以上前
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_diarrhea_timing'?: string;
    /**
     * 消化器疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_digestive_disease_comment'?: string;
    /**
     * 消化器疾患：慢性腸症/炎症性腸疾患 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_digestive_disease_ibd'?: string;
    /**
     * 消化器疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_digestive_disease_other'?: string;
    /**
     * 消化器疾患：膵炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_digestive_disease_pancreatitis'?: string;
    /**
     * 消化器疾患：膵外分泌不全 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_digestive_disease_pei'?: string;
    /**
     * 病歴 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_disease'?: string;
    /**
     * 眼科疾患：白内障 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_eye_disease_cataract'?: string;
    /**
     * 眼科疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_eye_disease_comment'?: string;
    /**
     * 眼科疾患：乾性核結膜炎（ドライアイ） 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_eye_disease_dry_eyes'?: string;
    /**
     * 眼科疾患：緑内障 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_eye_disease_glaucoma'?: string;
    /**
     * 眼科疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_eye_disease_other'?: string;
    /**
     * 目脂または涙やけ 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_eyemucus_tearsstain'?: string;
    /**
     * 心臓疾患：心筋症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_heart_disease_cardiomyopathy'?: string;
    /**
     * 心臓疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_heart_disease_comment'?: string;
    /**
     * 心臓疾患：心不全 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_heart_disease_heart_failure'?: string;
    /**
     * 心臓疾患：僧帽弁閉鎖不全症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_heart_disease_mr'?: string;
    /**
     * 心臓疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_heart_disease_other'?: string;
    /**
     * 心臓疾患：肺高血圧症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_heart_disease_ph'?: string;
    /**
     * ホルモン・代謝疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_hormone_metabolic_disorder_comment'?: string;
    /**
     * ホルモン・代謝疾患：クッシング症候群 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_hormone_metabolism_cushing_syndrome'?: string;
    /**
     * ホルモン・代謝疾患：糖尿病 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_hormone_metabolism_diabetes'?: string;
    /**
     * ホルモン・代謝疾患：高脂血症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_hormone_metabolism_dl'?: string;
    /**
     * ホルモン・代謝疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_hormone_metabolism_other'?: string;
    /**
     * 高血圧 0：なし 1：基礎疾患による高血圧 2：原因の判明していない高血圧
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_hypertension'?: string;
    /**
     * 腎臓疾患：慢性腎不全 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_kidney_disease_ckd'?: string;
    /**
     * 腎臓疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_kidney_disease_comment'?: string;
    /**
     * 腎臓疾患：腎結石 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_kidney_disease_kidney_stone'?: string;
    /**
     * 腎臓疾患：腎炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_kidney_disease_nephritis'?: string;
    /**
     * 腎臓疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_kidney_disease_other'?: string;
    /**
     * 腎臓疾患：蛋白漏出性腎症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_kidney_disease_pln'?: string;
    /**
     * 泌乳した子供の数 0：不明 0~99匹選択
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_lactated_children'?: string;
    /**
     * 泌乳経験 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_lactation_experience'?: string;
    /**
     * 肝・胆道疾患：胆泥症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_liver_bile_disease_biliary_sludge'?: string;
    /**
     * 肝・胆道疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_liver_bile_disease_comment'?: string;
    /**
     * 肝・胆道疾患：胆嚢粘液嚢胞 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_liver_bile_disease_gallbladder_mucocele'?: string;
    /**
     * 肝・胆道疾患：肝障害 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_liver_bile_disease_liver_damage'?: string;
    /**
     * 肝・胆道疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_liver_bile_disease_other'?: string;
    /**
     * 抗生物質の投与 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_medicine_antibiotics'?: string;
    /**
     * 抗生物質以外の薬の投与 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_medicine_except_antibiotics'?: string;
    /**
     * 整形疾患：関節炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_orthopedic_disease_arthritis'?: string;
    /**
     * 整形疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_orthopedic_disease_comment'?: string;
    /**
     * 整形疾患：股関節異形成 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_orthopedic_disease_hd'?: string;
    /**
     * 整形疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_orthopedic_disease_other'?: string;
    /**
     * 整形疾患：膝蓋骨脱臼 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_orthopedic_disease_patella'?: string;
    /**
     * その他病歴
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_other_comment'?: string;
    /**
     * 体重
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_pet_weight'?: string;
    /**
     * 現在の妊娠・妊娠の可能性 1：はい、2：いいえ、0：不明
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_pregnancy'?: string;
    /**
     * 妊娠期間 0：なし 0~99日選択
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_pregnancy_weeks'?: string;
    /**
     * 呼吸器疾患：喘息 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_respiratory_disease_asthma'?: string;
    /**
     * 呼吸器疾患：気管支炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_respiratory_disease_bronchitis'?: string;
    /**
     * 呼吸器疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_respiratory_disease_comment'?: string;
    /**
     * 呼吸器疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_respiratory_disease_other'?: string;
    /**
     * 呼吸器疾患：鼻炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_respiratory_disease_rhinitis'?: string;
    /**
     * 呼吸器疾患：気管虚脱 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_respiratory_disease_tracheal_collapse'?: string;
    /**
     * 皮膚疾患：その他のコメント
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_disease_comment'?: string;
    /**
     * 皮膚疾患：皮膚炎（原因が判明していない）0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_disease_eczema'?: string;
    /**
     * 皮膚疾患：外耳炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_disease_external_otitisis'?: string;
    /**
     * 皮膚疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_disease_other'?: string;
    /**
     * 皮膚疾患：膿皮症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_disease_pyoderma'?: string;
    /**
     * 皮膚疾患：脂漏症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_disease_seforrhoea'?: string;
    /**
     * 皮膚トラブル 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_trouble'?: string;
    /**
     * 皮膚トラブル（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_trouble_symptoms_comment'?: string;
    /**
     * 皮膚トラブル：フケ 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_trouble_symptoms_dandruff'?: string;
    /**
     * 皮膚トラブル：乾燥 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_trouble_symptoms_dry'?: string;
    /**
     * 皮膚トラブル：脱毛 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_trouble_symptoms_hairloss'?: string;
    /**
     * 皮膚トラブル：かゆみ 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_trouble_symptoms_itch'?: string;
    /**
     * 皮膚トラブル：その他 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_trouble_symptoms_other'?: string;
    /**
     * 皮膚トラブル：発疹 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_trouble_symptoms_rash'?: string;
    /**
     * 皮膚トラブル：赤み 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_trouble_symptoms_sore'?: string;
    /**
     * 皮膚トラブル発症時期 1：3日以内、2：1週間以内、3：2週間以内、4：１ヶ月以内、5：１ヶ月以上前
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_skin_trouble_timing'?: string;
    /**
     * 避妊・去勢の手術経験 1：はい、2：いいえ、0：不明
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_spayed_neutered'?: string;
    /**
     * 泌乳した期間の合計 0：不明 0~99週間選択
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_total_lactation_duration'?: string;
    /**
     * 腫瘍
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_tumor_comment'?: string;
    /**
     * 泌尿器疾患：膀胱結石 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_urology_disease_bladder_stone'?: string;
    /**
     * 泌尿器疾患（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_urology_disease_comment'?: string;
    /**
     * 泌尿器疾患：特発性膀胱炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_urology_disease_idiopathic_cystitis'?: string;
    /**
     * 泌尿器疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_urology_disease_other'?: string;
    /**
     * 泌尿器疾患：その他の膀胱炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_urology_disease_other_cystitis'?: string;
    /**
     * 嘔吐 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_vomit'?: string;
    /**
     * 嘔吐の発症時期 1：3日以内、2：1週間以内、3：2週間以内、4：１ヶ月以内、5：１ヶ月以上前
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_vomit_timing'?: string;
    /**
     * 過去の妊娠の有無 1：はい、2：いいえ、0：不明
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'is_was_pregnant_past'?: string;
    /**
     * 検体採取日
     * @type {string}
     * @memberof PpTestKitControllerStep1Request
     */
    'sampling_date'?: string;
}
/**
 * 
 * @export
 * @interface PpTestKitControllerStep2Request
 */
export interface PpTestKitControllerStep2Request {
    /**
     * 攻撃性(家族に対して) 1：よく攻撃する、2：時々攻撃する、3：ほとんど攻撃しない
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_aggression_for_family'?: string;
    /**
     * 攻撃性(見知らぬ人に対して) 1：よく攻撃する、2：時々攻撃する、3：ほとんど攻撃しない
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_aggression_for_stranger'?: string;
    /**
     * 食欲 1：完食する、2：完食するが食いつきが悪い、3：食べ残す、4：安定しない
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_appetite'?: string;
    /**
     * 体型 1：痩せている、2：やや痩せている、3：標準、4：やや肥満、5：肥満
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_body_figure'?: string;
    /**
     * 検査に関する同意(trueが必須)
     * @type {boolean}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_consent_for_testing'?: boolean;
    /**
     * 飼育環境 1：屋外、2：屋内、3：自由に行き来している
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_environment'?: string;
    /**
     * 1日の運動時間 1：1時間未満、2：1-2時間、3：2-3時間、4：3-4時間、5：4時間以上
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_exercise_time_oneday'?: string;
    /**
     * 恐怖心(他の犬or猫に対して) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_fear_for_other_pets'?: string;
    /**
     * 恐怖心(他の人に対して) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_fear_for_stranger'?: string;
    /**
     * 恐怖心(慣れない環境) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_fear_unfamiliar_environment'?: string;
    /**
     * ブラッシング頻度 1：毎日、2：週3-4回、3：週1-2回、4：月1-2回、5：ほとんどしない
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_frequency_brushing'?: string;
    /**
     * うんち/回数(1日) 1：４回以上、2：１-３回、3：１回するかしないか、4：２日以上空く
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_frequency_excrement'?: string;
    /**
     * シャンプー頻度 1：週１-２回、2：月１-２回、3：２-３ヶ月に１回、4：ほとんどしない
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_frequency_shampoo'?: string;
    /**
     * 散歩頻度 1：毎日、2：週3-4回、3：週1-2回、4：月1-2回、5：ほとんどしない
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_frequency_walking'?: string;
    /**
     * ごはんが手作りの場合の種類：その他内容
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_homemade_mainfood_type_comment'?: string;
    /**
     * ごはんが手作りの場合の種類：魚 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_homemade_mainfood_type_fish'?: string;
    /**
     * ごはんが手作りの場合の種類：果物 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_homemade_mainfood_type_fruit'?: string;
    /**
     * ごはんが手作りの場合の種類：肉 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_homemade_mainfood_type_meat'?: string;
    /**
     * ごはんが手作りの場合の種類：その他 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_homemade_mainfood_type_other'?: string;
    /**
     * ごはんが手作りの場合の種類：野菜 0：なし、1：あり
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_homemade_mainfood_type_vegetable'?: string;
    /**
     * 日常的な乳酸菌サプリ摂取有無 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_lactic_supplement'?: string;
    /**
     * ごはん 1：ドライフード、2：ウエットフード、3：手作り、4：その他（自由記述）
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_main_food'?: string;
    /**
     * ごはん（その他内容）
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_main_food_comment'?: string;
    /**
     * 研究参加に関する同意(trueが必須)
     * @type {boolean}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_research_participation_consent'?: boolean;
    /**
     * 分離不安(器物破損、大小便） 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_uneasiness_damage_to_property'?: string;
    /**
     * 分離不安(飼い主と分離時の不安感) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_uneasiness_separated_from_owner'?: string;
    /**
     * 気になること／その他
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_any_other'?: string;
    /**
     * 気になること／その他コメント
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_any_other_comment'?: string;
    /**
     * 気になること／口臭が気になる 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_bad_breath'?: string;
    /**
     * 気になること／毛ヅヤが悪い気がする 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_bad_hair'?: string;
    /**
     * 気になること／体臭が気になる 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_body_odor'?: string;
    /**
     * 気になること／歯石が気になる 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_dental_tartar'?: string;
    /**
     * 気になること／食糞する 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_eat_excrement'?: string;
    /**
     * 気になること／おしっこの回数が多い 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_frequent_urination'?: string;
    /**
     * 気になること／よだれが垂れる 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_increased_salivation'?: string;
    /**
     * 気になること／耳がかゆい 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_itchy_ears'?: string;
    /**
     * 気になること／体重が減りやすい 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_lost_weight'?: string;
    /**
     * 気になること／特になし 0：気になることあり、1：気になること特になし
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_nothing'?: string;
    /**
     * 気になること／耳の臭いが気になる 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_smell_of_ears'?: string;
    /**
     * 気になること／便が臭い 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_smell_of_excrement'?: string;
    /**
     * 気になること／イボがある 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_warts'?: string;
    /**
     * 気になること／体重が増えやすい 0：ない、1：ある
     * @type {string}
     * @memberof PpTestKitControllerStep2Request
     */
    'is_worry_weight_easily'?: string;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerBadBacteriaHistory
 */
export interface PpTestResultControllerBadBacteriaHistory {
    /**
     * Brucella Canis
     * @type {number}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'brucella_canis': number;
    /**
     * Campylobacter Coli
     * @type {number}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'campylobacter_coli': number;
    /**
     * Campylobacter Jejuni
     * @type {number}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'campylobacter_jejuni': number;
    /**
     * Clostridium Botulinum
     * @type {number}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'clostridium_botulinum': number;
    /**
     * Clostridium Perfringens
     * @type {number}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'clostridium_perfringens': number;
    /**
     * Clostridiumi Difficile
     * @type {number}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'clostridiumi_difficile': number;
    /**
     * 検査完了日
     * @type {string}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'completion_date': string;
    /**
     * 多様性スコア
     * @type {number}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'diversity_index': number;
    /**
     * Escherichia Coli
     * @type {number}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'escherichia_coli': number;
    /**
     * 善玉菌・悪玉菌スコア
     * @type {number}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'good_bacteria_bad_bacteria_index': number;
    /**
     * Listeria Monocytogenes
     * @type {number}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'listeria_monocytogenes': number;
    /**
     * Pseudomonas Aeruginosa
     * @type {number}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'pseudomonas_aeruginosa': number;
    /**
     * 検体ID
     * @type {string}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'sample_id': string;
    /**
     * 類似度スコア
     * @type {number}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'similarity_index': number;
    /**
     * Staphylocccus Aureus
     * @type {number}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'staphylocccus_aureus': number;
    /**
     * Vibrio Parahaemolyticus
     * @type {number}
     * @memberof PpTestResultControllerBadBacteriaHistory
     */
    'vibrio_parahaemolyticus': number;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerFrontSearchHistoryByUserRequest
 */
export interface PpTestResultControllerFrontSearchHistoryByUserRequest {
    /**
     * ページ番号
     * @type {number}
     * @memberof PpTestResultControllerFrontSearchHistoryByUserRequest
     */
    'page'?: number;
    /**
     * ページあたりの取得件数
     * @type {number}
     * @memberof PpTestResultControllerFrontSearchHistoryByUserRequest
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerFrontSearchHistoryByUserResponse
 */
export interface PpTestResultControllerFrontSearchHistoryByUserResponse {
    /**
     * 検査履歴データの配列
     * @type {Array<TypesHistoryData>}
     * @memberof PpTestResultControllerFrontSearchHistoryByUserResponse
     */
    'histories': Array<TypesHistoryData>;
    /**
     * 検査履歴の総数
     * @type {number}
     * @memberof PpTestResultControllerFrontSearchHistoryByUserResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerGetBacteriaRequest
 */
export interface PpTestResultControllerGetBacteriaRequest {
    /**
     * CSV出力種別(phylum|class|order|family|genus|species)
     * @type {string}
     * @memberof PpTestResultControllerGetBacteriaRequest
     */
    'csv_type'?: string;
    /**
     * CSV出力フラグ
     * @type {boolean}
     * @memberof PpTestResultControllerGetBacteriaRequest
     */
    'is_csv'?: boolean;
    /**
     * 検体ID
     * @type {string}
     * @memberof PpTestResultControllerGetBacteriaRequest
     */
    'sample_id'?: string;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerGetBacteriaResponse
 */
export interface PpTestResultControllerGetBacteriaResponse {
    /**
     * 
     * @type {PpTestResultControllerGetBacteriaResponseData}
     * @memberof PpTestResultControllerGetBacteriaResponse
     */
    'data': PpTestResultControllerGetBacteriaResponseData;
}
/**
 * データ
 * @export
 * @interface PpTestResultControllerGetBacteriaResponseData
 */
export interface PpTestResultControllerGetBacteriaResponseData {
    /**
     * 綱 class
     * @type {{ [key: string]: number; }}
     * @memberof PpTestResultControllerGetBacteriaResponseData
     */
    'class': { [key: string]: number; };
    /**
     * 科 family
     * @type {{ [key: string]: number; }}
     * @memberof PpTestResultControllerGetBacteriaResponseData
     */
    'family': { [key: string]: number; };
    /**
     * 属 genus
     * @type {{ [key: string]: number; }}
     * @memberof PpTestResultControllerGetBacteriaResponseData
     */
    'genus': { [key: string]: number; };
    /**
     * 目 order
     * @type {{ [key: string]: number; }}
     * @memberof PpTestResultControllerGetBacteriaResponseData
     */
    'order': { [key: string]: number; };
    /**
     * 門 phylum
     * @type {{ [key: string]: number; }}
     * @memberof PpTestResultControllerGetBacteriaResponseData
     */
    'phylum': { [key: string]: number; };
    /**
     * 種 species
     * @type {{ [key: string]: number; }}
     * @memberof PpTestResultControllerGetBacteriaResponseData
     */
    'species': { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface PpTestResultControllerGetBadBacteriaHistoryResponse
 */
export interface PpTestResultControllerGetBadBacteriaHistoryResponse {
    /**
     * データ
     * @type {Array<PpTestResultControllerBadBacteriaHistory>}
     * @memberof PpTestResultControllerGetBadBacteriaHistoryResponse
     */
    'histories': Array<PpTestResultControllerBadBacteriaHistory>;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerGetHistoryResponse
 */
export interface PpTestResultControllerGetHistoryResponse {
    /**
     * 猫の場合の評価データのオブジェクト（キーには疾患・症状のカラム名が入ります。）
     * @type {{ [key: string]: PpMstThresholdCatModelGetEvaluationMapResponse; }}
     * @memberof PpTestResultControllerGetHistoryResponse
     */
    'evaluation_cat': { [key: string]: PpMstThresholdCatModelGetEvaluationMapResponse; };
    /**
     * 犬の場合の評価データのオブジェクト（キーには疾患・症状のカラム名が入ります。）
     * @type {{ [key: string]: PpMstThresholdDogModelGetEvaluationMapResponse; }}
     * @memberof PpTestResultControllerGetHistoryResponse
     */
    'evaluation_dog': { [key: string]: PpMstThresholdDogModelGetEvaluationMapResponse; };
    /**
     * 
     * @type {PpTestResultControllerGetHistoryResponsePpTestKit}
     * @memberof PpTestResultControllerGetHistoryResponse
     */
    'pp_test_kit': PpTestResultControllerGetHistoryResponsePpTestKit;
    /**
     * 
     * @type {PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat}
     * @memberof PpTestResultControllerGetHistoryResponse
     */
    'pp_test_result_riskscore_cat': PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat;
    /**
     * 
     * @type {PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog}
     * @memberof PpTestResultControllerGetHistoryResponse
     */
    'pp_test_result_riskscore_dog': PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog;
    /**
     * 
     * @type {PpTestResultControllerGetHistoryResponseRcmndData}
     * @memberof PpTestResultControllerGetHistoryResponse
     */
    'rcmnd_data': PpTestResultControllerGetHistoryResponseRcmndData;
    /**
     * 閾値データ
     * @type {{ [key: string]: Array<PpMstThresholdCatModelGetThresholdMapResponse>; }}
     * @memberof PpTestResultControllerGetHistoryResponse
     */
    'thresholds': { [key: string]: Array<PpMstThresholdCatModelGetThresholdMapResponse>; };
}
/**
 * 腸内検査キット管理明細データ
 * @export
 * @interface PpTestResultControllerGetHistoryResponsePpTestKit
 */
export interface PpTestResultControllerGetHistoryResponsePpTestKit {
    /**
     * 生年月日確度 1：確定 2：推定 3：不明
     * @type {PptestkitBiAccuracyOfPetBirthday}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_accuracy_of_pet_birthday': PptestkitBiAccuracyOfPetBirthday | null;
    /**
     * 動物種類 1：犬 2：猫
     * @type {PptestkitBiAnimalType}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_animal_type': PptestkitBiAnimalType | null;
    /**
     * 品種
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_breed': number | null;
    /**
     * 品種名
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_breed_name': string | null;
    /**
     * 両親の品種名1
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_breed_name_parent_1': string | null;
    /**
     * 両親の品種名2
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_breed_name_parent_2': string | null;
    /**
     * 両親の品種1
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_breed_of_parents_1': number | null;
    /**
     * 両親の品種2
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_breed_of_parents_2': number | null;
    /**
     * ペットアイコン
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_icon': string | null;
    /**
     * アイコン画像（ディレクトリを省いてbasenameとした画像名）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_icon_name': string | null;
    /**
     * 閲覧用アイコン画像URL
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_icon_url': string | null;
    /**
     * ペット年齢 ○歳○ヶ月
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_pet_age': string | null;
    /**
     * ペット生年月日
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_pet_birthday': string | null;
    /**
     * ペット名前
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_pet_name': string | null;
    /**
     * ペット名前フリガナ
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_pet_name_furigana': string | null;
    /**
     * ペット名前ローマ字
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_pet_name_romaji': string | null;
    /**
     * ペット性別 1：オス 2：メス
     * @type {PptestkitBiPetSex}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'bi_pet_sex': PptestkitBiPetSex | null;
    /**
     * 検査完了日時 YYYY-MM-DD HH:MM:SS
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'completion_date_time': string | null;
    /**
     * 現在検査回数
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'current_number_of_test': number | null;
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'flowcell_id': string | null;
    /**
     * 強制検査フラグ
     * @type {boolean}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'forced_test': boolean | null;
    /**
     * 識別コード
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'identification_cd': string | null;
    /**
     * 攻撃性(家族に対して) 1：よく攻撃する、2：時々攻撃する、3：ほとんど攻撃しない
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_aggression_for_family': string | null;
    /**
     * 攻撃性(見知らぬ人に対して) 1：よく攻撃する、2：時々攻撃する、3：ほとんど攻撃しない
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_aggression_for_stranger': string | null;
    /**
     * アレルギー（その他内容）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_allergy_comment': string | null;
    /**
     * アレルギー：食物（消化器） 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_allergy_food_digestive_organ': string;
    /**
     * アレルギー：食物（皮膚） 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_allergy_food_skin': string;
    /**
     * アレルギー：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_allergy_other': string;
    /**
     * 食欲 1：完食する、2：完食するが食いつきが悪い、3：食べ残す、4：安定しない
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_appetite': string | null;
    /**
     * 体型 1：痩せている、2：やや痩せている、3：標準、4：やや肥満、5：肥満
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_body_figure': string | null;
    /**
     * 脳・脊髄疾患：脳疾患（原因不明） 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_brain_spinal_disease_brain_disease': string;
    /**
     * 脳・脊髄疾患（その他内容）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_brain_spinal_disease_comment': string | null;
    /**
     * 脳・脊髄疾患：てんかん 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_brain_spinal_disease_epilepsy': string;
    /**
     * 脳・脊髄疾患：椎間板ヘルニア 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_brain_spinal_disease_herniated_disc': string;
    /**
     * 脳・脊髄疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_brain_spinal_disease_other': string;
    /**
     * 申し込み完了フラグ（=検査申込有無）
     * @type {boolean}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_confirmed': boolean;
    /**
     * 検査に同意する 0：同意しない、1：同意する
     * @type {boolean}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_consent_for_testing': boolean;
    /**
     * 下痢 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_diarrhea': string | null;
    /**
     * 下痢の発症時期 1：3日以内、2：1週間以内、3：2週間以内、4：１ヶ月以内、5：１ヶ月以上前
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_diarrhea_timing': string | null;
    /**
     * 消化器疾患（その他内容）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_digestive_disease_comment': string | null;
    /**
     * 消化器疾患：慢性腸症/炎症性腸疾患 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_digestive_disease_ibd': string;
    /**
     * 消化器疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_digestive_disease_other': string;
    /**
     * 消化器疾患：膵炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_digestive_disease_pancreatitis': string;
    /**
     * 消化器疾患：膵外分泌不全 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_digestive_disease_pei': string;
    /**
     * 病歴 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_disease': string;
    /**
     * 飼育環境 1：屋外、2：屋内、3：自由に行き来している
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_environment': string | null;
    /**
     * 1日の運動時間 1：1時間未満、2：1-2時間、3：2-3時間、4：3-4時間、5：4時間以上
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_exercise_time_oneday': string | null;
    /**
     * 眼科疾患：白内障 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_eye_disease_cataract': string;
    /**
     * 眼科疾患（その他内容）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_eye_disease_comment': string | null;
    /**
     * 眼科疾患：乾性核結膜炎（ドライアイ） 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_eye_disease_dry_eyes': string;
    /**
     * 眼科疾患：緑内障 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_eye_disease_glaucoma': string;
    /**
     * 眼科疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_eye_disease_other': string;
    /**
     * 目脂または涙やけ 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_eyemucus_tearsstain': string | null;
    /**
     * 恐怖心(他の犬or猫に対して) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_fear_for_other_pets': string | null;
    /**
     * 恐怖心(他の人に対して) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_fear_for_stranger': string | null;
    /**
     * 恐怖心(慣れない環境) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_fear_unfamiliar_environment': string | null;
    /**
     * ブラッシング頻度 1：毎日、2：週3-4回、3：週1-2回、4：月1-2回、5：ほとんどしない
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_frequency_brushing': string | null;
    /**
     * うんち/回数(1日) 1：毎日、2：週3-4回、3：週1-2回、4：月1-2回、5：ほとんどしない
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_frequency_excrement': string | null;
    /**
     * シャンプー頻度 1：週１-２回、2：月１-２回、3：２-３ヶ月に１回、4：ほとんどしない
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_frequency_shampoo': string | null;
    /**
     * 散歩頻度 1：毎日、2：週3-4回、3：週1-2回、4：月1-2回、5：ほとんどしない
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_frequency_walking': string | null;
    /**
     * 心臓疾患：心筋症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_heart_disease_cardiomyopathy': string;
    /**
     * 心臓疾患（その他内容）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_heart_disease_comment': string | null;
    /**
     * 心臓疾患：心不全 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_heart_disease_heart_failure': string;
    /**
     * 心臓疾患：僧帽弁閉鎖不全症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_heart_disease_mr': string;
    /**
     * 心臓疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_heart_disease_other': string;
    /**
     * 心臓疾患：肺高血圧症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_heart_disease_ph': string;
    /**
     * ごはんが手作りの場合の種類：その他内容
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_homemade_mainfood_type_comment': string | null;
    /**
     * ごはんが手作りの場合の種類：魚 0：なし、1：あり
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_homemade_mainfood_type_fish': string;
    /**
     * ごはんが手作りの場合の種類：果物 0：なし、1：あり
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_homemade_mainfood_type_fruit': string;
    /**
     * ごはんが手作りの場合の種類：肉 0：なし、1：あり
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_homemade_mainfood_type_meat': string;
    /**
     * ごはんが手作りの場合の種類：その他 0：なし、1：あり
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_homemade_mainfood_type_other': string;
    /**
     * ごはんが手作りの場合の種類：野菜 0：なし、1：あり
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_homemade_mainfood_type_vegetable': string;
    /**
     * ホルモン・代謝疾患（その他内容）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_hormone_metabolic_disorder_comment': string | null;
    /**
     * ホルモン・代謝疾患：クッシング症候群 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_hormone_metabolism_cushing_syndrome': string;
    /**
     * ホルモン・代謝疾患：糖尿病 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_hormone_metabolism_diabetes': string;
    /**
     * ホルモン・代謝疾患：甲状腺機能低下症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_hormone_metabolism_dl': string;
    /**
     * ホルモン・代謝疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_hormone_metabolism_other': string;
    /**
     * 高血圧 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_hypertension': string;
    /**
     * 腎臓疾患：慢性腎不全 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_kidney_disease_ckd': string;
    /**
     * 腎臓疾患（その他内容）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_kidney_disease_comment': string | null;
    /**
     * 腎臓疾患：腎結石 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_kidney_disease_kidney_stone': string;
    /**
     * 腎臓疾患：腎炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_kidney_disease_nephritis': string;
    /**
     * 腎臓疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_kidney_disease_other': string;
    /**
     * 腎臓疾患：蛋白漏出性腎症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_kidney_disease_pln': string;
    /**
     * 泌乳した子供の数 0：不明 0~99匹選択
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_lactated_children': string | null;
    /**
     * 泌乳経験 0：なし、1：あり
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_lactation_experience': string | null;
    /**
     * 日常的な乳酸菌サプリ摂取有無 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_lactic_supplement': string | null;
    /**
     * 肝・胆道疾患：胆泥症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_liver_bile_disease_biliary_sludge': string;
    /**
     * 肝・胆道疾患（その他内容）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_liver_bile_disease_comment': string | null;
    /**
     * 肝・胆道疾患：胆嚢粘液嚢胞 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_liver_bile_disease_gallbladder_mucocele': string;
    /**
     * 肝・胆道疾患：肝障害 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_liver_bile_disease_liver_damage': string;
    /**
     * 肝・胆道疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_liver_bile_disease_other': string;
    /**
     * ごはん 1：ドライフード、2：ウエットフード、3：手作り、4：その他（自由記述）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_main_food': string | null;
    /**
     * ごはん（その他内容）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_main_food_comment': string | null;
    /**
     * 抗生物質の投与 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_medicine_antibiotics': string | null;
    /**
     * 抗生物質以外の薬の投与 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_medicine_except_antibiotics': string | null;
    /**
     * 整形疾患：関節炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_orthopedic_disease_arthritis': string;
    /**
     * 整形疾患（その他内容）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_orthopedic_disease_comment': string | null;
    /**
     * 整形疾患：股関節異形成 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_orthopedic_disease_hd': string;
    /**
     * 整形疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_orthopedic_disease_other': string;
    /**
     * 整形疾患：膝蓋骨脱臼 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_orthopedic_disease_patella': string;
    /**
     * その他病歴
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_other_comment': string | null;
    /**
     * ペット体重（Go言語では、1.0が1として判断されるため、小数点以下の選択チェックが行えないので、文字列にしています。）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_pet_weight': string | null;
    /**
     * 現在の妊娠・妊娠の可能性 1：はい、2：いいえ、0：不明
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_pregnancy': string;
    /**
     * 妊娠期間 0：なし 0~99日選択
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_pregnancy_weeks': string | null;
    /**
     * 研究参加に同意する 0：同意しない、1：同意する
     * @type {boolean}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_research_participation_consent': boolean;
    /**
     * 呼吸器疾患：喘息 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_respiratory_disease_asthma': string;
    /**
     * 呼吸器疾患：気管支炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_respiratory_disease_bronchitis': string;
    /**
     * 呼吸器疾患（その他内容）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_respiratory_disease_comment': string | null;
    /**
     * 呼吸器疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_respiratory_disease_other': string;
    /**
     * 呼吸器疾患：鼻炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_respiratory_disease_rhinitis': string;
    /**
     * 呼吸器疾患：気管虚脱 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_respiratory_disease_tracheal_collapse': string;
    /**
     * 皮膚疾患：その他のコメント
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_disease_comment': string | null;
    /**
     * 皮膚疾患：皮膚炎（原因が判明していない）0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_disease_eczema': string;
    /**
     * 皮膚疾患：外耳炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_disease_external_otitisis': string;
    /**
     * 皮膚疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_disease_other': string;
    /**
     * 皮膚疾患：膿皮症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_disease_pyoderma': string;
    /**
     * 皮膚疾患：脂漏症 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_disease_seforrhoea': string;
    /**
     * 皮膚トラブル 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_trouble': string | null;
    /**
     * 皮膚トラブル（その他内容）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_trouble_symptoms_comment': string | null;
    /**
     * 皮膚トラブル：フケ 0：なし、1：あり
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_trouble_symptoms_dandruff': string;
    /**
     * 皮膚トラブル：乾燥 0：なし、1：あり
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_trouble_symptoms_dry': string;
    /**
     * 皮膚トラブル：脱毛 0：なし、1：あり
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_trouble_symptoms_hairloss': string;
    /**
     * 皮膚トラブル：かゆみ 0：なし、1：あり
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_trouble_symptoms_itch': string;
    /**
     * 皮膚トラブル：その他 0：なし、1：あり
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_trouble_symptoms_other': string;
    /**
     * 皮膚トラブル：発疹 0：なし、1：あり
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_trouble_symptoms_rash': string;
    /**
     * 皮膚トラブル：赤み 0：なし、1：あり
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_trouble_symptoms_sore': string;
    /**
     * 皮膚トラブル発症時期 1：3日以内、2：1週間以内、3：2週間以内、4：１ヶ月以内、5：１ヶ月以上前
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_skin_trouble_timing': string | null;
    /**
     * 避妊・去勢の手術経験 1：はい、2：いいえ、0：不明
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_spayed_neutered': string;
    /**
     * 泌乳した期間の合計 0：不明 0~99週間選択
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_total_lactation_duration': string | null;
    /**
     * 腫瘍
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_tumor_comment': string | null;
    /**
     * 分離不安(器物破損、大小便） 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_uneasiness_damage_to_property': string | null;
    /**
     * 分離不安(飼い主と分離時の不安感) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_uneasiness_separated_from_owner': string | null;
    /**
     * 泌尿器疾患：膀胱結石 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_urology_disease_bladder_stone': string;
    /**
     * 泌尿器疾患（その他内容）
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_urology_disease_comment': string | null;
    /**
     * 泌尿器疾患：特発性膀胱炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_urology_disease_idiopathic_cystitis': string;
    /**
     * 泌尿器疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_urology_disease_other': string;
    /**
     * 泌尿器疾患：その他の膀胱炎 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_urology_disease_other_cystitis': string;
    /**
     * 嘔吐 0：いいえ、1：はい
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_vomit': string | null;
    /**
     * 嘔吐の発症時期 1：3日以内、2：1週間以内、3：2週間以内、4：１ヶ月以内、5：１ヶ月以上前
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_vomit_timing': string | null;
    /**
     * 過去の妊娠の有無 1：はい、2：いいえ、0：不明
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_was_pregnant_past': string;
    /**
     * 気になること／その他
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_any_other': string;
    /**
     * 気になること／その他コメント
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_any_other_comment': string | null;
    /**
     * 気になること／口臭が気になる 0：ない、1：ある
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_bad_breath': string;
    /**
     * 気になること／毛ヅヤが悪い気がする 0：ない、1：ある
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_bad_hair': string;
    /**
     * 気になること／体臭が気になる 0：ない、1：ある
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_body_odor': string;
    /**
     * 気になること／歯石が気になる 0：ない、1：ある
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_dental_tartar': string;
    /**
     * 気になること／食糞する 0：ない、1：ある
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_eat_excrement': string;
    /**
     * 気になること／おしっこの回数が多い 0：ない、1：ある
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_frequent_urination': string;
    /**
     * 気になること／よだれが垂れる 0：ない、1：ある
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_increased_salivation': string;
    /**
     * 気になること／耳がかゆい 0：ない、1：ある
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_itchy_ears': string;
    /**
     * 気になること／体重が減りやすい 0：ない、1：ある
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_lost_weight': string;
    /**
     * 気になること／特になし 0：気になることあり、1：気になること特になし
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_nothing': string;
    /**
     * 気になること／耳の臭いが気になる 0：ない、1：ある
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_smell_of_ears': string;
    /**
     * 気になること／便が臭い 0：ない、1：ある
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_smell_of_excrement': string;
    /**
     * 気になること／イボがある 0：ない、1：ある
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_warts': string;
    /**
     * 気になること／体重が増えやすい 0：ない、1：ある
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'is_worry_weight_easily': string;
    /**
     * LabバーコードID
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'lab_barcode_id': string | null;
    /**
     * 病院ID
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'medic_inst_id': number | null;
    /**
     * 病院名
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'medic_inst_name': string | null;
    /**
     * メタデータ出力
     * @type {boolean}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'metadata_out': boolean | null;
    /**
     * メタデータ出力日時 YYYY-MM-DD HH:MM:SS
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'metadata_out_date_time': string | null;
    /**
     * メタデータ出力ユーザー名
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'metadata_out_username': string | null;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'partner_inst_id': number | null;
    /**
     * 提携法人名
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'partner_inst_name': string | null;
    /**
     * 検体位置
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'position': string | null;
    /**
     * 
     * @type {TypesPpTestKitDataPpPet}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'pp_pet': TypesPpTestKitDataPpPet | null;
    /**
     * ペットID（未申込の場合はnull）
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'pp_pet_id': number | null;
    /**
     * 
     * @type {TypesPpTestKitDataPpUser}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'pp_user': TypesPpTestKitDataPpUser | null;
    /**
     * 会員ID（未申込の場合はnull）
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'pp_user_id': number | null;
    /**
     * 再検査フラグ
     * @type {boolean}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    're_test': boolean | null;
    /**
     * リコメンド要否区分 0：商品おすすめなし、1：腸内環境タイプ別商品おすすめ、2：腸内環境タイプ共通商品おすすめ
     * @type {PptestkitRecommendType}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'recommend_type': PptestkitRecommendType;
    /**
     * 検査依頼登録日 YYYY-MM-DD
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'request_ymd': string | null;
    /**
     * 検体ID
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'sample_id': string;
    /**
     * 検体採取日 YYYY-MM-DD
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'sampling_date': string | null;
    /**
     * SKUコード
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'sku_code': string;
    /**
     * 検査開始日 YYYY-MM-DD
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'start_ymd': string | null;
    /**
     * 状況00：検査未依頼、01：検査未受付、02：検査中、12：再検査中、22：強制検査中、50：検査完了
     * @type {PpteststatusStatus}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'status': PpteststatusStatus | null;
    /**
     * 状況（ユーザー用） 1：検査依頼済み、2：検査中、3：検査完了
     * @type {PpteststatusStatusForUser}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'status_for_user': PpteststatusStatusForUser | null;
    /**
     * 検索分
     * @type {PptestkitTestDivision}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestKit
     */
    'test_division': PptestkitTestDivision;
}
/**
 * 猫リスクスコアデータ
 * @export
 * @interface PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
 */
export interface PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat {
    /**
     * 急性下痢リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'acute_diarrhea_risk_score'?: string;
    /**
     * 膵炎リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'acute_pancreatitis_risk_score'?: string;
    /**
     * アトピー性皮膚炎リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'atopic_dermatitis_risk_score'?: string;
    /**
     * 自己免疫疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'autoimmune_disease_risk_score'?: string;
    /**
     * 胆管炎/胆管肝炎リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'bile_duct_hepatitis_risk_score'?: string;
    /**
     * 脳腫瘍リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'brain_tumor_rsk_score'?: string;
    /**
     * 循環器疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'cardiology_disease_risk_score'?: string;
    /**
     * 悪玉菌の細菌種数
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'cell_count_of_bad_bacteria'?: string;
    /**
     * 善玉菌の細菌種数
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'cell_count_of_good_bacteria'?: string;
    /**
     * その他の細菌種数
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'cell_count_of_other'?: string;
    /**
     * 腫瘍性疾患_消化器型小細胞性リンパ腫リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'cell_lymphoma_risk_score'?: string;
    /**
     * 脳梗塞リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'cerebral_infarction_risk_score'?: string;
    /**
     * 慢性下痢リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'chronic_diarrhea_risk_score'?: string;
    /**
     * 慢性腸症リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'chronic_enteropathy_risk_score'?: string;
    /**
     * 慢性心不全リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'chronic_heart_failure_risk_score'?: string;
    /**
     * 慢性腎不全リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'ckd_risk_score'?: string;
    /**
     * 便秘リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'constipation_risk_score'?: string;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'del'?: boolean;
    /**
     * 認知症リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'dementia_risk_score'?: string;
    /**
     * 皮膚疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'dermatological_disease_risk_score'?: string;
    /**
     * 下痢リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'diarrhea_risk_score'?: string;
    /**
     * 消化器疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'digestive_disorders_risk_score'?: string;
    /**
     * 多様性指数
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'diversity_index'?: string;
    /**
     * 糖尿病リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'dm_risk_score'?: string;
    /**
     * てんかんリスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'epilepsy_score'?: string;
    /**
     * 食物アレルギーリスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'food_allergy_risk_score'?: string;
    /**
     * 胆石リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'gallstones_risk_score'?: string;
    /**
     * 善玉菌/悪玉菌指数
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'good_bacteria_bad_bacteria_index'?: string;
    /**
     * クッシング症候群リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'hyperadrenocorticism_risk_score'?: string;
    /**
     * 高血圧リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'hypertention_risk_score'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'id'?: number;
    /**
     * 炎症性腸疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'inflammatory_bowel_disease_risk_score'?: string;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'ins_ymd'?: string;
    /**
     * 腸内環境スコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'intestinal_environment_score'?: string;
    /**
     * 腸内環境タイプ
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'intestinal_environment_type'?: string;
    /**
     * 腎結石リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'kidney_stones_risk_score'?: string;
    /**
     * 肝疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'liver_disease_risk_score'?: string;
    /**
     * 代謝・内分泌疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'metabolic_and_endocrine_diseases_risk_score'?: string;
    /**
     * 神経疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'neurologycal_disease_risk_score'?: string;
    /**
     * 腸内にいた細菌種数
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'number_of_bacteria_in_the_intestine'?: string;
    /**
     * 検査回数
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'number_of_test'?: number;
    /**
     * 肥満リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'obesity_risk_score'?: string;
    /**
     * 眼科疾患_緑内障リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'ophthalmology_disease_glaucoma_risk_score'?: string;
    /**
     * 整形疾患_変形性関節症リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'orthopedic_disease_chronic_arthritis_risk_score'?: string;
    /**
     * 悪玉菌の割合
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'percentage_of_bad_bacteria'?: string;
    /**
     * 善玉菌の割合
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'percentage_of_good_bacteria'?: string;
    /**
     * その他の割合
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'percentage_of_other'?: string;
    /**
     * ペットID
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'pp_pet_id'?: number;
    /**
     * 呼吸器疾患_喘息リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'respiratory_disease_asthma_risk_score'?: string;
    /**
     * リビジョン
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'revision'?: number;
    /**
     * 検体ID
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'sample_id'?: string;
    /**
     * 検体採取日
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'sampling_date'?: string;
    /**
     * 類似度指数
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'similarity_index'?: string;
    /**
     * 潰瘍性大腸炎リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'ulcerative_colitis_risk_score'?: string;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'upd_ymd'?: string;
    /**
     * 腎泌尿器疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreCat
     */
    'urologycal_disease_risk_score'?: string;
}
/**
 * 犬リスクスコアデータ
 * @export
 * @interface PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
 */
export interface PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog {
    /**
     * 急性下痢リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'acute_diarrhea_risk_score'?: string;
    /**
     * 膵炎リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'acute_pancreatitis_risk_score'?: string;
    /**
     * 攻撃性リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'aggression_risk_score'?: string;
    /**
     * アトピー性皮膚炎リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'atopic_dermatitis_risk_score'?: string;
    /**
     * 自己免疫性脳炎リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'autoimmune_encephalitis_risk_score'?: string;
    /**
     * 自己免疫疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'autoimmune_risk_score'?: string;
    /**
     * 胆管炎/胆管肝炎リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'bile_duct_hepatitis_risk_score'?: string;
    /**
     * 脳腫瘍リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'brain_tumor_risk_score'?: string;
    /**
     * 循環器疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'cardiology_disease_risk_score'?: string;
    /**
     * 悪玉菌の細菌種数
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'cell_count_of_bad_bacteria'?: string;
    /**
     * 善玉菌の細菌種数
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'cell_count_of_good_bacteria'?: string;
    /**
     * その他の細菌種数
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'cell_count_of_other'?: string;
    /**
     * 脳梗塞リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'cerebral_infarction_risk_score'?: string;
    /**
     * 慢性関節炎リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'chronic_arthritis_risk_score'?: string;
    /**
     * 慢性下痢リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'chronic_diarrhea_risk_score'?: string;
    /**
     * 慢性腎不全リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'ckd_risk_score'?: string;
    /**
     * うっ血性心不全リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'congestive_heart_failure_risk_score'?: string;
    /**
     * 皮膚型T細胞性リンパ腫リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'cutaneous_t_cell_lymphoma_risk_score'?: string;
    /**
     * 論理削除フラグ
     * @type {boolean}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'del'?: boolean;
    /**
     * 認知症リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'dementia_risk_score'?: string;
    /**
     * 皮膚疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'dermatological_disease_risk_score'?: string;
    /**
     * 下痢リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'diarrhea_risk_score'?: string;
    /**
     * 消化器疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'digestive_disease_risk_score'?: string;
    /**
     * 拡張型心筋症リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'dilated_cardiomyopathy_risk_score'?: string;
    /**
     * 多様性指数
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'diversity_index'?: string;
    /**
     * 糖尿病リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'dm_risk_score'?: string;
    /**
     * 膵外分泌不全リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'epi_risk_score'?: string;
    /**
     * てんかんリスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'epilepsy_score'?: string;
    /**
     * 食物アレルギーリスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'food_allergy_risk_score'?: string;
    /**
     * 胆石リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'gallstones_risk_score'?: string;
    /**
     * 善玉菌/悪玉菌指数
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'good_bacteria_bad_bacteria_index'?: string;
    /**
     * クッシング症候群リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'hyperadrenocorticism_risk_score'?: string;
    /**
     * 高血圧リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'hypertention_risk_score'?: string;
    /**
     * 炎症性腸疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'ibd_risk_score'?: string;
    /**
     * ID of the ent.
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'id'?: number;
    /**
     * 腸リンパ管拡張症リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'il_risk_score'?: string;
    /**
     * 登録機能ID
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'ins_function_id'?: string;
    /**
     * 登録時刻 HHMMSS
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'ins_hms'?: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'ins_user_id'?: string;
    /**
     * 登録年月日 YYYYMMDD
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'ins_ymd'?: string;
    /**
     * 腸内環境スコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'intestinal_environment_score'?: string;
    /**
     * 腸内環境タイプ
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'intestinal_environment_type'?: string;
    /**
     * かゆみリスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'itch_risk_score'?: string;
    /**
     * 腎結石リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'kidney_stones_risk_score'?: string;
    /**
     * 肝疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'liver_disease_risk_score'?: string;
    /**
     * リンパ腫リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'lymphoma_risk_score'?: string;
    /**
     * 代謝・内分泌疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'metabolic_and_endocrine_diseases_risk_score'?: string;
    /**
     * 僧帽弁閉鎖不全症リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'mitral_regurgitation_risk_score'?: string;
    /**
     * 多中心型リンパ腫リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'multicentric_lymphoma_risk_score'?: string;
    /**
     * 髄膜脳脊髄炎リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'muo_risk_score'?: string;
    /**
     * 腫瘍性疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'neoplastic_disease_risk_score'?: string;
    /**
     * 神経疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'neurologycal_disease_risk_score'?: string;
    /**
     * 腸内にいた細菌種数
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'number_of_bacteria_in_the_intestine'?: string;
    /**
     * 検査回数
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'number_of_test'?: number;
    /**
     * 肥満リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'obesity_risk_score'?: string;
    /**
     * 眼科疾患_緑内障リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'ophthalmology_disease_glaucoma_risk_score'?: string;
    /**
     * 整形疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'orthopedic_disease_risk_score'?: string;
    /**
     * 変形性関節症リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'osteoarthritis_risk_score'?: string;
    /**
     * 悪玉菌の割合
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'percentage_of_bad_bacteria'?: string;
    /**
     * 善玉菌の割合
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'percentage_of_good_bacteria'?: string;
    /**
     * その他の割合
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'percentage_of_other'?: string;
    /**
     * 恐怖リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'phobia_risk_score'?: string;
    /**
     * ペットID
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'pp_pet_id'?: number;
    /**
     * リビジョン
     * @type {number}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'revision'?: number;
    /**
     * 検体ID
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'sample_id'?: string;
    /**
     * 検体採取日
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'sampling_date'?: string;
    /**
     * 類似度指数
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'similarity_index'?: string;
    /**
     * ストレスのリスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'stress_risk_score'?: string;
    /**
     * 更新機能ID
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'upd_function_id'?: string;
    /**
     * 更新時刻 HHMMSS
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'upd_hms'?: string;
    /**
     * 更新ユーザー種別、ID
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'upd_user_id'?: string;
    /**
     * 更新年月日 YYYYMMDD
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'upd_ymd'?: string;
    /**
     * 腎泌尿器疾患リスクスコア
     * @type {string}
     * @memberof PpTestResultControllerGetHistoryResponsePpTestResultRiskscoreDog
     */
    'urologycal_disease_risk_score'?: string;
}
/**
 * おすすめ商品データ
 * @export
 * @interface PpTestResultControllerGetHistoryResponseRcmndData
 */
export interface PpTestResultControllerGetHistoryResponseRcmndData {
    /**
     * 
     * @type {PpMstRcmndMethodModelGetRcmndDataResponseGenericRcmndData}
     * @memberof PpTestResultControllerGetHistoryResponseRcmndData
     */
    'generic_rcmnd_data'?: PpMstRcmndMethodModelGetRcmndDataResponseGenericRcmndData | null;
    /**
     * 横型リコメンドデータ
     * @type {Array<PpMstRcmndMethodModelHorizontalRcmndData>}
     * @memberof PpTestResultControllerGetHistoryResponseRcmndData
     */
    'horizontal_rcmnd_data'?: Array<PpMstRcmndMethodModelHorizontalRcmndData>;
    /**
     * 縦型リコメンドデータ
     * @type {Array<PpMstRcmndMethodModelVerticalRcmndData>}
     * @memberof PpTestResultControllerGetHistoryResponseRcmndData
     */
    'vertical_rcmnd_data'?: Array<PpMstRcmndMethodModelVerticalRcmndData>;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerGetResultRequest
 */
export interface PpTestResultControllerGetResultRequest {
    /**
     * 
     * @type {Array<PpTestResultControllerTarget>}
     * @memberof PpTestResultControllerGetResultRequest
     */
    'targets'?: Array<PpTestResultControllerTarget>;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerGetResultResponse
 */
export interface PpTestResultControllerGetResultResponse {
    /**
     * 検体IDをキーにしたブール値のオブジェクト(true = 結果あり)
     * @type {{ [key: string]: boolean; }}
     * @memberof PpTestResultControllerGetResultResponse
     */
    'results': { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface PpTestResultControllerGetRollbackResponse
 */
export interface PpTestResultControllerGetRollbackResponse {
    /**
     * 切り戻し可能データの配列
     * @type {Array<TypesRollbackData>}
     * @memberof PpTestResultControllerGetRollbackResponse
     */
    'records': Array<TypesRollbackData>;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerMetadataDownloadRequest
 */
export interface PpTestResultControllerMetadataDownloadRequest {
    /**
     * 検体IDのリスト
     * @type {Array<string>}
     * @memberof PpTestResultControllerMetadataDownloadRequest
     */
    'sample_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerMetadataIndexRequest
 */
export interface PpTestResultControllerMetadataIndexRequest {
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof PpTestResultControllerMetadataIndexRequest
     */
    'flowcell_id'?: string;
    /**
     * 検体ID
     * @type {string}
     * @memberof PpTestResultControllerMetadataIndexRequest
     */
    'sample_id'?: string;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerMetadataIndexResponse
 */
export interface PpTestResultControllerMetadataIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpTestResultData>}
     * @memberof PpTestResultControllerMetadataIndexResponse
     */
    'pp_test_results': Array<TypesPpTestResultData>;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerReferenceRequest
 */
export interface PpTestResultControllerReferenceRequest {
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof PpTestResultControllerReferenceRequest
     */
    'flowcell_id'?: string;
    /**
     * 検体ID
     * @type {string}
     * @memberof PpTestResultControllerReferenceRequest
     */
    'sample_id'?: string;
    /**
     * PCRプレート番号
     * @type {string}
     * @memberof PpTestResultControllerReferenceRequest
     */
    'wellplate_id'?: string;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerReferenceResponse
 */
export interface PpTestResultControllerReferenceResponse {
    /**
     * 照会データ
     * @type {Array<TypesReferenceData>}
     * @memberof PpTestResultControllerReferenceResponse
     */
    'reference': Array<TypesReferenceData>;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerResultIndexRequest
 */
export interface PpTestResultControllerResultIndexRequest {
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof PpTestResultControllerResultIndexRequest
     */
    'flowcell_id'?: string;
    /**
     * 検体ID
     * @type {string}
     * @memberof PpTestResultControllerResultIndexRequest
     */
    'sample_id'?: string;
    /**
     * 検体IDの配列
     * @type {Array<string>}
     * @memberof PpTestResultControllerResultIndexRequest
     */
    'sample_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerRollbackRequest
 */
export interface PpTestResultControllerRollbackRequest {
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof PpTestResultControllerRollbackRequest
     */
    'flowcell_id'?: string;
    /**
     * 検体ID
     * @type {string}
     * @memberof PpTestResultControllerRollbackRequest
     */
    'sample_id'?: string;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerSearchHistoryPetResponse
 */
export interface PpTestResultControllerSearchHistoryPetResponse {
    /**
     * 検査履歴データ
     * @type {Array<PpTestResultControllerGetHistoryResponse>}
     * @memberof PpTestResultControllerSearchHistoryPetResponse
     */
    'histories': Array<PpTestResultControllerGetHistoryResponse>;
    /**
     * 
     * @type {PpPetControllerGetResponsePpPet}
     * @memberof PpTestResultControllerSearchHistoryPetResponse
     */
    'pp_pet': PpPetControllerGetResponsePpPet;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerSetResultRequest
 */
export interface PpTestResultControllerSetResultRequest {
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof PpTestResultControllerSetResultRequest
     */
    'flowcell_id'?: string | null;
    /**
     * 再検査対象の検体IDの配列
     * @type {Array<string>}
     * @memberof PpTestResultControllerSetResultRequest
     */
    're_tests'?: Array<string>;
    /**
     * 検体IDの配列
     * @type {Array<string>}
     * @memberof PpTestResultControllerSetResultRequest
     */
    'sample_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PpTestResultControllerTarget
 */
export interface PpTestResultControllerTarget {
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof PpTestResultControllerTarget
     */
    'flowcell_id'?: string;
    /**
     * 検体ID
     * @type {string}
     * @memberof PpTestResultControllerTarget
     */
    'sample_id'?: string;
}
/**
 * 
 * @export
 * @interface PpUserControllerCreateRequest
 */
export interface PpUserControllerCreateRequest {
    /**
     * 生年月日 YYYY-MM-DD
     * @type {string}
     * @memberof PpUserControllerCreateRequest
     */
    'birthday'?: string;
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof PpUserControllerCreateRequest
     */
    'building'?: string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof PpUserControllerCreateRequest
     */
    'city'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PpUserControllerCreateRequest
     */
    'email'?: string;
    /**
     * 性別 1=>male 2=>female 3=>unknown
     * @type {string}
     * @memberof PpUserControllerCreateRequest
     */
    'gender'?: string;
    /**
     * バリデーションのみ行う場合trueを指定
     * @type {boolean}
     * @memberof PpUserControllerCreateRequest
     */
    'is_only_validate'?: boolean;
    /**
     * パスワード（平文）
     * @type {string}
     * @memberof PpUserControllerCreateRequest
     */
    'password'?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof PpUserControllerCreateRequest
     */
    'postal_code'?: string;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof PpUserControllerCreateRequest
     */
    'pref_code'?: string;
    /**
     * 丁目・番地
     * @type {string}
     * @memberof PpUserControllerCreateRequest
     */
    'street'?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof PpUserControllerCreateRequest
     */
    'tel_no'?: string;
    /**
     * 姓カナ
     * @type {string}
     * @memberof PpUserControllerCreateRequest
     */
    'user_family_furigana'?: string;
    /**
     * 姓
     * @type {string}
     * @memberof PpUserControllerCreateRequest
     */
    'user_family_name'?: string;
    /**
     * 名カナ
     * @type {string}
     * @memberof PpUserControllerCreateRequest
     */
    'user_first_furigana'?: string;
    /**
     * 名
     * @type {string}
     * @memberof PpUserControllerCreateRequest
     */
    'user_first_name'?: string;
    /**
     * 利用規約への同意
     * @type {boolean}
     * @memberof PpUserControllerCreateRequest
     */
    'user_policy_consent'?: boolean;
}
/**
 * 
 * @export
 * @interface PpUserControllerEditRequest
 */
export interface PpUserControllerEditRequest {
    /**
     * 生年月日 YYYY-MM-DD
     * @type {string}
     * @memberof PpUserControllerEditRequest
     */
    'birthday'?: string;
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof PpUserControllerEditRequest
     */
    'building'?: string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof PpUserControllerEditRequest
     */
    'city'?: string;
    /**
     * パスワード（再入力・平文）
     * @type {string}
     * @memberof PpUserControllerEditRequest
     */
    'confirmation_password'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PpUserControllerEditRequest
     */
    'email'?: string;
    /**
     * 性別 male or female or other
     * @type {string}
     * @memberof PpUserControllerEditRequest
     */
    'gender'?: string;
    /**
     * メールアドレスを変更する場合true
     * @type {boolean}
     * @memberof PpUserControllerEditRequest
     */
    'is_email_change'?: boolean;
    /**
     * バリデーションのみ行う場合trueを指定
     * @type {boolean}
     * @memberof PpUserControllerEditRequest
     */
    'is_only_validate'?: boolean;
    /**
     * パスワードを変更する場合true
     * @type {boolean}
     * @memberof PpUserControllerEditRequest
     */
    'is_password_change'?: boolean;
    /**
     * パスワード（平文）
     * @type {string}
     * @memberof PpUserControllerEditRequest
     */
    'password'?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof PpUserControllerEditRequest
     */
    'postal_code'?: string;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof PpUserControllerEditRequest
     */
    'pref_code'?: string;
    /**
     * 丁目・番地
     * @type {string}
     * @memberof PpUserControllerEditRequest
     */
    'street'?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof PpUserControllerEditRequest
     */
    'tel_no'?: string;
    /**
     * 姓カナ
     * @type {string}
     * @memberof PpUserControllerEditRequest
     */
    'user_family_furigana'?: string;
    /**
     * 姓
     * @type {string}
     * @memberof PpUserControllerEditRequest
     */
    'user_family_name'?: string;
    /**
     * 名カナ
     * @type {string}
     * @memberof PpUserControllerEditRequest
     */
    'user_first_furigana'?: string;
    /**
     * 名
     * @type {string}
     * @memberof PpUserControllerEditRequest
     */
    'user_first_name'?: string;
}
/**
 * 
 * @export
 * @interface PpUserControllerFrontConfirmationRequest
 */
export interface PpUserControllerFrontConfirmationRequest {
    /**
     * 認証コード
     * @type {string}
     * @memberof PpUserControllerFrontConfirmationRequest
     */
    'auth_code'?: string;
}
/**
 * 
 * @export
 * @interface PpUserControllerFrontEditRequest
 */
export interface PpUserControllerFrontEditRequest {
    /**
     * 生年月日 YYYY-MM-DD
     * @type {string}
     * @memberof PpUserControllerFrontEditRequest
     */
    'birthday'?: string;
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof PpUserControllerFrontEditRequest
     */
    'building'?: string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof PpUserControllerFrontEditRequest
     */
    'city'?: string;
    /**
     * 性別 male or female or other
     * @type {string}
     * @memberof PpUserControllerFrontEditRequest
     */
    'gender'?: string;
    /**
     * バリデーションのみ行う場合trueを指定
     * @type {boolean}
     * @memberof PpUserControllerFrontEditRequest
     */
    'is_only_validate'?: boolean;
    /**
     * 郵便番号
     * @type {string}
     * @memberof PpUserControllerFrontEditRequest
     */
    'postal_code'?: string;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof PpUserControllerFrontEditRequest
     */
    'pref_code'?: string;
    /**
     * 丁目・番地
     * @type {string}
     * @memberof PpUserControllerFrontEditRequest
     */
    'street'?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof PpUserControllerFrontEditRequest
     */
    'tel_no'?: string;
    /**
     * 姓カナ
     * @type {string}
     * @memberof PpUserControllerFrontEditRequest
     */
    'user_family_furigana'?: string;
    /**
     * 姓
     * @type {string}
     * @memberof PpUserControllerFrontEditRequest
     */
    'user_family_name'?: string;
    /**
     * 名カナ
     * @type {string}
     * @memberof PpUserControllerFrontEditRequest
     */
    'user_first_furigana'?: string;
    /**
     * 名
     * @type {string}
     * @memberof PpUserControllerFrontEditRequest
     */
    'user_first_name'?: string;
}
/**
 * 
 * @export
 * @interface PpUserControllerFrontRegisterRequest
 */
export interface PpUserControllerFrontRegisterRequest {
    /**
     * 利用規約への同意
     * @type {boolean}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'accept_terms'?: boolean;
    /**
     * 生年月日 YYYY-MM-DD
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'birthday'?: string;
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'building'?: string;
    /**
     * 市区町村
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'city'?: string;
    /**
     * 確認用メールアドレス
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'confirmation_email'?: string;
    /**
     * 確認用パスワード
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'confirmation_password'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'email'?: string;
    /**
     * 性別 1:男性 2:女性 3:その他
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'gender'?: string;
    /**
     * バリデーションのみ行う場合trueを指定
     * @type {boolean}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'is_only_validate'?: boolean;
    /**
     * パスワード（平文）
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'password'?: string;
    /**
     * 郵便番号 181-0012
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'postal_code'?: string;
    /**
     * 都道府県コード 1〜47
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'pref_code'?: string;
    /**
     * 丁目・番地
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'street'?: string;
    /**
     * 電話番号 ハイフンなし
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'tel_no'?: string;
    /**
     * 姓カナ
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'user_family_furigana'?: string;
    /**
     * 姓
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'user_family_name'?: string;
    /**
     * 名カナ
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'user_first_furigana'?: string;
    /**
     * 名
     * @type {string}
     * @memberof PpUserControllerFrontRegisterRequest
     */
    'user_first_name'?: string;
}
/**
 * 
 * @export
 * @interface PpUserControllerGetResponse
 */
export interface PpUserControllerGetResponse {
    /**
     * 
     * @type {PpUserControllerGetResponsePpUser}
     * @memberof PpUserControllerGetResponse
     */
    'pp_user': PpUserControllerGetResponsePpUser;
}
/**
 * 会員データ
 * @export
 * @interface PpUserControllerGetResponsePpUser
 */
export interface PpUserControllerGetResponsePpUser {
    /**
     * 生年月日
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'birthday': string;
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'building': string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'city': string;
    /**
     * 登録日
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'created': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'email': string;
    /**
     * 性別 male or female or other
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'gender': string;
    /**
     * 飼い主（会員）ID
     * @type {number}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'id': number;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'last_login': string | null;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'last_login_ip': string | null;
    /**
     * 郵便番号（000-1111）
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'postal_code': string;
    /**
     * 飼い主（会員）が登録しているペット情報
     * @type {Array<TypesPpPetData>}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'pp_pets'?: Array<TypesPpPetData>;
    /**
     * 都道府県
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'pref_code': string;
    /**
     * ステータス active or inactive
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'status': string;
    /**
     * 丁目・番地
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'street': string;
    /**
     * 電話番号
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'tel_no': string;
    /**
     * 姓カナ
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'user_family_furigana': string;
    /**
     * 姓
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'user_family_name': string;
    /**
     * 名カナ
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'user_first_furigana': string;
    /**
     * 名
     * @type {string}
     * @memberof PpUserControllerGetResponsePpUser
     */
    'user_first_name': string;
}
/**
 * 
 * @export
 * @interface PpUserControllerIndexRequest
 */
export interface PpUserControllerIndexRequest {
    /**
     * メールアドレス
     * @type {string}
     * @memberof PpUserControllerIndexRequest
     */
    'email'?: string;
    /**
     * 名
     * @type {string}
     * @memberof PpUserControllerIndexRequest
     */
    'first_name'?: string;
    /**
     * メイ
     * @type {string}
     * @memberof PpUserControllerIndexRequest
     */
    'first_name_kana'?: string;
    /**
     * 姓
     * @type {string}
     * @memberof PpUserControllerIndexRequest
     */
    'last_name'?: string;
    /**
     * セイ
     * @type {string}
     * @memberof PpUserControllerIndexRequest
     */
    'last_name_kana'?: string;
    /**
     * 本人認証完了のみとする場合true
     * @type {boolean}
     * @memberof PpUserControllerIndexRequest
     */
    'only_personal_auth'?: boolean;
    /**
     * ページ番号
     * @type {number}
     * @memberof PpUserControllerIndexRequest
     */
    'page'?: number;
    /**
     * ページあたりの取得件数
     * @type {number}
     * @memberof PpUserControllerIndexRequest
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface PpUserControllerIndexResponse
 */
export interface PpUserControllerIndexResponse {
    /**
     * 一覧データ
     * @type {Array<TypesPpUserData>}
     * @memberof PpUserControllerIndexResponse
     */
    'pp_users'?: Array<TypesPpUserData>;
    /**
     * 全件数
     * @type {number}
     * @memberof PpUserControllerIndexResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpflowcellStatus = {
    Status0: '0',
    Status1: '1',
    Status2: '2'
} as const;

export type PpflowcellStatus = typeof PpflowcellStatus[keyof typeof PpflowcellStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpmstbreedAnimalType = {
    AnimalType1: '1',
    AnimalType2: '2'
} as const;

export type PpmstbreedAnimalType = typeof PpmstbreedAnimalType[keyof typeof PpmstbreedAnimalType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpmstgenericrcmndgroupGenericRcmndDisplayMethod = {
    DefaultGenericRcmndDisplayMethod: '1',
    GenericRcmndDisplayMethod1: '1',
    GenericRcmndDisplayMethod2: '2'
} as const;

export type PpmstgenericrcmndgroupGenericRcmndDisplayMethod = typeof PpmstgenericrcmndgroupGenericRcmndDisplayMethod[keyof typeof PpmstgenericrcmndgroupGenericRcmndDisplayMethod];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpmstgenericrcmndimageAnimalType = {
    AnimalType1: '1',
    AnimalType2: '2'
} as const;

export type PpmstgenericrcmndimageAnimalType = typeof PpmstgenericrcmndimageAnimalType[keyof typeof PpmstgenericrcmndimageAnimalType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpmstgenericrcmndimageIntestinalEnvironmentType = {
    IntestinalEnvironmentTypeA: 'A',
    IntestinalEnvironmentTypeB: 'B',
    IntestinalEnvironmentTypeC: 'C',
    IntestinalEnvironmentTypeD: 'D',
    IntestinalEnvironmentTypeZ: 'Z'
} as const;

export type PpmstgenericrcmndimageIntestinalEnvironmentType = typeof PpmstgenericrcmndimageIntestinalEnvironmentType[keyof typeof PpmstgenericrcmndimageIntestinalEnvironmentType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpmstidentificationcdAuthorityLevel = {
    AuthorityLevel1: '1',
    AuthorityLevel2: '2',
    AuthorityLevel3: '3'
} as const;

export type PpmstidentificationcdAuthorityLevel = typeof PpmstidentificationcdAuthorityLevel[keyof typeof PpmstidentificationcdAuthorityLevel];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpmstrcmndmethodRcmndDisplayPattern = {
    RcmndDisplayPattern0: '0',
    RcmndDisplayPattern1: '1',
    RcmndDisplayPattern2: '2',
    RcmndDisplayPattern3: '3',
    RcmndDisplayPattern4: '4',
    RcmndDisplayPattern5: '5',
    RcmndDisplayPattern6: '6',
    RcmndDisplayPattern7: '7'
} as const;

export type PpmstrcmndmethodRcmndDisplayPattern = typeof PpmstrcmndmethodRcmndDisplayPattern[keyof typeof PpmstrcmndmethodRcmndDisplayPattern];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpmstrcmndproductIntestinalEnvironmentType = {
    IntestinalEnvironmentTypeA: 'A',
    IntestinalEnvironmentTypeB: 'B',
    IntestinalEnvironmentTypeC: 'C',
    IntestinalEnvironmentTypeD: 'D',
    IntestinalEnvironmentTypeZ: 'Z'
} as const;

export type PpmstrcmndproductIntestinalEnvironmentType = typeof PpmstrcmndproductIntestinalEnvironmentType[keyof typeof PpmstrcmndproductIntestinalEnvironmentType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpmstrcmndproductRecommendType = {
    RecommendType0: '0',
    RecommendType1: '1',
    RecommendType2: '2'
} as const;

export type PpmstrcmndproductRecommendType = typeof PpmstrcmndproductRecommendType[keyof typeof PpmstrcmndproductRecommendType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpmstrcmndproductstitleDisplayLocation = {
    DisplayLocation1: '1',
    DisplayLocation2: '2',
    DisplayLocation3: '3',
    DisplayLocation4: '4'
} as const;

export type PpmstrcmndproductstitleDisplayLocation = typeof PpmstrcmndproductstitleDisplayLocation[keyof typeof PpmstrcmndproductstitleDisplayLocation];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpmstskuRcmndAvailability = {
    DefaultRcmndAvailability: '0',
    RcmndAvailability0: '0',
    RcmndAvailability1: '1'
} as const;

export type PpmstskuRcmndAvailability = typeof PpmstskuRcmndAvailability[keyof typeof PpmstskuRcmndAvailability];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpmstthresholdcatRiskEvaluation = {
    RiskEvaluation1: '1',
    RiskEvaluation2: '2',
    RiskEvaluation3: '3',
    RiskEvaluation4: '4',
    RiskEvaluation5: '5'
} as const;

export type PpmstthresholdcatRiskEvaluation = typeof PpmstthresholdcatRiskEvaluation[keyof typeof PpmstthresholdcatRiskEvaluation];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpmstthresholddogRiskEvaluation = {
    RiskEvaluation1: '1',
    RiskEvaluation2: '2',
    RiskEvaluation3: '3',
    RiskEvaluation4: '4',
    RiskEvaluation5: '5'
} as const;

export type PpmstthresholddogRiskEvaluation = typeof PpmstthresholddogRiskEvaluation[keyof typeof PpmstthresholddogRiskEvaluation];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpmstuseradminAccess = {
    Access1: '1',
    Access2: '2',
    Access3: '3',
    Access4: '4'
} as const;

export type PpmstuseradminAccess = typeof PpmstuseradminAccess[keyof typeof PpmstuseradminAccess];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpmstusermedicalResultSendTarget = {
    DefaultResultSendTarget: '3',
    ResultSendTarget0: '0',
    ResultSendTarget1: '1',
    ResultSendTarget2: '2',
    ResultSendTarget3: '3'
} as const;

export type PpmstusermedicalResultSendTarget = typeof PpmstusermedicalResultSendTarget[keyof typeof PpmstusermedicalResultSendTarget];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpmstuserpartnerPartnerInstDivision = {
    PartnerInstDivision1: '1',
    PartnerInstDivision2: '2'
} as const;

export type PpmstuserpartnerPartnerInstDivision = typeof PpmstuserpartnerPartnerInstDivision[keyof typeof PpmstuserpartnerPartnerInstDivision];


/**
 * 
 * @export
 * @enum {string}
 */

export const PppetAccuracyOfPetBirthday = {
    AccuracyOfPetBirthday1: '1',
    AccuracyOfPetBirthday2: '2',
    AccuracyOfPetBirthday3: '3'
} as const;

export type PppetAccuracyOfPetBirthday = typeof PppetAccuracyOfPetBirthday[keyof typeof PppetAccuracyOfPetBirthday];


/**
 * 
 * @export
 * @enum {string}
 */

export const PppetAnimalType = {
    AnimalType1: '1',
    AnimalType2: '2'
} as const;

export type PppetAnimalType = typeof PppetAnimalType[keyof typeof PppetAnimalType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PppetPetSex = {
    PetSex1: '1',
    PetSex2: '2'
} as const;

export type PppetPetSex = typeof PppetPetSex[keyof typeof PppetPetSex];


/**
 * 
 * @export
 * @enum {string}
 */

export const PptestkitBiAccuracyOfPetBirthday = {
    BiAccuracyOfPetBirthday1: '1',
    BiAccuracyOfPetBirthday2: '2',
    BiAccuracyOfPetBirthday3: '3'
} as const;

export type PptestkitBiAccuracyOfPetBirthday = typeof PptestkitBiAccuracyOfPetBirthday[keyof typeof PptestkitBiAccuracyOfPetBirthday];


/**
 * 
 * @export
 * @enum {string}
 */

export const PptestkitBiAnimalType = {
    BiAnimalType1: '1',
    BiAnimalType2: '2'
} as const;

export type PptestkitBiAnimalType = typeof PptestkitBiAnimalType[keyof typeof PptestkitBiAnimalType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PptestkitBiPetSex = {
    BiPetSex1: '1',
    BiPetSex2: '2'
} as const;

export type PptestkitBiPetSex = typeof PptestkitBiPetSex[keyof typeof PptestkitBiPetSex];


/**
 * 
 * @export
 * @enum {string}
 */

export const PptestkitRecommendType = {
    DefaultRecommendType: '0',
    RecommendType0: '0',
    RecommendType1: '1',
    RecommendType2: '2'
} as const;

export type PptestkitRecommendType = typeof PptestkitRecommendType[keyof typeof PptestkitRecommendType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PptestkitTestDivision = {
    TestDivision1: '1',
    TestDivision2: '2'
} as const;

export type PptestkitTestDivision = typeof PptestkitTestDivision[keyof typeof PptestkitTestDivision];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpteststatusStatus = {
    Status00: '00',
    Status01: '01',
    Status02: '02',
    Status12: '12',
    Status22: '22',
    Status50: '50'
} as const;

export type PpteststatusStatus = typeof PpteststatusStatus[keyof typeof PpteststatusStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpteststatusStatusForUser = {
    StatusForUser1: '1',
    StatusForUser2: '2',
    StatusForUser3: '3'
} as const;

export type PpteststatusStatusForUser = typeof PpteststatusStatusForUser[keyof typeof PpteststatusStatusForUser];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpuserGender = {
    Gender1: '1',
    Gender2: '2',
    Gender3: '3'
} as const;

export type PpuserGender = typeof PpuserGender[keyof typeof PpuserGender];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpuserauthcodeStatus = {
    Status0: '0',
    Status1: '1',
    Status2: '2',
    Status3: '3',
    Status9: '9'
} as const;

export type PpuserauthcodeStatus = typeof PpuserauthcodeStatus[keyof typeof PpuserauthcodeStatus];


/**
 * 
 * @export
 * @interface ResponseErrorResponse
 */
export interface ResponseErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ResponseErrorResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface SephirahModulesSephirahAuthCertificate
 */
export interface SephirahModulesSephirahAuthCertificate {
    /**
     * アクセストークン セッションストレージに保存し、以後`X-Sp-Api-Token` としてリクエストヘッダーに設定する
     * @type {string}
     * @memberof SephirahModulesSephirahAuthCertificate
     */
    'apiToken': string;
    /**
     * 認証分類 1:一般ユーザー 2:病院・提携機関 3:運営(PROUMED)
     * @type {string}
     * @memberof SephirahModulesSephirahAuthCertificate
     */
    'authGroup': string;
    /**
     * 結果 0:OK、1:ログインエラー、2:sessionタイムアウトエラー、3:電子署名不正なりすまし、4:トークン不正、5:二重ログインエラー、9:システムエラー
     * @type {string}
     * @memberof SephirahModulesSephirahAuthCertificate
     */
    'result': string;
    /**
     * トークンキー セッションストレージに保存し、以後`X-Sp-Token-Key` としてリクエストヘッダーに設定する
     * @type {string}
     * @memberof SephirahModulesSephirahAuthCertificate
     */
    'tokenKey': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TypesAccessType = {
    AccessTypeGeneral: 'general',
    AccessTypeLab: 'lab',
    AccessTypeAdvisor: 'advisor'
} as const;

export type TypesAccessType = typeof TypesAccessType[keyof typeof TypesAccessType];


/**
 * 
 * @export
 * @interface TypesFrontPpFaqCategoryData
 */
export interface TypesFrontPpFaqCategoryData {
    /**
     * カテゴリ名
     * @type {string}
     * @memberof TypesFrontPpFaqCategoryData
     */
    'category_name': string;
    /**
     * FAQ
     * @type {Array<TypesFrontPpFaqData>}
     * @memberof TypesFrontPpFaqCategoryData
     */
    'faqs': Array<TypesFrontPpFaqData>;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesFrontPpFaqCategoryData
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface TypesFrontPpFaqData
 */
export interface TypesFrontPpFaqData {
    /**
     * 回答
     * @type {string}
     * @memberof TypesFrontPpFaqData
     */
    'answer': string;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesFrontPpFaqData
     */
    'id': number;
    /**
     * 質問
     * @type {string}
     * @memberof TypesFrontPpFaqData
     */
    'question': string;
}
/**
 * 
 * @export
 * @interface TypesHistoryData
 */
export interface TypesHistoryData {
    /**
     * ペット名前（検査時断面情報）
     * @type {string}
     * @memberof TypesHistoryData
     */
    'bi_pet_name': string;
    /**
     * ペット性別 1：男の子、2：女の子
     * @type {string}
     * @memberof TypesHistoryData
     */
    'bi_pet_sex': string;
    /**
     * ペット名前（最新データ）
     * @type {string}
     * @memberof TypesHistoryData
     */
    'pet_name': string;
    /**
     * ペットID
     * @type {number}
     * @memberof TypesHistoryData
     */
    'pp_pet_id': number;
    /**
     * ユーザーID
     * @type {number}
     * @memberof TypesHistoryData
     */
    'pp_user_id': number;
    /**
     * 検査依頼登録日 YYYY-MM-DD
     * @type {string}
     * @memberof TypesHistoryData
     */
    'request_ymd': string | null;
    /**
     * 検体ID
     * @type {string}
     * @memberof TypesHistoryData
     */
    'sample_id': string;
    /**
     * 状況（ユーザー用） 1：検査依頼済み、2：検査中、3：検査完了
     * @type {PpteststatusStatusForUser}
     * @memberof TypesHistoryData
     */
    'status_for_user': PpteststatusStatusForUser | null;
}
/**
 * 
 * @export
 * @interface TypesPpAnnouncementAdminData
 */
export interface TypesPpAnnouncementAdminData {
    /**
     * 本文
     * @type {string}
     * @memberof TypesPpAnnouncementAdminData
     */
    'announcement_detail': string;
    /**
     * タイトル
     * @type {string}
     * @memberof TypesPpAnnouncementAdminData
     */
    'announcement_title': string;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpAnnouncementAdminData
     */
    'created': string;
    /**
     * 表示開始 2023-04-11
     * @type {string}
     * @memberof TypesPpAnnouncementAdminData
     */
    'display_period_from': string;
    /**
     * 表示終了 2023-04-20
     * @type {string}
     * @memberof TypesPpAnnouncementAdminData
     */
    'display_period_to': string;
    /**
     * 表示日時 2023-04-11
     * @type {string}
     * @memberof TypesPpAnnouncementAdminData
     */
    'display_ymd': string;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpAnnouncementAdminData
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface TypesPpAnnouncementUserData
 */
export interface TypesPpAnnouncementUserData {
    /**
     * 本文
     * @type {string}
     * @memberof TypesPpAnnouncementUserData
     */
    'announcement_detail': string;
    /**
     * お知らせタイトル
     * @type {string}
     * @memberof TypesPpAnnouncementUserData
     */
    'announcement_title': string;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpAnnouncementUserData
     */
    'created': string;
    /**
     * 表示開始 2023-04-11
     * @type {string}
     * @memberof TypesPpAnnouncementUserData
     */
    'display_period_from': string;
    /**
     * 表示終了 2023-04-20
     * @type {string}
     * @memberof TypesPpAnnouncementUserData
     */
    'display_period_to': string;
    /**
     * 表示日時 2023-04-11
     * @type {string}
     * @memberof TypesPpAnnouncementUserData
     */
    'display_ymd': string;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpAnnouncementUserData
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface TypesPpFaqCategoryData
 */
export interface TypesPpFaqCategoryData {
    /**
     * 表示フラグ
     * @type {boolean}
     * @memberof TypesPpFaqCategoryData
     */
    'active': boolean;
    /**
     * カテゴリ名
     * @type {string}
     * @memberof TypesPpFaqCategoryData
     */
    'category_name': string;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpFaqCategoryData
     */
    'id': number;
    /**
     * 表示順
     * @type {number}
     * @memberof TypesPpFaqCategoryData
     */
    'sort_order': number;
}
/**
 * 
 * @export
 * @interface TypesPpFaqData
 */
export interface TypesPpFaqData {
    /**
     * 表示フラグ
     * @type {boolean}
     * @memberof TypesPpFaqData
     */
    'active': boolean;
    /**
     * 回答
     * @type {string}
     * @memberof TypesPpFaqData
     */
    'answer': string;
    /**
     * カテゴリー名称
     * @type {string}
     * @memberof TypesPpFaqData
     */
    'category_name': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof TypesPpFaqData
     */
    'created': string;
    /**
     * カテゴリーID
     * @type {number}
     * @memberof TypesPpFaqData
     */
    'faq_category_id': number;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpFaqData
     */
    'id': number;
    /**
     * 質問
     * @type {string}
     * @memberof TypesPpFaqData
     */
    'question': string;
    /**
     * 表示順
     * @type {number}
     * @memberof TypesPpFaqData
     */
    'sort_order': number;
}
/**
 * 
 * @export
 * @interface TypesPpFlowcellData
 */
export interface TypesPpFlowcellData {
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof TypesPpFlowcellData
     */
    'flowcell_id': string;
    /**
     * ステータス 0:未使用、1:検査中、2:検査完了
     * @type {PpflowcellStatus}
     * @memberof TypesPpFlowcellData
     */
    'status': PpflowcellStatus;
}
/**
 * 
 * @export
 * @interface TypesPpMaintenanceData
 */
export interface TypesPpMaintenanceData {
    /**
     * 開始時刻 HH:MM:SS
     * @type {string}
     * @memberof TypesPpMaintenanceData
     */
    'mainte_from_hms': string | null;
    /**
     * 終了時刻 HH:MM:SS
     * @type {string}
     * @memberof TypesPpMaintenanceData
     */
    'mainte_to_hms': string | null;
    /**
     * メンテナンス日付 YYYY-MM-DD
     * @type {string}
     * @memberof TypesPpMaintenanceData
     */
    'mainte_ymd': string | null;
}
/**
 * 
 * @export
 * @interface TypesPpMstBreedData
 */
export interface TypesPpMstBreedData {
    /**
     * ペットの種類
     * @type {string}
     * @memberof TypesPpMstBreedData
     */
    'animal_type': string;
    /**
     * 品種名
     * @type {string}
     * @memberof TypesPpMstBreedData
     */
    'breed_name': string;
    /**
     * コード番号
     * @type {number}
     * @memberof TypesPpMstBreedData
     */
    'code_id': number;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstBreedData
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface TypesPpMstGenericRcmndGroupData
 */
export interface TypesPpMstGenericRcmndGroupData {
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstGenericRcmndGroupData
     */
    'created': string;
    /**
     * 汎用リコメンド表示方式 1：個別に表示、2：グループ単位にまとめて表示
     * @type {PpmstgenericrcmndgroupGenericRcmndDisplayMethod}
     * @memberof TypesPpMstGenericRcmndGroupData
     */
    'generic_rcmnd_display_method': PpmstgenericrcmndgroupGenericRcmndDisplayMethod;
    /**
     * 汎用リコメンドグループタイトル
     * @type {string}
     * @memberof TypesPpMstGenericRcmndGroupData
     */
    'generic_rcmnd_group_title': string;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstGenericRcmndGroupData
     */
    'id': number;
    /**
     * 更新日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstGenericRcmndGroupData
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface TypesPpMstGenericRcmndImageData
 */
export interface TypesPpMstGenericRcmndImageData {
    /**
     * 動物種類 1：犬、2：猫
     * @type {PpmstgenericrcmndimageAnimalType}
     * @memberof TypesPpMstGenericRcmndImageData
     */
    'animal_type': PpmstgenericrcmndimageAnimalType;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstGenericRcmndImageData
     */
    'created': string;
    /**
     * 表示順位
     * @type {number}
     * @memberof TypesPpMstGenericRcmndImageData
     */
    'display_order': number;
    /**
     * 汎用リコメンドグループID
     * @type {number}
     * @memberof TypesPpMstGenericRcmndImageData
     */
    'generic_rcmnd_group_id': number;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstGenericRcmndImageData
     */
    'id': number;
    /**
     * 画像ファイル保存先パス ファイル名
     * @type {string}
     * @memberof TypesPpMstGenericRcmndImageData
     */
    'image_name': string;
    /**
     * イメージ概要
     * @type {string}
     * @memberof TypesPpMstGenericRcmndImageData
     */
    'image_overview': string;
    /**
     * 画像ファイル保存先パス
     * @type {string}
     * @memberof TypesPpMstGenericRcmndImageData
     */
    'image_path': string;
    /**
     * 画像ファイル保存先パス 閲覧URL
     * @type {string}
     * @memberof TypesPpMstGenericRcmndImageData
     */
    'image_url': string;
    /**
     * 腸内環境タイプ A：現状維持型、B：多様性補強型、C：善玉菌補強型、D：多様性／善玉菌補強型、Z：腸内環境タイプ不問
     * @type {PpmstgenericrcmndimageIntestinalEnvironmentType}
     * @memberof TypesPpMstGenericRcmndImageData
     */
    'intestinal_environment_type': PpmstgenericrcmndimageIntestinalEnvironmentType;
    /**
     * 
     * @type {PpMstGenericRcmndGroupControllerGetResponsePpMstGenericRcmndGroup}
     * @memberof TypesPpMstGenericRcmndImageData
     */
    'pp_mst_generic_rcmnd_group': PpMstGenericRcmndGroupControllerGetResponsePpMstGenericRcmndGroup;
    /**
     * 更新日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstGenericRcmndImageData
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface TypesPpMstIdentificationCdData
 */
export interface TypesPpMstIdentificationCdData {
    /**
     * 権限レベル
     * @type {PpmstidentificationcdAuthorityLevel}
     * @memberof TypesPpMstIdentificationCdData
     */
    'authority_level': PpmstidentificationcdAuthorityLevel;
    /**
     * 識別コード_番号
     * @type {string}
     * @memberof TypesPpMstIdentificationCdData
     */
    'code_number': string;
    /**
     * 登録日
     * @type {string}
     * @memberof TypesPpMstIdentificationCdData
     */
    'created': string;
    /**
     * 説明
     * @type {string}
     * @memberof TypesPpMstIdentificationCdData
     */
    'description': string | null;
    /**
     * 識別コード_グルーピングコード
     * @type {string}
     * @memberof TypesPpMstIdentificationCdData
     */
    'grouping_code': string;
    /**
     * ID
     * @type {number}
     * @memberof TypesPpMstIdentificationCdData
     */
    'id': number;
    /**
     * 識別コード
     * @type {string}
     * @memberof TypesPpMstIdentificationCdData
     */
    'identification_cd': string;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof TypesPpMstIdentificationCdData
     */
    'partner_inst_id': number;
    /**
     * 提携法人名
     * @type {string}
     * @memberof TypesPpMstIdentificationCdData
     */
    'partner_inst_name': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof TypesPpMstIdentificationCdData
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface TypesPpMstMeatureCommentData
 */
export interface TypesPpMstMeatureCommentData {
    /**
     * 有害菌情報画面吹き出しコメント
     * @type {string}
     * @memberof TypesPpMstMeatureCommentData
     */
    'comment_001': string;
    /**
     * 詳細_見出し1
     * @type {string}
     * @memberof TypesPpMstMeatureCommentData
     */
    'comment_100': string;
    /**
     * 詳細_テキスト1-1
     * @type {string}
     * @memberof TypesPpMstMeatureCommentData
     */
    'comment_101': string;
    /**
     * 詳細_見出し2
     * @type {string}
     * @memberof TypesPpMstMeatureCommentData
     */
    'comment_200': string;
    /**
     * 詳細_小見出し2-1
     * @type {string}
     * @memberof TypesPpMstMeatureCommentData
     */
    'comment_210': string;
    /**
     * 詳細_テキスト2-1-1
     * @type {string}
     * @memberof TypesPpMstMeatureCommentData
     */
    'comment_211': string;
    /**
     * 詳細_小見出し2-2
     * @type {string}
     * @memberof TypesPpMstMeatureCommentData
     */
    'comment_220': string;
    /**
     * 詳細_テキスト2-2-1
     * @type {string}
     * @memberof TypesPpMstMeatureCommentData
     */
    'comment_221': string;
    /**
     * 詳細_見出し3
     * @type {string}
     * @memberof TypesPpMstMeatureCommentData
     */
    'comment_300': string;
    /**
     * 詳細_小見出し3-1
     * @type {string}
     * @memberof TypesPpMstMeatureCommentData
     */
    'comment_310': string;
    /**
     * 詳細_テキスト3-1-1
     * @type {string}
     * @memberof TypesPpMstMeatureCommentData
     */
    'comment_311': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof TypesPpMstMeatureCommentData
     */
    'created': string;
    /**
     * 対象カテゴリ
     * @type {string}
     * @memberof TypesPpMstMeatureCommentData
     */
    'measure_category': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof TypesPpMstMeatureCommentData
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface TypesPpMstMeatureCommentSingleData
 */
export interface TypesPpMstMeatureCommentSingleData {
    /**
     * 作成日時
     * @type {string}
     * @memberof TypesPpMstMeatureCommentSingleData
     */
    'created': string;
    /**
     * ID
     * @type {number}
     * @memberof TypesPpMstMeatureCommentSingleData
     */
    'id': number;
    /**
     * 対象カテゴリ
     * @type {string}
     * @memberof TypesPpMstMeatureCommentSingleData
     */
    'measure_category': string;
    /**
     * 対策コメント表示位置コード
     * @type {string}
     * @memberof TypesPpMstMeatureCommentSingleData
     */
    'measure_comment_position_id': string;
    /**
     * 対策コメント_内容
     * @type {string}
     * @memberof TypesPpMstMeatureCommentSingleData
     */
    'measure_comment_text': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof TypesPpMstMeatureCommentSingleData
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface TypesPpMstPartnerInstData
 */
export interface TypesPpMstPartnerInstData {
    /**
     * ビル名
     * @type {string}
     * @memberof TypesPpMstPartnerInstData
     */
    'building': string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof TypesPpMstPartnerInstData
     */
    'city': string | null;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstPartnerInstData
     */
    'created': string;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstPartnerInstData
     */
    'id': number;
    /**
     * 提携法人名
     * @type {string}
     * @memberof TypesPpMstPartnerInstData
     */
    'partner_inst_name': string;
    /**
     * 
     * @type {TypesPpMstPartnerInstDataPartnerSupplierType}
     * @memberof TypesPpMstPartnerInstData
     */
    'partner_supplier_type': TypesPpMstPartnerInstDataPartnerSupplierType;
    /**
     * 営業取引種別ID
     * @type {number}
     * @memberof TypesPpMstPartnerInstData
     */
    'partner_supplier_type_id': number;
    /**
     * 郵便番号
     * @type {string}
     * @memberof TypesPpMstPartnerInstData
     */
    'postal_code': string | null;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof TypesPpMstPartnerInstData
     */
    'pref_code': string | null;
    /**
     * 番地
     * @type {string}
     * @memberof TypesPpMstPartnerInstData
     */
    'street': string | null;
    /**
     * TEL
     * @type {string}
     * @memberof TypesPpMstPartnerInstData
     */
    'tel_no': string | null;
}
/**
 * 営業取引種別
 * @export
 * @interface TypesPpMstPartnerInstDataPartnerSupplierType
 */
export interface TypesPpMstPartnerInstDataPartnerSupplierType {
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstPartnerInstDataPartnerSupplierType
     */
    'id': number;
    /**
     * 営業取引種別名
     * @type {string}
     * @memberof TypesPpMstPartnerInstDataPartnerSupplierType
     */
    'partner_supplier_name': string;
}
/**
 * 
 * @export
 * @interface TypesPpMstPartnerSupplierTypeData
 */
export interface TypesPpMstPartnerSupplierTypeData {
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstPartnerSupplierTypeData
     */
    'id': number;
    /**
     * 営業取引種別名
     * @type {string}
     * @memberof TypesPpMstPartnerSupplierTypeData
     */
    'partner_supplier_name': string;
}
/**
 * 
 * @export
 * @interface TypesPpMstRcmndMethodData
 */
export interface TypesPpMstRcmndMethodData {
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstRcmndMethodData
     */
    'created': string;
    /**
     * 汎用リコメンドタイトル
     * @type {string}
     * @memberof TypesPpMstRcmndMethodData
     */
    'generic_rcmnd_title': string | null;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstRcmndMethodData
     */
    'id': number;
    /**
     * 
     * @type {TypesPpMstRcmndMethodDataMedicInst}
     * @memberof TypesPpMstRcmndMethodData
     */
    'medic_inst'?: TypesPpMstRcmndMethodDataMedicInst | null;
    /**
     * 病院ID
     * @type {number}
     * @memberof TypesPpMstRcmndMethodData
     */
    'medic_inst_id': number;
    /**
     * 
     * @type {TypesPpMstRcmndMethodDataPartnerInst}
     * @memberof TypesPpMstRcmndMethodData
     */
    'partner_inst'?: TypesPpMstRcmndMethodDataPartnerInst;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof TypesPpMstRcmndMethodData
     */
    'partner_inst_id': number | null;
    /**
     * リコメンド表示パターン 0：表示なし、1：汎用のみ、2：個別おすすめ商品のみ(横表示)、3：個別おすすめ商品のみ(縦表示)、4：汎用＋個別おすすめ商品(横表示)、5：汎用＋個別おすすめ商品(縦表示)、6：個別おすすめ商品のみ(縦表示＋横表示)、7：フル表示
     * @type {PpmstrcmndmethodRcmndDisplayPattern}
     * @memberof TypesPpMstRcmndMethodData
     */
    'rcmnd_display_pattern': PpmstrcmndmethodRcmndDisplayPattern;
    /**
     * リコメンド方式ラベル
     * @type {string}
     * @memberof TypesPpMstRcmndMethodData
     */
    'rcmnd_method_label': string | null;
    /**
     * SKU
     * @type {string}
     * @memberof TypesPpMstRcmndMethodData
     */
    'sku_code': string;
    /**
     * 更新日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstRcmndMethodData
     */
    'updated': string;
}
/**
 * 病院データ
 * @export
 * @interface TypesPpMstRcmndMethodDataMedicInst
 */
export interface TypesPpMstRcmndMethodDataMedicInst {
    /**
     * ビル名
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataMedicInst
     */
    'building': string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataMedicInst
     */
    'city': string;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataMedicInst
     */
    'created': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataMedicInst
     */
    'email': string;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstRcmndMethodDataMedicInst
     */
    'id': number;
    /**
     * 病院名
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataMedicInst
     */
    'medic_inst_name': string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataMedicInst
     */
    'postal_code': string;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataMedicInst
     */
    'pref_code': string;
    /**
     * 検査結果メール送信対象 0: 対象外、1: 病院のみ通知、2: ユーザーのみ通知、3: 両方通知
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataMedicInst
     */
    'result_send_target': string;
    /**
     * 番地
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataMedicInst
     */
    'street': string;
    /**
     * TEL
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataMedicInst
     */
    'tel_no': string;
}
/**
 * 提携法人データ
 * @export
 * @interface TypesPpMstRcmndMethodDataPartnerInst
 */
export interface TypesPpMstRcmndMethodDataPartnerInst {
    /**
     * ビル名
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataPartnerInst
     */
    'building': string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataPartnerInst
     */
    'city': string | null;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataPartnerInst
     */
    'created': string;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstRcmndMethodDataPartnerInst
     */
    'id': number;
    /**
     * 提携法人名
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataPartnerInst
     */
    'partner_inst_name': string;
    /**
     * 
     * @type {TypesPpMstPartnerInstDataPartnerSupplierType}
     * @memberof TypesPpMstRcmndMethodDataPartnerInst
     */
    'partner_supplier_type': TypesPpMstPartnerInstDataPartnerSupplierType;
    /**
     * 営業取引種別ID
     * @type {number}
     * @memberof TypesPpMstRcmndMethodDataPartnerInst
     */
    'partner_supplier_type_id': number;
    /**
     * 郵便番号
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataPartnerInst
     */
    'postal_code': string | null;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataPartnerInst
     */
    'pref_code': string | null;
    /**
     * 番地
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataPartnerInst
     */
    'street': string | null;
    /**
     * TEL
     * @type {string}
     * @memberof TypesPpMstRcmndMethodDataPartnerInst
     */
    'tel_no': string | null;
}
/**
 * 
 * @export
 * @interface TypesPpMstRcmndProductData
 */
export interface TypesPpMstRcmndProductData {
    /**
     * 商品リンク1「フード付き検査キット」ボタンに対応するリンク先
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'ecsite_link_1': string | null;
    /**
     * 商品リンク2「フードのみ」ボタンに対応するリンク先
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'ecsite_link_2': string | null;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstRcmndProductData
     */
    'id': number;
    /**
     * 腸内環境タイプ A：現状維持型、B：多様性補強型、C：善玉菌補強型、D：多様性／善玉菌補強型、Z：腸内環境タイプ不
     * @type {PpmstrcmndproductIntestinalEnvironmentType}
     * @memberof TypesPpMstRcmndProductData
     */
    'intestinal_environment_type': PpmstrcmndproductIntestinalEnvironmentType;
    /**
     * 
     * @type {TypesPpMstRcmndProductDataPpMstRcmndProductsTitle}
     * @memberof TypesPpMstRcmndProductData
     */
    'pp_mst_rcmnd_products_title'?: TypesPpMstRcmndProductDataPpMstRcmndProductsTitle;
    /**
     * 優先順位
     * @type {number}
     * @memberof TypesPpMstRcmndProductData
     */
    'priority': number;
    /**
     * 商品コード
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_code': string;
    /**
     * 商品説明
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_description': string;
    /**
     * 商品画像1
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_01': string;
    /**
     * 商品画像1 ファイル名
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_01_name': string;
    /**
     * 商品画像1 閲覧URL
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_01_url': string;
    /**
     * 商品画像2
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_02': string;
    /**
     * 商品画像2 ファイル名
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_02_name': string;
    /**
     * 商品画像2 閲覧URL
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_02_url': string;
    /**
     * 商品画像3
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_03': string;
    /**
     * 商品画像3 ファイル名
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_03_name': string;
    /**
     * 商品画像3 閲覧URL
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_03_url': string;
    /**
     * 商品画像4
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_04': string;
    /**
     * 商品画像4 ファイル名
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_04_name': string;
    /**
     * 商品画像4 閲覧URL
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_04_url': string;
    /**
     * 商品画像5
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_05': string;
    /**
     * 商品画像5 ファイル名
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_05_name': string;
    /**
     * 商品画像5 閲覧URL
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_05_url': string;
    /**
     * 商品画像6
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_06': string;
    /**
     * 商品画像6 ファイル名
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_06_name': string;
    /**
     * 商品画像6 閲覧URL
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_06_url': string;
    /**
     * 商品画像7
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_07': string;
    /**
     * 商品画像7 ファイル名
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_07_name': string;
    /**
     * 商品画像7 閲覧URL
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_07_url': string;
    /**
     * 商品画像8
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_08': string;
    /**
     * 商品画像8 ファイル名
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_08_name': string;
    /**
     * 商品画像8 閲覧URL
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_08_url': string;
    /**
     * 商品画像9
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_09': string;
    /**
     * 商品画像9 ファイル名
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_09_name': string;
    /**
     * 商品画像9 閲覧URL
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_09_url': string;
    /**
     * 商品画像10
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_10': string;
    /**
     * 商品画像10 ファイル名
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_10_name': string;
    /**
     * 商品画像10 閲覧URL
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_image_10_url': string;
    /**
     * 商品名
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_name': string;
    /**
     * 商品区分
     * @type {string}
     * @memberof TypesPpMstRcmndProductData
     */
    'product_type': string;
    /**
     * リコメンド商品タイトルID
     * @type {number}
     * @memberof TypesPpMstRcmndProductData
     */
    'rcmnd_products_tittle_id': number;
    /**
     * リコメンド要否区分 0：商品おすすめなし、1：腸内環境タイプ別商品おすすめ、2：腸内環境タイプ共通商品おすすめ
     * @type {PpmstrcmndproductRecommendType}
     * @memberof TypesPpMstRcmndProductData
     */
    'recommend_type': PpmstrcmndproductRecommendType;
}
/**
 * リコメンド商品タイトル
 * @export
 * @interface TypesPpMstRcmndProductDataPpMstRcmndProductsTitle
 */
export interface TypesPpMstRcmndProductDataPpMstRcmndProductsTitle {
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstRcmndProductDataPpMstRcmndProductsTitle
     */
    'created': string;
    /**
     * リコメンドタイトル表示箇所 1：おすすめ商品_縦、2：おすすめ商品_横1段目、3：おすすめ商品_横2段目、4：おすすめ商品_横3段目
     * @type {PpmstrcmndproductstitleDisplayLocation}
     * @memberof TypesPpMstRcmndProductDataPpMstRcmndProductsTitle
     */
    'display_location': PpmstrcmndproductstitleDisplayLocation;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstRcmndProductDataPpMstRcmndProductsTitle
     */
    'id': number;
    /**
     * 
     * @type {PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod}
     * @memberof TypesPpMstRcmndProductDataPpMstRcmndProductsTitle
     */
    'pp_mst_rcmnd_method': PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod;
    /**
     * リコメンド方式ID
     * @type {number}
     * @memberof TypesPpMstRcmndProductDataPpMstRcmndProductsTitle
     */
    'rcmnd_method_id': number;
    /**
     * リコメンドタイトル
     * @type {string}
     * @memberof TypesPpMstRcmndProductDataPpMstRcmndProductsTitle
     */
    'recommend_title': string;
    /**
     * 更新日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstRcmndProductDataPpMstRcmndProductsTitle
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface TypesPpMstRcmndProductsTitleData
 */
export interface TypesPpMstRcmndProductsTitleData {
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstRcmndProductsTitleData
     */
    'created': string;
    /**
     * リコメンドタイトル表示箇所 1：おすすめ商品_縦、2：おすすめ商品_横1段目、3：おすすめ商品_横2段目、4：おすすめ商品_横3段目
     * @type {PpmstrcmndproductstitleDisplayLocation}
     * @memberof TypesPpMstRcmndProductsTitleData
     */
    'display_location': PpmstrcmndproductstitleDisplayLocation;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstRcmndProductsTitleData
     */
    'id': number;
    /**
     * 
     * @type {PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod}
     * @memberof TypesPpMstRcmndProductsTitleData
     */
    'pp_mst_rcmnd_method': PpMstRcmndMethodControllerGetResponsePpMstRcmndMethod;
    /**
     * リコメンド方式ID
     * @type {number}
     * @memberof TypesPpMstRcmndProductsTitleData
     */
    'rcmnd_method_id': number;
    /**
     * リコメンドタイトル
     * @type {string}
     * @memberof TypesPpMstRcmndProductsTitleData
     */
    'recommend_title': string;
    /**
     * 更新日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstRcmndProductsTitleData
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface TypesPpMstSkuData
 */
export interface TypesPpMstSkuData {
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstSkuData
     */
    'created': string;
    /**
     * 説明
     * @type {string}
     * @memberof TypesPpMstSkuData
     */
    'description': string;
    /**
     * 商品付帯有無
     * @type {boolean}
     * @memberof TypesPpMstSkuData
     */
    'food_included': boolean;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstSkuData
     */
    'id': number;
    /**
     * 商品リコメンドオプション有無 0: なし、1: あり
     * @type {PpmstskuRcmndAvailability}
     * @memberof TypesPpMstSkuData
     */
    'rcmnd_availability': PpmstskuRcmndAvailability;
    /**
     * SKU
     * @type {string}
     * @memberof TypesPpMstSkuData
     */
    'sku_code': string;
    /**
     * 更新日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstSkuData
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface TypesPpMstTestKitData
 */
export interface TypesPpMstTestKitData {
    /**
     * 登録日時
     * @type {string}
     * @memberof TypesPpMstTestKitData
     */
    'created': string;
    /**
     * パスワード
     * @type {string}
     * @memberof TypesPpMstTestKitData
     */
    'password': string;
    /**
     * 検体ID
     * @type {string}
     * @memberof TypesPpMstTestKitData
     */
    'sample_id': string;
    /**
     * 区分 1->犬 2->猫
     * @type {string}
     * @memberof TypesPpMstTestKitData
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface TypesPpMstUserAdminData
 */
export interface TypesPpMstUserAdminData {
    /**
     * 権限
     * @type {string}
     * @memberof TypesPpMstUserAdminData
     */
    'access': string;
    /**
     * 所属
     * @type {string}
     * @memberof TypesPpMstUserAdminData
     */
    'belongs': string | null;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstUserAdminData
     */
    'created': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof TypesPpMstUserAdminData
     */
    'email': string;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstUserAdminData
     */
    'id': number;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof TypesPpMstUserAdminData
     */
    'last_login': string | null;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof TypesPpMstUserAdminData
     */
    'last_login_ip': string | null;
    /**
     * 名前
     * @type {string}
     * @memberof TypesPpMstUserAdminData
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TypesPpMstUserMedicalData
 */
export interface TypesPpMstUserMedicalData {
    /**
     * ビル名
     * @type {string}
     * @memberof TypesPpMstUserMedicalData
     */
    'building': string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof TypesPpMstUserMedicalData
     */
    'city': string;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstUserMedicalData
     */
    'created': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof TypesPpMstUserMedicalData
     */
    'email': string;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstUserMedicalData
     */
    'id': number;
    /**
     * 病院名
     * @type {string}
     * @memberof TypesPpMstUserMedicalData
     */
    'medic_inst_name': string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof TypesPpMstUserMedicalData
     */
    'postal_code': string;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof TypesPpMstUserMedicalData
     */
    'pref_code': string;
    /**
     * 検査結果メール送信対象 0: 対象外、1: 病院のみ通知、2: ユーザーのみ通知、3: 両方通知
     * @type {string}
     * @memberof TypesPpMstUserMedicalData
     */
    'result_send_target': string;
    /**
     * 番地
     * @type {string}
     * @memberof TypesPpMstUserMedicalData
     */
    'street': string;
    /**
     * TEL
     * @type {string}
     * @memberof TypesPpMstUserMedicalData
     */
    'tel_no': string;
}
/**
 * 
 * @export
 * @interface TypesPpMstUserPartnerData
 */
export interface TypesPpMstUserPartnerData {
    /**
     * 提携法人ユーザーのアクセス権限 1：一般提携法人ユーザー、2：研究機関ユーザー、3：顧問研究者ユーザー
     * @type {number}
     * @memberof TypesPpMstUserPartnerData
     */
    'access': number;
    /**
     * 提携法人ユーザーのアクセス権限の文字列表現 general, lab, advisor
     * @type {TypesAccessType}
     * @memberof TypesPpMstUserPartnerData
     */
    'access_type': TypesAccessType | null;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstUserPartnerData
     */
    'created': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof TypesPpMstUserPartnerData
     */
    'email': string;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstUserPartnerData
     */
    'id': number;
    /**
     * 識別コード
     * @type {string}
     * @memberof TypesPpMstUserPartnerData
     */
    'identification_cd': string | null;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof TypesPpMstUserPartnerData
     */
    'last_login': string | null;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof TypesPpMstUserPartnerData
     */
    'last_login_ip': string | null;
    /**
     * 名前
     * @type {string}
     * @memberof TypesPpMstUserPartnerData
     */
    'name': string;
    /**
     * 提携機関区分 1:病院 2:提携法人
     * @type {PpmstuserpartnerPartnerInstDivision}
     * @memberof TypesPpMstUserPartnerData
     */
    'partner_inst_division': PpmstuserpartnerPartnerInstDivision;
    /**
     * 所属機関ID
     * @type {number}
     * @memberof TypesPpMstUserPartnerData
     */
    'partner_inst_id': number;
    /**
     * 所属機関名
     * @type {string}
     * @memberof TypesPpMstUserPartnerData
     */
    'partner_inst_name': string;
    /**
     * 
     * @type {TypesPpMstUserPartnerDataPpMstPartnerInst}
     * @memberof TypesPpMstUserPartnerData
     */
    'pp_mst_partner_inst': TypesPpMstUserPartnerDataPpMstPartnerInst | null;
    /**
     * 
     * @type {TypesPpMstRcmndMethodDataMedicInst}
     * @memberof TypesPpMstUserPartnerData
     */
    'pp_mst_user_medical': TypesPpMstRcmndMethodDataMedicInst | null;
}
/**
 * 提携法人データ
 * @export
 * @interface TypesPpMstUserPartnerDataPpMstPartnerInst
 */
export interface TypesPpMstUserPartnerDataPpMstPartnerInst {
    /**
     * ビル名
     * @type {string}
     * @memberof TypesPpMstUserPartnerDataPpMstPartnerInst
     */
    'building': string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof TypesPpMstUserPartnerDataPpMstPartnerInst
     */
    'city': string | null;
    /**
     * 作成日時 2023-04-11 00:01:03
     * @type {string}
     * @memberof TypesPpMstUserPartnerDataPpMstPartnerInst
     */
    'created': string;
    /**
     * 主キー
     * @type {number}
     * @memberof TypesPpMstUserPartnerDataPpMstPartnerInst
     */
    'id': number;
    /**
     * 提携法人名
     * @type {string}
     * @memberof TypesPpMstUserPartnerDataPpMstPartnerInst
     */
    'partner_inst_name': string;
    /**
     * 
     * @type {TypesPpMstPartnerInstDataPartnerSupplierType}
     * @memberof TypesPpMstUserPartnerDataPpMstPartnerInst
     */
    'partner_supplier_type': TypesPpMstPartnerInstDataPartnerSupplierType;
    /**
     * 営業取引種別ID
     * @type {number}
     * @memberof TypesPpMstUserPartnerDataPpMstPartnerInst
     */
    'partner_supplier_type_id': number;
    /**
     * 郵便番号
     * @type {string}
     * @memberof TypesPpMstUserPartnerDataPpMstPartnerInst
     */
    'postal_code': string | null;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof TypesPpMstUserPartnerDataPpMstPartnerInst
     */
    'pref_code': string | null;
    /**
     * 番地
     * @type {string}
     * @memberof TypesPpMstUserPartnerDataPpMstPartnerInst
     */
    'street': string | null;
    /**
     * TEL
     * @type {string}
     * @memberof TypesPpMstUserPartnerDataPpMstPartnerInst
     */
    'tel_no': string | null;
}
/**
 * 
 * @export
 * @interface TypesPpPetData
 */
export interface TypesPpPetData {
    /**
     * 生年月日種別 1：確定、2：推定、3：不明
     * @type {string}
     * @memberof TypesPpPetData
     */
    'accuracy_of_pet_birthday': string;
    /**
     * 種類 1：dog 2：cat
     * @type {string}
     * @memberof TypesPpPetData
     */
    'animal_type': string;
    /**
     * 生年月日
     * @type {string}
     * @memberof TypesPpPetData
     */
    'birthday': string | null;
    /**
     * 品種ID
     * @type {number}
     * @memberof TypesPpPetData
     */
    'breed_id': number;
    /**
     * 品種名
     * @type {string}
     * @memberof TypesPpPetData
     */
    'breed_name': string;
    /**
     * 両親の品種名1
     * @type {string}
     * @memberof TypesPpPetData
     */
    'breed_name_parent_1': string | null;
    /**
     * 両親の品種名2
     * @type {string}
     * @memberof TypesPpPetData
     */
    'breed_name_parent_2': string | null;
    /**
     * 両親の品種ID1
     * @type {number}
     * @memberof TypesPpPetData
     */
    'breed_of_parents_1': number | null;
    /**
     * 両親の品種ID2
     * @type {number}
     * @memberof TypesPpPetData
     */
    'breed_of_parents_2': number | null;
    /**
     * 登録日
     * @type {string}
     * @memberof TypesPpPetData
     */
    'created': string;
    /**
     * アイコン画像（保存されているディレクトリ名を含む）
     * @type {string}
     * @memberof TypesPpPetData
     */
    'icon': string | null;
    /**
     * アイコン画像（ディレクトリを省いてbasenameとした画像名）
     * @type {string}
     * @memberof TypesPpPetData
     */
    'icon_name': string | null;
    /**
     * ペットID
     * @type {number}
     * @memberof TypesPpPetData
     */
    'id': number;
    /**
     * 閲覧用アイコン画像URL
     * @type {string}
     * @memberof TypesPpPetData
     */
    'img_url': string | null;
    /**
     * カルテID
     * @type {string}
     * @memberof TypesPpPetData
     */
    'karte_id': string | null;
    /**
     * ペットの名前
     * @type {string}
     * @memberof TypesPpPetData
     */
    'pet_name': string;
    /**
     * ペットの名前フリガナ
     * @type {string}
     * @memberof TypesPpPetData
     */
    'pet_name_furigana': string;
    /**
     * ペットの名前ローマ字
     * @type {string}
     * @memberof TypesPpPetData
     */
    'pet_name_romaji': string;
    /**
     * 性別 1：男の子、2：女の子
     * @type {string}
     * @memberof TypesPpPetData
     */
    'pet_sex': string;
    /**
     * 会員ID(飼い主ID)
     * @type {number}
     * @memberof TypesPpPetData
     */
    'pp_user_id': number;
}
/**
 * 
 * @export
 * @interface TypesPpTestKitData
 */
export interface TypesPpTestKitData {
    /**
     * 生年月日確度 1：確定 2：推定 3：不明
     * @type {PptestkitBiAccuracyOfPetBirthday}
     * @memberof TypesPpTestKitData
     */
    'bi_accuracy_of_pet_birthday': PptestkitBiAccuracyOfPetBirthday | null;
    /**
     * 動物種類 1：犬 2：猫
     * @type {PptestkitBiAnimalType}
     * @memberof TypesPpTestKitData
     */
    'bi_animal_type': PptestkitBiAnimalType | null;
    /**
     * 品種
     * @type {number}
     * @memberof TypesPpTestKitData
     */
    'bi_breed': number | null;
    /**
     * 品種名
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'bi_breed_name': string | null;
    /**
     * 両親の品種名1
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'bi_breed_name_parent_1': string | null;
    /**
     * 両親の品種名2
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'bi_breed_name_parent_2': string | null;
    /**
     * 両親の品種1
     * @type {number}
     * @memberof TypesPpTestKitData
     */
    'bi_breed_of_parents_1': number | null;
    /**
     * 両親の品種2
     * @type {number}
     * @memberof TypesPpTestKitData
     */
    'bi_breed_of_parents_2': number | null;
    /**
     * ペットアイコン
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'bi_icon': string | null;
    /**
     * アイコン画像（ディレクトリを省いてbasenameとした画像名）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'bi_icon_name': string | null;
    /**
     * 閲覧用アイコン画像URL
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'bi_icon_url': string | null;
    /**
     * ペット年齢 ○歳○ヶ月
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'bi_pet_age': string | null;
    /**
     * ペット生年月日
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'bi_pet_birthday': string | null;
    /**
     * ペット名前
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'bi_pet_name': string | null;
    /**
     * ペット名前フリガナ
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'bi_pet_name_furigana': string | null;
    /**
     * ペット名前ローマ字
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'bi_pet_name_romaji': string | null;
    /**
     * ペット性別 1：オス 2：メス
     * @type {PptestkitBiPetSex}
     * @memberof TypesPpTestKitData
     */
    'bi_pet_sex': PptestkitBiPetSex | null;
    /**
     * 検査完了日時 YYYY-MM-DD HH:MM:SS
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'completion_date_time': string | null;
    /**
     * 現在検査回数
     * @type {number}
     * @memberof TypesPpTestKitData
     */
    'current_number_of_test': number | null;
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'flowcell_id': string | null;
    /**
     * 強制検査フラグ
     * @type {boolean}
     * @memberof TypesPpTestKitData
     */
    'forced_test': boolean | null;
    /**
     * 識別コード
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'identification_cd': string | null;
    /**
     * 攻撃性(家族に対して) 1：よく攻撃する、2：時々攻撃する、3：ほとんど攻撃しない
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_aggression_for_family': string | null;
    /**
     * 攻撃性(見知らぬ人に対して) 1：よく攻撃する、2：時々攻撃する、3：ほとんど攻撃しない
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_aggression_for_stranger': string | null;
    /**
     * アレルギー（その他内容）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_allergy_comment': string | null;
    /**
     * アレルギー：食物（消化器） 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_allergy_food_digestive_organ': string;
    /**
     * アレルギー：食物（皮膚） 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_allergy_food_skin': string;
    /**
     * アレルギー：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_allergy_other': string;
    /**
     * 食欲 1：完食する、2：完食するが食いつきが悪い、3：食べ残す、4：安定しない
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_appetite': string | null;
    /**
     * 体型 1：痩せている、2：やや痩せている、3：標準、4：やや肥満、5：肥満
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_body_figure': string | null;
    /**
     * 脳・脊髄疾患：脳疾患（原因不明） 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_brain_spinal_disease_brain_disease': string;
    /**
     * 脳・脊髄疾患（その他内容）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_brain_spinal_disease_comment': string | null;
    /**
     * 脳・脊髄疾患：てんかん 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_brain_spinal_disease_epilepsy': string;
    /**
     * 脳・脊髄疾患：椎間板ヘルニア 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_brain_spinal_disease_herniated_disc': string;
    /**
     * 脳・脊髄疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_brain_spinal_disease_other': string;
    /**
     * 申し込み完了フラグ（=検査申込有無）
     * @type {boolean}
     * @memberof TypesPpTestKitData
     */
    'is_confirmed': boolean;
    /**
     * 検査に同意する 0：同意しない、1：同意する
     * @type {boolean}
     * @memberof TypesPpTestKitData
     */
    'is_consent_for_testing': boolean;
    /**
     * 下痢 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_diarrhea': string | null;
    /**
     * 下痢の発症時期 1：3日以内、2：1週間以内、3：2週間以内、4：１ヶ月以内、5：１ヶ月以上前
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_diarrhea_timing': string | null;
    /**
     * 消化器疾患（その他内容）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_digestive_disease_comment': string | null;
    /**
     * 消化器疾患：慢性腸症/炎症性腸疾患 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_digestive_disease_ibd': string;
    /**
     * 消化器疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_digestive_disease_other': string;
    /**
     * 消化器疾患：膵炎 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_digestive_disease_pancreatitis': string;
    /**
     * 消化器疾患：膵外分泌不全 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_digestive_disease_pei': string;
    /**
     * 病歴 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_disease': string;
    /**
     * 飼育環境 1：屋外、2：屋内、3：自由に行き来している
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_environment': string | null;
    /**
     * 1日の運動時間 1：1時間未満、2：1-2時間、3：2-3時間、4：3-4時間、5：4時間以上
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_exercise_time_oneday': string | null;
    /**
     * 眼科疾患：白内障 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_eye_disease_cataract': string;
    /**
     * 眼科疾患（その他内容）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_eye_disease_comment': string | null;
    /**
     * 眼科疾患：乾性核結膜炎（ドライアイ） 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_eye_disease_dry_eyes': string;
    /**
     * 眼科疾患：緑内障 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_eye_disease_glaucoma': string;
    /**
     * 眼科疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_eye_disease_other': string;
    /**
     * 目脂または涙やけ 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_eyemucus_tearsstain': string | null;
    /**
     * 恐怖心(他の犬or猫に対して) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_fear_for_other_pets': string | null;
    /**
     * 恐怖心(他の人に対して) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_fear_for_stranger': string | null;
    /**
     * 恐怖心(慣れない環境) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_fear_unfamiliar_environment': string | null;
    /**
     * ブラッシング頻度 1：毎日、2：週3-4回、3：週1-2回、4：月1-2回、5：ほとんどしない
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_frequency_brushing': string | null;
    /**
     * うんち/回数(1日) 1：毎日、2：週3-4回、3：週1-2回、4：月1-2回、5：ほとんどしない
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_frequency_excrement': string | null;
    /**
     * シャンプー頻度 1：週１-２回、2：月１-２回、3：２-３ヶ月に１回、4：ほとんどしない
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_frequency_shampoo': string | null;
    /**
     * 散歩頻度 1：毎日、2：週3-4回、3：週1-2回、4：月1-2回、5：ほとんどしない
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_frequency_walking': string | null;
    /**
     * 心臓疾患：心筋症 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_heart_disease_cardiomyopathy': string;
    /**
     * 心臓疾患（その他内容）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_heart_disease_comment': string | null;
    /**
     * 心臓疾患：心不全 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_heart_disease_heart_failure': string;
    /**
     * 心臓疾患：僧帽弁閉鎖不全症 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_heart_disease_mr': string;
    /**
     * 心臓疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_heart_disease_other': string;
    /**
     * 心臓疾患：肺高血圧症 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_heart_disease_ph': string;
    /**
     * ごはんが手作りの場合の種類：その他内容
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_homemade_mainfood_type_comment': string | null;
    /**
     * ごはんが手作りの場合の種類：魚 0：なし、1：あり
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_homemade_mainfood_type_fish': string;
    /**
     * ごはんが手作りの場合の種類：果物 0：なし、1：あり
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_homemade_mainfood_type_fruit': string;
    /**
     * ごはんが手作りの場合の種類：肉 0：なし、1：あり
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_homemade_mainfood_type_meat': string;
    /**
     * ごはんが手作りの場合の種類：その他 0：なし、1：あり
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_homemade_mainfood_type_other': string;
    /**
     * ごはんが手作りの場合の種類：野菜 0：なし、1：あり
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_homemade_mainfood_type_vegetable': string;
    /**
     * ホルモン・代謝疾患（その他内容）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_hormone_metabolic_disorder_comment': string | null;
    /**
     * ホルモン・代謝疾患：クッシング症候群 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_hormone_metabolism_cushing_syndrome': string;
    /**
     * ホルモン・代謝疾患：糖尿病 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_hormone_metabolism_diabetes': string;
    /**
     * ホルモン・代謝疾患：甲状腺機能低下症 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_hormone_metabolism_dl': string;
    /**
     * ホルモン・代謝疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_hormone_metabolism_other': string;
    /**
     * 高血圧 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_hypertension': string;
    /**
     * 腎臓疾患：慢性腎不全 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_kidney_disease_ckd': string;
    /**
     * 腎臓疾患（その他内容）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_kidney_disease_comment': string | null;
    /**
     * 腎臓疾患：腎結石 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_kidney_disease_kidney_stone': string;
    /**
     * 腎臓疾患：腎炎 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_kidney_disease_nephritis': string;
    /**
     * 腎臓疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_kidney_disease_other': string;
    /**
     * 腎臓疾患：蛋白漏出性腎症 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_kidney_disease_pln': string;
    /**
     * 泌乳した子供の数 0：不明 0~99匹選択
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_lactated_children': string | null;
    /**
     * 泌乳経験 0：なし、1：あり
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_lactation_experience': string | null;
    /**
     * 日常的な乳酸菌サプリ摂取有無 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_lactic_supplement': string | null;
    /**
     * 肝・胆道疾患：胆泥症 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_liver_bile_disease_biliary_sludge': string;
    /**
     * 肝・胆道疾患（その他内容）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_liver_bile_disease_comment': string | null;
    /**
     * 肝・胆道疾患：胆嚢粘液嚢胞 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_liver_bile_disease_gallbladder_mucocele': string;
    /**
     * 肝・胆道疾患：肝障害 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_liver_bile_disease_liver_damage': string;
    /**
     * 肝・胆道疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_liver_bile_disease_other': string;
    /**
     * ごはん 1：ドライフード、2：ウエットフード、3：手作り、4：その他（自由記述）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_main_food': string | null;
    /**
     * ごはん（その他内容）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_main_food_comment': string | null;
    /**
     * 抗生物質の投与 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_medicine_antibiotics': string | null;
    /**
     * 抗生物質以外の薬の投与 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_medicine_except_antibiotics': string | null;
    /**
     * 整形疾患：関節炎 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_orthopedic_disease_arthritis': string;
    /**
     * 整形疾患（その他内容）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_orthopedic_disease_comment': string | null;
    /**
     * 整形疾患：股関節異形成 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_orthopedic_disease_hd': string;
    /**
     * 整形疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_orthopedic_disease_other': string;
    /**
     * 整形疾患：膝蓋骨脱臼 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_orthopedic_disease_patella': string;
    /**
     * その他病歴
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_other_comment': string | null;
    /**
     * ペット体重（Go言語では、1.0が1として判断されるため、小数点以下の選択チェックが行えないので、文字列にしています。）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_pet_weight': string | null;
    /**
     * 現在の妊娠・妊娠の可能性 1：はい、2：いいえ、0：不明
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_pregnancy': string;
    /**
     * 妊娠期間 0：なし 0~99日選択
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_pregnancy_weeks': string | null;
    /**
     * 研究参加に同意する 0：同意しない、1：同意する
     * @type {boolean}
     * @memberof TypesPpTestKitData
     */
    'is_research_participation_consent': boolean;
    /**
     * 呼吸器疾患：喘息 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_respiratory_disease_asthma': string;
    /**
     * 呼吸器疾患：気管支炎 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_respiratory_disease_bronchitis': string;
    /**
     * 呼吸器疾患（その他内容）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_respiratory_disease_comment': string | null;
    /**
     * 呼吸器疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_respiratory_disease_other': string;
    /**
     * 呼吸器疾患：鼻炎 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_respiratory_disease_rhinitis': string;
    /**
     * 呼吸器疾患：気管虚脱 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_respiratory_disease_tracheal_collapse': string;
    /**
     * 皮膚疾患：その他のコメント
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_disease_comment': string | null;
    /**
     * 皮膚疾患：皮膚炎（原因が判明していない）0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_disease_eczema': string;
    /**
     * 皮膚疾患：外耳炎 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_disease_external_otitisis': string;
    /**
     * 皮膚疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_disease_other': string;
    /**
     * 皮膚疾患：膿皮症 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_disease_pyoderma': string;
    /**
     * 皮膚疾患：脂漏症 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_disease_seforrhoea': string;
    /**
     * 皮膚トラブル 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_trouble': string | null;
    /**
     * 皮膚トラブル（その他内容）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_trouble_symptoms_comment': string | null;
    /**
     * 皮膚トラブル：フケ 0：なし、1：あり
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_trouble_symptoms_dandruff': string;
    /**
     * 皮膚トラブル：乾燥 0：なし、1：あり
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_trouble_symptoms_dry': string;
    /**
     * 皮膚トラブル：脱毛 0：なし、1：あり
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_trouble_symptoms_hairloss': string;
    /**
     * 皮膚トラブル：かゆみ 0：なし、1：あり
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_trouble_symptoms_itch': string;
    /**
     * 皮膚トラブル：その他 0：なし、1：あり
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_trouble_symptoms_other': string;
    /**
     * 皮膚トラブル：発疹 0：なし、1：あり
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_trouble_symptoms_rash': string;
    /**
     * 皮膚トラブル：赤み 0：なし、1：あり
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_trouble_symptoms_sore': string;
    /**
     * 皮膚トラブル発症時期 1：3日以内、2：1週間以内、3：2週間以内、4：１ヶ月以内、5：１ヶ月以上前
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_skin_trouble_timing': string | null;
    /**
     * 避妊・去勢の手術経験 1：はい、2：いいえ、0：不明
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_spayed_neutered': string;
    /**
     * 泌乳した期間の合計 0：不明 0~99週間選択
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_total_lactation_duration': string | null;
    /**
     * 腫瘍
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_tumor_comment': string | null;
    /**
     * 分離不安(器物破損、大小便） 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_uneasiness_damage_to_property': string | null;
    /**
     * 分離不安(飼い主と分離時の不安感) 1：ある、2：時々ある、3：どちらとも言えない、4：あまりない、0：ない
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_uneasiness_separated_from_owner': string | null;
    /**
     * 泌尿器疾患：膀胱結石 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_urology_disease_bladder_stone': string;
    /**
     * 泌尿器疾患（その他内容）
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_urology_disease_comment': string | null;
    /**
     * 泌尿器疾患：特発性膀胱炎 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_urology_disease_idiopathic_cystitis': string;
    /**
     * 泌尿器疾患：その他 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_urology_disease_other': string;
    /**
     * 泌尿器疾患：その他の膀胱炎 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_urology_disease_other_cystitis': string;
    /**
     * 嘔吐 0：いいえ、1：はい
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_vomit': string | null;
    /**
     * 嘔吐の発症時期 1：3日以内、2：1週間以内、3：2週間以内、4：１ヶ月以内、5：１ヶ月以上前
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_vomit_timing': string | null;
    /**
     * 過去の妊娠の有無 1：はい、2：いいえ、0：不明
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_was_pregnant_past': string;
    /**
     * 気になること／その他
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_any_other': string;
    /**
     * 気になること／その他コメント
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_any_other_comment': string | null;
    /**
     * 気になること／口臭が気になる 0：ない、1：ある
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_bad_breath': string;
    /**
     * 気になること／毛ヅヤが悪い気がする 0：ない、1：ある
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_bad_hair': string;
    /**
     * 気になること／体臭が気になる 0：ない、1：ある
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_body_odor': string;
    /**
     * 気になること／歯石が気になる 0：ない、1：ある
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_dental_tartar': string;
    /**
     * 気になること／食糞する 0：ない、1：ある
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_eat_excrement': string;
    /**
     * 気になること／おしっこの回数が多い 0：ない、1：ある
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_frequent_urination': string;
    /**
     * 気になること／よだれが垂れる 0：ない、1：ある
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_increased_salivation': string;
    /**
     * 気になること／耳がかゆい 0：ない、1：ある
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_itchy_ears': string;
    /**
     * 気になること／体重が減りやすい 0：ない、1：ある
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_lost_weight': string;
    /**
     * 気になること／特になし 0：気になることあり、1：気になること特になし
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_nothing': string;
    /**
     * 気になること／耳の臭いが気になる 0：ない、1：ある
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_smell_of_ears': string;
    /**
     * 気になること／便が臭い 0：ない、1：ある
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_smell_of_excrement': string;
    /**
     * 気になること／イボがある 0：ない、1：ある
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_warts': string;
    /**
     * 気になること／体重が増えやすい 0：ない、1：ある
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'is_worry_weight_easily': string;
    /**
     * LabバーコードID
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'lab_barcode_id': string | null;
    /**
     * 病院ID
     * @type {number}
     * @memberof TypesPpTestKitData
     */
    'medic_inst_id': number | null;
    /**
     * 病院名
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'medic_inst_name': string | null;
    /**
     * メタデータ出力
     * @type {boolean}
     * @memberof TypesPpTestKitData
     */
    'metadata_out': boolean | null;
    /**
     * メタデータ出力日時 YYYY-MM-DD HH:MM:SS
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'metadata_out_date_time': string | null;
    /**
     * メタデータ出力ユーザー名
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'metadata_out_username': string | null;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof TypesPpTestKitData
     */
    'partner_inst_id': number | null;
    /**
     * 提携法人名
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'partner_inst_name': string | null;
    /**
     * 検体位置
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'position': string | null;
    /**
     * 
     * @type {TypesPpTestKitDataPpPet}
     * @memberof TypesPpTestKitData
     */
    'pp_pet': TypesPpTestKitDataPpPet | null;
    /**
     * ペットID（未申込の場合はnull）
     * @type {number}
     * @memberof TypesPpTestKitData
     */
    'pp_pet_id': number | null;
    /**
     * 
     * @type {TypesPpTestKitDataPpUser}
     * @memberof TypesPpTestKitData
     */
    'pp_user': TypesPpTestKitDataPpUser | null;
    /**
     * 会員ID（未申込の場合はnull）
     * @type {number}
     * @memberof TypesPpTestKitData
     */
    'pp_user_id': number | null;
    /**
     * 再検査フラグ
     * @type {boolean}
     * @memberof TypesPpTestKitData
     */
    're_test': boolean | null;
    /**
     * リコメンド要否区分 0：商品おすすめなし、1：腸内環境タイプ別商品おすすめ、2：腸内環境タイプ共通商品おすすめ
     * @type {PptestkitRecommendType}
     * @memberof TypesPpTestKitData
     */
    'recommend_type': PptestkitRecommendType;
    /**
     * 検査依頼登録日 YYYY-MM-DD
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'request_ymd': string | null;
    /**
     * 検体ID
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'sample_id': string;
    /**
     * 検体採取日 YYYY-MM-DD
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'sampling_date': string | null;
    /**
     * SKUコード
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'sku_code': string;
    /**
     * 検査開始日 YYYY-MM-DD
     * @type {string}
     * @memberof TypesPpTestKitData
     */
    'start_ymd': string | null;
    /**
     * 状況00：検査未依頼、01：検査未受付、02：検査中、12：再検査中、22：強制検査中、50：検査完了
     * @type {PpteststatusStatus}
     * @memberof TypesPpTestKitData
     */
    'status': PpteststatusStatus | null;
    /**
     * 状況（ユーザー用） 1：検査依頼済み、2：検査中、3：検査完了
     * @type {PpteststatusStatusForUser}
     * @memberof TypesPpTestKitData
     */
    'status_for_user': PpteststatusStatusForUser | null;
    /**
     * 検索分
     * @type {PptestkitTestDivision}
     * @memberof TypesPpTestKitData
     */
    'test_division': PptestkitTestDivision;
}
/**
 * ペット情報（最新の情報なので、検査時点の情報は`bi_****`を参照）
 * @export
 * @interface TypesPpTestKitDataPpPet
 */
export interface TypesPpTestKitDataPpPet {
    /**
     * 生年月日種別 1：確定、2：推定、3：不明
     * @type {string}
     * @memberof TypesPpTestKitDataPpPet
     */
    'accuracy_of_pet_birthday': string;
    /**
     * 種類 1：dog 2：cat
     * @type {string}
     * @memberof TypesPpTestKitDataPpPet
     */
    'animal_type': string;
    /**
     * 生年月日
     * @type {string}
     * @memberof TypesPpTestKitDataPpPet
     */
    'birthday': string | null;
    /**
     * 品種ID
     * @type {number}
     * @memberof TypesPpTestKitDataPpPet
     */
    'breed_id': number;
    /**
     * 品種名
     * @type {string}
     * @memberof TypesPpTestKitDataPpPet
     */
    'breed_name': string;
    /**
     * 両親の品種名1
     * @type {string}
     * @memberof TypesPpTestKitDataPpPet
     */
    'breed_name_parent_1': string | null;
    /**
     * 両親の品種名2
     * @type {string}
     * @memberof TypesPpTestKitDataPpPet
     */
    'breed_name_parent_2': string | null;
    /**
     * 両親の品種ID1
     * @type {number}
     * @memberof TypesPpTestKitDataPpPet
     */
    'breed_of_parents_1': number | null;
    /**
     * 両親の品種ID2
     * @type {number}
     * @memberof TypesPpTestKitDataPpPet
     */
    'breed_of_parents_2': number | null;
    /**
     * 登録日
     * @type {string}
     * @memberof TypesPpTestKitDataPpPet
     */
    'created': string;
    /**
     * アイコン画像（保存されているディレクトリ名を含む）
     * @type {string}
     * @memberof TypesPpTestKitDataPpPet
     */
    'icon': string | null;
    /**
     * アイコン画像（ディレクトリを省いてbasenameとした画像名）
     * @type {string}
     * @memberof TypesPpTestKitDataPpPet
     */
    'icon_name': string | null;
    /**
     * ペットID
     * @type {number}
     * @memberof TypesPpTestKitDataPpPet
     */
    'id': number;
    /**
     * 閲覧用アイコン画像URL
     * @type {string}
     * @memberof TypesPpTestKitDataPpPet
     */
    'img_url': string | null;
    /**
     * カルテID
     * @type {string}
     * @memberof TypesPpTestKitDataPpPet
     */
    'karte_id': string | null;
    /**
     * ペットの名前
     * @type {string}
     * @memberof TypesPpTestKitDataPpPet
     */
    'pet_name': string;
    /**
     * ペットの名前フリガナ
     * @type {string}
     * @memberof TypesPpTestKitDataPpPet
     */
    'pet_name_furigana': string;
    /**
     * ペットの名前ローマ字
     * @type {string}
     * @memberof TypesPpTestKitDataPpPet
     */
    'pet_name_romaji': string;
    /**
     * 性別 1：男の子、2：女の子
     * @type {string}
     * @memberof TypesPpTestKitDataPpPet
     */
    'pet_sex': string;
    /**
     * 会員ID(飼い主ID)
     * @type {number}
     * @memberof TypesPpTestKitDataPpPet
     */
    'pp_user_id': number;
}
/**
 * 飼い主（会員）情報
 * @export
 * @interface TypesPpTestKitDataPpUser
 */
export interface TypesPpTestKitDataPpUser {
    /**
     * 生年月日
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'birthday': string;
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'building': string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'city': string;
    /**
     * 登録日
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'created': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'email': string;
    /**
     * 性別 male or female or other
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'gender': string;
    /**
     * 飼い主（会員）ID
     * @type {number}
     * @memberof TypesPpTestKitDataPpUser
     */
    'id': number;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'last_login': string | null;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'last_login_ip': string | null;
    /**
     * 郵便番号（000-1111）
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'postal_code': string;
    /**
     * 飼い主（会員）が登録しているペット情報
     * @type {Array<TypesPpPetData>}
     * @memberof TypesPpTestKitDataPpUser
     */
    'pp_pets'?: Array<TypesPpPetData>;
    /**
     * 都道府県
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'pref_code': string;
    /**
     * ステータス active or inactive
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'status': string;
    /**
     * 丁目・番地
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'street': string;
    /**
     * 電話番号
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'tel_no': string;
    /**
     * 姓カナ
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'user_family_furigana': string;
    /**
     * 姓
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'user_family_name': string;
    /**
     * 名カナ
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'user_first_furigana': string;
    /**
     * 名
     * @type {string}
     * @memberof TypesPpTestKitDataPpUser
     */
    'user_first_name': string;
}
/**
 * 
 * @export
 * @interface TypesPpTestKitSampleData
 */
export interface TypesPpTestKitSampleData {
    /**
     * 範囲開始の検体ID
     * @type {string}
     * @memberof TypesPpTestKitSampleData
     */
    'from': string;
    /**
     * 病院ID
     * @type {number}
     * @memberof TypesPpTestKitSampleData
     */
    'medic_inst_id': number | null;
    /**
     * 病院名
     * @type {string}
     * @memberof TypesPpTestKitSampleData
     */
    'medic_inst_name': string | null;
    /**
     * マージされた件数
     * @type {number}
     * @memberof TypesPpTestKitSampleData
     */
    'num': number;
    /**
     * 提携法人ID
     * @type {number}
     * @memberof TypesPpTestKitSampleData
     */
    'partner_inst_id': number | null;
    /**
     * 提携法人名
     * @type {string}
     * @memberof TypesPpTestKitSampleData
     */
    'partner_inst_name': string | null;
    /**
     * リコメンド要否区分
     * @type {PptestkitRecommendType}
     * @memberof TypesPpTestKitSampleData
     */
    'recommend_type': PptestkitRecommendType;
    /**
     * SKUコード
     * @type {string}
     * @memberof TypesPpTestKitSampleData
     */
    'sku_code': string;
    /**
     * ステータス
     * @type {string}
     * @memberof TypesPpTestKitSampleData
     */
    'status': string | null;
    /**
     * 検査区分
     * @type {PptestkitTestDivision}
     * @memberof TypesPpTestKitSampleData
     */
    'testDivision': PptestkitTestDivision | null;
    /**
     * 範囲終了の検体ID
     * @type {string}
     * @memberof TypesPpTestKitSampleData
     */
    'to': string;
}
/**
 * 
 * @export
 * @interface TypesPpTestResultData
 */
export interface TypesPpTestResultData {
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof TypesPpTestResultData
     */
    'flowcell_id': string;
    /**
     * 強制検査フラグ
     * @type {boolean}
     * @memberof TypesPpTestResultData
     */
    'forced_test': boolean;
    /**
     * メタデータ入力日 YYYY-MM-DD
     * @type {string}
     * @memberof TypesPpTestResultData
     */
    'is_upd_ymd': string | null;
    /**
     * Lab barcode ID
     * @type {string}
     * @memberof TypesPpTestResultData
     */
    'lab_barcode_id': string;
    /**
     * メタデータ出力フラグ
     * @type {boolean}
     * @memberof TypesPpTestResultData
     */
    'metadata_out': boolean;
    /**
     * メタデータ出力日時(=前回DL日時) YYYY-MM-DD HH:MM:SS
     * @type {string}
     * @memberof TypesPpTestResultData
     */
    'metadata_out_dt': string;
    /**
     * メタデータ出力ユーザー名
     * @type {string}
     * @memberof TypesPpTestResultData
     */
    'metadata_out_user_name': string;
    /**
     * 位置
     * @type {string}
     * @memberof TypesPpTestResultData
     */
    'position': string;
    /**
     * 検体ID
     * @type {string}
     * @memberof TypesPpTestResultData
     */
    'sample_id': string;
    /**
     * ステータス
     * @type {PpteststatusStatus}
     * @memberof TypesPpTestResultData
     */
    'status': PpteststatusStatus;
}
/**
 * 
 * @export
 * @interface TypesPpUserData
 */
export interface TypesPpUserData {
    /**
     * 生年月日
     * @type {string}
     * @memberof TypesPpUserData
     */
    'birthday': string;
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof TypesPpUserData
     */
    'building': string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof TypesPpUserData
     */
    'city': string;
    /**
     * 登録日
     * @type {string}
     * @memberof TypesPpUserData
     */
    'created': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof TypesPpUserData
     */
    'email': string;
    /**
     * 性別 male or female or other
     * @type {string}
     * @memberof TypesPpUserData
     */
    'gender': string;
    /**
     * 飼い主（会員）ID
     * @type {number}
     * @memberof TypesPpUserData
     */
    'id': number;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof TypesPpUserData
     */
    'last_login': string | null;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof TypesPpUserData
     */
    'last_login_ip': string | null;
    /**
     * 郵便番号（000-1111）
     * @type {string}
     * @memberof TypesPpUserData
     */
    'postal_code': string;
    /**
     * 飼い主（会員）が登録しているペット情報
     * @type {Array<TypesPpPetData>}
     * @memberof TypesPpUserData
     */
    'pp_pets'?: Array<TypesPpPetData>;
    /**
     * 都道府県
     * @type {string}
     * @memberof TypesPpUserData
     */
    'pref_code': string;
    /**
     * ステータス active or inactive
     * @type {string}
     * @memberof TypesPpUserData
     */
    'status': string;
    /**
     * 丁目・番地
     * @type {string}
     * @memberof TypesPpUserData
     */
    'street': string;
    /**
     * 電話番号
     * @type {string}
     * @memberof TypesPpUserData
     */
    'tel_no': string;
    /**
     * 姓カナ
     * @type {string}
     * @memberof TypesPpUserData
     */
    'user_family_furigana': string;
    /**
     * 姓
     * @type {string}
     * @memberof TypesPpUserData
     */
    'user_family_name': string;
    /**
     * 名カナ
     * @type {string}
     * @memberof TypesPpUserData
     */
    'user_first_furigana': string;
    /**
     * 名
     * @type {string}
     * @memberof TypesPpUserData
     */
    'user_first_name': string;
}
/**
 * 
 * @export
 * @interface TypesReferenceData
 */
export interface TypesReferenceData {
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof TypesReferenceData
     */
    'flowcell_id': string;
    /**
     * Flow Cellステータス
     * @type {PpflowcellStatus}
     * @memberof TypesReferenceData
     */
    'flowcell_status': PpflowcellStatus;
    /**
     * LabバーコードID
     * @type {string}
     * @memberof TypesReferenceData
     */
    'lab_barcode_id': string;
    /**
     * ペットメタデータ有無
     * @type {boolean}
     * @memberof TypesReferenceData
     */
    'metadata_out': boolean;
    /**
     * 位置
     * @type {string}
     * @memberof TypesReferenceData
     */
    'position': string;
    /**
     * 再検査要否
     * @type {boolean}
     * @memberof TypesReferenceData
     */
    're_test': boolean;
    /**
     * 検体ID
     * @type {string}
     * @memberof TypesReferenceData
     */
    'sample_id': string;
    /**
     * PCR試験プレート番号
     * @type {string}
     * @memberof TypesReferenceData
     */
    'wellplate_id': string;
}
/**
 * 
 * @export
 * @interface TypesRollbackData
 */
export interface TypesRollbackData {
    /**
     * 結果通知日
     * @type {string}
     * @memberof TypesRollbackData
     */
    'completion_ymd': string;
    /**
     * Flow Cell ID
     * @type {string}
     * @memberof TypesRollbackData
     */
    'flowcell_id': string;
    /**
     * LabバーコードID
     * @type {string}
     * @memberof TypesRollbackData
     */
    'lab_barcode_id': string;
    /**
     * 位置
     * @type {string}
     * @memberof TypesRollbackData
     */
    'position': string;
    /**
     * 
     * @type {TypesRollbackDataPpPet}
     * @memberof TypesRollbackData
     */
    'pp_pet': TypesRollbackDataPpPet;
    /**
     * 
     * @type {TypesRollbackDataPpUser}
     * @memberof TypesRollbackData
     */
    'pp_user': TypesRollbackDataPpUser;
    /**
     * 検体ID
     * @type {string}
     * @memberof TypesRollbackData
     */
    'sample_id': string;
}
/**
 * ペット情報
 * @export
 * @interface TypesRollbackDataPpPet
 */
export interface TypesRollbackDataPpPet {
    /**
     * 生年月日種別 1：確定、2：推定、3：不明
     * @type {string}
     * @memberof TypesRollbackDataPpPet
     */
    'accuracy_of_pet_birthday': string;
    /**
     * 種類 1：dog 2：cat
     * @type {string}
     * @memberof TypesRollbackDataPpPet
     */
    'animal_type': string;
    /**
     * 生年月日
     * @type {string}
     * @memberof TypesRollbackDataPpPet
     */
    'birthday': string | null;
    /**
     * 品種ID
     * @type {number}
     * @memberof TypesRollbackDataPpPet
     */
    'breed_id': number;
    /**
     * 品種名
     * @type {string}
     * @memberof TypesRollbackDataPpPet
     */
    'breed_name': string;
    /**
     * 両親の品種名1
     * @type {string}
     * @memberof TypesRollbackDataPpPet
     */
    'breed_name_parent_1': string | null;
    /**
     * 両親の品種名2
     * @type {string}
     * @memberof TypesRollbackDataPpPet
     */
    'breed_name_parent_2': string | null;
    /**
     * 両親の品種ID1
     * @type {number}
     * @memberof TypesRollbackDataPpPet
     */
    'breed_of_parents_1': number | null;
    /**
     * 両親の品種ID2
     * @type {number}
     * @memberof TypesRollbackDataPpPet
     */
    'breed_of_parents_2': number | null;
    /**
     * 登録日
     * @type {string}
     * @memberof TypesRollbackDataPpPet
     */
    'created': string;
    /**
     * アイコン画像（保存されているディレクトリ名を含む）
     * @type {string}
     * @memberof TypesRollbackDataPpPet
     */
    'icon': string | null;
    /**
     * アイコン画像（ディレクトリを省いてbasenameとした画像名）
     * @type {string}
     * @memberof TypesRollbackDataPpPet
     */
    'icon_name': string | null;
    /**
     * ペットID
     * @type {number}
     * @memberof TypesRollbackDataPpPet
     */
    'id': number;
    /**
     * 閲覧用アイコン画像URL
     * @type {string}
     * @memberof TypesRollbackDataPpPet
     */
    'img_url': string | null;
    /**
     * カルテID
     * @type {string}
     * @memberof TypesRollbackDataPpPet
     */
    'karte_id': string | null;
    /**
     * ペットの名前
     * @type {string}
     * @memberof TypesRollbackDataPpPet
     */
    'pet_name': string;
    /**
     * ペットの名前フリガナ
     * @type {string}
     * @memberof TypesRollbackDataPpPet
     */
    'pet_name_furigana': string;
    /**
     * ペットの名前ローマ字
     * @type {string}
     * @memberof TypesRollbackDataPpPet
     */
    'pet_name_romaji': string;
    /**
     * 性別 1：男の子、2：女の子
     * @type {string}
     * @memberof TypesRollbackDataPpPet
     */
    'pet_sex': string;
    /**
     * 会員ID(飼い主ID)
     * @type {number}
     * @memberof TypesRollbackDataPpPet
     */
    'pp_user_id': number;
}
/**
 * ユーザー情報
 * @export
 * @interface TypesRollbackDataPpUser
 */
export interface TypesRollbackDataPpUser {
    /**
     * 生年月日
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'birthday': string;
    /**
     * 建物名・部屋番号
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'building': string | null;
    /**
     * 市区町村
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'city': string;
    /**
     * 登録日
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'created': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'email': string;
    /**
     * 性別 male or female or other
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'gender': string;
    /**
     * 飼い主（会員）ID
     * @type {number}
     * @memberof TypesRollbackDataPpUser
     */
    'id': number;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'last_login': string | null;
    /**
     * 最終ログインIP
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'last_login_ip': string | null;
    /**
     * 郵便番号（000-1111）
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'postal_code': string;
    /**
     * 飼い主（会員）が登録しているペット情報
     * @type {Array<TypesPpPetData>}
     * @memberof TypesRollbackDataPpUser
     */
    'pp_pets'?: Array<TypesPpPetData>;
    /**
     * 都道府県
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'pref_code': string;
    /**
     * ステータス active or inactive
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'status': string;
    /**
     * 丁目・番地
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'street': string;
    /**
     * 電話番号
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'tel_no': string;
    /**
     * 姓カナ
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'user_family_furigana': string;
    /**
     * 姓
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'user_family_name': string;
    /**
     * 名カナ
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'user_first_furigana': string;
    /**
     * 名
     * @type {string}
     * @memberof TypesRollbackDataPpUser
     */
    'user_first_name': string;
}
/**
 * 
 * @export
 * @interface XlsControllerParseForSampleIDResponse
 */
export interface XlsControllerParseForSampleIDResponse {
    /**
     * 検体IDのリスト
     * @type {Array<PpTestKitControllerFromTo>}
     * @memberof XlsControllerParseForSampleIDResponse
     */
    'sample_ids': Array<PpTestKitControllerFromTo>;
}

/**
 * AdminAgencyLoginApi - axios parameter creator
 * @export
 */
export const AdminAgencyLoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 代行ログイン一時パスワード作成
         * @param {AgencyLoginControllerAdminGenerateRequest} adminGenerateRequest AdminGenerateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAgencyLoginGeneratePost: async (adminGenerateRequest: AgencyLoginControllerAdminGenerateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminGenerateRequest' is not null or undefined
            assertParamExists('v1AdminAgencyLoginGeneratePost', 'adminGenerateRequest', adminGenerateRequest)
            const localVarPath = `/v1/admin/agency_login/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminGenerateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAgencyLoginApi - functional programming interface
 * @export
 */
export const AdminAgencyLoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAgencyLoginApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 代行ログイン一時パスワード作成
         * @param {AgencyLoginControllerAdminGenerateRequest} adminGenerateRequest AdminGenerateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminAgencyLoginGeneratePost(adminGenerateRequest: AgencyLoginControllerAdminGenerateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgencyLoginControllerAdminGenerateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminAgencyLoginGeneratePost(adminGenerateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAgencyLoginApi - factory interface
 * @export
 */
export const AdminAgencyLoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAgencyLoginApiFp(configuration)
    return {
        /**
         * 
         * @summary 代行ログイン一時パスワード作成
         * @param {AgencyLoginControllerAdminGenerateRequest} adminGenerateRequest AdminGenerateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAgencyLoginGeneratePost(adminGenerateRequest: AgencyLoginControllerAdminGenerateRequest, options?: any): AxiosPromise<AgencyLoginControllerAdminGenerateResponse> {
            return localVarFp.v1AdminAgencyLoginGeneratePost(adminGenerateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminAgencyLoginApi - object-oriented interface
 * @export
 * @class AdminAgencyLoginApi
 * @extends {BaseAPI}
 */
export class AdminAgencyLoginApi extends BaseAPI {
    /**
     * 
     * @summary 代行ログイン一時パスワード作成
     * @param {AgencyLoginControllerAdminGenerateRequest} adminGenerateRequest AdminGenerateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAgencyLoginApi
     */
    public v1AdminAgencyLoginGeneratePost(adminGenerateRequest: AgencyLoginControllerAdminGenerateRequest, options?: AxiosRequestConfig) {
        return AdminAgencyLoginApiFp(this.configuration).v1AdminAgencyLoginGeneratePost(adminGenerateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminAuthApi - axios parameter creator
 * @export
 */
export const AdminAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 自分自身のパスワード変更
         * @param {AdminAuthControllerChangePasswordRequest} changePasswordRequest ChangePasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAuthChangePasswordPost: async (changePasswordRequest: AdminAuthControllerChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordRequest' is not null or undefined
            assertParamExists('v1AdminAuthChangePasswordPost', 'changePasswordRequest', changePasswordRequest)
            const localVarPath = `/v1/admin/auth/change_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary パスワードを忘れた場合
         * @param {AdminAuthControllerForgotRequest} forgotRequest ForgotRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAuthForgotPost: async (forgotRequest: AdminAuthControllerForgotRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotRequest' is not null or undefined
            assertParamExists('v1AdminAuthForgotPost', 'forgotRequest', forgotRequest)
            const localVarPath = `/v1/admin/auth/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ログイン認証
         * @param {AdminAuthControllerLoginRequest} loginRequest LoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAuthLoginPost: async (loginRequest: AdminAuthControllerLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('v1AdminAuthLoginPost', 'loginRequest', loginRequest)
            const localVarPath = `/v1/admin/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ログアウト処理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAuthLogoutGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 自分自身の情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAuthMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary パスワード再設定
         * @param {AdminAuthControllerResetPasswordRequest} resetPasswordRequest ResetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAuthResetPasswordPost: async (resetPasswordRequest: AdminAuthControllerResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('v1AdminAuthResetPasswordPost', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/v1/admin/auth/reset_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAuthApi - functional programming interface
 * @export
 */
export const AdminAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 自分自身のパスワード変更
         * @param {AdminAuthControllerChangePasswordRequest} changePasswordRequest ChangePasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminAuthChangePasswordPost(changePasswordRequest: AdminAuthControllerChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminAuthChangePasswordPost(changePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary パスワードを忘れた場合
         * @param {AdminAuthControllerForgotRequest} forgotRequest ForgotRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminAuthForgotPost(forgotRequest: AdminAuthControllerForgotRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminAuthForgotPost(forgotRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ログイン認証
         * @param {AdminAuthControllerLoginRequest} loginRequest LoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminAuthLoginPost(loginRequest: AdminAuthControllerLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAuthControllerLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminAuthLoginPost(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ログアウト処理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminAuthLogoutGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminAuthLogoutGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 自分自身の情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminAuthMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAuthControllerMeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminAuthMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary パスワード再設定
         * @param {AdminAuthControllerResetPasswordRequest} resetPasswordRequest ResetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminAuthResetPasswordPost(resetPasswordRequest: AdminAuthControllerResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminAuthResetPasswordPost(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAuthApi - factory interface
 * @export
 */
export const AdminAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAuthApiFp(configuration)
    return {
        /**
         * 
         * @summary 自分自身のパスワード変更
         * @param {AdminAuthControllerChangePasswordRequest} changePasswordRequest ChangePasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAuthChangePasswordPost(changePasswordRequest: AdminAuthControllerChangePasswordRequest, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminAuthChangePasswordPost(changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary パスワードを忘れた場合
         * @param {AdminAuthControllerForgotRequest} forgotRequest ForgotRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAuthForgotPost(forgotRequest: AdminAuthControllerForgotRequest, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminAuthForgotPost(forgotRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ログイン認証
         * @param {AdminAuthControllerLoginRequest} loginRequest LoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAuthLoginPost(loginRequest: AdminAuthControllerLoginRequest, options?: any): AxiosPromise<AdminAuthControllerLoginResponse> {
            return localVarFp.v1AdminAuthLoginPost(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ログアウト処理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAuthLogoutGet(options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminAuthLogoutGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 自分自身の情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAuthMeGet(options?: any): AxiosPromise<AdminAuthControllerMeResponse> {
            return localVarFp.v1AdminAuthMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary パスワード再設定
         * @param {AdminAuthControllerResetPasswordRequest} resetPasswordRequest ResetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAuthResetPasswordPost(resetPasswordRequest: AdminAuthControllerResetPasswordRequest, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminAuthResetPasswordPost(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminAuthApi - object-oriented interface
 * @export
 * @class AdminAuthApi
 * @extends {BaseAPI}
 */
export class AdminAuthApi extends BaseAPI {
    /**
     * 
     * @summary 自分自身のパスワード変更
     * @param {AdminAuthControllerChangePasswordRequest} changePasswordRequest ChangePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAuthApi
     */
    public v1AdminAuthChangePasswordPost(changePasswordRequest: AdminAuthControllerChangePasswordRequest, options?: AxiosRequestConfig) {
        return AdminAuthApiFp(this.configuration).v1AdminAuthChangePasswordPost(changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary パスワードを忘れた場合
     * @param {AdminAuthControllerForgotRequest} forgotRequest ForgotRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAuthApi
     */
    public v1AdminAuthForgotPost(forgotRequest: AdminAuthControllerForgotRequest, options?: AxiosRequestConfig) {
        return AdminAuthApiFp(this.configuration).v1AdminAuthForgotPost(forgotRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ログイン認証
     * @param {AdminAuthControllerLoginRequest} loginRequest LoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAuthApi
     */
    public v1AdminAuthLoginPost(loginRequest: AdminAuthControllerLoginRequest, options?: AxiosRequestConfig) {
        return AdminAuthApiFp(this.configuration).v1AdminAuthLoginPost(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ログアウト処理
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAuthApi
     */
    public v1AdminAuthLogoutGet(options?: AxiosRequestConfig) {
        return AdminAuthApiFp(this.configuration).v1AdminAuthLogoutGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 自分自身の情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAuthApi
     */
    public v1AdminAuthMeGet(options?: AxiosRequestConfig) {
        return AdminAuthApiFp(this.configuration).v1AdminAuthMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary パスワード再設定
     * @param {AdminAuthControllerResetPasswordRequest} resetPasswordRequest ResetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAuthApi
     */
    public v1AdminAuthResetPasswordPost(resetPasswordRequest: AdminAuthControllerResetPasswordRequest, options?: AxiosRequestConfig) {
        return AdminAuthApiFp(this.configuration).v1AdminAuthResetPasswordPost(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminHistoryApi - axios parameter creator
 * @export
 */
export const AdminHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * URLで検体IDを指定して、最新の菌組成情報を返す
         * @summary 菌組成情報取得
         * @param {PpTestResultControllerGetBacteriaRequest} getBacteriaRequest PpTestResultControllerGetBacteriaRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminHistoryBacteriaPost: async (getBacteriaRequest: PpTestResultControllerGetBacteriaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getBacteriaRequest' is not null or undefined
            assertParamExists('v1AdminHistoryBacteriaPost', 'getBacteriaRequest', getBacteriaRequest)
            const localVarPath = `/v1/admin/history/bacteria`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getBacteriaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定の検体IDを起点とした有害菌の履歴を取得
         * @summary 有害菌の履歴情報取得
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminHistoryBadBacteriaSampleIdGet: async (sampleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleId' is not null or undefined
            assertParamExists('v1AdminHistoryBadBacteriaSampleIdGet', 'sampleId', sampleId)
            const localVarPath = `/v1/admin/history/bad-bacteria/{sampleId}`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 起点となる検体IDを指定し、し、最大3件の検査履歴をPDFで出力します。<br>
         * @summary 検査履歴のPDF出力
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminHistoryPdfSampleIdGet: async (sampleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleId' is not null or undefined
            assertParamExists('v1AdminHistoryPdfSampleIdGet', 'sampleId', sampleId)
            const localVarPath = `/v1/admin/history-pdf/{sampleId}`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ペットIDを指定して、検査完了した履歴の一覧を降順で返します。<br> 過去の履歴グラフの作成などに使用します。
         * @summary 特定ペットの検査履歴取得
         * @param {string} ppPetId ペットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminHistoryPetPpPetIdGet: async (ppPetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ppPetId' is not null or undefined
            assertParamExists('v1AdminHistoryPetPpPetIdGet', 'ppPetId', ppPetId)
            const localVarPath = `/v1/admin/history-pet/{ppPetId}`
                .replace(`{${"ppPetId"}}`, encodeURIComponent(String(ppPetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検体IDを指定し、結果データ1件を取得します。<br> ログイン権限により見れるデータは変わってきます。<br> リスクスコアデータは非常に項目が多いです。<br> また、EG MIrRORのAPIのレスポンスが基本文字列なので、数値も文字列としています。必要に応じてキャストしてください。
         * @summary 検査履歴データ1件取得
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminHistorySampleIdGet: async (sampleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleId' is not null or undefined
            assertParamExists('v1AdminHistorySampleIdGet', 'sampleId', sampleId)
            const localVarPath = `/v1/admin/history/{sampleId}`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminHistoryApi - functional programming interface
 * @export
 */
export const AdminHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * URLで検体IDを指定して、最新の菌組成情報を返す
         * @summary 菌組成情報取得
         * @param {PpTestResultControllerGetBacteriaRequest} getBacteriaRequest PpTestResultControllerGetBacteriaRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminHistoryBacteriaPost(getBacteriaRequest: PpTestResultControllerGetBacteriaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestResultControllerGetBacteriaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminHistoryBacteriaPost(getBacteriaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定の検体IDを起点とした有害菌の履歴を取得
         * @summary 有害菌の履歴情報取得
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminHistoryBadBacteriaSampleIdGet(sampleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestResultControllerGetBadBacteriaHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminHistoryBadBacteriaSampleIdGet(sampleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 起点となる検体IDを指定し、し、最大3件の検査履歴をPDFで出力します。<br>
         * @summary 検査履歴のPDF出力
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminHistoryPdfSampleIdGet(sampleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestResultControllerSearchHistoryPetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminHistoryPdfSampleIdGet(sampleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ペットIDを指定して、検査完了した履歴の一覧を降順で返します。<br> 過去の履歴グラフの作成などに使用します。
         * @summary 特定ペットの検査履歴取得
         * @param {string} ppPetId ペットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminHistoryPetPpPetIdGet(ppPetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestResultControllerSearchHistoryPetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminHistoryPetPpPetIdGet(ppPetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検体IDを指定し、結果データ1件を取得します。<br> ログイン権限により見れるデータは変わってきます。<br> リスクスコアデータは非常に項目が多いです。<br> また、EG MIrRORのAPIのレスポンスが基本文字列なので、数値も文字列としています。必要に応じてキャストしてください。
         * @summary 検査履歴データ1件取得
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminHistorySampleIdGet(sampleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestResultControllerGetHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminHistorySampleIdGet(sampleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminHistoryApi - factory interface
 * @export
 */
export const AdminHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminHistoryApiFp(configuration)
    return {
        /**
         * URLで検体IDを指定して、最新の菌組成情報を返す
         * @summary 菌組成情報取得
         * @param {PpTestResultControllerGetBacteriaRequest} getBacteriaRequest PpTestResultControllerGetBacteriaRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminHistoryBacteriaPost(getBacteriaRequest: PpTestResultControllerGetBacteriaRequest, options?: any): AxiosPromise<PpTestResultControllerGetBacteriaResponse> {
            return localVarFp.v1AdminHistoryBacteriaPost(getBacteriaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定の検体IDを起点とした有害菌の履歴を取得
         * @summary 有害菌の履歴情報取得
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminHistoryBadBacteriaSampleIdGet(sampleId: string, options?: any): AxiosPromise<PpTestResultControllerGetBadBacteriaHistoryResponse> {
            return localVarFp.v1AdminHistoryBadBacteriaSampleIdGet(sampleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 起点となる検体IDを指定し、し、最大3件の検査履歴をPDFで出力します。<br>
         * @summary 検査履歴のPDF出力
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminHistoryPdfSampleIdGet(sampleId: string, options?: any): AxiosPromise<PpTestResultControllerSearchHistoryPetResponse> {
            return localVarFp.v1AdminHistoryPdfSampleIdGet(sampleId, options).then((request) => request(axios, basePath));
        },
        /**
         * ペットIDを指定して、検査完了した履歴の一覧を降順で返します。<br> 過去の履歴グラフの作成などに使用します。
         * @summary 特定ペットの検査履歴取得
         * @param {string} ppPetId ペットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminHistoryPetPpPetIdGet(ppPetId: string, options?: any): AxiosPromise<PpTestResultControllerSearchHistoryPetResponse> {
            return localVarFp.v1AdminHistoryPetPpPetIdGet(ppPetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 検体IDを指定し、結果データ1件を取得します。<br> ログイン権限により見れるデータは変わってきます。<br> リスクスコアデータは非常に項目が多いです。<br> また、EG MIrRORのAPIのレスポンスが基本文字列なので、数値も文字列としています。必要に応じてキャストしてください。
         * @summary 検査履歴データ1件取得
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminHistorySampleIdGet(sampleId: string, options?: any): AxiosPromise<PpTestResultControllerGetHistoryResponse> {
            return localVarFp.v1AdminHistorySampleIdGet(sampleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminHistoryApi - object-oriented interface
 * @export
 * @class AdminHistoryApi
 * @extends {BaseAPI}
 */
export class AdminHistoryApi extends BaseAPI {
    /**
     * URLで検体IDを指定して、最新の菌組成情報を返す
     * @summary 菌組成情報取得
     * @param {PpTestResultControllerGetBacteriaRequest} getBacteriaRequest PpTestResultControllerGetBacteriaRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminHistoryApi
     */
    public v1AdminHistoryBacteriaPost(getBacteriaRequest: PpTestResultControllerGetBacteriaRequest, options?: AxiosRequestConfig) {
        return AdminHistoryApiFp(this.configuration).v1AdminHistoryBacteriaPost(getBacteriaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定の検体IDを起点とした有害菌の履歴を取得
     * @summary 有害菌の履歴情報取得
     * @param {string} sampleId 検体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminHistoryApi
     */
    public v1AdminHistoryBadBacteriaSampleIdGet(sampleId: string, options?: AxiosRequestConfig) {
        return AdminHistoryApiFp(this.configuration).v1AdminHistoryBadBacteriaSampleIdGet(sampleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 起点となる検体IDを指定し、し、最大3件の検査履歴をPDFで出力します。<br>
     * @summary 検査履歴のPDF出力
     * @param {string} sampleId 検体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminHistoryApi
     */
    public v1AdminHistoryPdfSampleIdGet(sampleId: string, options?: AxiosRequestConfig) {
        return AdminHistoryApiFp(this.configuration).v1AdminHistoryPdfSampleIdGet(sampleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ペットIDを指定して、検査完了した履歴の一覧を降順で返します。<br> 過去の履歴グラフの作成などに使用します。
     * @summary 特定ペットの検査履歴取得
     * @param {string} ppPetId ペットID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminHistoryApi
     */
    public v1AdminHistoryPetPpPetIdGet(ppPetId: string, options?: AxiosRequestConfig) {
        return AdminHistoryApiFp(this.configuration).v1AdminHistoryPetPpPetIdGet(ppPetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検体IDを指定し、結果データ1件を取得します。<br> ログイン権限により見れるデータは変わってきます。<br> リスクスコアデータは非常に項目が多いです。<br> また、EG MIrRORのAPIのレスポンスが基本文字列なので、数値も文字列としています。必要に応じてキャストしてください。
     * @summary 検査履歴データ1件取得
     * @param {string} sampleId 検体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminHistoryApi
     */
    public v1AdminHistorySampleIdGet(sampleId: string, options?: AxiosRequestConfig) {
        return AdminHistoryApiFp(this.configuration).v1AdminHistorySampleIdGet(sampleId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminLogApi - axios parameter creator
 * @export
 */
export const AdminLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * cloudwatchlogsに保存されたAPIの操作ログを返す
         * @summary 特権ユーザー向けログ検索
         * @param {LogControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminLogPost: async (indexRequest: LogControllerIndexRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indexRequest' is not null or undefined
            assertParamExists('v1AdminLogPost', 'indexRequest', indexRequest)
            const localVarPath = `/v1/admin/log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indexRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminLogApi - functional programming interface
 * @export
 */
export const AdminLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminLogApiAxiosParamCreator(configuration)
    return {
        /**
         * cloudwatchlogsに保存されたAPIの操作ログを返す
         * @summary 特権ユーザー向けログ検索
         * @param {LogControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminLogPost(indexRequest: LogControllerIndexRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminLogPost(indexRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminLogApi - factory interface
 * @export
 */
export const AdminLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminLogApiFp(configuration)
    return {
        /**
         * cloudwatchlogsに保存されたAPIの操作ログを返す
         * @summary 特権ユーザー向けログ検索
         * @param {LogControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminLogPost(indexRequest: LogControllerIndexRequest, options?: any): AxiosPromise<LogControllerIndexResponse> {
            return localVarFp.v1AdminLogPost(indexRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminLogApi - object-oriented interface
 * @export
 * @class AdminLogApi
 * @extends {BaseAPI}
 */
export class AdminLogApi extends BaseAPI {
    /**
     * cloudwatchlogsに保存されたAPIの操作ログを返す
     * @summary 特権ユーザー向けログ検索
     * @param {LogControllerIndexRequest} indexRequest IndexRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLogApi
     */
    public v1AdminLogPost(indexRequest: LogControllerIndexRequest, options?: AxiosRequestConfig) {
        return AdminLogApiFp(this.configuration).v1AdminLogPost(indexRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminMetadataApi - axios parameter creator
 * @export
 */
export const AdminMetadataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した検体IDで、22:強制検査中の検体はダウンロード対象外となります。
         * @summary メタデータTSV出力
         * @param {PpTestResultControllerMetadataDownloadRequest} metadataDownloadRequest MetadataDownloadRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminMetadataDownloadPost: async (metadataDownloadRequest: PpTestResultControllerMetadataDownloadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metadataDownloadRequest' is not null or undefined
            assertParamExists('v1AdminMetadataDownloadPost', 'metadataDownloadRequest', metadataDownloadRequest)
            const localVarPath = `/v1/admin/metadata/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metadataDownloadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メタデータの一覧出力
         * @param {PpTestResultControllerMetadataIndexRequest} metadataIndexRequest MetadataIndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminMetadataIndexPost: async (metadataIndexRequest: PpTestResultControllerMetadataIndexRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metadataIndexRequest' is not null or undefined
            assertParamExists('v1AdminMetadataIndexPost', 'metadataIndexRequest', metadataIndexRequest)
            const localVarPath = `/v1/admin/metadata/index`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metadataIndexRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminMetadataApi - functional programming interface
 * @export
 */
export const AdminMetadataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminMetadataApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した検体IDで、22:強制検査中の検体はダウンロード対象外となります。
         * @summary メタデータTSV出力
         * @param {PpTestResultControllerMetadataDownloadRequest} metadataDownloadRequest MetadataDownloadRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminMetadataDownloadPost(metadataDownloadRequest: PpTestResultControllerMetadataDownloadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminMetadataDownloadPost(metadataDownloadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary メタデータの一覧出力
         * @param {PpTestResultControllerMetadataIndexRequest} metadataIndexRequest MetadataIndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminMetadataIndexPost(metadataIndexRequest: PpTestResultControllerMetadataIndexRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestResultControllerMetadataIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminMetadataIndexPost(metadataIndexRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminMetadataApi - factory interface
 * @export
 */
export const AdminMetadataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminMetadataApiFp(configuration)
    return {
        /**
         * 指定した検体IDで、22:強制検査中の検体はダウンロード対象外となります。
         * @summary メタデータTSV出力
         * @param {PpTestResultControllerMetadataDownloadRequest} metadataDownloadRequest MetadataDownloadRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminMetadataDownloadPost(metadataDownloadRequest: PpTestResultControllerMetadataDownloadRequest, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminMetadataDownloadPost(metadataDownloadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メタデータの一覧出力
         * @param {PpTestResultControllerMetadataIndexRequest} metadataIndexRequest MetadataIndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminMetadataIndexPost(metadataIndexRequest: PpTestResultControllerMetadataIndexRequest, options?: any): AxiosPromise<PpTestResultControllerMetadataIndexResponse> {
            return localVarFp.v1AdminMetadataIndexPost(metadataIndexRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminMetadataApi - object-oriented interface
 * @export
 * @class AdminMetadataApi
 * @extends {BaseAPI}
 */
export class AdminMetadataApi extends BaseAPI {
    /**
     * 指定した検体IDで、22:強制検査中の検体はダウンロード対象外となります。
     * @summary メタデータTSV出力
     * @param {PpTestResultControllerMetadataDownloadRequest} metadataDownloadRequest MetadataDownloadRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMetadataApi
     */
    public v1AdminMetadataDownloadPost(metadataDownloadRequest: PpTestResultControllerMetadataDownloadRequest, options?: AxiosRequestConfig) {
        return AdminMetadataApiFp(this.configuration).v1AdminMetadataDownloadPost(metadataDownloadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メタデータの一覧出力
     * @param {PpTestResultControllerMetadataIndexRequest} metadataIndexRequest MetadataIndexRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMetadataApi
     */
    public v1AdminMetadataIndexPost(metadataIndexRequest: PpTestResultControllerMetadataIndexRequest, options?: AxiosRequestConfig) {
        return AdminMetadataApiFp(this.configuration).v1AdminMetadataIndexPost(metadataIndexRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpAnnouncementAdminApi - axios parameter creator
 * @export
 */
export const AdminPpAnnouncementAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 管理者向けお知らせ新規登録
         * @param {PpAnnouncementAdminControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementAdminCreatePost: async (createRequest: PpAnnouncementAdminControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpAnnouncementAdminCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_announcement_admin/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理者向けお知らせ削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementAdminDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpAnnouncementAdminDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_announcement_admin/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理者向けお知らせ編集
         * @param {string} id 更新対象のID
         * @param {PpAnnouncementAdminControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementAdminEditIdPost: async (id: string, editRequest: PpAnnouncementAdminControllerEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpAnnouncementAdminEditIdPost', 'id', id)
            // verify required parameter 'editRequest' is not null or undefined
            assertParamExists('v1AdminPpAnnouncementAdminEditIdPost', 'editRequest', editRequest)
            const localVarPath = `/v1/admin/pp_announcement_admin/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理者向けお知らせ一覧
         * @param {string} [forFront] フロント用に出力する場合1を指定
         * @param {string} [keyword] 検索キーワード
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementAdminGet: async (forFront?: string, keyword?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_announcement_admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (forFront !== undefined) {
                localVarQueryParameter['for_front'] = forFront;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理者向けお知らせ一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementAdminGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpAnnouncementAdminGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_announcement_admin/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpAnnouncementAdminApi - functional programming interface
 * @export
 */
export const AdminPpAnnouncementAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpAnnouncementAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 管理者向けお知らせ新規登録
         * @param {PpAnnouncementAdminControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpAnnouncementAdminCreatePost(createRequest: PpAnnouncementAdminControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpAnnouncementAdminControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpAnnouncementAdminCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 管理者向けお知らせ削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpAnnouncementAdminDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpAnnouncementAdminDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 管理者向けお知らせ編集
         * @param {string} id 更新対象のID
         * @param {PpAnnouncementAdminControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpAnnouncementAdminEditIdPost(id: string, editRequest: PpAnnouncementAdminControllerEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpAnnouncementAdminControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpAnnouncementAdminEditIdPost(id, editRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 管理者向けお知らせ一覧
         * @param {string} [forFront] フロント用に出力する場合1を指定
         * @param {string} [keyword] 検索キーワード
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpAnnouncementAdminGet(forFront?: string, keyword?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpAnnouncementAdminControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpAnnouncementAdminGet(forFront, keyword, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 管理者向けお知らせ一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpAnnouncementAdminGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpAnnouncementAdminControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpAnnouncementAdminGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpAnnouncementAdminApi - factory interface
 * @export
 */
export const AdminPpAnnouncementAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpAnnouncementAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary 管理者向けお知らせ新規登録
         * @param {PpAnnouncementAdminControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementAdminCreatePost(createRequest: PpAnnouncementAdminControllerCreateRequest, options?: any): AxiosPromise<PpAnnouncementAdminControllerGetResponse> {
            return localVarFp.v1AdminPpAnnouncementAdminCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理者向けお知らせ削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementAdminDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpAnnouncementAdminDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理者向けお知らせ編集
         * @param {string} id 更新対象のID
         * @param {PpAnnouncementAdminControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementAdminEditIdPost(id: string, editRequest: PpAnnouncementAdminControllerEditRequest, options?: any): AxiosPromise<PpAnnouncementAdminControllerGetResponse> {
            return localVarFp.v1AdminPpAnnouncementAdminEditIdPost(id, editRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理者向けお知らせ一覧
         * @param {string} [forFront] フロント用に出力する場合1を指定
         * @param {string} [keyword] 検索キーワード
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementAdminGet(forFront?: string, keyword?: string, page?: number, size?: number, options?: any): AxiosPromise<PpAnnouncementAdminControllerIndexResponse> {
            return localVarFp.v1AdminPpAnnouncementAdminGet(forFront, keyword, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理者向けお知らせ一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementAdminGetIdGet(id: string, options?: any): AxiosPromise<PpAnnouncementAdminControllerGetResponse> {
            return localVarFp.v1AdminPpAnnouncementAdminGetIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpAnnouncementAdminApi - object-oriented interface
 * @export
 * @class AdminPpAnnouncementAdminApi
 * @extends {BaseAPI}
 */
export class AdminPpAnnouncementAdminApi extends BaseAPI {
    /**
     * 
     * @summary 管理者向けお知らせ新規登録
     * @param {PpAnnouncementAdminControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpAnnouncementAdminApi
     */
    public v1AdminPpAnnouncementAdminCreatePost(createRequest: PpAnnouncementAdminControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpAnnouncementAdminApiFp(this.configuration).v1AdminPpAnnouncementAdminCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理者向けお知らせ削除
     * @param {string} id 削除対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpAnnouncementAdminApi
     */
    public v1AdminPpAnnouncementAdminDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpAnnouncementAdminApiFp(this.configuration).v1AdminPpAnnouncementAdminDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理者向けお知らせ編集
     * @param {string} id 更新対象のID
     * @param {PpAnnouncementAdminControllerEditRequest} editRequest EditRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpAnnouncementAdminApi
     */
    public v1AdminPpAnnouncementAdminEditIdPost(id: string, editRequest: PpAnnouncementAdminControllerEditRequest, options?: AxiosRequestConfig) {
        return AdminPpAnnouncementAdminApiFp(this.configuration).v1AdminPpAnnouncementAdminEditIdPost(id, editRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理者向けお知らせ一覧
     * @param {string} [forFront] フロント用に出力する場合1を指定
     * @param {string} [keyword] 検索キーワード
     * @param {number} [page] ページ番号
     * @param {number} [size] ページあたりの取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpAnnouncementAdminApi
     */
    public v1AdminPpAnnouncementAdminGet(forFront?: string, keyword?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AdminPpAnnouncementAdminApiFp(this.configuration).v1AdminPpAnnouncementAdminGet(forFront, keyword, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理者向けお知らせ一件取得
     * @param {string} id 取得対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpAnnouncementAdminApi
     */
    public v1AdminPpAnnouncementAdminGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpAnnouncementAdminApiFp(this.configuration).v1AdminPpAnnouncementAdminGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpAnnouncementUserApi - axios parameter creator
 * @export
 */
export const AdminPpAnnouncementUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ユーザー向けお知らせ新規登録
         * @param {PpAnnouncementUserControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementUserCreatePost: async (createRequest: PpAnnouncementUserControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpAnnouncementUserCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_announcement_user/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー向けお知らせ削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementUserDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpAnnouncementUserDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_announcement_user/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー向けお知らせ編集
         * @param {string} id 更新対象のID
         * @param {PpAnnouncementUserControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementUserEditIdPost: async (id: string, editRequest: PpAnnouncementUserControllerEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpAnnouncementUserEditIdPost', 'id', id)
            // verify required parameter 'editRequest' is not null or undefined
            assertParamExists('v1AdminPpAnnouncementUserEditIdPost', 'editRequest', editRequest)
            const localVarPath = `/v1/admin/pp_announcement_user/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理画面用ユーザー向けお知らせ一覧
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementUserGet: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_announcement_user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー向けお知らせ一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementUserGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpAnnouncementUserGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_announcement_user/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpAnnouncementUserApi - functional programming interface
 * @export
 */
export const AdminPpAnnouncementUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpAnnouncementUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ユーザー向けお知らせ新規登録
         * @param {PpAnnouncementUserControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpAnnouncementUserCreatePost(createRequest: PpAnnouncementUserControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpAnnouncementUserControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpAnnouncementUserCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザー向けお知らせ削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpAnnouncementUserDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpAnnouncementUserDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザー向けお知らせ編集
         * @param {string} id 更新対象のID
         * @param {PpAnnouncementUserControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpAnnouncementUserEditIdPost(id: string, editRequest: PpAnnouncementUserControllerEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpAnnouncementUserControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpAnnouncementUserEditIdPost(id, editRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 管理画面用ユーザー向けお知らせ一覧
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpAnnouncementUserGet(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpAnnouncementUserControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpAnnouncementUserGet(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザー向けお知らせ一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpAnnouncementUserGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpAnnouncementUserControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpAnnouncementUserGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpAnnouncementUserApi - factory interface
 * @export
 */
export const AdminPpAnnouncementUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpAnnouncementUserApiFp(configuration)
    return {
        /**
         * 
         * @summary ユーザー向けお知らせ新規登録
         * @param {PpAnnouncementUserControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementUserCreatePost(createRequest: PpAnnouncementUserControllerCreateRequest, options?: any): AxiosPromise<PpAnnouncementUserControllerGetResponse> {
            return localVarFp.v1AdminPpAnnouncementUserCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー向けお知らせ削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementUserDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpAnnouncementUserDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー向けお知らせ編集
         * @param {string} id 更新対象のID
         * @param {PpAnnouncementUserControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementUserEditIdPost(id: string, editRequest: PpAnnouncementUserControllerEditRequest, options?: any): AxiosPromise<PpAnnouncementUserControllerGetResponse> {
            return localVarFp.v1AdminPpAnnouncementUserEditIdPost(id, editRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理画面用ユーザー向けお知らせ一覧
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementUserGet(page?: number, size?: number, options?: any): AxiosPromise<PpAnnouncementUserControllerIndexResponse> {
            return localVarFp.v1AdminPpAnnouncementUserGet(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー向けお知らせ一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpAnnouncementUserGetIdGet(id: string, options?: any): AxiosPromise<PpAnnouncementUserControllerGetResponse> {
            return localVarFp.v1AdminPpAnnouncementUserGetIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpAnnouncementUserApi - object-oriented interface
 * @export
 * @class AdminPpAnnouncementUserApi
 * @extends {BaseAPI}
 */
export class AdminPpAnnouncementUserApi extends BaseAPI {
    /**
     * 
     * @summary ユーザー向けお知らせ新規登録
     * @param {PpAnnouncementUserControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpAnnouncementUserApi
     */
    public v1AdminPpAnnouncementUserCreatePost(createRequest: PpAnnouncementUserControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpAnnouncementUserApiFp(this.configuration).v1AdminPpAnnouncementUserCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー向けお知らせ削除
     * @param {string} id 削除対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpAnnouncementUserApi
     */
    public v1AdminPpAnnouncementUserDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpAnnouncementUserApiFp(this.configuration).v1AdminPpAnnouncementUserDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー向けお知らせ編集
     * @param {string} id 更新対象のID
     * @param {PpAnnouncementUserControllerEditRequest} editRequest EditRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpAnnouncementUserApi
     */
    public v1AdminPpAnnouncementUserEditIdPost(id: string, editRequest: PpAnnouncementUserControllerEditRequest, options?: AxiosRequestConfig) {
        return AdminPpAnnouncementUserApiFp(this.configuration).v1AdminPpAnnouncementUserEditIdPost(id, editRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理画面用ユーザー向けお知らせ一覧
     * @param {number} [page] ページ番号
     * @param {number} [size] ページあたりの取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpAnnouncementUserApi
     */
    public v1AdminPpAnnouncementUserGet(page?: number, size?: number, options?: AxiosRequestConfig) {
        return AdminPpAnnouncementUserApiFp(this.configuration).v1AdminPpAnnouncementUserGet(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー向けお知らせ一件取得
     * @param {string} id 取得対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpAnnouncementUserApi
     */
    public v1AdminPpAnnouncementUserGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpAnnouncementUserApiFp(this.configuration).v1AdminPpAnnouncementUserGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpFaqApi - axios parameter creator
 * @export
 */
export const AdminPpFaqApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary FAQ新規作成
         * @param {PpFaqControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqCreatePost: async (createRequest: PpFaqControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpFaqCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_faq/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary FAQ削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpFaqDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_faq/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary FAQ編集
         * @param {string} id 編集対象のID
         * @param {PpFaqControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqEditIdPost: async (id: string, createRequest: PpFaqControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpFaqEditIdPost', 'id', id)
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpFaqEditIdPost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_faq/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary FAQ一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpFaqGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_faq/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary FAQ一覧
         * @param {PpFaqControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqPost: async (indexRequest: PpFaqControllerIndexRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indexRequest' is not null or undefined
            assertParamExists('v1AdminPpFaqPost', 'indexRequest', indexRequest)
            const localVarPath = `/v1/admin/pp_faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indexRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary FAQ表示順変更
         * @param {PpFaqControllerSortRequest} sortRequest SortRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqSortPost: async (sortRequest: PpFaqControllerSortRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortRequest' is not null or undefined
            assertParamExists('v1AdminPpFaqSortPost', 'sortRequest', sortRequest)
            const localVarPath = `/v1/admin/pp_faq/sort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpFaqApi - functional programming interface
 * @export
 */
export const AdminPpFaqApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpFaqApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary FAQ新規作成
         * @param {PpFaqControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpFaqCreatePost(createRequest: PpFaqControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpFaqControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpFaqCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary FAQ削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpFaqDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpFaqDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary FAQ編集
         * @param {string} id 編集対象のID
         * @param {PpFaqControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpFaqEditIdPost(id: string, createRequest: PpFaqControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpFaqControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpFaqEditIdPost(id, createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary FAQ一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpFaqGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpFaqControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpFaqGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary FAQ一覧
         * @param {PpFaqControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpFaqPost(indexRequest: PpFaqControllerIndexRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpFaqControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpFaqPost(indexRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary FAQ表示順変更
         * @param {PpFaqControllerSortRequest} sortRequest SortRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpFaqSortPost(sortRequest: PpFaqControllerSortRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpFaqSortPost(sortRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpFaqApi - factory interface
 * @export
 */
export const AdminPpFaqApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpFaqApiFp(configuration)
    return {
        /**
         * 
         * @summary FAQ新規作成
         * @param {PpFaqControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqCreatePost(createRequest: PpFaqControllerCreateRequest, options?: any): AxiosPromise<PpFaqControllerGetResponse> {
            return localVarFp.v1AdminPpFaqCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary FAQ削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpFaqDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary FAQ編集
         * @param {string} id 編集対象のID
         * @param {PpFaqControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqEditIdPost(id: string, createRequest: PpFaqControllerCreateRequest, options?: any): AxiosPromise<PpFaqControllerGetResponse> {
            return localVarFp.v1AdminPpFaqEditIdPost(id, createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary FAQ一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqGetIdGet(id: string, options?: any): AxiosPromise<PpFaqControllerGetResponse> {
            return localVarFp.v1AdminPpFaqGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary FAQ一覧
         * @param {PpFaqControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqPost(indexRequest: PpFaqControllerIndexRequest, options?: any): AxiosPromise<PpFaqControllerIndexResponse> {
            return localVarFp.v1AdminPpFaqPost(indexRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary FAQ表示順変更
         * @param {PpFaqControllerSortRequest} sortRequest SortRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqSortPost(sortRequest: PpFaqControllerSortRequest, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpFaqSortPost(sortRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpFaqApi - object-oriented interface
 * @export
 * @class AdminPpFaqApi
 * @extends {BaseAPI}
 */
export class AdminPpFaqApi extends BaseAPI {
    /**
     * 
     * @summary FAQ新規作成
     * @param {PpFaqControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpFaqApi
     */
    public v1AdminPpFaqCreatePost(createRequest: PpFaqControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpFaqApiFp(this.configuration).v1AdminPpFaqCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary FAQ削除
     * @param {string} id 削除対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpFaqApi
     */
    public v1AdminPpFaqDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpFaqApiFp(this.configuration).v1AdminPpFaqDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary FAQ編集
     * @param {string} id 編集対象のID
     * @param {PpFaqControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpFaqApi
     */
    public v1AdminPpFaqEditIdPost(id: string, createRequest: PpFaqControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpFaqApiFp(this.configuration).v1AdminPpFaqEditIdPost(id, createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary FAQ一件取得
     * @param {string} id 取得対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpFaqApi
     */
    public v1AdminPpFaqGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpFaqApiFp(this.configuration).v1AdminPpFaqGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary FAQ一覧
     * @param {PpFaqControllerIndexRequest} indexRequest IndexRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpFaqApi
     */
    public v1AdminPpFaqPost(indexRequest: PpFaqControllerIndexRequest, options?: AxiosRequestConfig) {
        return AdminPpFaqApiFp(this.configuration).v1AdminPpFaqPost(indexRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary FAQ表示順変更
     * @param {PpFaqControllerSortRequest} sortRequest SortRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpFaqApi
     */
    public v1AdminPpFaqSortPost(sortRequest: PpFaqControllerSortRequest, options?: AxiosRequestConfig) {
        return AdminPpFaqApiFp(this.configuration).v1AdminPpFaqSortPost(sortRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpFaqCategoryApi - axios parameter creator
 * @export
 */
export const AdminPpFaqCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary FAQカテゴリー新規作成
         * @param {PpFaqCateogryControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqCategoryCreatePost: async (createRequest: PpFaqCateogryControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpFaqCategoryCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_faq_category/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary FAQカテゴリー削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqCategoryDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpFaqCategoryDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_faq_category/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary FAQカテゴリー編集
         * @param {string} id 編集対象のID
         * @param {PpFaqCateogryControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqCategoryEditIdPost: async (id: string, createRequest: PpFaqCateogryControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpFaqCategoryEditIdPost', 'id', id)
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpFaqCategoryEditIdPost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_faq_category/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary FAQカテゴリー一覧
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqCategoryGet: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_faq_category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary FAQカテゴリー一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqCategoryGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpFaqCategoryGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_faq_category/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpFaqCategoryApi - functional programming interface
 * @export
 */
export const AdminPpFaqCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpFaqCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary FAQカテゴリー新規作成
         * @param {PpFaqCateogryControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpFaqCategoryCreatePost(createRequest: PpFaqCateogryControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpFaqCateogryControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpFaqCategoryCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary FAQカテゴリー削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpFaqCategoryDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpFaqCategoryDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary FAQカテゴリー編集
         * @param {string} id 編集対象のID
         * @param {PpFaqCateogryControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpFaqCategoryEditIdPost(id: string, createRequest: PpFaqCateogryControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpFaqCateogryControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpFaqCategoryEditIdPost(id, createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary FAQカテゴリー一覧
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpFaqCategoryGet(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpFaqCateogryControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpFaqCategoryGet(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary FAQカテゴリー一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpFaqCategoryGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpFaqCateogryControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpFaqCategoryGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpFaqCategoryApi - factory interface
 * @export
 */
export const AdminPpFaqCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpFaqCategoryApiFp(configuration)
    return {
        /**
         * 
         * @summary FAQカテゴリー新規作成
         * @param {PpFaqCateogryControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqCategoryCreatePost(createRequest: PpFaqCateogryControllerCreateRequest, options?: any): AxiosPromise<PpFaqCateogryControllerGetResponse> {
            return localVarFp.v1AdminPpFaqCategoryCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary FAQカテゴリー削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqCategoryDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpFaqCategoryDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary FAQカテゴリー編集
         * @param {string} id 編集対象のID
         * @param {PpFaqCateogryControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqCategoryEditIdPost(id: string, createRequest: PpFaqCateogryControllerCreateRequest, options?: any): AxiosPromise<PpFaqCateogryControllerGetResponse> {
            return localVarFp.v1AdminPpFaqCategoryEditIdPost(id, createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary FAQカテゴリー一覧
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqCategoryGet(page?: number, size?: number, options?: any): AxiosPromise<PpFaqCateogryControllerIndexResponse> {
            return localVarFp.v1AdminPpFaqCategoryGet(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary FAQカテゴリー一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFaqCategoryGetIdGet(id: string, options?: any): AxiosPromise<PpFaqCateogryControllerGetResponse> {
            return localVarFp.v1AdminPpFaqCategoryGetIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpFaqCategoryApi - object-oriented interface
 * @export
 * @class AdminPpFaqCategoryApi
 * @extends {BaseAPI}
 */
export class AdminPpFaqCategoryApi extends BaseAPI {
    /**
     * 
     * @summary FAQカテゴリー新規作成
     * @param {PpFaqCateogryControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpFaqCategoryApi
     */
    public v1AdminPpFaqCategoryCreatePost(createRequest: PpFaqCateogryControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpFaqCategoryApiFp(this.configuration).v1AdminPpFaqCategoryCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary FAQカテゴリー削除
     * @param {string} id 削除対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpFaqCategoryApi
     */
    public v1AdminPpFaqCategoryDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpFaqCategoryApiFp(this.configuration).v1AdminPpFaqCategoryDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary FAQカテゴリー編集
     * @param {string} id 編集対象のID
     * @param {PpFaqCateogryControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpFaqCategoryApi
     */
    public v1AdminPpFaqCategoryEditIdPost(id: string, createRequest: PpFaqCateogryControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpFaqCategoryApiFp(this.configuration).v1AdminPpFaqCategoryEditIdPost(id, createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary FAQカテゴリー一覧
     * @param {number} [page] ページ番号
     * @param {number} [size] ページあたりの取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpFaqCategoryApi
     */
    public v1AdminPpFaqCategoryGet(page?: number, size?: number, options?: AxiosRequestConfig) {
        return AdminPpFaqCategoryApiFp(this.configuration).v1AdminPpFaqCategoryGet(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary FAQカテゴリー一件取得
     * @param {string} id 取得対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpFaqCategoryApi
     */
    public v1AdminPpFaqCategoryGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpFaqCategoryApiFp(this.configuration).v1AdminPpFaqCategoryGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpFlowcellApi - axios parameter creator
 * @export
 */
export const AdminPpFlowcellApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * URLで指定したFlow Cellの情報を返します。
         * @summary Flow Cell IDを指定してレコードを返す
         * @param {string} flowcellId Flow Cell ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFlowcellGetFlowcellIdGet: async (flowcellId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowcellId' is not null or undefined
            assertParamExists('v1AdminPpFlowcellGetFlowcellIdGet', 'flowcellId', flowcellId)
            const localVarPath = `/v1/admin/pp_flowcell/get/{flowcellId}`
                .replace(`{${"flowcellId"}}`, encodeURIComponent(String(flowcellId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpFlowcellApi - functional programming interface
 * @export
 */
export const AdminPpFlowcellApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpFlowcellApiAxiosParamCreator(configuration)
    return {
        /**
         * URLで指定したFlow Cellの情報を返します。
         * @summary Flow Cell IDを指定してレコードを返す
         * @param {string} flowcellId Flow Cell ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpFlowcellGetFlowcellIdGet(flowcellId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpFlowcellControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpFlowcellGetFlowcellIdGet(flowcellId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpFlowcellApi - factory interface
 * @export
 */
export const AdminPpFlowcellApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpFlowcellApiFp(configuration)
    return {
        /**
         * URLで指定したFlow Cellの情報を返します。
         * @summary Flow Cell IDを指定してレコードを返す
         * @param {string} flowcellId Flow Cell ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpFlowcellGetFlowcellIdGet(flowcellId: string, options?: any): AxiosPromise<PpFlowcellControllerGetResponse> {
            return localVarFp.v1AdminPpFlowcellGetFlowcellIdGet(flowcellId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpFlowcellApi - object-oriented interface
 * @export
 * @class AdminPpFlowcellApi
 * @extends {BaseAPI}
 */
export class AdminPpFlowcellApi extends BaseAPI {
    /**
     * URLで指定したFlow Cellの情報を返します。
     * @summary Flow Cell IDを指定してレコードを返す
     * @param {string} flowcellId Flow Cell ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpFlowcellApi
     */
    public v1AdminPpFlowcellGetFlowcellIdGet(flowcellId: string, options?: AxiosRequestConfig) {
        return AdminPpFlowcellApiFp(this.configuration).v1AdminPpFlowcellGetFlowcellIdGet(flowcellId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpMaintenanceApi - axios parameter creator
 * @export
 */
export const AdminPpMaintenanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary メンテナンス設定のクリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMaintenanceClearDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_maintenance/clear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メンテナンス設定の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMaintenanceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_maintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メンテナンス設定の追加
         * @param {PpMaintenanceControllerSetRequest} setRequest SetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMaintenanceSetPost: async (setRequest: PpMaintenanceControllerSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setRequest' is not null or undefined
            assertParamExists('v1AdminPpMaintenanceSetPost', 'setRequest', setRequest)
            const localVarPath = `/v1/admin/pp_maintenance/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpMaintenanceApi - functional programming interface
 * @export
 */
export const AdminPpMaintenanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpMaintenanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary メンテナンス設定のクリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMaintenanceClearDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMaintenanceClearDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary メンテナンス設定の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMaintenanceGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMaintenanceControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMaintenanceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary メンテナンス設定の追加
         * @param {PpMaintenanceControllerSetRequest} setRequest SetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMaintenanceSetPost(setRequest: PpMaintenanceControllerSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMaintenanceControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMaintenanceSetPost(setRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpMaintenanceApi - factory interface
 * @export
 */
export const AdminPpMaintenanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpMaintenanceApiFp(configuration)
    return {
        /**
         * 
         * @summary メンテナンス設定のクリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMaintenanceClearDelete(options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpMaintenanceClearDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メンテナンス設定の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMaintenanceGet(options?: any): AxiosPromise<PpMaintenanceControllerGetResponse> {
            return localVarFp.v1AdminPpMaintenanceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メンテナンス設定の追加
         * @param {PpMaintenanceControllerSetRequest} setRequest SetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMaintenanceSetPost(setRequest: PpMaintenanceControllerSetRequest, options?: any): AxiosPromise<PpMaintenanceControllerGetResponse> {
            return localVarFp.v1AdminPpMaintenanceSetPost(setRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpMaintenanceApi - object-oriented interface
 * @export
 * @class AdminPpMaintenanceApi
 * @extends {BaseAPI}
 */
export class AdminPpMaintenanceApi extends BaseAPI {
    /**
     * 
     * @summary メンテナンス設定のクリア
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMaintenanceApi
     */
    public v1AdminPpMaintenanceClearDelete(options?: AxiosRequestConfig) {
        return AdminPpMaintenanceApiFp(this.configuration).v1AdminPpMaintenanceClearDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メンテナンス設定の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMaintenanceApi
     */
    public v1AdminPpMaintenanceGet(options?: AxiosRequestConfig) {
        return AdminPpMaintenanceApiFp(this.configuration).v1AdminPpMaintenanceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メンテナンス設定の追加
     * @param {PpMaintenanceControllerSetRequest} setRequest SetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMaintenanceApi
     */
    public v1AdminPpMaintenanceSetPost(setRequest: PpMaintenanceControllerSetRequest, options?: AxiosRequestConfig) {
        return AdminPpMaintenanceApiFp(this.configuration).v1AdminPpMaintenanceSetPost(setRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpMstGenericRcmndGroupApi - axios parameter creator
 * @export
 */
export const AdminPpMstGenericRcmndGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 汎用リコメンドグループ新規登録
         * @param {PpMstGenericRcmndGroupControllerCreateRequest} createRequest PpMstGenericRcmndGroupControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndGroupCreatePost: async (createRequest: PpMstGenericRcmndGroupControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstGenericRcmndGroupCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_generic_rcmnd_group/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 汎用リコメンドグループ削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndGroupDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstGenericRcmndGroupDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_generic_rcmnd_group/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 汎用リコメンドグループ編集
         * @param {string} id 更新対象のID
         * @param {PpMstGenericRcmndGroupControllerCreateRequest} createRequest PpMstGenericRcmndGroupControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndGroupEditIdPost: async (id: string, createRequest: PpMstGenericRcmndGroupControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstGenericRcmndGroupEditIdPost', 'id', id)
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstGenericRcmndGroupEditIdPost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_generic_rcmnd_group/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 汎用リコメンドグループ一件取得
         * @param {string} id 取得対象のフードのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndGroupGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstGenericRcmndGroupGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_generic_rcmnd_group/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 汎用リコメンドグループリスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndGroupListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_mst_generic_rcmnd_group/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 汎用リコメンドグループ一覧
         * @param {PpMstGenericRcmndGroupControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndGroupPost: async (indexRequest: PpMstGenericRcmndGroupControllerIndexRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indexRequest' is not null or undefined
            assertParamExists('v1AdminPpMstGenericRcmndGroupPost', 'indexRequest', indexRequest)
            const localVarPath = `/v1/admin/pp_mst_generic_rcmnd_group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indexRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpMstGenericRcmndGroupApi - functional programming interface
 * @export
 */
export const AdminPpMstGenericRcmndGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpMstGenericRcmndGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 汎用リコメンドグループ新規登録
         * @param {PpMstGenericRcmndGroupControllerCreateRequest} createRequest PpMstGenericRcmndGroupControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstGenericRcmndGroupCreatePost(createRequest: PpMstGenericRcmndGroupControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstGenericRcmndGroupControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstGenericRcmndGroupCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 汎用リコメンドグループ削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstGenericRcmndGroupDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstGenericRcmndGroupDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 汎用リコメンドグループ編集
         * @param {string} id 更新対象のID
         * @param {PpMstGenericRcmndGroupControllerCreateRequest} createRequest PpMstGenericRcmndGroupControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstGenericRcmndGroupEditIdPost(id: string, createRequest: PpMstGenericRcmndGroupControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstGenericRcmndGroupControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstGenericRcmndGroupEditIdPost(id, createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 汎用リコメンドグループ一件取得
         * @param {string} id 取得対象のフードのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstGenericRcmndGroupGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstGenericRcmndGroupControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstGenericRcmndGroupGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 汎用リコメンドグループリスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstGenericRcmndGroupListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstGenericRcmndGroupControllerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstGenericRcmndGroupListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 汎用リコメンドグループ一覧
         * @param {PpMstGenericRcmndGroupControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstGenericRcmndGroupPost(indexRequest: PpMstGenericRcmndGroupControllerIndexRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstGenericRcmndGroupControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstGenericRcmndGroupPost(indexRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpMstGenericRcmndGroupApi - factory interface
 * @export
 */
export const AdminPpMstGenericRcmndGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpMstGenericRcmndGroupApiFp(configuration)
    return {
        /**
         * 
         * @summary 汎用リコメンドグループ新規登録
         * @param {PpMstGenericRcmndGroupControllerCreateRequest} createRequest PpMstGenericRcmndGroupControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndGroupCreatePost(createRequest: PpMstGenericRcmndGroupControllerCreateRequest, options?: any): AxiosPromise<PpMstGenericRcmndGroupControllerGetResponse> {
            return localVarFp.v1AdminPpMstGenericRcmndGroupCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 汎用リコメンドグループ削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndGroupDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpMstGenericRcmndGroupDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 汎用リコメンドグループ編集
         * @param {string} id 更新対象のID
         * @param {PpMstGenericRcmndGroupControllerCreateRequest} createRequest PpMstGenericRcmndGroupControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndGroupEditIdPost(id: string, createRequest: PpMstGenericRcmndGroupControllerCreateRequest, options?: any): AxiosPromise<PpMstGenericRcmndGroupControllerGetResponse> {
            return localVarFp.v1AdminPpMstGenericRcmndGroupEditIdPost(id, createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 汎用リコメンドグループ一件取得
         * @param {string} id 取得対象のフードのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndGroupGetIdGet(id: string, options?: any): AxiosPromise<PpMstGenericRcmndGroupControllerGetResponse> {
            return localVarFp.v1AdminPpMstGenericRcmndGroupGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 汎用リコメンドグループリスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndGroupListGet(options?: any): AxiosPromise<PpMstGenericRcmndGroupControllerListResponse> {
            return localVarFp.v1AdminPpMstGenericRcmndGroupListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 汎用リコメンドグループ一覧
         * @param {PpMstGenericRcmndGroupControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndGroupPost(indexRequest: PpMstGenericRcmndGroupControllerIndexRequest, options?: any): AxiosPromise<PpMstGenericRcmndGroupControllerIndexResponse> {
            return localVarFp.v1AdminPpMstGenericRcmndGroupPost(indexRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpMstGenericRcmndGroupApi - object-oriented interface
 * @export
 * @class AdminPpMstGenericRcmndGroupApi
 * @extends {BaseAPI}
 */
export class AdminPpMstGenericRcmndGroupApi extends BaseAPI {
    /**
     * 
     * @summary 汎用リコメンドグループ新規登録
     * @param {PpMstGenericRcmndGroupControllerCreateRequest} createRequest PpMstGenericRcmndGroupControllerCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstGenericRcmndGroupApi
     */
    public v1AdminPpMstGenericRcmndGroupCreatePost(createRequest: PpMstGenericRcmndGroupControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstGenericRcmndGroupApiFp(this.configuration).v1AdminPpMstGenericRcmndGroupCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 汎用リコメンドグループ削除
     * @param {string} id 削除対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstGenericRcmndGroupApi
     */
    public v1AdminPpMstGenericRcmndGroupDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstGenericRcmndGroupApiFp(this.configuration).v1AdminPpMstGenericRcmndGroupDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 汎用リコメンドグループ編集
     * @param {string} id 更新対象のID
     * @param {PpMstGenericRcmndGroupControllerCreateRequest} createRequest PpMstGenericRcmndGroupControllerCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstGenericRcmndGroupApi
     */
    public v1AdminPpMstGenericRcmndGroupEditIdPost(id: string, createRequest: PpMstGenericRcmndGroupControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstGenericRcmndGroupApiFp(this.configuration).v1AdminPpMstGenericRcmndGroupEditIdPost(id, createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 汎用リコメンドグループ一件取得
     * @param {string} id 取得対象のフードのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstGenericRcmndGroupApi
     */
    public v1AdminPpMstGenericRcmndGroupGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstGenericRcmndGroupApiFp(this.configuration).v1AdminPpMstGenericRcmndGroupGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 汎用リコメンドグループリスト取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstGenericRcmndGroupApi
     */
    public v1AdminPpMstGenericRcmndGroupListGet(options?: AxiosRequestConfig) {
        return AdminPpMstGenericRcmndGroupApiFp(this.configuration).v1AdminPpMstGenericRcmndGroupListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 汎用リコメンドグループ一覧
     * @param {PpMstGenericRcmndGroupControllerIndexRequest} indexRequest IndexRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstGenericRcmndGroupApi
     */
    public v1AdminPpMstGenericRcmndGroupPost(indexRequest: PpMstGenericRcmndGroupControllerIndexRequest, options?: AxiosRequestConfig) {
        return AdminPpMstGenericRcmndGroupApiFp(this.configuration).v1AdminPpMstGenericRcmndGroupPost(indexRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpMstGenericRcmndImageApi - axios parameter creator
 * @export
 */
export const AdminPpMstGenericRcmndImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 汎用リコメンドイメージ新規登録
         * @param {PpMstGenericRcmndImageControllerCreateRequest} createRequest PpMstGenericRcmndImageControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndImageCreatePost: async (createRequest: PpMstGenericRcmndImageControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstGenericRcmndImageCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_generic_rcmnd_image/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 汎用リコメンドイメージ削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndImageDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstGenericRcmndImageDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_generic_rcmnd_image/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 汎用リコメンドイメージ編集
         * @param {string} id 更新対象のID
         * @param {PpMstGenericRcmndImageControllerCreateRequest} createRequest PpMstGenericRcmndImageControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndImageEditIdPost: async (id: string, createRequest: PpMstGenericRcmndImageControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstGenericRcmndImageEditIdPost', 'id', id)
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstGenericRcmndImageEditIdPost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_generic_rcmnd_image/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 汎用リコメンドイメージ一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndImageGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstGenericRcmndImageGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_generic_rcmnd_image/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 汎用リコメンドイメージ一覧
         * @param {PpMstGenericRcmndImageControllerIndexRequest} indexRequest PpMstGenericRcmndImageControllerIndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndImagePost: async (indexRequest: PpMstGenericRcmndImageControllerIndexRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indexRequest' is not null or undefined
            assertParamExists('v1AdminPpMstGenericRcmndImagePost', 'indexRequest', indexRequest)
            const localVarPath = `/v1/admin/pp_mst_generic_rcmnd_image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indexRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpMstGenericRcmndImageApi - functional programming interface
 * @export
 */
export const AdminPpMstGenericRcmndImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpMstGenericRcmndImageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 汎用リコメンドイメージ新規登録
         * @param {PpMstGenericRcmndImageControllerCreateRequest} createRequest PpMstGenericRcmndImageControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstGenericRcmndImageCreatePost(createRequest: PpMstGenericRcmndImageControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstGenericRcmndImageControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstGenericRcmndImageCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 汎用リコメンドイメージ削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstGenericRcmndImageDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstGenericRcmndImageDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 汎用リコメンドイメージ編集
         * @param {string} id 更新対象のID
         * @param {PpMstGenericRcmndImageControllerCreateRequest} createRequest PpMstGenericRcmndImageControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstGenericRcmndImageEditIdPost(id: string, createRequest: PpMstGenericRcmndImageControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstGenericRcmndImageControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstGenericRcmndImageEditIdPost(id, createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 汎用リコメンドイメージ一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstGenericRcmndImageGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstGenericRcmndImageControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstGenericRcmndImageGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 汎用リコメンドイメージ一覧
         * @param {PpMstGenericRcmndImageControllerIndexRequest} indexRequest PpMstGenericRcmndImageControllerIndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstGenericRcmndImagePost(indexRequest: PpMstGenericRcmndImageControllerIndexRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstGenericRcmndImageControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstGenericRcmndImagePost(indexRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpMstGenericRcmndImageApi - factory interface
 * @export
 */
export const AdminPpMstGenericRcmndImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpMstGenericRcmndImageApiFp(configuration)
    return {
        /**
         * 
         * @summary 汎用リコメンドイメージ新規登録
         * @param {PpMstGenericRcmndImageControllerCreateRequest} createRequest PpMstGenericRcmndImageControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndImageCreatePost(createRequest: PpMstGenericRcmndImageControllerCreateRequest, options?: any): AxiosPromise<PpMstGenericRcmndImageControllerGetResponse> {
            return localVarFp.v1AdminPpMstGenericRcmndImageCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 汎用リコメンドイメージ削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndImageDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpMstGenericRcmndImageDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 汎用リコメンドイメージ編集
         * @param {string} id 更新対象のID
         * @param {PpMstGenericRcmndImageControllerCreateRequest} createRequest PpMstGenericRcmndImageControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndImageEditIdPost(id: string, createRequest: PpMstGenericRcmndImageControllerCreateRequest, options?: any): AxiosPromise<PpMstGenericRcmndImageControllerGetResponse> {
            return localVarFp.v1AdminPpMstGenericRcmndImageEditIdPost(id, createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 汎用リコメンドイメージ一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndImageGetIdGet(id: string, options?: any): AxiosPromise<PpMstGenericRcmndImageControllerGetResponse> {
            return localVarFp.v1AdminPpMstGenericRcmndImageGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 汎用リコメンドイメージ一覧
         * @param {PpMstGenericRcmndImageControllerIndexRequest} indexRequest PpMstGenericRcmndImageControllerIndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstGenericRcmndImagePost(indexRequest: PpMstGenericRcmndImageControllerIndexRequest, options?: any): AxiosPromise<PpMstGenericRcmndImageControllerIndexResponse> {
            return localVarFp.v1AdminPpMstGenericRcmndImagePost(indexRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpMstGenericRcmndImageApi - object-oriented interface
 * @export
 * @class AdminPpMstGenericRcmndImageApi
 * @extends {BaseAPI}
 */
export class AdminPpMstGenericRcmndImageApi extends BaseAPI {
    /**
     * 
     * @summary 汎用リコメンドイメージ新規登録
     * @param {PpMstGenericRcmndImageControllerCreateRequest} createRequest PpMstGenericRcmndImageControllerCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstGenericRcmndImageApi
     */
    public v1AdminPpMstGenericRcmndImageCreatePost(createRequest: PpMstGenericRcmndImageControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstGenericRcmndImageApiFp(this.configuration).v1AdminPpMstGenericRcmndImageCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 汎用リコメンドイメージ削除
     * @param {string} id 削除対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstGenericRcmndImageApi
     */
    public v1AdminPpMstGenericRcmndImageDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstGenericRcmndImageApiFp(this.configuration).v1AdminPpMstGenericRcmndImageDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 汎用リコメンドイメージ編集
     * @param {string} id 更新対象のID
     * @param {PpMstGenericRcmndImageControllerCreateRequest} createRequest PpMstGenericRcmndImageControllerCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstGenericRcmndImageApi
     */
    public v1AdminPpMstGenericRcmndImageEditIdPost(id: string, createRequest: PpMstGenericRcmndImageControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstGenericRcmndImageApiFp(this.configuration).v1AdminPpMstGenericRcmndImageEditIdPost(id, createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 汎用リコメンドイメージ一件取得
     * @param {string} id 取得対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstGenericRcmndImageApi
     */
    public v1AdminPpMstGenericRcmndImageGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstGenericRcmndImageApiFp(this.configuration).v1AdminPpMstGenericRcmndImageGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 汎用リコメンドイメージ一覧
     * @param {PpMstGenericRcmndImageControllerIndexRequest} indexRequest PpMstGenericRcmndImageControllerIndexRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstGenericRcmndImageApi
     */
    public v1AdminPpMstGenericRcmndImagePost(indexRequest: PpMstGenericRcmndImageControllerIndexRequest, options?: AxiosRequestConfig) {
        return AdminPpMstGenericRcmndImageApiFp(this.configuration).v1AdminPpMstGenericRcmndImagePost(indexRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpMstIdentificationCdApi - axios parameter creator
 * @export
 */
export const AdminPpMstIdentificationCdApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 識別コード新規作成
         * @param {PpMstIdentificationCdControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstIdentificationCdCreatePost: async (createRequest: PpMstIdentificationCdControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstIdentificationCdCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_identification_cd/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 識別コード削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstIdentificationCdDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstIdentificationCdDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_identification_cd/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 識別コード編集
         * @param {string} id 取得対象のID
         * @param {PpMstIdentificationCdControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstIdentificationCdEditIdPost: async (id: string, createRequest: PpMstIdentificationCdControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstIdentificationCdEditIdPost', 'id', id)
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstIdentificationCdEditIdPost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_identification_cd/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 識別コード一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstIdentificationCdGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstIdentificationCdGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_identification_cd/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 識別コード一覧
         * @param {PpMstIdentificationCdControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstIdentificationCdPost: async (indexRequest: PpMstIdentificationCdControllerIndexRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indexRequest' is not null or undefined
            assertParamExists('v1AdminPpMstIdentificationCdPost', 'indexRequest', indexRequest)
            const localVarPath = `/v1/admin/pp_mst_identification_cd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indexRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpMstIdentificationCdApi - functional programming interface
 * @export
 */
export const AdminPpMstIdentificationCdApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpMstIdentificationCdApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 識別コード新規作成
         * @param {PpMstIdentificationCdControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstIdentificationCdCreatePost(createRequest: PpMstIdentificationCdControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstIdentificationCdControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstIdentificationCdCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 識別コード削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstIdentificationCdDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstIdentificationCdDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 識別コード編集
         * @param {string} id 取得対象のID
         * @param {PpMstIdentificationCdControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstIdentificationCdEditIdPost(id: string, createRequest: PpMstIdentificationCdControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstIdentificationCdControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstIdentificationCdEditIdPost(id, createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 識別コード一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstIdentificationCdGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstIdentificationCdControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstIdentificationCdGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 識別コード一覧
         * @param {PpMstIdentificationCdControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstIdentificationCdPost(indexRequest: PpMstIdentificationCdControllerIndexRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstIdentificationCdControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstIdentificationCdPost(indexRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpMstIdentificationCdApi - factory interface
 * @export
 */
export const AdminPpMstIdentificationCdApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpMstIdentificationCdApiFp(configuration)
    return {
        /**
         * 
         * @summary 識別コード新規作成
         * @param {PpMstIdentificationCdControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstIdentificationCdCreatePost(createRequest: PpMstIdentificationCdControllerCreateRequest, options?: any): AxiosPromise<PpMstIdentificationCdControllerGetResponse> {
            return localVarFp.v1AdminPpMstIdentificationCdCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 識別コード削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstIdentificationCdDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpMstIdentificationCdDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 識別コード編集
         * @param {string} id 取得対象のID
         * @param {PpMstIdentificationCdControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstIdentificationCdEditIdPost(id: string, createRequest: PpMstIdentificationCdControllerCreateRequest, options?: any): AxiosPromise<PpMstIdentificationCdControllerGetResponse> {
            return localVarFp.v1AdminPpMstIdentificationCdEditIdPost(id, createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 識別コード一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstIdentificationCdGetIdGet(id: string, options?: any): AxiosPromise<PpMstIdentificationCdControllerGetResponse> {
            return localVarFp.v1AdminPpMstIdentificationCdGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 識別コード一覧
         * @param {PpMstIdentificationCdControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstIdentificationCdPost(indexRequest: PpMstIdentificationCdControllerIndexRequest, options?: any): AxiosPromise<PpMstIdentificationCdControllerIndexResponse> {
            return localVarFp.v1AdminPpMstIdentificationCdPost(indexRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpMstIdentificationCdApi - object-oriented interface
 * @export
 * @class AdminPpMstIdentificationCdApi
 * @extends {BaseAPI}
 */
export class AdminPpMstIdentificationCdApi extends BaseAPI {
    /**
     * 
     * @summary 識別コード新規作成
     * @param {PpMstIdentificationCdControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstIdentificationCdApi
     */
    public v1AdminPpMstIdentificationCdCreatePost(createRequest: PpMstIdentificationCdControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstIdentificationCdApiFp(this.configuration).v1AdminPpMstIdentificationCdCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 識別コード削除
     * @param {string} id 削除対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstIdentificationCdApi
     */
    public v1AdminPpMstIdentificationCdDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstIdentificationCdApiFp(this.configuration).v1AdminPpMstIdentificationCdDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 識別コード編集
     * @param {string} id 取得対象のID
     * @param {PpMstIdentificationCdControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstIdentificationCdApi
     */
    public v1AdminPpMstIdentificationCdEditIdPost(id: string, createRequest: PpMstIdentificationCdControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstIdentificationCdApiFp(this.configuration).v1AdminPpMstIdentificationCdEditIdPost(id, createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 識別コード一件取得
     * @param {string} id 取得対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstIdentificationCdApi
     */
    public v1AdminPpMstIdentificationCdGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstIdentificationCdApiFp(this.configuration).v1AdminPpMstIdentificationCdGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 識別コード一覧
     * @param {PpMstIdentificationCdControllerIndexRequest} indexRequest IndexRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstIdentificationCdApi
     */
    public v1AdminPpMstIdentificationCdPost(indexRequest: PpMstIdentificationCdControllerIndexRequest, options?: AxiosRequestConfig) {
        return AdminPpMstIdentificationCdApiFp(this.configuration).v1AdminPpMstIdentificationCdPost(indexRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpMstMeatureCommentApi - axios parameter creator
 * @export
 */
export const AdminPpMstMeatureCommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 対策コメント新規作成
         * @param {PpMstMeatureCommentsControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstMeatureCommentCreatePost: async (createRequest: PpMstMeatureCommentsControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstMeatureCommentCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_meature_comment/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 対策コメント削除
         * @param {string} id 編集対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstMeatureCommentDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstMeatureCommentDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_meature_comment/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 編集時はカテゴリの変更は不可とする
         * @summary 対策コメント編集
         * @param {string} id 編集対象のID
         * @param {PpMstMeatureCommentsControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstMeatureCommentEditIdPost: async (id: string, createRequest: PpMstMeatureCommentsControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstMeatureCommentEditIdPost', 'id', id)
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstMeatureCommentEditIdPost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_meature_comment/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 対策コメント全件取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstMeatureCommentGetAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_mst_meature_comment/get-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 対策コメント一件取得
         * @param {string} id 編集対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstMeatureCommentGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstMeatureCommentGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_meature_comment/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 対策コメント一覧
         * @param {PpMstMeatureCommentModelPagingRequest} pagingRequest PpMstMeatureCommentModelPagingRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstMeatureCommentPost: async (pagingRequest: PpMstMeatureCommentModelPagingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pagingRequest' is not null or undefined
            assertParamExists('v1AdminPpMstMeatureCommentPost', 'pagingRequest', pagingRequest)
            const localVarPath = `/v1/admin/pp_mst_meature_comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pagingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpMstMeatureCommentApi - functional programming interface
 * @export
 */
export const AdminPpMstMeatureCommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpMstMeatureCommentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 対策コメント新規作成
         * @param {PpMstMeatureCommentsControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstMeatureCommentCreatePost(createRequest: PpMstMeatureCommentsControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstMeatureCommentsControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstMeatureCommentCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 対策コメント削除
         * @param {string} id 編集対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstMeatureCommentDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstMeatureCommentDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 編集時はカテゴリの変更は不可とする
         * @summary 対策コメント編集
         * @param {string} id 編集対象のID
         * @param {PpMstMeatureCommentsControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstMeatureCommentEditIdPost(id: string, createRequest: PpMstMeatureCommentsControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstMeatureCommentsControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstMeatureCommentEditIdPost(id, createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 対策コメント全件取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstMeatureCommentGetAllGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstMeatureCommentsControllerGetAllResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstMeatureCommentGetAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 対策コメント一件取得
         * @param {string} id 編集対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstMeatureCommentGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstMeatureCommentsControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstMeatureCommentGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 対策コメント一覧
         * @param {PpMstMeatureCommentModelPagingRequest} pagingRequest PpMstMeatureCommentModelPagingRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstMeatureCommentPost(pagingRequest: PpMstMeatureCommentModelPagingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstMeatureCommentsControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstMeatureCommentPost(pagingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpMstMeatureCommentApi - factory interface
 * @export
 */
export const AdminPpMstMeatureCommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpMstMeatureCommentApiFp(configuration)
    return {
        /**
         * 
         * @summary 対策コメント新規作成
         * @param {PpMstMeatureCommentsControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstMeatureCommentCreatePost(createRequest: PpMstMeatureCommentsControllerCreateRequest, options?: any): AxiosPromise<PpMstMeatureCommentsControllerGetResponse> {
            return localVarFp.v1AdminPpMstMeatureCommentCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 対策コメント削除
         * @param {string} id 編集対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstMeatureCommentDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpMstMeatureCommentDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 編集時はカテゴリの変更は不可とする
         * @summary 対策コメント編集
         * @param {string} id 編集対象のID
         * @param {PpMstMeatureCommentsControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstMeatureCommentEditIdPost(id: string, createRequest: PpMstMeatureCommentsControllerCreateRequest, options?: any): AxiosPromise<PpMstMeatureCommentsControllerGetResponse> {
            return localVarFp.v1AdminPpMstMeatureCommentEditIdPost(id, createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 対策コメント全件取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstMeatureCommentGetAllGet(options?: any): AxiosPromise<PpMstMeatureCommentsControllerGetAllResponse> {
            return localVarFp.v1AdminPpMstMeatureCommentGetAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 対策コメント一件取得
         * @param {string} id 編集対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstMeatureCommentGetIdGet(id: string, options?: any): AxiosPromise<PpMstMeatureCommentsControllerGetResponse> {
            return localVarFp.v1AdminPpMstMeatureCommentGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 対策コメント一覧
         * @param {PpMstMeatureCommentModelPagingRequest} pagingRequest PpMstMeatureCommentModelPagingRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstMeatureCommentPost(pagingRequest: PpMstMeatureCommentModelPagingRequest, options?: any): AxiosPromise<PpMstMeatureCommentsControllerIndexResponse> {
            return localVarFp.v1AdminPpMstMeatureCommentPost(pagingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpMstMeatureCommentApi - object-oriented interface
 * @export
 * @class AdminPpMstMeatureCommentApi
 * @extends {BaseAPI}
 */
export class AdminPpMstMeatureCommentApi extends BaseAPI {
    /**
     * 
     * @summary 対策コメント新規作成
     * @param {PpMstMeatureCommentsControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstMeatureCommentApi
     */
    public v1AdminPpMstMeatureCommentCreatePost(createRequest: PpMstMeatureCommentsControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstMeatureCommentApiFp(this.configuration).v1AdminPpMstMeatureCommentCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 対策コメント削除
     * @param {string} id 編集対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstMeatureCommentApi
     */
    public v1AdminPpMstMeatureCommentDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstMeatureCommentApiFp(this.configuration).v1AdminPpMstMeatureCommentDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 編集時はカテゴリの変更は不可とする
     * @summary 対策コメント編集
     * @param {string} id 編集対象のID
     * @param {PpMstMeatureCommentsControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstMeatureCommentApi
     */
    public v1AdminPpMstMeatureCommentEditIdPost(id: string, createRequest: PpMstMeatureCommentsControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstMeatureCommentApiFp(this.configuration).v1AdminPpMstMeatureCommentEditIdPost(id, createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 対策コメント全件取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstMeatureCommentApi
     */
    public v1AdminPpMstMeatureCommentGetAllGet(options?: AxiosRequestConfig) {
        return AdminPpMstMeatureCommentApiFp(this.configuration).v1AdminPpMstMeatureCommentGetAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 対策コメント一件取得
     * @param {string} id 編集対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstMeatureCommentApi
     */
    public v1AdminPpMstMeatureCommentGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstMeatureCommentApiFp(this.configuration).v1AdminPpMstMeatureCommentGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 対策コメント一覧
     * @param {PpMstMeatureCommentModelPagingRequest} pagingRequest PpMstMeatureCommentModelPagingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstMeatureCommentApi
     */
    public v1AdminPpMstMeatureCommentPost(pagingRequest: PpMstMeatureCommentModelPagingRequest, options?: AxiosRequestConfig) {
        return AdminPpMstMeatureCommentApiFp(this.configuration).v1AdminPpMstMeatureCommentPost(pagingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpMstPartnerInstApi - axios parameter creator
 * @export
 */
export const AdminPpMstPartnerInstApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 提携法人新規登録
         * @param {PpMstPartnerInstControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerInstCreatePost: async (createRequest: PpMstPartnerInstControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstPartnerInstCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_partner_inst/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 提携法人削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerInstDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstPartnerInstDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_partner_inst/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 提携法人編集
         * @param {string} id 更新対象のID
         * @param {PpMstPartnerInstControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerInstEditIdPost: async (id: string, editRequest: PpMstPartnerInstControllerEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstPartnerInstEditIdPost', 'id', id)
            // verify required parameter 'editRequest' is not null or undefined
            assertParamExists('v1AdminPpMstPartnerInstEditIdPost', 'editRequest', editRequest)
            const localVarPath = `/v1/admin/pp_mst_partner_inst/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 提携法人一覧
         * @param {string} [keyword] 
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerInstGet: async (keyword?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_mst_partner_inst`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 提携法人一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerInstGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstPartnerInstGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_partner_inst/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 提携法人リスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerInstListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_mst_partner_inst/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpMstPartnerInstApi - functional programming interface
 * @export
 */
export const AdminPpMstPartnerInstApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpMstPartnerInstApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 提携法人新規登録
         * @param {PpMstPartnerInstControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstPartnerInstCreatePost(createRequest: PpMstPartnerInstControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstPartnerInstControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstPartnerInstCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 提携法人削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstPartnerInstDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstPartnerInstDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 提携法人編集
         * @param {string} id 更新対象のID
         * @param {PpMstPartnerInstControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstPartnerInstEditIdPost(id: string, editRequest: PpMstPartnerInstControllerEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstPartnerInstControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstPartnerInstEditIdPost(id, editRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 提携法人一覧
         * @param {string} [keyword] 
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstPartnerInstGet(keyword?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstPartnerInstControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstPartnerInstGet(keyword, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 提携法人一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstPartnerInstGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstPartnerInstControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstPartnerInstGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 提携法人リスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstPartnerInstListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstPartnerInstControllerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstPartnerInstListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpMstPartnerInstApi - factory interface
 * @export
 */
export const AdminPpMstPartnerInstApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpMstPartnerInstApiFp(configuration)
    return {
        /**
         * 
         * @summary 提携法人新規登録
         * @param {PpMstPartnerInstControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerInstCreatePost(createRequest: PpMstPartnerInstControllerCreateRequest, options?: any): AxiosPromise<PpMstPartnerInstControllerGetResponse> {
            return localVarFp.v1AdminPpMstPartnerInstCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 提携法人削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerInstDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpMstPartnerInstDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 提携法人編集
         * @param {string} id 更新対象のID
         * @param {PpMstPartnerInstControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerInstEditIdPost(id: string, editRequest: PpMstPartnerInstControllerEditRequest, options?: any): AxiosPromise<PpMstPartnerInstControllerGetResponse> {
            return localVarFp.v1AdminPpMstPartnerInstEditIdPost(id, editRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 提携法人一覧
         * @param {string} [keyword] 
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerInstGet(keyword?: string, page?: number, size?: number, options?: any): AxiosPromise<PpMstPartnerInstControllerIndexResponse> {
            return localVarFp.v1AdminPpMstPartnerInstGet(keyword, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 提携法人一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerInstGetIdGet(id: string, options?: any): AxiosPromise<PpMstPartnerInstControllerGetResponse> {
            return localVarFp.v1AdminPpMstPartnerInstGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 提携法人リスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerInstListGet(options?: any): AxiosPromise<PpMstPartnerInstControllerListResponse> {
            return localVarFp.v1AdminPpMstPartnerInstListGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpMstPartnerInstApi - object-oriented interface
 * @export
 * @class AdminPpMstPartnerInstApi
 * @extends {BaseAPI}
 */
export class AdminPpMstPartnerInstApi extends BaseAPI {
    /**
     * 
     * @summary 提携法人新規登録
     * @param {PpMstPartnerInstControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstPartnerInstApi
     */
    public v1AdminPpMstPartnerInstCreatePost(createRequest: PpMstPartnerInstControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstPartnerInstApiFp(this.configuration).v1AdminPpMstPartnerInstCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 提携法人削除
     * @param {string} id 削除対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstPartnerInstApi
     */
    public v1AdminPpMstPartnerInstDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstPartnerInstApiFp(this.configuration).v1AdminPpMstPartnerInstDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 提携法人編集
     * @param {string} id 更新対象のID
     * @param {PpMstPartnerInstControllerEditRequest} editRequest EditRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstPartnerInstApi
     */
    public v1AdminPpMstPartnerInstEditIdPost(id: string, editRequest: PpMstPartnerInstControllerEditRequest, options?: AxiosRequestConfig) {
        return AdminPpMstPartnerInstApiFp(this.configuration).v1AdminPpMstPartnerInstEditIdPost(id, editRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 提携法人一覧
     * @param {string} [keyword] 
     * @param {number} [page] ページ番号
     * @param {number} [size] ページあたりの取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstPartnerInstApi
     */
    public v1AdminPpMstPartnerInstGet(keyword?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AdminPpMstPartnerInstApiFp(this.configuration).v1AdminPpMstPartnerInstGet(keyword, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 提携法人一件取得
     * @param {string} id 取得対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstPartnerInstApi
     */
    public v1AdminPpMstPartnerInstGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstPartnerInstApiFp(this.configuration).v1AdminPpMstPartnerInstGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 提携法人リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstPartnerInstApi
     */
    public v1AdminPpMstPartnerInstListGet(options?: AxiosRequestConfig) {
        return AdminPpMstPartnerInstApiFp(this.configuration).v1AdminPpMstPartnerInstListGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpMstPartnerSupplierTypeApi - axios parameter creator
 * @export
 */
export const AdminPpMstPartnerSupplierTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * URLで指定した主キーの営業取引種別を返します。 なお、このAPIは認証なしでアクセス可能です。
         * @summary 営業取引種別一件取得
         * @param {string} id 営業取引種別ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerSupplierTypeGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstPartnerSupplierTypeGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_partner_supplier_type/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 営業取引種別リスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerSupplierTypeListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_mst_partner_supplier_type/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpMstPartnerSupplierTypeApi - functional programming interface
 * @export
 */
export const AdminPpMstPartnerSupplierTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpMstPartnerSupplierTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * URLで指定した主キーの営業取引種別を返します。 なお、このAPIは認証なしでアクセス可能です。
         * @summary 営業取引種別一件取得
         * @param {string} id 営業取引種別ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstPartnerSupplierTypeGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstPartnerSupplierTypeControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstPartnerSupplierTypeGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 営業取引種別リスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstPartnerSupplierTypeListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstPartnerSupplierTypeControllerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstPartnerSupplierTypeListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpMstPartnerSupplierTypeApi - factory interface
 * @export
 */
export const AdminPpMstPartnerSupplierTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpMstPartnerSupplierTypeApiFp(configuration)
    return {
        /**
         * URLで指定した主キーの営業取引種別を返します。 なお、このAPIは認証なしでアクセス可能です。
         * @summary 営業取引種別一件取得
         * @param {string} id 営業取引種別ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerSupplierTypeGetIdGet(id: string, options?: any): AxiosPromise<PpMstPartnerSupplierTypeControllerGetResponse> {
            return localVarFp.v1AdminPpMstPartnerSupplierTypeGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 営業取引種別リスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstPartnerSupplierTypeListGet(options?: any): AxiosPromise<PpMstPartnerSupplierTypeControllerListResponse> {
            return localVarFp.v1AdminPpMstPartnerSupplierTypeListGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpMstPartnerSupplierTypeApi - object-oriented interface
 * @export
 * @class AdminPpMstPartnerSupplierTypeApi
 * @extends {BaseAPI}
 */
export class AdminPpMstPartnerSupplierTypeApi extends BaseAPI {
    /**
     * URLで指定した主キーの営業取引種別を返します。 なお、このAPIは認証なしでアクセス可能です。
     * @summary 営業取引種別一件取得
     * @param {string} id 営業取引種別ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstPartnerSupplierTypeApi
     */
    public v1AdminPpMstPartnerSupplierTypeGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstPartnerSupplierTypeApiFp(this.configuration).v1AdminPpMstPartnerSupplierTypeGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 営業取引種別リスト取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstPartnerSupplierTypeApi
     */
    public v1AdminPpMstPartnerSupplierTypeListGet(options?: AxiosRequestConfig) {
        return AdminPpMstPartnerSupplierTypeApiFp(this.configuration).v1AdminPpMstPartnerSupplierTypeListGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpMstRcmndMethodApi - axios parameter creator
 * @export
 */
export const AdminPpMstRcmndMethodApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary リコメンド方式新規登録
         * @param {PpMstRcmndMethodControllerCreateRequest} createRequest PpMstRcmndMethodControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndMethodCreatePost: async (createRequest: PpMstRcmndMethodControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndMethodCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_rcmnd_method/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary リコメンド方式削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndMethodDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndMethodDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_rcmnd_method/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary リコメンド方式編集
         * @param {string} id 更新対象のユーザーのID
         * @param {PpMstRcmndMethodControllerCreateRequest} editRequest PpMstRcmndMethodControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndMethodEditIdPost: async (id: string, editRequest: PpMstRcmndMethodControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndMethodEditIdPost', 'id', id)
            // verify required parameter 'editRequest' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndMethodEditIdPost', 'editRequest', editRequest)
            const localVarPath = `/v1/admin/pp_mst_rcmnd_method/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary リコメンド方式一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndMethodGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndMethodGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_rcmnd_method/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary リコメンド方式リスト取得
         * @param {string} [exclude] 0：表示なし、1：汎用のみを除外する場合1を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndMethodListGet: async (exclude?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_mst_rcmnd_method/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (exclude !== undefined) {
                localVarQueryParameter['exclude'] = exclude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary リコメンド方式一覧
         * @param {PpMstRcmndMethodControllerIndexRequest} indexRequest PpMstRcmndMethodControllerIndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndMethodPost: async (indexRequest: PpMstRcmndMethodControllerIndexRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indexRequest' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndMethodPost', 'indexRequest', indexRequest)
            const localVarPath = `/v1/admin/pp_mst_rcmnd_method`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indexRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpMstRcmndMethodApi - functional programming interface
 * @export
 */
export const AdminPpMstRcmndMethodApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpMstRcmndMethodApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary リコメンド方式新規登録
         * @param {PpMstRcmndMethodControllerCreateRequest} createRequest PpMstRcmndMethodControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndMethodCreatePost(createRequest: PpMstRcmndMethodControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstRcmndMethodControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndMethodCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary リコメンド方式削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndMethodDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndMethodDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary リコメンド方式編集
         * @param {string} id 更新対象のユーザーのID
         * @param {PpMstRcmndMethodControllerCreateRequest} editRequest PpMstRcmndMethodControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndMethodEditIdPost(id: string, editRequest: PpMstRcmndMethodControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstRcmndMethodControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndMethodEditIdPost(id, editRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary リコメンド方式一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndMethodGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstRcmndMethodControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndMethodGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary リコメンド方式リスト取得
         * @param {string} [exclude] 0：表示なし、1：汎用のみを除外する場合1を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndMethodListGet(exclude?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstRcmndMethodControllerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndMethodListGet(exclude, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary リコメンド方式一覧
         * @param {PpMstRcmndMethodControllerIndexRequest} indexRequest PpMstRcmndMethodControllerIndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndMethodPost(indexRequest: PpMstRcmndMethodControllerIndexRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstRcmndMethodControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndMethodPost(indexRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpMstRcmndMethodApi - factory interface
 * @export
 */
export const AdminPpMstRcmndMethodApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpMstRcmndMethodApiFp(configuration)
    return {
        /**
         * 
         * @summary リコメンド方式新規登録
         * @param {PpMstRcmndMethodControllerCreateRequest} createRequest PpMstRcmndMethodControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndMethodCreatePost(createRequest: PpMstRcmndMethodControllerCreateRequest, options?: any): AxiosPromise<PpMstRcmndMethodControllerGetResponse> {
            return localVarFp.v1AdminPpMstRcmndMethodCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary リコメンド方式削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndMethodDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpMstRcmndMethodDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary リコメンド方式編集
         * @param {string} id 更新対象のユーザーのID
         * @param {PpMstRcmndMethodControllerCreateRequest} editRequest PpMstRcmndMethodControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndMethodEditIdPost(id: string, editRequest: PpMstRcmndMethodControllerCreateRequest, options?: any): AxiosPromise<PpMstRcmndMethodControllerGetResponse> {
            return localVarFp.v1AdminPpMstRcmndMethodEditIdPost(id, editRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary リコメンド方式一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndMethodGetIdGet(id: string, options?: any): AxiosPromise<PpMstRcmndMethodControllerGetResponse> {
            return localVarFp.v1AdminPpMstRcmndMethodGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary リコメンド方式リスト取得
         * @param {string} [exclude] 0：表示なし、1：汎用のみを除外する場合1を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndMethodListGet(exclude?: string, options?: any): AxiosPromise<PpMstRcmndMethodControllerListResponse> {
            return localVarFp.v1AdminPpMstRcmndMethodListGet(exclude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary リコメンド方式一覧
         * @param {PpMstRcmndMethodControllerIndexRequest} indexRequest PpMstRcmndMethodControllerIndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndMethodPost(indexRequest: PpMstRcmndMethodControllerIndexRequest, options?: any): AxiosPromise<PpMstRcmndMethodControllerIndexResponse> {
            return localVarFp.v1AdminPpMstRcmndMethodPost(indexRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpMstRcmndMethodApi - object-oriented interface
 * @export
 * @class AdminPpMstRcmndMethodApi
 * @extends {BaseAPI}
 */
export class AdminPpMstRcmndMethodApi extends BaseAPI {
    /**
     * 
     * @summary リコメンド方式新規登録
     * @param {PpMstRcmndMethodControllerCreateRequest} createRequest PpMstRcmndMethodControllerCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndMethodApi
     */
    public v1AdminPpMstRcmndMethodCreatePost(createRequest: PpMstRcmndMethodControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndMethodApiFp(this.configuration).v1AdminPpMstRcmndMethodCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary リコメンド方式削除
     * @param {string} id 削除対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndMethodApi
     */
    public v1AdminPpMstRcmndMethodDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndMethodApiFp(this.configuration).v1AdminPpMstRcmndMethodDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary リコメンド方式編集
     * @param {string} id 更新対象のユーザーのID
     * @param {PpMstRcmndMethodControllerCreateRequest} editRequest PpMstRcmndMethodControllerCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndMethodApi
     */
    public v1AdminPpMstRcmndMethodEditIdPost(id: string, editRequest: PpMstRcmndMethodControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndMethodApiFp(this.configuration).v1AdminPpMstRcmndMethodEditIdPost(id, editRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary リコメンド方式一件取得
     * @param {string} id 取得対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndMethodApi
     */
    public v1AdminPpMstRcmndMethodGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndMethodApiFp(this.configuration).v1AdminPpMstRcmndMethodGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary リコメンド方式リスト取得
     * @param {string} [exclude] 0：表示なし、1：汎用のみを除外する場合1を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndMethodApi
     */
    public v1AdminPpMstRcmndMethodListGet(exclude?: string, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndMethodApiFp(this.configuration).v1AdminPpMstRcmndMethodListGet(exclude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary リコメンド方式一覧
     * @param {PpMstRcmndMethodControllerIndexRequest} indexRequest PpMstRcmndMethodControllerIndexRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndMethodApi
     */
    public v1AdminPpMstRcmndMethodPost(indexRequest: PpMstRcmndMethodControllerIndexRequest, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndMethodApiFp(this.configuration).v1AdminPpMstRcmndMethodPost(indexRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpMstRcmndProductApi - axios parameter creator
 * @export
 */
export const AdminPpMstRcmndProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary おすすめ商品新規登録
         * @param {PpMstRcmndProductControllerCreateRequest} createRequest PpMstRcmndProductControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductCreatePost: async (createRequest: PpMstRcmndProductControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndProductCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_rcmnd_product/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary おすすめ商品削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndProductDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_rcmnd_product/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary おすすめ商品編集
         * @param {string} id 更新対象のID
         * @param {PpMstRcmndProductControllerCreateRequest} editRequest PpMstRcmndProductControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductEditIdPost: async (id: string, editRequest: PpMstRcmndProductControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndProductEditIdPost', 'id', id)
            // verify required parameter 'editRequest' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndProductEditIdPost', 'editRequest', editRequest)
            const localVarPath = `/v1/admin/pp_mst_rcmnd_product/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary おすすめ商品一件取得
         * @param {string} id 取得対象のフードのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndProductGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_rcmnd_product/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary おすすめ商品一覧
         * @param {PpMstRcmndProductControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductPost: async (indexRequest: PpMstRcmndProductControllerIndexRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indexRequest' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndProductPost', 'indexRequest', indexRequest)
            const localVarPath = `/v1/admin/pp_mst_rcmnd_product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indexRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpMstRcmndProductApi - functional programming interface
 * @export
 */
export const AdminPpMstRcmndProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpMstRcmndProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary おすすめ商品新規登録
         * @param {PpMstRcmndProductControllerCreateRequest} createRequest PpMstRcmndProductControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndProductCreatePost(createRequest: PpMstRcmndProductControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstRcmndProductControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndProductCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary おすすめ商品削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndProductDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndProductDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary おすすめ商品編集
         * @param {string} id 更新対象のID
         * @param {PpMstRcmndProductControllerCreateRequest} editRequest PpMstRcmndProductControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndProductEditIdPost(id: string, editRequest: PpMstRcmndProductControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstRcmndProductControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndProductEditIdPost(id, editRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary おすすめ商品一件取得
         * @param {string} id 取得対象のフードのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndProductGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstRcmndProductControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndProductGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary おすすめ商品一覧
         * @param {PpMstRcmndProductControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndProductPost(indexRequest: PpMstRcmndProductControllerIndexRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstRcmndProductControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndProductPost(indexRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpMstRcmndProductApi - factory interface
 * @export
 */
export const AdminPpMstRcmndProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpMstRcmndProductApiFp(configuration)
    return {
        /**
         * 
         * @summary おすすめ商品新規登録
         * @param {PpMstRcmndProductControllerCreateRequest} createRequest PpMstRcmndProductControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductCreatePost(createRequest: PpMstRcmndProductControllerCreateRequest, options?: any): AxiosPromise<PpMstRcmndProductControllerGetResponse> {
            return localVarFp.v1AdminPpMstRcmndProductCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary おすすめ商品削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpMstRcmndProductDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary おすすめ商品編集
         * @param {string} id 更新対象のID
         * @param {PpMstRcmndProductControllerCreateRequest} editRequest PpMstRcmndProductControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductEditIdPost(id: string, editRequest: PpMstRcmndProductControllerCreateRequest, options?: any): AxiosPromise<PpMstRcmndProductControllerGetResponse> {
            return localVarFp.v1AdminPpMstRcmndProductEditIdPost(id, editRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary おすすめ商品一件取得
         * @param {string} id 取得対象のフードのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductGetIdGet(id: string, options?: any): AxiosPromise<PpMstRcmndProductControllerGetResponse> {
            return localVarFp.v1AdminPpMstRcmndProductGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary おすすめ商品一覧
         * @param {PpMstRcmndProductControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductPost(indexRequest: PpMstRcmndProductControllerIndexRequest, options?: any): AxiosPromise<PpMstRcmndProductControllerIndexResponse> {
            return localVarFp.v1AdminPpMstRcmndProductPost(indexRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpMstRcmndProductApi - object-oriented interface
 * @export
 * @class AdminPpMstRcmndProductApi
 * @extends {BaseAPI}
 */
export class AdminPpMstRcmndProductApi extends BaseAPI {
    /**
     * 
     * @summary おすすめ商品新規登録
     * @param {PpMstRcmndProductControllerCreateRequest} createRequest PpMstRcmndProductControllerCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndProductApi
     */
    public v1AdminPpMstRcmndProductCreatePost(createRequest: PpMstRcmndProductControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndProductApiFp(this.configuration).v1AdminPpMstRcmndProductCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary おすすめ商品削除
     * @param {string} id 削除対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndProductApi
     */
    public v1AdminPpMstRcmndProductDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndProductApiFp(this.configuration).v1AdminPpMstRcmndProductDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary おすすめ商品編集
     * @param {string} id 更新対象のID
     * @param {PpMstRcmndProductControllerCreateRequest} editRequest PpMstRcmndProductControllerCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndProductApi
     */
    public v1AdminPpMstRcmndProductEditIdPost(id: string, editRequest: PpMstRcmndProductControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndProductApiFp(this.configuration).v1AdminPpMstRcmndProductEditIdPost(id, editRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary おすすめ商品一件取得
     * @param {string} id 取得対象のフードのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndProductApi
     */
    public v1AdminPpMstRcmndProductGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndProductApiFp(this.configuration).v1AdminPpMstRcmndProductGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary おすすめ商品一覧
     * @param {PpMstRcmndProductControllerIndexRequest} indexRequest IndexRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndProductApi
     */
    public v1AdminPpMstRcmndProductPost(indexRequest: PpMstRcmndProductControllerIndexRequest, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndProductApiFp(this.configuration).v1AdminPpMstRcmndProductPost(indexRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpMstRcmndProductsTitleApi - axios parameter creator
 * @export
 */
export const AdminPpMstRcmndProductsTitleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 個別商品リコメンドタイトル新規登録
         * @param {PpMstRcmndProductsTitleControllerCreateRequest} createRequest PpMstRcmndProductsTitleControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductsTitleCreatePost: async (createRequest: PpMstRcmndProductsTitleControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndProductsTitleCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_rcmnd_products_title/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 個別商品リコメンドタイトル削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductsTitleDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndProductsTitleDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_rcmnd_products_title/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 個別商品リコメンドタイトル編集
         * @param {string} id 更新対象のID
         * @param {PpMstRcmndProductsTitleControllerCreateRequest} editRequest PpMstRcmndProductsTitleControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductsTitleEditIdPost: async (id: string, editRequest: PpMstRcmndProductsTitleControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndProductsTitleEditIdPost', 'id', id)
            // verify required parameter 'editRequest' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndProductsTitleEditIdPost', 'editRequest', editRequest)
            const localVarPath = `/v1/admin/pp_mst_rcmnd_products_title/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 個別商品リコメンドタイトル一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductsTitleGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndProductsTitleGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_rcmnd_products_title/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 個別商品リコメンドタイトル新規登録リスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductsTitleListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_mst_rcmnd_products_title/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 個別商品リコメンドタイトル一覧
         * @param {PpMstRcmndProductsTitleControllerIndexRequest} indexRequest PpMstRcmndProductsTitleControllerIndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductsTitlePost: async (indexRequest: PpMstRcmndProductsTitleControllerIndexRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indexRequest' is not null or undefined
            assertParamExists('v1AdminPpMstRcmndProductsTitlePost', 'indexRequest', indexRequest)
            const localVarPath = `/v1/admin/pp_mst_rcmnd_products_title`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indexRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpMstRcmndProductsTitleApi - functional programming interface
 * @export
 */
export const AdminPpMstRcmndProductsTitleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpMstRcmndProductsTitleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 個別商品リコメンドタイトル新規登録
         * @param {PpMstRcmndProductsTitleControllerCreateRequest} createRequest PpMstRcmndProductsTitleControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndProductsTitleCreatePost(createRequest: PpMstRcmndProductsTitleControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstRcmndProductsTitleControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndProductsTitleCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 個別商品リコメンドタイトル削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndProductsTitleDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndProductsTitleDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 個別商品リコメンドタイトル編集
         * @param {string} id 更新対象のID
         * @param {PpMstRcmndProductsTitleControllerCreateRequest} editRequest PpMstRcmndProductsTitleControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndProductsTitleEditIdPost(id: string, editRequest: PpMstRcmndProductsTitleControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstRcmndProductsTitleControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndProductsTitleEditIdPost(id, editRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 個別商品リコメンドタイトル一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndProductsTitleGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstRcmndProductsTitleControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndProductsTitleGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 個別商品リコメンドタイトル新規登録リスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndProductsTitleListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstRcmndProductsTitleControllerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndProductsTitleListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 個別商品リコメンドタイトル一覧
         * @param {PpMstRcmndProductsTitleControllerIndexRequest} indexRequest PpMstRcmndProductsTitleControllerIndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstRcmndProductsTitlePost(indexRequest: PpMstRcmndProductsTitleControllerIndexRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstRcmndProductsTitleControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstRcmndProductsTitlePost(indexRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpMstRcmndProductsTitleApi - factory interface
 * @export
 */
export const AdminPpMstRcmndProductsTitleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpMstRcmndProductsTitleApiFp(configuration)
    return {
        /**
         * 
         * @summary 個別商品リコメンドタイトル新規登録
         * @param {PpMstRcmndProductsTitleControllerCreateRequest} createRequest PpMstRcmndProductsTitleControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductsTitleCreatePost(createRequest: PpMstRcmndProductsTitleControllerCreateRequest, options?: any): AxiosPromise<PpMstRcmndProductsTitleControllerGetResponse> {
            return localVarFp.v1AdminPpMstRcmndProductsTitleCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 個別商品リコメンドタイトル削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductsTitleDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpMstRcmndProductsTitleDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 個別商品リコメンドタイトル編集
         * @param {string} id 更新対象のID
         * @param {PpMstRcmndProductsTitleControllerCreateRequest} editRequest PpMstRcmndProductsTitleControllerCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductsTitleEditIdPost(id: string, editRequest: PpMstRcmndProductsTitleControllerCreateRequest, options?: any): AxiosPromise<PpMstRcmndProductsTitleControllerGetResponse> {
            return localVarFp.v1AdminPpMstRcmndProductsTitleEditIdPost(id, editRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 個別商品リコメンドタイトル一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductsTitleGetIdGet(id: string, options?: any): AxiosPromise<PpMstRcmndProductsTitleControllerGetResponse> {
            return localVarFp.v1AdminPpMstRcmndProductsTitleGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 個別商品リコメンドタイトル新規登録リスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductsTitleListGet(options?: any): AxiosPromise<PpMstRcmndProductsTitleControllerListResponse> {
            return localVarFp.v1AdminPpMstRcmndProductsTitleListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 個別商品リコメンドタイトル一覧
         * @param {PpMstRcmndProductsTitleControllerIndexRequest} indexRequest PpMstRcmndProductsTitleControllerIndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstRcmndProductsTitlePost(indexRequest: PpMstRcmndProductsTitleControllerIndexRequest, options?: any): AxiosPromise<PpMstRcmndProductsTitleControllerIndexResponse> {
            return localVarFp.v1AdminPpMstRcmndProductsTitlePost(indexRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpMstRcmndProductsTitleApi - object-oriented interface
 * @export
 * @class AdminPpMstRcmndProductsTitleApi
 * @extends {BaseAPI}
 */
export class AdminPpMstRcmndProductsTitleApi extends BaseAPI {
    /**
     * 
     * @summary 個別商品リコメンドタイトル新規登録
     * @param {PpMstRcmndProductsTitleControllerCreateRequest} createRequest PpMstRcmndProductsTitleControllerCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndProductsTitleApi
     */
    public v1AdminPpMstRcmndProductsTitleCreatePost(createRequest: PpMstRcmndProductsTitleControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndProductsTitleApiFp(this.configuration).v1AdminPpMstRcmndProductsTitleCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 個別商品リコメンドタイトル削除
     * @param {string} id 削除対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndProductsTitleApi
     */
    public v1AdminPpMstRcmndProductsTitleDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndProductsTitleApiFp(this.configuration).v1AdminPpMstRcmndProductsTitleDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 個別商品リコメンドタイトル編集
     * @param {string} id 更新対象のID
     * @param {PpMstRcmndProductsTitleControllerCreateRequest} editRequest PpMstRcmndProductsTitleControllerCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndProductsTitleApi
     */
    public v1AdminPpMstRcmndProductsTitleEditIdPost(id: string, editRequest: PpMstRcmndProductsTitleControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndProductsTitleApiFp(this.configuration).v1AdminPpMstRcmndProductsTitleEditIdPost(id, editRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 個別商品リコメンドタイトル一件取得
     * @param {string} id 取得対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndProductsTitleApi
     */
    public v1AdminPpMstRcmndProductsTitleGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndProductsTitleApiFp(this.configuration).v1AdminPpMstRcmndProductsTitleGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 個別商品リコメンドタイトル新規登録リスト取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndProductsTitleApi
     */
    public v1AdminPpMstRcmndProductsTitleListGet(options?: AxiosRequestConfig) {
        return AdminPpMstRcmndProductsTitleApiFp(this.configuration).v1AdminPpMstRcmndProductsTitleListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 個別商品リコメンドタイトル一覧
     * @param {PpMstRcmndProductsTitleControllerIndexRequest} indexRequest PpMstRcmndProductsTitleControllerIndexRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstRcmndProductsTitleApi
     */
    public v1AdminPpMstRcmndProductsTitlePost(indexRequest: PpMstRcmndProductsTitleControllerIndexRequest, options?: AxiosRequestConfig) {
        return AdminPpMstRcmndProductsTitleApiFp(this.configuration).v1AdminPpMstRcmndProductsTitlePost(indexRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpMstSkuApi - axios parameter creator
 * @export
 */
export const AdminPpMstSkuApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary SKU新規登録
         * @param {PpMstSkuControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstSkuCreatePost: async (createRequest: PpMstSkuControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstSkuCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_sku/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary SKU削除
         * @param {string} id 削除対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstSkuDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstSkuDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_sku/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary SKU編集
         * @param {string} id 更新対象のユーザーのID
         * @param {PpMstSkuControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstSkuEditIdPost: async (id: string, editRequest: PpMstSkuControllerEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstSkuEditIdPost', 'id', id)
            // verify required parameter 'editRequest' is not null or undefined
            assertParamExists('v1AdminPpMstSkuEditIdPost', 'editRequest', editRequest)
            const localVarPath = `/v1/admin/pp_mst_sku/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary SKU一覧
         * @param {string} [keyword] 
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstSkuGet: async (keyword?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_mst_sku`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary SKU一件取得
         * @param {string} id 取得対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstSkuGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstSkuGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_sku/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary SKUリスト取得
         * @param {PpMstSkuControllerListRequest} listRequest PpMstSkuControllerListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstSkuListPost: async (listRequest: PpMstSkuControllerListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listRequest' is not null or undefined
            assertParamExists('v1AdminPpMstSkuListPost', 'listRequest', listRequest)
            const localVarPath = `/v1/admin/pp_mst_sku/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpMstSkuApi - functional programming interface
 * @export
 */
export const AdminPpMstSkuApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpMstSkuApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary SKU新規登録
         * @param {PpMstSkuControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstSkuCreatePost(createRequest: PpMstSkuControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstSkuControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstSkuCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary SKU削除
         * @param {string} id 削除対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstSkuDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstSkuDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary SKU編集
         * @param {string} id 更新対象のユーザーのID
         * @param {PpMstSkuControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstSkuEditIdPost(id: string, editRequest: PpMstSkuControllerEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstSkuControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstSkuEditIdPost(id, editRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary SKU一覧
         * @param {string} [keyword] 
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstSkuGet(keyword?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstSkuControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstSkuGet(keyword, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary SKU一件取得
         * @param {string} id 取得対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstSkuGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstSkuControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstSkuGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary SKUリスト取得
         * @param {PpMstSkuControllerListRequest} listRequest PpMstSkuControllerListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstSkuListPost(listRequest: PpMstSkuControllerListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstSkuControllerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstSkuListPost(listRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpMstSkuApi - factory interface
 * @export
 */
export const AdminPpMstSkuApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpMstSkuApiFp(configuration)
    return {
        /**
         * 
         * @summary SKU新規登録
         * @param {PpMstSkuControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstSkuCreatePost(createRequest: PpMstSkuControllerCreateRequest, options?: any): AxiosPromise<PpMstSkuControllerGetResponse> {
            return localVarFp.v1AdminPpMstSkuCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary SKU削除
         * @param {string} id 削除対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstSkuDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpMstSkuDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary SKU編集
         * @param {string} id 更新対象のユーザーのID
         * @param {PpMstSkuControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstSkuEditIdPost(id: string, editRequest: PpMstSkuControllerEditRequest, options?: any): AxiosPromise<PpMstSkuControllerGetResponse> {
            return localVarFp.v1AdminPpMstSkuEditIdPost(id, editRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary SKU一覧
         * @param {string} [keyword] 
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstSkuGet(keyword?: string, page?: number, size?: number, options?: any): AxiosPromise<PpMstSkuControllerIndexResponse> {
            return localVarFp.v1AdminPpMstSkuGet(keyword, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary SKU一件取得
         * @param {string} id 取得対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstSkuGetIdGet(id: string, options?: any): AxiosPromise<PpMstSkuControllerGetResponse> {
            return localVarFp.v1AdminPpMstSkuGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary SKUリスト取得
         * @param {PpMstSkuControllerListRequest} listRequest PpMstSkuControllerListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstSkuListPost(listRequest: PpMstSkuControllerListRequest, options?: any): AxiosPromise<PpMstSkuControllerListResponse> {
            return localVarFp.v1AdminPpMstSkuListPost(listRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpMstSkuApi - object-oriented interface
 * @export
 * @class AdminPpMstSkuApi
 * @extends {BaseAPI}
 */
export class AdminPpMstSkuApi extends BaseAPI {
    /**
     * 
     * @summary SKU新規登録
     * @param {PpMstSkuControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstSkuApi
     */
    public v1AdminPpMstSkuCreatePost(createRequest: PpMstSkuControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstSkuApiFp(this.configuration).v1AdminPpMstSkuCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary SKU削除
     * @param {string} id 削除対象のユーザーのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstSkuApi
     */
    public v1AdminPpMstSkuDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstSkuApiFp(this.configuration).v1AdminPpMstSkuDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary SKU編集
     * @param {string} id 更新対象のユーザーのID
     * @param {PpMstSkuControllerEditRequest} editRequest EditRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstSkuApi
     */
    public v1AdminPpMstSkuEditIdPost(id: string, editRequest: PpMstSkuControllerEditRequest, options?: AxiosRequestConfig) {
        return AdminPpMstSkuApiFp(this.configuration).v1AdminPpMstSkuEditIdPost(id, editRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary SKU一覧
     * @param {string} [keyword] 
     * @param {number} [page] ページ番号
     * @param {number} [size] ページあたりの取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstSkuApi
     */
    public v1AdminPpMstSkuGet(keyword?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AdminPpMstSkuApiFp(this.configuration).v1AdminPpMstSkuGet(keyword, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary SKU一件取得
     * @param {string} id 取得対象のユーザーのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstSkuApi
     */
    public v1AdminPpMstSkuGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstSkuApiFp(this.configuration).v1AdminPpMstSkuGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary SKUリスト取得
     * @param {PpMstSkuControllerListRequest} listRequest PpMstSkuControllerListRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstSkuApi
     */
    public v1AdminPpMstSkuListPost(listRequest: PpMstSkuControllerListRequest, options?: AxiosRequestConfig) {
        return AdminPpMstSkuApiFp(this.configuration).v1AdminPpMstSkuListPost(listRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpMstTestKitApi - axios parameter creator
 * @export
 */
export const AdminPpMstTestKitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 検体ID情報一覧（マスター）
         * @param {PpMstTestKitControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstTestKitPost: async (indexRequest: PpMstTestKitControllerIndexRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indexRequest' is not null or undefined
            assertParamExists('v1AdminPpMstTestKitPost', 'indexRequest', indexRequest)
            const localVarPath = `/v1/admin/pp_mst_test_kit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indexRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エクセルファイルをアップロードして検体IDマスタ情報を登録します。<Br /> ヘッダーレコードは想定しておらず、一行目からデータとして扱われます。<Br /> アップロードしたデータに問題がある場合、登録は行われません。 A列 --> 検体ID<Br /> B列 --> パスワード<Br />
         * @summary 検体IDマスタ情報の登録
         * @param {File} file 登録対象のエクセルファイル(xlsx、xls)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstTestKitRegisterPost: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('v1AdminPpMstTestKitRegisterPost', 'file', file)
            const localVarPath = `/v1/admin/pp_mst_test_kit/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpMstTestKitApi - functional programming interface
 * @export
 */
export const AdminPpMstTestKitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpMstTestKitApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 検体ID情報一覧（マスター）
         * @param {PpMstTestKitControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstTestKitPost(indexRequest: PpMstTestKitControllerIndexRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstTestKitControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstTestKitPost(indexRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * エクセルファイルをアップロードして検体IDマスタ情報を登録します。<Br /> ヘッダーレコードは想定しておらず、一行目からデータとして扱われます。<Br /> アップロードしたデータに問題がある場合、登録は行われません。 A列 --> 検体ID<Br /> B列 --> パスワード<Br />
         * @summary 検体IDマスタ情報の登録
         * @param {File} file 登録対象のエクセルファイル(xlsx、xls)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstTestKitRegisterPost(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstTestKitRegisterPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpMstTestKitApi - factory interface
 * @export
 */
export const AdminPpMstTestKitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpMstTestKitApiFp(configuration)
    return {
        /**
         * 
         * @summary 検体ID情報一覧（マスター）
         * @param {PpMstTestKitControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstTestKitPost(indexRequest: PpMstTestKitControllerIndexRequest, options?: any): AxiosPromise<PpMstTestKitControllerIndexResponse> {
            return localVarFp.v1AdminPpMstTestKitPost(indexRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * エクセルファイルをアップロードして検体IDマスタ情報を登録します。<Br /> ヘッダーレコードは想定しておらず、一行目からデータとして扱われます。<Br /> アップロードしたデータに問題がある場合、登録は行われません。 A列 --> 検体ID<Br /> B列 --> パスワード<Br />
         * @summary 検体IDマスタ情報の登録
         * @param {File} file 登録対象のエクセルファイル(xlsx、xls)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstTestKitRegisterPost(file: File, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpMstTestKitRegisterPost(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpMstTestKitApi - object-oriented interface
 * @export
 * @class AdminPpMstTestKitApi
 * @extends {BaseAPI}
 */
export class AdminPpMstTestKitApi extends BaseAPI {
    /**
     * 
     * @summary 検体ID情報一覧（マスター）
     * @param {PpMstTestKitControllerIndexRequest} indexRequest IndexRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstTestKitApi
     */
    public v1AdminPpMstTestKitPost(indexRequest: PpMstTestKitControllerIndexRequest, options?: AxiosRequestConfig) {
        return AdminPpMstTestKitApiFp(this.configuration).v1AdminPpMstTestKitPost(indexRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エクセルファイルをアップロードして検体IDマスタ情報を登録します。<Br /> ヘッダーレコードは想定しておらず、一行目からデータとして扱われます。<Br /> アップロードしたデータに問題がある場合、登録は行われません。 A列 --> 検体ID<Br /> B列 --> パスワード<Br />
     * @summary 検体IDマスタ情報の登録
     * @param {File} file 登録対象のエクセルファイル(xlsx、xls)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstTestKitApi
     */
    public v1AdminPpMstTestKitRegisterPost(file: File, options?: AxiosRequestConfig) {
        return AdminPpMstTestKitApiFp(this.configuration).v1AdminPpMstTestKitRegisterPost(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpMstUserAdminApi - axios parameter creator
 * @export
 */
export const AdminPpMstUserAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 管理ユーザー新規登録
         * @param {PpMstUserAdminControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserAdminCreatePost: async (createRequest: PpMstUserAdminControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstUserAdminCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_user_admin/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理ユーザー削除
         * @param {string} id 削除対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserAdminDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstUserAdminDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_user_admin/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理ユーザー編集
         * @param {string} id 更新対象のユーザーのID
         * @param {PpMstUserAdminControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserAdminEditIdPost: async (id: string, editRequest: PpMstUserAdminControllerEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstUserAdminEditIdPost', 'id', id)
            // verify required parameter 'editRequest' is not null or undefined
            assertParamExists('v1AdminPpMstUserAdminEditIdPost', 'editRequest', editRequest)
            const localVarPath = `/v1/admin/pp_mst_user_admin/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理ユーザー一覧
         * @param {string} [keyword] 
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserAdminGet: async (keyword?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_mst_user_admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理ユーザー一件取得
         * @param {string} id 取得対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserAdminGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstUserAdminGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_user_admin/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpMstUserAdminApi - functional programming interface
 * @export
 */
export const AdminPpMstUserAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpMstUserAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 管理ユーザー新規登録
         * @param {PpMstUserAdminControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserAdminCreatePost(createRequest: PpMstUserAdminControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstUserAdminControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserAdminCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 管理ユーザー削除
         * @param {string} id 削除対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserAdminDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserAdminDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 管理ユーザー編集
         * @param {string} id 更新対象のユーザーのID
         * @param {PpMstUserAdminControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserAdminEditIdPost(id: string, editRequest: PpMstUserAdminControllerEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstUserAdminControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserAdminEditIdPost(id, editRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 管理ユーザー一覧
         * @param {string} [keyword] 
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserAdminGet(keyword?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstUserAdminControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserAdminGet(keyword, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 管理ユーザー一件取得
         * @param {string} id 取得対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserAdminGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstUserAdminControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserAdminGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpMstUserAdminApi - factory interface
 * @export
 */
export const AdminPpMstUserAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpMstUserAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary 管理ユーザー新規登録
         * @param {PpMstUserAdminControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserAdminCreatePost(createRequest: PpMstUserAdminControllerCreateRequest, options?: any): AxiosPromise<PpMstUserAdminControllerGetResponse> {
            return localVarFp.v1AdminPpMstUserAdminCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理ユーザー削除
         * @param {string} id 削除対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserAdminDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpMstUserAdminDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理ユーザー編集
         * @param {string} id 更新対象のユーザーのID
         * @param {PpMstUserAdminControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserAdminEditIdPost(id: string, editRequest: PpMstUserAdminControllerEditRequest, options?: any): AxiosPromise<PpMstUserAdminControllerGetResponse> {
            return localVarFp.v1AdminPpMstUserAdminEditIdPost(id, editRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理ユーザー一覧
         * @param {string} [keyword] 
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserAdminGet(keyword?: string, page?: number, size?: number, options?: any): AxiosPromise<PpMstUserAdminControllerIndexResponse> {
            return localVarFp.v1AdminPpMstUserAdminGet(keyword, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理ユーザー一件取得
         * @param {string} id 取得対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserAdminGetIdGet(id: string, options?: any): AxiosPromise<PpMstUserAdminControllerGetResponse> {
            return localVarFp.v1AdminPpMstUserAdminGetIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpMstUserAdminApi - object-oriented interface
 * @export
 * @class AdminPpMstUserAdminApi
 * @extends {BaseAPI}
 */
export class AdminPpMstUserAdminApi extends BaseAPI {
    /**
     * 
     * @summary 管理ユーザー新規登録
     * @param {PpMstUserAdminControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserAdminApi
     */
    public v1AdminPpMstUserAdminCreatePost(createRequest: PpMstUserAdminControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstUserAdminApiFp(this.configuration).v1AdminPpMstUserAdminCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理ユーザー削除
     * @param {string} id 削除対象のユーザーのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserAdminApi
     */
    public v1AdminPpMstUserAdminDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstUserAdminApiFp(this.configuration).v1AdminPpMstUserAdminDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理ユーザー編集
     * @param {string} id 更新対象のユーザーのID
     * @param {PpMstUserAdminControllerEditRequest} editRequest EditRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserAdminApi
     */
    public v1AdminPpMstUserAdminEditIdPost(id: string, editRequest: PpMstUserAdminControllerEditRequest, options?: AxiosRequestConfig) {
        return AdminPpMstUserAdminApiFp(this.configuration).v1AdminPpMstUserAdminEditIdPost(id, editRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理ユーザー一覧
     * @param {string} [keyword] 
     * @param {number} [page] ページ番号
     * @param {number} [size] ページあたりの取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserAdminApi
     */
    public v1AdminPpMstUserAdminGet(keyword?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AdminPpMstUserAdminApiFp(this.configuration).v1AdminPpMstUserAdminGet(keyword, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理ユーザー一件取得
     * @param {string} id 取得対象のユーザーのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserAdminApi
     */
    public v1AdminPpMstUserAdminGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstUserAdminApiFp(this.configuration).v1AdminPpMstUserAdminGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpMstUserMedicalApi - axios parameter creator
 * @export
 */
export const AdminPpMstUserMedicalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 病院新規登録
         * @param {PpMstUserMedicalControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserMedicalCreatePost: async (createRequest: PpMstUserMedicalControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstUserMedicalCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_user_medical/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 病院削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserMedicalDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstUserMedicalDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_user_medical/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 病院編集
         * @param {string} id 更新対象のID
         * @param {PpMstUserMedicalControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserMedicalEditIdPost: async (id: string, editRequest: PpMstUserMedicalControllerEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstUserMedicalEditIdPost', 'id', id)
            // verify required parameter 'editRequest' is not null or undefined
            assertParamExists('v1AdminPpMstUserMedicalEditIdPost', 'editRequest', editRequest)
            const localVarPath = `/v1/admin/pp_mst_user_medical/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 病院一覧
         * @param {string} [keyword] 
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserMedicalGet: async (keyword?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_mst_user_medical`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 病院一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserMedicalGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstUserMedicalGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_user_medical/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 病院リスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserMedicalListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_mst_user_medical/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpMstUserMedicalApi - functional programming interface
 * @export
 */
export const AdminPpMstUserMedicalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpMstUserMedicalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 病院新規登録
         * @param {PpMstUserMedicalControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserMedicalCreatePost(createRequest: PpMstUserMedicalControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstUserMedicalControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserMedicalCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 病院削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserMedicalDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserMedicalDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 病院編集
         * @param {string} id 更新対象のID
         * @param {PpMstUserMedicalControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserMedicalEditIdPost(id: string, editRequest: PpMstUserMedicalControllerEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstUserMedicalControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserMedicalEditIdPost(id, editRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 病院一覧
         * @param {string} [keyword] 
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserMedicalGet(keyword?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstUserMedicalControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserMedicalGet(keyword, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 病院一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserMedicalGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstUserMedicalControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserMedicalGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 病院リスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserMedicalListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstUserMedicalControllerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserMedicalListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpMstUserMedicalApi - factory interface
 * @export
 */
export const AdminPpMstUserMedicalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpMstUserMedicalApiFp(configuration)
    return {
        /**
         * 
         * @summary 病院新規登録
         * @param {PpMstUserMedicalControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserMedicalCreatePost(createRequest: PpMstUserMedicalControllerCreateRequest, options?: any): AxiosPromise<PpMstUserMedicalControllerGetResponse> {
            return localVarFp.v1AdminPpMstUserMedicalCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 病院削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserMedicalDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpMstUserMedicalDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 病院編集
         * @param {string} id 更新対象のID
         * @param {PpMstUserMedicalControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserMedicalEditIdPost(id: string, editRequest: PpMstUserMedicalControllerEditRequest, options?: any): AxiosPromise<PpMstUserMedicalControllerGetResponse> {
            return localVarFp.v1AdminPpMstUserMedicalEditIdPost(id, editRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 病院一覧
         * @param {string} [keyword] 
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserMedicalGet(keyword?: string, page?: number, size?: number, options?: any): AxiosPromise<PpMstUserMedicalControllerIndexResponse> {
            return localVarFp.v1AdminPpMstUserMedicalGet(keyword, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 病院一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserMedicalGetIdGet(id: string, options?: any): AxiosPromise<PpMstUserMedicalControllerGetResponse> {
            return localVarFp.v1AdminPpMstUserMedicalGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 病院リスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserMedicalListGet(options?: any): AxiosPromise<PpMstUserMedicalControllerListResponse> {
            return localVarFp.v1AdminPpMstUserMedicalListGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpMstUserMedicalApi - object-oriented interface
 * @export
 * @class AdminPpMstUserMedicalApi
 * @extends {BaseAPI}
 */
export class AdminPpMstUserMedicalApi extends BaseAPI {
    /**
     * 
     * @summary 病院新規登録
     * @param {PpMstUserMedicalControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserMedicalApi
     */
    public v1AdminPpMstUserMedicalCreatePost(createRequest: PpMstUserMedicalControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstUserMedicalApiFp(this.configuration).v1AdminPpMstUserMedicalCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 病院削除
     * @param {string} id 削除対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserMedicalApi
     */
    public v1AdminPpMstUserMedicalDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstUserMedicalApiFp(this.configuration).v1AdminPpMstUserMedicalDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 病院編集
     * @param {string} id 更新対象のID
     * @param {PpMstUserMedicalControllerEditRequest} editRequest EditRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserMedicalApi
     */
    public v1AdminPpMstUserMedicalEditIdPost(id: string, editRequest: PpMstUserMedicalControllerEditRequest, options?: AxiosRequestConfig) {
        return AdminPpMstUserMedicalApiFp(this.configuration).v1AdminPpMstUserMedicalEditIdPost(id, editRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 病院一覧
     * @param {string} [keyword] 
     * @param {number} [page] ページ番号
     * @param {number} [size] ページあたりの取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserMedicalApi
     */
    public v1AdminPpMstUserMedicalGet(keyword?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AdminPpMstUserMedicalApiFp(this.configuration).v1AdminPpMstUserMedicalGet(keyword, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 病院一件取得
     * @param {string} id 取得対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserMedicalApi
     */
    public v1AdminPpMstUserMedicalGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstUserMedicalApiFp(this.configuration).v1AdminPpMstUserMedicalGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 病院リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserMedicalApi
     */
    public v1AdminPpMstUserMedicalListGet(options?: AxiosRequestConfig) {
        return AdminPpMstUserMedicalApiFp(this.configuration).v1AdminPpMstUserMedicalListGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpMstUserPartnerApi - axios parameter creator
 * @export
 */
export const AdminPpMstUserPartnerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 提携機関ユーザー新規登録
         * @param {PpMstUserPartnerControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserPartnerCreatePost: async (createRequest: PpMstUserPartnerControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpMstUserPartnerCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_mst_user_partner/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 提携機関ユーザー削除
         * @param {string} id 削除対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserPartnerDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstUserPartnerDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_user_partner/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 提携機関ユーザー編集
         * @param {string} id 更新対象のユーザーのID
         * @param {PpMstUserPartnerControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserPartnerEditIdPost: async (id: string, editRequest: PpMstUserPartnerControllerEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstUserPartnerEditIdPost', 'id', id)
            // verify required parameter 'editRequest' is not null or undefined
            assertParamExists('v1AdminPpMstUserPartnerEditIdPost', 'editRequest', editRequest)
            const localVarPath = `/v1/admin/pp_mst_user_partner/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 提携機関ユーザー一覧
         * @param {string} [keyword] 
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserPartnerGet: async (keyword?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_mst_user_partner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 提携機関ユーザー一件取得
         * @param {string} id 取得対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserPartnerGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpMstUserPartnerGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_mst_user_partner/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpMstUserPartnerApi - functional programming interface
 * @export
 */
export const AdminPpMstUserPartnerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpMstUserPartnerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 提携機関ユーザー新規登録
         * @param {PpMstUserPartnerControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserPartnerCreatePost(createRequest: PpMstUserPartnerControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstUserPartnerControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserPartnerCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 提携機関ユーザー削除
         * @param {string} id 削除対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserPartnerDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserPartnerDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 提携機関ユーザー編集
         * @param {string} id 更新対象のユーザーのID
         * @param {PpMstUserPartnerControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserPartnerEditIdPost(id: string, editRequest: PpMstUserPartnerControllerEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstUserPartnerControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserPartnerEditIdPost(id, editRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 提携機関ユーザー一覧
         * @param {string} [keyword] 
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserPartnerGet(keyword?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstUserPartnerControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserPartnerGet(keyword, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 提携機関ユーザー一件取得
         * @param {string} id 取得対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpMstUserPartnerGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstUserPartnerControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpMstUserPartnerGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpMstUserPartnerApi - factory interface
 * @export
 */
export const AdminPpMstUserPartnerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpMstUserPartnerApiFp(configuration)
    return {
        /**
         * 
         * @summary 提携機関ユーザー新規登録
         * @param {PpMstUserPartnerControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserPartnerCreatePost(createRequest: PpMstUserPartnerControllerCreateRequest, options?: any): AxiosPromise<PpMstUserPartnerControllerGetResponse> {
            return localVarFp.v1AdminPpMstUserPartnerCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 提携機関ユーザー削除
         * @param {string} id 削除対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserPartnerDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpMstUserPartnerDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 提携機関ユーザー編集
         * @param {string} id 更新対象のユーザーのID
         * @param {PpMstUserPartnerControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserPartnerEditIdPost(id: string, editRequest: PpMstUserPartnerControllerEditRequest, options?: any): AxiosPromise<PpMstUserPartnerControllerGetResponse> {
            return localVarFp.v1AdminPpMstUserPartnerEditIdPost(id, editRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 提携機関ユーザー一覧
         * @param {string} [keyword] 
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserPartnerGet(keyword?: string, page?: number, size?: number, options?: any): AxiosPromise<PpMstUserPartnerControllerIndexResponse> {
            return localVarFp.v1AdminPpMstUserPartnerGet(keyword, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 提携機関ユーザー一件取得
         * @param {string} id 取得対象のユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpMstUserPartnerGetIdGet(id: string, options?: any): AxiosPromise<PpMstUserPartnerControllerGetResponse> {
            return localVarFp.v1AdminPpMstUserPartnerGetIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpMstUserPartnerApi - object-oriented interface
 * @export
 * @class AdminPpMstUserPartnerApi
 * @extends {BaseAPI}
 */
export class AdminPpMstUserPartnerApi extends BaseAPI {
    /**
     * 
     * @summary 提携機関ユーザー新規登録
     * @param {PpMstUserPartnerControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserPartnerApi
     */
    public v1AdminPpMstUserPartnerCreatePost(createRequest: PpMstUserPartnerControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpMstUserPartnerApiFp(this.configuration).v1AdminPpMstUserPartnerCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 提携機関ユーザー削除
     * @param {string} id 削除対象のユーザーのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserPartnerApi
     */
    public v1AdminPpMstUserPartnerDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstUserPartnerApiFp(this.configuration).v1AdminPpMstUserPartnerDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 提携機関ユーザー編集
     * @param {string} id 更新対象のユーザーのID
     * @param {PpMstUserPartnerControllerEditRequest} editRequest EditRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserPartnerApi
     */
    public v1AdminPpMstUserPartnerEditIdPost(id: string, editRequest: PpMstUserPartnerControllerEditRequest, options?: AxiosRequestConfig) {
        return AdminPpMstUserPartnerApiFp(this.configuration).v1AdminPpMstUserPartnerEditIdPost(id, editRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 提携機関ユーザー一覧
     * @param {string} [keyword] 
     * @param {number} [page] ページ番号
     * @param {number} [size] ページあたりの取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserPartnerApi
     */
    public v1AdminPpMstUserPartnerGet(keyword?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AdminPpMstUserPartnerApiFp(this.configuration).v1AdminPpMstUserPartnerGet(keyword, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 提携機関ユーザー一件取得
     * @param {string} id 取得対象のユーザーのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpMstUserPartnerApi
     */
    public v1AdminPpMstUserPartnerGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpMstUserPartnerApiFp(this.configuration).v1AdminPpMstUserPartnerGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpPetApi - axios parameter creator
 * @export
 */
export const AdminPpPetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ペット新規登録
         * @param {PpPetControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpPetCreatePost: async (createRequest: PpPetControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpPetCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_pet/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ペット削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpPetDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpPetDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_pet/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ペット情報編集
         * @param {string} id 更新対象のID
         * @param {PpPetControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpPetEditIdPost: async (id: string, editRequest: PpPetControllerEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpPetEditIdPost', 'id', id)
            // verify required parameter 'editRequest' is not null or undefined
            assertParamExists('v1AdminPpPetEditIdPost', 'editRequest', editRequest)
            const localVarPath = `/v1/admin/pp_pet/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ペット一覧
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpPetGet: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/pp_pet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ペット一件取得
         * @param {string} ppUserId 会員ID
         * @param {string} id ペットのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpPetGetPpUserIdIdGet: async (ppUserId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ppUserId' is not null or undefined
            assertParamExists('v1AdminPpPetGetPpUserIdIdGet', 'ppUserId', ppUserId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpPetGetPpUserIdIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_pet/get/{ppUserId}/{id}`
                .replace(`{${"ppUserId"}}`, encodeURIComponent(String(ppUserId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ペットIDから、申し込み完了した検査があるかをチェックします。 検査がある場合、種別の変更をできない仕様とします。
         * @summary 申し込み完了した検査があるかをチェック
         * @param {number} ppPetId ペットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpPetHasTestPpPetIdGet: async (ppPetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ppPetId' is not null or undefined
            assertParamExists('v1AdminPpPetHasTestPpPetIdGet', 'ppPetId', ppPetId)
            const localVarPath = `/v1/admin/pp_pet/has_test/{ppPetId}`
                .replace(`{${"ppPetId"}}`, encodeURIComponent(String(ppPetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary カルテID設定
         * @param {string} ppPetId 更新対象のペットID
         * @param {PpPetControllerSetKarteIDRequest} setKarteIDRequest PpPetControllerSetKarteIDRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpPetSetKarteIdPpPetIdPost: async (ppPetId: string, setKarteIDRequest: PpPetControllerSetKarteIDRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ppPetId' is not null or undefined
            assertParamExists('v1AdminPpPetSetKarteIdPpPetIdPost', 'ppPetId', ppPetId)
            // verify required parameter 'setKarteIDRequest' is not null or undefined
            assertParamExists('v1AdminPpPetSetKarteIdPpPetIdPost', 'setKarteIDRequest', setKarteIDRequest)
            const localVarPath = `/v1/admin/pp_pet/set_karte_id/{ppPetId}`
                .replace(`{${"ppPetId"}}`, encodeURIComponent(String(ppPetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setKarteIDRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpPetApi - functional programming interface
 * @export
 */
export const AdminPpPetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpPetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ペット新規登録
         * @param {PpPetControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpPetCreatePost(createRequest: PpPetControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpPetControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpPetCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ペット削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpPetDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpPetDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ペット情報編集
         * @param {string} id 更新対象のID
         * @param {PpPetControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpPetEditIdPost(id: string, editRequest: PpPetControllerEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpPetControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpPetEditIdPost(id, editRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ペット一覧
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpPetGet(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpPetControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpPetGet(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ペット一件取得
         * @param {string} ppUserId 会員ID
         * @param {string} id ペットのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpPetGetPpUserIdIdGet(ppUserId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpPetControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpPetGetPpUserIdIdGet(ppUserId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ペットIDから、申し込み完了した検査があるかをチェックします。 検査がある場合、種別の変更をできない仕様とします。
         * @summary 申し込み完了した検査があるかをチェック
         * @param {number} ppPetId ペットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpPetHasTestPpPetIdGet(ppPetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpPetControllerHasTestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpPetHasTestPpPetIdGet(ppPetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary カルテID設定
         * @param {string} ppPetId 更新対象のペットID
         * @param {PpPetControllerSetKarteIDRequest} setKarteIDRequest PpPetControllerSetKarteIDRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpPetSetKarteIdPpPetIdPost(ppPetId: string, setKarteIDRequest: PpPetControllerSetKarteIDRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpPetSetKarteIdPpPetIdPost(ppPetId, setKarteIDRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpPetApi - factory interface
 * @export
 */
export const AdminPpPetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpPetApiFp(configuration)
    return {
        /**
         * 
         * @summary ペット新規登録
         * @param {PpPetControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpPetCreatePost(createRequest: PpPetControllerCreateRequest, options?: any): AxiosPromise<PpPetControllerGetResponse> {
            return localVarFp.v1AdminPpPetCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ペット削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpPetDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpPetDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ペット情報編集
         * @param {string} id 更新対象のID
         * @param {PpPetControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpPetEditIdPost(id: string, editRequest: PpPetControllerEditRequest, options?: any): AxiosPromise<PpPetControllerGetResponse> {
            return localVarFp.v1AdminPpPetEditIdPost(id, editRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ペット一覧
         * @param {number} [page] ページ番号
         * @param {number} [size] ページあたりの取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpPetGet(page?: number, size?: number, options?: any): AxiosPromise<PpPetControllerIndexResponse> {
            return localVarFp.v1AdminPpPetGet(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ペット一件取得
         * @param {string} ppUserId 会員ID
         * @param {string} id ペットのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpPetGetPpUserIdIdGet(ppUserId: string, id: string, options?: any): AxiosPromise<PpPetControllerGetResponse> {
            return localVarFp.v1AdminPpPetGetPpUserIdIdGet(ppUserId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * ペットIDから、申し込み完了した検査があるかをチェックします。 検査がある場合、種別の変更をできない仕様とします。
         * @summary 申し込み完了した検査があるかをチェック
         * @param {number} ppPetId ペットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpPetHasTestPpPetIdGet(ppPetId: number, options?: any): AxiosPromise<PpPetControllerHasTestResponse> {
            return localVarFp.v1AdminPpPetHasTestPpPetIdGet(ppPetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary カルテID設定
         * @param {string} ppPetId 更新対象のペットID
         * @param {PpPetControllerSetKarteIDRequest} setKarteIDRequest PpPetControllerSetKarteIDRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpPetSetKarteIdPpPetIdPost(ppPetId: string, setKarteIDRequest: PpPetControllerSetKarteIDRequest, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpPetSetKarteIdPpPetIdPost(ppPetId, setKarteIDRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpPetApi - object-oriented interface
 * @export
 * @class AdminPpPetApi
 * @extends {BaseAPI}
 */
export class AdminPpPetApi extends BaseAPI {
    /**
     * 
     * @summary ペット新規登録
     * @param {PpPetControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpPetApi
     */
    public v1AdminPpPetCreatePost(createRequest: PpPetControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpPetApiFp(this.configuration).v1AdminPpPetCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ペット削除
     * @param {string} id 削除対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpPetApi
     */
    public v1AdminPpPetDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpPetApiFp(this.configuration).v1AdminPpPetDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ペット情報編集
     * @param {string} id 更新対象のID
     * @param {PpPetControllerEditRequest} editRequest EditRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpPetApi
     */
    public v1AdminPpPetEditIdPost(id: string, editRequest: PpPetControllerEditRequest, options?: AxiosRequestConfig) {
        return AdminPpPetApiFp(this.configuration).v1AdminPpPetEditIdPost(id, editRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ペット一覧
     * @param {number} [page] ページ番号
     * @param {number} [size] ページあたりの取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpPetApi
     */
    public v1AdminPpPetGet(page?: number, size?: number, options?: AxiosRequestConfig) {
        return AdminPpPetApiFp(this.configuration).v1AdminPpPetGet(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ペット一件取得
     * @param {string} ppUserId 会員ID
     * @param {string} id ペットのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpPetApi
     */
    public v1AdminPpPetGetPpUserIdIdGet(ppUserId: string, id: string, options?: AxiosRequestConfig) {
        return AdminPpPetApiFp(this.configuration).v1AdminPpPetGetPpUserIdIdGet(ppUserId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ペットIDから、申し込み完了した検査があるかをチェックします。 検査がある場合、種別の変更をできない仕様とします。
     * @summary 申し込み完了した検査があるかをチェック
     * @param {number} ppPetId ペットID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpPetApi
     */
    public v1AdminPpPetHasTestPpPetIdGet(ppPetId: number, options?: AxiosRequestConfig) {
        return AdminPpPetApiFp(this.configuration).v1AdminPpPetHasTestPpPetIdGet(ppPetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary カルテID設定
     * @param {string} ppPetId 更新対象のペットID
     * @param {PpPetControllerSetKarteIDRequest} setKarteIDRequest PpPetControllerSetKarteIDRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpPetApi
     */
    public v1AdminPpPetSetKarteIdPpPetIdPost(ppPetId: string, setKarteIDRequest: PpPetControllerSetKarteIDRequest, options?: AxiosRequestConfig) {
        return AdminPpPetApiFp(this.configuration).v1AdminPpPetSetKarteIdPpPetIdPost(ppPetId, setKarteIDRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpTestKitApi - axios parameter creator
 * @export
 */
export const AdminPpTestKitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 既存のFlow Cell ID、PCR試験プレートに検体を追加
         * @summary 追加検査受付
         * @param {PpTestKitControllerAddReceptionRequest} addReceptionRequest AddReceptionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitAddReceptionPost: async (addReceptionRequest: PpTestKitControllerAddReceptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addReceptionRequest' is not null or undefined
            assertParamExists('v1AdminPpTestKitAddReceptionPost', 'addReceptionRequest', addReceptionRequest)
            const localVarPath = `/v1/admin/pp_test_kit/add_reception`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addReceptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 検査申込:1 / 検体ID/パスワードの検証、確保
         * @param {PpTestKitControllerApplyRequest} applyRequest ApplyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitApplyPost: async (applyRequest: PpTestKitControllerApplyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyRequest' is not null or undefined
            assertParamExists('v1AdminPpTestKitApplyPost', 'applyRequest', applyRequest)
            const localVarPath = `/v1/admin/pp_test_kit/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 確認画面で「申し込み完了」ボタンを押した時にリクエストを投げてください。 このAPIの実行により、申し込みが完了となります。
         * @summary 検査申込:4 / 確認完了・申し込み完了
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitConfirmSampleIdPost: async (sampleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleId' is not null or undefined
            assertParamExists('v1AdminPpTestKitConfirmSampleIdPost', 'sampleId', sampleId)
            const localVarPath = `/v1/admin/pp_test_kit/confirm/{sampleId}`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検体IDを指定してDBデータをダンプします。
         * @summary 検体IDデバッグ
         * @param {PpTestKitControllerDebugRequest} debugRequest DebugRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitDebugPost: async (debugRequest: PpTestKitControllerDebugRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'debugRequest' is not null or undefined
            assertParamExists('v1AdminPpTestKitDebugPost', 'debugRequest', debugRequest)
            const localVarPath = `/v1/admin/pp_test_kit/debug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(debugRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検査未受付ステータスの検査情報の編集。<br /> 項目が多いのでご注意ください。<br /> 項目の詳細は、以下DB仕様書に準拠しています。<br /> https://docs.google.com/spreadsheets/d/1TI2qwIw_vHW96UdwkSC8p9xRNX685-dv/edit#gid=70003530 <br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
         * @summary 検査未受付ステータスの検査情報の編集
         * @param {string} sampleId 検体ID
         * @param {PpTestKitControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitEditSampleIdPost: async (sampleId: string, editRequest: PpTestKitControllerEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleId' is not null or undefined
            assertParamExists('v1AdminPpTestKitEditSampleIdPost', 'sampleId', sampleId)
            // verify required parameter 'editRequest' is not null or undefined
            assertParamExists('v1AdminPpTestKitEditSampleIdPost', 'editRequest', editRequest)
            const localVarPath = `/v1/admin/pp_test_kit/edit/{sampleId}`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検査追加受付時にFlowcellIDに含まれる検体IDを順番通りに返す
         * @summary FlowcellIDから検体ID一覧を取得する
         * @param {string} flowcellId Flow Cell ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitGetByFlowcellIdFlowcellIdGet: async (flowcellId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowcellId' is not null or undefined
            assertParamExists('v1AdminPpTestKitGetByFlowcellIdFlowcellIdGet', 'flowcellId', flowcellId)
            const localVarPath = `/v1/admin/pp_test_kit/get_by_flowcell_id/{flowcellId}`
                .replace(`{${"flowcellId"}}`, encodeURIComponent(String(flowcellId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 腸内検査キット管理明細一件取得
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitGetSampleIdGet: async (sampleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleId' is not null or undefined
            assertParamExists('v1AdminPpTestKitGetSampleIdGet', 'sampleId', sampleId)
            const localVarPath = `/v1/admin/pp_test_kit/get/{sampleId}`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したエクセルファイルを解析して値の配列を返します。
         * @summary エクセルの解析
         * @param {File} file 解析対象のエクセルファイル(xlsx、xls)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitParseXlsPost: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('v1AdminPpTestKitParseXlsPost', 'file', file)
            const localVarPath = `/v1/admin/pp_test_kit/parse_xls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検索項目が多く、自動生成のコードが煩雑になるので、POSTにしています。
         * @summary 検査状況一覧
         * @param {PpTestKitControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitPost: async (indexRequest: PpTestKitControllerIndexRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indexRequest' is not null or undefined
            assertParamExists('v1AdminPpTestKitPost', 'indexRequest', indexRequest)
            const localVarPath = `/v1/admin/pp_test_kit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indexRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 検体ID情報の一覧取得
         * @param {PpTestKitControllerGetSampleIDRequest} getSampleIDRequest GetSampleIDRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitSamplesPost: async (getSampleIDRequest: PpTestKitControllerGetSampleIDRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSampleIDRequest' is not null or undefined
            assertParamExists('v1AdminPpTestKitSamplesPost', 'getSampleIDRequest', getSampleIDRequest)
            const localVarPath = `/v1/admin/pp_test_kit/samples`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSampleIDRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 強制検査受付時、PCR試験プレートID、Flow Cell IDを設定する
         * @param {PpTestKitControllerSetWellplateIDRequest} setWellplateIDRequest SetWellplateIDRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitSetForcedTestPost: async (setWellplateIDRequest: PpTestKitControllerSetWellplateIDRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setWellplateIDRequest' is not null or undefined
            assertParamExists('v1AdminPpTestKitSetForcedTestPost', 'setWellplateIDRequest', setWellplateIDRequest)
            const localVarPath = `/v1/admin/pp_test_kit/set_forced_test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setWellplateIDRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 識別コード変更
         * @param {PpTestKitControllerSetIdentificationCdRequest} setIdentificationCdRequest SetIdentificationCdRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitSetIdentificationCdPost: async (setIdentificationCdRequest: PpTestKitControllerSetIdentificationCdRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setIdentificationCdRequest' is not null or undefined
            assertParamExists('v1AdminPpTestKitSetIdentificationCdPost', 'setIdentificationCdRequest', setIdentificationCdRequest)
            const localVarPath = `/v1/admin/pp_test_kit/set_identification_cd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setIdentificationCdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 病院、提携法人ID一括設定
         * @param {PpTestKitControllerSetSampleIDRequest} setSampleIDRequest SetSampleIDRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitSetSamplesPost: async (setSampleIDRequest: PpTestKitControllerSetSampleIDRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setSampleIDRequest' is not null or undefined
            assertParamExists('v1AdminPpTestKitSetSamplesPost', 'setSampleIDRequest', setSampleIDRequest)
            const localVarPath = `/v1/admin/pp_test_kit/set_samples`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setSampleIDRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 検査受付時、PCR試験プレートID、Flow Cell IDを設定する
         * @param {PpTestKitControllerSetWellplateIDRequest} setWellplateIDRequest SetWellplateIDRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitSetWellplateIdPost: async (setWellplateIDRequest: PpTestKitControllerSetWellplateIDRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setWellplateIDRequest' is not null or undefined
            assertParamExists('v1AdminPpTestKitSetWellplateIdPost', 'setWellplateIDRequest', setWellplateIDRequest)
            const localVarPath = `/v1/admin/pp_test_kit/set_wellplate_id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setWellplateIDRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 項目が多いのでご注意ください。<br /> 項目の詳細は、以下DB仕様書に準拠しています。<br /> https://docs.google.com/spreadsheets/d/1TI2qwIw_vHW96UdwkSC8p9xRNX685-dv/edit#gid=70003530 <br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
         * @summary 検査申込:2 / 入力1 / 検査情報、体調に関する基礎情報の回答を保存
         * @param {string} sampleId 検体ID
         * @param {PpTestKitControllerStep1Request} step1Request Step1Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitStep1SampleIdPost: async (sampleId: string, step1Request: PpTestKitControllerStep1Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleId' is not null or undefined
            assertParamExists('v1AdminPpTestKitStep1SampleIdPost', 'sampleId', sampleId)
            // verify required parameter 'step1Request' is not null or undefined
            assertParamExists('v1AdminPpTestKitStep1SampleIdPost', 'step1Request', step1Request)
            const localVarPath = `/v1/admin/pp_test_kit/step1/{sampleId}`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(step1Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * こちらも項目が多いのでご注意ください。<br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
         * @summary 検査申込:3 / 入力2 / 生活習慣に関する基礎情報の回答を保存
         * @param {string} sampleId 検体ID
         * @param {PpTestKitControllerStep2Request} step2Request Step2Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitStep2SampleIdPost: async (sampleId: string, step2Request: PpTestKitControllerStep2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleId' is not null or undefined
            assertParamExists('v1AdminPpTestKitStep2SampleIdPost', 'sampleId', sampleId)
            // verify required parameter 'step2Request' is not null or undefined
            assertParamExists('v1AdminPpTestKitStep2SampleIdPost', 'step2Request', step2Request)
            const localVarPath = `/v1/admin/pp_test_kit/step2/{sampleId}`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(step2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpTestKitApi - functional programming interface
 * @export
 */
export const AdminPpTestKitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpTestKitApiAxiosParamCreator(configuration)
    return {
        /**
         * 既存のFlow Cell ID、PCR試験プレートに検体を追加
         * @summary 追加検査受付
         * @param {PpTestKitControllerAddReceptionRequest} addReceptionRequest AddReceptionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitAddReceptionPost(addReceptionRequest: PpTestKitControllerAddReceptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerAddReceptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitAddReceptionPost(addReceptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 検査申込:1 / 検体ID/パスワードの検証、確保
         * @param {PpTestKitControllerApplyRequest} applyRequest ApplyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitApplyPost(applyRequest: PpTestKitControllerApplyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerApplyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitApplyPost(applyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 確認画面で「申し込み完了」ボタンを押した時にリクエストを投げてください。 このAPIの実行により、申し込みが完了となります。
         * @summary 検査申込:4 / 確認完了・申し込み完了
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitConfirmSampleIdPost(sampleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitConfirmSampleIdPost(sampleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検体IDを指定してDBデータをダンプします。
         * @summary 検体IDデバッグ
         * @param {PpTestKitControllerDebugRequest} debugRequest DebugRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitDebugPost(debugRequest: PpTestKitControllerDebugRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerDebugResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitDebugPost(debugRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検査未受付ステータスの検査情報の編集。<br /> 項目が多いのでご注意ください。<br /> 項目の詳細は、以下DB仕様書に準拠しています。<br /> https://docs.google.com/spreadsheets/d/1TI2qwIw_vHW96UdwkSC8p9xRNX685-dv/edit#gid=70003530 <br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
         * @summary 検査未受付ステータスの検査情報の編集
         * @param {string} sampleId 検体ID
         * @param {PpTestKitControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitEditSampleIdPost(sampleId: string, editRequest: PpTestKitControllerEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitEditSampleIdPost(sampleId, editRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検査追加受付時にFlowcellIDに含まれる検体IDを順番通りに返す
         * @summary FlowcellIDから検体ID一覧を取得する
         * @param {string} flowcellId Flow Cell ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitGetByFlowcellIdFlowcellIdGet(flowcellId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerGetByFlowcellIDResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitGetByFlowcellIdFlowcellIdGet(flowcellId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 腸内検査キット管理明細一件取得
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitGetSampleIdGet(sampleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitGetSampleIdGet(sampleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したエクセルファイルを解析して値の配列を返します。
         * @summary エクセルの解析
         * @param {File} file 解析対象のエクセルファイル(xlsx、xls)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitParseXlsPost(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XlsControllerParseForSampleIDResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitParseXlsPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検索項目が多く、自動生成のコードが煩雑になるので、POSTにしています。
         * @summary 検査状況一覧
         * @param {PpTestKitControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitPost(indexRequest: PpTestKitControllerIndexRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitPost(indexRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 検体ID情報の一覧取得
         * @param {PpTestKitControllerGetSampleIDRequest} getSampleIDRequest GetSampleIDRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitSamplesPost(getSampleIDRequest: PpTestKitControllerGetSampleIDRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerGetSampleIDResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitSamplesPost(getSampleIDRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 強制検査受付時、PCR試験プレートID、Flow Cell IDを設定する
         * @param {PpTestKitControllerSetWellplateIDRequest} setWellplateIDRequest SetWellplateIDRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitSetForcedTestPost(setWellplateIDRequest: PpTestKitControllerSetWellplateIDRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitSetForcedTestPost(setWellplateIDRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 識別コード変更
         * @param {PpTestKitControllerSetIdentificationCdRequest} setIdentificationCdRequest SetIdentificationCdRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitSetIdentificationCdPost(setIdentificationCdRequest: PpTestKitControllerSetIdentificationCdRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitSetIdentificationCdPost(setIdentificationCdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 病院、提携法人ID一括設定
         * @param {PpTestKitControllerSetSampleIDRequest} setSampleIDRequest SetSampleIDRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitSetSamplesPost(setSampleIDRequest: PpTestKitControllerSetSampleIDRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitSetSamplesPost(setSampleIDRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 検査受付時、PCR試験プレートID、Flow Cell IDを設定する
         * @param {PpTestKitControllerSetWellplateIDRequest} setWellplateIDRequest SetWellplateIDRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitSetWellplateIdPost(setWellplateIDRequest: PpTestKitControllerSetWellplateIDRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitSetWellplateIdPost(setWellplateIDRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 項目が多いのでご注意ください。<br /> 項目の詳細は、以下DB仕様書に準拠しています。<br /> https://docs.google.com/spreadsheets/d/1TI2qwIw_vHW96UdwkSC8p9xRNX685-dv/edit#gid=70003530 <br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
         * @summary 検査申込:2 / 入力1 / 検査情報、体調に関する基礎情報の回答を保存
         * @param {string} sampleId 検体ID
         * @param {PpTestKitControllerStep1Request} step1Request Step1Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitStep1SampleIdPost(sampleId: string, step1Request: PpTestKitControllerStep1Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitStep1SampleIdPost(sampleId, step1Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * こちらも項目が多いのでご注意ください。<br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
         * @summary 検査申込:3 / 入力2 / 生活習慣に関する基礎情報の回答を保存
         * @param {string} sampleId 検体ID
         * @param {PpTestKitControllerStep2Request} step2Request Step2Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpTestKitStep2SampleIdPost(sampleId: string, step2Request: PpTestKitControllerStep2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpTestKitStep2SampleIdPost(sampleId, step2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpTestKitApi - factory interface
 * @export
 */
export const AdminPpTestKitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpTestKitApiFp(configuration)
    return {
        /**
         * 既存のFlow Cell ID、PCR試験プレートに検体を追加
         * @summary 追加検査受付
         * @param {PpTestKitControllerAddReceptionRequest} addReceptionRequest AddReceptionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitAddReceptionPost(addReceptionRequest: PpTestKitControllerAddReceptionRequest, options?: any): AxiosPromise<PpTestKitControllerAddReceptionResponse> {
            return localVarFp.v1AdminPpTestKitAddReceptionPost(addReceptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 検査申込:1 / 検体ID/パスワードの検証、確保
         * @param {PpTestKitControllerApplyRequest} applyRequest ApplyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitApplyPost(applyRequest: PpTestKitControllerApplyRequest, options?: any): AxiosPromise<PpTestKitControllerApplyResponse> {
            return localVarFp.v1AdminPpTestKitApplyPost(applyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 確認画面で「申し込み完了」ボタンを押した時にリクエストを投げてください。 このAPIの実行により、申し込みが完了となります。
         * @summary 検査申込:4 / 確認完了・申し込み完了
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitConfirmSampleIdPost(sampleId: string, options?: any): AxiosPromise<PpTestKitControllerGetResponse> {
            return localVarFp.v1AdminPpTestKitConfirmSampleIdPost(sampleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 検体IDを指定してDBデータをダンプします。
         * @summary 検体IDデバッグ
         * @param {PpTestKitControllerDebugRequest} debugRequest DebugRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitDebugPost(debugRequest: PpTestKitControllerDebugRequest, options?: any): AxiosPromise<PpTestKitControllerDebugResponse> {
            return localVarFp.v1AdminPpTestKitDebugPost(debugRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 検査未受付ステータスの検査情報の編集。<br /> 項目が多いのでご注意ください。<br /> 項目の詳細は、以下DB仕様書に準拠しています。<br /> https://docs.google.com/spreadsheets/d/1TI2qwIw_vHW96UdwkSC8p9xRNX685-dv/edit#gid=70003530 <br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
         * @summary 検査未受付ステータスの検査情報の編集
         * @param {string} sampleId 検体ID
         * @param {PpTestKitControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitEditSampleIdPost(sampleId: string, editRequest: PpTestKitControllerEditRequest, options?: any): AxiosPromise<PpTestKitControllerGetResponse> {
            return localVarFp.v1AdminPpTestKitEditSampleIdPost(sampleId, editRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 検査追加受付時にFlowcellIDに含まれる検体IDを順番通りに返す
         * @summary FlowcellIDから検体ID一覧を取得する
         * @param {string} flowcellId Flow Cell ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitGetByFlowcellIdFlowcellIdGet(flowcellId: string, options?: any): AxiosPromise<PpTestKitControllerGetByFlowcellIDResponse> {
            return localVarFp.v1AdminPpTestKitGetByFlowcellIdFlowcellIdGet(flowcellId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 腸内検査キット管理明細一件取得
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitGetSampleIdGet(sampleId: string, options?: any): AxiosPromise<PpTestKitControllerGetResponse> {
            return localVarFp.v1AdminPpTestKitGetSampleIdGet(sampleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したエクセルファイルを解析して値の配列を返します。
         * @summary エクセルの解析
         * @param {File} file 解析対象のエクセルファイル(xlsx、xls)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitParseXlsPost(file: File, options?: any): AxiosPromise<XlsControllerParseForSampleIDResponse> {
            return localVarFp.v1AdminPpTestKitParseXlsPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 検索項目が多く、自動生成のコードが煩雑になるので、POSTにしています。
         * @summary 検査状況一覧
         * @param {PpTestKitControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitPost(indexRequest: PpTestKitControllerIndexRequest, options?: any): AxiosPromise<PpTestKitControllerIndexResponse> {
            return localVarFp.v1AdminPpTestKitPost(indexRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 検体ID情報の一覧取得
         * @param {PpTestKitControllerGetSampleIDRequest} getSampleIDRequest GetSampleIDRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitSamplesPost(getSampleIDRequest: PpTestKitControllerGetSampleIDRequest, options?: any): AxiosPromise<PpTestKitControllerGetSampleIDResponse> {
            return localVarFp.v1AdminPpTestKitSamplesPost(getSampleIDRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 強制検査受付時、PCR試験プレートID、Flow Cell IDを設定する
         * @param {PpTestKitControllerSetWellplateIDRequest} setWellplateIDRequest SetWellplateIDRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitSetForcedTestPost(setWellplateIDRequest: PpTestKitControllerSetWellplateIDRequest, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpTestKitSetForcedTestPost(setWellplateIDRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 識別コード変更
         * @param {PpTestKitControllerSetIdentificationCdRequest} setIdentificationCdRequest SetIdentificationCdRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitSetIdentificationCdPost(setIdentificationCdRequest: PpTestKitControllerSetIdentificationCdRequest, options?: any): AxiosPromise<PpTestKitControllerGetResponse> {
            return localVarFp.v1AdminPpTestKitSetIdentificationCdPost(setIdentificationCdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 病院、提携法人ID一括設定
         * @param {PpTestKitControllerSetSampleIDRequest} setSampleIDRequest SetSampleIDRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitSetSamplesPost(setSampleIDRequest: PpTestKitControllerSetSampleIDRequest, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpTestKitSetSamplesPost(setSampleIDRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 検査受付時、PCR試験プレートID、Flow Cell IDを設定する
         * @param {PpTestKitControllerSetWellplateIDRequest} setWellplateIDRequest SetWellplateIDRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitSetWellplateIdPost(setWellplateIDRequest: PpTestKitControllerSetWellplateIDRequest, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpTestKitSetWellplateIdPost(setWellplateIDRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 項目が多いのでご注意ください。<br /> 項目の詳細は、以下DB仕様書に準拠しています。<br /> https://docs.google.com/spreadsheets/d/1TI2qwIw_vHW96UdwkSC8p9xRNX685-dv/edit#gid=70003530 <br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
         * @summary 検査申込:2 / 入力1 / 検査情報、体調に関する基礎情報の回答を保存
         * @param {string} sampleId 検体ID
         * @param {PpTestKitControllerStep1Request} step1Request Step1Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitStep1SampleIdPost(sampleId: string, step1Request: PpTestKitControllerStep1Request, options?: any): AxiosPromise<PpTestKitControllerGetResponse> {
            return localVarFp.v1AdminPpTestKitStep1SampleIdPost(sampleId, step1Request, options).then((request) => request(axios, basePath));
        },
        /**
         * こちらも項目が多いのでご注意ください。<br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
         * @summary 検査申込:3 / 入力2 / 生活習慣に関する基礎情報の回答を保存
         * @param {string} sampleId 検体ID
         * @param {PpTestKitControllerStep2Request} step2Request Step2Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpTestKitStep2SampleIdPost(sampleId: string, step2Request: PpTestKitControllerStep2Request, options?: any): AxiosPromise<PpTestKitControllerGetResponse> {
            return localVarFp.v1AdminPpTestKitStep2SampleIdPost(sampleId, step2Request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpTestKitApi - object-oriented interface
 * @export
 * @class AdminPpTestKitApi
 * @extends {BaseAPI}
 */
export class AdminPpTestKitApi extends BaseAPI {
    /**
     * 既存のFlow Cell ID、PCR試験プレートに検体を追加
     * @summary 追加検査受付
     * @param {PpTestKitControllerAddReceptionRequest} addReceptionRequest AddReceptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitAddReceptionPost(addReceptionRequest: PpTestKitControllerAddReceptionRequest, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitAddReceptionPost(addReceptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 検査申込:1 / 検体ID/パスワードの検証、確保
     * @param {PpTestKitControllerApplyRequest} applyRequest ApplyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitApplyPost(applyRequest: PpTestKitControllerApplyRequest, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitApplyPost(applyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 確認画面で「申し込み完了」ボタンを押した時にリクエストを投げてください。 このAPIの実行により、申し込みが完了となります。
     * @summary 検査申込:4 / 確認完了・申し込み完了
     * @param {string} sampleId 検体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitConfirmSampleIdPost(sampleId: string, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitConfirmSampleIdPost(sampleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検体IDを指定してDBデータをダンプします。
     * @summary 検体IDデバッグ
     * @param {PpTestKitControllerDebugRequest} debugRequest DebugRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitDebugPost(debugRequest: PpTestKitControllerDebugRequest, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitDebugPost(debugRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検査未受付ステータスの検査情報の編集。<br /> 項目が多いのでご注意ください。<br /> 項目の詳細は、以下DB仕様書に準拠しています。<br /> https://docs.google.com/spreadsheets/d/1TI2qwIw_vHW96UdwkSC8p9xRNX685-dv/edit#gid=70003530 <br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
     * @summary 検査未受付ステータスの検査情報の編集
     * @param {string} sampleId 検体ID
     * @param {PpTestKitControllerEditRequest} editRequest EditRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitEditSampleIdPost(sampleId: string, editRequest: PpTestKitControllerEditRequest, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitEditSampleIdPost(sampleId, editRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検査追加受付時にFlowcellIDに含まれる検体IDを順番通りに返す
     * @summary FlowcellIDから検体ID一覧を取得する
     * @param {string} flowcellId Flow Cell ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitGetByFlowcellIdFlowcellIdGet(flowcellId: string, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitGetByFlowcellIdFlowcellIdGet(flowcellId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 腸内検査キット管理明細一件取得
     * @param {string} sampleId 検体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitGetSampleIdGet(sampleId: string, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitGetSampleIdGet(sampleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したエクセルファイルを解析して値の配列を返します。
     * @summary エクセルの解析
     * @param {File} file 解析対象のエクセルファイル(xlsx、xls)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitParseXlsPost(file: File, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitParseXlsPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検索項目が多く、自動生成のコードが煩雑になるので、POSTにしています。
     * @summary 検査状況一覧
     * @param {PpTestKitControllerIndexRequest} indexRequest IndexRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitPost(indexRequest: PpTestKitControllerIndexRequest, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitPost(indexRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 検体ID情報の一覧取得
     * @param {PpTestKitControllerGetSampleIDRequest} getSampleIDRequest GetSampleIDRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitSamplesPost(getSampleIDRequest: PpTestKitControllerGetSampleIDRequest, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitSamplesPost(getSampleIDRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 強制検査受付時、PCR試験プレートID、Flow Cell IDを設定する
     * @param {PpTestKitControllerSetWellplateIDRequest} setWellplateIDRequest SetWellplateIDRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitSetForcedTestPost(setWellplateIDRequest: PpTestKitControllerSetWellplateIDRequest, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitSetForcedTestPost(setWellplateIDRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 識別コード変更
     * @param {PpTestKitControllerSetIdentificationCdRequest} setIdentificationCdRequest SetIdentificationCdRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitSetIdentificationCdPost(setIdentificationCdRequest: PpTestKitControllerSetIdentificationCdRequest, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitSetIdentificationCdPost(setIdentificationCdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 病院、提携法人ID一括設定
     * @param {PpTestKitControllerSetSampleIDRequest} setSampleIDRequest SetSampleIDRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitSetSamplesPost(setSampleIDRequest: PpTestKitControllerSetSampleIDRequest, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitSetSamplesPost(setSampleIDRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 検査受付時、PCR試験プレートID、Flow Cell IDを設定する
     * @param {PpTestKitControllerSetWellplateIDRequest} setWellplateIDRequest SetWellplateIDRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitSetWellplateIdPost(setWellplateIDRequest: PpTestKitControllerSetWellplateIDRequest, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitSetWellplateIdPost(setWellplateIDRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 項目が多いのでご注意ください。<br /> 項目の詳細は、以下DB仕様書に準拠しています。<br /> https://docs.google.com/spreadsheets/d/1TI2qwIw_vHW96UdwkSC8p9xRNX685-dv/edit#gid=70003530 <br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
     * @summary 検査申込:2 / 入力1 / 検査情報、体調に関する基礎情報の回答を保存
     * @param {string} sampleId 検体ID
     * @param {PpTestKitControllerStep1Request} step1Request Step1Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitStep1SampleIdPost(sampleId: string, step1Request: PpTestKitControllerStep1Request, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitStep1SampleIdPost(sampleId, step1Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * こちらも項目が多いのでご注意ください。<br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
     * @summary 検査申込:3 / 入力2 / 生活習慣に関する基礎情報の回答を保存
     * @param {string} sampleId 検体ID
     * @param {PpTestKitControllerStep2Request} step2Request Step2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpTestKitApi
     */
    public v1AdminPpTestKitStep2SampleIdPost(sampleId: string, step2Request: PpTestKitControllerStep2Request, options?: AxiosRequestConfig) {
        return AdminPpTestKitApiFp(this.configuration).v1AdminPpTestKitStep2SampleIdPost(sampleId, step2Request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPpUserApi - axios parameter creator
 * @export
 */
export const AdminPpUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 会員新規登録
         * @param {PpUserControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpUserCreatePost: async (createRequest: PpUserControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1AdminPpUserCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/admin/pp_user/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 会員削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpUserDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpUserDeleteIdDelete', 'id', id)
            const localVarPath = `/v1/admin/pp_user/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 会員情報編集
         * @param {string} id 更新対象のID
         * @param {PpUserControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpUserEditIdPost: async (id: string, editRequest: PpUserControllerEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpUserEditIdPost', 'id', id)
            // verify required parameter 'editRequest' is not null or undefined
            assertParamExists('v1AdminPpUserEditIdPost', 'editRequest', editRequest)
            const localVarPath = `/v1/admin/pp_user/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 会員一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpUserGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1AdminPpUserGetIdGet', 'id', id)
            const localVarPath = `/v1/admin/pp_user/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 会員一覧
         * @param {PpUserControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpUserPost: async (indexRequest: PpUserControllerIndexRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indexRequest' is not null or undefined
            assertParamExists('v1AdminPpUserPost', 'indexRequest', indexRequest)
            const localVarPath = `/v1/admin/pp_user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indexRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPpUserApi - functional programming interface
 * @export
 */
export const AdminPpUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPpUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 会員新規登録
         * @param {PpUserControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpUserCreatePost(createRequest: PpUserControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpUserControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpUserCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 会員削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpUserDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpUserDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 会員情報編集
         * @param {string} id 更新対象のID
         * @param {PpUserControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpUserEditIdPost(id: string, editRequest: PpUserControllerEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpUserControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpUserEditIdPost(id, editRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 会員一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpUserGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpUserControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpUserGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 会員一覧
         * @param {PpUserControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminPpUserPost(indexRequest: PpUserControllerIndexRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpUserControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminPpUserPost(indexRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPpUserApi - factory interface
 * @export
 */
export const AdminPpUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPpUserApiFp(configuration)
    return {
        /**
         * 
         * @summary 会員新規登録
         * @param {PpUserControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpUserCreatePost(createRequest: PpUserControllerCreateRequest, options?: any): AxiosPromise<PpUserControllerGetResponse> {
            return localVarFp.v1AdminPpUserCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 会員削除
         * @param {string} id 削除対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpUserDeleteIdDelete(id: string, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminPpUserDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 会員情報編集
         * @param {string} id 更新対象のID
         * @param {PpUserControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpUserEditIdPost(id: string, editRequest: PpUserControllerEditRequest, options?: any): AxiosPromise<PpUserControllerGetResponse> {
            return localVarFp.v1AdminPpUserEditIdPost(id, editRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 会員一件取得
         * @param {string} id 取得対象のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpUserGetIdGet(id: string, options?: any): AxiosPromise<PpUserControllerGetResponse> {
            return localVarFp.v1AdminPpUserGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 会員一覧
         * @param {PpUserControllerIndexRequest} indexRequest IndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminPpUserPost(indexRequest: PpUserControllerIndexRequest, options?: any): AxiosPromise<PpUserControllerIndexResponse> {
            return localVarFp.v1AdminPpUserPost(indexRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPpUserApi - object-oriented interface
 * @export
 * @class AdminPpUserApi
 * @extends {BaseAPI}
 */
export class AdminPpUserApi extends BaseAPI {
    /**
     * 
     * @summary 会員新規登録
     * @param {PpUserControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpUserApi
     */
    public v1AdminPpUserCreatePost(createRequest: PpUserControllerCreateRequest, options?: AxiosRequestConfig) {
        return AdminPpUserApiFp(this.configuration).v1AdminPpUserCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 会員削除
     * @param {string} id 削除対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpUserApi
     */
    public v1AdminPpUserDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminPpUserApiFp(this.configuration).v1AdminPpUserDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 会員情報編集
     * @param {string} id 更新対象のID
     * @param {PpUserControllerEditRequest} editRequest EditRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpUserApi
     */
    public v1AdminPpUserEditIdPost(id: string, editRequest: PpUserControllerEditRequest, options?: AxiosRequestConfig) {
        return AdminPpUserApiFp(this.configuration).v1AdminPpUserEditIdPost(id, editRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 会員一件取得
     * @param {string} id 取得対象のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpUserApi
     */
    public v1AdminPpUserGetIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminPpUserApiFp(this.configuration).v1AdminPpUserGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 会員一覧
     * @param {PpUserControllerIndexRequest} indexRequest IndexRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPpUserApi
     */
    public v1AdminPpUserPost(indexRequest: PpUserControllerIndexRequest, options?: AxiosRequestConfig) {
        return AdminPpUserApiFp(this.configuration).v1AdminPpUserPost(indexRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminResultApi - axios parameter creator
 * @export
 */
export const AdminResultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Flow Cell ID、検体ID、PCRプレート番号のいずれかを指定して検索します。
         * @summary 試験番号情報照会API
         * @param {PpTestResultControllerReferenceRequest} referenceRequest ReferenceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReferencePost: async (referenceRequest: PpTestResultControllerReferenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceRequest' is not null or undefined
            assertParamExists('v1AdminReferencePost', 'referenceRequest', referenceRequest)
            const localVarPath = `/v1/admin/reference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(referenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検体IDリストを指定して、EG MIrRORのAPIを呼び出して結果の有無を返す
         * @summary EG MIrRORからのデータ取得
         * @param {PpTestResultControllerGetResultRequest} getResultRequest GetResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminResultGetResultsPost: async (getResultRequest: PpTestResultControllerGetResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getResultRequest' is not null or undefined
            assertParamExists('v1AdminResultGetResultsPost', 'getResultRequest', getResultRequest)
            const localVarPath = `/v1/admin/result/get-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getResultRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 管理画面、検査結果登録の一覧作成用のAPIです。
         * @summary 結果データの一覧出力
         * @param {PpTestResultControllerResultIndexRequest} resultIndexRequest ResultIndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminResultIndexPost: async (resultIndexRequest: PpTestResultControllerResultIndexRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resultIndexRequest' is not null or undefined
            assertParamExists('v1AdminResultIndexPost', 'resultIndexRequest', resultIndexRequest)
            const localVarPath = `/v1/admin/result/index`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resultIndexRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検体IDリストを指定して、EG MIrRORのAPIを呼び出して結果をDBに保存<br> また、検査完了、メール通知処理を行います。
         * @summary EG MIrRORからデータを取得し結果を登録する
         * @param {PpTestResultControllerSetResultRequest} setResultRequest SetResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminResultSetResultsPost: async (setResultRequest: PpTestResultControllerSetResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setResultRequest' is not null or undefined
            assertParamExists('v1AdminResultSetResultsPost', 'setResultRequest', setResultRequest)
            const localVarPath = `/v1/admin/result/set-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setResultRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検体ID、もしくはFlow Cell IDを指定して、検査完了した検査の一覧を返します。
         * @summary 切り戻し対象の検査を取得
         * @param {string} [flowcellId] Flow Cell ID
         * @param {string} [sampleId] 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminRollbackGet: async (flowcellId?: string, sampleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/rollback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (flowcellId !== undefined) {
                localVarQueryParameter['flowcell_id'] = flowcellId;
            }

            if (sampleId !== undefined) {
                localVarQueryParameter['sample_id'] = sampleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検体ID、もしくはFlow Cell IDを指定して、検査完了した検査を切り戻します。
         * @summary 切り戻し処理実行
         * @param {PpTestResultControllerRollbackRequest} rollbackRequest RollbackRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminRollbackProcessPost: async (rollbackRequest: PpTestResultControllerRollbackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rollbackRequest' is not null or undefined
            assertParamExists('v1AdminRollbackProcessPost', 'rollbackRequest', rollbackRequest)
            const localVarPath = `/v1/admin/rollback/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rollbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminResultApi - functional programming interface
 * @export
 */
export const AdminResultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminResultApiAxiosParamCreator(configuration)
    return {
        /**
         * Flow Cell ID、検体ID、PCRプレート番号のいずれかを指定して検索します。
         * @summary 試験番号情報照会API
         * @param {PpTestResultControllerReferenceRequest} referenceRequest ReferenceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminReferencePost(referenceRequest: PpTestResultControllerReferenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestResultControllerReferenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminReferencePost(referenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検体IDリストを指定して、EG MIrRORのAPIを呼び出して結果の有無を返す
         * @summary EG MIrRORからのデータ取得
         * @param {PpTestResultControllerGetResultRequest} getResultRequest GetResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminResultGetResultsPost(getResultRequest: PpTestResultControllerGetResultRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestResultControllerGetResultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminResultGetResultsPost(getResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 管理画面、検査結果登録の一覧作成用のAPIです。
         * @summary 結果データの一覧出力
         * @param {PpTestResultControllerResultIndexRequest} resultIndexRequest ResultIndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminResultIndexPost(resultIndexRequest: PpTestResultControllerResultIndexRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestResultControllerMetadataIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminResultIndexPost(resultIndexRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検体IDリストを指定して、EG MIrRORのAPIを呼び出して結果をDBに保存<br> また、検査完了、メール通知処理を行います。
         * @summary EG MIrRORからデータを取得し結果を登録する
         * @param {PpTestResultControllerSetResultRequest} setResultRequest SetResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminResultSetResultsPost(setResultRequest: PpTestResultControllerSetResultRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminResultSetResultsPost(setResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検体ID、もしくはFlow Cell IDを指定して、検査完了した検査の一覧を返します。
         * @summary 切り戻し対象の検査を取得
         * @param {string} [flowcellId] Flow Cell ID
         * @param {string} [sampleId] 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminRollbackGet(flowcellId?: string, sampleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestResultControllerGetRollbackResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminRollbackGet(flowcellId, sampleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検体ID、もしくはFlow Cell IDを指定して、検査完了した検査を切り戻します。
         * @summary 切り戻し処理実行
         * @param {PpTestResultControllerRollbackRequest} rollbackRequest RollbackRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminRollbackProcessPost(rollbackRequest: PpTestResultControllerRollbackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestResultControllerGetRollbackResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminRollbackProcessPost(rollbackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminResultApi - factory interface
 * @export
 */
export const AdminResultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminResultApiFp(configuration)
    return {
        /**
         * Flow Cell ID、検体ID、PCRプレート番号のいずれかを指定して検索します。
         * @summary 試験番号情報照会API
         * @param {PpTestResultControllerReferenceRequest} referenceRequest ReferenceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReferencePost(referenceRequest: PpTestResultControllerReferenceRequest, options?: any): AxiosPromise<PpTestResultControllerReferenceResponse> {
            return localVarFp.v1AdminReferencePost(referenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 検体IDリストを指定して、EG MIrRORのAPIを呼び出して結果の有無を返す
         * @summary EG MIrRORからのデータ取得
         * @param {PpTestResultControllerGetResultRequest} getResultRequest GetResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminResultGetResultsPost(getResultRequest: PpTestResultControllerGetResultRequest, options?: any): AxiosPromise<PpTestResultControllerGetResultResponse> {
            return localVarFp.v1AdminResultGetResultsPost(getResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 管理画面、検査結果登録の一覧作成用のAPIです。
         * @summary 結果データの一覧出力
         * @param {PpTestResultControllerResultIndexRequest} resultIndexRequest ResultIndexRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminResultIndexPost(resultIndexRequest: PpTestResultControllerResultIndexRequest, options?: any): AxiosPromise<PpTestResultControllerMetadataIndexResponse> {
            return localVarFp.v1AdminResultIndexPost(resultIndexRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 検体IDリストを指定して、EG MIrRORのAPIを呼び出して結果をDBに保存<br> また、検査完了、メール通知処理を行います。
         * @summary EG MIrRORからデータを取得し結果を登録する
         * @param {PpTestResultControllerSetResultRequest} setResultRequest SetResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminResultSetResultsPost(setResultRequest: PpTestResultControllerSetResultRequest, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1AdminResultSetResultsPost(setResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 検体ID、もしくはFlow Cell IDを指定して、検査完了した検査の一覧を返します。
         * @summary 切り戻し対象の検査を取得
         * @param {string} [flowcellId] Flow Cell ID
         * @param {string} [sampleId] 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminRollbackGet(flowcellId?: string, sampleId?: string, options?: any): AxiosPromise<PpTestResultControllerGetRollbackResponse> {
            return localVarFp.v1AdminRollbackGet(flowcellId, sampleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 検体ID、もしくはFlow Cell IDを指定して、検査完了した検査を切り戻します。
         * @summary 切り戻し処理実行
         * @param {PpTestResultControllerRollbackRequest} rollbackRequest RollbackRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminRollbackProcessPost(rollbackRequest: PpTestResultControllerRollbackRequest, options?: any): AxiosPromise<PpTestResultControllerGetRollbackResponse> {
            return localVarFp.v1AdminRollbackProcessPost(rollbackRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminResultApi - object-oriented interface
 * @export
 * @class AdminResultApi
 * @extends {BaseAPI}
 */
export class AdminResultApi extends BaseAPI {
    /**
     * Flow Cell ID、検体ID、PCRプレート番号のいずれかを指定して検索します。
     * @summary 試験番号情報照会API
     * @param {PpTestResultControllerReferenceRequest} referenceRequest ReferenceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResultApi
     */
    public v1AdminReferencePost(referenceRequest: PpTestResultControllerReferenceRequest, options?: AxiosRequestConfig) {
        return AdminResultApiFp(this.configuration).v1AdminReferencePost(referenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検体IDリストを指定して、EG MIrRORのAPIを呼び出して結果の有無を返す
     * @summary EG MIrRORからのデータ取得
     * @param {PpTestResultControllerGetResultRequest} getResultRequest GetResultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResultApi
     */
    public v1AdminResultGetResultsPost(getResultRequest: PpTestResultControllerGetResultRequest, options?: AxiosRequestConfig) {
        return AdminResultApiFp(this.configuration).v1AdminResultGetResultsPost(getResultRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 管理画面、検査結果登録の一覧作成用のAPIです。
     * @summary 結果データの一覧出力
     * @param {PpTestResultControllerResultIndexRequest} resultIndexRequest ResultIndexRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResultApi
     */
    public v1AdminResultIndexPost(resultIndexRequest: PpTestResultControllerResultIndexRequest, options?: AxiosRequestConfig) {
        return AdminResultApiFp(this.configuration).v1AdminResultIndexPost(resultIndexRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検体IDリストを指定して、EG MIrRORのAPIを呼び出して結果をDBに保存<br> また、検査完了、メール通知処理を行います。
     * @summary EG MIrRORからデータを取得し結果を登録する
     * @param {PpTestResultControllerSetResultRequest} setResultRequest SetResultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResultApi
     */
    public v1AdminResultSetResultsPost(setResultRequest: PpTestResultControllerSetResultRequest, options?: AxiosRequestConfig) {
        return AdminResultApiFp(this.configuration).v1AdminResultSetResultsPost(setResultRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検体ID、もしくはFlow Cell IDを指定して、検査完了した検査の一覧を返します。
     * @summary 切り戻し対象の検査を取得
     * @param {string} [flowcellId] Flow Cell ID
     * @param {string} [sampleId] 検体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResultApi
     */
    public v1AdminRollbackGet(flowcellId?: string, sampleId?: string, options?: AxiosRequestConfig) {
        return AdminResultApiFp(this.configuration).v1AdminRollbackGet(flowcellId, sampleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検体ID、もしくはFlow Cell IDを指定して、検査完了した検査を切り戻します。
     * @summary 切り戻し処理実行
     * @param {PpTestResultControllerRollbackRequest} rollbackRequest RollbackRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResultApi
     */
    public v1AdminRollbackProcessPost(rollbackRequest: PpTestResultControllerRollbackRequest, options?: AxiosRequestConfig) {
        return AdminResultApiFp(this.configuration).v1AdminRollbackProcessPost(rollbackRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * このAPIは認証していない状態でも公開されているので、一時ディレクトリへのアップロード処理のみ行います。<br> そのため、アップロードされた画像は一定時間経過後（現状24h）に自動的に削除されます。<br> アップロードした後に、別のAPIで画像の登録処理を行う必要があります。<br> 画像の本登録時にサーバに送信する値は、`path`の値となります。<br>
         * @summary 一時ディレクトリへの画像アップロード処理
         * @param {File} file アップロードする画像
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UploadImagePost: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('v1UploadImagePost', 'file', file)
            const localVarPath = `/v1/upload-image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * このAPIは認証していない状態でも公開されているので、一時ディレクトリへのアップロード処理のみ行います。<br> そのため、アップロードされた画像は一定時間経過後（現状24h）に自動的に削除されます。<br> アップロードした後に、別のAPIで画像の登録処理を行う必要があります。<br> 画像の本登録時にサーバに送信する値は、`path`の値となります。<br>
         * @summary 一時ディレクトリへの画像アップロード処理
         * @param {File} file アップロードする画像
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UploadImagePost(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetsControllerUploadImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UploadImagePost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetsApiFp(configuration)
    return {
        /**
         * このAPIは認証していない状態でも公開されているので、一時ディレクトリへのアップロード処理のみ行います。<br> そのため、アップロードされた画像は一定時間経過後（現状24h）に自動的に削除されます。<br> アップロードした後に、別のAPIで画像の登録処理を行う必要があります。<br> 画像の本登録時にサーバに送信する値は、`path`の値となります。<br>
         * @summary 一時ディレクトリへの画像アップロード処理
         * @param {File} file アップロードする画像
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UploadImagePost(file: File, options?: any): AxiosPromise<AssetsControllerUploadImageResponse> {
            return localVarFp.v1UploadImagePost(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI {
    /**
     * このAPIは認証していない状態でも公開されているので、一時ディレクトリへのアップロード処理のみ行います。<br> そのため、アップロードされた画像は一定時間経過後（現状24h）に自動的に削除されます。<br> アップロードした後に、別のAPIで画像の登録処理を行う必要があります。<br> 画像の本登録時にサーバに送信する値は、`path`の値となります。<br>
     * @summary 一時ディレクトリへの画像アップロード処理
     * @param {File} file アップロードする画像
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public v1UploadImagePost(file: File, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).v1UploadImagePost(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 死活監視用エンドポイント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 死活監視用エンドポイント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HelloControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonApiFp(configuration)
    return {
        /**
         * 
         * @summary 死活監視用エンドポイント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet(options?: any): AxiosPromise<HelloControllerIndexResponse> {
            return localVarFp.rootGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
    /**
     * 
     * @summary 死活監視用エンドポイント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public rootGet(options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).rootGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FrontAgencyLoginApi - axios parameter creator
 * @export
 */
export const FrontAgencyLoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 代行ログイン時のトークン検証
         * @param {AgencyLoginControllerFrontLoginRequest} frontLoginRequest FrontLoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontAgencyLoginPost: async (frontLoginRequest: AgencyLoginControllerFrontLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'frontLoginRequest' is not null or undefined
            assertParamExists('v1FrontAgencyLoginPost', 'frontLoginRequest', frontLoginRequest)
            const localVarPath = `/v1/front/agency_login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(frontLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontAgencyLoginApi - functional programming interface
 * @export
 */
export const FrontAgencyLoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontAgencyLoginApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 代行ログイン時のトークン検証
         * @param {AgencyLoginControllerFrontLoginRequest} frontLoginRequest FrontLoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontAgencyLoginPost(frontLoginRequest: AgencyLoginControllerFrontLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgencyLoginControllerFrontLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontAgencyLoginPost(frontLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FrontAgencyLoginApi - factory interface
 * @export
 */
export const FrontAgencyLoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontAgencyLoginApiFp(configuration)
    return {
        /**
         * 
         * @summary 代行ログイン時のトークン検証
         * @param {AgencyLoginControllerFrontLoginRequest} frontLoginRequest FrontLoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontAgencyLoginPost(frontLoginRequest: AgencyLoginControllerFrontLoginRequest, options?: any): AxiosPromise<AgencyLoginControllerFrontLoginResponse> {
            return localVarFp.v1FrontAgencyLoginPost(frontLoginRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FrontAgencyLoginApi - object-oriented interface
 * @export
 * @class FrontAgencyLoginApi
 * @extends {BaseAPI}
 */
export class FrontAgencyLoginApi extends BaseAPI {
    /**
     * 
     * @summary 代行ログイン時のトークン検証
     * @param {AgencyLoginControllerFrontLoginRequest} frontLoginRequest FrontLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontAgencyLoginApi
     */
    public v1FrontAgencyLoginPost(frontLoginRequest: AgencyLoginControllerFrontLoginRequest, options?: AxiosRequestConfig) {
        return FrontAgencyLoginApiFp(this.configuration).v1FrontAgencyLoginPost(frontLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FrontAuthApi - axios parameter creator
 * @export
 */
export const FrontAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary パスワードを忘れた場合
         * @param {FrontAuthControllerForgotRequest} forgotRequest ForgotRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontAuthForgotPost: async (forgotRequest: FrontAuthControllerForgotRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotRequest' is not null or undefined
            assertParamExists('v1FrontAuthForgotPost', 'forgotRequest', forgotRequest)
            const localVarPath = `/v1/front/auth/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ログイン認証
         * @param {FrontAuthControllerLoginRequest} loginRequest LoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontAuthLoginPost: async (loginRequest: FrontAuthControllerLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('v1FrontAuthLoginPost', 'loginRequest', loginRequest)
            const localVarPath = `/v1/front/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 認証情報が必要です
         * @summary ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontAuthLogoutGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/front/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 自分自身の情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontAuthMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/front/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary パスワード再設定
         * @param {FrontAuthControllerResetPasswordRequest} resetPasswordRequest ResetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontAuthResetPasswordPost: async (resetPasswordRequest: FrontAuthControllerResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('v1FrontAuthResetPasswordPost', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/v1/front/auth/reset_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontAuthApi - functional programming interface
 * @export
 */
export const FrontAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary パスワードを忘れた場合
         * @param {FrontAuthControllerForgotRequest} forgotRequest ForgotRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontAuthForgotPost(forgotRequest: FrontAuthControllerForgotRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontAuthForgotPost(forgotRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ログイン認証
         * @param {FrontAuthControllerLoginRequest} loginRequest LoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontAuthLoginPost(loginRequest: FrontAuthControllerLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrontAuthControllerLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontAuthLoginPost(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 認証情報が必要です
         * @summary ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontAuthLogoutGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontAuthLogoutGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 自分自身の情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontAuthMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrontAuthControllerMeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontAuthMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary パスワード再設定
         * @param {FrontAuthControllerResetPasswordRequest} resetPasswordRequest ResetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontAuthResetPasswordPost(resetPasswordRequest: FrontAuthControllerResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontAuthResetPasswordPost(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FrontAuthApi - factory interface
 * @export
 */
export const FrontAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontAuthApiFp(configuration)
    return {
        /**
         * 
         * @summary パスワードを忘れた場合
         * @param {FrontAuthControllerForgotRequest} forgotRequest ForgotRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontAuthForgotPost(forgotRequest: FrontAuthControllerForgotRequest, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1FrontAuthForgotPost(forgotRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ログイン認証
         * @param {FrontAuthControllerLoginRequest} loginRequest LoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontAuthLoginPost(loginRequest: FrontAuthControllerLoginRequest, options?: any): AxiosPromise<FrontAuthControllerLoginResponse> {
            return localVarFp.v1FrontAuthLoginPost(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 認証情報が必要です
         * @summary ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontAuthLogoutGet(options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1FrontAuthLogoutGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 自分自身の情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontAuthMeGet(options?: any): AxiosPromise<FrontAuthControllerMeResponse> {
            return localVarFp.v1FrontAuthMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary パスワード再設定
         * @param {FrontAuthControllerResetPasswordRequest} resetPasswordRequest ResetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontAuthResetPasswordPost(resetPasswordRequest: FrontAuthControllerResetPasswordRequest, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1FrontAuthResetPasswordPost(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FrontAuthApi - object-oriented interface
 * @export
 * @class FrontAuthApi
 * @extends {BaseAPI}
 */
export class FrontAuthApi extends BaseAPI {
    /**
     * 
     * @summary パスワードを忘れた場合
     * @param {FrontAuthControllerForgotRequest} forgotRequest ForgotRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontAuthApi
     */
    public v1FrontAuthForgotPost(forgotRequest: FrontAuthControllerForgotRequest, options?: AxiosRequestConfig) {
        return FrontAuthApiFp(this.configuration).v1FrontAuthForgotPost(forgotRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ログイン認証
     * @param {FrontAuthControllerLoginRequest} loginRequest LoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontAuthApi
     */
    public v1FrontAuthLoginPost(loginRequest: FrontAuthControllerLoginRequest, options?: AxiosRequestConfig) {
        return FrontAuthApiFp(this.configuration).v1FrontAuthLoginPost(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 認証情報が必要です
     * @summary ログアウト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontAuthApi
     */
    public v1FrontAuthLogoutGet(options?: AxiosRequestConfig) {
        return FrontAuthApiFp(this.configuration).v1FrontAuthLogoutGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 自分自身の情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontAuthApi
     */
    public v1FrontAuthMeGet(options?: AxiosRequestConfig) {
        return FrontAuthApiFp(this.configuration).v1FrontAuthMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary パスワード再設定
     * @param {FrontAuthControllerResetPasswordRequest} resetPasswordRequest ResetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontAuthApi
     */
    public v1FrontAuthResetPasswordPost(resetPasswordRequest: FrontAuthControllerResetPasswordRequest, options?: AxiosRequestConfig) {
        return FrontAuthApiFp(this.configuration).v1FrontAuthResetPasswordPost(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FrontHistoryApi - axios parameter creator
 * @export
 */
export const FrontHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 現在ログイン中の会員の検査履歴をページングします。 ページングが必要ない場合は、大きなSizeを設定してください。
         * @summary 会員の履歴一覧の取得
         * @param {PpTestResultControllerFrontSearchHistoryByUserRequest} frontSearchHistoryByUserRequest FrontSearchHistoryByUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontHistoryGet: async (frontSearchHistoryByUserRequest: PpTestResultControllerFrontSearchHistoryByUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'frontSearchHistoryByUserRequest' is not null or undefined
            assertParamExists('v1FrontHistoryGet', 'frontSearchHistoryByUserRequest', frontSearchHistoryByUserRequest)
            const localVarPath = `/v1/front/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(frontSearchHistoryByUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ペットIDを指定して、検査完了した履歴の一覧を降順で返します。<br> 自分のペットの検査のみ取得可能です。<br> 過去の履歴グラフの作成などに使用します。
         * @summary 特定ペットの検査履歴取得
         * @param {string} ppPetId ペットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontHistoryPetPpPetIdGet: async (ppPetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ppPetId' is not null or undefined
            assertParamExists('v1FrontHistoryPetPpPetIdGet', 'ppPetId', ppPetId)
            const localVarPath = `/v1/front/history-pet/{ppPetId}`
                .replace(`{${"ppPetId"}}`, encodeURIComponent(String(ppPetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検体IDを指定し、結果データ1件を取得します。<br> 自分の検査のみ取得可能です。<br> リスクスコアデータは非常に項目が多いです。<br> また、EG MIrRORのAPIのレスポンスが基本文字列なので、数値も文字列としています。必要に応じてキャストしてください。
         * @summary 検査履歴データ1件取得
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontHistorySampleIdGet: async (sampleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleId' is not null or undefined
            assertParamExists('v1FrontHistorySampleIdGet', 'sampleId', sampleId)
            const localVarPath = `/v1/front/history/{sampleId}`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontHistoryApi - functional programming interface
 * @export
 */
export const FrontHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 現在ログイン中の会員の検査履歴をページングします。 ページングが必要ない場合は、大きなSizeを設定してください。
         * @summary 会員の履歴一覧の取得
         * @param {PpTestResultControllerFrontSearchHistoryByUserRequest} frontSearchHistoryByUserRequest FrontSearchHistoryByUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontHistoryGet(frontSearchHistoryByUserRequest: PpTestResultControllerFrontSearchHistoryByUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestResultControllerFrontSearchHistoryByUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontHistoryGet(frontSearchHistoryByUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ペットIDを指定して、検査完了した履歴の一覧を降順で返します。<br> 自分のペットの検査のみ取得可能です。<br> 過去の履歴グラフの作成などに使用します。
         * @summary 特定ペットの検査履歴取得
         * @param {string} ppPetId ペットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontHistoryPetPpPetIdGet(ppPetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestResultControllerSearchHistoryPetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontHistoryPetPpPetIdGet(ppPetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検体IDを指定し、結果データ1件を取得します。<br> 自分の検査のみ取得可能です。<br> リスクスコアデータは非常に項目が多いです。<br> また、EG MIrRORのAPIのレスポンスが基本文字列なので、数値も文字列としています。必要に応じてキャストしてください。
         * @summary 検査履歴データ1件取得
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontHistorySampleIdGet(sampleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestResultControllerGetHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontHistorySampleIdGet(sampleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FrontHistoryApi - factory interface
 * @export
 */
export const FrontHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontHistoryApiFp(configuration)
    return {
        /**
         * 現在ログイン中の会員の検査履歴をページングします。 ページングが必要ない場合は、大きなSizeを設定してください。
         * @summary 会員の履歴一覧の取得
         * @param {PpTestResultControllerFrontSearchHistoryByUserRequest} frontSearchHistoryByUserRequest FrontSearchHistoryByUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontHistoryGet(frontSearchHistoryByUserRequest: PpTestResultControllerFrontSearchHistoryByUserRequest, options?: any): AxiosPromise<PpTestResultControllerFrontSearchHistoryByUserResponse> {
            return localVarFp.v1FrontHistoryGet(frontSearchHistoryByUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * ペットIDを指定して、検査完了した履歴の一覧を降順で返します。<br> 自分のペットの検査のみ取得可能です。<br> 過去の履歴グラフの作成などに使用します。
         * @summary 特定ペットの検査履歴取得
         * @param {string} ppPetId ペットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontHistoryPetPpPetIdGet(ppPetId: string, options?: any): AxiosPromise<PpTestResultControllerSearchHistoryPetResponse> {
            return localVarFp.v1FrontHistoryPetPpPetIdGet(ppPetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 検体IDを指定し、結果データ1件を取得します。<br> 自分の検査のみ取得可能です。<br> リスクスコアデータは非常に項目が多いです。<br> また、EG MIrRORのAPIのレスポンスが基本文字列なので、数値も文字列としています。必要に応じてキャストしてください。
         * @summary 検査履歴データ1件取得
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontHistorySampleIdGet(sampleId: string, options?: any): AxiosPromise<PpTestResultControllerGetHistoryResponse> {
            return localVarFp.v1FrontHistorySampleIdGet(sampleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FrontHistoryApi - object-oriented interface
 * @export
 * @class FrontHistoryApi
 * @extends {BaseAPI}
 */
export class FrontHistoryApi extends BaseAPI {
    /**
     * 現在ログイン中の会員の検査履歴をページングします。 ページングが必要ない場合は、大きなSizeを設定してください。
     * @summary 会員の履歴一覧の取得
     * @param {PpTestResultControllerFrontSearchHistoryByUserRequest} frontSearchHistoryByUserRequest FrontSearchHistoryByUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontHistoryApi
     */
    public v1FrontHistoryGet(frontSearchHistoryByUserRequest: PpTestResultControllerFrontSearchHistoryByUserRequest, options?: AxiosRequestConfig) {
        return FrontHistoryApiFp(this.configuration).v1FrontHistoryGet(frontSearchHistoryByUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ペットIDを指定して、検査完了した履歴の一覧を降順で返します。<br> 自分のペットの検査のみ取得可能です。<br> 過去の履歴グラフの作成などに使用します。
     * @summary 特定ペットの検査履歴取得
     * @param {string} ppPetId ペットID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontHistoryApi
     */
    public v1FrontHistoryPetPpPetIdGet(ppPetId: string, options?: AxiosRequestConfig) {
        return FrontHistoryApiFp(this.configuration).v1FrontHistoryPetPpPetIdGet(ppPetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検体IDを指定し、結果データ1件を取得します。<br> 自分の検査のみ取得可能です。<br> リスクスコアデータは非常に項目が多いです。<br> また、EG MIrRORのAPIのレスポンスが基本文字列なので、数値も文字列としています。必要に応じてキャストしてください。
     * @summary 検査履歴データ1件取得
     * @param {string} sampleId 検体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontHistoryApi
     */
    public v1FrontHistorySampleIdGet(sampleId: string, options?: AxiosRequestConfig) {
        return FrontHistoryApiFp(this.configuration).v1FrontHistorySampleIdGet(sampleId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FrontPpAnnouncementUserApi - axios parameter creator
 * @export
 */
export const FrontPpAnnouncementUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 表示可能なお知らせを全件返します。
         * @summary ユーザー向けお知らせ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpAnnouncementUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/front/pp_announcement_user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お知らせを既読状態に変更します。<br> 特に個別のお知らせIDを指定せず、現在表示可能なお知らせすべてを一括既読にします。<br> 何度実行しても問題ありません。
         * @summary ユーザー向けお知らせ既読処理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpAnnouncementUserOpenPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/front/pp_announcement_user/open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お知らせ未読数を返します。<br> 既読にならずに表示期間が終了したお知らせは除外されます。
         * @summary ユーザー向けお知らせ未読数取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpAnnouncementUserUnreadGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/front/pp_announcement_user/unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontPpAnnouncementUserApi - functional programming interface
 * @export
 */
export const FrontPpAnnouncementUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontPpAnnouncementUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 表示可能なお知らせを全件返します。
         * @summary ユーザー向けお知らせ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpAnnouncementUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpAnnouncementUserControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpAnnouncementUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お知らせを既読状態に変更します。<br> 特に個別のお知らせIDを指定せず、現在表示可能なお知らせすべてを一括既読にします。<br> 何度実行しても問題ありません。
         * @summary ユーザー向けお知らせ既読処理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpAnnouncementUserOpenPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpAnnouncementUserOpenPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お知らせ未読数を返します。<br> 既読にならずに表示期間が終了したお知らせは除外されます。
         * @summary ユーザー向けお知らせ未読数取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpAnnouncementUserUnreadGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpAnnouncementUserControllerFrontUnreadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpAnnouncementUserUnreadGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FrontPpAnnouncementUserApi - factory interface
 * @export
 */
export const FrontPpAnnouncementUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontPpAnnouncementUserApiFp(configuration)
    return {
        /**
         * 表示可能なお知らせを全件返します。
         * @summary ユーザー向けお知らせ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpAnnouncementUserGet(options?: any): AxiosPromise<PpAnnouncementUserControllerIndexResponse> {
            return localVarFp.v1FrontPpAnnouncementUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * お知らせを既読状態に変更します。<br> 特に個別のお知らせIDを指定せず、現在表示可能なお知らせすべてを一括既読にします。<br> 何度実行しても問題ありません。
         * @summary ユーザー向けお知らせ既読処理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpAnnouncementUserOpenPost(options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1FrontPpAnnouncementUserOpenPost(options).then((request) => request(axios, basePath));
        },
        /**
         * お知らせ未読数を返します。<br> 既読にならずに表示期間が終了したお知らせは除外されます。
         * @summary ユーザー向けお知らせ未読数取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpAnnouncementUserUnreadGet(options?: any): AxiosPromise<PpAnnouncementUserControllerFrontUnreadResponse> {
            return localVarFp.v1FrontPpAnnouncementUserUnreadGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FrontPpAnnouncementUserApi - object-oriented interface
 * @export
 * @class FrontPpAnnouncementUserApi
 * @extends {BaseAPI}
 */
export class FrontPpAnnouncementUserApi extends BaseAPI {
    /**
     * 表示可能なお知らせを全件返します。
     * @summary ユーザー向けお知らせ一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpAnnouncementUserApi
     */
    public v1FrontPpAnnouncementUserGet(options?: AxiosRequestConfig) {
        return FrontPpAnnouncementUserApiFp(this.configuration).v1FrontPpAnnouncementUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お知らせを既読状態に変更します。<br> 特に個別のお知らせIDを指定せず、現在表示可能なお知らせすべてを一括既読にします。<br> 何度実行しても問題ありません。
     * @summary ユーザー向けお知らせ既読処理
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpAnnouncementUserApi
     */
    public v1FrontPpAnnouncementUserOpenPost(options?: AxiosRequestConfig) {
        return FrontPpAnnouncementUserApiFp(this.configuration).v1FrontPpAnnouncementUserOpenPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お知らせ未読数を返します。<br> 既読にならずに表示期間が終了したお知らせは除外されます。
     * @summary ユーザー向けお知らせ未読数取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpAnnouncementUserApi
     */
    public v1FrontPpAnnouncementUserUnreadGet(options?: AxiosRequestConfig) {
        return FrontPpAnnouncementUserApiFp(this.configuration).v1FrontPpAnnouncementUserUnreadGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FrontPpContactApi - axios parameter creator
 * @export
 */
export const FrontPpContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 新規お問い合わせ
         * @param {PpContactControllerFrontCreateRequest} frontCreateRequest FrontCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpContactPost: async (frontCreateRequest: PpContactControllerFrontCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'frontCreateRequest' is not null or undefined
            assertParamExists('v1FrontPpContactPost', 'frontCreateRequest', frontCreateRequest)
            const localVarPath = `/v1/front/pp_contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(frontCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontPpContactApi - functional programming interface
 * @export
 */
export const FrontPpContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontPpContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 新規お問い合わせ
         * @param {PpContactControllerFrontCreateRequest} frontCreateRequest FrontCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpContactPost(frontCreateRequest: PpContactControllerFrontCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpContactPost(frontCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FrontPpContactApi - factory interface
 * @export
 */
export const FrontPpContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontPpContactApiFp(configuration)
    return {
        /**
         * 
         * @summary 新規お問い合わせ
         * @param {PpContactControllerFrontCreateRequest} frontCreateRequest FrontCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpContactPost(frontCreateRequest: PpContactControllerFrontCreateRequest, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1FrontPpContactPost(frontCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FrontPpContactApi - object-oriented interface
 * @export
 * @class FrontPpContactApi
 * @extends {BaseAPI}
 */
export class FrontPpContactApi extends BaseAPI {
    /**
     * 
     * @summary 新規お問い合わせ
     * @param {PpContactControllerFrontCreateRequest} frontCreateRequest FrontCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpContactApi
     */
    public v1FrontPpContactPost(frontCreateRequest: PpContactControllerFrontCreateRequest, options?: AxiosRequestConfig) {
        return FrontPpContactApiFp(this.configuration).v1FrontPpContactPost(frontCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FrontPpFaqApi - axios parameter creator
 * @export
 */
export const FrontPpFaqApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary FAQ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpFaqGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/front/pp_faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontPpFaqApi - functional programming interface
 * @export
 */
export const FrontPpFaqApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontPpFaqApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary FAQ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpFaqGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpFaqControllerFrontIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpFaqGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FrontPpFaqApi - factory interface
 * @export
 */
export const FrontPpFaqApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontPpFaqApiFp(configuration)
    return {
        /**
         * 
         * @summary FAQ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpFaqGet(options?: any): AxiosPromise<PpFaqControllerFrontIndexResponse> {
            return localVarFp.v1FrontPpFaqGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FrontPpFaqApi - object-oriented interface
 * @export
 * @class FrontPpFaqApi
 * @extends {BaseAPI}
 */
export class FrontPpFaqApi extends BaseAPI {
    /**
     * 
     * @summary FAQ一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpFaqApi
     */
    public v1FrontPpFaqGet(options?: AxiosRequestConfig) {
        return FrontPpFaqApiFp(this.configuration).v1FrontPpFaqGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FrontPpPetApi - axios parameter creator
 * @export
 */
export const FrontPpPetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 会員登録時のペット新規登録も兼ねるので、認証をかけておりません。<br /> 複数のペットを登録する場合は、複数回リクエストを送信してください。<br /> また、ログイン後に実行する場合でも、`pp_user_id`を指定してください。
         * @summary ペット新規登録
         * @param {PpPetControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpPetCreatePost: async (createRequest: PpPetControllerCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequest' is not null or undefined
            assertParamExists('v1FrontPpPetCreatePost', 'createRequest', createRequest)
            const localVarPath = `/v1/front/pp_pet/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ペット情報編集
         * @param {string} id 更新対象のペットID
         * @param {PpPetControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpPetEditIdPost: async (id: string, editRequest: PpPetControllerEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1FrontPpPetEditIdPost', 'id', id)
            // verify required parameter 'editRequest' is not null or undefined
            assertParamExists('v1FrontPpPetEditIdPost', 'editRequest', editRequest)
            const localVarPath = `/v1/front/pp_pet/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ペット一覧（全件取得）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpPetGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/front/pp_pet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ペット一件取得
         * @param {string} id ペットのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpPetGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1FrontPpPetGetIdGet', 'id', id)
            const localVarPath = `/v1/front/pp_pet/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ペットIDから、申し込み完了した検査があるかをチェックします。 検査がある場合、種別の変更をできない仕様とします。
         * @summary 申し込み完了した検査があるかをチェック
         * @param {number} ppPetId ペットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpPetHasTestPpPetIdGet: async (ppPetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ppPetId' is not null or undefined
            assertParamExists('v1FrontPpPetHasTestPpPetIdGet', 'ppPetId', ppPetId)
            const localVarPath = `/v1/front/pp_pet/has_test/{ppPetId}`
                .replace(`{${"ppPetId"}}`, encodeURIComponent(String(ppPetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontPpPetApi - functional programming interface
 * @export
 */
export const FrontPpPetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontPpPetApiAxiosParamCreator(configuration)
    return {
        /**
         * 会員登録時のペット新規登録も兼ねるので、認証をかけておりません。<br /> 複数のペットを登録する場合は、複数回リクエストを送信してください。<br /> また、ログイン後に実行する場合でも、`pp_user_id`を指定してください。
         * @summary ペット新規登録
         * @param {PpPetControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpPetCreatePost(createRequest: PpPetControllerCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpPetControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpPetCreatePost(createRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ペット情報編集
         * @param {string} id 更新対象のペットID
         * @param {PpPetControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpPetEditIdPost(id: string, editRequest: PpPetControllerEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpPetControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpPetEditIdPost(id, editRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ペット一覧（全件取得）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpPetGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpPetControllerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpPetGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ペット一件取得
         * @param {string} id ペットのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpPetGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpPetControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpPetGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ペットIDから、申し込み完了した検査があるかをチェックします。 検査がある場合、種別の変更をできない仕様とします。
         * @summary 申し込み完了した検査があるかをチェック
         * @param {number} ppPetId ペットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpPetHasTestPpPetIdGet(ppPetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpPetControllerHasTestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpPetHasTestPpPetIdGet(ppPetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FrontPpPetApi - factory interface
 * @export
 */
export const FrontPpPetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontPpPetApiFp(configuration)
    return {
        /**
         * 会員登録時のペット新規登録も兼ねるので、認証をかけておりません。<br /> 複数のペットを登録する場合は、複数回リクエストを送信してください。<br /> また、ログイン後に実行する場合でも、`pp_user_id`を指定してください。
         * @summary ペット新規登録
         * @param {PpPetControllerCreateRequest} createRequest CreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpPetCreatePost(createRequest: PpPetControllerCreateRequest, options?: any): AxiosPromise<PpPetControllerGetResponse> {
            return localVarFp.v1FrontPpPetCreatePost(createRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ペット情報編集
         * @param {string} id 更新対象のペットID
         * @param {PpPetControllerEditRequest} editRequest EditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpPetEditIdPost(id: string, editRequest: PpPetControllerEditRequest, options?: any): AxiosPromise<PpPetControllerGetResponse> {
            return localVarFp.v1FrontPpPetEditIdPost(id, editRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ペット一覧（全件取得）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpPetGet(options?: any): AxiosPromise<PpPetControllerIndexResponse> {
            return localVarFp.v1FrontPpPetGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ペット一件取得
         * @param {string} id ペットのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpPetGetIdGet(id: string, options?: any): AxiosPromise<PpPetControllerGetResponse> {
            return localVarFp.v1FrontPpPetGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ペットIDから、申し込み完了した検査があるかをチェックします。 検査がある場合、種別の変更をできない仕様とします。
         * @summary 申し込み完了した検査があるかをチェック
         * @param {number} ppPetId ペットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpPetHasTestPpPetIdGet(ppPetId: number, options?: any): AxiosPromise<PpPetControllerHasTestResponse> {
            return localVarFp.v1FrontPpPetHasTestPpPetIdGet(ppPetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FrontPpPetApi - object-oriented interface
 * @export
 * @class FrontPpPetApi
 * @extends {BaseAPI}
 */
export class FrontPpPetApi extends BaseAPI {
    /**
     * 会員登録時のペット新規登録も兼ねるので、認証をかけておりません。<br /> 複数のペットを登録する場合は、複数回リクエストを送信してください。<br /> また、ログイン後に実行する場合でも、`pp_user_id`を指定してください。
     * @summary ペット新規登録
     * @param {PpPetControllerCreateRequest} createRequest CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpPetApi
     */
    public v1FrontPpPetCreatePost(createRequest: PpPetControllerCreateRequest, options?: AxiosRequestConfig) {
        return FrontPpPetApiFp(this.configuration).v1FrontPpPetCreatePost(createRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ペット情報編集
     * @param {string} id 更新対象のペットID
     * @param {PpPetControllerEditRequest} editRequest EditRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpPetApi
     */
    public v1FrontPpPetEditIdPost(id: string, editRequest: PpPetControllerEditRequest, options?: AxiosRequestConfig) {
        return FrontPpPetApiFp(this.configuration).v1FrontPpPetEditIdPost(id, editRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ペット一覧（全件取得）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpPetApi
     */
    public v1FrontPpPetGet(options?: AxiosRequestConfig) {
        return FrontPpPetApiFp(this.configuration).v1FrontPpPetGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ペット一件取得
     * @param {string} id ペットのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpPetApi
     */
    public v1FrontPpPetGetIdGet(id: string, options?: AxiosRequestConfig) {
        return FrontPpPetApiFp(this.configuration).v1FrontPpPetGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ペットIDから、申し込み完了した検査があるかをチェックします。 検査がある場合、種別の変更をできない仕様とします。
     * @summary 申し込み完了した検査があるかをチェック
     * @param {number} ppPetId ペットID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpPetApi
     */
    public v1FrontPpPetHasTestPpPetIdGet(ppPetId: number, options?: AxiosRequestConfig) {
        return FrontPpPetApiFp(this.configuration).v1FrontPpPetHasTestPpPetIdGet(ppPetId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FrontPpTestKitApi - axios parameter creator
 * @export
 */
export const FrontPpTestKitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 検査申込:1 / 検体ID/パスワードの検証、確保
         * @param {PpTestKitControllerApplyRequest} applyRequest ApplyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpTestKitApplyPost: async (applyRequest: PpTestKitControllerApplyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyRequest' is not null or undefined
            assertParamExists('v1FrontPpTestKitApplyPost', 'applyRequest', applyRequest)
            const localVarPath = `/v1/front/pp_test_kit/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 確認画面で「申し込み完了」ボタンを押した時にリクエストを投げてください。 このAPIの実行により、申し込みが完了となります。
         * @summary 検査申込:4 / 確認完了・申し込み完了
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpTestKitConfirmSampleIdPost: async (sampleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleId' is not null or undefined
            assertParamExists('v1FrontPpTestKitConfirmSampleIdPost', 'sampleId', sampleId)
            const localVarPath = `/v1/front/pp_test_kit/confirm/{sampleId}`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検査キット管理明細を1件取得します。<Br> 検査申込：1が完了して、ユーザーIDとの紐付けが完了しているもののみ取得可能となります。
         * @summary 腸内検査キット管理明細一件取得
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpTestKitGetSampleIdGet: async (sampleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleId' is not null or undefined
            assertParamExists('v1FrontPpTestKitGetSampleIdGet', 'sampleId', sampleId)
            const localVarPath = `/v1/front/pp_test_kit/get/{sampleId}`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 項目が多いのでご注意ください。<br /> 項目の詳細は、以下DB仕様書に準拠しています。<br /> https://docs.google.com/spreadsheets/d/1TI2qwIw_vHW96UdwkSC8p9xRNX685-dv/edit#gid=70003530 <br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
         * @summary 検査申込:2 / 入力1 / 検査情報、体調に関する基礎情報の回答を保存
         * @param {string} sampleId 検体ID
         * @param {PpTestKitControllerStep1Request} step1Request Step1Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpTestKitStep1SampleIdPost: async (sampleId: string, step1Request: PpTestKitControllerStep1Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleId' is not null or undefined
            assertParamExists('v1FrontPpTestKitStep1SampleIdPost', 'sampleId', sampleId)
            // verify required parameter 'step1Request' is not null or undefined
            assertParamExists('v1FrontPpTestKitStep1SampleIdPost', 'step1Request', step1Request)
            const localVarPath = `/v1/front/pp_test_kit/step1/{sampleId}`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(step1Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * こちらも項目が多いのでご注意ください。<br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
         * @summary 検査申込:3 / 入力2 / 生活習慣に関する基礎情報の回答を保存
         * @param {string} sampleId 検体ID
         * @param {PpTestKitControllerStep2Request} step2Request Step2Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpTestKitStep2SampleIdPost: async (sampleId: string, step2Request: PpTestKitControllerStep2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleId' is not null or undefined
            assertParamExists('v1FrontPpTestKitStep2SampleIdPost', 'sampleId', sampleId)
            // verify required parameter 'step2Request' is not null or undefined
            assertParamExists('v1FrontPpTestKitStep2SampleIdPost', 'step2Request', step2Request)
            const localVarPath = `/v1/front/pp_test_kit/step2/{sampleId}`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(step2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontPpTestKitApi - functional programming interface
 * @export
 */
export const FrontPpTestKitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontPpTestKitApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 検査申込:1 / 検体ID/パスワードの検証、確保
         * @param {PpTestKitControllerApplyRequest} applyRequest ApplyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpTestKitApplyPost(applyRequest: PpTestKitControllerApplyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerApplyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpTestKitApplyPost(applyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 確認画面で「申し込み完了」ボタンを押した時にリクエストを投げてください。 このAPIの実行により、申し込みが完了となります。
         * @summary 検査申込:4 / 確認完了・申し込み完了
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpTestKitConfirmSampleIdPost(sampleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpTestKitConfirmSampleIdPost(sampleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検査キット管理明細を1件取得します。<Br> 検査申込：1が完了して、ユーザーIDとの紐付けが完了しているもののみ取得可能となります。
         * @summary 腸内検査キット管理明細一件取得
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpTestKitGetSampleIdGet(sampleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpTestKitGetSampleIdGet(sampleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 項目が多いのでご注意ください。<br /> 項目の詳細は、以下DB仕様書に準拠しています。<br /> https://docs.google.com/spreadsheets/d/1TI2qwIw_vHW96UdwkSC8p9xRNX685-dv/edit#gid=70003530 <br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
         * @summary 検査申込:2 / 入力1 / 検査情報、体調に関する基礎情報の回答を保存
         * @param {string} sampleId 検体ID
         * @param {PpTestKitControllerStep1Request} step1Request Step1Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpTestKitStep1SampleIdPost(sampleId: string, step1Request: PpTestKitControllerStep1Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpTestKitStep1SampleIdPost(sampleId, step1Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * こちらも項目が多いのでご注意ください。<br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
         * @summary 検査申込:3 / 入力2 / 生活習慣に関する基礎情報の回答を保存
         * @param {string} sampleId 検体ID
         * @param {PpTestKitControllerStep2Request} step2Request Step2Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpTestKitStep2SampleIdPost(sampleId: string, step2Request: PpTestKitControllerStep2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpTestKitControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpTestKitStep2SampleIdPost(sampleId, step2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FrontPpTestKitApi - factory interface
 * @export
 */
export const FrontPpTestKitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontPpTestKitApiFp(configuration)
    return {
        /**
         * 
         * @summary 検査申込:1 / 検体ID/パスワードの検証、確保
         * @param {PpTestKitControllerApplyRequest} applyRequest ApplyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpTestKitApplyPost(applyRequest: PpTestKitControllerApplyRequest, options?: any): AxiosPromise<PpTestKitControllerApplyResponse> {
            return localVarFp.v1FrontPpTestKitApplyPost(applyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 確認画面で「申し込み完了」ボタンを押した時にリクエストを投げてください。 このAPIの実行により、申し込みが完了となります。
         * @summary 検査申込:4 / 確認完了・申し込み完了
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpTestKitConfirmSampleIdPost(sampleId: string, options?: any): AxiosPromise<PpTestKitControllerGetResponse> {
            return localVarFp.v1FrontPpTestKitConfirmSampleIdPost(sampleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 検査キット管理明細を1件取得します。<Br> 検査申込：1が完了して、ユーザーIDとの紐付けが完了しているもののみ取得可能となります。
         * @summary 腸内検査キット管理明細一件取得
         * @param {string} sampleId 検体ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpTestKitGetSampleIdGet(sampleId: string, options?: any): AxiosPromise<PpTestKitControllerGetResponse> {
            return localVarFp.v1FrontPpTestKitGetSampleIdGet(sampleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 項目が多いのでご注意ください。<br /> 項目の詳細は、以下DB仕様書に準拠しています。<br /> https://docs.google.com/spreadsheets/d/1TI2qwIw_vHW96UdwkSC8p9xRNX685-dv/edit#gid=70003530 <br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
         * @summary 検査申込:2 / 入力1 / 検査情報、体調に関する基礎情報の回答を保存
         * @param {string} sampleId 検体ID
         * @param {PpTestKitControllerStep1Request} step1Request Step1Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpTestKitStep1SampleIdPost(sampleId: string, step1Request: PpTestKitControllerStep1Request, options?: any): AxiosPromise<PpTestKitControllerGetResponse> {
            return localVarFp.v1FrontPpTestKitStep1SampleIdPost(sampleId, step1Request, options).then((request) => request(axios, basePath));
        },
        /**
         * こちらも項目が多いのでご注意ください。<br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
         * @summary 検査申込:3 / 入力2 / 生活習慣に関する基礎情報の回答を保存
         * @param {string} sampleId 検体ID
         * @param {PpTestKitControllerStep2Request} step2Request Step2Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpTestKitStep2SampleIdPost(sampleId: string, step2Request: PpTestKitControllerStep2Request, options?: any): AxiosPromise<PpTestKitControllerGetResponse> {
            return localVarFp.v1FrontPpTestKitStep2SampleIdPost(sampleId, step2Request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FrontPpTestKitApi - object-oriented interface
 * @export
 * @class FrontPpTestKitApi
 * @extends {BaseAPI}
 */
export class FrontPpTestKitApi extends BaseAPI {
    /**
     * 
     * @summary 検査申込:1 / 検体ID/パスワードの検証、確保
     * @param {PpTestKitControllerApplyRequest} applyRequest ApplyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpTestKitApi
     */
    public v1FrontPpTestKitApplyPost(applyRequest: PpTestKitControllerApplyRequest, options?: AxiosRequestConfig) {
        return FrontPpTestKitApiFp(this.configuration).v1FrontPpTestKitApplyPost(applyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 確認画面で「申し込み完了」ボタンを押した時にリクエストを投げてください。 このAPIの実行により、申し込みが完了となります。
     * @summary 検査申込:4 / 確認完了・申し込み完了
     * @param {string} sampleId 検体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpTestKitApi
     */
    public v1FrontPpTestKitConfirmSampleIdPost(sampleId: string, options?: AxiosRequestConfig) {
        return FrontPpTestKitApiFp(this.configuration).v1FrontPpTestKitConfirmSampleIdPost(sampleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検査キット管理明細を1件取得します。<Br> 検査申込：1が完了して、ユーザーIDとの紐付けが完了しているもののみ取得可能となります。
     * @summary 腸内検査キット管理明細一件取得
     * @param {string} sampleId 検体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpTestKitApi
     */
    public v1FrontPpTestKitGetSampleIdGet(sampleId: string, options?: AxiosRequestConfig) {
        return FrontPpTestKitApiFp(this.configuration).v1FrontPpTestKitGetSampleIdGet(sampleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 項目が多いのでご注意ください。<br /> 項目の詳細は、以下DB仕様書に準拠しています。<br /> https://docs.google.com/spreadsheets/d/1TI2qwIw_vHW96UdwkSC8p9xRNX685-dv/edit#gid=70003530 <br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
     * @summary 検査申込:2 / 入力1 / 検査情報、体調に関する基礎情報の回答を保存
     * @param {string} sampleId 検体ID
     * @param {PpTestKitControllerStep1Request} step1Request Step1Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpTestKitApi
     */
    public v1FrontPpTestKitStep1SampleIdPost(sampleId: string, step1Request: PpTestKitControllerStep1Request, options?: AxiosRequestConfig) {
        return FrontPpTestKitApiFp(this.configuration).v1FrontPpTestKitStep1SampleIdPost(sampleId, step1Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * こちらも項目が多いのでご注意ください。<br /> なお、送信項目が非常に多いのでそれぞれ細かく `nullable` などの設定を行うと、いろいろ煩雑化しますので、値があろうが無かろうがとにかく全項目を送信いただくといいと思います。
     * @summary 検査申込:3 / 入力2 / 生活習慣に関する基礎情報の回答を保存
     * @param {string} sampleId 検体ID
     * @param {PpTestKitControllerStep2Request} step2Request Step2Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpTestKitApi
     */
    public v1FrontPpTestKitStep2SampleIdPost(sampleId: string, step2Request: PpTestKitControllerStep2Request, options?: AxiosRequestConfig) {
        return FrontPpTestKitApiFp(this.configuration).v1FrontPpTestKitStep2SampleIdPost(sampleId, step2Request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FrontPpUserApi - axios parameter creator
 * @export
 */
export const FrontPpUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * メールアドレス、パスワードの変更はできません。
         * @summary 会員情報編集
         * @param {PpUserControllerFrontEditRequest} frontEditRequest FrontEditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpUserEditPost: async (frontEditRequest: PpUserControllerFrontEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'frontEditRequest' is not null or undefined
            assertParamExists('v1FrontPpUserEditPost', 'frontEditRequest', frontEditRequest)
            const localVarPath = `/v1/front/pp_user/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(frontEditRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新規登録時、メールで送付された認証コードで本人確認を行います。<br /> 認証成功時には、ログイン時と同じレスポンスを返します。<br/> なおトークンは正しいものの、認証期限が切れている場合は、504（Gateway Timeout）レスポンスを返します。<br /> その場合は、トークンを /v1/front/resend にPOSTすることで認証メールを再送できます。
         * @summary 本人認証
         * @param {PpUserControllerFrontConfirmationRequest} frontConfirmationRequest FrontConfirmationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontRegisterConfirmationPost: async (frontConfirmationRequest: PpUserControllerFrontConfirmationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'frontConfirmationRequest' is not null or undefined
            assertParamExists('v1FrontRegisterConfirmationPost', 'frontConfirmationRequest', frontConfirmationRequest)
            const localVarPath = `/v1/front/register_confirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(frontConfirmationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 画面上、ペット情報の登録も続きますが、先にこのAPIに `is_only_validate: true` でリクエストを投げてバリデーションを行ってから次のページに進んでください。<br> また、ペット情報を登録する前に、`is_only_validate: false`で会員情報を登録し、返される会員のID `pp_user.id`を追加してペット情報を送信してください。<Br> 登録が完了すると、本人確認メールが送信されます。<br>
         * @summary 会員新規登録
         * @param {PpUserControllerFrontRegisterRequest} frontRegisterRequest FrontRegisterRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontRegisterPost: async (frontRegisterRequest: PpUserControllerFrontRegisterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'frontRegisterRequest' is not null or undefined
            assertParamExists('v1FrontRegisterPost', 'frontRegisterRequest', frontRegisterRequest)
            const localVarPath = `/v1/front/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(frontRegisterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 期限の切れたトークンを送信することで、ユーザー情報を取得します。<br /> 認証成功時には、新しいトークンを発行してメールを送信します。
         * @summary 本人認証メール再送処理
         * @param {PpUserControllerFrontConfirmationRequest} frontConfirmationRequest FrontConfirmationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontResendPost: async (frontConfirmationRequest: PpUserControllerFrontConfirmationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'frontConfirmationRequest' is not null or undefined
            assertParamExists('v1FrontResendPost', 'frontConfirmationRequest', frontConfirmationRequest)
            const localVarPath = `/v1/front/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(frontConfirmationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontPpUserApi - functional programming interface
 * @export
 */
export const FrontPpUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontPpUserApiAxiosParamCreator(configuration)
    return {
        /**
         * メールアドレス、パスワードの変更はできません。
         * @summary 会員情報編集
         * @param {PpUserControllerFrontEditRequest} frontEditRequest FrontEditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontPpUserEditPost(frontEditRequest: PpUserControllerFrontEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpUserControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontPpUserEditPost(frontEditRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 新規登録時、メールで送付された認証コードで本人確認を行います。<br /> 認証成功時には、ログイン時と同じレスポンスを返します。<br/> なおトークンは正しいものの、認証期限が切れている場合は、504（Gateway Timeout）レスポンスを返します。<br /> その場合は、トークンを /v1/front/resend にPOSTすることで認証メールを再送できます。
         * @summary 本人認証
         * @param {PpUserControllerFrontConfirmationRequest} frontConfirmationRequest FrontConfirmationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontRegisterConfirmationPost(frontConfirmationRequest: PpUserControllerFrontConfirmationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SephirahModulesSephirahAuthCertificate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontRegisterConfirmationPost(frontConfirmationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 画面上、ペット情報の登録も続きますが、先にこのAPIに `is_only_validate: true` でリクエストを投げてバリデーションを行ってから次のページに進んでください。<br> また、ペット情報を登録する前に、`is_only_validate: false`で会員情報を登録し、返される会員のID `pp_user.id`を追加してペット情報を送信してください。<Br> 登録が完了すると、本人確認メールが送信されます。<br>
         * @summary 会員新規登録
         * @param {PpUserControllerFrontRegisterRequest} frontRegisterRequest FrontRegisterRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontRegisterPost(frontRegisterRequest: PpUserControllerFrontRegisterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpUserControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontRegisterPost(frontRegisterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 期限の切れたトークンを送信することで、ユーザー情報を取得します。<br /> 認証成功時には、新しいトークンを発行してメールを送信します。
         * @summary 本人認証メール再送処理
         * @param {PpUserControllerFrontConfirmationRequest} frontConfirmationRequest FrontConfirmationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FrontResendPost(frontConfirmationRequest: PpUserControllerFrontConfirmationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FrontResendPost(frontConfirmationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FrontPpUserApi - factory interface
 * @export
 */
export const FrontPpUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontPpUserApiFp(configuration)
    return {
        /**
         * メールアドレス、パスワードの変更はできません。
         * @summary 会員情報編集
         * @param {PpUserControllerFrontEditRequest} frontEditRequest FrontEditRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontPpUserEditPost(frontEditRequest: PpUserControllerFrontEditRequest, options?: any): AxiosPromise<PpUserControllerGetResponse> {
            return localVarFp.v1FrontPpUserEditPost(frontEditRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 新規登録時、メールで送付された認証コードで本人確認を行います。<br /> 認証成功時には、ログイン時と同じレスポンスを返します。<br/> なおトークンは正しいものの、認証期限が切れている場合は、504（Gateway Timeout）レスポンスを返します。<br /> その場合は、トークンを /v1/front/resend にPOSTすることで認証メールを再送できます。
         * @summary 本人認証
         * @param {PpUserControllerFrontConfirmationRequest} frontConfirmationRequest FrontConfirmationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontRegisterConfirmationPost(frontConfirmationRequest: PpUserControllerFrontConfirmationRequest, options?: any): AxiosPromise<SephirahModulesSephirahAuthCertificate> {
            return localVarFp.v1FrontRegisterConfirmationPost(frontConfirmationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 画面上、ペット情報の登録も続きますが、先にこのAPIに `is_only_validate: true` でリクエストを投げてバリデーションを行ってから次のページに進んでください。<br> また、ペット情報を登録する前に、`is_only_validate: false`で会員情報を登録し、返される会員のID `pp_user.id`を追加してペット情報を送信してください。<Br> 登録が完了すると、本人確認メールが送信されます。<br>
         * @summary 会員新規登録
         * @param {PpUserControllerFrontRegisterRequest} frontRegisterRequest FrontRegisterRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontRegisterPost(frontRegisterRequest: PpUserControllerFrontRegisterRequest, options?: any): AxiosPromise<PpUserControllerGetResponse> {
            return localVarFp.v1FrontRegisterPost(frontRegisterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 期限の切れたトークンを送信することで、ユーザー情報を取得します。<br /> 認証成功時には、新しいトークンを発行してメールを送信します。
         * @summary 本人認証メール再送処理
         * @param {PpUserControllerFrontConfirmationRequest} frontConfirmationRequest FrontConfirmationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FrontResendPost(frontConfirmationRequest: PpUserControllerFrontConfirmationRequest, options?: any): AxiosPromise<ResponseErrorResponse> {
            return localVarFp.v1FrontResendPost(frontConfirmationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FrontPpUserApi - object-oriented interface
 * @export
 * @class FrontPpUserApi
 * @extends {BaseAPI}
 */
export class FrontPpUserApi extends BaseAPI {
    /**
     * メールアドレス、パスワードの変更はできません。
     * @summary 会員情報編集
     * @param {PpUserControllerFrontEditRequest} frontEditRequest FrontEditRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpUserApi
     */
    public v1FrontPpUserEditPost(frontEditRequest: PpUserControllerFrontEditRequest, options?: AxiosRequestConfig) {
        return FrontPpUserApiFp(this.configuration).v1FrontPpUserEditPost(frontEditRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新規登録時、メールで送付された認証コードで本人確認を行います。<br /> 認証成功時には、ログイン時と同じレスポンスを返します。<br/> なおトークンは正しいものの、認証期限が切れている場合は、504（Gateway Timeout）レスポンスを返します。<br /> その場合は、トークンを /v1/front/resend にPOSTすることで認証メールを再送できます。
     * @summary 本人認証
     * @param {PpUserControllerFrontConfirmationRequest} frontConfirmationRequest FrontConfirmationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpUserApi
     */
    public v1FrontRegisterConfirmationPost(frontConfirmationRequest: PpUserControllerFrontConfirmationRequest, options?: AxiosRequestConfig) {
        return FrontPpUserApiFp(this.configuration).v1FrontRegisterConfirmationPost(frontConfirmationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 画面上、ペット情報の登録も続きますが、先にこのAPIに `is_only_validate: true` でリクエストを投げてバリデーションを行ってから次のページに進んでください。<br> また、ペット情報を登録する前に、`is_only_validate: false`で会員情報を登録し、返される会員のID `pp_user.id`を追加してペット情報を送信してください。<Br> 登録が完了すると、本人確認メールが送信されます。<br>
     * @summary 会員新規登録
     * @param {PpUserControllerFrontRegisterRequest} frontRegisterRequest FrontRegisterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpUserApi
     */
    public v1FrontRegisterPost(frontRegisterRequest: PpUserControllerFrontRegisterRequest, options?: AxiosRequestConfig) {
        return FrontPpUserApiFp(this.configuration).v1FrontRegisterPost(frontRegisterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 期限の切れたトークンを送信することで、ユーザー情報を取得します。<br /> 認証成功時には、新しいトークンを発行してメールを送信します。
     * @summary 本人認証メール再送処理
     * @param {PpUserControllerFrontConfirmationRequest} frontConfirmationRequest FrontConfirmationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPpUserApi
     */
    public v1FrontResendPost(frontConfirmationRequest: PpUserControllerFrontConfirmationRequest, options?: AxiosRequestConfig) {
        return FrontPpUserApiFp(this.configuration).v1FrontResendPost(frontConfirmationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PpMstBreedApi - axios parameter creator
 * @export
 */
export const PpMstBreedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * URLで指定した主キーの品種情報を返します。 なお、このAPIは認証なしでアクセス可能です。
         * @summary 品種一件取得
         * @param {string} id 品種ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PpMstBreedGetIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1PpMstBreedGetIdGet', 'id', id)
            const localVarPath = `/v1/pp_mst_breed/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 特に引数はなしで全データを返しますので、受け取り側でペット種別により出し分けてください。 なお、このAPIは認証なしでアクセス可能です。
         * @summary 品種リスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PpMstBreedListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pp_mst_breed/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PpMstBreedApi - functional programming interface
 * @export
 */
export const PpMstBreedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PpMstBreedApiAxiosParamCreator(configuration)
    return {
        /**
         * URLで指定した主キーの品種情報を返します。 なお、このAPIは認証なしでアクセス可能です。
         * @summary 品種一件取得
         * @param {string} id 品種ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PpMstBreedGetIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpMstBreedControllerGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PpMstBreedGetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 特に引数はなしで全データを返しますので、受け取り側でペット種別により出し分けてください。 なお、このAPIは認証なしでアクセス可能です。
         * @summary 品種リスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PpMstBreedListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PpMstBreedModelGetListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PpMstBreedListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PpMstBreedApi - factory interface
 * @export
 */
export const PpMstBreedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PpMstBreedApiFp(configuration)
    return {
        /**
         * URLで指定した主キーの品種情報を返します。 なお、このAPIは認証なしでアクセス可能です。
         * @summary 品種一件取得
         * @param {string} id 品種ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PpMstBreedGetIdGet(id: string, options?: any): AxiosPromise<PpMstBreedControllerGetResponse> {
            return localVarFp.v1PpMstBreedGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 特に引数はなしで全データを返しますので、受け取り側でペット種別により出し分けてください。 なお、このAPIは認証なしでアクセス可能です。
         * @summary 品種リスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PpMstBreedListGet(options?: any): AxiosPromise<Array<PpMstBreedModelGetListResponse>> {
            return localVarFp.v1PpMstBreedListGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PpMstBreedApi - object-oriented interface
 * @export
 * @class PpMstBreedApi
 * @extends {BaseAPI}
 */
export class PpMstBreedApi extends BaseAPI {
    /**
     * URLで指定した主キーの品種情報を返します。 なお、このAPIは認証なしでアクセス可能です。
     * @summary 品種一件取得
     * @param {string} id 品種ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PpMstBreedApi
     */
    public v1PpMstBreedGetIdGet(id: string, options?: AxiosRequestConfig) {
        return PpMstBreedApiFp(this.configuration).v1PpMstBreedGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 特に引数はなしで全データを返しますので、受け取り側でペット種別により出し分けてください。 なお、このAPIは認証なしでアクセス可能です。
     * @summary 品種リスト取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PpMstBreedApi
     */
    public v1PpMstBreedListGet(options?: AxiosRequestConfig) {
        return PpMstBreedApiFp(this.configuration).v1PpMstBreedListGet(options).then((request) => request(this.axios, this.basePath));
    }
}


