import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {H1, H3} from "../common/Headline";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {AxiosResponse} from "axios";
import {ErrorMessage} from "../common/ErrorMessage";
import {adminPpMstGenericRcmndImageApi} from "../../api/Api";
import {PpMstGenericRcmndImageControllerCreateRequest, TypesPpMstGenericRcmndImageData} from "../../generated";
import {useGenericRcmndGroup} from "../../hooks/useGenericRcmndGroup";
import {GetIntestinalEnvironmentTypes} from "../../utility/Utility";
import {ImageForm} from "./partial/ImageForm";
import {ImageModal} from "../pp_mst_rcmnd_product/partial/ImageModal";

export const PpMstGenericRcmndImageEdit = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppMstGenericRcmndImage, setPpMstGenericRcmndImage] = useState<TypesPpMstGenericRcmndImageData | null>(null)
    const [animalType, setAnimalType] = useState<string>("");
    const [intestinalEnvironmentType, setIntestinalEnvironmentType] = useState<string>("");
    const [genericRcmndGroupID, setGenericRcmndGroupID] = useState<string>("");
    const [imageOverview, setImageOverview] = useState<string>("");
    const [imagePath, setImagePath] = useState<string>("");
    const [displayOrder, setDisplayOrder] = useState<string>("");
    const [isModalImg, setIsModalImg] = React.useState<boolean>(false);
    const [viewPath, setViewPath] = React.useState<string>("");
    const [modalTitle, setModalTitle] = React.useState<string>("");

    const params = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    // 汎用レコメンドグループ
    const ppMstGenericRcmndGroups = useGenericRcmndGroup();

    useEffect(() => {

        if (params.id) {
            // IDが指定されている場合はデータを取得する
            getData(params.id);
        }

    }, [params]);

    const getData = (id: string) => {

        setShowSpinner(true);

        adminPpMstGenericRcmndImageApi
            .v1AdminPpMstGenericRcmndImageGetIdGet(id)
            .then(({data}) => {
                setPpMstGenericRcmndImage(data.pp_mst_generic_rcmnd_image);
                setAnimalType(data.pp_mst_generic_rcmnd_image.animal_type);
                setIntestinalEnvironmentType(data.pp_mst_generic_rcmnd_image.intestinal_environment_type);
                setGenericRcmndGroupID(String(data.pp_mst_generic_rcmnd_image.generic_rcmnd_group_id));
                setImageOverview(data.pp_mst_generic_rcmnd_image.image_overview);
                setImagePath(data.pp_mst_generic_rcmnd_image.image_path);
                setDisplayOrder(String(data.pp_mst_generic_rcmnd_image.display_order));
            })
            .catch((err) => {
                console.log(err);

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/pp_mst_generic_rcmnd_group");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };


    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const req: PpMstGenericRcmndImageControllerCreateRequest = {
            generic_rcmnd_group_id: parseInt(genericRcmndGroupID),
            animal_type: animalType,
            intestinal_environment_type: intestinalEnvironmentType,
            image_overview: imageOverview,
            image_path: imagePath,
            display_order: parseInt(displayOrder),
        };

        setShowSpinner(true);

        let promise: Promise<void | AxiosResponse<any, any>>;

        if (ppMstGenericRcmndImage) {
            // 編集時
            promise = adminPpMstGenericRcmndImageApi
                .v1AdminPpMstGenericRcmndImageEditIdPost(String(ppMstGenericRcmndImage.id), req)
                .then(() => {
                    setErrors({});
                    navigate(`/pp_mst_generic_rcmnd_image?message_type=edit_complete`);
                });

        } else {
            // 新規登録時
            promise = adminPpMstGenericRcmndImageApi
                .v1AdminPpMstGenericRcmndImageCreatePost(req)
                .then(() => {
                    setErrors({});
                    navigate(`/pp_mst_generic_rcmnd_image?message_type=create_complete`);
                });
        }

        promise
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {

        switch (e.currentTarget.name) {
            case "generic_rcmnd_group_id":
                setGenericRcmndGroupID(e.currentTarget.value);
                break;
            case "animal_type":
                setAnimalType(e.currentTarget.value);
                break;
            case "intestinal_environment_type":
                setIntestinalEnvironmentType(e.currentTarget.value);
                break;
            case "image_overview":
                setImageOverview(e.currentTarget.value);
                break;
            case "image_path":
                setImagePath(e.currentTarget.value);
                break;
            case "display_order":
                setDisplayOrder(e.currentTarget.value);
                break;
        }
    };

    // 前の画面に戻る
    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(-1);
    };

    const onSetImage = (column: string, filename: string): void => {
        switch (column) {
            case "image_path":
                setImagePath(filename);
                break;
        }
    };

    const showImgModal = (title: string, viewPath: string) => {
        setModalTitle(title);
        setViewPath(viewPath);
        setIsModalImg(true);
    }

    const intestinalEnvironments = GetIntestinalEnvironmentTypes();
    const intestinalEnvironmentKeys = Object.keys(intestinalEnvironments);

    return <Layout title={"汎用リコメンドイメージ管理"}>

        <H1 title={ppMstGenericRcmndImage ? "汎用リコメンドイメージの編集" : "汎用リコメンドイメージの新規登録"} subtitle="data entry"/>

        <div className="box">

            <H3 title="汎用リコメンドイメージ" subtitle="data"/>

            <StyledSkuEdit onSubmit={onSubmit}>

                <div className="form-wrapper">

                    <div className="form-group">
                        <label className="req">汎用リコメンド<br/>グループID</label>
                        <div>
                            <select name="generic_rcmnd_group_id" value={genericRcmndGroupID} onChange={onChange} className={errors["generic_rcmnd_group_id"] ? "error" : ""}>
                                <option value="">---選択してください</option>
                                {ppMstGenericRcmndGroups.map((d, i) => {
                                    return <option value={d.id} key={`gr-${i}`}>{d.id}：{d.title}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["generic_rcmnd_group_id"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">動物種類</label>
                        <div>
                            <select name="animal_type" value={animalType} onChange={onChange} className={errors["animal_type"] ? "error" : ""}>
                                <option value="">---選択してください</option>
                                <option value="1">1：犬</option>
                                <option value="2">2：猫</option>
                            </select>
                            <ErrorMessage message={errors["animal_type"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">腸内環境タイプ</label>
                        <div>
                            <select name="intestinal_environment_type" value={intestinalEnvironmentType} onChange={onChange} className={errors["intestinal_environment_type"] ? "error" : ""}>
                                <option value="">---選択してください</option>
                                {intestinalEnvironmentKeys.map((d, i) => {
                                    return <option value={d} key={`iet-${i}`}>{d}：{intestinalEnvironments[d]}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["intestinal_environment_type"]}/>
                        </div>
                    </div>


                    <div className="form-group">
                        <label className="req">表示順位</label>
                        <div>
                            <select name="display_order" value={displayOrder} onChange={onChange} className={errors["display_order"] ? "error" : ""}>
                                <option value="">---選択してください</option>
                                {[...Array(10)].map((d, i) => {
                                    return <option value={i + 1} key={`do-${i}`}>{i + 1}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["display_order"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">イメージ概要</label>
                        <div>
                            <input type="text" name="image_overview" placeholder="例：はちみつ" maxLength={30} value={imageOverview} onChange={onChange} className={errors["image_overview"] ? "error" : ""}/>
                            <ErrorMessage message={errors["image_overview"]}/>
                        </div>
                    </div>

                    <ImageForm
                        column="image_path"
                        title={`画像`}
                        error={errors["image_path"]}
                        onSetImage={onSetImage}
                        showHint={true}
                        required={true}
                        showModal={showImgModal}
                        oldFilename={ppMstGenericRcmndImage ? ppMstGenericRcmndImage.image_name : null}
                        oldViewPath={ppMstGenericRcmndImage ? ppMstGenericRcmndImage.image_url : null}
                    />

                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type={"button"} onClick={onClickBack} icon={ButtonIcon.Arrow}>前の画面に戻る</Button>
                    <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
                </div>
            </StyledSkuEdit>

        </div>

        {isModalImg && viewPath && <ImageModal title={modalTitle} img={viewPath} onClose={() => setIsModalImg(false)}/>}

    </Layout>;

};

const StyledSkuEdit = styled(StyledForm)`

    select, input {
        width: 400px !important;
    }

`;
