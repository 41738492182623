import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import {Counter} from "../common/Counter";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {adminPpMstRcmndMethodApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";
import {AccesstokenRole, PpMstRcmndMethodControllerIndexRequest, TypesPpMstRcmndMethodData} from "../../generated";
import {Table} from "../common/Table";
import {Paginator} from "../common/Paginator";
import {GetRcmndDisplayPatternStr} from "../../utility/Utility";
import styled from "styled-components";
import {MainColor} from "../common/Colors";
import rightWhite from "../../images/right-white.svg";
import {CompleteMessage} from "../common/CompleteMessage";
import {SearchForm} from "./SearchForm";

export const PpMstRcmndMethodIndex = () => {

    const {setShowSpinner, setDangerMessage, setCompleteMessage, role} = useContext(AppContext);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [ppMstRcmndMethods, setPpMstRcmndMethods] = useState<TypesPpMstRcmndMethodData[]>([]);
    const navigate = useNavigate();
    const location = useLocation();

    const size = 200;

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const messageType = params.get("message_type") ?? "";

        if (messageType === "create_complete") {
            setCompleteMessage(`リコメンド表示パターンの新規追加が完了いたしました。`);
        }

        if (messageType === "edit_complete") {
            setCompleteMessage(`リコメンド表示パターンの編集が完了いたしました`);
        }

    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get("page")) {
            setPage(Number(params.get("page")));
        }

        getData();
    }, [location.search]);

    const getData = () => {

        setShowSpinner(true);

        const params = new URLSearchParams(location.search);

        const skuCode = params.get("sku_code") ?? "";
        const partnerInstId = params.get("partner_inst_id") ?? "";
        const rcmndDisplayPattern = params.get("rcmnd_display_pattern") ?? "";
        const medicInstId = params.get("medic_inst_id") ?? "";
        const genericRcmndTitle = params.get("generic_rcmnd_title") ?? "";

        const req: PpMstRcmndMethodControllerIndexRequest = {
            page: page,
            size: size,
            sku_code: skuCode,
            partner_inst_id: Number(partnerInstId),
            medic_inst_id: Number(medicInstId),
            rcmnd_display_pattern: rcmndDisplayPattern,
            generic_rcmnd_title: genericRcmndTitle,
        };

        adminPpMstRcmndMethodApi
            .v1AdminPpMstRcmndMethodPost(req)
            .then(({data}) => {
                setPpMstRcmndMethods(data.pp_mst_rcmnd_methods);
                setTotal(data.total ?? 0);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onDelete = (e: React.MouseEvent<HTMLSpanElement>, ppMstRcmndMethod: TypesPpMstRcmndMethodData) => {

        e.preventDefault();

        if (!window.confirm(`${ppMstRcmndMethod.sku_code} を削除します。よろしいですか？`)) {
            return;
        }

        setShowSpinner(true);
        adminPpMstRcmndMethodApi
            .v1AdminPpMstRcmndMethodDeleteIdDelete(String(ppMstRcmndMethod.id))
            .then(() => {
                setCompleteMessage(`リコメンド方式：${ppMstRcmndMethod.sku_code}を削除いたしました。`);
                getData();
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onPageChange = (page: number) => {
        setPage(page);
        navigate(`/pp_mst_rcmnd_method?page=${page}`);
    }

    return <Layout title="リコメンド方式管理">

        <SearchForm/>

        <CompleteMessage/>

        <StyledPpMstSkuIndex className="box">

            <H3 title="方式一覧" subtitle="list" counter={<Counter total={total} size={size} current={page}/>}/>

            <Table>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>SKUコード</th>
                    <th>病院名</th>
                    <th>提携法人名</th>
                    <th>リコメンド表示パターン</th>
                    <th>汎用リコメンドタイトル</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>

                <tbody>

                {ppMstRcmndMethods.map((d, i) => {

                    return <tr key={`sku-${i}`}>
                        <td>{d.id}</td>
                        <td>{d.sku_code}</td>
                        <td>{d.medic_inst ? d.medic_inst.medic_inst_name : "-"}</td>
                        <td>{d.partner_inst ? d.partner_inst.partner_inst_name : "-"}</td>
                        <td>{GetRcmndDisplayPatternStr(d.rcmnd_display_pattern)}</td>
                        <td>{d.generic_rcmnd_title ?? "-"}</td>
                        {role === AccesstokenRole.RoleMaster && <>
                            <td><NavLink to={`/pp_mst_rcmnd_method/edit/${d.id}`} className="btn-link">編集</NavLink></td>
                            <td><span onClick={(e) => onDelete(e, d)} className="btn-del">削除</span></td>
                        </>}
                    </tr>
                })}

                </tbody>

            </Table>

            <Paginator onClick={onPageChange} pages={Math.ceil(total / size)} page={page}/>

        </StyledPpMstSkuIndex>

    </Layout>
}


const StyledPpMstSkuIndex = styled.div`

    tr {
        td:nth-child(7), td:nth-child(8) {
            text-align: center !important;
            width: 120px;
        }
    }

    .btn-link, .btn-del {
        background-color: ${MainColor};
        color: #fff;
        font-size: 12px !important;
        line-height: 24px;
        display: inline-block;
        text-decoration: none !important;
        padding: 0 30px 0 20px;
        letter-spacing: 1px;
        border-radius: 24px;
        background-image: url(${rightWhite});
        background-repeat: no-repeat;
        background-position: top 50% right 15px;
        background-size: 5px auto;
    }

    .btn-del {
        background-color: #ccc;
    }

    .modal-inner {
        background-color: #fff;

        .confirmation {
            background-color: #fff;
            display: flex;
            justify-content: center;
            border-bottom: 1px solid #efefef;
            padding: 20px 0;
            align-items: flex-end;
            margin: 0 20px;

            span {
                margin-right: 23px;
            }

            strong {
                font-size: 40px;
                color: #FF9871;
                font-weight: 400;
                line-height: 1;
            }
        }

        p {
            padding-top: 20px !important;

            span {
                background: linear-gradient(transparent 50%, rgba(217, 62, 76, 0.2) 20%);

                strong {
                    background: none !important;
                }
            }
        }
    }

`;
