import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {H2} from "../common/Headline";
import rightOrange2 from "../../images/right-orange2.svg";
import React, {useContext, useEffect, useState} from "react";
import {AccesstokenRole} from "../../generated";
import {AppContext} from "../../contexts/AppContext";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {GenericRcmndDisplayMethod} from "../../utility/Utility";

export const SearchForm = () => {

    const {role} = useContext(AppContext);
    const [genericRcmndDisplayMethod, setGenericRcmndDisplayMethod] = useState<string>("");
    const [genericRcmndGroupTitle, setGenericRcmndGroupTitle] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        setGenericRcmndDisplayMethod(params.get("generic_rcmnd_display_method") ?? "");
        setGenericRcmndGroupTitle(params.get("generic_rcmnd_group_title") ?? "");

    }, [location.search]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        switch (e.currentTarget.name) {
            case "generic_rcmnd_display_method":
                setGenericRcmndDisplayMethod(e.currentTarget.value);
                break;
            case "generic_rcmnd_group_title":
                setGenericRcmndGroupTitle(e.currentTarget.value);
                break;
        }
    }

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowError(false);
        setGenericRcmndDisplayMethod("");
        setGenericRcmndGroupTitle("");
        navigate(`/pp_mst_generic_rcmnd_group`);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const params = new URLSearchParams();
        params.append("generic_rcmnd_display_method", genericRcmndDisplayMethod);
        params.append("generic_rcmnd_group_title", genericRcmndGroupTitle);
        params.append("page", "1");

        if (genericRcmndDisplayMethod === "" && genericRcmndGroupTitle === "") {
            // 未入力で検索した場合
            setShowError(true);
            return;
        }

        setShowError(false);

        navigate(`/pp_mst_generic_rcmnd_group?${params.toString()}`);
    }

    const onClickCreate = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/pp_mst_generic_rcmnd_group/create`);
    };

    return <StyledSearchForm onSubmit={onSubmit}>

        <div className="box">

            <H2 title="情報を検索する" subtitle="input"/>

            <div className="row">

                <div className="left">

                    <h5>情報を検索する</h5>

                    <div className={showError ? "search-form error" : "search-form"}>

                        {showError && <span className="error">検索内容を入力してください</span>}

                        <div className="form-group">
                            <label>汎用リコメンド表示方式</label>
                            <select name="generic_rcmnd_display_method" value={genericRcmndDisplayMethod} onChange={onChange}>
                                <option value="">---選択してください</option>
                                {GenericRcmndDisplayMethod.map((d, i) => {
                                    return <option value={d.id} key={`gr-${i}`}>{d.id}：{d.name}</option>
                                })}
                            </select>
                        </div>

                        <div className="form-group">
                            <label>汎用リコメンドグループタイトル</label>
                            <input name="generic_rcmnd_group_title" type="text" placeholder="例：オリゴ糖" value={genericRcmndGroupTitle} onChange={onChange}/>
                        </div>

                    </div>
                </div>

                {role === AccesstokenRole.RoleMaster && <>
                    <div className="right">

                        <h5>情報を新規登録する</h5>

                        <div>
                            <div className="btn-area">
                                <Button color={ButtonColor.Orange} type="button" icon={ButtonIcon.Arrow} onClick={onClickCreate}>新規登録</Button>
                            </div>
                        </div>

                    </div>
                </>}

            </div>

            <div className="btn-area mt40">
                <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                <Button color={ButtonColor.Orange} type="submit" icon={ButtonIcon.Arrow}>検索</Button>
            </div>

        </div>


    </StyledSearchForm>

}

const StyledSearchForm = styled(StyledForm)`

    .row {
        display: flex;
        justify-content: space-between;

        h5 {
            background-color: #EFEFEF;
            font-weight: 400;
            line-height: 36px;
            font-size: 14px;
            padding: 0 10px 0 30px;
            background-image: url(${rightOrange2});
            background-repeat: no-repeat;
            background-position: left 15px top 50%;
        }

        .left {
            flex: 1;
            margin-right: 23px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            overflow: hidden;
            border: 1px solid #EFEFEF;

            .search-form {
                padding: 22px;

                &.error {
                    position: relative;

                    input, select {
                        background-color: #F7D7DA;
                    }
                }

                .error {
                    background-color: #D93E4C;
                    color: #fff;
                    line-height: 47px;
                    display: block;
                    position: absolute;
                    top: 25px;
                    left: 620px;
                    padding: 0 16px;
                    border-radius: 4px;

                    &:before {
                        position: absolute;
                        border: 8px solid transparent;
                        border-right: 8px solid #D93E4C;
                        top: 50%;
                        left: -16px;
                        transform: translateY(-50%);
                        content: "";
                        display: block;
                    }
                }

            }


            .form-group {
                padding: 6px 0;
                margin-bottom: 0;
            }
            
            label {
                width: 240px;
                margin-right: 0;
            }

            input, select {
                width: 340px !important;
            }

        }

        .right {
            width: 338px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            overflow: hidden;
            border: 1px solid #EFEFEF;

            .btn-area {
                margin-top: 60px;

                button {
                    width: 240px;
                }
            }
        }

    }

`