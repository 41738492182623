import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import React, {useEffect, useRef, useState} from "react";
import {H2} from "../common/Headline";
import {CheckSampleId} from "../../utility/Utility";
import {useLocation, useNavigate} from "react-router-dom";

enum SearchType {
    FlowCellId = 1,
    SampleId = 2,
}

export enum ErrorType {
    None = 0,
    NotFound = 1,
    Duplicate = 2
}

interface Props {
    errorType: ErrorType,
    onClear: () => void;
    onConfirm: (e: React.MouseEvent<HTMLButtonElement>) => void;
    isDownloadable: boolean;
}

export const SearchForm = ({onClear, onConfirm, errorType, isDownloadable}: Props) => {

    const [sampleId, setSampleId] = useState<string>("");
    const [flowcellId, setFlowcellId] = useState<string>("");
    const [searchType, setSearchType] = useState<SearchType>(SearchType.FlowCellId);
    const ref = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        if (ref.current) {
            ref.current.focus();
        }

    }, []);


    useEffect(() => {

        const params = new URLSearchParams(location.search);

        const flowcellId = params.get("flowcell_id");
        const sampleId = params.get("sample_id");

        if (flowcellId != null) {
            setFlowcellId(flowcellId);
        }

        if (sampleId != null) {
            setSampleId(sampleId);
        }


    }, [location.search]);

    useEffect(() => {
        if (flowcellId.length === 8) {
            navigate(`/metadata?flowcell_id=${flowcellId}`);
        }
        if (CheckSampleId(sampleId)) {
            navigate(`/metadata?sample_id=${sampleId}`);
        }
    }, [sampleId, flowcellId]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.currentTarget.name) {
            case "flowcell_id":
                setFlowcellId(e.currentTarget.value);
                break;
            case "sample_id":
                setSampleId(e.currentTarget.value);
                break;
            case "search_type":
                if (e.currentTarget.value === "1") {
                    setSearchType(SearchType.FlowCellId);
                    setSampleId("");
                } else {
                    setSearchType(SearchType.SampleId);
                    setFlowcellId("");
                }
                break;
        }
    };

    const onSubmitDummy = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
    };

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (!window.confirm("一覧をクリアします。よろしいですか？")) {
            return;
        }

        setSearchType(SearchType.FlowCellId);
        setFlowcellId("");
        setSampleId("");
        onClear();

        if (ref.current) {
            ref.current.focus();
        }
    }

    // フォーカス時選択
    const onFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.currentTarget.select();
    }

    return <StyledSearchForm noValidate={true} onSubmit={onSubmitDummy}>

        <div className="box">

            <H2 title="検体ID読み取り" subtitle="input"/>

            <div className="search-form">

                <div className="form-group">
                    <label>
                        <input type="radio" name="search_type" value="1" checked={searchType === SearchType.FlowCellId} onChange={onChange}/>
                        Flow Cell ID
                    </label>
                    <div>
                        <input type="text" name="flowcell_id" placeholder={"例：ABC12345"} value={flowcellId} onChange={onChange} maxLength={8} disabled={searchType === SearchType.SampleId} ref={ref} onFocus={onFocus}/>
                    </div>

                    {(searchType === SearchType.FlowCellId && errorType === ErrorType.NotFound) && <div className="search-error">取得対象外のFlow Cell IDです</div>}
                    {(searchType === SearchType.FlowCellId && errorType === ErrorType.Duplicate) && <div className="search-error">複数Flow Cell分のメタデータは同時に出力できません</div>}
                </div>

                <div className="form-group">
                    <label>
                        <input type="radio" name="search_type" value="2" checked={searchType === SearchType.SampleId} onChange={onChange}/>
                        検体ID
                    </label>
                    <div>
                        <input type="text" name="sample_id" placeholder={"例：PC23-1000-NGS-1000"} value={sampleId} onChange={onChange} maxLength={18} disabled={searchType === SearchType.FlowCellId} onFocus={onFocus}/>
                    </div>

                    {(searchType === SearchType.SampleId && errorType === ErrorType.NotFound) && <div className="search-error">取得対象外の検体IDです</div>}
                    {(searchType === SearchType.SampleId && errorType === ErrorType.Duplicate) && <div className="search-error">検体ID指定の場合、メタデータは2件以上同時に出力できません</div>}

                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                    <Button color={ButtonColor.Orange} type="button" icon={ButtonIcon.Arrow} onClick={onConfirm} disabled={!isDownloadable}>ダウンロード</Button>
                </div>

            </div>

        </div>

    </StyledSearchForm>
};

export const StyledSearchForm = styled(StyledForm)`

  .search-form {

    .form-group {
      position: relative;

      label {
        width: 130px;
        margin-right: 0;
        display: flex;
        align-items: center;

        input[type="radio"] {
          margin: 0 10px 0 0;
        }
      }

      .search-error {
        color: #fff;
        background-color: #D93E4C;
        font-size: 14px;
        line-height: 47px;
        display: inline-block;
        position: absolute;
        padding: 0 10px;
        border-radius: 6px;
        width: auto;
        top: 7px;
        left: 470px;

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: -18px;
          top: 50%;
          transform: translateY(-50%);
          border: 10px solid transparent;
          border-right: 10px solid #D93E4C;
        }
      }

    }

  }

`;