import styled from "styled-components";
import React from "react";

export const P = ({children}: React.PropsWithChildren) => {

    return <StyledP>
        {children}
    </StyledP>

};

const StyledP = styled.p`

  font-size: 16px;
  line-height: 2.4;
`;