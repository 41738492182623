import styled from "styled-components";
import {Button, ButtonColor, ButtonIcon} from "./Button";
import {MainColor} from "./Colors";
import {useEffect} from "react";

interface Props {
    img: string;
    onClose: () => void;
}

export const PetModal = ({img, onClose}: Props) => {

    useEffect(() => {

        // escキーで閉じる
        window.addEventListener("keyup", closeHandler);

        return () => {
            window.removeEventListener("keyup", closeHandler);
        }

    }, []);

    const closeHandler = (e: KeyboardEvent) => {
        if (e.code.toLowerCase() === "escape") {
            onClose();
        }
    };

    return <StyledPetModal>

        <div className="pet-modal">

            <h4>登録アイコンの確認</h4>

            <p>以下の写真が登録されております。</p>

            <div className="img-area">
                <img src={img} alt={"画像"}/>
            </div>

            <div className="footer">
                <Button color={ButtonColor.Green} onClick={onClose} icon={ButtonIcon.Arrow}>元の画面に戻る</Button>
            </div>

        </div>

    </StyledPetModal>


}

const wh = 250;

const StyledPetModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.5);

  .pet-modal {
    width: 640px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    overflow: hidden;

    h4 {
      background-color: ${MainColor};
      text-align: center;
      font-size: 18px;
      line-height: 48px;
      font-weight: normal;
      color: #fff;
    }

    p {
      color: #4D8E82;
      text-align: center;
      padding: 24px 0;
      background-color: #EFEFEF;

    }

    .img-area {
      background-color: #fff;
      padding: 30px 0;
      text-align: center;

      img {
        width: ${wh}px;
        height: ${wh}px;
        object-fit: cover;
        border-radius: 50%;
        border: 16px solid ${MainColor};
      }
    }

    .footer {
      padding: 24px 0;
      background-color: #EFEFEF;
    }
  }
`;