import {Layout} from "../common/Layout";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {PpMstTestKitControllerIndexRequest, TypesPpMstTestKitData} from "../../generated";
import {adminPpMstTestKitApi} from "../../api/Api";
import {H3} from "../common/Headline";
import {Table} from "../common/Table";
import {SlashedDatetime} from "../../utility/Utility";
import {Counter} from "../common/Counter";
import {Paginator} from "../common/Paginator";
import {SearchForm} from "./SearchForm";
import {useLocation, useNavigate} from "react-router-dom";

export const PpMstTestKitIndex = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppMstTestKits, setPpMstTestKits] = useState<TypesPpMstTestKitData[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const {search} = useLocation();
    const navigate = useNavigate();
    const size = 100;

    useEffect(() => {

        const params = new URLSearchParams(search);
        let page = Number(params.get("page")) ?? 1;

        if (page <= 0) {
            page = 1;
        }

        const req: PpMstTestKitControllerIndexRequest = {
            sample_id: params.get("sample_id") ?? "",
            type: params.get("type") ?? "",
            size: size,
            page: page
        };

        getPpMstTestKits(req);

    }, [search]);

    const getPpMstTestKits = (req: PpMstTestKitControllerIndexRequest) => {

        setShowSpinner(true);

        adminPpMstTestKitApi.v1AdminPpMstTestKitPost(req)
            .then(({data}) => {

                setTotal(data.total);
                setPpMstTestKits(data.pp_mst_test_kits);
            })
            .catch((err) => {

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onPageChange = (next: number) => {
        setPage(next);

        const params = new URLSearchParams(search);
        params.set("page", String(next));

        navigate(`/pp_mst_test_kit?${params.toString()}`);

    }

    const onForceReload = () => {

        setPage(1);

        const req: PpMstTestKitControllerIndexRequest = {
            sample_id: "",
            type: "",
            size: size,
            page: 1,
        };

        getPpMstTestKits(req);
    };

    return <Layout title="検体ID登録">

        <SearchForm onForceReload={onForceReload}/>

        <div className="box">
            <H3 title="検体ID一覧" subtitle="list" counter={<Counter total={total} size={size} current={page}/>}/>

            <Table>
                <thead>
                <tr>
                    <th>検体ID</th>
                    <th>パスワード</th>
                    <th>登録日時</th>
                </tr>
                </thead>

                <tbody>

                {ppMstTestKits.map((d, i) => {
                    return <tr key={`pp_mst_test_kit-${i}`}>
                        <td>{d.sample_id}</td>
                        <td>{d.password}</td>
                        <td>{SlashedDatetime(d.created)}</td>
                    </tr>
                })}

                </tbody>

            </Table>

            <Paginator onClick={onPageChange} pages={Math.ceil(total / size)} page={page}/>

        </div>

    </Layout>
}