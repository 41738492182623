import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import React, {useContext, useEffect, useRef, useState} from "react";
import {H2} from "../common/Headline";
import {useLocation, useNavigate} from "react-router-dom";
import rightOrange2 from "../../images/right-orange2.svg";
import {AppContext} from "../../contexts/AppContext";
import {adminPpMstTestKitApi} from "../../api/Api";
import {AccesstokenRole} from "../../generated";

interface Props {
    onForceReload: () => void;
}

export const SearchForm = ({onForceReload}: Props) => {

    const {setShowSpinner, setDangerMessage, setSuccessMessage, role} = useContext(AppContext);
    const [type, setType] = useState<string>("");
    const [sampleId, setSampleId] = useState<string>("");
    const navigate = useNavigate();
    const location = useLocation();
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        const type = params.get("type") ?? "";
        const sampleId = params.get("sample_id") ?? "";

        if (type) {
            setType(type);
        }

        if (sampleId) {
            setSampleId(sampleId);
        }

    }, []);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const params = new URLSearchParams();
        params.set("type", type);
        params.set("sample_id", sampleId);
        navigate(`/pp_mst_test_kit?${params.toString()}`);
    }

    const onClear = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        setType("");
        setSampleId("");
        navigate("/pp_mst_test_kit");
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
        switch (e.currentTarget.name) {
            case "type":
                setType(e.currentTarget.value);
                break;
            case "sample_id":
                setSampleId(e.currentTarget.value);
                break;
        }
    };

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

        e.preventDefault();

        if (!e.currentTarget.files) {
            return;
        }

        const file = e.currentTarget.files.item(0);

        if (!file) {
            return;
        }

        setShowSpinner(true);

        adminPpMstTestKitApi.v1AdminPpMstTestKitRegisterPost(file)
            .then(({data}) => {
                setSuccessMessage(data.message ?? "登録が完了しました。");

                setType("");
                setSampleId("");
                navigate("/pp_mst_test_kit");
                onForceReload();
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            })

    };

    const onClickSelect = (e: React.MouseEvent<HTMLButtonElement>): void => {

        e.preventDefault();

        if (ref.current) {
            console.log("onClickSelect", ref.current);
            ref.current.click();
        }
    };

    return <StyledSearchForm noValidate={true} onSubmit={onSubmit}>

        <div className="box">

            <H2 title="検索・新規検体IDの登録" subtitle="search"/>

            <div className="row">

                <div className="col">

                    <h5>検体IDで読み込む</h5>

                    <div className="form-group">
                        <label>検査区分</label>
                        <div>
                            <select name="type" value={type} onChange={onChange}>
                                <option value="">選択してください</option>
                                <option value="1">犬腸内細菌</option>
                                <option value="2">猫腸内細菌</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>検体ID</label>
                        <div>
                            <input type="text" name="sample_id" placeholder={"例：PC23-1000-NGS-1000"} value={sampleId} onChange={onChange} maxLength={18}/>
                        </div>
                    </div>

                    <div className="btn-area">
                        <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClear}>クリア</Button>
                        <Button color={ButtonColor.Orange} type="submit" icon={ButtonIcon.Arrow}>検索</Button>
                    </div>

                </div>

                {role === AccesstokenRole.RoleMaster && <>
                    <div className="col">

                        <h5>新規検体IDの登録</h5>


                        <p>A列に検体ID、B列にパスワードを記載したエクセルファイル（.xlsx）を選択してください。</p>

                        <input type="file" ref={ref} onChange={onFileChange} accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>

                        <Button color={ButtonColor.Green} type="button" icon={ButtonIcon.Arrow} onClick={onClickSelect}>ファイル取込</Button>

                    </div>

                </>}

            </div>

        </div>

    </StyledSearchForm>
};

export const StyledSearchForm = styled(StyledForm)`

  .row {
    display: flex;
    justify-content: space-between;

    .col {
      width: 49%;

      h5 {
        background-color: #EFEFEF;
        font-weight: 400;
        line-height: 36px;
        font-size: 14px;
        padding: 0 10px 0 30px;
        background-image: url(${rightOrange2});
        background-repeat: no-repeat;
        background-position: left 15px top 50%;
      }

      p {
        font-size: 14px;
        padding: 20px;
        margin-bottom: 30px;
        color: #666;
      }

      input[type="file"] {
        display: none;
      }

    }
  }

  .form-group {
    position: relative;

    label {
      width: 100px;
      margin-right: 0;
    }

    .search-error {
      color: #fff;
      background-color: #D93E4C;
      font-size: 14px;
      line-height: 47px;
      display: block;
      position: absolute;
      padding: 0 10px;
      border-radius: 6px;
      top: -2px;
      left: 320px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: -18px;
        top: 50%;
        transform: translateY(-50%);
        border: 10px solid transparent;
        border-right: 10px solid #D93E4C;
      }
    }

  }

`;