import styled from "styled-components";
import React, {useContext} from "react";
import {AppContext} from "../../contexts/AppContext";
import {MainColor} from "./Colors";
import {NavLink} from "react-router-dom";

interface Props {
    title: string;
}

export const Header = ({title}: Props) => {

    const {ppMstUserAdmin, ppMstUserPartner} = useContext(AppContext);

    if (!ppMstUserAdmin && !ppMstUserPartner) {
        return null;
    }

    let id = 0;
    let name = "";

    if (ppMstUserAdmin !== null) {
        id = ppMstUserAdmin.id ?? 0;
        name = ppMstUserAdmin.name ?? "";
    }

    if (ppMstUserPartner !== null) {
        id = ppMstUserPartner.id ?? 0;
        name = ppMstUserPartner.name ?? "";
    }

    return <StyledHeader>

        <h1>{title}</h1>

        <h2>
            <div className="id">
                <span>ID</span>
                <span>{id}</span>
            </div>
            {ppMstUserPartner && ppMstUserPartner.identification_cd && <div className="identification_cd">
                <span>識別コード</span>
                <span>{ppMstUserPartner?.identification_cd}</span>
            </div>}

            <span className="name">{name}</span>
        </h2>

        <div className="account-menu">
            <NavLink to={"/change-password"}>パスワード変更</NavLink>
            <NavLink to={"/logout"}>ログアウト</NavLink>
        </div>
    </StyledHeader>

}

const StyledHeader = styled.div`

  background-color: ${MainColor};
  display: flex;
  align-items: center;
  padding: 24px 0;

  h1 {
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 3px;
    color: #fff;
  }

  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;

    .id {
      display: flex;
      align-items: center;
      margin-right: 15px;

      span {
        &:first-child {
          background-color: #ebdb7a;
          color: ${MainColor};
          padding: 0 5px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          line-height: 14px;
          font-size: 10px;
          font-weight: normal;
        }

        &:last-child {
          background-color: #fff;
          color: ${MainColor};
          padding: 0 5px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          line-height: 14px;
          font-weight: normal;
          font-size: 10px;
          width: 40px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: center;
        }
      }
    }

    .identification_cd {
      display: flex;
      align-items: center;
      margin-right: 15px;

      span {
        &:first-child {
          background-color: #ebdb7a;
          color: ${MainColor};
          padding: 0 5px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          line-height: 14px;
          font-size: 10px;
          font-weight: normal;
        }

        &:last-child {
          background-color: #fff;
          color: ${MainColor};
          padding: 0 5px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          line-height: 14px;
          font-weight: normal;
          font-size: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: center;
        }
      }
    }

    .role {
      color: #fff;
      margin-right: 15px;
      font-weight: normal;
      font-size: 14px;
      line-height: 32px;
    }

    .name {
      color: #fff;
      font-weight: normal;
      font-size: 14px;
      line-height: 32px;
    }

  }

  .account-menu {

    a {
      color: #fff;
      font-size: 12px;
      text-decoration: none;
      margin-left: 20px;

      &:hover {
        text-decoration: underline;
      }

    }

  }

`;