import React, {useContext, useEffect} from "react";
import {AppContext} from "../../contexts/AppContext";
import {AgencyLoginControllerAdminGenerateRequest, TypesPpTestKitData} from "../../generated";
import {adminAgencyLoginApi, adminHistoryApi} from "../../api/Api";
import {useNavigate, useParams} from "react-router-dom";

export const AgencyLogin = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const sampleId = params.sampleId;
        getPpTestKit(sampleId ?? "");
    }, []);

    const getPpTestKit = (sampleId: string) => {

        setShowSpinner(true);

        adminHistoryApi.v1AdminHistorySampleIdGet(sampleId)
            .then(({data}) => {
                generateLoginToken(data.pp_test_kit);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
                navigate("/pp_test_status");
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    // トークン生成
    const generateLoginToken = (ppTestKit: TypesPpTestKitData): void => {

        setShowSpinner(true);

        const req: AgencyLoginControllerAdminGenerateRequest = {
            pp_user_id: ppTestKit.pp_user_id ?? 0,
        };

        adminAgencyLoginApi.v1AdminAgencyLoginGeneratePost(req)
            .then(({data}) => {
                // トークンが取得できたら、フロント画面にリダイレクト
                // https://stg.pitpet.jp/login/?petId=1&sampleId=PC01-0629-NGS-0441
                window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/login/?token=${data.token}&sampleId=${ppTestKit.sample_id}&petId=${ppTestKit.pp_pet_id}`;
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
                navigate(`/pp_test_status/result/${ppTestKit.sample_id}`);
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    return <></>
}