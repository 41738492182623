import {Layout} from "../common/Layout";
import {H1, H3, H4} from "../common/Headline";
import React, {useContext, useEffect, useState} from "react";
import {adminPpTestKitApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";
import {PptestkitBiPetSex, PpTestKitControllerStep1Request, TypesPpTestKitData} from "../../generated";
import {useNavigate, useParams} from "react-router-dom";
import {PartsPetDetail} from "../pp_pet/PartsPetDetail";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Basic} from "./interview/Basic";
import {Condition} from "./interview/Condition";
import {InterviewSteps} from "./interview/InterviewSteps";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";

export const PpTestKitStep1 = () => {

    const params = useParams<{ sampleId: string }>();
    const navigate = useNavigate();
    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppTestKit, setPpTestKit] = React.useState<TypesPpTestKitData | null>(null);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {

        if (params.sampleId) {
            getPpTestKit(params.sampleId);
        }

    }, []);

    const getPpTestKit = (sampleId: string) => {

        setShowSpinner(true);

        adminPpTestKitApi.v1AdminPpTestKitGetSampleIdGet(sampleId)
            .then(({data}) => {

                if (!data.pp_test_kit.pp_pet) {
                    setDangerMessage("ご指定の検体IDにはまた申し込みがされていません。");
                    navigate(`/pp_test_kit`);
                }

                // オスの場合、一部デフォルト値を設定
                if (data.pp_test_kit.bi_pet_sex === PptestkitBiPetSex.BiPetSex1) {
                    data.pp_test_kit.is_was_pregnant_past = "2";
                    data.pp_test_kit.is_pregnancy = "2";
                }

                setPpTestKit(data.pp_test_kit ?? null);

            })
            .catch((err) => {

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

                navigate(`/pp_test_kit`);

            })
            .finally(() => {
                setShowSpinner(false);
            });

    };

    if (!ppTestKit || !ppTestKit.pp_pet) {
        return <Layout title="検査申込管理"/>
    }

    const onUpdate = (next: TypesPpTestKitData) => {
        setPpTestKit((prev) => {
            return {...prev, ...next};
        });
    };

    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/pp_test_kit/apply/${ppTestKit.pp_user_id}/${ppTestKit.pp_pet_id}`);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        setShowSpinner(true);
        setErrors({});

        // 項目のマッピングが大変なので、一旦型違いを無理やりコピーしてそのまま送る
        const req: PpTestKitControllerStep1Request = ppTestKit as PpTestKitControllerStep1Request;

        adminPpTestKitApi.v1AdminPpTestKitStep1SampleIdPost(ppTestKit.sample_id, req)
            .then(({data}) => {
                setPpTestKit(data.pp_test_kit ?? null);
                navigate(`/pp_test_kit/step2/${ppTestKit.sample_id}`);
            })
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setDangerMessage("入力内容に不足、または誤りがあります。もう一度、入力内容をご確認ください。");
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }


    return <Layout title="検査申込管理">

        <StyledPpTestKitInterview onSubmit={onSubmit}>

            <H1 title="ペット情報の新規登録" subtitle="registration"/>

            <InterviewSteps offset={0}/>

            <div className="box">

                <H3 title={"ペットの情報"} subtitle="pet"/>

                <PartsPetDetail ppPet={ppTestKit.pp_pet}/>

            </div>

            <H4 title={"検査情報の入力"}/>

            <Basic errors={errors} onUpdate={onUpdate} ppTestKit={ppTestKit}/>

            <H4 title={"基礎情報の入力"}/>

            <Condition errors={errors} onUpdate={onUpdate} ppTestKit={ppTestKit}/>

            <div className="btn-area">
                <Button color={ButtonColor.Gray} icon={ButtonIcon.Arrow} type="button" onClick={onClickBack}>前の画面に戻る</Button>
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>次の質問に進む</Button>
            </div>

        </StyledPpTestKitInterview>

    </Layout>

}

const StyledPpTestKitInterview = styled(StyledForm)`


`;