import styled from "styled-components";
import React, {useContext, useEffect, useState} from "react";
import {TopicDetail} from "./TopicDetail";
import {SlashedYmd} from "../../utility/Utility";
import {AppContext} from "../../contexts/AppContext";
import {adminPpAnnouncementAdminApi} from "../../api/Api";
import {TypesPpAnnouncementAdminData} from "../../generated";

export const Topics = () => {

    const {setShowSpinner} = useContext(AppContext);
    const [topic, setTopic] = useState<TypesPpAnnouncementAdminData | null>(null);
    const [topics, setTopics] = useState<TypesPpAnnouncementAdminData[]>([]);

    useEffect(() => {
        getTopics();
    }, []);

    const getTopics = () => {

        setShowSpinner(true);

        adminPpAnnouncementAdminApi.v1AdminPpAnnouncementAdminGet("1", "", 1, 3)
            .then(({data}) => {
                setTopics(data.pp_announcement_admins ?? []);
            })
            .catch((err) => {
                // ここでのエラーは黙殺する
                console.log(err);
            })
            .finally(() => {
                setShowSpinner(false);

            });
    };

    const onClick = (e: React.MouseEvent<HTMLLIElement>, topic: TypesPpAnnouncementAdminData) => {
        e.preventDefault();
        setTopic(topic);
    };

    const onCloseDetail = () => {
        setTopic(null);
    };

    if (topics.length === 0) {
        // 表示するものがない場合
        return null;
    }

    return <React.Fragment>

        <StyledTopics>

            <h3>お知らせ</h3>

            <ul>
                {topics.map((d) => {
                    return <li key={`topic-${d.id}`} onClick={(e) => onClick(e, d)}>
                        {SlashedYmd(d.display_ymd ?? "")}&nbsp; &nbsp;{d.announcement_title}
                    </li>
                })}
            </ul>

        </StyledTopics>

        {topic && <TopicDetail topic={topic} onClose={onCloseDetail}/>}

    </React.Fragment>
}


const StyledTopics = styled.div`
  position: relative;
  margin-bottom: 53px;

  h3 {
    background-color: #F4E37D;
    font-size: 16px;
    letter-spacing: 2px;
    border-radius: 20px;
    line-height: 40px;
    font-weight: normal;
    text-align: center;
    width: 125px;
    position: absolute;
    top: 0;
    left: 0;
  }

  ul {
    list-style-type: none;
    margin: 0 0 0 115px;

    li {
      cursor: pointer;
      background-color: #fff;
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 30px;
      line-height: 40px;
      border-radius: 20px;
      font-size: 16px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

`;
