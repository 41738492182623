import {Layout} from "../common/Layout";
import React, {useContext, useEffect, useState} from "react";
import {H1} from "../common/Headline";
import {PpteststatusStatus, TypesPpTestKitData} from "../../generated";
import {AppContext} from "../../contexts/AppContext";
import {adminHistoryApi, adminPpTestKitApi} from "../../api/Api";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {DetailHeader} from "./DetailHeader";
import {MainColor} from "../common/Colors";
import rightWhite from "../../images/right-white.svg";
import {CompleteMessage} from "../common/CompleteMessage";
import {AxiosRequestConfig} from "axios";

export const PpTestStatusResult = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppTestKit, setPpTestKit] = useState<TypesPpTestKitData | null>(null);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params.sampleId) {
            getPpTestKit(params.sampleId);
        }
    }, []);

    const onReloadPpTestKit = () => {
        getPpTestKit(params.sampleId ?? "");
    }

    const getPpTestKit = (sampleId: string) => {

        setShowSpinner(true);

        adminPpTestKitApi
            .v1AdminPpTestKitGetSampleIdGet(sampleId)
            .then(({data}) => {

                if (data.pp_test_kit.status !== PpteststatusStatus.Status50) {
                    // 検査完了していない場合は、リダイレクト
                    setDangerMessage("まだ検査完了していませんので、検査情報は閲覧できません。");
                    navigate(`/pp_test_status/detail/${sampleId}`);
                    return;
                }
                setPpTestKit(data.pp_test_kit);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
                navigate("/pp_test_status");
            })
            .finally(() => {
                setShowSpinner(false);
            });

    };

    if (!ppTestKit) {
        return <Layout title="検査情報照会">
            <H1 title="会員の登録情報" subtitle="user data"/>
        </Layout>
    }


    const onClickPdf = (e: React.MouseEvent<HTMLAnchorElement>) => {

        e.preventDefault();
        setShowSpinner(true);

        const options: AxiosRequestConfig = {
            responseType: "blob",
        };

        adminHistoryApi
            .v1AdminHistoryPdfSampleIdGet(ppTestKit.sample_id ?? "", options)
            .then((res) => {
                const data = res.data as never;
                const blob = new Blob([data], {type: "application/pdf"});
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `検査結果表_${ppTestKit.pp_pet?.pet_name}.pdf`;
                a.click();

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    return <Layout title="検査情報照会">

        <H1 title="会員の登録情報" subtitle="user data"/>

        <CompleteMessage/>

        <DetailHeader active={1} ppTestKit={ppTestKit} onReload={onReloadPpTestKit}/>

        <StyledPpTestStatusResult>

            <div className="box mt-minus">

                <p className="mb30">以下のボタンをクリックし、ユーザー様と同じページで検査結果をご確認できます。</p>

                <div className="mb50">
                    <NavLink to={`/agency_login/${ppTestKit.sample_id}`} target="_blank">会員画面を開く</NavLink>
                </div>

                <p className="mb30">検査結果の表をPDFで出力します。</p>

                <div className="mb50">
                    <span onClick={onClickPdf} className="orange">PDFで表を出力</span>
                </div>

            </div>

        </StyledPpTestStatusResult>

    </Layout>

};


const StyledPpTestStatusResult = styled(StyledForm)`
    .mt-minus { // ヘッダー部分に被せる
        margin-top: -120px !important;
    }

    .box {
        p {
            text-align: center;
            padding: 30px 0 0 0;
        }

        a, span {
            width: 240px;
            display: block;
            margin: 0 auto;
            border: none;
            border-radius: 48px;
            line-height: 40px;
            color: #fff;
            font-size: 16px;
            padding: 0 50px 0 40px;
            letter-spacing: 2px;
            cursor: pointer;
            background-color: ${MainColor};
            text-align: center;
            text-decoration: none;

            background-image: url(${rightWhite});
            background-repeat: no-repeat;
            background-position: top 50% right 30px;

            &.orange {
                background-color: #FF9871;
            }
        }
    }
`;
