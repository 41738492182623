import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {ErrorMessage} from "../common/ErrorMessage";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Topics} from "./Topics";
import {StyledLogin} from "./Login";
import {SetPageTitle} from "../../utility/Utility";
import {Button, ButtonColor} from "../common/Button";
import {adminAuthApi} from "../../api/Api";
import {AdminAuthControllerResetPasswordRequest} from "../../generated";
import {PasswordInput} from "../common/PasswordInput";

export const ResetPassword = () => {

    const {setShowSpinner, setDangerMessage, setSuccessMessage} = useContext(AppContext);
    const [token, setToken] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmationPassword, setConfirmationPassword] = useState<string>("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const location = useLocation();
    const navigate = useNavigate();

    SetPageTitle("パスワード再発行");

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        const token = params.get("token") ?? "";

        if (!token) {
            setDangerMessage("URLが正しくありません。");
            navigate("/login");
            return;
        }

        setToken(token);

    }, []);

    // 変更ハンドラー
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

        switch (e.currentTarget.name) {
            case "password":
                setPassword(e.currentTarget.value);
                break;
            case "confirmation_password":
                setConfirmationPassword(e.currentTarget.value);
                break;
        }
    }

    // 送信ハンドラー
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const req: AdminAuthControllerResetPasswordRequest = {
            token: token,
            password: password,
            confirmation_password: confirmationPassword,
        }

        setShowSpinner(true);

        adminAuthApi.v1AdminAuthResetPasswordPost(req)
            .then(() => {
                setErrors({});
                setSuccessMessage("パスワードのリセットが完了しました。新しいパスワードでログインしてください。");
                navigate("/login");
            })
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    return (
        <StyledLogin>

            <form onSubmit={onSubmit}>

                <h1>PiTPET</h1>

                <Topics/>

                <div className="form-wrapper">

                    <p>
                        以下のフォームに新しいパスワードを設定して送信してください。<br/>
                        パスワードは半角英数時8文字以上（大文字英字 A〜Z、小文字英字 a〜z、数字 0〜9 をそれぞれ1文字以上使用してください）で設定してください。
                    </p>

                    <div className="form-group">
                        <label>パスワード</label>
                        <div>
                            <PasswordInput name="password" value={password} onChange={onChange}/>
                            <ErrorMessage message={errors["password"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>パスワード</label>
                        <div>
                            <PasswordInput name="confirmation_password" value={confirmationPassword} onChange={onChange} placeholder="再入力"/>
                            <ErrorMessage message={errors["confirmation_password"]}/>
                        </div>
                    </div>

                    <Button color={ButtonColor.Green}>送信する</Button>

                    <div className="links">
                        <NavLink to={"/login"}>ログイン画面に戻る</NavLink>
                    </div>

                </div>

            </form>

        </StyledLogin>
    );
};