import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {useNavigate} from "react-router-dom";
import {PpTestKitControllerIndexRequest, PpTestKitControllerSetWellplateIDRequest, PpteststatusStatus} from "../../generated";
import {adminPpTestKitApi} from "../../api/Api";
import {Modal, ModalColor} from "../sample_id/Modal";
import rightLightBlue from "../../images/right-lightblue.svg";
import {CheckIsReceptionOK} from "../../utility/Utility";

enum ModalType {
    InputError = 1,
    InputConfirm = 2,
    Complete = 3,
    FlowCellDuplicate = 4,
    WellplateDuplicate = 5,
}

export const ReceptionRegister = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [wellplateId, setWellplateId] = useState<string>("");
    const [flowcellId, setFlowcellId] = useState<string>("");
    const [totalNum, setTotalNum] = useState<number>(0);
    const [notAppliedNum, setNotAppliedNum] = useState<number>(0);
    const [modalType, setModalType] = useState<ModalType | null>(null);
    const sessionKey = "newreception_session_key";
    const navigate = useNavigate();
    const [isInputOk, setIsInputOk] = useState<boolean>(false);

    const ref1 = useRef<HTMLInputElement>(null);
    const ref2 = useRef<HTMLInputElement>(null);

    useEffect(() => {

        onSearch();

    }, []);

    useEffect(() => {
        // 入力okかチェック
        setIsInputOk(wellplateId.length === 8 && flowcellId.length === 8);
    }, [wellplateId, flowcellId]);

    // 検体IDの配列を返す
    const getOldSampleIds = (): string[] => {
        const old = sessionStorage.getItem(sessionKey);
        return JSON.parse(old ?? "[]");
    };

    // 検索処理
    const onSearch = () => {

        const sampleIds = getOldSampleIds();

        if (sampleIds.length === 0) {
            // 念の為チェック
            navigate("/reception/new");
            return;
        }

        setShowSpinner(true);

        const req: PpTestKitControllerIndexRequest = {
            size: 500,
            page: 1,
            sample_ids: sampleIds,
            is_confirmed: false, // 申し込みのないデータも含める
            has_medic_inst: true, // 病院の紐付きがあるものに限定する
        };

        adminPpTestKitApi.v1AdminPpTestKitPost(req)
            .then(({data}) => {

                // 採番された数
                let total = 0;
                // 申し込みのない件数を取得
                let notApplied = 0;

                data.pp_test_kits.forEach((d) => {
                    if (CheckIsReceptionOK(d.status)) {
                        total++;
                    }
                    if (d.status !== PpteststatusStatus.Status22 && !d.is_confirmed) {
                        // 強制検査中でなくて、検査申込がないものをカウントする
                        notApplied++;
                    }
                });

                setTotalNum(total);
                setNotAppliedNum(notApplied);

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }


    // 戻るをクリック
    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(`/reception/new`);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

        switch (e.currentTarget.name) {
            case "wellplate_id":
                setWellplateId(e.currentTarget.value);
                // とりあえず、順序として、wellplateId --> flowcellIdの順で入力するものとして、8桁の入力が完了したら、フォーカスを移動する
                if (e.currentTarget.value.length === 8) {
                    ref2.current?.focus();
                    ref2.current?.select();
                }
                break;
            case "flowcell_id":
                setFlowcellId(e.currentTarget.value);
                break;
        }
    };

    const onClear = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        setWellplateId("");
        setFlowcellId("");
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {

        e.preventDefault();

        if (wellplateId === "" || flowcellId === "") {
            // 入力不備
            setModalType(ModalType.InputError);
            return;
        }

        setModalType(ModalType.InputConfirm);
    };

    const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setModalType(null);
    };

    // データの送信
    const onComplete = (e: React.MouseEvent<HTMLButtonElement>): void => {

        e.preventDefault();

        const req: PpTestKitControllerSetWellplateIDRequest = {
            wellplate_id: wellplateId,
            flowcell_id: flowcellId,
            sample_ids: getOldSampleIds(),
        };

        setShowSpinner(true);

        adminPpTestKitApi
            .v1AdminPpTestKitSetWellplateIdPost(req)
            .then(() => {
                setWellplateId("");
                setFlowcellId("");
                sessionStorage.removeItem(sessionKey);
                setModalType(ModalType.Complete);
            })
            .catch((err) => {
                if (err.response.status === 406) {
                    // バリデーションエラー
                    const keys = Object.keys(err.response.data);

                    if (keys.includes("wellplate_id")) {
                        setModalType(ModalType.WellplateDuplicate);
                    } else if (keys.includes("flowcell_id")) {
                        setModalType(ModalType.FlowCellDuplicate);
                    }

                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    // 検査トップに戻るボタン
    const onClickTop = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(`/reception`);
    };

    // フォーカス時選択
    const onFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.currentTarget.select();
    }

    return <Layout title="検査受付">

        <StyledReceptionRegister onSubmit={onSubmit}>

            <div className="box">
                <H3 title="試験情報登録" subtitle="input"/>
                <p>紐付け対象件数をご確認の上、PCR試験プレートID、Flow Cell IDを読み取ってください。</p>
            </div>


            <div className="box">

                <div className="row">

                    <div className="column">
                        <H3 title="紐付け対象データ">
                            <Button type="button" color={ButtonColor.Gray} icon={ButtonIcon.Arrow}>クリア</Button>
                        </H3>
                        <table>
                            <tbody>
                            <tr>
                                <th>対象件数</th>
                                <td>{totalNum}</td>
                            </tr>
                            <tr>
                                <th>検査未申込件数</th>
                                <td>{notAppliedNum}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="column">
                        <H3 title="登録する試験情報">
                            <Button type="button" color={ButtonColor.Gray} icon={ButtonIcon.Arrow} onClick={onClear}>クリア</Button>
                        </H3>

                        <table className="input">
                            <tbody>
                            <tr>
                                <th>PCR試験プレートID</th>
                                <td>
                                    <input type="text" name="wellplate_id" placeholder="例：A1234567" value={wellplateId} onChange={onChange} maxLength={8} ref={ref1} onFocus={onFocus}/>
                                </td>
                            </tr>
                            <tr>
                                <th>Flow Cell ID</th>
                                <td>
                                    <input type="text" name="flowcell_id" placeholder="例：ABC12345" value={flowcellId} onChange={onChange} maxLength={8} ref={ref2} onFocus={onFocus}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>

            <div className="btn-area">
                <Button type="button" color={ButtonColor.Gray} icon={ButtonIcon.Arrow} onClick={onClickBack}>戻る</Button>
                <Button type="submit" color={ButtonColor.Orange} icon={ButtonIcon.Arrow} disabled={!isInputOk}>登録</Button>
            </div>

        </StyledReceptionRegister>

        {modalType === ModalType.InputError && <Modal color={ModalColor.Danger}>
            <h4>試験情報の登録エラー</h4>
            <h5>「登録する試験情報」のIDを入力してください。</h5>
            <p>
                登録する試験情報の<strong>PCR試験プレートID</strong>と、<br/>
                <strong>Flow Cell ID</strong>を読み取ってください。
            </p>
            <div className="btn-area">
                <Button type="button" color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={onModalClose}>元の画面に戻る</Button>
            </div>

        </Modal>}

        {modalType === ModalType.InputConfirm && <Modal color={ModalColor.Success}>
            <h4>試験情報の登録</h4>
            <h5>以下の紐付け内容でよろしいですか？</h5>
            <ul>
                <li><label className="small">PCR試験プレートID</label><span className="value">{wellplateId}</span></li>
                <li><label className="small">Flow Cell ID</label><span className="value">{flowcellId}</span></li>
            </ul>
            <div className="btn-area">
                <Button type="button" color={ButtonColor.Gray} icon={ButtonIcon.Arrow} onClick={onModalClose}>いいえ</Button>
                <Button type="button" color={ButtonColor.Orange} icon={ButtonIcon.Arrow} onClick={onComplete}>はい</Button>
            </div>

        </Modal>}


        {modalType === ModalType.Complete && <Modal color={ModalColor.Success}>
            <h4>試験情報の登録</h4>
            <h5>紐付けが完了しました。</h5>
            <ul>
                <li><label>登録対象件数</label><span className="num">{totalNum}</span><span className="postfix">件</span></li>
                <li><label>検査未申込件数</label><span className="num">{notAppliedNum}</span><span className="postfix">件</span></li>
            </ul>
            <div className="btn-area">
                <Button type="button" color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={onClickTop}>検査受付トップに戻る</Button>
            </div>

        </Modal>}

        {modalType === ModalType.WellplateDuplicate && <Modal color={ModalColor.Danger}>
            <h4>試験情報の登録エラー</h4>
            <h5>「登録する試験情報」の入力内容をご確認ください。</h5>
            <p>
                既に登録済みの<strong>PCR試験プレートID</strong>です。<br/>
                <strong>別の試験プレート</strong>を読み取ってください。
            </p>
            <div className="btn-area">
                <Button type="button" color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={onModalClose}>元の画面に戻る</Button>
            </div>

        </Modal>}

        {modalType === ModalType.FlowCellDuplicate && <Modal color={ModalColor.Danger}>
            <h4>試験情報の登録エラー</h4>
            <h5>「登録する試験情報」の入力内容をご確認ください。</h5>
            <p>
                既に登録済みの<strong>Flow Cell ID</strong>です。<br/>
                <strong>別のFlow Cell ID</strong>を読み取ってください。
            </p>
            <div className="btn-area">
                <Button type="button" color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={onModalClose}>元の画面に戻る</Button>
            </div>

        </Modal>}


    </Layout>

};


const StyledReceptionRegister = styled(StyledForm)`

    .row {
        display: flex;
        justify-content: space-between;
        background-image: url(${rightLightBlue});
        background-position: left 50% bottom 50px;
        background-repeat: no-repeat;

        .column {
            width: 48%;

            &:first-child {
                h3 {
                    // 高さ合わせのダミー

                    button {
                        opacity: 0;
                        cursor: default;
                    }
                }
            }

            table {
                width: 100%;

                th {
                    font-weight: normal;
                    width: 180px;
                    text-align: left;
                    padding: 19px 23px;
                    background-color: #F4F9F8;
                    border: 1px solid #ccc;
                    vertical-align: middle;
                }

                td {
                    font-weight: normal;
                    text-align: left;
                    padding: 19px 23px;
                    background-color: #fff;
                    border: 1px solid #ccc;
                    vertical-align: middle;
                    position: relative;
                }

                &.input {
                    th {
                        width: 215px;
                        padding: 10px 23px;
                    }

                    td {
                        padding: 10px 23px;
                    }

                }

            }

        }
    }

`;