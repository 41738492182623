import React, {useContext} from "react";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "./Button";
import {ErrorMessage} from "./ErrorMessage";
import {Prefectures} from "../../utility/Utility";
import {ApiZipCode} from "../../api/Zip";
import {ZipCodeResponse} from "../../typings";
import {AppContext} from "../../contexts/AppContext";

interface Props {
    postalCode: string
    postalCode1: string;
    postalCode2: string;
    prefCode: string;
    city: string;
    street: string;
    building: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    isRequired: boolean;
    errors: { [key: string]: string };

    setPrefCode: (s: string) => void;
    setCity: (s: string) => void;
    setStreet: (s: string) => void;
    setBuilding: (s: string) => void;
}

export const AddressSet = (props: Props) => {

    const {setDangerMessage, setShowSpinner} = useContext(AppContext);

    // 郵便番号検索
    const onZipcode = (e: React.MouseEvent<HTMLButtonElement>): void => {

        e.preventDefault();

        // 一応入力チェック
        if(props.postalCode1.length !== 3 || props.postalCode2.length !== 4) {
            setDangerMessage("郵便番号を正しく入力してください。");
            return;
        }

        setShowSpinner(true);

        ApiZipCode(props.postalCode)
            .then((res) => {

                const data = res.data as ZipCodeResponse

                console.log(data);

                if (data.status === 200 && data.results) {
                    props.setPrefCode(data.results[0].prefcode);
                    props.setCity(data.results[0].address2);
                    props.setStreet(data.results[0].address3);
                } else {
                    if (res.data.message) {
                        setDangerMessage(res.data.message);
                    } else {
                        setDangerMessage("郵便番号が見つかりませんでした。");
                    }
                }

            })
            .catch((err) => {
                console.log(err);

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("郵便番号の検索に失敗しました。");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const prefCodes = Object.keys(Prefectures);

    return <React.Fragment>

        <div className="form-group">
            <label className={props.isRequired ? "req" : ""}>郵便番号</label>
            <div>
                <div className="inline">
                    <input type="text" name="postal_code1" value={props.postalCode1} onChange={props.onChange} placeholder={"例：108"} style={{width: "80px"}} maxLength={3}/>
                    <span>-</span>
                    <input type="text" name="postal_code2" value={props.postalCode2} onChange={props.onChange} placeholder={"例：0014"} style={{width: "100px"}} maxLength={4}/>

                    <Button type="button" color={ButtonColor.Green} size={ButtonSize.Small} icon={ButtonIcon.Arrow} onClick={onZipcode}>郵便番号から住所を入力</Button>
                </div>
                <div className="hint">※半角数字</div>
                <ErrorMessage message={props.errors["postal_code"]}/>
            </div>
        </div>

        <div className="form-group">
            <label className={props.isRequired ? "req" : ""}>都道府県</label>
            <div>
                <select name="pref_code" value={props.prefCode} onChange={props.onChange}>
                    <option value="">選択してください</option>

                    {prefCodes.map((_prefCode, i) => {
                        return <option key={`pref-${i}`} value={_prefCode}>{Prefectures[_prefCode]}</option>
                    })}

                </select>
                <ErrorMessage message={props.errors["pref_code"]}/>
            </div>
        </div>

        <div className="form-group">
            <label className={props.isRequired ? "req" : ""}>市区町村</label>
            <div>
                <input type="text" name="city" value={props.city} maxLength={20} className="medium" onChange={props.onChange} placeholder={"例：港区"}/>
                <ErrorMessage message={props.errors["city"]}/>
            </div>
        </div>

        <div className="form-group">
            <label className={props.isRequired ? "req" : ""}>丁目・番地</label>
            <div>
                <input type="text" name="street" value={props.street} maxLength={30} className="medium" onChange={props.onChange} placeholder={"例：芝5-26-24"}/>
                <ErrorMessage message={props.errors["street"]}/>
            </div>
        </div>

        <div className="form-group">
            <label>建物名・部屋番号</label>
            <div>
                <input type="text" name="building" value={props.building} maxLength={30} className="medium" onChange={props.onChange} placeholder={"例：田町スクエア3F"}/>
                <ErrorMessage message={props.errors["building"]}/>
            </div>
        </div>


    </React.Fragment>

}