import {adminPpFaqApi, adminPpFaqCategoryApi} from "../../api/Api";
import React, {useContext, useEffect, useState} from "react";
import {Layout} from "../common/Layout";
import {AccesstokenRole, PpFaqControllerIndexRequest, PpFaqControllerSortRequest, TypesPpFaqCategoryData, TypesPpFaqData} from "../../generated";
import {AppContext} from "../../contexts/AppContext";
import {H2} from "../common/Headline";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import icoUp from "../../images/ico-up.svg";
import icoDown from "../../images/ico-down.svg";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {useNavigate} from "react-router-dom";

export const PpFaqSort = () => {

    const {setShowSpinner, setDangerMessage, setSuccessMessage, role} = useContext(AppContext);
    const [ppFaqCategories, setPpFaqCategories] = useState<TypesPpFaqCategoryData[]>([]);
    const [ppFaqCategoryId, setPpFaqCategoryId] = useState<string>("");
    const [ppFaqs, setPpFaqs] = useState<TypesPpFaqData[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (role && role !== AccesstokenRole.RoleMaster) {
            navigate("/system");
        }
    }, [role]);

    useEffect(() => {
        getFaqCategories();
    }, []);

    useEffect(() => {
        if (ppFaqCategoryId === "") {
            setPpFaqs([]);
        } else {
            getPpFaqs();
        }
    }, [ppFaqCategoryId]);

    const getFaqCategories = () => {

        setShowSpinner(true);

        adminPpFaqCategoryApi
            .v1AdminPpFaqCategoryGet(1, 200)
            .then(({data}) => {
                setPpFaqCategories(data.pp_faq_categories);
            })
            .catch((err) => {
                if (err.response.data && err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました。");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const getPpFaqs = () => {

        setShowSpinner(true);

        const req: PpFaqControllerIndexRequest = {
            pp_faq_category_id: Number(ppFaqCategoryId),
            page: 1,
            size: 1000,
        };

        adminPpFaqApi
            .v1AdminPpFaqPost(req)
            .then(({data}) => {
                setPpFaqs(data.pp_faqs);
            })
            .catch((err) => {
                if (err.response.data && err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました。");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        setPpFaqCategoryId(e.currentTarget.value);
    }

    const onMove = (e: React.MouseEvent<HTMLSpanElement>, direction: "up" | "down", id: number) => {
        e.preventDefault();

        // 現在のオフセットを取得
        let currentOffset = 0;
        let newOffset = 0;
        ppFaqs.forEach((d, i) => {
            if (d.id === id) {
                currentOffset = i;

                if (direction === "up") {
                    newOffset = i - 1;
                } else {
                    newOffset = i + 1;
                }
            }
        });

        if (newOffset < 0) {
            newOffset = 0;
        }
        if (newOffset >= ppFaqs.length) {
            newOffset = ppFaqs.length - 1;
        }

        if (currentOffset === newOffset) {
            // 変更がない場合NOOP（範囲外への変更）
            return;
        }

        // next配列を並び替えてステート更新
        // 並び替えロジックは、以下参照したもの
        // https://blog.beatdjam.com/entry/2017/09/24/025854
        const next = [...ppFaqs];
        next[currentOffset] = [next[newOffset], next[newOffset] = next[currentOffset]][0];

        setShowSpinner(true);

        const req: PpFaqControllerSortRequest = {
            ids: next.map(d => d.id),
        }

        adminPpFaqApi
            .v1AdminPpFaqSortPost(req)
            .then(() => {
                setPpFaqs(next);
                setSuccessMessage("表示順を変更しました。");
            })
            .catch((err) => {
                if (err.response.data && err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました。");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            })
    }

    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/pp_faq`);
    }

    return <Layout title={"FAQ管理"}>

        <StyledPpFaqSort>

            <div className="box">

                <H2 title="1. カテゴリの選択" subtitle="Category"/>

                <p>
                    表示順を変更するカテゴリを選択してください。
                </p>

                <div className="form-group">
                    <select value={ppFaqCategoryId} onChange={onCategoryChange}>
                        <option value="">ここから選択</option>
                        {ppFaqCategories.map((d, i) => {
                            return <option key={`fc-${i}`} value={d.id}>{d.category_name}</option>
                        })}
                    </select>
                </div>
            </div>

            {ppFaqs.length > 0 && <div className="box">
                <H2 title="2. 表示順の設定" subtitle="Sort"/>
                <p>
                    表示順を並び替えてください。
                </p>
                <ul>
                    {ppFaqs.map((d, i) => {
                        return <li key={`faq-${i}`}>
                            <span className="btn-up" onClick={(e) => onMove(e, "up", d.id)}/>
                            <span className="btn-down" onClick={(e) => onMove(e, "down", d.id)}/>
                            {d.question}
                        </li>
                    })}
                </ul>
            </div>}


            <Button color={ButtonColor.Gray} icon={ButtonIcon.Arrow} type="button" onClick={onClickBack}>前の画面に戻る</Button>

        </StyledPpFaqSort>

    </Layout>

}

const StyledPpFaqSort = styled(StyledForm)`

  p {
    margin-bottom: 20px;
  }

  ul {
    display: block;
    list-style-type: none;

    li {
      margin-bottom: 20px;
      border-radius: 4px;
      border: 1px solid #ccc;
      padding: 15px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .btn-up, .btn-down {
        display: block;
        width: 25px;
        height: 25px;
        margin-right: 15px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 95%;
        background-image: url(${icoUp});
        border-radius: 25px;
        cursor: pointer;
        padding: 0 5px;

        &:hover {
          background-color: #ddd;
        }
      }

      .btn-down {
        background-image: url(${icoDown});

      }
    }
  }


`