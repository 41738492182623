import {ErrorMessage} from "./ErrorMessage";
import React from "react";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "./Button";
import styled from "styled-components";

interface Props {
    currentEmail: string;
    email: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    errors: { [key: string]: string };
    disabled: boolean;
    toggleChangeable: () => void;
    isPasswordChange: boolean;
}

export const EditUserEmailInput = ({currentEmail, email, onChange, errors, disabled, toggleChangeable, isPasswordChange}: Props) => {

    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        toggleChangeable();
    }

    return <>
        <StyledEditUserEmailInput className="form-group current-value-area">
            <label className="req">メールアドレス</label>
            <div className="form-object">
                <div className="inline">
                    <input type="email" name="current_email" value={currentEmail} placeholder={"半角英数"} disabled={true}/>
                    <Button color={disabled ? ButtonColor.Green : ButtonColor.Gray} type="button" size={ButtonSize.Small} icon={ButtonIcon.Arrow} disabled={isPasswordChange} onClick={onClick}>
                        {disabled ? <>メールアドレスを<br/>変更する</> : <>変更をキャンセル</>}
                    </Button>
                </div>
                <p className="hint">＊半角英数字。</p>
            </div>

            <div className="hint-balloon">
                <span className="red">＊ログインIDはメールアドレスをご入力ください。</span><br/>
                ＊ご登録はフリーメール（GmailやYahooメールなど）をお勧めしております。<br/>
                ＊携帯キャリア発行のメールアドレスは、迷惑メールとして振り分けられる可能性があるためご注意ください。<br/>
                ＊<span className="red">@pitpet.jp</span>からのメールが受信できるよう、受信設定をご確認ください。<br/>
            </div>
        </StyledEditUserEmailInput>

        {!disabled && <StyledEditUserEmailInput className="form-group input-area">
            <label className="req">新しい<br/>メールアドレス</label>
            <div className="form-object">
                <input type="email" name="email" value={email} onChange={onChange} placeholder={"半角英数"} disabled={disabled}/>
                <ErrorMessage message={errors["email"]}/>
                <p className="hint">＊半角英数字。</p>
            </div>

            <div className="hint-balloon">
                <span className="red">
                    ＊メールアドレス変更後は必ずユーザー様にパスワードの再設定行うようにお願いいたします。<br/>
                    ＊メールアドレス変更後、ログイン画面「パスワードをお忘れですか？」にて変更をするようご案内お願いいたします。
                </span>
            </div>

        </StyledEditUserEmailInput>}

    </>

}

const StyledEditUserEmailInput = styled.div`
  &.current-value-area {
    align-items: center;

    label {
      padding-top: 0;
      
      &:after {
        top: 0 !important;
      }
    }

    > .form-object {
      max-width: 480px;
    }

    .hint-balloon {
      left: 0;
      width: 440px;
      top: 0;
    }
    
  }

  &.input-area {
    align-items: center;
    
    label {
      padding-top: 0;

      &:after {
        top: 10px !important;
      }
    }

    > .form-object {
      max-width: 340px;
    }
    .hint-balloon {
      left: 0;
      width: 340px !important;
      top: 0;
    }
  
  }
`;