import {H3} from "../common/Headline";
import {Layout} from "../common/Layout";
import styled from "styled-components";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {adminPpMstIdentificationCdApi} from "../../api/Api";
import {PpMstIdentificationCdControllerIndexRequest, TypesPpMstIdentificationCdData} from "../../generated";
import {Counter} from "../common/Counter";
import {SearchForm} from "./SearchForm";
import {Paginator} from "../common/Paginator";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Table} from "../common/Table";
import {MainColor} from "../common/Colors";
import rightWhite from "../../images/right-white.svg";
import {CompleteMessage} from "../common/CompleteMessage";
import {SlashedDate} from "../../utility/Utility";
import {Modal, ModalColor} from "../result/Modal";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";

export const PpMstIdentificationCdIndex = () => {

    const {setShowSpinner, setDangerMessage, setCompleteMessage} = useContext(AppContext);
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [ppMstIdentificationCds, setPpMstIdentificationCds] = useState<TypesPpMstIdentificationCdData[]>([]);
    const [isModal, setIsModal] = useState<boolean>(false);
    const [ppMstIdentificationCd, setPpMstIdentificationCd] = useState<TypesPpMstIdentificationCdData | null>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const size = 100;

    useEffect(() => {

        const params = new URLSearchParams(location.search);
        const messageType = params.get("message_type") ?? "";

        if (messageType === "create_complete") {
            setCompleteMessage("識別コードの新規追加が完了しました。");
        }

        if (messageType === "edit_complete") {
            setCompleteMessage("識別コードの編集が完了しました。");
        }

        setPage(params.get("page") ? Number(params.get("page")) : 1);

        getIndex();

    }, [location.search]);

    const getIndex = () => {
        setShowSpinner(true);

        const params = new URLSearchParams(location.search);

        const page = params.get("page") ? Number(params.get("page")) : 1

        const req:PpMstIdentificationCdControllerIndexRequest = {
            page: page,
            size: size,
            partner_inst_id: params.get("partner_inst_id") ? Number(params.get("partner_inst_id")) : 0,
            authority_level: params.get("authority_level") ?? "",
            identification_cd: params.get("identification_cd") ?? "",
            description: params.get("description") ?? "",
        }

        adminPpMstIdentificationCdApi
            .v1AdminPpMstIdentificationCdPost(req)
            .then(({data}) => {
                setTotal(data.total);
                setPpMstIdentificationCds(data.pp_mst_identification_cds);
            })
            .catch((err) => {
                if (err.response.data && err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました。");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onPageChange = (page: number): void => {
        setPage(page);
        const params = new URLSearchParams(location.search);
        params.set("page", String(page));

        navigate(`/pp_mst_identification_cd?${params.toString()}`);
    };

    const onDeleteConfirm = (e: React.MouseEvent<HTMLSpanElement>, d: TypesPpMstIdentificationCdData) => {
        e.preventDefault();
        setIsModal(true);
        setPpMstIdentificationCd(d);
    }

    const onDeleteCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsModal(false);
        setPpMstIdentificationCd(null);
    };

    const onDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        setShowSpinner(true);

        adminPpMstIdentificationCdApi
            .v1AdminPpMstIdentificationCdDeleteIdDelete(String(ppMstIdentificationCd?.id))
            .then(() => {
                setCompleteMessage("識別コードを削除しました。");
                setIsModal(false);
                setPpMstIdentificationCd(null);
                getIndex();
            })
            .catch((err) => {
                if (err.response.data && err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました。");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });

    };

    return <Layout title={"識別コード管理"}>

        <StyledPpMstIdentificationIndex>

            <SearchForm/>

            <CompleteMessage/>

            <div className="box">

                <H3 title="識別コード一覧" subtitle="List" counter={<Counter total={total} size={size} current={page}/>}/>

                <Table>

                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>登録日</th>
                        <th>提携法人</th>
                        <th>識別コード</th>
                        <th>アクセス権限<br/>レベル</th>
                        <th>説明</th>
                        <th/>
                        <th/>
                    </tr>
                    </thead>

                    <tbody>

                    {ppMstIdentificationCds.map((d) => {

                        return <tr key={`cd-${d.id}`}>

                            <td>{d.id}</td>
                            <td>{SlashedDate(d.created)}</td>
                            <td>{d.partner_inst_name}</td>
                            <td>{d.identification_cd}</td>
                            <td>{d.authority_level}</td>
                            <td>{d.description}</td>
                            <td style={{textAlign: "center"}}>
                                <NavLink to={`/pp_mst_identification_cd/edit/${d.id}`} className="btn-link">編集</NavLink>
                            </td>
                            <td style={{textAlign: "center"}}>
                                <span onClick={(e) => onDeleteConfirm(e, d)} className="btn-del">削除</span>
                            </td>

                        </tr>

                    })}

                    </tbody>

                </Table>

                <Paginator onClick={onPageChange} pages={Math.ceil(total / size)} page={page}/>

            </div>

            {isModal && ppMstIdentificationCd && <Modal color={ModalColor.Danger}>
                <h4>識別コードの削除</h4>
                <h5>以下のIDを削除してよろしいですか？</h5>

                <div className="confirmation">
                    <span>登録ID</span>
                    <strong>{ppMstIdentificationCd.id}</strong>
                    <span>識別コード</span>
                    <strong>{ppMstIdentificationCd.identification_cd}</strong>
                </div>

                <div className="btn-area">
                    <Button type="button" color={ButtonColor.Gray} icon={ButtonIcon.Arrow} onClick={onDeleteCancel}>いいえ</Button>
                    <Button type="button" color={ButtonColor.Danger} icon={ButtonIcon.Arrow} onClick={onDelete}>はい</Button>
                </div>
            </Modal>}

        </StyledPpMstIdentificationIndex>

    </Layout>

}

const StyledPpMstIdentificationIndex = styled.div`

  tr {
    td:nth-child(7), td:nth-child(8) {
      text-align: center !important;
      width: 120px;
    }
  }

  .btn-link, .btn-del {
    background-color: ${MainColor};
    color: #fff;
    font-size: 12px !important;
    line-height: 24px;
    display: inline-block;
    text-decoration: none !important;
    padding: 0 30px 0 20px;
    letter-spacing: 1px;
    border-radius: 24px;
    background-image: url(${rightWhite});
    background-repeat: no-repeat;
    background-position: top 50% right 15px;
    background-size: 5px auto;
  }

  .btn-del {
    background-color: #ccc;
  }

  .modal-inner {
    background-color: #fff;

    .confirmation {
      background-color: #fff;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 22px 0 0 0;
      align-items: flex-end;
      margin: 0 20px;

      span {
        font-size: 14px;
        width: 50%;
        text-align: right;
        padding-right: 20px;
        margin-bottom: 20px;
      }

      strong {
        font-size: 24px;
        color: #FF9871;
        font-weight: 400;
        line-height: 1;
        width: 50%;
        margin-bottom: 20px;
      }
    }

    p {
      padding-top: 20px !important;

      span {
        background: linear-gradient(transparent 50%, rgba(217, 62, 76, 0.2) 20%);

        strong {
          background: none !important;
        }
      }
    }
  }

`