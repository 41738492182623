import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {useNavigate} from "react-router-dom";
import {Footer} from "./Footer";
import styled from "styled-components";
import {adminAuthApi} from "../../api/Api";

export const MustAuth = ({children}: React.PropsWithChildren) => {

    const {setShowSpinner, setPpMstUserAdmin, setPpMstUserPartner, setLoginType, setRole, ppMstUserAdmin, ppMstUserPartner, setDangerMessage} = useContext(AppContext);
    const [isNotLogin, setIsNotLogin] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        getMe();
    }, []);

    // 自分自身取得
    const getMe = () => {

        setShowSpinner(true);

        adminAuthApi.v1AdminAuthMeGet()
            .then(({data}) => {

                setLoginType(data.login_type ?? null);
                setPpMstUserAdmin(data.pp_mst_user_admin ?? null);
                setPpMstUserPartner(data.pp_mst_user_partner ?? null);
                setRole(data.role ?? null);

            })
            .catch((err) => {
                console.log(err);
                setIsNotLogin(true);
                setDangerMessage("ログインしてください。");
                setPpMstUserAdmin(null);
                setPpMstUserPartner(null);
                navigate("/login");
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    if (isNotLogin) {
        // ログイン画面に遷移
        navigate("/login");
        return null;
    }

    if (!ppMstUserAdmin && !ppMstUserPartner) {
        // まだ取得できていない場合は何も描画しない
        return null;
    }

    return <StyledMustAuth>

        {children}

        <Footer/>

    </StyledMustAuth>
}

const StyledMustAuth = styled.div`

  position: relative;
  min-height: 100vh;

`;
