import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import React, {useContext, useEffect, useRef, useState} from "react";
import {H2} from "../common/Headline";
import {CheckSampleId} from "../../utility/Utility";
import {useNavigate} from "react-router-dom";
import {PpflowcellStatus, PpTestKitControllerIndexRequest, PpteststatusStatus, TypesPpTestKitData} from "../../generated";
import {adminPpFlowcellApi, adminPpTestKitApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";

interface Props {
    sessionKey: string;
    maxNum: number;
    setPpTestKits: (ppTestKits: TypesPpTestKitData[]) => void;
}

export const SearchForm = ({sessionKey, maxNum, setPpTestKits}: Props) => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [sampleId, setSampleId] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const ref = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);

    const getSampleIds = (): string[] => {
        const old = sessionStorage.getItem(sessionKey);
        return JSON.parse(old ?? "[]");
    };

    useEffect(() => {

        const sampleIds = getSampleIds();

        if (sampleIds.length > 0) {
            setSampleId(sampleIds[0]);
            onSearch(sampleIds);
        }

    }, []);

    useEffect(() => {

        if (CheckSampleId(sampleId)) {
            setErrorMessage("");

            const sampleIds = getSampleIds();

            if (sampleIds.includes(sampleId)) {
                return;
            }

            if (sampleIds.length >= maxNum) {
                setSampleId("");
                setErrorMessage("それ以上受付できません");
                return;
            }

            // 検索実行
            onSearch([sampleId]);

        }

    }, [sampleId]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.currentTarget.name) {
            case "sample_id":
                setSampleId(e.currentTarget.value);
                break;
        }
    };

    const onSearch = (sampleIds: string[]) => {

        setShowSpinner(true);

        const req: PpTestKitControllerIndexRequest = {
            size: 100,
            page: 1,
            sample_ids: sampleIds,
            is_confirmed: true, // 申し込みのないデータは含めない
        };

        adminPpTestKitApi.v1AdminPpTestKitPost(req)
            .then(({data}) => {

                if (data.pp_test_kits.length === 0) {
                    // 該当する検体IDがない場合、検体IDをクリア
                    setSampleId("");
                    setErrorMessage("受付対象外の検体です");
                    return;
                }

                if (data.pp_test_kits[0].status !== PpteststatusStatus.Status22) {
                    // 強制検査中でない場合
                    setSampleId("");
                    setErrorMessage("受付対象外の検体です");
                    return;
                }

                // Flow Cellのステータスチェックに進む
                checkFlowCell(data.pp_test_kits[0]);

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    // Flow Cell のステータスチェック
    const checkFlowCell = (ppTestKit: TypesPpTestKitData) => {
        setShowSpinner(true);

        adminPpFlowcellApi.v1AdminPpFlowcellGetFlowcellIdGet(ppTestKit.flowcell_id ?? "")
            .then(({data}) => {

                if (data.pp_flowcell.status !== PpflowcellStatus.Status2) {
                    // Flow Cell が「2：検査完了」ではない
                    setErrorMessage("当検体の強制検査受付は、検体受付時のFlow Cellの試験結果登録後に実施してください");
                    return;
                }
                setPpTestKits([ppTestKit]);
                sessionStorage.setItem(sessionKey, JSON.stringify([ppTestKit.sample_id]));
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    // 試験情報を登録
    const onSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        const sampleIds = getSampleIds();

        if (sampleIds.length === 0) {
            setErrorMessage("先に検体IDを入力して一覧を表示してから実行してください。");
            return;
        }

        navigate(`/forced_test/register`);
    };

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        if (!window.confirm("一覧をクリアしてやり直します。よろしいですか？")) {
            return;
        }

        setSampleId("");
        sessionStorage.removeItem(sessionKey);
        setPpTestKits([]);
    }

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.currentTarget.select();
    };

    const onSubmitDummy = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    return <StyledSearchForm noValidate={true} onSubmit={onSubmitDummy}>

        <div className="box">

            <H2 title="検体ID読み取り" subtitle="input"/>

            <div className="search-form">

                <div className="form-group">
                    <label>検体ID</label>
                    <div>
                        <input type="text" name="sample_id" placeholder={"例：PC23-1000-NGS-1000"} value={sampleId} onChange={onChange} ref={ref} maxLength={18} onFocus={onFocus}/>
                        {errorMessage && <span className="search-error">{errorMessage}</span>}
                    </div>
                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                    <Button color={ButtonColor.Orange} type="button" icon={ButtonIcon.Arrow} onClick={onSubmit}>試験情報を登録</Button>
                </div>

            </div>

        </div>

    </StyledSearchForm>
};

export const StyledSearchForm = styled(StyledForm)`

  .search-form {

    .form-group {
      position: relative;

      label {
        width: 100px;
        margin-right: 0;
      }

      .search-error {
        color: #fff;
        background-color: #D93E4C;
        font-size: 14px;
        line-height: 47px;
        display: block;
        position: absolute;
        padding: 0 10px;
        border-radius: 6px;
        top: -2px;
        left: 320px;

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: -18px;
          top: 50%;
          transform: translateY(-50%);
          border: 10px solid transparent;
          border-right: 10px solid #D93E4C;
        }
      }

    }

  }

`;