import {TypesPpPetData} from "../../generated";
import {BirthTypeUnknown, CreateJaYmd, GetAnimalType, GetBirthdayType, GetPetGender} from "../../utility/Utility";
import React from "react";
import styled from "styled-components";
import {PetModal} from "../common/PetModal";

interface Props {
    ppPet: TypesPpPetData;
}

export const PartsPetDetail = ({ppPet}: Props) => {

    const [modalImg, setModalImg] = React.useState<string>("");

    return <StyledPartsPetDetail className="form-wrapper">

        <div className="form-group center">
            <label>カルテID</label>
            <div>
                {ppPet.karte_id ?? "カルテIDなし"}
            </div>
        </div>

        <div className="form-group center">
            <label>お名前</label>
            <div>
                {ppPet.pet_name}
            </div>
        </div>

        <div className="form-group center">
            <label>種別</label>
            <div>
                {GetAnimalType(ppPet.animal_type ?? "")}
            </div>
        </div>

        <div className="form-group center">
            <label>品種</label>
            <div>
                {ppPet.breed_name}
                {ppPet.breed_name_parent_1 ? `（両親の品種1：${ppPet.breed_name_parent_1}）` : ""}
                {ppPet.breed_name_parent_2 ? `（両親の品種2：${ppPet.breed_name_parent_2}）` : ""}
            </div>
        </div>

        <div className="form-group center">
            <label>生年月日</label>
            <div>
                {ppPet.birthday && CreateJaYmd(ppPet.birthday ?? "")}
                {ppPet.accuracy_of_pet_birthday !== BirthTypeUnknown && `（${GetBirthdayType(ppPet.accuracy_of_pet_birthday ?? "")}）`}
                {ppPet.accuracy_of_pet_birthday === BirthTypeUnknown && `不明`}
            </div>
        </div>

        <div className="form-group center">
            <label>性別</label>
            <div>
                {GetPetGender(ppPet.pet_sex ?? "")}
            </div>
        </div>

        <div className="form-group">
            <label>アイコン</label>
            <div>
                {ppPet.icon_name && <div className="filename"><span onClick={() => setModalImg(ppPet.img_url ?? "")}>{ppPet.icon_name}（クリックして確認）</span></div>}
                {!ppPet.icon_name && <div className="upload-none">未設定</div>}
            </div>
        </div>

        {modalImg && <PetModal img={modalImg ?? ""} onClose={() => setModalImg("")}/>}

    </StyledPartsPetDetail>


}

const StyledPartsPetDetail = styled.div`
`;