import React from "react";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "./Button";
import {ErrorMessage} from "./ErrorMessage";

interface Props {
    password: string;
    confirmationPassword: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    errors: { [key: string]: string };
    disabled: boolean;
    isEmailChange: boolean;
    toggleChangeable: () => void;
}

export const EditPasswordInput = ({password, confirmationPassword, onChange, errors, disabled, toggleChangeable, isEmailChange}: Props) => {

    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        toggleChangeable();
    }

    const isInputDisabled = !isEmailChange && disabled;

    return <>

        <div className="form-group">
            {isEmailChange && <label className="req">現在のパスワード</label>}
            {(!isEmailChange && !disabled) && <label className="req">新しいパスワード</label>}
            {(!isEmailChange && disabled) && <label>パスワード</label>}
            <div>
                <div className="inline">
                    <input type="password" name="password" disabled={isInputDisabled} value={password} onChange={onChange} placeholder="パスワード"/>
                    <Button color={disabled ? ButtonColor.Green : ButtonColor.Gray} type="button" size={ButtonSize.Small} icon={ButtonIcon.Arrow} disabled={isEmailChange} onClick={onClick}>
                        {disabled ? "パスワードを変更する" : "変更をキャンセル"}
                    </Button>
                </div>
                <ErrorMessage message={errors["password"]}/>
            </div>
        </div>

        <div className="form-group">
            {isEmailChange && <label className="req">現在のパスワード<br />（再入力）</label>}
            {(!isEmailChange && !disabled) && <label className="req">新しいパスワード<br />（再入力）</label>}
            {(!isEmailChange && disabled) && <label>パスワード（再入力）</label>}
            <div>
                <input type="password" name="confirmation_password" disabled={isInputDisabled} value={confirmationPassword} onChange={onChange} placeholder="パスワード（再入力）"/>
                <ErrorMessage message={errors["confirmation_password"]}/>
            </div>
        </div>
    </>
};

