import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import {Table} from "../common/Table";
import {CounterSimple} from "../common/CounterSimple";
import React, {useContext, useEffect, useState} from "react";
import {PpTestResultControllerGetResultRequest, PpTestResultControllerResultIndexRequest, PpTestResultControllerSetResultRequest, PpTestResultControllerTarget, PpteststatusStatus, TypesPpTestResultData} from "../../generated";
import {useLocation} from "react-router-dom";
import {adminResultApi} from "../../api/Api";
import {ErrorType, SearchForm} from "./SearchForm";
import {CheckSampleId, GetTestStatus, Zerofill} from "../../utility/Utility";
import {AppContext} from "../../contexts/AppContext";
import {StyledForm} from "../common/StyledForm";
import styled from "styled-components";
import {Modal, ModalColor} from "./Modal";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";

enum ModalType {
    Confirm = "confirm",
    Complete = "complete",
    Error = "error",
}

export const ResultIndex = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppTestResults, setPpTestResults] = useState<TypesPpTestResultData[]>([]);
    const location = useLocation();
    const [errorType, setErrorType] = useState<ErrorType>(ErrorType.NoInput);
    const [egMirrorResults, setEgMirrorResults] = useState<{ [key: string]: boolean }>({});
    const [isResultSetEnabled, setIsResultSetEnabled] = useState<boolean>(false);
    const [modalType, setModalType] = useState<ModalType | null>(null);
    const [totalNum, setTotalNum] = useState<number>(0);
    const [reTestNum, setReTestNum] = useState<number>(0);
    const [checked, setChecked] = useState<{ [key: string]: boolean }>({});
    const [flowcellId, setFlowcellId] = useState<string | null>(null);
    const sessSampleId = "session_result_sample_id";
    const sessFlowcellId = "session_result_flowcell_id";

    useEffect(() => {

        // 以前の内容を復旧
        // セッションのデータ取得
        const sessSampleIds = JSON.parse(sessionStorage.getItem(sessSampleId) ?? "[]");

        if (sessSampleIds.length > 0) {
            onSearch("", "", sessSampleIds);
        }

    }, []);

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        const flowcellId = params.get("flowcell_id") ?? "";
        const sampleId = params.get("sample_id") ?? "";

        if (flowcellId.length === 8) {
            setErrorType(ErrorType.None);
            onSearch(flowcellId, "", []);
            setFlowcellId(flowcellId);
            return;
        }

        if (CheckSampleId(sampleId)) {
            setErrorType(ErrorType.None);
            onSearch("", sampleId, []);
            setFlowcellId(null);
            return;
        }

    }, [location.search]);

    // 一覧作成処理
    const onSearch = (flowcellId: string, sampleId: string, sampleIds: string[]) => {

        const oldFlowcellId = sessionStorage.getItem(sessFlowcellId);

        if (flowcellId !== "" && oldFlowcellId && flowcellId !== oldFlowcellId) {
            // すでにFlow Cell IDで検索していたらエラー
            setErrorType(ErrorType.ErrorFlowCell);
            return;
        }

        const req: PpTestResultControllerResultIndexRequest = {
            flowcell_id: flowcellId,
            sample_id: sampleId,
            sample_ids: sampleIds,
        };

        setShowSpinner(true);

        adminResultApi.v1AdminResultIndexPost(req)
            .then(({data}) => {

                if (data.pp_test_results.length === 0) {
                    // データが見つからない場合
                    setErrorType(ErrorType.NotFound);
                    return;
                }

                const next: TypesPpTestResultData[] = [...ppTestResults];
                const sampleIds = ppTestResults.map((d) => {
                    return d.sample_id;
                });

                // セッションのデータ取得
                const sessSampleIds = JSON.parse(sessionStorage.getItem(sessSampleId) ?? "[]");

                const checked: { [key: string]: boolean } = {};

                data.pp_test_results.forEach((d) => {
                    if (sampleIds.indexOf(d.sample_id) === -1) {
                        // なければ追加
                        next.push(d);
                        sampleIds.push(d.sample_id);
                    }

                    if (sessSampleIds.indexOf(d.sample_id) === -1) {
                        // FlowCellIdで検索しても検体IDとして保持する
                        sessSampleIds.push(d.sample_id);
                    }

                    checked[d.sample_id] = false;
                });

                // セッションに保存
                sessionStorage.setItem(sessSampleId, JSON.stringify(sessSampleIds));

                if (flowcellId !== "") {
                    sessionStorage.setItem(sessFlowcellId, flowcellId);
                }

                // ステート更新
                setPpTestResults(next);
                // 再検査要否のチェック状態
                setChecked(checked)

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onClear = () => {
        setPpTestResults([]);
        setErrorType(ErrorType.NoInput);
        setEgMirrorResults({});
        setFlowcellId(null);
        setIsResultSetEnabled(false);
        sessionStorage.removeItem(sessSampleId);
        sessionStorage.removeItem(sessFlowcellId);
    }

    const getStatus = (status: string): string => {
        if (checkResultNotSet(status)) {
            return "結果未登録";
        }
        return GetTestStatus().get(status) ?? status;
    };

    // 結果未登録かどうかをチェック
    const checkResultNotSet = (status: string): boolean => {
        // No.12
        // see: https://docs.google.com/spreadsheets/d/1DIDIJivvCHljX9wOHkXJyg5a9ZqQ2xrb8LUpiO4jrn8/edit#gid=520944478
        // 02：検査中 / 12：再検査中 / 22：強制検査中の場合は、結果未登録とする
        return [String(PpteststatusStatus.Status02), String(PpteststatusStatus.Status12), String(PpteststatusStatus.Status22)].indexOf(status) !== -1;
    };

    // EG MIrRORからのデータ取得
    const onGetData = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        if (ppTestResults.length === 0) {
            alert("先にIDを読み取って対象となる一覧を作成してください。");
            return;
        }

        const targets: PpTestResultControllerTarget[] = [];

        ppTestResults.forEach((d) => {

            if (!checkResultNotSet(d.status)) {
                // PiTPET通知ステータスが「結果未登録」以外をスキップ
                return;
            }

            if (checkResultNotSet(d.status) && d.status === PpteststatusStatus.Status22) {
                // 「結果未登録」かつ検査状況管理テーブル.状況が「22：強制検査中」
                return;
            }

            if (!d.metadata_out) {
                // メタデータなし
                return;
            }

            targets.push({
                sample_id: d.sample_id,
                flowcell_id: d.flowcell_id,
            });
        });

        if (targets.length === 0) {
            // 取得対象がない場合
            setDangerMessage("APIからのデータ取得対象がないので、データ取得されません。");
            // 取得対象がなくてもボタンを活性化する
            setIsResultSetEnabled(true);
            return;
        }

        const req: PpTestResultControllerGetResultRequest = {
            targets: targets,
        };

        setShowSpinner(true);

        adminResultApi.v1AdminResultGetResultsPost(req)
            .then(({data}) => {
                setEgMirrorResults(data.results);

                // データを取得したらとりあえずボタンを活性化する。
                setIsResultSetEnabled(true);

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    // EG Mirrorの解析ステータス文字列を返す
    const getEgMirrorStatus = (sampleId: string): string => {
        if (Object.keys(egMirrorResults).indexOf(sampleId) === -1) {
            // まだデータ取得していない場合、あるいはスキップされた場合
            return "-";
        }

        if (egMirrorResults[sampleId]) {
            return "解析完了";
        }

        return "解析失敗";
    };

    // 結果を更新する
    const onClickUpdateResult = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        // 解析失敗がある場合、登録させない
        const keys = Object.keys(egMirrorResults);

        let hasFailure = false;
        keys.forEach((key) => {
            if (!egMirrorResults[key] && !checked[key]) {
                // 解析失敗が含まれ、かつ再検査のチェックが入っていない場合
                hasFailure = true;
            }
        });

        if (hasFailure) {
            // 解析失敗が含まれる場合
            setModalType(ModalType.Error);
        } else {
            // 確認okの場合
            // 件数設定

            let totalNum = 0;
            let reTestNum = 0; // ここは現状0となる

            keys.forEach((key) => {
                if (egMirrorResults[key] && !checked[key]) {
                    totalNum++;
                }
            });

            // 再検査数は、結果取得にかかわらず算出
            const checkedKeys = Object.keys(checked);
            checkedKeys.forEach((key) => {
                if (checked[key]) {
                    reTestNum++;
                }
            });

            setTotalNum(totalNum);
            setReTestNum(reTestNum);
            setModalType(ModalType.Confirm);
        }
    }

    // 結果を登録リクエストを送信する
    const onSetResult = (e: React.MouseEvent<HTMLButtonElement>): void => {

        e.preventDefault();

        // 検体IDを抽出
        const sampleIds: string[] = [];

        // 再検査対象を蓄積
        const reTests: string[] = [];

        ppTestResults.forEach((ppTestResult) => {

            if (!checkResultNotSet(ppTestResult.status)) {
                // 結果未登録以外はスキップ
                return;
            }

            sampleIds.push(ppTestResult.sample_id);

            if (checked[ppTestResult.sample_id]) {
                reTests.push(ppTestResult.sample_id);
            }
        });

        const req: PpTestResultControllerSetResultRequest = {
            flowcell_id: flowcellId,
            sample_ids: sampleIds,
            re_tests: reTests,
        };

        setShowSpinner(true);

        adminResultApi.v1AdminResultSetResultsPost(req)
            .then(() => {
                setModalType(ModalType.Complete);

                // 正常に完了した検体IDのみ、結果未登録から結果登録済みに変更する
                const next: TypesPpTestResultData[] = [];
                ppTestResults.forEach((ppTestResult) => {
                    if (!checked[ppTestResult.sample_id] && egMirrorResults[ppTestResult.sample_id]) {
                        // 再検査要否にチェックがなく、解析完了のものは、今回正常に登録されたものとして、検査完了にする
                        ppTestResult.status = PpteststatusStatus.Status50;
                    }
                    next.push(ppTestResult);
                });
                setPpTestResults(next);

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onModalClose = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        setModalType(null);
    };

    const onChangeCheck = (e: React.ChangeEvent<HTMLInputElement>, sampleId: string): void => {
        const next: { [key: string]: boolean } = {...checked};
        next[sampleId] = e.target.checked;
        setChecked(next);
    }

    return <Layout title="検査結果登録">

        <SearchForm
            errorType={errorType}
            onClear={onClear}
            onGetData={onGetData}
            isResultSetEnabled={isResultSetEnabled}
            onUpdateResult={onClickUpdateResult}
        />

        <StyledResultIndex className="box">

            <H3 title="検体情報一覧" subtitle="list" counter={<CounterSimple total={ppTestResults.length}/>}/>

            <Table>
                <thead>
                <tr>
                    <th>No.</th>
                    <th>検体ID</th>
                    <th>Flow Cell ID</th>
                    <th>位置</th>
                    <th>PiTPET<br/>通知ステータス</th>
                    <th>ペットメタ<br/>データ有無</th>
                    <th className="yellow">EG MIrROR<br/>解析ステータス</th>
                    <th>再検査要否</th>
                </tr>
                </thead>

                <tbody>

                {ppTestResults.map((d, i) => {

                    const isResultNotSet = checkResultNotSet(d.status);
                    const noMetadata = d.is_upd_ymd === null;

                    return <tr key={`result-${i}`} className={(isResultNotSet && noMetadata) || d.status === PpteststatusStatus.Status50 ? "gray" : ""}>

                        <td>{Zerofill(i + 1, 2)}</td>
                        <td>{d.sample_id}</td>
                        <td>{d.flowcell_id}</td>
                        <td>{d.position}</td>
                        <td>{getStatus(d.status)}</td>
                        <td className={!d.metadata_out ? "no-metadata" : ""}>{d.metadata_out ? "あり" : "なし"}</td>
                        <td>{getEgMirrorStatus(d.sample_id)}</td>
                        <td><input type="checkbox" value={d.sample_id} checked={isResultNotSet && checked[d.sample_id]} disabled={!isResultNotSet} onChange={(e) => onChangeCheck(e, d.sample_id)}/></td>
                    </tr>

                })}

                </tbody>

            </Table>

        </StyledResultIndex>

        {/*内容確認*/}
        {modalType === ModalType.Confirm && <Modal color={ModalColor.Success}>
            <h4>検査結果の登録</h4>
            <h5>以下の内容で更新してよろしいですか？</h5>
            <ul>
                <li><label>検査結果登録件数</label><span className="num">{totalNum}</span><span className="postfix">検体</span></li>
                <li><label>再検査件数</label><span className="num">{reTestNum}</span><span className="postfix">件</span></li>
            </ul>
            <div className="btn-area">
                <Button type="button" color={ButtonColor.Gray} icon={ButtonIcon.Arrow} onClick={onModalClose}>いいえ</Button>
                <Button type="button" color={ButtonColor.Orange} icon={ButtonIcon.Arrow} onClick={onSetResult}>はい</Button>
            </div>
        </Modal>}

        {/*登録完了*/}
        {modalType === ModalType.Complete && <Modal color={ModalColor.Success}>
            <h4>検査結果の登録</h4>
            <h5>更新が完了しました。</h5>
            <ul>
                <li><label>検査結果登録件数</label><span className="num">{totalNum}</span><span className="postfix">検体</span></li>
                <li><label>再検査件数</label><span className="num">{reTestNum}</span><span className="postfix">件</span></li>
            </ul>
            <div className="btn-area">
                <Button type="button" color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={onModalClose}>検査結果登録に戻る</Button>
            </div>
        </Modal>}

        {/*エラー*/}
        {modalType === ModalType.Error && <Modal color={ModalColor.Danger}>
            <h4>結果更新エラー</h4>
            <h5>解析失敗の検体が含まれています。</h5>
            <p>
                解析結果がすべて揃ったことを確認し、<br/>
                もう一度<strong>結果更新</strong>ボタンをクリックしてください。
            </p>
            <div className="btn-area">
                <Button type="button" color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={onModalClose}>元の画面に戻る</Button>
            </div>
        </Modal>}

    </Layout>

};


const StyledResultIndex = styled(StyledForm)`

  table {
    thead {
      tr {
        th.yellow {
          background-color: #FFFAE6;
        }
      }
    }

    tbody {
      tr {
        td:last-child {
          text-align: center;
        }

        &.gray {
          td {
            background-color: #DDDDDD !important;
            color: #5E5E5E !important;;

            &.no-metadata {
              color: #D93E4C !important;
            }
          }
        }
      }
    }
  }
`;
