import {H1, H3} from "../common/Headline";
import React, {useContext, useEffect, useState} from "react";
import {Layout} from "../common/Layout";
import {AppContext} from "../../contexts/AppContext";
import {PpTestResultControllerGetBacteriaRequest, PpTestResultControllerGetBacteriaResponse, TypesPpTestKitData} from "../../generated";
import {useNavigate, useParams} from "react-router-dom";
import {adminHistoryApi, adminPpTestKitApi} from "../../api/Api";
import {DetailHeader} from "./DetailHeader";
import styled from "styled-components";
import {Table} from "../common/Table";
import {Zerofill} from "../../utility/Utility";
import sortCursor from "../../images/sort-cursor.png";
import sortAsc from "../../images/sort-asc.png";
import sortDesc from "../../images/sort-desc.png";
import {CompleteMessage} from "../common/CompleteMessage";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {AxiosRequestConfig} from "axios";

interface PrefixType {
    key: string;
    ja: string;
    en: string;
}

export const PpTestStatusBacteria = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppTestKit, setPpTestKit] = useState<TypesPpTestKitData | null>(null);
    const params = useParams();
    const navigate = useNavigate();
    const [bacteria, setBacteria] = useState<PpTestResultControllerGetBacteriaResponse | null>(null);
    const [key, setKey] = useState<string>("phylum");
    const [sort, setSort] = useState<string>("ratio");
    const [direction, setDirection] = useState<string>("desc");

    const prefixes: PrefixType[] = [
        {
            key: "phylum",
            ja: "門",
            en: "phylum",
        },
        {
            key: "class",
            ja: "綱",
            en: "class",
        },
        {
            key: "order",
            ja: "目",
            en: "order",
        },
        {
            key: "family",
            ja: "科",
            en: "family",
        },
        {
            key: "genus",
            ja: "属",
            en: "genus",
        },
        {
            key: "species",
            ja: "種",
            en: "species",
        },
    ];

    useEffect(() => {
        if (params.sampleId) {
            getPpTestKit(params.sampleId);
        }
    }, []);

    const onReloadPpTestKit = () => {
        getPpTestKit(params.sampleId ?? "");
    }

    const getPpTestKit = (sampleId: string) => {

        setShowSpinner(true);

        adminPpTestKitApi
            .v1AdminPpTestKitGetSampleIdGet(sampleId)
            .then(({data}) => {
                setPpTestKit(data.pp_test_kit);

                if (!data.pp_test_kit.pp_user_id) {
                    setDangerMessage("ご指定の検査情報は検査申込されていません。");
                    navigate("/pp_test_status");
                }

                // 菌組成情報を続けて取得
                getBacteria(sampleId, false);

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
                navigate("/pp_test_status");
            })
            .finally(() => {
                setShowSpinner(false);
            });

    };

    const getBacteria = (sampleId: string, isCsv: boolean) => {

        setShowSpinner(true);

        const req: PpTestResultControllerGetBacteriaRequest = {
            sample_id: sampleId,
            is_csv: isCsv,
            csv_type: key,
        }

        const options: AxiosRequestConfig = {};

        if (isCsv) {
            // csvの場合
            options.responseType = "blob";
        }

        adminHistoryApi
            .v1AdminHistoryBacteriaPost(req, options)
            .then((res) => {

                if (isCsv) {
                    // CSVの場合
                    const data = res.data as never;
                    const blob = new Blob([data], {type: "text/csv"});
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `${sampleId}_${key}.csv`;
                    a.click();
                    return;
                }

                setBacteria(res.data);

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
                navigate(`/pp_test_status/detail/${sampleId}`);
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onClickCsv = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!ppTestKit) {
            return;
        }
        getBacteria(ppTestKit.sample_id, true);
    }

    const changeKey = (e: React.MouseEvent<HTMLLIElement>, key: string) => {
        e.preventDefault();
        setKey(key);
    }

    if (!ppTestKit || ppTestKit.pp_user === null || bacteria === null) {
        return <Layout title="検査情報照会">
            <H1 title="会員の登録情報" subtitle="user data"/>
        </Layout>
    }

    // 菌組成情報をフィルタリンング
    const getBacteriaRatio = (): JSX.Element => {

        let data: { [key: string]: number } = {};

        switch (key) {
            case "phylum":
                data = bacteria.data.phylum;
                break;
            case "class":
                data = bacteria.data.class;
                break;
            case "order":
                data = bacteria.data.order;
                break;
            case "family":
                data = bacteria.data.family;
                break;
            case "genus":
                data = bacteria.data.genus;
                break;
            case "species":
                data = bacteria.data.species;
                break;
        }

        const keys = Object.keys(data);

        // ソート基準に従いソートする
        const sorted = keys.sort((a, b) => {

            if (sort === "taxon") {
                // 割合でのソート
                if (a < b) {
                    return direction === "desc" ? 1 : -1;
                }
                if (a > b) {
                    return direction === "desc" ? -1 : 1;
                }
            } else if (sort === "ratio") {
                // 割合でのソート
                if (data[a] < data[b]) {
                    return direction === "desc" ? 1 : -1;
                }
                if (data[a] > data[b]) {
                    return direction === "desc" ? -1 : 1;
                }
            }

            return 0;
        });

        return <tbody>
        {sorted.map((f, k) => {
            return <tr key={`bacteria-${k}`}>
                <td>{Zerofill(k + 1, 2)}</td>
                <td>{f.split(";").join(`\u00A0 \u00A0 \u00A0`)}</td>
                <td>{data[f]}%</td>
            </tr>
        })}
        </tbody>
    };

    const onChangeSort = (e: React.MouseEvent<HTMLTableCellElement>, sort: string) => {
        e.preventDefault();
        setSort(sort);
        setDirection(direction === "desc" ? "asc" : "desc");
    };

    return <Layout title="検査情報照会">

        <H1 title="会員の登録情報" subtitle="user data"/>

        <CompleteMessage/>

        <DetailHeader active={2} ppTestKit={ppTestKit} onReload={onReloadPpTestKit}/>

        <StyledPpTestStatusBacteria>

            <div className="box mt-minus">

                <H3 title="菌組成情報" subtitle="fungal tissue"/>

                <div className="tab-area">

                    <ul className="bacteria-tabs">
                        {prefixes.map((d, i) => {
                            return <li
                                onClick={(e) => changeKey(e, d.key)}
                                className={key === d.key ? "active" : ""}
                                key={`prefix-${i}`}>
                                <span>{d.ja}</span>
                                {d.en}
                            </li>
                        })}
                    </ul>

                    <Button type="button" color={ButtonColor.Orange} icon={ButtonIcon.Arrow} onClick={onClickCsv}>菌組成CSVダウンロード</Button>
                </div>

                <Table>
                    <thead>
                    <tr>
                        <th>No.</th>
                        <th className={sort === "taxon" ? `sort ${direction}` : "sort"} onClick={(e) => onChangeSort(e, "taxon")}><span>菌名(taxon)</span></th>
                        <th className={sort === "ratio" ? `sort ${direction}` : "sort"} onClick={(e) => onChangeSort(e, "ratio")}><span>割合(ratio)</span></th>
                    </tr>
                    </thead>

                    {getBacteriaRatio()}

                </Table>

            </div>

        </StyledPpTestStatusBacteria>
    </Layout>

}

const StyledPpTestStatusBacteria = styled.div`

    // ヘッダー部分に被せる

    .mt-minus {
        margin-top: -120px !important;
    }


    .tab-area {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bacteria-tabs {
            // タブ
            display: flex;
            list-style-type: none;
            margin-bottom: 12px;

            li {
                background-color: #EFEFEF;
                text-align: center;
                line-height: 40px;
                font-size: 16px;
                position: relative;
                border-radius: 4px;
                color: #BCBCBC;
                margin-right: 8px;
                width: 140px;
                cursor: pointer;

                &.active {
                    background-color: #FFFAE6;
                    color: #333;
                }

                span {
                    position: absolute;
                    background-color: #00BFA5;
                    color: #fff;
                    text-align: center;
                    line-height: 24px;
                    width: 24px;
                    border-radius: 4px;
                    top: 50%;
                    left: 5px;
                    transform: translate(0, -50%);
                }
            }
        }

        button {
            margin: 0 0 0 auto;
        }
    }

    table {
        thead {
            tr {
                th:first-child {
                    width: 60px;
                }

                th:last-child {
                    width: 200px;
                }

                th.sort {
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }

                    span {
                        position: relative;

                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 10px;
                            height: 14px;
                            background-image: url(${sortCursor});
                            background-size: auto 16px;
                            background-repeat: no-repeat;
                            background-position: center center;
                            right: -20px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    &.desc span:after {
                        background-image: url(${sortDesc});
                    }

                    &.asc span:after {
                        background-image: url(${sortAsc});
                    }
                }
            }
        }
    }

`;