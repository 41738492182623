import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {H1, H3} from "../common/Headline";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {AxiosResponse} from "axios";
import {ErrorMessage} from "../common/ErrorMessage";
import {adminPpMstRcmndMethodApi} from "../../api/Api";
import {PpMstRcmndMethodControllerCreateRequest, TypesPpMstRcmndMethodData} from "../../generated";
import {usePartnerInsts} from "../../hooks/usePartnerInsts";
import {useMedicalInsts} from "../../hooks/useMedicalInsts";
import {useSkus} from "../../hooks/useSkus";
import {RcmndDisplayPattern} from "../../utility/Utility";

export const PpMstRcmndMethodEdit = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppMstRcmndMethod, setPpMstRcmndMethod] = useState<TypesPpMstRcmndMethodData | null>(null)
    const [skuCode, setSkuCode] = useState<string>("");
    const [rcmndDisplayPattern, setRcmndDisplayPattern] = useState<string>("");
    const [partnerInstId, setPartnerInstId] = useState<string>("");
    const [medicInstId, setMedicInstId] = useState<string>("");
    const [genericRcmndTitle, setGenericRcmndTitle] = useState<string>("");
    const [rcmndMethodLabel, setRcmndMethodLabel] = useState<string>("");
    const params = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const partnerInsts = usePartnerInsts();
    const medicalInsts = useMedicalInsts();
    const skus = useSkus(true);

    useEffect(() => {

        if (params.id) {
            // IDが指定されている場合はデータを取得する
            getData(params.id);
        }

    }, [params]);

    const getData = (id: string) => {

        setShowSpinner(true);

        adminPpMstRcmndMethodApi
            .v1AdminPpMstRcmndMethodGetIdGet(id)
            .then(({data}) => {
                setPpMstRcmndMethod(data.pp_mst_rcmnd_method);

                setSkuCode(data.pp_mst_rcmnd_method.sku_code);
                setRcmndDisplayPattern(data.pp_mst_rcmnd_method.rcmnd_display_pattern);
                setPartnerInstId(String(data.pp_mst_rcmnd_method.partner_inst_id));
                setMedicInstId(String(data.pp_mst_rcmnd_method.medic_inst_id));
                setGenericRcmndTitle(data.pp_mst_rcmnd_method.generic_rcmnd_title ?? "");
                setRcmndMethodLabel(data.pp_mst_rcmnd_method.rcmnd_method_label ?? "");

            })
            .catch((err) => {
                console.log(err);

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/pp_mst_rcmnd_method");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };


    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const req: PpMstRcmndMethodControllerCreateRequest = {
            sku_code: skuCode,
            partner_inst_id: Number(partnerInstId),
            medic_inst_id: Number(medicInstId),
            rcmnd_display_pattern: rcmndDisplayPattern,
            generic_rcmnd_title: genericRcmndTitle,
            rcmnd_method_label: rcmndMethodLabel,
        };

        setShowSpinner(true);

        let promise: Promise<void | AxiosResponse<any, any>>;

        if (ppMstRcmndMethod) {
            // 編集時
            promise = adminPpMstRcmndMethodApi
                .v1AdminPpMstRcmndMethodEditIdPost(String(ppMstRcmndMethod.id), req)
                .then(() => {
                    setErrors({});
                    navigate(`/pp_mst_rcmnd_method?message_type=edit_complete`);
                });

        } else {
            // 新規登録時
            promise = adminPpMstRcmndMethodApi
                .v1AdminPpMstRcmndMethodCreatePost(req)
                .then(() => {
                    setErrors({});
                    navigate(`/pp_mst_rcmnd_method?message_type=create_complete`);
                });
        }

        promise
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {

        switch (e.currentTarget.name) {
            case "sku_code":
                setSkuCode(e.currentTarget.value);
                break;
            case "partner_inst_id":
                setPartnerInstId(e.currentTarget.value);
                break;
            case "medic_inst_id":
                setMedicInstId(e.currentTarget.value);
                break;
            case "rcmnd_display_pattern":
                setRcmndDisplayPattern(e.currentTarget.value);
                break;
            case "generic_rcmnd_title":
                setGenericRcmndTitle(e.currentTarget.value);
                break;
            case "rcmnd_method_label":
                setRcmndMethodLabel(e.currentTarget.value);
                break;
        }
    };

    // 前の画面に戻る
    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(-1);
    };

    return <Layout title={"リコメンド方式管理"}>

        <H1 title={ppMstRcmndMethod ? "リコメンド方式の編集" : "リコメンド方式の新規登録"} subtitle="data entry"/>

        <div className="box">

            <H3 title="リコメンド方式" subtitle="data"/>

            <StyledSkuEdit onSubmit={onSubmit}>

                <div className="form-wrapper">

                    <div className="form-group">
                        <label className="req">SKUコード</label>
                        <div>
                            <select name="sku_code" value={skuCode} onChange={onChange} className={errors["sku_code"] ? "error" : ""}>
                                <option value="">---選択してください</option>
                                {skus.map((d, i) => {
                                    return <option value={d.sku_code} key={`sc-${i}`}>{d.sku_code}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["sku_code"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">病院</label>
                        <div>
                            <select name="medic_inst_id" value={medicInstId} onChange={onChange} className={errors["medic_inst_id"] ? "error" : ""}>
                                <option value="">---選択してください</option>
                                {medicalInsts.map((d, i) => {
                                    return <option value={d.id} key={`mi-${i}`}>{d.name}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["medic_inst_id"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label >提携法人</label>
                        <div>
                            <select name="partner_inst_id" value={partnerInstId} onChange={onChange} className={errors["partner_inst_id"] ? "error" : ""}>
                                <option value="">---選択してください</option>
                                {partnerInsts.map((d, i) => {
                                    return <option value={d.id} key={`pi-${i}`}>{d.name}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["partner_inst_id"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req pt3">リコメンド<br/>表示パターン</label>
                        <div>
                            <select name="rcmnd_display_pattern" value={rcmndDisplayPattern} onChange={onChange} className={errors["rcmnd_display_pattern"] ? "error" : ""}>
                                <option value="">---選択してください</option>
                                {RcmndDisplayPattern.map((d, i) => {
                                    return <option value={d.id} key={`rdp-${i}`}>{d.id}：{d.name}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["rcmnd_display_pattern"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>汎用リコメンドタイトル</label>
                        <div>
                            <input type="text" name="generic_rcmnd_title" maxLength={50} value={genericRcmndTitle} onChange={onChange} className={errors["generic_rcmnd_title"] ? "error" : ""}/>
                            <ErrorMessage message={errors["generic_rcmnd_title"]}/>
                        </div>
                    </div>


                    <div className="form-group">
                        <label>リコメンド方式ラベル</label>
                        <div>
                            <input type="text" name="rcmnd_method_label" value={rcmndMethodLabel} maxLength={50} onChange={onChange} className={errors["rcmnd_method_label"] ? "error" : ""}/>
                            <ErrorMessage message={errors["rcmnd_method_label"]}/>
                        </div>
                    </div>


                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type={"button"} onClick={onClickBack} icon={ButtonIcon.Arrow}>前の画面に戻る</Button>
                    <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
                </div>
            </StyledSkuEdit>

        </div>

    </Layout>;

};

const StyledSkuEdit = styled(StyledForm)`

    select, input {
        width: 400px !important;
    }

`;
