import {ErrorMessage} from "./ErrorMessage";
import React from "react";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "./Button";
import styled from "styled-components";

interface Props {
    currentEmail: string;
    email: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    errors: { [key: string]: string };
    disabled: boolean;
    toggleChangeable: () => void;
    isPasswordChange: boolean;
}

export const EditEmailInput = ({currentEmail, email, onChange, errors, disabled, toggleChangeable, isPasswordChange}: Props) => {

    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        toggleChangeable();
    }

    return <StyledEditEmailInput>
        <div className="form-group">
            <label className="req">現在の<br/>メールアドレス</label>
            <div>
                <div className="inline">
                    <input type="email" name="current_email" value={currentEmail} placeholder={"半角英数"} disabled={true}/>
                    <Button color={disabled ? ButtonColor.Green : ButtonColor.Gray} type="button" size={ButtonSize.Small} icon={ButtonIcon.Arrow} disabled={isPasswordChange} onClick={onClick}>
                        {disabled ? "メールアドレスを変更する" : "変更をキャンセル"}
                    </Button>
                </div>
            </div>
        </div>

        {!disabled && <div className="form-group">
            <label className="req">新しい<br/>メールアドレス</label>
            <div>
                <input type="email" name="email" value={email} onChange={onChange} placeholder={"半角英数"} disabled={disabled}/>

                <p className="note">
                    ※メールアドレス変更時は下記パスワード入力欄に現在のパスワードを入力してください。<br/>
                    ※なお、メールアドレス、パスワードを同時に変更することはできません。
                </p>

                <ErrorMessage message={errors["email"]}/>
            </div>

        </div>}

    </StyledEditEmailInput>

}

const StyledEditEmailInput = styled.div`
  .note {
    padding: 5px 0 0 0;
    font-size: 12px;
    color: #cc0000;
  }
`;