import React from 'react';
import {AppProvider} from "./contexts/AppContext";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Login} from "./components/auth/Login";
import {Home} from "./components/home/Home";
import {Spinner} from "./components/common/Spinner";
import {Message} from "./components/common/Message";
import {Forgot} from "./components/auth/Forgot";
import {ResetPassword} from "./components/auth/ResetPassword";
import {Logout} from "./components/auth/Logout";
import {PpAnnouncementAdminIndex} from "./components/pp_announcement_admin/PpAnnouncementAdminIndex";
import {PpAnnouncementAdminEdit} from "./components/pp_announcement_admin/PpAnnouncementAdminEdit";
import {PpMstUserAdminIndex} from "./components/pp_mst_user_admin/PpMstUserAdminIndex";
import {ScrollTop} from "./components/common/ScrollTop";
import {PpMstUserAdminEdit} from "./components/pp_mst_user_admin/PpMstUserAdminEdit";
import {PpMstPartnerInstEdit} from "./components/pp_mst_partner_inst/PpMstPartnerInstEdit";
import {PpMstPartnerInstIndex} from "./components/pp_mst_partner_inst/PpMstPartnerInstIndex";
import {PpMstUserAdminChangePassword} from "./components/pp_mst_user_admin/PpMstUserAdminChangePassword";
import {PpUserIndex} from "./components/pp_user/PpUserIndex";
import {PpUserCreate} from "./components/pp_user/PpUserCreate";
import {PpUserCreateComplete} from "./components/pp_user/PpUserCreateComplete";
import {PpUserDetail} from "./components/pp_user/PpUserDetail";
import {PpPetEdit} from "./components/pp_pet/PpPetEdit";
import {PpPetCreateComplete} from "./components/pp_pet/PpPetCreateComplete";
import {PpUserEdit} from "./components/pp_user/PpUserEdit";
import {PpMstUserMedicalIndex} from "./components/pp_mst_user_medical/PpMstUserMedicalIndex";
import {PpMstUserMedicalEdit} from "./components/pp_mst_user_medical/PpMstUserMedicalEdit";
import {PpMstUserPartnerIndex} from "./components/pp_mst_user_partner/PpMstUserPartnerIndex";
import {PpMstUserPartnerEdit} from "./components/pp_mst_user_partner/PpMstUserPartnerEdit";
import {PpAnnouncementUserIndex} from "./components/pp_announcement_user/PpAnnouncementUserIndex";
import {PpAnnouncementUserEdit} from "./components/pp_announcement_user/PpAnnouncementUserEdit";
import {PpMaintenanceIndex} from "./components/pp_maintenance/PpMaintenanceIndex";
import {SystemIndex} from "./components/system/SystemIndex";
import {PpTestKitIndex} from "./components/pp_test_kit/PpTestKitIndex";
import {PpTestKitDetail} from "./components/pp_test_kit/PpTestKitDetail";
import {PpTestKitApply} from "./components/pp_test_kit/PpTestKitApply";
import {PpTestKitStep1} from "./components/pp_test_kit/PpTestKitStep1";
import {PpTestKitStep2} from "./components/pp_test_kit/PpTestKitStep2";
import {PpTestKitConfirm} from "./components/pp_test_kit/PpTestKitConfirm";
import {PpTestKitComplete} from "./components/pp_test_kit/PpTestKitComplete";
import {PpTestStatusIndex} from "./components/pp_test_status/PpTestStatusIndex";
import {PpTestStatusDetail} from "./components/pp_test_status/PpTestStatusDetail";
import {PpTestStatusEdit} from "./components/pp_test_status/PpTestStatusEdit";
import {SampleIdIndex} from "./components/sample_id/SampleIdIndex";
import {PpTestStatusResult} from "./components/pp_test_status/PpTestStatusResult";
import {ReceptionIndex} from "./components/reception/ReceptionIndex";
import {ReceptionRegister} from "./components/reception/ReceptionRegister";
import {MetadataIndex} from "./components/metadata/MetadataIndex";
import {ForcedTestIndex} from "./components/forced_test/ForcedTestIndex";
import {ForcedTestRegister} from "./components/forced_test/ForcedTestRegister";
import {ResultIndex} from "./components/result/ResultIndex";
import {PpMstTestKitIndex} from "./components/pp_mst_test_kit/PpMstTestKitIndex";
import {DebugIndex} from "./components/debug/DebugIndex";
import {AgencyLogin} from "./components/agency_login/AgencyLogin";
import {LogsIndex} from "./components/logs/LogsIndex";
import {ReferenceIndex} from "./components/reference/ReferenceIndex";
import {RollbackIndex} from "./components/rollback/RollbackIndex";
import {PpMstSkuIndex} from "./components/pp_mst_sku/PpMstSkuIndex";
import {PpMstSkuEdit} from "./components/pp_mst_sku/PpMstSkuEdit";
import {PpMstRcmndProductIndex} from "./components/pp_mst_rcmnd_product/PpMstRcmndProductIndex";
import {PpMstRcmndProductEdit} from "./components/pp_mst_rcmnd_product/PpMstRcmndProductEdit";
import {ResetCompleteMessage} from "./components/common/ResetCompleteMessage";
import {PpTestStatusBacteria} from "./components/pp_test_status/PpTestStatusBacteria";
import {PpMstIdentificationCdIndex} from "./components/pp_mst_identification_cd/PpMstIdentificationCdIndex";
import {PpMstIdentificationCdEdit} from "./components/pp_mst_identification_cd/PpMstIdentificationCdEdit";
import {PpFaqIndex} from "./components/pp_faq/PpFaqIndex";
import {PpFaqEdit} from "./components/pp_faq/PpFaqEdit";
import {ReceptionInput} from "./components/reception/Receptioninput";
import {PpFaqSort} from "./components/pp_faq/PpFaqSort";
import {PpMstRcmndMethodIndex} from "./components/pp_mst_rcmnd_method/PpMstRcmndMethodIndex";
import {PpMstRcmndMethodEdit} from "./components/pp_mst_rcmnd_method/PpMstRcmndMethodEdit";
import {PpMstGenericRcmndGroupIndex} from "./components/pp_mst_generic_rcmnd_group/PpMstGenericRcmndGroupIndex";
import {PpMstGenericRcmndGroupEdit} from "./components/pp_mst_generic_rcmnd_group/PpMstGenericRcmndGroupEdit";
import {PpMstRcmndProductsTitleIndex} from "./components/pp_mst_rcmnd_products_title/PpMstRcmndProductsTitleIndex";
import {PpMstRcmndProductsTitleEdit} from "./components/pp_mst_rcmnd_products_title/PpMstRcmndProductsTitleEdit";
import {PpMstGenericRcmndImageIndex} from "./components/pp_mst_generic_rcmnd_image/PpMstGenericRcmndImageIndex";
import {PpMstGenericRcmndImageEdit} from "./components/pp_mst_generic_rcmnd_image/PpMstGenericRcmndImageEdit";
import {PpMstMeatureCommentsIndex} from "./components/pp_mst_meature_comments/PpMstMeatureCommentsIndex";
import {PpMstMeatureCommentsEdit} from "./components/pp_mst_meature_comments/PpMstMeatureCommentsEdit";
import {PpTestStatusBadBacteria} from "./components/pp_test_status/PpTestStatusBadBacteria";

export const App = () => {

    return <AppProvider>

        <Spinner/>
        <Message/>

        <BrowserRouter>

            <ScrollTop/>

            <ResetCompleteMessage/>

            <Routes>

                {/*認証不要*/}
                <Route path="/login" element={<Login/>}/>
                <Route path="/forgot" element={<Forgot/>}/>
                <Route path="/reset-password" element={<ResetPassword/>}/>

                {/*要認証*/}
                <Route path="/logout" element={<Logout/>}/>
                <Route path="/" element={<Home/>}/>

                {/*システム管理*/}
                <Route path="/system" element={<SystemIndex/>}/>

                {/*管理者用お知らせ管理*/}
                <Route path="/pp_announcement_admin" element={<PpAnnouncementAdminIndex/>}/>
                <Route path="/pp_announcement_admin/create" element={<PpAnnouncementAdminEdit/>}/>
                <Route path="/pp_announcement_admin/edit/:id" element={<PpAnnouncementAdminEdit/>}/>

                {/*一般ユーザー用お知らせ管理*/}
                <Route path="/pp_announcement_user" element={<PpAnnouncementUserIndex/>}/>
                <Route path="/pp_announcement_user/create" element={<PpAnnouncementUserEdit/>}/>
                <Route path="/pp_announcement_user/edit/:id" element={<PpAnnouncementUserEdit/>}/>

                {/*管理ユーザー*/}
                <Route path="/pp_mst_user_admin" element={<PpMstUserAdminIndex/>}/>
                <Route path="/pp_mst_user_admin/create" element={<PpMstUserAdminEdit/>}/>
                <Route path="/pp_mst_user_admin/edit/:id" element={<PpMstUserAdminEdit/>}/>
                <Route path="/change-password" element={<PpMstUserAdminChangePassword/>}/>

                {/*管理ユーザー*/}
                <Route path="/pp_mst_user_partner" element={<PpMstUserPartnerIndex/>}/>
                <Route path="/pp_mst_user_partner/create" element={<PpMstUserPartnerEdit/>}/>
                <Route path="/pp_mst_user_partner/edit/:id" element={<PpMstUserPartnerEdit/>}/>

                {/*メンテナンス管理*/}
                <Route path="/pp_maintenance" element={<PpMaintenanceIndex/>}/>

                {/*提携法人管理*/}
                <Route path="/pp_mst_partner_inst" element={<PpMstPartnerInstIndex/>}/>
                <Route path="/pp_mst_partner_inst/create" element={<PpMstPartnerInstEdit/>}/>
                <Route path="/pp_mst_partner_inst/edit/:id" element={<PpMstPartnerInstEdit/>}/>

                {/*病院管理*/}
                <Route path="/pp_mst_user_medical" element={<PpMstUserMedicalIndex/>}/>
                <Route path="/pp_mst_user_medical/create" element={<PpMstUserMedicalEdit/>}/>
                <Route path="/pp_mst_user_medical/edit/:id" element={<PpMstUserMedicalEdit/>}/>

                {/*会員管理*/}
                <Route path="/pp_user" element={<PpUserIndex/>}/>
                <Route path="/pp_user/create" element={<PpUserCreate/>}/>
                <Route path="/pp_user/create-complete/:id" element={<PpUserCreateComplete/>}/>
                <Route path="/pp_user/detail/:id" element={<PpUserDetail/>}/>
                <Route path="/pp_user/edit/:id" element={<PpUserEdit/>}/>

                {/*ペット管理*/}
                <Route path="/pp_pet/create/:ppUserId" element={<PpPetEdit/>}/>
                <Route path="/pp_pet/edit/:ppUserId/:id" element={<PpPetEdit/>}/>
                <Route path="/pp_pet/create-complete/:memberId/:petId" element={<PpPetCreateComplete/>}/>

                {/*検査申込管理*/}
                <Route path="/pp_test_kit" element={<PpTestKitIndex/>}/>
                <Route path="/pp_test_kit/detail/:memberId" element={<PpTestKitDetail/>}/>
                <Route path="/pp_test_kit/apply/:memberId/:petId" element={<PpTestKitApply/>}/>
                <Route path="/pp_test_kit/step1/:sampleId" element={<PpTestKitStep1/>}/>
                <Route path="/pp_test_kit/step2/:sampleId" element={<PpTestKitStep2/>}/>
                <Route path="/pp_test_kit/confirm/:sampleId" element={<PpTestKitConfirm/>}/>
                <Route path="/pp_test_kit/complete/:sampleId" element={<PpTestKitComplete/>}/>

                {/*検査状況照会*/}
                <Route path="/pp_test_status" element={<PpTestStatusIndex/>}/>
                <Route path="/pp_test_status/detail/:sampleId" element={<PpTestStatusDetail/>}/>
                <Route path="/pp_test_status/result/:sampleId" element={<PpTestStatusResult/>}/>
                <Route path="/pp_test_status/bacteria/:sampleId" element={<PpTestStatusBacteria/>}/>
                <Route path="/pp_test_status/edit/:sampleId" element={<PpTestStatusEdit/>}/>
                <Route path="/pp_test_status/bad-bacteria/:sampleId" element={<PpTestStatusBadBacteria/>}/>

                {/*検査ID情報管理*/}
                <Route path="/sample_id" element={<SampleIdIndex/>}/>

                {/*検査受付*/}
                <Route path="/reception" element={<ReceptionIndex/>}/>
                <Route path="/reception/new" element={<ReceptionInput/>}/>
                <Route path="/reception/add" element={<ReceptionInput/>}/>
                <Route path="/reception/register" element={<ReceptionRegister/>}/>

                {/*メタデータ出力*/}
                <Route path="/metadata" element={<MetadataIndex/>}/>

                {/*強制検査受付*/}
                <Route path="/forced_test" element={<ForcedTestIndex/>}/>
                <Route path="/forced_test/register" element={<ForcedTestRegister/>}/>

                {/*検査結果登録*/}
                <Route path="/result" element={<ResultIndex/>}/>

                {/*検体ID登録*/}
                <Route path="/pp_mst_test_kit" element={<PpMstTestKitIndex/>}/>

                {/*検体IDデバッグ*/}
                <Route path="/debug" element={<DebugIndex/>}/>

                {/*ログ表示*/}
                <Route path="/logs" element={<LogsIndex/>}/>

                {/*代行ログイン*/}
                <Route path="/agency_login/:sampleId" element={<AgencyLogin/>}/>

                {/*試験番号情報照会*/}
                <Route path="/reference" element={<ReferenceIndex/>}/>

                {/*検体ID切り戻し*/}
                <Route path="/rollback/:type" element={<RollbackIndex/>}/>

                {/*SKU管理*/}
                <Route path="/pp_mst_sku" element={<PpMstSkuIndex/>}/>
                <Route path="/pp_mst_sku/create" element={<PpMstSkuEdit/>}/>
                <Route path="/pp_mst_sku/edit/:id" element={<PpMstSkuEdit/>}/>

                {/*ペットフード管理*/}
                <Route path="/pp_mst_rcmnd_product" element={<PpMstRcmndProductIndex/>}/>
                <Route path="/pp_mst_rcmnd_product/create" element={<PpMstRcmndProductEdit/>}/>
                <Route path="/pp_mst_rcmnd_product/edit/:id" element={<PpMstRcmndProductEdit/>}/>

                {/*識別コード管理*/}
                <Route path="/pp_mst_identification_cd" element={<PpMstIdentificationCdIndex/>}/>
                <Route path="/pp_mst_identification_cd/create" element={<PpMstIdentificationCdEdit/>}/>
                <Route path="/pp_mst_identification_cd/edit/:id" element={<PpMstIdentificationCdEdit/>}/>

                {/*FAQ管理*/}
                <Route path="/pp_faq" element={<PpFaqIndex/>}/>
                <Route path="/pp_faq/create" element={<PpFaqEdit/>}/>
                <Route path="/pp_faq/edit/:id" element={<PpFaqEdit/>}/>
                <Route path="/pp_faq/sort" element={<PpFaqSort/>}/>

                {/*リコメンド方式管理*/}
                <Route path="/pp_mst_rcmnd_method" element={<PpMstRcmndMethodIndex/>}/>
                <Route path="/pp_mst_rcmnd_method/create" element={<PpMstRcmndMethodEdit/>}/>
                <Route path="/pp_mst_rcmnd_method/edit/:id" element={<PpMstRcmndMethodEdit/>}/>

                {/*汎用リコメンドグループ管理*/}
                <Route path="/pp_mst_generic_rcmnd_group" element={<PpMstGenericRcmndGroupIndex/>}/>
                <Route path="/pp_mst_generic_rcmnd_group/create" element={<PpMstGenericRcmndGroupEdit/>}/>
                <Route path="/pp_mst_generic_rcmnd_group/edit/:id" element={<PpMstGenericRcmndGroupEdit/>}/>

                {/*個別商品リコメンドタイトル管理*/}
                <Route path="/pp_mst_rcmnd_products_title" element={<PpMstRcmndProductsTitleIndex/>}/>
                <Route path="/pp_mst_rcmnd_products_title/create" element={<PpMstRcmndProductsTitleEdit/>}/>
                <Route path="/pp_mst_rcmnd_products_title/edit/:id" element={<PpMstRcmndProductsTitleEdit/>}/>

                {/*汎用リコメンドイメージ管理*/}
                <Route path="/pp_mst_generic_rcmnd_image" element={<PpMstGenericRcmndImageIndex/>}/>
                <Route path="/pp_mst_generic_rcmnd_image/create" element={<PpMstGenericRcmndImageEdit/>}/>
                <Route path="/pp_mst_generic_rcmnd_image/edit/:id" element={<PpMstGenericRcmndImageEdit/>}/>

                {/*対策コメント管理*/}
                <Route path="/pp_mst_meature_comments" element={<PpMstMeatureCommentsIndex/>}/>
                <Route path="/pp_mst_meature_comments/create" element={<PpMstMeatureCommentsEdit/>}/>
                <Route path="/pp_mst_meature_comments/edit/:measureCategory" element={<PpMstMeatureCommentsEdit/>}/>


            </Routes>

        </BrowserRouter>

    </AppProvider>
}
