import React from "react";
import styled from "styled-components";
import {PpMstBreedModelGetListResponse} from "../../generated";
import Select, {StylesConfig} from 'react-select';
import {FilterOptionOption} from "react-select/dist/declarations/src/filters";

interface Props {
    name: string;
    animalType: string;
    onChange: (name: string, n: number | null) => void;
    ppMstBreeds: PpMstBreedModelGetListResponse[];
    value: number | null;
}

export const BreedInput = ({name, animalType, onChange, ppMstBreeds, value}: Props) => {

    const options: any = [];

    const currentValue: any = {
        label: null,
        value: null,
    };

    if (ppMstBreeds.length === 0) {
        return null;
    }

    // 動物の種類でフィルター
    ppMstBreeds.forEach((d) => {

        if (d.id === value) {
            currentValue.label = String(d.breed_name);
            currentValue.value = d.id;
        }

        if (animalType === d.animal_type) {
            options.push({
                label: String(d.breed_name),
                value: d.id,
            });
        }
    });

    const onUpdate = (newValue: any) => {
        onChange(name, newValue ? newValue.value : null);
    };

    // 前方一致フィルター
    const customFilter = (option: FilterOptionOption<any>, inputValue: string): boolean => {
        // 大文字小文字は無視で前方一致とする
        return kanaToHira(option.label).toLowerCase().indexOf(inputValue.toLowerCase()) === 0;
    };

    // ひらがなに変換
    const kanaToHira = (str: string) => {
        return str.replace(/[\u30a1-\u30f6]/g, (match) => {
            const chr = match.charCodeAt(0) - 0x60;
            return String.fromCharCode(chr);
        });
    }

    const customStyles: StylesConfig = {
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 })
    }

    return <StyledBreedInput>
        {(value !== null && value !== 0) && <Select
            styles={customStyles}
            isClearable={true}
            onChange={onUpdate}
            defaultValue={currentValue}
            options={options}
            placeholder="-- 選択してください"/>}
        {!value && <Select
            styles={customStyles}
            isClearable={true}
            onChange={onUpdate}
            options={options}
            filterOption={customFilter}
            placeholder="-- 選択してください"
        />}

    </StyledBreedInput>

};

const StyledBreedInput = styled.div`
    width: 400px;

    input[type=text] {
        height: 30px !important;
    }
`;