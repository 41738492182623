import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {H2} from "../common/Headline";

export enum SearchErrorType {
    NoCondition = "no_condition",
    None = "none",
}


interface Props {
    onClear: () => void;
    errorType: SearchErrorType;
}

export const SearchForm = ({onClear, errorType}: Props) => {

    const [email, setEmail] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [lastNameKana, setLastNameKana] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [firstNameKana, setFirstNameKana] = useState<string>("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        const s = new URLSearchParams(location.search);

        setEmail(s.get("email") || "");
        setLastName(s.get("last_name") || "");
        setLastNameKana(s.get("last_name_kana") || "");
        setFirstName(s.get("first_name") || "");
        setFirstNameKana(s.get("first_name_kana") || "");

    }, []);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

        switch (e.currentTarget.name) {
            case "email":
                setEmail(e.currentTarget.value);
                break;
            case "last_name":
                setLastName(e.currentTarget.value);
                break;
            case "last_name_kana":
                setLastNameKana(e.currentTarget.value);
                break;
            case "first_name":
                setFirstName(e.currentTarget.value);
                break;
            case "first_name_kana":
                setFirstNameKana(e.currentTarget.value);
                break;
        }

    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        // URLを作成する
        const params = new URLSearchParams();
        params.append("email", email);
        params.append("last_name", lastName);
        params.append("first_name", firstName);
        params.append("last_name_kana", lastNameKana);
        params.append("first_name_kana", firstNameKana);
        params.append("page", "1");
        navigate(`/pp_user?${params.toString()}`);
    };

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        setEmail("");
        setLastName("");
        setFirstName("");
        setLastNameKana("");
        setFirstNameKana("");
        onClear();
        navigate(`/pp_user`);
    }

    const classNames: string[] = [];
    if(errorType !== SearchErrorType.None) {
        // 何かしらエラーがある場合
        classNames.push("error");
    }

    return <StyledMembersSearchForm noValidate={true} onSubmit={onSubmit} className={classNames.join(" ")}>

        <div className="box">

            <H2 title="オーナー情報の検索" subtitle="search"/>

            <div className="search-form">

                <div className="form-group">
                    <label>会員ID（メールアドレス）</label>
                    <div>
                        <input type="email" maxLength={255} name="email" placeholder={"例：sample@pitpet.jp"} value={email} onChange={onChange}/>

                        {errorType === SearchErrorType.NoCondition && <p className="search-error">検索内容を入力してください</p>}

                    </div>
                </div>

                <div className="form-group">
                    <label>飼い主の名前</label>
                    <div className="inline">
                        <span>姓</span>
                        <input type="text" name="last_name" maxLength={40} placeholder={"例：プラウメッド"} value={lastName} onChange={onChange} style={{width: "240px"}}/>
                        <span>名</span>
                        <input type="text" name="first_name" maxLength={40} placeholder={"例：太郎"} value={firstName} onChange={onChange} style={{width: "240px"}}/>
                    </div>
                </div>

                <div className="form-group">
                    <label>フリガナ</label>
                    <div className="inline">
                        <span>セイ</span>
                        <input type="text" name="last_name_kana" maxLength={60} placeholder={"例：プラウメッド"} value={lastNameKana} onChange={onChange} style={{width: "240px"}}/>
                        <span>メイ</span>
                        <input type="text" name="first_name_kana" maxLength={60} placeholder={"例：タロウ"} value={firstNameKana} onChange={onChange} style={{width: "240px"}}/>
                    </div>
                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                    <Button color={ButtonColor.Orange} type="submit" icon={ButtonIcon.Arrow}>検索</Button>
                </div>

            </div>

        </div>

    </StyledMembersSearchForm>
};

export const StyledMembersSearchForm = styled(StyledForm)`
  
  &.error {
    input[type=text], input[type=email], select {
      background-color: rgba(217, 62, 76, 0.2);
    }
  }

  // inlineフォームのspanの幅を固定する
  .form-group {
    .inline {
      span {
        width: 40px;
        text-align: right;
      }
    }

    &:first-child {
      div {
        padding-left: 50px;
      }
    }

    .search-error {
      color: #fff;
      background-color: #D93E4C;
      font-size: 14px;
      line-height: 47px;
      display: block;
      position: absolute;
      padding: 0 10px;
      border-radius: 6px;
      top: -2px;
      left: 370px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: -18px;
        top: 50%;
        transform: translateY(-50%);
        border: 10px solid transparent;
        border-right: 10px solid #D93E4C;
      }
    }
  }

`;