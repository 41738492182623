import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {AxiosResponse} from "axios";
import {ErrorMessage} from "../common/ErrorMessage";
import {PpMstUserMedicalControllerCreateRequest, TypesPpMstUserMedicalData} from "../../generated";
import {adminPpMstUserMedicalApi} from "../../api/Api";
import {AddressSet} from "../common/AddressSet";
import {ResultSendTargets} from "../../utility/Utility";

export const PpMstUserMedicalEdit = () => {

    const {setShowSpinner, setDangerMessage, setSuccessMessage} = useContext(AppContext);
    const [ppMstUserMedical, setPpMstUserMedical] = useState<TypesPpMstUserMedicalData | null>(null)
    const [medicInstName, setMedicInstName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [postalCode, setPostalCode] = useState<string>("");
    const [postalCode1, setPostalCode1] = useState<string>("");
    const [postalCode2, setPostalCode2] = useState<string>("");
    const [prefCode, setPrefCode] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [street, setStreet] = useState<string>("");
    const [building, setBuilding] = useState<string>("");
    const [telNo, setTelNo] = useState<string>("");
    const [resultSendTarget, setResultSendTarget] = useState<string>("");
    const params = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {

        if (params.id) {
            // IDが指定されている場合は、企業を取得する
            getUserMedial(params.id);
        }

    }, [params]);

    const getUserMedial = (id: string) => {

        setShowSpinner(true);

        adminPpMstUserMedicalApi.v1AdminPpMstUserMedicalGetIdGet(id)
            .then(({data}) => {
                setPpMstUserMedical(data.pp_mst_user_medical ?? null);

                if (data.pp_mst_user_medical) {
                    setMedicInstName(data.pp_mst_user_medical.medic_inst_name ?? "");
                    setEmail(data.pp_mst_user_medical.email ?? "");
                    setPostalCode(data.pp_mst_user_medical.postal_code ?? "");
                    setPrefCode(data.pp_mst_user_medical.pref_code ?? "");
                    setCity(data.pp_mst_user_medical.city ?? "");
                    setStreet(data.pp_mst_user_medical.street ?? "");
                    setBuilding(data.pp_mst_user_medical.building ?? "");
                    setTelNo(data.pp_mst_user_medical.tel_no ?? "");
                    setResultSendTarget(data.pp_mst_user_medical.result_send_target ?? "");

                    if (data.pp_mst_user_medical.postal_code) {
                        const parts = data.pp_mst_user_medical.postal_code.split("-");
                        if (parts.length === 2) {
                            setPostalCode1(parts[0]);
                            setPostalCode2(parts[1]);
                        }
                    }
                }

            })
            .catch((err) => {
                console.log(err);

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/topics");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const req: PpMstUserMedicalControllerCreateRequest = {
            medic_inst_name: medicInstName,
            email: email,
            postal_code: postalCode,
            pref_code: prefCode,
            city: city,
            street: street,
            building: building,
            tel_no: telNo,
            result_send_target: resultSendTarget,
        };

        if (postalCode === "-") {
            req.postal_code = "";
        }

        setShowSpinner(true);

        let promise: Promise<void | AxiosResponse<any, any>>;

        if (ppMstUserMedical) {
            // 編集時
            promise = adminPpMstUserMedicalApi.v1AdminPpMstUserMedicalEditIdPost(String(ppMstUserMedical.id), req)
                .then(() => {
                    setSuccessMessage("病院の編集が完了しました。");
                    setErrors({});
                    navigate(-1);
                });

        } else {
            // 新規登録時
            promise = adminPpMstUserMedicalApi.v1AdminPpMstUserMedicalCreatePost(req)
                .then(() => {
                    setSuccessMessage("病院の新規追加が完了しました。");
                    setErrors({});
                    navigate(-1);
                });
        }

        promise
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {

        switch (e.currentTarget.name) {
            case "medic_inst_name":
                setMedicInstName(e.currentTarget.value);
                break;
            case "email":
                setEmail(e.currentTarget.value);
                break;
            case "postal_code1":
                setPostalCode1(e.currentTarget.value);
                setPostalCode(`${e.currentTarget.value}-${postalCode2}`);
                break;
            case "postal_code2":
                setPostalCode2(e.currentTarget.value);
                setPostalCode(`${postalCode1}-${e.currentTarget.value}`);
                break;
            case "pref_code":
                setPrefCode(e.currentTarget.value);
                break;
            case "city":
                setCity(e.currentTarget.value);
                break;
            case "street":
                setStreet(e.currentTarget.value);
                break;
            case "building":
                setBuilding(e.currentTarget.value);
                break;
            case "tel_no":
                setTelNo(e.currentTarget.value);
                break;
            case "result_send_target":
                setResultSendTarget(e.currentTarget.value);
                break;
        }
    };

    // 前の画面に戻る
    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(-1);
    };

    const resultSendTargetKeys = Object.keys(ResultSendTargets);

    return <Layout title={"病院管理"}>

        <div className="box">

            <H3 title={"病院の設定"}/>

            <StyledCompanyEdit onSubmit={onSubmit}>

                <div className="form-wrapper">

                    <div className="form-group">
                        <label className="req">病院名</label>
                        <div>
                            <input type="text" name="medic_inst_name" value={medicInstName} className="medium" onChange={onChange} placeholder={"病院名"}/>
                            <ErrorMessage message={errors["medic_inst_name"]}/>
                        </div>
                    </div>


                    <div className="form-group">
                        <label className="req">メールアドレス</label>
                        <div>
                            <input type="text" name="email" value={email} className="medium" onChange={onChange} placeholder={"半角英数"}/>
                            <ErrorMessage message={errors["email"]}/>
                        </div>
                    </div>


                    <AddressSet
                        postalCode={postalCode}
                        postalCode1={postalCode1}
                        postalCode2={postalCode2}
                        prefCode={prefCode}
                        city={city}
                        street={street}
                        building={building}
                        onChange={onChange}
                        isRequired={false}
                        errors={errors}
                        setPrefCode={setPrefCode}
                        setCity={setCity}
                        setStreet={setStreet}
                        setBuilding={setBuilding}/>

                    <div className="form-group">
                        <label>電話番号</label>
                        <div>
                            <input type="tel" name="tel_no" value={telNo} onChange={onChange} placeholder={"半角数字"}/>
                            <div className="hint">※半角数字。ハイフンの入力は不要。</div>
                            <ErrorMessage message={errors["tel_no"]}/>
                        </div>
                    </div>

                    <div className="form-group center">
                        <label className="req">検査結果メール<br/>送信対象</label>
                        <div>
                            <select name="result_send_target" value={resultSendTarget} onChange={onChange}>
                                <option value="">選択してください</option>
                                {resultSendTargetKeys.map((d, i) => {
                                    return <option key={`p-${i}`} value={d}>{ResultSendTargets[d]}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["result_send_target"]}/>
                        </div>
                    </div>
                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type={"button"} onClick={onClickBack} icon={ButtonIcon.Arrow}>前の画面に戻る</Button>
                    <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
                </div>

            </StyledCompanyEdit>

        </div>

    </Layout>

};

const StyledCompanyEdit = styled(StyledForm)`

`;
