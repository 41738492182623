import {Layout} from "../common/Layout";
import {SearchForm} from "./SearchForm";
import {H3} from "../common/Headline";
import {Table} from "../common/Table";
import {adminPpMstPartnerInstApi, adminPpMstUserMedicalApi, adminPpTestKitApi} from "../../api/Api";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {PpMstPartnerInstModelGetListResponse, PpMstUserMedicalModelGetListResponse, PpTestKitControllerEachData, PpTestKitControllerFromTo, PpTestKitControllerSetSampleIDRequest, PptestkitRecommendType, TypesPpTestKitSampleData} from "../../generated";
import {GetRecommendTypes, GetTestDivision, GetTestStatus, Zerofill} from "../../utility/Utility";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Modal, ModalColor} from "./Modal";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {InstInput} from "./partial/InstInput";
import {useSkus} from "../../hooks/useSkus";

enum ModalType {
    None = "none",
    NotSelected = "not_selected",
    SetConfirm = "confirm",
    SetCompleted = "complete",
}

export const SampleIdIndex = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppTestKits, setPpTestKits] = useState<TypesPpTestKitSampleData[]>([]);
    const [partnerInsts, setPartnerInsts] = useState<PpMstPartnerInstModelGetListResponse[]>([]);
    const [medicInsts, setMedicInsts] = useState<PpMstUserMedicalModelGetListResponse[]>([]);
    const [sendData, setSendData] = useState<{ [key: string]: PpTestKitControllerEachData }>({});
    const [modalType, setModalType] = useState<ModalType | null>(null);
    const sessionKey = "specimen_index";

    const skus = useSkus(false);

    useEffect(() => {

        getPartnerInsts();
        getMedicInsts();

        const old = sessionStorage.getItem(sessionKey);

        if (old) {
            const fromTos: PpTestKitControllerFromTo[] = JSON.parse(old);
            onSearch(fromTos, false);
        }
    }, []);

    const getPartnerInsts = () => {

        setShowSpinner(true);

        adminPpMstPartnerInstApi.v1AdminPpMstPartnerInstListGet()
            .then(({data}) => {
                setPartnerInsts(data.data);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            })
    };

    const getMedicInsts = () => {
        setShowSpinner(true);

        adminPpMstUserMedicalApi.v1AdminPpMstUserMedicalListGet()
            .then(({data}) => {
                setMedicInsts(data.data);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            })
    };

    // 検索処理実行
    const onSearch = (fromTos: PpTestKitControllerFromTo[], isFile: boolean) => {

        setShowSpinner(true);

        // ページ更新時に消えないよう、セッションに保存
        sessionStorage.setItem(sessionKey, JSON.stringify(fromTos));

        setPpTestKits([]);
        setSendData({});

        adminPpTestKitApi.v1AdminPpTestKitSamplesPost({sample_ids: fromTos})
            .then(({data}) => {
                setPpTestKits(data.pp_test_kits);

                const sendData: { [key: string]: PpTestKitControllerEachData } = {}

                let isFileErr = false;

                data.pp_test_kits.forEach((d) => {
                    if (d.status !== null) {

                        let medicInstId = d.medic_inst_id;
                        let partnerInstId = d.partner_inst_id;
                        let skuCode = d.sku_code;
                        let recommendType = d.recommend_type;

                        for (let i = 0; i < fromTos.length; i++) {

                            if (fromTos[i].from === d.from && fromTos[i].to === d.to) {
                                // エクセルで病院ID、提携法人ID、SKU、レコメンド要否区分が設定されていた場合、その値を初期値とする
                                if (fromTos[i].medic_inst_id) {
                                    medicInstId = Number(fromTos[i].medic_inst_id);
                                }
                                if (fromTos[i].partner_inst_id) {
                                    partnerInstId = Number(fromTos[i].partner_inst_id);
                                }
                                if (fromTos[i].sku_code) {
                                    skuCode = fromTos[i].sku_code ?? "";
                                }
                                if (fromTos[i].recommend_type) {
                                    recommendType = (fromTos[i].recommend_type ?? "") as PptestkitRecommendType;
                                }
                            }
                        }

                        // ファイル取り込み時、出荷済みだったらエラーとする
                        if (isFile && d.medic_inst_id !== null) {

                            console.log(d.from, "は出荷済み");
                            isFileErr = true;
                        }

                        sendData[d.from] = {
                            from: d.from,
                            to: d.to,
                            medic_inst_id: medicInstId,
                            partner_inst_id: partnerInstId,
                            sku_code: skuCode,
                            recommend_type: recommendType,
                        };
                    }
                });

                setSendData(sendData);

                if (isFileErr) {
                    // ファイルエラー時
                    setDangerMessage("既に出荷処理済みの検体が含まれています。出荷先を変更する場合は、検体IDごとに個別に行ってください。");
                    setPpTestKits([]);
                    setSendData({});
                    sessionStorage.removeItem(sessionKey);
                }

            })
            .catch((err) => {

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onClear = () => {
        setPpTestKits([]);
        setSendData({});
        sessionStorage.removeItem(sessionKey);
    };

    // 病院、提携法人の変更ハンドラー
    const onChangeSendData = (type: string, fromSampleId: string, n: number | null) => {

        const next = {...sendData};

        switch (type) {
            case "medic_inst_id":
                next[fromSampleId].medic_inst_id = n;
                break;
            case "partner_inst_id":
                next[fromSampleId].partner_inst_id = n;
                break;
        }

        setSendData(next);
    }
    // SKU、レコメンド要否の変更ハンドラー
    const onChange = (e: React.ChangeEvent<HTMLSelectElement>, fromSampleId: string) => {

        const next = {...sendData};

        switch (e.currentTarget.name) {
            case "sku_code":
                next[fromSampleId].sku_code = e.currentTarget.value;
                break;
            case "recommend_type":
                next[fromSampleId].recommend_type = e.currentTarget.value;
                break;
        }

        setSendData(next);
    }

    // 設定するボタン押下時
    const onSet = (e: React.MouseEvent<HTMLButtonElement>): void => {

        e.preventDefault();

        const keys = Object.keys(sendData);

        // 対象が1件でもあるかチェック
        if (keys.length === 0) {
            setModalType(ModalType.None);
            return;
        }

        // 対象の病院がすべて選択されているかチェック
        let isNoSelected = false;
        keys.forEach((key) => {
            if (!sendData[key].medic_inst_id) {
                isNoSelected = true;
                return;
            }
        });

        if (isNoSelected) {
            // 全て選択されていない場合エラーとする
            setModalType(ModalType.NotSelected);
            return;
        }

        // 確認モーダルを表示する
        setModalType(ModalType.SetConfirm);
    };

    // 設定実行処理
    const onComplete = (e: React.MouseEvent<HTMLButtonElement>): void => {

        e.preventDefault();

        setShowSpinner(true);

        const data: PpTestKitControllerEachData[] = [];

        Object.keys(sendData).forEach((key) => {
            data.push(sendData[key]);
        });

        const req: PpTestKitControllerSetSampleIDRequest = {
            data: data,
        };

        adminPpTestKitApi.v1AdminPpTestKitSetSamplesPost(req)
            .then(() => {
                setModalType(ModalType.SetCompleted);

                // 完了後、リストを再読み込みする
                const old = sessionStorage.getItem(sessionKey);
                if (old) {
                    const fromTos: PpTestKitControllerFromTo[] = JSON.parse(old);
                    onSearch(fromTos, false);
                }

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setModalType(null);
    };

    // 登録内容確認モーダルの表示
    const getConfirmModal = (): JSX.Element => {

        const num = getStatNums();

        return <Modal color={ModalColor.Success}>
            <h4>検体IDの情報登録</h4>
            <h5>以下の内容でよろしいですか？</h5>
            <ul>
                <li><label>登録検体数</label><span className="num">{num.sampleNum}</span><span className="postfix">検体</span></li>
                <li><label>レコード数</label><span className="num">{num.recordNum}</span><span className="postfix">件</span></li>
            </ul>
            <div className="btn-area">
                <Button type="button" color={ButtonColor.Gray} icon={ButtonIcon.Arrow} onClick={onModalClose}>いいえ</Button>
                <Button type="button" color={ButtonColor.Orange} icon={ButtonIcon.Arrow} onClick={onComplete}>はい</Button>
            </div>
        </Modal>
    };

    // 統計数値を返す
    const getStatNums = () => {

        let sampleNum = 0;
        let recordNum = 0;

        ppTestKits.forEach((ppTestKit) => {
            if (ppTestKit.status !== null) {
                sampleNum += ppTestKit.num;
                recordNum++;
            }
        });

        return {
            "sampleNum": sampleNum,
            "recordNum": recordNum,
        };
    }

    // 登録完了モーダルの表示
    const getCompleteModal = (): JSX.Element => {

        const num = getStatNums();

        return <Modal color={ModalColor.Success}>
            <h4>検体IDの情報登録</h4>
            <h5>登録が完了しました。</h5>
            <ul>
                <li><label>登録検体数</label><span className="num">{num.sampleNum}</span><span className="postfix">検体</span></li>
            </ul>
            <div className="btn-area">
                <Button type="button" color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={onModalClose}>情報管理トップに戻る</Button>
            </div>
        </Modal>
    };

    const recommendTypes = GetRecommendTypes();
    const recommendTypeKeys = Object.keys(recommendTypes);

    const statuses = GetTestStatus();

    return <Layout title="検体ID情報管理">

        <SearchForm onSearch={onSearch} onClear={onClear} onSet={onSet}/>

        <StyledSpecimenIndex>

            <div className="box">

                <H3 title="検体情報一覧" subtitle="list"/>

                <Table>
                    <thead>
                    <tr>
                        <th>No.</th>
                        <th>検体ID（from）</th>
                        <th>検体ID（to）</th>
                        <th>ステータス</th>
                        <th>病院</th>
                        <th>提携法人</th>
                        <th>SKU</th>
                        <th>検査区分</th>
                        <th>レコメンド<br/>要否</th>
                    </tr>
                    </thead>

                    <tbody>

                    {ppTestKits.map((d, i) => {

                        if (d.status === null) {
                            // 検体未登録
                            return (<tr className="no-sample" key={`row-${i}`}>
                                <td>{Zerofill(i + 1, 2)}</td>
                                <td>{d.from}</td>
                                <td>{d.to}</td>
                                <td/>
                                <td>検体未登録</td>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                            </tr>);
                        }

                        // ステータス設定
                        let statusStr = "";
                        if (d.status) {
                            statusStr = statuses.get(d.status) ?? "";
                        }

                        // 未出荷はステータスを空欄にする
                        if (!d.medic_inst_id) {
                            statusStr = "";
                        }

                        return (<tr key={`row-${i}`}>
                            <td>{Zerofill(i + 1, 2)}</td>
                            <td>{d.from}</td>
                            <td>{d.to}</td>
                            <td>{statusStr}</td>
                            <td>
                                <InstInput
                                    name="medic_inst_id"
                                    value={sendData[d.from].medic_inst_id ?? 0}
                                    sampleId={d.from}
                                    listData={medicInsts}
                                    onChange={onChangeSendData}
                                    changePlaceholderColor={true}
                                />
                            </td>
                            <td>
                                <InstInput
                                    name="partner_inst_id"
                                    value={sendData[d.from].partner_inst_id ?? 0}
                                    sampleId={d.from}
                                    listData={partnerInsts}
                                    onChange={onChangeSendData}
                                    changePlaceholderColor={false}
                                />
                            </td>
                            <td>
                                <select name="sku_code" value={sendData[d.from].sku_code} onChange={(e) => onChange(e, d.from)}>
                                    <option value="">---</option>
                                    {skus.map((d, i) => {
                                        return <option key={`sku-${i}`} value={d.sku_code}>{d.sku_code}</option>
                                    })}
                                </select>
                            </td>
                            <td>{d.testDivision}：{GetTestDivision()[d.testDivision ?? ""]}</td>
                            <td>
                                <select name="recommend_type" value={sendData[d.from].recommend_type} onChange={(e) => onChange(e, d.from)}>
                                    <option value="">---</option>
                                    {recommendTypeKeys.map((key, i) => {
                                        return <option key={`rt-${i}`} value={key}>{key}</option>
                                    })}
                                </select>
                            </td>
                        </tr>);

                    })}

                    </tbody>

                </Table>

            </div>

        </StyledSpecimenIndex>

        {modalType === ModalType.None && <Modal color={ModalColor.Danger}>
            <h4>検体ID指定エラー</h4>
            <h5>検体情報がありません。</h5>
            <p>検体IDで指定、もしくはファイルで取り込んで<br/>対象の検体情報を抽出してから実行してください。</p>
            <div className="btn-area">
                <Button type="button" color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={onModalClose}>元の画面に戻る</Button>
            </div>
        </Modal>}

        {modalType === ModalType.NotSelected && <Modal color={ModalColor.Danger}>
            <h4>検体IDの設定エラー</h4>
            <h5>「病院」が未設定の検体IDがあります。</h5>
            <p>検体IDの<strong>病院</strong>を確認してから、<br/>設定するをクリックしてください。</p>
            <div className="btn-area">
                <Button type="button" color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={onModalClose}>元の画面に戻る</Button>
            </div>
        </Modal>}

        {/*内容確認*/}
        {modalType === ModalType.SetConfirm && getConfirmModal()}

        {/*内容確認*/}
        {modalType === ModalType.SetCompleted && getCompleteModal()}


    </Layout>

};

const StyledSpecimenIndex = styled(StyledForm)`

    th {
        white-space: nowrap;
    }

    // 検体未登録

    .no-sample {
        td {
            color: #D93E4C;
        }
    }

`;