import {TypesPpTestKitData} from "../../../generated";
import styled from "styled-components";
import React, {useEffect} from "react";
import {ErrorMessage} from "../../common/ErrorMessage";
import {GetHypertension} from "../../../utility/Utility";

interface Props {
    errors: { [key: string]: string };
    onUpdate: (next: TypesPpTestKitData) => void;
    ppTestKit: TypesPpTestKitData;
    disabled: boolean;
}

export const Disease = ({errors, onUpdate, ppTestKit, disabled}: Props) => {

    useEffect(() => {
        if (disabled) {
            // 無効な場合はクリアする
            const next: any = ppTestKit;
            // 皮膚疾患
            next.is_skin_disease_pyoderma = "0";
            next.is_skin_disease_seforrhoea = "0";
            next.is_skin_disease_external_otitisis = "0";
            next.is_skin_disease_eczema = "0";
            next.is_skin_disease_other = "0";
            next.is_skin_disease_comment = "";

            // 心臓疾患
            next.is_heart_disease_cardiomyopathy = "0";
            next.is_heart_disease_ph = "0";
            next.is_heart_disease_mr = "0";
            next.is_heart_disease_heart_failure = "0";
            next.is_heart_disease_other = "0";
            next.is_heart_disease_comment = "";

            // 腎臓疾患
            next.is_kidney_disease_ckd = "0";
            next.is_kidney_disease_pln = "0";
            next.is_kidney_disease_nephritis = "0";
            next.is_kidney_disease_kidney_stone = "0";
            next.is_kidney_disease_other = "0";
            next.is_kidney_disease_comment = "";

            // 泌尿器疾患
            next.is_urology_disease_idiopathic_cystitis = "0";
            next.is_urology_disease_other_cystitis = "0";
            next.is_urology_disease_bladder_stone = "0";
            next.is_urology_disease_other = "0";
            next.is_urology_disease_comment = "";

            // 整形疾患
            next.is_orthopedic_disease_patella = "0";
            next.is_orthopedic_disease_hd = "0";
            next.is_orthopedic_disease_arthritis = "0";
            next.is_orthopedic_disease_other = "0";
            next.is_orthopedic_disease_comment = "";

            // 脳疾患/脊髄疾患
            next.is_brain_spinal_disease_epilepsy = "0";
            next.is_brain_spinal_disease_herniated_disc = "0";
            next.is_brain_spinal_disease_brain_disease = "0";
            next.is_brain_spinal_disease_other = "0";
            next.is_brain_spinal_disease_comment = "";

            // 呼吸器疾患
            next.is_respiratory_disease_tracheal_collapse = "0";
            next.is_respiratory_disease_bronchitis = "0";
            next.is_respiratory_disease_asthma = "0";
            next.is_respiratory_disease_rhinitis = "0";
            next.is_respiratory_disease_other = "0";
            next.is_respiratory_disease_comment = "";

            // アレルギー
            next.is_allergy_food_skin = "0";
            next.is_allergy_food_digestive_organ = "0";
            next.is_allergy_other = "0";
            next.is_allergy_comment = "";

            // 消化器疾患
            next.is_digestive_disease_ibd = "0";
            next.is_digestive_disease_pancreatitis = "0";
            next.is_digestive_disease_pei = "0";
            next.is_digestive_disease_other = "0";
            next.is_digestive_disease_comment = "";

            // 肝・胆道疾患
            next.is_liver_bile_disease_liver_damage = "0";
            next.is_liver_bile_disease_biliary_sludge = "0";
            next.is_liver_bile_disease_gallbladder_mucocele = "0";
            next.is_liver_bile_disease_other = "0";
            next.is_liver_bile_disease_comment = "";

            // 高血圧
            next.is_hypertension = "";

            // ホルモン・代謝異常
            next.is_hormone_metabolism_diabetes = "0";
            next.is_hormone_metabolism_cushing_syndrome = "0";
            next.is_hormone_metabolism_dl = "0";
            next.is_hormone_metabolism_other = "0";
            next.is_hormone_metabolic_disorder_comment = "";

            // 眼科疾患
            next.is_eye_disease_cataract = "0";
            next.is_eye_disease_glaucoma = "0";
            next.is_eye_disease_dry_eyes = "0";
            next.is_eye_disease_other = "0";
            next.is_eye_disease_comment = "";

            // 腫瘍
            next.is_tumor_comment = "";

            // その他の疾患
            next.is_other_comment = "";

            onUpdate(next);
        }

    }, [disabled]);

    const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const next: any = ppTestKit;
        next[e.currentTarget.name] = e.currentTarget.checked ? "1" : "0";
        onUpdate(next);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const next: any = ppTestKit;
        next[e.currentTarget.name] = e.currentTarget.value;
        onUpdate(next);
    };

    const hypertension = GetHypertension();

    return <StyledDisease className="form-wrapper">

        <div className="form-group">
            <label>皮膚疾患</label>
            <div>
                <div className="row">
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_skin_disease_pyoderma" checked={ppTestKit.is_skin_disease_pyoderma === "1"} onChange={onCheckChange} disabled={disabled}/>
                            膿皮症
                        </label>
                        <label>
                            <input type="checkbox" name="is_skin_disease_seforrhoea" checked={ppTestKit.is_skin_disease_seforrhoea === "1"} onChange={onCheckChange} disabled={disabled}/>
                            脂漏症
                        </label>
                        <label>
                            <input type="checkbox" name="is_skin_disease_external_otitisis" checked={ppTestKit.is_skin_disease_external_otitisis === "1"} onChange={onCheckChange} disabled={disabled}/>
                            外耳炎
                        </label>
                        <label>
                            <input type="checkbox" name="is_skin_disease_eczema" checked={ppTestKit.is_skin_disease_eczema === "1"} onChange={onCheckChange} disabled={disabled}/>
                            皮膚炎（原因が判明していない）
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="inline">
                        <div className="check">
                            <label>
                                <input type="checkbox" name="is_skin_disease_other" checked={ppTestKit.is_skin_disease_other === "1"} onChange={onCheckChange} disabled={disabled}/>
                                その他：
                            </label>
                        </div>
                        <input type="text" maxLength={100} name="is_skin_disease_comment" value={ppTestKit.is_skin_disease_comment ?? ""} placeholder="疾患を記入してください" onChange={onChange} disabled={disabled}/>
                    </div>

                    <ErrorMessage message={errors["is_skin_disease_comment"]}/>

                </div>
            </div>
        </div>

        <div className="form-group">
            <label>心臓疾患</label>
            <div>
                <div className="row">
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_heart_disease_cardiomyopathy" checked={ppTestKit.is_heart_disease_cardiomyopathy === "1"} onChange={onCheckChange} disabled={disabled}/>
                            心筋症
                        </label>
                        <label>
                            <input type="checkbox" name="is_heart_disease_ph" checked={ppTestKit.is_heart_disease_ph === "1"} onChange={onCheckChange} disabled={disabled}/>
                            肺高血圧症
                        </label>
                        <label>
                            <input type="checkbox" name="is_heart_disease_mr" checked={ppTestKit.is_heart_disease_mr === "1"} onChange={onCheckChange} disabled={disabled}/>
                            僧帽弁閉鎖不全症
                        </label>
                        <label>
                            <input type="checkbox" name="is_heart_disease_heart_failure" checked={ppTestKit.is_heart_disease_heart_failure === "1"} onChange={onCheckChange} disabled={disabled}/>
                            心不全(原因が判明してない)
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="inline">
                        <div className="check">
                            <label>
                                <input type="checkbox" name="is_heart_disease_other" checked={ppTestKit.is_heart_disease_other === "1"} onChange={onCheckChange} disabled={disabled}/>
                                その他：
                            </label>
                        </div>
                        <input type="text" maxLength={100} name="is_heart_disease_comment" value={ppTestKit.is_heart_disease_comment ?? ""} placeholder="疾患を記入してください" onChange={onChange} disabled={disabled}/>
                    </div>
                    <ErrorMessage message={errors["is_heart_disease_comment"]}/>

                </div>
            </div>
        </div>

        <div className="form-group">
            <label>腎臓疾患</label>
            <div>
                <div className="row">
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_kidney_disease_ckd" checked={ppTestKit.is_kidney_disease_ckd === "1"} onChange={onCheckChange} disabled={disabled}/>
                            慢性腎不全
                        </label>
                        <label>
                            <input type="checkbox" name="is_kidney_disease_pln" checked={ppTestKit.is_kidney_disease_pln === "1"} onChange={onCheckChange} disabled={disabled}/>
                            蛋白漏出性腎症
                        </label>
                        <label>
                            <input type="checkbox" name="is_kidney_disease_nephritis" checked={ppTestKit.is_kidney_disease_nephritis === "1"} onChange={onCheckChange} disabled={disabled}/>
                            腎炎
                        </label>
                        <label>
                            <input type="checkbox" name="is_kidney_disease_kidney_stone" checked={ppTestKit.is_kidney_disease_kidney_stone === "1"} onChange={onCheckChange} disabled={disabled}/>
                            腎結石
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="inline">
                        <div className="check">
                            <label>
                                <input type="checkbox" name="is_kidney_disease_other" checked={ppTestKit.is_kidney_disease_other === "1"} onChange={onCheckChange} disabled={disabled}/>
                                その他：
                            </label>
                        </div>
                        <input type="text" maxLength={100} name="is_kidney_disease_comment" value={ppTestKit.is_kidney_disease_comment ?? ""} placeholder="疾患を記入してください" onChange={onChange} disabled={disabled}/>
                    </div>

                    <ErrorMessage message={errors["is_kidney_disease_comment"]}/>

                </div>

            </div>
        </div>

        <div className="form-group">
            <label>泌尿器疾患</label>
            <div>

                <div className="row">
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_urology_disease_idiopathic_cystitis" checked={ppTestKit.is_urology_disease_idiopathic_cystitis === "1"} onChange={onCheckChange} disabled={disabled}/>
                            特発性膀胱炎
                        </label>
                        <label>
                            <input type="checkbox" name="is_urology_disease_other_cystitis" checked={ppTestKit.is_urology_disease_other_cystitis === "1"} onChange={onCheckChange} disabled={disabled}/>
                            細菌性/その他膀胱炎
                        </label>
                        <label>
                            <input type="checkbox" name="is_urology_disease_bladder_stone" checked={ppTestKit.is_urology_disease_bladder_stone === "1"} onChange={onCheckChange} disabled={disabled}/>
                            膀胱結石
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="inline">
                        <div className="check">
                            <label>
                                <input type="checkbox" name="is_urology_disease_other" checked={ppTestKit.is_urology_disease_other === "1"} onChange={onCheckChange} disabled={disabled}/>
                                その他：
                            </label>
                        </div>
                        <input type="text" maxLength={100} name="is_urology_disease_comment" value={ppTestKit.is_urology_disease_comment ?? ""} placeholder="疾患を記入してください" onChange={onChange} disabled={disabled}/>
                    </div>
                </div>
                <ErrorMessage message={errors["is_urology_disease_comment"]}/>

            </div>
        </div>

        <div className="form-group">
            <label>整形疾患</label>
            <div>

                <div className="row">
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_orthopedic_disease_patella" checked={ppTestKit.is_orthopedic_disease_patella === "1"} onChange={onCheckChange} disabled={disabled}/>
                            膝蓋骨脱臼
                        </label>
                        <label>
                            <input type="checkbox" name="is_orthopedic_disease_hd" checked={ppTestKit.is_orthopedic_disease_hd === "1"} onChange={onCheckChange} disabled={disabled}/>
                            股関節異形成
                        </label>
                        <label>
                            <input type="checkbox" name="is_orthopedic_disease_arthritis" checked={ppTestKit.is_orthopedic_disease_arthritis === "1"} onChange={onCheckChange} disabled={disabled}/>
                            関節炎
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="inline">
                        <div className="check">
                            <label>
                                <input type="checkbox" name="is_orthopedic_disease_other" checked={ppTestKit.is_orthopedic_disease_other === "1"} onChange={onCheckChange} disabled={disabled}/>
                                その他：
                            </label>
                        </div>
                        <input type="text" maxLength={100} name="is_orthopedic_disease_comment" value={ppTestKit.is_orthopedic_disease_comment ?? ""} placeholder="疾患を記入してください" onChange={onChange} disabled={disabled}/>
                    </div>

                    <ErrorMessage message={errors["is_orthopedic_disease_comment"]}/>

                </div>

            </div>
        </div>

        <div className="form-group">
            <label>脳疾患/脊髄疾患</label>
            <div>

                <div className="row">
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_brain_spinal_disease_epilepsy" checked={ppTestKit.is_brain_spinal_disease_epilepsy === "1"} onChange={onCheckChange} disabled={disabled}/>
                            てんかん
                        </label>
                        <label>
                            <input type="checkbox" name="is_brain_spinal_disease_herniated_disc" checked={ppTestKit.is_brain_spinal_disease_herniated_disc === "1"} onChange={onCheckChange} disabled={disabled}/>
                            椎間板ヘルニア
                        </label>
                        <label>
                            <input type="checkbox" name="is_brain_spinal_disease_brain_disease" checked={ppTestKit.is_brain_spinal_disease_brain_disease === "1"} onChange={onCheckChange} disabled={disabled}/>
                            脳疾患(原因が判明してない)
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="inline">
                        <div className="check">
                            <label>
                                <input type="checkbox" name="is_brain_spinal_disease_other" checked={ppTestKit.is_brain_spinal_disease_other === "1"} onChange={onCheckChange} disabled={disabled}/>
                                その他：
                            </label>
                        </div>
                        <input type="text" maxLength={100} name="is_brain_spinal_disease_comment" value={ppTestKit.is_brain_spinal_disease_comment ?? ""} placeholder="疾患を記入してください" onChange={onChange} disabled={disabled}/>
                    </div>
                    <ErrorMessage message={errors["is_brain_spinal_disease_comment"]}/>

                </div>

            </div>
        </div>

        <div className="form-group">
            <label>呼吸器疾患</label>
            <div>

                <div className="row">
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_respiratory_disease_tracheal_collapse" checked={ppTestKit.is_respiratory_disease_tracheal_collapse === "1"} onChange={onCheckChange} disabled={disabled}/>
                            気管虚脱
                        </label>
                        <label>
                            <input type="checkbox" name="is_respiratory_disease_bronchitis" checked={ppTestKit.is_respiratory_disease_bronchitis === "1"} onChange={onCheckChange} disabled={disabled}/>
                            気管支炎
                        </label>
                        <label>
                            <input type="checkbox" name="is_respiratory_disease_asthma" checked={ppTestKit.is_respiratory_disease_asthma === "1"} onChange={onCheckChange} disabled={disabled}/>
                            喘息
                        </label>
                        <label>
                            <input type="checkbox" name="is_respiratory_disease_rhinitis" checked={ppTestKit.is_respiratory_disease_rhinitis === "1"} onChange={onCheckChange} disabled={disabled}/>
                            鼻炎
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="inline">
                        <div className="check">
                            <label>
                                <input type="checkbox" name="is_respiratory_disease_other" checked={ppTestKit.is_respiratory_disease_other === "1"} onChange={onCheckChange} disabled={disabled}/>
                                その他：
                            </label>
                        </div>
                        <input type="text" maxLength={100} name="is_respiratory_disease_comment" value={ppTestKit.is_respiratory_disease_comment ?? ""} placeholder="疾患を記入してください" onChange={onChange} disabled={disabled}/>
                    </div>

                    <ErrorMessage message={errors["is_respiratory_disease_comment"]}/>

                </div>

            </div>
        </div>

        <div className="form-group">
            <label>アレルギー</label>
            <div>

                <div className="row">
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_allergy_food_skin" checked={ppTestKit.is_allergy_food_skin === "1"} onChange={onCheckChange} disabled={disabled}/>
                            食物アレルギー(皮膚)
                        </label>
                        <label>
                            <input type="checkbox" name="is_allergy_food_digestive_organ" checked={ppTestKit.is_allergy_food_digestive_organ === "1"} onChange={onCheckChange} disabled={disabled}/>
                            食物アレルギー(消化器)
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="inline">
                        <div className="check">
                            <label>
                                <input type="checkbox" name="is_allergy_other" checked={ppTestKit.is_allergy_other === "1"} onChange={onCheckChange} disabled={disabled}/>
                                その他：
                            </label>
                        </div>
                        <input type="text" maxLength={100} name="is_allergy_comment" value={ppTestKit.is_allergy_comment ?? ""} placeholder="疾患を記入してください" onChange={onChange} disabled={disabled}/>
                    </div>

                    <ErrorMessage message={errors["is_allergy_comment"]}/>

                </div>

            </div>
        </div>

        <div className="form-group">
            <label>消化器疾患</label>
            <div>

                <div className="row">
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_digestive_disease_ibd" checked={ppTestKit.is_digestive_disease_ibd === "1"} onChange={onCheckChange} disabled={disabled}/>
                            慢性腸症/炎症性腸疾患
                        </label>
                        <label>
                            <input type="checkbox" name="is_digestive_disease_pancreatitis" checked={ppTestKit.is_digestive_disease_pancreatitis === "1"} onChange={onCheckChange} disabled={disabled}/>
                            膵炎
                        </label>
                        <label>
                            <input type="checkbox" name="is_digestive_disease_pei" checked={ppTestKit.is_digestive_disease_pei === "1"} onChange={onCheckChange} disabled={disabled}/>
                            膵外分泌不全
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="inline">
                        <div className="check">
                            <label>
                                <input type="checkbox" name="is_digestive_disease_other" checked={ppTestKit.is_digestive_disease_other === "1"} onChange={onCheckChange} disabled={disabled}/>
                                その他：
                            </label>
                        </div>
                        <input type="text" maxLength={100} name="is_digestive_disease_comment" value={ppTestKit.is_digestive_disease_comment ?? ""} placeholder="疾患を記入してください" onChange={onChange} disabled={disabled}/>
                    </div>

                    <ErrorMessage message={errors["is_digestive_disease_comment"]}/>

                </div>

            </div>
        </div>

        <div className="form-group">
            <label>肝・胆道疾患</label>
            <div>

                <div className="row">
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_liver_bile_disease_liver_damage" checked={ppTestKit.is_liver_bile_disease_liver_damage === "1"} onChange={onCheckChange} disabled={disabled}/>
                            肝障害
                        </label>
                        <label>
                            <input type="checkbox" name="is_liver_bile_disease_biliary_sludge" checked={ppTestKit.is_liver_bile_disease_biliary_sludge === "1"} onChange={onCheckChange} disabled={disabled}/>
                            胆泥症
                        </label>
                        <label>
                            <input type="checkbox" name="is_liver_bile_disease_gallbladder_mucocele" checked={ppTestKit.is_liver_bile_disease_gallbladder_mucocele === "1"} onChange={onCheckChange} disabled={disabled}/>
                            胆嚢粘液嚢腫
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="inline">
                        <div className="check">
                            <label>
                                <input type="checkbox" name="is_liver_bile_disease_other" checked={ppTestKit.is_liver_bile_disease_other === "1"} onChange={onCheckChange} disabled={disabled}/>
                                その他：
                            </label>
                        </div>
                        <input type="text" maxLength={100} name="is_liver_bile_disease_comment" value={ppTestKit.is_liver_bile_disease_comment ?? ""} placeholder="疾患を記入してください" onChange={onChange} disabled={disabled}/>
                    </div>

                    <ErrorMessage message={errors["is_liver_bile_disease_comment"]}/>

                </div>

            </div>
        </div>

        <div className="form-group">
            <label>高血圧</label>
            <div>

                <select name="is_hypertension" value={ppTestKit.is_hypertension} onChange={onChange} disabled={disabled}>
                    {Object.keys(hypertension).map((key) => {
                        return <option value={key} key={`is_hypertension-${key}`}>{hypertension[key]}</option>
                    })}
                </select>

            </div>
        </div>

        <div className="form-group">
            <label>ホルモン・代謝異常</label>
            <div>

                <div className="row">
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_hormone_metabolism_diabetes" checked={ppTestKit.is_hormone_metabolism_diabetes === "1"} onChange={onCheckChange} disabled={disabled}/>
                            糖尿病
                        </label>
                        <label>
                            <input type="checkbox" name="is_hormone_metabolism_cushing_syndrome" checked={ppTestKit.is_hormone_metabolism_cushing_syndrome === "1"} onChange={onCheckChange} disabled={disabled}/>
                            クッシング症候群
                        </label>
                        <label>
                            <input type="checkbox" name="is_hormone_metabolism_dl" checked={ppTestKit.is_hormone_metabolism_dl === "1"} onChange={onCheckChange} disabled={disabled}/>
                            高脂血症
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="inline">
                        <div className="check">
                            <label>
                                <input type="checkbox" name="is_hormone_metabolism_other" checked={ppTestKit.is_hormone_metabolism_other === "1"} onChange={onCheckChange} disabled={disabled}/>
                                その他：
                            </label>
                        </div>
                        <input type="text" maxLength={100} name="is_hormone_metabolic_disorder_comment" value={ppTestKit.is_hormone_metabolic_disorder_comment ?? ""} placeholder="疾患を記入してください" onChange={onChange} disabled={disabled}/>
                    </div>

                    <ErrorMessage message={errors["is_hormone_metabolic_disorder_comment"]}/>

                </div>

            </div>
        </div>

        <div className="form-group">
            <label>眼科疾患</label>
            <div>

                <div className="row">
                    <div className="check">
                        <label>
                            <input type="checkbox" name="is_eye_disease_cataract" checked={ppTestKit.is_eye_disease_cataract === "1"} onChange={onCheckChange} disabled={disabled}/>
                            白内障
                        </label>
                        <label>
                            <input type="checkbox" name="is_eye_disease_glaucoma" checked={ppTestKit.is_eye_disease_glaucoma === "1"} onChange={onCheckChange} disabled={disabled}/>
                            緑内障
                        </label>
                        <label>
                            <input type="checkbox" name="is_eye_disease_dry_eyes" checked={ppTestKit.is_eye_disease_dry_eyes === "1"} onChange={onCheckChange} disabled={disabled}/>
                            乾性核結膜炎(ドライアイ)
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="inline">
                        <div className="check">
                            <label>
                                <input type="checkbox" name="is_eye_disease_other" checked={ppTestKit.is_eye_disease_other === "1"} onChange={onCheckChange} disabled={disabled}/>
                                その他：
                            </label>
                        </div>
                        <input type="text" maxLength={100} name="is_eye_disease_comment" value={ppTestKit.is_eye_disease_comment ?? ""} placeholder="疾患を記入してください" onChange={onChange} disabled={disabled}/>
                    </div>

                    <ErrorMessage message={errors["is_eye_disease_comment"]}/>

                </div>

            </div>
        </div>

        <div className="form-group">
            <label>腫瘍</label>
            <div>
                <input type="text" maxLength={100} name="is_tumor_comment" value={ppTestKit.is_tumor_comment ?? ""} placeholder="疾患を記入してください" onChange={onChange} disabled={disabled}/>
                <ErrorMessage message={errors["is_tumor_comment"]}/>
            </div>
        </div>

        <div className="form-group">
            <label>その他の疾患</label>
            <div>
                <input type="text" maxLength={100} name="is_other_comment" value={ppTestKit.is_other_comment ?? ""} placeholder="疾患を記入してください" onChange={onChange} disabled={disabled}/>
                <ErrorMessage message={errors["is_other_comment"]}/>
            </div>
        </div>

    </StyledDisease>


};

const StyledDisease = styled.div`
  margin-top: 20px;

`;