import {Button, ButtonColor, ButtonIcon, ButtonSize} from "../../common/Button";
import {ErrorMessage} from "../../common/ErrorMessage";
import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../../contexts/AppContext";
import {assetsApi} from "../../../api/Api";
import styled from "styled-components";

interface Props {
    column: string;
    title: string;
    error: string | undefined;
    onSetImage: (column: string, filename: string) => void;
    showHint: boolean;
    required: boolean;
    showModal: (title: string, viewPath: string) => void;
    oldFilename: string | null;
    oldViewPath: string | null;
    disabled?: boolean;
}

export const ImageForm = ({column, title, error, onSetImage, showHint, required, showModal, oldFilename, oldViewPath, disabled}: Props) => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [filename, setFilename] = useState<string>("");
    const [viewPath, setViewPath] = useState<string>("");

    useEffect(() => {
        if (oldFilename) {
            setFilename(oldFilename);
        }
        if (oldViewPath) {
            setViewPath(oldViewPath);
        }
    }, [oldFilename, oldViewPath]);

    const onFileSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {

        const files = e.currentTarget.files;

        if (!files || files.length === 0) {
            setDangerMessage("ファイルが未選択です。");
            return;
        }

        const file = files.item(0);

        if (!file) {
            return;
        }

        const allowedMimeTypes = [
            "image/jpeg",
            "image/gif",
            "image/png",
        ];

        if (allowedMimeTypes.indexOf(file.type) === -1) {
            setDangerMessage(`ファイル形式が不正です。 ${file.type} はアップロードできません。`);
            return;
        }

        setShowSpinner(true);

        assetsApi.v1UploadImagePost(file)
            .then(({data}) => {
                console.log(data);

                onSetImage(column, data.path);
                setFilename(file.name);
                setViewPath(data.view_path);

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });

    };

    const onDeleteImg = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setFilename("");
        setViewPath("");
        onSetImage(column, "");
    }

    return <StyledImageForm className="form-group center">
        <label className={required ? "req" : ""}>{title}</label>
        <div>
            <div className="flex">
                <Button
                    type="button"
                    color={disabled ? ButtonColor.Gray : ButtonColor.Green}
                    size={ButtonSize.Small}
                    icon={ButtonIcon.Arrow}
                    onClick={onFileSelect}
                    disabled={disabled}
                >
                    画像をアップロード
                </Button>
                <input type="file" ref={fileInputRef} onChange={onUpload}/>

                {filename && <div className="filename">
                    <span onClick={() => showModal(title, viewPath)}>{filename}（クリックして確認）</span>
                    <div className="btn-delete" onClick={onDeleteImg}>&times; 画像を削除</div>
                </div>}

            </div>

            {showHint && <div className="hint">
                * アップロード可能な画像形式：jpg、png、gif<br/>
                * アップロードする画像は5MB以下でお願いいたします。<br/>
            </div>}

            <ErrorMessage message={error}/>
        </div>

    </StyledImageForm>

}


const StyledImageForm = styled.div`

    .flex {
        display: flex;
        align-items: center;

        button {
            margin-right: 20px;
        }
    }

    input[type=file] {
        display: none;
    }
`;