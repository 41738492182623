import {useLocation} from "react-router-dom";
import {useContext, useEffect} from "react";
import {AppContext} from "../../contexts/AppContext";

export const ResetCompleteMessage = () => {

    const {setCompleteMessage, completeMessage} = useContext(AppContext);
    const location = useLocation();

    // ページ遷移時に完了メッセージを削除
    useEffect(() => {

        if (completeMessage) {
            setCompleteMessage(null);
        }

    }, [location.pathname, location.search]);


    return null;
}
