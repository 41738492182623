import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import {Counter} from "../common/Counter";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {adminPpMstGenericRcmndImageApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";
import {AccesstokenRole, PpMstGenericRcmndImageControllerIndexRequest, TypesPpMstGenericRcmndImageData} from "../../generated";
import {Table} from "../common/Table";
import {Paginator} from "../common/Paginator";
import styled from "styled-components";
import {MainColor} from "../common/Colors";
import rightWhite from "../../images/right-white.svg";
import {CompleteMessage} from "../common/CompleteMessage";
import {SearchForm} from "./SearchForm";

export const PpMstGenericRcmndImageIndex = () => {

    const {setShowSpinner, setDangerMessage, setCompleteMessage, role} = useContext(AppContext);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [ppMstGenericRcmndImages, setPpMstGenericRcmndImages] = useState<TypesPpMstGenericRcmndImageData[]>([]);
    const navigate = useNavigate();
    const location = useLocation();

    const size = 200;

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const messageType = params.get("message_type") ?? "";

        if (messageType === "create_complete") {
            setCompleteMessage(`リコメンド表示パターンの新規追加が完了いたしました。`);
        }

        if (messageType === "edit_complete") {
            setCompleteMessage(`リコメンド表示パターンの編集が完了いたしました`);
        }

    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get("page")) {
            setPage(Number(params.get("page")));
        }

        getData();
    }, [location.search]);

    const getData = () => {

        setShowSpinner(true);

        const params = new URLSearchParams(location.search);

        const animalType = params.get("animal_type") ?? "";
        const intestinalEnvironmentType = params.get("intestinal_environment_type") ?? "";
        const title = params.get("title") ?? "";
        const imageOverview = params.get("image_overview") ?? "";

        const req: PpMstGenericRcmndImageControllerIndexRequest = {
            page: page,
            size: size,
            animal_type: animalType,
            intestinal_environment_type: intestinalEnvironmentType,
            title: title,
            image_overview: imageOverview,
        };

        adminPpMstGenericRcmndImageApi
            .v1AdminPpMstGenericRcmndImagePost(req)
            .then(({data}) => {
                setPpMstGenericRcmndImages(data.pp_mst_generic_rcmnd_images);
                setTotal(data.total ?? 0);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onDelete = (e: React.MouseEvent<HTMLSpanElement>, ppMstGenericRcmndImage: TypesPpMstGenericRcmndImageData) => {

        e.preventDefault();

        if (!window.confirm(`${ppMstGenericRcmndImage.image_overview} を削除します。よろしいですか？`)) {
            return;
        }

        setShowSpinner(true);

        adminPpMstGenericRcmndImageApi
            .v1AdminPpMstGenericRcmndImageDeleteIdDelete(String(ppMstGenericRcmndImage.id))
            .then(() => {
                setCompleteMessage(`汎用リコメンドイメージ：${ppMstGenericRcmndImage.image_overview}を削除いたしました。`);
                getData();
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onPageChange = (page: number) => {
        setPage(page);
        navigate(`/pp_mst_generic_rcmnd_image?page=${page}`);
    }

    return <Layout title="汎用リコメンドイメージ管理">

        <SearchForm/>

        <CompleteMessage/>

        <StyledPpMstSkuIndex className="box">

            <H3 title="リコメンドイメージ一覧" subtitle="list" counter={<Counter total={total} size={size} current={page}/>}/>

            <Table>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>汎用リコメンドグループID</th>
                    <th>動物種類</th>
                    <th>腸内環境タイプ</th>
                    <th>表示順位</th>
                    <th>イメージ概要</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>

                <tbody>

                {ppMstGenericRcmndImages.map((d, i) => {

                    return <tr key={`sku-${i}`}>
                        <td>{d.id}</td>
                        <td>{d.pp_mst_generic_rcmnd_group ? `${d.pp_mst_generic_rcmnd_group.id}：${d.pp_mst_generic_rcmnd_group.generic_rcmnd_group_title}` : "-"}</td>
                        <td>{d.animal_type === "1" ? "犬" : "猫"}</td>
                        <td>{d.intestinal_environment_type}</td>
                        <td>{d.display_order}</td>
                        <td>{d.image_overview}</td>
                        {role === AccesstokenRole.RoleMaster && <>
                            <td><NavLink to={`/pp_mst_generic_rcmnd_image/edit/${d.id}`} className="btn-link">編集</NavLink></td>
                            <td><span onClick={(e) => onDelete(e, d)} className="btn-del">削除</span></td>
                        </>}
                    </tr>
                })}

                </tbody>

            </Table>

            <Paginator onClick={onPageChange} pages={Math.ceil(total / size)} page={page}/>

        </StyledPpMstSkuIndex>

    </Layout>
}


const StyledPpMstSkuIndex = styled.div`

    tr {
        td:nth-child(7), td:nth-child(8) {
            text-align: center !important;
            width: 120px;
        }
    }

    .btn-link, .btn-del {
        background-color: ${MainColor};
        color: #fff;
        font-size: 12px !important;
        line-height: 24px;
        display: inline-block;
        text-decoration: none !important;
        padding: 0 30px 0 20px;
        letter-spacing: 1px;
        border-radius: 24px;
        background-image: url(${rightWhite});
        background-repeat: no-repeat;
        background-position: top 50% right 15px;
        background-size: 5px auto;
    }

    .btn-del {
        background-color: #ccc;
    }

`;
