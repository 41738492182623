import styled from "styled-components";
import React from "react";

interface Props {
    currentOffset: number;
}

const w = 440;
const h = 210;

export const PlateImage = ({currentOffset}: Props) => {

    const alphabets = "ABCDEFGH";

    // 縦9列、横13列の表を描画する
    const rows = alphabets.length + 1;
    const cols = 13;

    const stageWidth = w;
    const stageHeight = h;

    const elements: React.ReactNode[] = [];
    const lines: React.ReactNode[] = [];

    const cellW = stageWidth / cols;
    const cellH = stageHeight / rows;

    for (let a = 0; a < rows; a++) {

        for (let b = 0; b < cols; b++) {

            // 背景色策定
            let bgColor = "#fff";
            if (a === 0 || b === 0) {
                // ラベルセル
                bgColor = "#CCCCCC";
            } else if (a >= 1) {
                // 2行目以降のデータセル
                bgColor = a % 2 === 0 ? "#fff" : "#F4F9F8";
            }

            const offset = ((b - 1) * alphabets.length) + a;

            // ラベル策定
            let t = "";
            if (a === 0 && b !== 0) {
                // 1行目列のラベル
                t = String(b);
            } else if (a >= 1 && b === 0) {
                // 2行目以降の行ラベル
                t = alphabets.substring(a - 1, a);
            } else if (a >= 1 && b >= 1) {
                // 2行目以降のデータセル
                t = String(offset);
            }

            // 現在埋まっているセルを目立たせる
            if (a !== 0 && b !== 0 && currentOffset >= offset) {
                bgColor = "#FBEBED";
            }

            // 95、96は予約済みなので、色を変える
            if (offset === 95 || offset === 96) {
                bgColor = "#FDE5BC";
            }

            // セル座標設定
            const x = b * cellW;
            const y = a * cellH;

            elements.push(<React.Fragment key={`c-${a}-${b}`}>
                <g transform={`translate(${x}, ${y})`}>
                    <rect fill={bgColor} width={cellW} height={cellH}/>
                    <text fill="#333" dominantBaseline="central" textAnchor="middle" x={cellW / 2} y={cellH / 2}>{t}</text>
                </g>
            </React.Fragment>);

            if (a === 0 && b !== 0) {
                // 縦線
                lines.push(<line key={`vl-${a}-${b}`} x1={cellW * b} y1={0} x2={cellW * b} y2={stageHeight} strokeWidth={1} stroke="#ccc"/>)
            }
        }

        if (a !== 0) {
            // 横線
            lines.push(<line key={`hl-${a}`} x1={0} y1={a * cellH} x2={stageWidth} y2={a * cellH} strokeWidth={1} stroke="#ccc"/>)
        }
    }

    // 反転させた枠線を別途描画
    for (let a = 0; a < rows; a++) {
        for (let b = 0; b < cols; b++) {
            if (a === 0 && b !== 0) {
                // 縦
                lines.push(<line key={`wvl-${a}-${b}`} x1={cellW * b} y1={0} x2={cellW * b} y2={cellH} strokeWidth={1} stroke="#fff"/>)
            }
        }
        if (a !== 0) {
            // 横
            lines.push(<line key={`whl-${a}`} x1={0} y1={cellH * a} x2={cellW} y2={cellH * a} strokeWidth={1} stroke="#fff"/>)
        }
    }

    return <StyledPlateImage>

        <svg viewBox={`0 0 ${stageWidth} ${stageHeight}`}>

            <g>
                {elements}
            </g>

            <g className="lines">

                {lines}
            </g>

        </svg>

    </StyledPlateImage>

}


const StyledPlateImage = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 4px;
  font-size: 0;

  svg {
    width: ${w}px;
    height: ${h}px;
    border-radius: 4px;

    text {
      font-size: 14px;
    }
  }

`;