import {adminPpMstPartnerInstApi} from "../../api/Api";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {PpMstPartnerInstModelGetListResponse} from "../../generated";
import Select, {StylesConfig} from "react-select";
import styled from "styled-components";
import {FilterOptionOption} from "react-select/dist/declarations/src/filters";

interface Props {
    name: string;
    onChange: (name: string, n: number | null) => void;
    value: number | null;
}

export const PartnerInstInput = ({name, onChange, value}: Props) => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [partnerInsts, setPartnerInsts] = useState<PpMstPartnerInstModelGetListResponse[]>([]);

    useEffect(() => {
        getPartnerInsts();
    }, []);

    const getPartnerInsts = () => {

        setShowSpinner(true);

        adminPpMstPartnerInstApi.v1AdminPpMstPartnerInstListGet()
            .then(({data}) => {
                setPartnerInsts(data.data);

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            })
    };

    const onUpdate = (newValue: any) => {
        onChange(name, newValue ? newValue.value : null);
    };

    // 前方一致フィルター
    const customFilter = (option: FilterOptionOption<any>, inputValue: string): boolean => {
        // 大文字小文字は無視で前方一致とする
        return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) === 0;
    };

    let currentValue = {
        label: "",
        value: 0,
    };

    const options: any = partnerInsts.map((partnerInst) => {

        if (value === partnerInst.id) {
            currentValue = {
                label: partnerInst.name ?? "",
                value: partnerInst.id,
            };
        }

        return {
            label: partnerInst.name,
            value: partnerInst.id,
        };
    })

    const customStyles: StylesConfig = {
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 })
    }

    return <StyledInput>
        {(value !== null && value !== 0) && <Select
            styles={customStyles}
            isClearable={true}
            onChange={onUpdate}
            value={currentValue}
            defaultValue={currentValue}
            options={options}
            placeholder="-- 選択してください"
        />}
        {!value && <Select
            styles={customStyles}
            isClearable={true}
            onChange={onUpdate}
            options={options}
            filterOption={customFilter}
            placeholder="-- 選択してください"
        />}
    </StyledInput>
};
const StyledInput = styled.div`
  width: 400px;

  input[type=text] {
    height: 30px !important;
  }

  * {
    font-size: 14px !important;
  }
`;