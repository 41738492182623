import {Layout} from "../common/Layout";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../contexts/AppContext";
import {adminPpUserApi} from "../../api/Api";
import {TypesPpPetData, TypesPpUserData} from "../../generated";
import {H1, H3, H4} from "../common/Headline";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {PartsPpUserDetail} from "../pp_user/PartsPpUserDetail";
import {PartsPetDetail} from "../pp_pet/PartsPetDetail";

export const PpTestKitDetail = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();
    const [ppUser, setPpUser] = useState<TypesPpUserData | null>(null);

    useEffect(() => {

        if (params.memberId) {
            getPpUser(params.memberId);
        }
    }, []);

    const getPpUser = (memberId: string) => {

        setShowSpinner(true);

        adminPpUserApi.v1AdminPpUserGetIdGet(memberId)
            .then(({data}) => {
                setPpUser(data.pp_user ?? null);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
                navigate("/pp_test_kit");
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    if (!ppUser) {
        // 会員が取得できるまではダミー表示
        return <Layout title={"検査申込管理"}/>;
    }

    // 申し込み画面に遷移
    const onClickApply = (e: React.MouseEvent<HTMLButtonElement>, ppPet: TypesPpPetData): void => {
        e.preventDefault();
        navigate(`/pp_test_kit/apply/${ppPet.pp_user_id}/${ppPet.id}`);
    };

    return <Layout title={"検査申込管理"}>

        <H1 title="ペットの選択" subtitle="select"/>

        <StyledPpTestKitDetail>

            <div className="box">

                <H3 title="オーナー情報" subtitle="owner"/>

                <PartsPpUserDetail ppUser={ppUser}/>

            </div>

            <H4 title={"どのペットの検査を申し込みますか？"}/>

            <div className="box mb40">

                <H3 title="登録中のペット情報" subtitle="pet"/>

                {ppUser.pp_pets?.map((ppPet, i) => {

                    return <div className="pet-select" key={`pet-${i}`}>

                        <div className="left">
                            <PartsPetDetail ppPet={ppPet}/>
                        </div>

                        <div className="right">
                            <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow} onClick={(e) => onClickApply(e, ppPet)}>検査を申し込む</Button>
                        </div>

                    </div>

                })}

            </div>

        </StyledPpTestKitDetail>

    </Layout>

}

const StyledPpTestKitDetail = styled(StyledForm)`

  .pet-select {
    border: 1px solid #ccc;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      flex: 1;
      border-right: 1px solid #ccc;
    }

    .right {
      width: 320px;
      text-align: center;
    }

  }

`;
