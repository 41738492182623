/* tslint:disable */
/* eslint-disable */
/**
 * PiTPET API
 * PiTPET APIに関する仕様書 管理画面、フロントサイド、それぞれで使用するAPIです。<br> 管理画面は、/v1/admin/ から始まります。<br> フロントサイドは、/v1/front から始まります。<br> それ以外のルーティングは認証がかけられていない公開状態のAPIです。<br> 認証が必要なAPIではリクエストヘッダーに `Authorization: Bearer [ログイン時に発行されたトークン]` を指定してください。<br> なお、Basic認証がかけられているSTG環境では`Authorization`ヘッダーが重複してしまい正常に認証ができません。<br> そのため、STG環境では`X-API-KEY: [ログイン時に発行されたトークン]`ヘッダーを指定してください。<br> 2023/11/09追記、PITPET_SYS_2023-176 の自動ログアウト仕様追加により、管理画面はCookie認証に変更しています。<br>Cookieはログイン時に発行されているので、そのまま送れば認証されます。<br> なお、各認証を経由したAPIにおいては、`Sephirahモジュール`の共通の認証結果、`sephirahAuthCertificate`プロパティが追加で返却されます。<br> `sephirahAuthCertificate`のプロパティに関しては、admin_authのログイン認証のレスポンスを参照してください。<br>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ConfigurationParameters {
    apiKey?: string | Promise<string> | ((name: string) => string) | ((name: string) => Promise<string>);
    username?: string;
    password?: string;
    accessToken?: string | Promise<string> | ((name?: string, scopes?: string[]) => string) | ((name?: string, scopes?: string[]) => Promise<string>);
    basePath?: string;
    baseOptions?: any;
    formDataCtor?: new () => any;
}

export class Configuration {
    /**
     * parameter for apiKey security
     * @param name security name
     * @memberof Configuration
     */
    apiKey?: string | Promise<string> | ((name: string) => string) | ((name: string) => Promise<string>);
    /**
     * parameter for basic security
     *
     * @type {string}
     * @memberof Configuration
     */
    username?: string;
    /**
     * parameter for basic security
     *
     * @type {string}
     * @memberof Configuration
     */
    password?: string;
    /**
     * parameter for oauth2 security
     * @param name security name
     * @param scopes oauth2 scope
     * @memberof Configuration
     */
    accessToken?: string | Promise<string> | ((name?: string, scopes?: string[]) => string) | ((name?: string, scopes?: string[]) => Promise<string>);
    /**
     * override base path
     *
     * @type {string}
     * @memberof Configuration
     */
    basePath?: string;
    /**
     * base options for axios calls
     *
     * @type {any}
     * @memberof Configuration
     */
    baseOptions?: any;
    /**
     * The FormData constructor that will be used to create multipart form data
     * requests. You can inject this here so that execution environments that
     * do not support the FormData class can still run the generated client.
     *
     * @type {new () => FormData}
     */
    formDataCtor?: new () => any;

    constructor(param: ConfigurationParameters = {}) {
        this.apiKey = param.apiKey;
        this.username = param.username;
        this.password = param.password;
        this.accessToken = param.accessToken;
        this.basePath = param.basePath;
        this.baseOptions = param.baseOptions;
        this.formDataCtor = param.formDataCtor;
    }

    /**
     * Check if the given MIME is a JSON MIME.
     * JSON MIME examples:
     *   application/json
     *   application/json; charset=UTF8
     *   APPLICATION/JSON
     *   application/vnd.company+json
     * @param mime - MIME (Multipurpose Internet Mail Extensions)
     * @return True if the given MIME is JSON, false otherwise.
     */
    public isJsonMime(mime: string): boolean {
        const jsonMime: RegExp = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
        return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
    }
}
