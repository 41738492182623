import {Layout} from "../common/Layout";
import React, {useContext, useEffect, useState} from "react";
import {H1, H3, H4} from "../common/Headline";
import {PpTestKitControllerEditRequest, PpteststatusStatus, TypesPpTestKitData} from "../../generated";
import {AppContext} from "../../contexts/AppContext";
import {adminPpTestKitApi} from "../../api/Api";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {DetailHeader} from "./DetailHeader";
import {PetModal} from "../common/PetModal";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {Owner} from "./partial/Owner";
import {PetForm} from "./partial/PetForm";
import {Condition} from "../pp_test_kit/interview/Condition";
import {Habit} from "../pp_test_kit/interview/Habit";
import {CompleteMessage} from "../common/CompleteMessage";


export const PpTestStatusEdit = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppTestKit, setPpTestKit] = useState<TypesPpTestKitData | null>(null);
    const [modalImg, setModalImg] = React.useState<string>("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        if (params.sampleId) {
            getPpTestKit(params.sampleId);
        }

    }, []);

    const onReloadPpTestKit = () => {
        getPpTestKit(params.sampleId ?? "");
    }

    const getPpTestKit = (sampleId: string) => {

        setShowSpinner(true);

        adminPpTestKitApi.v1AdminPpTestKitGetSampleIdGet(sampleId)
            .then(({data}) => {
                setPpTestKit(data.pp_test_kit);

                if (data.pp_test_kit.status !== PpteststatusStatus.Status01) {
                    // 編集可能でない場合（検査未受付時のみ）
                    setDangerMessage("編集可能な状態ではありません。");
                    navigate(`/pp_test_status/detail/${sampleId}`);
                }
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
                navigate("/pp_test_status");
            })
            .finally(() => {
                setShowSpinner(false);
            });

    };

    if (!ppTestKit || ppTestKit.pp_user == null) {
        return <Layout title="検査情報照会"/>
    }

    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/pp_test_status/detail/${ppTestKit.sample_id}`);
    };

    const onUpdate = (next: TypesPpTestKitData) => {

        if (next.bi_pet_sex === "1") {
            // オスの場合は、妊娠関連項目を「いいえ」に設定
            next.is_was_pregnant_past = "2";
            next.is_pregnancy = "2";
            next.is_lactation_experience = "";
            next.is_lactated_children = "";
            next.is_total_lactation_duration = "";
            next.is_pregnancy_weeks = "0";
        }

        setPpTestKit((prev) => {
            return {...prev, ...next};
        });
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {

        e.preventDefault();
        setShowSpinner(true);
        setErrors({});

        const req = ppTestKit as PpTestKitControllerEditRequest;

        adminPpTestKitApi.v1AdminPpTestKitEditSampleIdPost(ppTestKit.sample_id, req)
            .then(() => {
                navigate(`/pp_test_status/detail/${ppTestKit.sample_id}?edit_complete=1`);
            })
            .catch((err) => {
                if (err.response.status === 406) {
                    // バリデーションエラー
                    setDangerMessage("入力内容に不足、または誤りがあります。もう一度、入力内容をご確認ください。");
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });

    };

    return <Layout title="検査情報照会">

        <H1 title="会員の登録情報" subtitle="user data"/>

        <CompleteMessage/>

        <StyledPpTestStatusDetail onSubmit={onSubmit}>

            <DetailHeader active={0} ppTestKit={ppTestKit} onReload={onReloadPpTestKit}/>

            <div className="box mt-minus">
                <H3 title="オーナー情報" subtitle="owner"/>

                <Owner ppTestKit={ppTestKit}/>
            </div>

            <H4 title="登録中のペット情報の編集" id="pet"/>

            <div className="box">

                <H3 title="ペット情報" subtitle="pet"/>

                <PetForm ppTestKit={ppTestKit} onUpdate={onUpdate} errors={errors}/>
            </div>

            <div className="btn-area mb100">
                <Button color={ButtonColor.Gray} onClick={onClickBack} icon={ButtonIcon.Arrow} type="button">前の画面に戻る</Button>
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>修正を保存する</Button>
            </div>

            <H4 title="基礎情報の修正"/>

            <span id="condition"/>

            {/*体調フォームは再利用*/}
            <Condition errors={errors} onUpdate={onUpdate} ppTestKit={ppTestKit}/>

            <div className="btn-area mb100">
                <Button color={ButtonColor.Gray} onClick={onClickBack} icon={ButtonIcon.Arrow} type="button">前の画面に戻る</Button>
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>修正を保存する</Button>
            </div>

            <span id="habit"/>

            {/*生活週間フォームは再利用*/}
            <Habit errors={errors} onUpdate={onUpdate} ppTestKit={ppTestKit}/>

            <div className="btn-area mb100">
                <Button color={ButtonColor.Gray} onClick={onClickBack} icon={ButtonIcon.Arrow} type="button">前の画面に戻る</Button>
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>修正を保存する</Button>
            </div>


        </StyledPpTestStatusDetail>

        {modalImg && <PetModal img={modalImg ?? ""} onClose={() => setModalImg("")}/>}

    </Layout>

};


const StyledPpTestStatusDetail = styled(StyledForm)`


    // ヘッダー部分に被せる

    .mt-minus {
        margin-top: -120px !important;
    }

`;
