import styled from "styled-components";
import navLogo from "../../images/nav-logo.svg";
import {NavLink, useLocation} from "react-router-dom";
import nav01 from "../../images/nav-01.svg";
import nav02 from "../../images/nav-02.svg";
import nav03 from "../../images/nav-03.svg";
import nav04 from "../../images/nav-04.svg";
import nav05 from "../../images/nav-05.svg";
import nav06 from "../../images/nav-06.svg";
import nav07 from "../../images/nav-07.svg";
import nav08 from "../../images/nav-08.svg";
import nav09 from "../../images/nav-09.svg";
import nav10 from "../../images/nav-10.svg";
import nav11 from "../../images/nav-11.svg";
import {useContext} from "react";
import {AppContext} from "../../contexts/AppContext";
import {AccesstokenRole} from "../../generated";
import {MainColor} from "./Colors";

interface MenuParts {
    roles: AccesstokenRole[],
    name: string;
    link: string;
    className: string;
    exact: boolean;
    aliases?: string[];
}

export const Menu = () => {

    const location = useLocation();
    const {role} = useContext(AppContext);

    const commonMenus: MenuParts[] = [
        // common
        {
            roles: [AccesstokenRole.RoleMaster, AccesstokenRole.RoleCs, AccesstokenRole.RoleOperator, AccesstokenRole.RoleMedical, AccesstokenRole.RolePartner, AccesstokenRole.RoleLaboratory],
            name: "検査情報照会",
            link: "/pp_test_status",
            className: "common",
            exact: false,
        },
        {
            roles: [AccesstokenRole.RoleMaster, AccesstokenRole.RoleCs, AccesstokenRole.RoleOperator, AccesstokenRole.RoleMedical],
            name: "会員検索",
            link: "/pp_user",
            className: "common",
            exact: true,
            aliases: [
                "/pp_user/detail",
                "/pp_user/edit",
                "/pp_pet/create",
                "/pp_pet/edit",
                "/pp_pet/create-complete",
            ]
        },
        {
            roles: [AccesstokenRole.RoleMaster, AccesstokenRole.RoleOperator, AccesstokenRole.RoleMedical],
            name: "会員情報登録",
            link: "/pp_user/create",
            className: "common",
            exact: false,
        },
        {
            roles: [AccesstokenRole.RoleMaster, AccesstokenRole.RoleOperator, AccesstokenRole.RoleMedical],
            name: "検査申込管理",
            link: "/pp_test_kit",
            className: "common",
            exact: false,
        },
    ];
    const labMenus: MenuParts[] = [
        // laboratory
        {
            roles: [AccesstokenRole.RoleMaster, AccesstokenRole.RoleOperator, AccesstokenRole.RoleLaboratory],
            name: "検査受付",
            link: "/reception",
            className: "laboratory",
            exact: false,
        },
        {
            roles: [AccesstokenRole.RoleMaster, AccesstokenRole.RoleOperator, AccesstokenRole.RoleLaboratory],
            name: "メタデータ取得",
            link: "/metadata",
            className: "laboratory",
            exact: false,
        },
        {
            roles: [AccesstokenRole.RoleMaster, AccesstokenRole.RoleOperator, AccesstokenRole.RoleLaboratory],
            name: "検査結果登録",
            link: "/result",
            className: "laboratory",
            exact: false,
        },
        {
            roles: [AccesstokenRole.RoleMaster, AccesstokenRole.RoleOperator, AccesstokenRole.RoleLaboratory],
            name: "試験番号情報照会",
            link: "/reference",
            className: "laboratory",
            exact: false,
        },
    ];
    const operatorMenus: MenuParts[] = [

        // operator
        {
            roles: [AccesstokenRole.RoleMaster, AccesstokenRole.RoleOperator],
            name: "検体ID情報管理",
            link: "/sample_id",
            className: "operator",
            exact: false,
        },
        {
            roles: [AccesstokenRole.RoleMaster, AccesstokenRole.RoleOperator],
            name: "強制検査受付",
            link: "/forced_test",
            className: "operator",
            exact: false,
        },
        {
            roles: [AccesstokenRole.RoleMaster, AccesstokenRole.RoleOperator],
            name: "システム設定",
            link: "/system",
            className: "operator",
            exact: false,
            aliases: [
                "/pp_mst_user_admin",
                "/pp_mst_user_partner",
                "/pp_announcement_admin",
                "/pp_announcement_user",
                "/pp_mst_user_medical",
                "/pp_mst_partner_inst",
                "/pp_maintenance",
                "/pp_mst_sku",
                "/pp_mst_rcmnd_product",
                "/pp_mst_identification_cd",
                "/pp_faq",
                "/pp_mst_rcmnd_method",
                "/pp_mst_generic_rcmnd_group",
                "/pp_mst_rcmnd_products_title",
                "/pp_mst_generic_rcmnd_image",
            ]

        },
    ];

    const getMenus = (className: string, menus: MenuParts[]): JSX.Element | null => {

        let hasMenu = false;

        const liElements = menus.map((d, i) => {

            let active = false;

            if (!d.exact && location.pathname.indexOf(d.link) === 0) {
                active = true;
            } else if (d.exact && location.pathname === d.link) {
                active = true;
            } else if (d.aliases) {

                d.aliases.forEach((a) => {
                    if (location.pathname.indexOf(a) === 0) {
                        active = true;
                    }
                });
            }

            const classes = [d.className];

            if (active) {
                classes.push("active");
            }

            if (role && d.roles.indexOf(role) === -1) {
                return null;
            }

            hasMenu = true;

            return <li className={classes.join(" ")} key={`menu-${i}`}><NavLink to={d.link}>{d.name}</NavLink></li>
        });

        if (!hasMenu) {
            return null;
        }

        return <ul className={className}>
            {liElements}
        </ul>


    };

    return <StyledMenu className={`color-${role}`}>

        <h1><NavLink to={"/"}>PiTPET</NavLink></h1>

        {getMenus("common", commonMenus)}
        {getMenus("laboratory", labMenus)}
        {getMenus("operator", operatorMenus)}

    </StyledMenu>

}

const StyledMenu = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  flex-shrink: 0;
  width: 120px;
  padding: 40px 0;
  background-color: #fff;
  height: 100vh;
  overflow-y: scroll;
  box-shadow: 2px 0 2px rgba(0, 0, 0, .1);
  z-index: 2;

  h1 {
    margin: 0 auto 13px auto;
    width: 95px;
    height: 59px;

    a {
      width: 100%;
      height: 100%;
      display: block;
      background-repeat: no-repeat;
      background-image: url(${navLogo});
      background-size: 100% auto;
      overflow: hidden;
      text-indent: 100px;
      white-space: nowrap;
    }
  }

  ul {
    list-style-type: none;
    width: 103px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 10px;

    li {
      padding: 5px;
      border-radius: 8px;
      margin-bottom: 5px;

      a {
        display: block;
        text-align: center;
        text-decoration: none;
        width: 100%;
        height: 61px;
        color: #aaa;
        font-size: 12px;
        background-repeat: no-repeat;
        background-position: top 5px left 50%;
        padding-top: 45px;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }
      }

      &.active {
        background-color: #FFFAE6;

        a {
          color: ${MainColor};
        }
      }
    }

    &:after {
      content: "";
      width: 40px;
      margin: 0 auto 5px auto;
      border: none;
      border-top: 1px solid #ccc;
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &:last-child:after {
      content: none;
    }
  }

  ul.common {
    li:nth-child(1) a {
      background-size: auto 32px;
      background-image: url(${nav06});
    }

    li:nth-child(2) a {
      background-size: 30px auto;
      background-image: url(${nav07});
    }

    li:nth-child(3) a {
      background-size: auto 32px;
      background-image: url(${nav08});
    }

    li:nth-child(4) a {
      background-size: auto 32px;
      background-image: url(${nav09});
    }
  }

  ul.laboratory {
    li:nth-child(1) a {
      background-size: auto 32px;
      background-image: url(${nav03});
    }

    li:nth-child(2) a {
      background-image: url(${nav04});
      background-size: auto 32px;
    }

    li:nth-child(3) a {
      background-image: url(${nav05});
      background-size: auto 32px;
    }

    li:nth-child(4) a {
      background-image: url(${nav11});
      background-size: auto 32px;
    }

  }

  ul.operator {
    li:nth-child(1) a {
      background-size: auto 32px;
      background-image: url(${nav01});
    }

    li:nth-child(2) a {
      background-image: url(${nav02});
      background-size: auto 32px;
    }

    li:nth-child(3) a {
      background-image: url(${nav10});
      background-size: auto 32px;
    }

  }

`;
