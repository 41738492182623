import styled from "styled-components";
import {MainColor} from "./Colors";
import React from "react";
import bgH4 from "../../images/bg-h4.svg";

interface Props extends React.PropsWithChildren {
    title: string;
    subtitle?: string;
    id?: string;
    counter?: JSX.Element;
}

export const H1 = (props: Props) => {
    return <StyledH1 id={props.id}>
        {props.title}
        {props.subtitle && <span>{props.subtitle}</span>}
        {props.children && <div className="right">{props.children}</div>}
    </StyledH1>

};

const StyledH1 = styled.h1`
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 1.5px;
  color: #515151;
  padding: 0 20px;
  line-height: 47px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #EFEFEF;
  background-color: #fff;
  margin-bottom: 30px;

  span {
    color: #FFC76E;
    font-size: 12px;
    margin-left: 10px;
  }
`;

export const H2 = (props: Props) => {
    return <StyledH2 id={props.id}>
        {props.title}
        {props.subtitle && <span>{props.subtitle}</span>}
        {props.children && <div className="right">{props.children}</div>}
    </StyledH2>

};

const StyledH2 = styled.h2`
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 1.5px;
  position: relative;
  color: #515151;
  padding: 0 20px;
  line-height: 47px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #EFEFEF;
  margin: -24px -24px 20px -24px;

  span {
    color: #FFC76E;
    font-size: 12px;
    margin-left: 10px;
  }
`;

export const H3 = (props: Props) => {
    return <StyledH3 id={props.id}>
        {props.title}
        {props.subtitle && <span>{props.subtitle}</span>}
        {props.counter}
        {props.children && <div className="right">{props.children}</div>}
    </StyledH3>
};

const StyledH3 = styled.h3`
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 1.5px;
  margin-bottom: 16px;
  padding-bottom: 15px;
  border-bottom: 1px solid #E8E8E8;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  .counter {
    margin-left: 24px;
  }
  
  span {
    color: #FFC76E;
    font-size: 12px;
    margin-left: 10px;
  }

  &:after {
    position: absolute;
    content: "";
    width: 80px;
    height: 2px;
    background-color: ${MainColor};
    bottom: -1px;
    left: 0;
  }
  
  .right {
    margin-left: auto;
    
    button {
      margin-left: 15px;
    }
  }
`;


export const H4 = (props: Props) => {
    return <StyledH4 id={props.id}>
        {props.title}
        {props.subtitle && <span>{props.subtitle}</span>}
        {props.children && <div className="right">{props.children}</div>}
    </StyledH4>
};

const StyledH4 = styled.h4`
  font-size: 20px;
  color: #515151;
  font-weight: normal;
  letter-spacing: 1.5px;
  margin-bottom: 25px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-image: url(${bgH4});
  background-repeat: no-repeat;
  padding: 18px 0 0 25px;

  span {
    color: #FFC76E;
    font-size: 12px;
    margin-left: 10px;
  }
  
  .right {
    margin-left: auto;
  }
`;