import {AccesstokenRole, TypesAccessType, TypesPpTestKitData} from "../../generated";
import React, {useContext, useState} from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {AppContext} from "../../contexts/AppContext";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "../common/Button";
import {IdentificationCdModal} from "./partial/IdentificationCdModal";
import {KarteIdModal} from "./partial/KarteIdModal";

interface Props {
    active: number;
    ppTestKit: TypesPpTestKitData;
    onReload: () => void;
}

export const DetailHeader = ({active, ppTestKit, onReload}: Props) => {

    const {role, ppMstUserPartner} = useContext(AppContext);
    const [isChangeModal, setIsChangeModal] = useState<boolean>(false);
    const [isKarteModal, setIsKarteModal] = useState<boolean>(false);

    if (!role) {
        return null;
    }

    // 識別コード変更ボタン表示判定(運用保守、特権ユーザーのみ)
    // ログインユーザーが運営管理ユーザー、かつアクセス権限が「1：運用保守」または「4：特権ユーザー」のいずれかである。
    // ログインユーザーが医療法人ユーザーである
    // see: https://docs.google.com/spreadsheets/d/1un46Bbag3ORysfzqBx0TUYrtcIH6RJzF/edit#gid=9698707
    let isShowChangeButton = role === AccesstokenRole.RoleOperator || role === AccesstokenRole.RoleMaster || role === AccesstokenRole.RoleMedical;
    if (role === AccesstokenRole.RolePartner && ppMstUserPartner && [String(TypesAccessType.AccessTypeGeneral), String(TypesAccessType.AccessTypeAdvisor)].includes(String(ppMstUserPartner.access_type))) {
        // ログインユーザーが提携法人ユーザー、かつアクセス権限が「1：一般提携法人ユーザー」または「3：顧問研究者ユーザー」のいずれかである。
        isShowChangeButton = true;
    }

    const isShowKarteChangeButton = role !== AccesstokenRole.RoleLaboratory && role !== AccesstokenRole.RoleCs;

    // 検査情報タブ表示判定
    let isResultVisible = true;

    if (role === AccesstokenRole.RolePartner && ppMstUserPartner && ppMstUserPartner.access_type === TypesAccessType.AccessTypeLab) {
        // ログインユーザーが提携法人ユーザー、かつアクセス権限が「2：研究機関ユーザー」である場合は編集不可
        // https://docs.google.com/spreadsheets/d/1un46Bbag3ORysfzqBx0TUYrtcIH6RJzF/edit#gid=9698707
        isResultVisible = false;
    }

    // 菌組成情報タブ表示判定
    // なお、菌組成情報タブは全員表示ok
    let isBacteriaVisible = role !== AccesstokenRole.RoleMedical;

    if(role === AccesstokenRole.RolePartner && ppMstUserPartner && ppMstUserPartner.access_type === TypesAccessType.AccessTypeGeneral) {
        // 提携法人の場合は、一般提携法人ユーザーは菌組成タブを表示しない
        isBacteriaVisible = false;
    }

    const onToggleModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsChangeModal(!isChangeModal);
    }

    const onToggleKarteModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsKarteModal(!isKarteModal);
    }

    // 識別コード変更完了コールバック
    const onComplete = () => {
        setIsChangeModal(false);
        setIsKarteModal(false);
        onReload(); // バケツリレー式に更新
    };

    return <>
        <StyledDetailHeader>
            <div className="left">
                <div>
                    <span>検体ID</span>
                    <span>{ppTestKit.sample_id}</span>
                    <span>識別コード</span>
                    <span>{ppTestKit.identification_cd ?? "コードなし"}</span>
                    {isShowChangeButton && <Button color={ButtonColor.Green} type="button" icon={ButtonIcon.Arrow} size={ButtonSize.Medium} onClick={onToggleModal}>識別コードを変更</Button>}
                </div>
                <div>
                    <span>カルテID</span>
                    <span>{ppTestKit.pp_pet?.karte_id ?? "カルテIDなし"}</span>
                    {isShowKarteChangeButton && <Button color={ButtonColor.Green} type="button" icon={ButtonIcon.Arrow} size={ButtonSize.Medium} onClick={onToggleKarteModal}>カルテIDを変更</Button>}
                </div>
            </div>
            <div className="tabs">
                <ul>
                    <li className={active === 0 ? "active " : ""}><NavLink to={`/pp_test_status/detail/${ppTestKit.sample_id}`}>基本情報</NavLink></li>
                    {isResultVisible && <li className={active === 1 ? "active " : ""}><NavLink to={`/pp_test_status/result/${ppTestKit.sample_id}`}>検査情報</NavLink></li>}
                    {isBacteriaVisible && <li className={active === 2 ? "active " : ""}><NavLink to={`/pp_test_status/bacteria/${ppTestKit.sample_id}`}>菌組成情報</NavLink></li>}
                    <li className={active === 3 ? "active " : ""}><NavLink to={`/pp_test_status/bad-bacteria/${ppTestKit.sample_id}`}>有害菌情報</NavLink></li>
                </ul>
            </div>
        </StyledDetailHeader>

        {isChangeModal && <IdentificationCdModal
            sampleId={ppTestKit.sample_id}
            onCancel={onToggleModal}
            oldValue={ppTestKit.identification_cd}
            onComplete={onComplete}
        />}

        {(isKarteModal && ppTestKit.pp_pet) && <KarteIdModal
            ppPetId={ppTestKit.pp_pet?.id ?? 0}
            onCancel={onToggleKarteModal}
            oldValue={ppTestKit.pp_pet?.karte_id ?? ""}
            onComplete={onComplete}
        />}
    </>

};


const StyledDetailHeader = styled.div`

    display: flex;
    justify-content: space-between;
    background-color: #039573;
    margin: 0 -40px 0 -40px;
    padding: 18px 50px 120px 50px;
    align-items: center;

    .left {
        > div {
            display: flex;
            align-items: center;
            padding-bottom: 10px;

            span {
                &:nth-child(1), &:nth-child(3) {
                    background-color: #F4E37D;
                    border-radius: 6px;
                    color: #008972;
                    font-size: 14px;
                    line-height: 29px;
                    text-align: center;
                    width: 64px;
                    margin-right: 16px;
                }

                &:nth-child(3) {
                    width: 92px;

                }

                &:nth-child(2), &:nth-child(4) {
                    color: #fff;
                    font-size: 16px;
                    margin-right: 40px;
                }
            }

        }
    }

    .tabs {
        padding-top: 48px;

        ul {
            list-style-type: none;
            display: flex;
            justify-content: flex-end;

            li {
                margin-right: 4px;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    display: block;
                    background-color: #03704b;
                    color: #fff;
                    text-align: center;
                    letter-spacing: 1.5px;
                    font-size: 14px;
                    padding: 10px 16px 4px 16px;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }

                }

                &.active a {
                    background-color: #fff;
                    color: #333;
                }

            }
        }

    }

`;