import {Layout} from "../common/Layout";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../contexts/AppContext";
import {adminPpPetApi, adminPpTestKitApi} from "../../api/Api";
import {PpTestKitControllerApplyRequest, TypesPpPetData} from "../../generated";
import {H1, H3} from "../common/Headline";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {PartsPetDetail} from "../pp_pet/PartsPetDetail";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {ErrorMessage} from "../common/ErrorMessage";

export const PpTestKitApply = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();
    const [ppPet, setPpPet] = useState<TypesPpPetData | null>(null);
    const [sampleId, setSampleId] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [errors, setErrors] = useState<{ [key: string]: string; }>({});

    useEffect(() => {

        if (params.memberId && params.petId) {
            getPpPet(params.memberId, params.petId);
        }
    }, []);

    const getPpPet = (memberId: string, petId: string) => {

        setShowSpinner(true);

        adminPpPetApi.v1AdminPpPetGetPpUserIdIdGet(memberId, petId)
            .then(({data}) => {
                setPpPet(data.pp_pet ?? null);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
                navigate("/pp_test_kit");
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    if (!ppPet) {
        // 会員が取得できるまではダミー表示
        return <Layout title={"検査申込管理"}/>;
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.currentTarget.name) {
            case "sample_id":
                setSampleId(e.currentTarget.value);
                break;
            case "password":
                setPassword(e.currentTarget.value);
                break;
        }
    };

    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {

        e.preventDefault();

        navigate(`/pp_test_kit/detail/${ppPet.pp_user_id}`);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {

        e.preventDefault();

        setShowSpinner(true);
        setErrors({});

        const req: PpTestKitControllerApplyRequest = {
            sample_id: sampleId,
            password: password,
            pp_user_id: ppPet.pp_user_id,
            pp_pet_id: ppPet.id,
        };

        adminPpTestKitApi.v1AdminPpTestKitApplyPost(req)
            .then(({data}) => {
                // 検体IDを付与して次のページに遷移
                navigate(`/pp_test_kit/step1/${data.sample_id}`);
            })
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    };


    return <Layout title={"検査申込管理"}>

        <H1 title="ペット情報の新規登録" subtitle="registration"/>

        <StyledPpTestKitApply onSubmit={onSubmit}>

            <div className="box">
                <H3 title="ペットの情報" subtitle="pet"/>
                <PartsPetDetail ppPet={ppPet}/>
            </div>

            <div className="box">
                <H3 title="検体情報" subtitle="user information"/>

                <div className="test-kit-form">
                    <div className="left">

                        <div className="form-wrapper">

                            <div className="form-group">
                                <label className="req">検体ID</label>
                                <div>
                                    <input type="text" name="sample_id" placeholder="例：PC23-1000-NGS-2000" value={sampleId} onChange={onChange}/>
                                    <div className="hint">
                                        ※15桁の半角英数字（大文字のみ、ハイフン除く。）
                                    </div>
                                    <ErrorMessage message={errors["sample_id"]}/>
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="req">パスワード</label>
                                <div>
                                    <input type="text" name="password" value={password} onChange={onChange}/>
                                    <div className="hint">
                                        ※8桁の半角英数字（アルファベット l（エル）とo（オー ）は除く 。）
                                    </div>
                                    <ErrorMessage message={errors["password"]}/>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="right">

                        <div className="note">
                            <h5>ご注意</h5>
                            <p>
                                <strong>検査キットに同封されている手引き</strong>をお手元にご用意ください。<br/>
                                なお、検体ID・パスワードは、<br/>
                                <strong>結果が出るまでお手元に保管</strong>してください。
                            </p>
                        </div>

                    </div>

                </div>
            </div>


            <div className="btn-area">
                <Button color={ButtonColor.Gray} icon={ButtonIcon.Arrow} type="button" onClick={onClickBack}>前の画面に戻る</Button>
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>次へ進む</Button>
            </div>

        </StyledPpTestKitApply>


    </Layout>

}

const StyledPpTestKitApply = styled(StyledForm)`

  .test-kit-form {
    display: flex;
    justify-content: center;

    .left {
      flex: 1;

      input {
        width: 100%;
      }
    }

    .right {
      padding: 0 30px;

      .note {
        width: 556px;

        h5 {
          text-align: center;
          color: #fff;
          background-color: #D93E4C;
          line-height: 48px;
          font-size: 16px;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
          font-weight: normal;
        }

        p {
          background-color: #EFEFEF;
          color: #333333;
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
          font-size: 16px;
          padding: 33px 12px 40px 43px;
          line-height: 2;

          strong {
            font-weight: normal;
            color: #D93E4C;
            background: linear-gradient(transparent 50%, rgba(217, 62, 76, 0.2) 20%);
          }
        }

      }
    }
  }


`;
