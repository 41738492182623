import styled from "styled-components";
import React from "react";
import {SlashedDate} from "../../../utility/Utility";

interface BadBacteriaLowerGraphProps {
    dates: string[];
    values: number[];
}

// svg全体のサイズ
const w = 864;
const h = 360;

// ページ下部の推移グラフ
export const BadBacteriaLowerGraph = ({dates, values}: BadBacteriaLowerGraphProps) => {

    const xPadding = 100;
    const yPadding = 100;

    // 内径サイズはそれぞれ
    const graphW = w - xPadding;
    const graphH = h - yPadding;

    // グラフの上限値を決める
    // const maxValue = Math.max(...values);
    // 20240412 上限5%に変更
    const max = 5;

    // if (maxValue >= 81) {
    //     max = 100;
    // } else if (maxValue >= 61) {
    //     max = 80;
    // } else if (maxValue >= 41) {
    //     max = 60;
    // } else if (maxValue >= 21) {
    //     max = 40;
    // } else {
    //     max = 20;
    // }

    // 左側凡例を作成
    // 0〜maxの5等分
    const legend = [];
    for (let i = 0; i <= max; i += max / 5) {
        legend.push(i);
    }

    const horizontalLines: JSX.Element[] = [];
    const legendElements = legend.reverse().map((n, i) => {
        const y = (graphH) / 5 * i;

        if (i !== 5) {
            // ついでに水平線も作成
            horizontalLines.push(<line key={`hl-${i}`} x1={0} y1={y} x2={graphW} y2={y} stroke="#B7B7B7" strokeWidth={1} strokeDasharray={`4, 5`}/>);
        }
        return <text key={`lt-${i}`} x={-13} y={y} dominantBaseline="middle" textAnchor="end" fill="#444444">{n}</text>
    });

    // 描画するグラフ数は最大6としてエリアを分割
    const valueLength = values.length > 6 ? 6 : values.length;
    const eachWidth = graphW / valueLength;

    const filteredDates = dates.slice(0, valueLength).reverse();
    const filteredValues = values.slice(0, valueLength).reverse();


    // 推移を取得
    const barGraphClass: string[] = ["transition"];
    const textClass: string[] = [""];
    const diffValue = values[0] - values[1];
    if (diffValue < 0) {
        barGraphClass.push("green");
        textClass.push("green");
    } else if (diffValue > 0) {
        barGraphClass.push("red");
        textClass.push("red");
    }

    const lowerLabels: JSX.Element[] = [];
    const barGraphs: JSX.Element[] = [];
    const pathParts: string[] = [];
    const circles: JSX.Element[] = [];
    const barGraphW = 62;
    const valueTexts: JSX.Element[] = [];
    for (let i = 0; i < filteredDates.length; i++) {
        const x = eachWidth * i + eachWidth / 2;

        // 上限を超えたら上つきになるようにする
        let _val = filteredValues[i];
        if (filteredValues[i] > max) {
            _val = max;
        }

        const _h = graphH * (_val / max);

        // 下部日付
        lowerLabels.push(<text key={`lt-${i}`} x={x} y={graphH + 10} dominantBaseline="hanging" textAnchor={"middle"} fill="#444444">{SlashedDate(filteredDates[i])}</text>);

        // 棒グラフ
        barGraphs.push(<rect
            key={`bg-${i}`}
            fill="#B7B7B7"
            width={barGraphW}
            height={_h + 20}
            x={x - barGraphW / 2}
            y={graphH - _h}
            className={i === filteredValues.length - 1 ? barGraphClass.join(" ") : "transition"}
            clipPath={`url(#graphClip)`}
            rx={5}
            ry={5}
        />)

        // 各棒のラベル
        valueTexts.push(<text
            key={`gl-${i}`}
            x={x}
            y={graphH - _h - 16}
            textAnchor="middle"
            dominantBaseline="auto"
            className={i === filteredDates.length - 1 ? textClass.join(" ") : ""}
        >{filteredValues[i]}%</text>)

        // パス
        if (i === 0) {
            // 始点
            pathParts.push(`M${x} ${graphH - _h}`);
        } else {
            pathParts.push(`L${x} ${graphH - _h}`);
        }

        // 円
        circles.push(<circle key={`ccl-${i}`} cx={x} cy={graphH - _h} r={10} fill="#fff" strokeWidth={2} stroke="#8B8B8B" className="transition"/>);
    }

    return <StyledBadBacteriaLowerGraph viewBox={`0 0 ${w} ${h}`}>

        <g transform={`translate(${xPadding / 2 + .5}, ${yPadding / 2 + .5})`}>

            {/*<rect x={0} y={0} width={graphW} height={graphH} fill={"#f5f5f5"}/>*/}

            <clipPath id="graphClip">
                <rect x={0} y={0} width={graphW} height={graphH} fill={"#f5f5f5"}/>
            </clipPath>

            {/*水平線*/}
            {horizontalLines}

            {/*左凡例*/}
            {legendElements}
            <text x={0} y={-25} fill="#444444" textAnchor="middle">ratio(%)</text>

            {/*グラフ*/}
            {barGraphs}

            {/*各棒の値（実数値）*/}
            {valueTexts}

            {/*縦線・横線*/}
            {/*縦線は上にはみ出るようにする*/}
            <line x1={0} y1={-15} x2={0} y2={graphH} stroke="#8B8B8B" strokeWidth={3} strokeLinecap="square"/>
            <line x1={0} y1={graphH} x2={graphW} y2={graphH} stroke="#8B8B8B" strokeWidth={3} strokeLinecap="square"/>

            {/*下部日付*/}
            {lowerLabels}

            {/*折れ線グラフ*/}
            <path d={pathParts.join(" ")} strokeWidth={3} stroke="#707070" fill="none" className="transition"/>

            {/*円*/}
            {circles}

        </g>

    </StyledBadBacteriaLowerGraph>
}

const StyledBadBacteriaLowerGraph = styled.svg`
    width: ${w}px;
    height: ${h}px;
    display: block;
    margin: 20px auto;

    text {
        font-size: 14px;

        &.red {
            fill: #D93E4C;
        }

        &.green {
            fill: #00BFA5;
        }
        
    }

    .transition {
        transition: all .4s;

        &.red {
            fill: #D93E4C;
        }

        &.green {
            fill: #00BFA5;
        }
    }
`;