import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {Table} from "../common/Table";
import {ShortenStr, SlashedYmd} from "../../utility/Utility";
import {H3} from "../common/Headline";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Links} from "../common/Links";
import {Paginator} from "../common/Paginator";
import {Counter} from "../common/Counter";
import {adminPpAnnouncementUserApi} from "../../api/Api";
import {AccesstokenRole, TypesPpAnnouncementUserData} from "../../generated";

export const PpAnnouncementUserIndex = () => {

    const {setShowSpinner, setDangerMessage, setSuccessMessage, role} = useContext(AppContext);
    const [ppAnnouncementUsers, setPpAnnouncementUsers] = useState<TypesPpAnnouncementUserData[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const navigate = useNavigate();
    const location = useLocation();

    const size = 100;

    // URL変更時
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get("page")) {
            setPage(Number(params.get("page")));
        }
    }, [location]);

    // ページ変更時
    useEffect(() => {
        getTopics();
    }, [page]);

    const getTopics = () => {

        setShowSpinner(true);

        adminPpAnnouncementUserApi.v1AdminPpAnnouncementUserGet(page, size)
            .then(({data}) => {
                setPpAnnouncementUsers(data.pp_announcement_users ?? []);
                setTotal(data.total ?? 0);
            })
            .catch((err) => {
                console.log(err);
                setDangerMessage("通信時にエラーが発生しました");
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onDelete = (e: React.MouseEvent<HTMLSpanElement>, ppAnnouncementUser: TypesPpAnnouncementUserData): void => {

        e.preventDefault();

        if (!window.confirm(`「${ShortenStr(ppAnnouncementUser.announcement_detail ?? "", 30)}」 を削除します。よろしいですか？`)) {
            return;
        }

        setShowSpinner(true);

        adminPpAnnouncementUserApi.v1AdminPpAnnouncementUserDeleteIdDelete(String(ppAnnouncementUser.id))
            .then(() => {
                setSuccessMessage("お知らせを削除しました。");

                getTopics();
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onPageChange = (page: number): void => {

        navigate(`/pp_announcement?page=${page}`);

    };

    return <Layout title={"一般用お知らせ管理"}>

        <div className="box">

            <H3 title="お知らせ一覧" subtitle="list" counter={<Counter total={total} size={size} current={page}/>}>
                {role === AccesstokenRole.RoleMaster && <>
                    <Links>
                        <NavLink to={"./create"}>新規追加</NavLink>
                    </Links>
                </>}
            </H3>


            <Table>

                <thead>
                <tr>
                    <th>表示日付</th>
                    <th>タイトル</th>
                    <th>表示期間</th>
                    {role === AccesstokenRole.RoleMaster && <>
                        <th>編集</th>
                        <th>削除</th>
                    </>}
                </tr>
                </thead>

                <tbody>
                {ppAnnouncementUsers.map((d, i) => {

                    return <tr key={`row-${i}`}>
                        <td>{SlashedYmd(d.display_ymd ?? "")}</td>
                        <td>{ShortenStr(d.announcement_title ?? "", 50)}</td>
                        <td>{SlashedYmd(d.display_period_from ?? "")} 〜 {SlashedYmd(d.display_period_to ?? "")}</td>
                        {role === AccesstokenRole.RoleMaster && <>
                            <td><NavLink to={`./edit/${d.id}`}>編集</NavLink></td>
                            <td><span onClick={(e) => onDelete(e, d)}>削除</span></td>
                        </>}
                    </tr>

                })}
                </tbody>

            </Table>

            <Paginator onClick={onPageChange} pages={Math.ceil(total / size)} page={page}/>

        </div>

    </Layout>

};
