import React, {useState} from "react";
import {AccessTokenModelLoginType, AccesstokenRole, SephirahModulesSephirahAuthCertificate, TypesPpMstUserAdminData, TypesPpMstUserPartnerData} from "../generated";
import {AppContextData} from "../typings";

export const AppContext = React.createContext({} as AppContextData);

export const AppProvider = ({children}: React.PropsWithChildren<any>): JSX.Element => {

    const [loginType, setLoginType] = useState<AccessTokenModelLoginType | null>(null);
    const [role, setRole] = useState<AccesstokenRole | null>(null);
    const [ppMstUserAdmin, setPpMstUserAdmin] = useState<TypesPpMstUserAdminData | null>(null);
    const [ppMstUserPartner, setPpMstUserPartner] = useState<TypesPpMstUserPartnerData | null>(null);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [dangerMessage, setDangerMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [completeMessage, setCompleteMessage] = useState<string | null>(null);

    const defaults: AppContextData = {
        loginType: loginType,
        role: role,
        setRole: setRole,
        setLoginType: setLoginType,
        setToken: (authCertificate: SephirahModulesSephirahAuthCertificate | null) => {
            if (!authCertificate) {
                sessionStorage.removeItem("sp_g_key");
                sessionStorage.removeItem("sp_g_token");
                sessionStorage.removeItem("sp_p_key");
                sessionStorage.removeItem("sp_p_token");
                sessionStorage.removeItem("sp_a_key");
                sessionStorage.removeItem("sp_a_token");
            } else {
                switch(authCertificate.authGroup) {
                    case "1":
                        // 一般ユーザー
                        sessionStorage.setItem("sp_g_key", authCertificate.tokenKey);
                        sessionStorage.setItem("sp_g_token", authCertificate.apiToken);
                        break;
                    case "2":
                        // 病院・提携法人ユーザー
                        sessionStorage.setItem("sp_p_key", authCertificate.tokenKey);
                        sessionStorage.setItem("sp_p_token", authCertificate.apiToken);
                        break;
                    case "3":
                        // Adminユーザー
                        sessionStorage.setItem("sp_a_key", authCertificate.tokenKey);
                        sessionStorage.setItem("sp_a_token", authCertificate.apiToken);
                        break;
                }
            }
        },
        ppMstUserAdmin: ppMstUserAdmin,
        setPpMstUserAdmin: setPpMstUserAdmin,
        ppMstUserPartner: ppMstUserPartner,
        setPpMstUserPartner: setPpMstUserPartner,
        dangerMessage: dangerMessage,
        successMessage: successMessage,
        setDangerMessage: (message: string | null) => {

            if (message) {
                setTimeout(() => {
                    setDangerMessage(null);
                }, 5000);
            }

            setDangerMessage(message);
        },
        setSuccessMessage:  (message: string | null) => {

            if (message) {
                setTimeout(() => {
                    setSuccessMessage(null);
                }, 5000);
            }

            setSuccessMessage(message);
        },
        showSpinner: showSpinner,
        setShowSpinner: setShowSpinner,
        completeMessage: completeMessage,
        setCompleteMessage: setCompleteMessage,
    };

    return <AppContext.Provider value={defaults}>
        {children}
    </AppContext.Provider>
}
