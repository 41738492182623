import {Layout} from "../common/Layout";
import React, {useContext, useEffect, useState} from "react";
import {H1, H3, H4} from "../common/Headline";
import {BirthTypeUnknown, CreateJaYmd, GetAnimalType, GetBirthdayType, GetPetGender, SlashedYmd} from "../../utility/Utility";
import {AccesstokenRole, PpteststatusStatus, TypesAccessType, TypesPpTestKitData} from "../../generated";
import {AppContext} from "../../contexts/AppContext";
import {adminPpTestKitApi} from "../../api/Api";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {DetailHeader} from "./DetailHeader";
import {PetModal} from "../common/PetModal";
import {Condition} from "../pp_test_kit/confirm/Condition";
import {Habit} from "../pp_test_kit/confirm/Habit";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "../common/Button";
import {Owner} from "./partial/Owner";
import {CompleteMessage} from "../common/CompleteMessage";


export const PpTestStatusDetail = () => {

    const {setShowSpinner, setDangerMessage, setCompleteMessage, role, ppMstUserPartner} = useContext(AppContext);
    const [ppTestKit, setPpTestKit] = useState<TypesPpTestKitData | null>(null);
    const [modalImg, setModalImg] = React.useState<string>("");
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        if (params.sampleId) {
            getPpTestKit(params.sampleId);
        }

        handleMessage();

    }, []);

    const handleMessage = () => {
        const params = new URLSearchParams(location.search);

        if (params.get("edit_complete")) {
            setCompleteMessage("ペット／基礎情報を変更いたしました。");
        }
    };

    const getPpTestKit = (sampleId: string) => {

        setShowSpinner(true);

        adminPpTestKitApi
            .v1AdminPpTestKitGetSampleIdGet(sampleId)
            .then(({data}) => {
                setPpTestKit(data.pp_test_kit);

                if (!data.pp_test_kit.pp_user_id) {
                    setDangerMessage("ご指定の検査情報は検査申込されていません。");
                    navigate("/pp_test_status");
                }

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
                navigate("/pp_test_status");
            })
            .finally(() => {
                setShowSpinner(false);
            });

    };

    if (!ppTestKit || ppTestKit.pp_user === null) {
        return <Layout title="検査情報照会">
            <H1 title="会員の登録情報" subtitle="user data"/>
        </Layout>
    }

    const onClickEdit = (e: React.MouseEvent<HTMLButtonElement>, type: string) => {
        e.preventDefault();
        navigate(`/pp_test_status/edit/${ppTestKit.sample_id}#${type}`);
    };

    const onReloadPpTestKit = () => {
        getPpTestKit(params.sampleId ?? "");
    }

    // 編集可能なステータスかどうか
    const isEditable = ppTestKit.status === PpteststatusStatus.Status01;
    // 編集可能なロールかどうか
    let isEditableRole = [String(AccesstokenRole.RoleCs), String(AccesstokenRole.RoleLaboratory)].indexOf(String(role)) === -1;

    if (role === AccesstokenRole.RolePartner && ppMstUserPartner && ppMstUserPartner.access_type === TypesAccessType.AccessTypeLab) {
        // ログインユーザーが提携法人ユーザー、かつアクセス権限が「2：研究機関ユーザー」である場合は編集不可
        // https://docs.google.com/spreadsheets/d/1un46Bbag3ORysfzqBx0TUYrtcIH6RJzF/edit#gid=9698707
        isEditableRole = false;
    }

    return <Layout title="検査情報照会">

        <H1 title="会員の登録情報" subtitle="user data"/>

        <CompleteMessage/>

        <DetailHeader active={0} ppTestKit={ppTestKit} onReload={onReloadPpTestKit}/>

        <StyledPpTestStatusDetail>

            <div className="box mt-minus">

                <H3 title="オーナー情報" subtitle="owner"/>

                <Owner ppTestKit={ppTestKit}/>

            </div>

            <H4 title="登録中のペット情報"/>

            <div className="box">

                <H3 title="ペット情報" subtitle="pet">
                    {isEditableRole && <Button type="button" disabled={!isEditable} color={ButtonColor.Green} size={ButtonSize.Small} icon={ButtonIcon.Arrow} onClick={e => onClickEdit(e, "pet")}>ペット情報を修正</Button>}
                </H3>

                <div className="form-wrapper">

                    <div className="form-group center">
                        <label>お名前</label>
                        <div>
                            {ppTestKit.bi_pet_name}
                        </div>
                    </div>

                    <div className="form-group center">
                        <label>種別</label>
                        <div>
                            {GetAnimalType(ppTestKit.bi_animal_type ?? "")}
                        </div>
                    </div>

                    <div className="form-group center">
                        <label>品種</label>
                        <div>
                            {ppTestKit.bi_breed_name}
                            {ppTestKit.bi_breed_name_parent_1 ? `（両親の品種1：${ppTestKit.bi_breed_name_parent_1}）` : ""}
                            {ppTestKit.bi_breed_name_parent_2 ? `（両親の品種2：${ppTestKit.bi_breed_name_parent_2}）` : ""}
                        </div>
                    </div>

                    <div className="form-group center">
                        <label>生年月日</label>
                        <div>
                            {ppTestKit.bi_pet_birthday && CreateJaYmd(ppTestKit.bi_pet_birthday ?? "")}
                            {ppTestKit.bi_accuracy_of_pet_birthday !== BirthTypeUnknown && `（${GetBirthdayType(ppTestKit.bi_accuracy_of_pet_birthday ?? "")}）`}
                            {ppTestKit.bi_accuracy_of_pet_birthday === BirthTypeUnknown && `不明`}
                        </div>
                    </div>

                    <div className="form-group center">
                        <label>性別</label>
                        <div>
                            {GetPetGender(ppTestKit.bi_pet_sex ?? "")}
                        </div>
                    </div>

                    <div className="form-group">
                        <label>アイコン</label>
                        <div>
                            {ppTestKit.bi_icon && <div className="filename"><span onClick={() => setModalImg(ppTestKit.bi_icon_url ?? "")}>{ppTestKit.bi_icon_name}（クリックして確認）</span></div>}
                            {!ppTestKit.bi_icon_name && <div className="upload-none">未設定</div>}
                        </div>
                    </div>

                    <div className="form-group center">
                        <label>体重</label>
                        <div>
                            {ppTestKit.is_pet_weight}kg
                        </div>
                    </div>

                    <div className="form-group center">
                        <label>検体採取日</label>
                        <div>
                            {SlashedYmd(ppTestKit.sampling_date ?? "")}
                        </div>
                    </div>

                </div>

            </div>

            <H4 title="基礎情報"/>

            <div className="box">

                <H3 title="体調について" subtitle="condition">
                    {isEditableRole && <Button type="button" disabled={!isEditable} color={ButtonColor.Green} size={ButtonSize.Small} icon={ButtonIcon.Arrow} onClick={e => onClickEdit(e, "condition")}>体調についてを修正</Button>}
                </H3>

                <Condition ppTestKit={ppTestKit}/>

            </div>

            <div className="box">

                <H3 title="生活習慣について" subtitle="condition">
                    {isEditableRole && <Button type="button" disabled={!isEditable} color={ButtonColor.Green} size={ButtonSize.Small} icon={ButtonIcon.Arrow} onClick={e => onClickEdit(e, "habit")}>生活習慣についてを修正</Button>}
                </H3>

                <Habit ppTestKit={ppTestKit}/>

            </div>

        </StyledPpTestStatusDetail>

        {modalImg && <PetModal img={modalImg ?? ""} onClose={() => setModalImg("")}/>}

    </Layout>

};


const StyledPpTestStatusDetail = styled(StyledForm)`

    // ヘッダー部分に被せる

    .mt-minus {
        margin-top: -120px !important;
    }

`;
