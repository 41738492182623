import styled from "styled-components";

export const Footer = () => {

    return <StyledFooter>
        <p>© 2023 <a href="https://proumed.jp/" rel={"noreferrer"} target="_blank">PROUMED Co.,Ltd.</a></p>
    </StyledFooter>

}

const StyledFooter = styled.div`


  position: absolute;
  bottom: 0;
  width: 100%;

  p {
    text-align: center;
    font-size: 10px;
    line-height: 32px;
    
    a {
      font-size: inherit;
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }

`;