import styled from "styled-components";
import React from "react";

interface Props {
    activeTab: number;
    setActiveTab: (tab: number) => void;
    values: number[];
}

export const BadBacteriaTabs = ({activeTab, setActiveTab, values}: Props) => {

    const onClick = (e: React.MouseEvent<HTMLLIElement>, i: number) => {
        e.preventDefault();
        setActiveTab(i);
    }

    const tabLabels: JSX.Element[] = [
        <>Clostridium<br/>perfringens</>,
        <>Clostridiumi<br/>difficile</>,
        <>Campylobacter<br/>jejuni</>,
        <>Campylobacter<br/>coli</>,
        <>Escherichia<br/>coli</>,
        <>Staphylocccus<br/>aureus</>,
        <>Pseudomonas<br/>aeruginosa</>,
        <>Clostridium<br/>botulinum</>,
        <>Brucella<br/>canis</>,
        <>Listeria<br/>monocytogenes</>,
        <>Vibrio<br/>parahaemolyticus</>,
    ];

    let diff = "";
    let diffCls = "";

    if (values.length >= 2) {
        // 推移を取得
        const diffValue = values[0] - values[1];
        if (diffValue < 0) {
            diff = `${Math.floor(diffValue * 100) / 100}%`;
            diffCls = "green";
        } else if (diffValue > 0) {
            diff = `+${Math.floor(diffValue * 100) / 100}%`;
            diffCls = "red";
        }
    }

    return <StyledBadBacteriaTabs>
        {tabLabels.map((d, i) => {

            if (activeTab === i + 1) {
                return <li key={`tab-${i}`} className="active" onClick={(e) => onClick(e, i + 1)}>{d}{diff !== "" ? <div className={diffCls}>{diff}</div> : null}</li>
            } else {
                return <li key={`tab-${i}`} onClick={(e) => onClick(e, i + 1)}>{d}</li>
            }

        })}
    </StyledBadBacteriaTabs>

}

const StyledBadBacteriaTabs = styled.ul`
    display: flex;
    list-style-type: none;
    align-items: flex-end;

    li {
        width: calc(100% / 11);
        font-size: 11px;
        text-align: center;
        background-color: #EFEFEF;
        border-right: 1px solid #fff;
        padding: 10px 0;
        line-height: 1.2;
        cursor: pointer;

        &.active {
            border-top: 5px solid #707070;
            background-color: #fff;
            font-size: 15px;
            border-right: 1px solid #707070;
            border-left: 1px solid #707070;
        }

        > div {
            margin-top: 6px;
            font-size: 16px;
            
            &.red {
                color: #D93E4C;
            }
            
            &.green {
                color: #00BFA5;
            }
        }
        
    }

`;