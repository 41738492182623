import {Layout} from "../common/Layout";
import {H1, H3, H4} from "../common/Headline";
import React, {useContext, useEffect} from "react";
import {adminPpTestKitApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";
import {TypesPpTestKitData} from "../../generated";
import {useNavigate, useParams} from "react-router-dom";
import {PartsPetDetail} from "../pp_pet/PartsPetDetail";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {InterviewSteps} from "./interview/InterviewSteps";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {SlashedYmd} from "../../utility/Utility";
import {Condition} from "./confirm/Condition";
import {Habit} from "./confirm/Habit";

export const PpTestKitConfirm = () => {

    const params = useParams<{ sampleId: string }>();
    const navigate = useNavigate();
    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppTestKit, setPpTestKit] = React.useState<TypesPpTestKitData | null>(null);

    useEffect(() => {

        if (params.sampleId) {
            getPpTestKit(params.sampleId);
        }

    }, []);

    const getPpTestKit = (sampleId: string) => {

        setShowSpinner(true);

        adminPpTestKitApi.v1AdminPpTestKitGetSampleIdGet(sampleId)
            .then(({data}) => {
                setPpTestKit(data.pp_test_kit ?? null);

                if (!data.pp_test_kit.pp_pet) {
                    setDangerMessage("ご指定の検体IDにはまた申し込みがされていません。");
                    navigate(`/pp_test_kit`);
                }

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

                navigate(`/pp_test_kit`);
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    if (!ppTestKit || !ppTestKit.pp_pet) {
        return <Layout title="検査申込管理"/>
    }

    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/pp_test_kit/step2/${ppTestKit.sample_id}`);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        setShowSpinner(true);

        adminPpTestKitApi.v1AdminPpTestKitConfirmSampleIdPost(ppTestKit.sample_id)
            .then(({data}) => {
                setPpTestKit(data.pp_test_kit ?? null);
                navigate(`/pp_test_kit/complete/${ppTestKit.sample_id}`);
            })
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setDangerMessage("ご入力内容にエラーがありました。");
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    return <Layout title="検査申込管理">

        <StyledPpTestKitConfirm onSubmit={onSubmit}>

            <H1 title="ペット情報の新規登録" subtitle="registration"/>

            <InterviewSteps offset={2}/>

            <p className="confirm-note">入力情報にお間違いがなければ、申し込み完了ボタンを押してください。</p>

            <div className="box mb64">
                <H3 title={"ペットの情報"} subtitle="pet"/>
                <PartsPetDetail ppPet={ppTestKit.pp_pet}/>
            </div>

            <H4 title={"検査情報の入力"}/>

            <div className="box mb64">

                <H3 title="基本情報" subtitle="base data"/>

                <div className="form-wrapper">

                    <div className="form-group center">
                        <label className="req">検体ID</label>
                        <div>{ppTestKit.sample_id}</div>
                    </div>

                    <div className="form-group center">
                        <label className="req">体重</label>
                        <div>{ppTestKit.is_pet_weight}kg</div>
                    </div>

                    <div className="form-group center">
                        <label className="req">検体採取日</label>
                        <div>{SlashedYmd(ppTestKit.sampling_date ?? "")}</div>
                    </div>

                </div>

            </div>

            <H4 title={"基礎情報の入力"}/>

            <div className="box mb53">
                <H3 title="体調について" subtitle="condition"/>

                <Condition ppTestKit={ppTestKit}/>
            </div>

            <div className="box mb53">
                <H3 title="生活習慣について" subtitle="condition"/>

                <Habit ppTestKit={ppTestKit}/>

                <div className="form-group center">
                    <label className="req">同意</label>
                    <div>検査、研究への参加に同意する</div>
                </div>

            </div>

            <div className="btn-area">
                <Button color={ButtonColor.Gray} icon={ButtonIcon.Arrow} type="button" onClick={onClickBack}>前の画面に戻る</Button>
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>申し込み完了</Button>
            </div>

        </StyledPpTestKitConfirm>

    </Layout>

}

const StyledPpTestKitConfirm = styled(StyledForm)`

  .confirm-note {
    color: #fff;
    font-size: 18px;
    background-color: rgb(3, 171, 144);
    line-height: 58px;
    margin: 0 -40px 30px -40px;
    padding: 0 50px;
  }

`;
