import styled from "styled-components";
import React, {useContext, useEffect, useState} from "react";
import {adminAuthApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";
import {ErrorMessage} from "../common/ErrorMessage";
import {NavLink, useNavigate} from "react-router-dom";
import logo from "../../images/logo-white.svg";
import btnLogin from "../../images/btn-login.svg";
import {MainColor} from "../common/Colors";
import {Topics} from "./Topics";
import {SetPageTitle} from "../../utility/Utility";
import {PasswordInput} from "../common/PasswordInput";
import {AdminAuthControllerLoginRequest, SephirahModulesSephirahAuthCertificate} from "../../generated";

export const Login = () => {

    const {setShowSpinner, setDangerMessage, setToken, ppMstUserAdmin, ppMstUserPartner, setPpMstUserAdmin, setPpMstUserPartner} = useContext(AppContext);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [failed, setFailed] = useState<string>("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const navigate = useNavigate();

    SetPageTitle("");

    useEffect(() => {

        // ログイン状態チェック
        checkIsLoggedIn();

    }, []);

    // 自分自身取得
    const checkIsLoggedIn = () => {

        setShowSpinner(true);

        adminAuthApi.v1AdminAuthMeGet()
            .then(({data}) => {
                setPpMstUserAdmin(data.pp_mst_user_admin ?? null);
                setPpMstUserPartner(data.pp_mst_user_partner ?? null);
            })
            .catch(() => {
                // 取れなくてもok
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    // 変更ハンドラー
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

        switch (e.currentTarget.name) {
            case "email":
                setEmail(e.currentTarget.value);
                break;
            case "password":
                setPassword(e.currentTarget.value);
                break;
        }
    }

    // 送信ハンドラー
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const req: AdminAuthControllerLoginRequest = {
            email: email,
            password: password,
        }

        setErrors({});
        setShowSpinner(true);
        setFailed("");

        adminAuthApi.v1AdminAuthLoginPost(req)
            .then(({data}) => {
                setToken(data.sephirahAuthCertificate ?? null);
                setDangerMessage(null);
                navigate("/");
            })
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setFailed(err.response.data.message);
                } else if (err.response.data && err.response.data.sephirahAuthCertificate) {

                    const data = err.response.data.sephirahAuthCertificate as SephirahModulesSephirahAuthCertificate;

                    switch (data.result) {
                        // ログイン時に発生するのは、1、9のみと思われる
                        case "1":
                        case "9":
                            setFailed("メールアドレスまたはパスワードが間違っています");
                            break;
                        default:
                            setFailed("システムエラーが発生しました。");
                            break;
                    }
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    if (ppMstUserAdmin || ppMstUserPartner) {
        // ログイン済みの場合はトップページに遷移
        navigate("/");
        return null;
    }

    return (
        <StyledLogin>

            <form onSubmit={onSubmit}>

                <h1>PiTPET</h1>

                <Topics/>

                <div className="form-wrapper">

                    {failed && <p className="login-failed">{failed}</p>}

                    <div className="form-group">
                        <label>メールアドレス</label>
                        <div>
                            <input type="text" name="email" value={email} onChange={onChange}/>
                            <ErrorMessage message={errors["email"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>パスワード</label>
                        <div>
                            <PasswordInput name="password" value={password} onChange={onChange}/>
                            <ErrorMessage message={errors["password"]}/>
                        </div>
                    </div>

                    <button className="btn-login">ログイン</button>

                    <div className="links">
                        <NavLink to={"/forgot"}>パスワードを忘れた方</NavLink>
                    </div>

                </div>

            </form>

        </StyledLogin>
    );
};

export const StyledLogin = styled.div`
  min-height: 100vh;

  form {
    padding-top: 50px;
    width: 880px;
    margin: auto;

    h1 {
      background-image: url(${logo});
      background-repeat: no-repeat;
      width: 100%;
      height: 57px;
      background-position: center center;
      background-size: auto 100%;
      text-indent: 100%;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 40px;
    }

    .form-wrapper {
      background-color: #fff;
      border-radius: 16px;
      padding: 84px 80px 50px 80px;

      .login-failed {
        color: #fff;
        background-color: rgba(217, 62, 76, 0.8);
        font-size: 18px;
        padding: 23px;
        margin-bottom: 40px;
        white-space: pre-line;
      }

      .form-group {
        margin-bottom: 40px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;

        label {
          width: 156px;
          background-color: ${MainColor};
          line-height: 52px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          color: #fff;
          padding-left: 14px;
          margin-right: 16px;
          font-size: 18px;
        }

        > div {
          flex: 1;
          width: 100%;

          input {
            display: block;
            width: 100%;
            line-height: 52px;
            background-color: #EFEFEF;
            border: none;
            padding: 0 10px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }

        }
      }

      button {
        background-color: ${MainColor};
        border: none;
        border-radius: 48px;
        height: 48px;
        width: 200px;
        color: #fff;
        display: block;
        margin: auto;
      }

      button.btn-login {
        text-indent: 100%;
        width: 150px;
        height: 150px;
        background-image: url(${btnLogin});
        white-space: nowrap;
        overflow: hidden;
        background-color: transparent;
        border: none;
        background-size: 100% 100%;
        margin: auto;
        display: block;
      }

      .links {
        text-align: right;
        padding-top: 10px;

        a {
          font-size: 14px;
          color: #666;
        }
      }

      p {
        color: #999;
        line-height: 1.7;
        margin-bottom: 40px;
      }
    }
  }
`;