import {useContext, useEffect} from "react";
import {AppContext} from "../../contexts/AppContext";
import {useNavigate} from "react-router-dom";
import {adminAuthApi} from "../../api/Api";

export const Logout = () => {

    const {setToken, setPpMstUserAdmin} = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {

        getLogout();

    }, []);

    const getLogout = () => {

        adminAuthApi.v1AdminAuthLogoutGet()
            .then(() => {
                // 何もしない
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                // エラーでも正常でもトークンを削除
                setToken(null);
                setPpMstUserAdmin(null);
                navigate("/login");
            });
    };

    return null;


};