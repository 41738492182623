import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {Table} from "../common/Table";
import {GetAddressStr, ResultSendTargets, SlashedYmd} from "../../utility/Utility";
import {H3} from "../common/Headline";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Links} from "../common/Links";
import {Paginator} from "../common/Paginator";
import {Counter} from "../common/Counter";
import {TypesPpMstUserMedicalData} from "../../generated";
import {adminPpMstUserMedicalApi} from "../../api/Api";

export const PpMstUserMedicalIndex = () => {

    const {setShowSpinner, setDangerMessage, setSuccessMessage} = useContext(AppContext);
    const [companies, setCompanies] = useState<TypesPpMstUserMedicalData[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const navigate = useNavigate();
    const location = useLocation();

    const size = 100;

    // URL変更時
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get("page")) {
            setPage(Number(params.get("page")));
        }
    }, [location]);

    // ページ変更時
    useEffect(() => {
        getCompanies();
    }, [page]);

    const getCompanies = () => {

        setShowSpinner(true);

        adminPpMstUserMedicalApi.v1AdminPpMstUserMedicalGet("", page, size)
            .then(({data}) => {
                setCompanies(data.pp_mst_user_medicals ?? []);
                setTotal(data.total ?? 0);
            })
            .catch((err) => {
                console.log(err);
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onDelete = (e: React.MouseEvent<HTMLSpanElement>, company: TypesPpMstUserMedicalData): void => {

        e.preventDefault();

        if (!window.confirm(`${company.medic_inst_name} の病院情報を削除します。よろしいですか？`)) {
            return;
        }

        setShowSpinner(true);
        adminPpMstUserMedicalApi.v1AdminPpMstUserMedicalDeleteIdDelete(String(company.id))
            .then(() => {
                setSuccessMessage("病院を削除しました。");
                getCompanies();
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onPageChange = (page: number): void => {
        navigate(`/companies?page=${page}`);
    };

    return <Layout title={"病院管理"}>

        <div className="box">

            <H3 title="病院一覧" subtitle="list" counter={<Counter total={total} size={size} current={page}/>}>
                <Links>
                    <NavLink to={"./create"}>新規追加</NavLink>
                </Links>
            </H3>

            <Table>

                <thead>
                <tr>
                    <th>ID</th>
                    <th>登録日</th>
                    <th>病院名</th>
                    <th>住所</th>
                    <th>TEL</th>
                    <th>結果メール</th>
                    <th>編集</th>
                    <th>削除</th>
                </tr>
                </thead>

                <tbody>
                {companies.map((d, i) => {

                    const address: string = GetAddressStr(d.pref_code, d.city, d.street, d.building);

                    return <tr key={`row-${i}`}>
                        <td>{d.id}</td>
                        <td>{SlashedYmd(d.created ?? "")}</td>
                        <td>{d.medic_inst_name}</td>
                        <td>{address !== "" ? address : "-"}</td>
                        <td>{d.tel_no ? d.tel_no : "-"}</td>
                        <td>{ResultSendTargets[d.result_send_target ?? ""]}</td>
                        <td><NavLink to={`./edit/${d.id}`}>編集</NavLink></td>
                        <td><span onClick={(e) => onDelete(e, d)}>削除</span></td>
                    </tr>

                })}
                </tbody>

            </Table>

            <Paginator onClick={onPageChange} pages={Math.ceil(total / size)} page={page}/>

        </div>

    </Layout>

};
