import {MustAuth} from "./MustAuth";
import React from "react";
import {SetPageTitle} from "../../utility/Utility";
import {Menu} from "./Menu";
import styled from "styled-components";
import {Header} from "./Header";

interface Props extends React.PropsWithChildren {
    title: string;
}

export const Layout = ({title, children}: Props) => {

    SetPageTitle(title);

    return <MustAuth>

        <StyledLayout>

            <Menu/>

            <main>

                <Header title={title}/>

                {children}
            </main>

        </StyledLayout>

    </MustAuth>

};

const StyledLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  align-items: flex-start;
  min-width: 1440px;

  main {
    flex: 1;
    padding: 0 40px 120px 40px;

    // よく使う白背景ボックス
    .box {
      border-radius: 4px;
      background-color: #fff;
      padding: 24px;
      box-shadow: 0 0 2px rgba(0, 0, 0, .1);
      margin: 0 0 46px 0;
    }
  }
`;