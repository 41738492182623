import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import React, {useEffect, useRef, useState} from "react";
import {H2} from "../common/Headline";
import {useLocation, useNavigate} from "react-router-dom";

enum SearchType {
    FlowCellId = 1,
    SampleId = 2,
    WellplateId = 3,
}

interface Props {
    onClear: () => void;
}

export const SearchForm = ({onClear}: Props) => {

    const [sampleId, setSampleId] = useState<string>("");
    const [flowcellId, setFlowcellId] = useState<string>("");
    const [wellplateId, setWellplateId] = useState<string>("");
    const [searchType, setSearchType] = useState<SearchType>(SearchType.FlowCellId);
    const [showError, setShowError] = useState<boolean>(false);
    const ref = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);


    useEffect(() => {

        const params = new URLSearchParams(location.search);

        const flowcellId = params.get("flowcell_id") ?? "";
        const sampleId = params.get("sample_id") ?? "";
        const wellplateId = params.get("wellplate_id") ?? "";

        if (flowcellId !== "") {
            console.log(1);
            setFlowcellId(flowcellId);
            setSampleId("");
            setWellplateId("");
            setSearchType(SearchType.FlowCellId);
        }

        if (sampleId !== "") {
            console.log(2);
            setFlowcellId("");
            setSampleId(sampleId);
            setWellplateId("");
            setSearchType(SearchType.SampleId);
        }

        if (wellplateId !== "") {
            console.log(3);
            setFlowcellId("");
            setSampleId("");
            setWellplateId(wellplateId);
            setSearchType(SearchType.WellplateId);
        }

    }, [location.search]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.currentTarget.name) {
            case "flowcell_id":
                setFlowcellId(e.currentTarget.value);
                setShowError(false);
                break;
            case "sample_id":
                setSampleId(e.currentTarget.value);
                setShowError(false);
                break;
            case "wellplate_id":
                setWellplateId(e.currentTarget.value);
                setShowError(false);
                break;
            case "search_type":
                console.log(e.currentTarget.value)
                if (e.currentTarget.value === "1") {
                    setSearchType(SearchType.FlowCellId);
                    setSampleId("");
                    setWellplateId("");
                } else if (e.currentTarget.value === "2") {
                    setSearchType(SearchType.SampleId);
                    setFlowcellId("");
                    setWellplateId("");
                } else if (e.currentTarget.value === "3") {
                    setSearchType(SearchType.WellplateId);
                    setFlowcellId("");
                    setSampleId("");
                }
                break;
        }
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        // 未入力ならエラーを表示
        if (flowcellId === "" && sampleId === "" && wellplateId === "") {
            setShowError(true);
        } else {
            navigate(`/reference?flowcell_id=${flowcellId}&sample_id=${sampleId}&wellplate_id=${wellplateId}`);
        }
    };

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        setSearchType(SearchType.FlowCellId);
        setFlowcellId("");
        setWellplateId("");
        setSampleId("");
        onClear();
        navigate("/reference");

        if (ref.current) {
            ref.current.focus();
        }
    }

    const onFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.currentTarget.select();
    };

    return <StyledSearchForm noValidate={true} onSubmit={onSubmit}>

        <div className="box">

            <H2 title="検体ID読み取り" subtitle="input"/>

            <div className="search-form">

                <div className="row">

                    <div className="col">

                        <div className="form-group">
                            <label>
                                <input type="radio" name="search_type" value="1" checked={searchType === SearchType.FlowCellId} onChange={onChange}/>
                                Flow Cell ID
                            </label>
                            <div>
                                <input type="text" name="flowcell_id" placeholder={"例：ABC12345"} value={flowcellId} onChange={onChange} maxLength={8} disabled={searchType !== SearchType.FlowCellId} ref={ref} onFocus={onFocus}/>
                            </div>

                        </div>

                        <div className="form-group">
                            <label>
                                <input type="radio" name="search_type" value="3" checked={searchType === SearchType.WellplateId} onChange={onChange}/>
                                PCRプレート番号
                            </label>
                            <div>
                                <input type="text" name="wellplate_id" placeholder={"例：A1234567"} value={wellplateId} onChange={onChange} maxLength={8} disabled={searchType !== SearchType.WellplateId} onFocus={onFocus}/>
                            </div>

                        </div>

                    </div>

                    <div className="col">

                        <div className="form-group">
                            <label>
                                <input type="radio" name="search_type" value="2" checked={searchType === SearchType.SampleId} onChange={onChange}/>
                                検体ID
                            </label>
                            <div>
                                <input type="text" name="sample_id" placeholder={"例：PC23-1000-NGS-1000"} value={sampleId} onChange={onChange} maxLength={18} disabled={searchType !== SearchType.SampleId} onFocus={onFocus}/>
                            </div>

                        </div>
                    </div>

                </div>

                {showError && <div className="search-error">IDを読み取ってください。</div>}


                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                    <Button color={ButtonColor.Orange} type="submit" icon={ButtonIcon.Arrow}>検索</Button>
                </div>

            </div>

        </div>

    </StyledSearchForm>
};

export const StyledSearchForm = styled(StyledForm)`

  .row {
    display: flex;
    align-items: flex-start;

    .col:first-child {
      .form-group:last-child {
        label {
          font-size: 12px;
        }
      }
    }

    .col:last-child {
      .form-group {
        label {
          width: 85px;
        }
      }
    }
  }

  .search-form {
    position: relative;

    .form-group {
      position: relative;

      label {
        width: 130px;
        margin-right: 0;
        display: flex;
        align-items: center;

        input[type="radio"] {
          margin: 0 10px 0 0;
        }
      }

      .search-error {
        color: #fff;
        background-color: #D93E4C;
        font-size: 14px;
        line-height: 1.8;
        display: inline-block;
        position: absolute;
        padding: 5px 10px;
        border-radius: 6px;
        width: 400px;
        top: 10px;
        left: 470px;

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: -18px;
          top: 50%;
          transform: translateY(-50%);
          border: 10px solid transparent;
          border-right: 10px solid #D93E4C;
        }

        &.no-input {
          top: 45px;
          width: 200px;
        }
      }

    }

    .btn-area {
      button {
        width: 220px;
      }
    }

  }

  .search-error {
    color: #fff;
    background-color: #D93E4C;
    font-size: 14px;
    line-height: 40px;
    display: inline-block;
    position: absolute;
    padding: 5px 10px;
    border-radius: 6px;
    width: 200px;
    top: 7px;
    left: 900px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -18px;
      top: 50%;
      transform: translateY(-50%);
      border: 10px solid transparent;
      border-right: 10px solid #D93E4C;
    }
  }

`;