import React from "react";

interface Props {
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean;
}

export const YesNoUnknown = ({name, value, onChange, disabled}: Props) => {

    return <div className="check">
        <label>
            <input type="radio" value="1" name={name} onChange={onChange} checked={value === "1"} disabled={disabled}/>
            はい
        </label>
        <label>
            <input type="radio" value="2" name={name} onChange={onChange} checked={value === "2"} disabled={disabled}/>
            いいえ
        </label>
        <label>
            <input type="radio" value="0" name={name} onChange={onChange} checked={value === "0"} disabled={disabled}/>
            不明
        </label>
    </div>

}