import {TypesPpUserData} from "../../generated";
import {CreateJaYmd, GetPersonGender, GetPrefectureName} from "../../utility/Utility";
import React from "react";

interface Props {
    ppUser: TypesPpUserData;
}

export const PartsPpUserDetail = ({ppUser}: Props) => {

    return <div className="form-wrapper">

        <div className="form-group center">
            <label>お名前</label>
            <div>
                姓：{ppUser.user_family_name} &nbsp; &nbsp; 名：{ppUser.user_first_name}
            </div>
        </div>

        <div className="form-group center">
            <label>お名前（フリガナ）</label>
            <div>
                セイ：{ppUser.user_family_furigana} &nbsp; &nbsp; メイ：{ppUser.user_first_furigana}
            </div>
        </div>

        <div className="form-group center">
            <label>メールアドレス</label>
            <div>
                {ppUser.email}
            </div>
        </div>

        <div className="form-group center">
            <label>パスワード</label>
            <div>
                ********
            </div>
        </div>

        <div className="form-group center">
            <label>生年月日</label>
            <div>
                {CreateJaYmd(ppUser.birthday ?? "")}
            </div>
        </div>

        <div className="form-group center">
            <label>性別</label>
            <div>
                {GetPersonGender(ppUser.gender ?? "")}
            </div>
        </div>

        <div className="form-group center">
            <label>郵便番号</label>
            <div>
                {ppUser.postal_code}
            </div>
        </div>

        <div className="form-group center">
            <label>都道府県</label>
            <div>
                {GetPrefectureName(ppUser.pref_code)}
            </div>
        </div>

        <div className="form-group center">
            <label>市区町村</label>
            <div>
                {ppUser.city}
            </div>
        </div>

        <div className="form-group center">
            <label>丁目・番地</label>
            <div>
                {ppUser.street}
            </div>
        </div>

        <div className="form-group center">
            <label>建物名</label>
            <div>
                {ppUser.building !== "" ? ppUser.building : "-"}
            </div>
        </div>

        <div className="form-group center">
            <label>電話番号</label>
            <div>
                {ppUser.tel_no}
            </div>
        </div>

    </div>


};