import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {H2} from "../common/Headline";
import rightOrange2 from "../../images/right-orange2.svg";
import React, {useEffect, useState} from "react";
import {GetAuthorityLevels} from "../../utility/Utility";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {PartnerInstInput} from "../common/PartnerInstInput";

export const SearchForm = () => {

    const [partnerInstId, setPartnerInstId] = useState<string | null>(null);
    const [authorityLevel, setAuthorityLevel] = useState<string>("");
    const [identificationCd, setIdentificationCd] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        setPartnerInstId(params.get("partner_inst_id") ?? "");
        setAuthorityLevel(params.get("authority_level") ?? "");
        setIdentificationCd(params.get("identification_cd") ?? "");
        setDescription(params.get("description") ?? "");

    }, [location.search]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        switch (e.currentTarget.name) {
            case "partner_inst_id":
                setPartnerInstId(e.currentTarget.value);
                break;
            case "authority_level":
                setAuthorityLevel(e.currentTarget.value);
                break;
            case "identification_cd":
                setIdentificationCd(e.currentTarget.value);
                break;
            case "description":
                setDescription(e.currentTarget.value);
                break;
        }
    }

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/pp_mst_identification_cd`);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const params = new URLSearchParams();
        params.append("partner_inst_id", partnerInstId ?? "");
        params.append("authority_level", authorityLevel);
        params.append("identification_cd", identificationCd);
        params.append("description", description);
        params.append("page", "1");

        navigate(`/pp_mst_identification_cd?${params.toString()}`);
    }

    const onClickCreate = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/pp_mst_identification_cd/create`);
    };

    const authorityLevels = GetAuthorityLevels();
    const authorityLevelKeys = Object.keys(authorityLevels);

    return <StyledSearchForm onSubmit={onSubmit}>

        <div className="box">

            <H2 title="検索情報の入力" subtitle="search"/>

            <div className="row">

                <div className="left">

                    <h5>情報を検索する</h5>

                    <div className="search-form">

                        <div className="form-group">
                            <label>提携法人</label>

                            <PartnerInstInput name="partner_inst_id" onChange={(name: string, n: number | null) => {
                                console.log(name); // linter対応なので本来は不要
                                setPartnerInstId(n ? String(n) : "");
                            }} value={partnerInstId ? Number(partnerInstId) : null}/>

                        </div>

                        <div className="form-group">
                            <label>アクセス権限レベル</label>
                            <select name="authority_level" value={authorityLevel} onChange={onChange} className="w240">
                                <option value="">---選択してください</option>
                                {authorityLevelKeys.map((d) => {
                                    return <option value={d} key={`al-${d}`}>{authorityLevels[d]}</option>
                                })}
                            </select>
                        </div>

                        <div className="flex">
                            <div className="form-group">
                                <label>識別コード</label>
                                <div>
                                    <input type="text" name="identification_cd" placeholder="例：TS123456" value={identificationCd} onChange={onChange}/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>説明</label>
                                <div>
                                    <input type="text" name="description" placeholder="例：Proumed Lab 本社" value={description} onChange={onChange}/>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="right">
                    <h5>情報を新規登録する</h5>
                    <div>
                        <div className="btn-area">
                            <Button color={ButtonColor.Orange} type="button" icon={ButtonIcon.Arrow} onClick={onClickCreate}>新規登録</Button>
                        </div>
                    </div>
                </div>

            </div>

            <div className="btn-area mt40">
                <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                <Button color={ButtonColor.Orange} type="submit" icon={ButtonIcon.Arrow}>検索</Button>
            </div>

        </div>


    </StyledSearchForm>

}

const StyledSearchForm = styled(StyledForm)`

  .row {
    display: flex;
    justify-content: space-between;

    h5 {
      background-color: #EFEFEF;
      font-weight: 400;
      line-height: 36px;
      font-size: 14px;
      padding: 0 10px 0 30px;
      background-image: url(${rightOrange2});
      background-repeat: no-repeat;
      background-position: left 15px top 50%;
    }

    .left {
      flex: 1;
      margin-right: 23px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border: 1px solid #EFEFEF;

      .search-form {
        padding: 22px;

        &.error {
          position: relative;

          input, select {
            background-color: #F7D7DA;
          }
        }

        .error {
          background-color: #D93E4C;
          color: #fff;
          line-height: 47px;
          display: block;
          position: absolute;
          top: 25px;
          left: 450px;
          padding: 0 16px;
          border-radius: 4px;

          &:before {
            position: absolute;
            border: 8px solid transparent;
            border-right: 8px solid #D93E4C;
            top: 50%;
            left: -16px;
            transform: translateY(-50%);
            content: "";
            display: block;
          }
        }

      }

      .form-group {
        padding: 6px 0;
        margin-bottom: 0;

        > div {
          width: 240px;
          flex: 0;
        }
      }

      .flex {
        display: flex;

        .form-group {
          width: 50%;
        }
      }

      label {
        width: 150px;
        margin-right: 0;
      }

      input, select {
        width: 240px !important;
      }

    }

    .right {
      width: 338px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      overflow: hidden;
      border: 1px solid #EFEFEF;

      .btn-area {
        margin-top: 80px;

        button {
          width: 240px;
        }
      }
    }

  }

`