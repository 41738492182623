import React, {useContext} from "react";
import styled from "styled-components";
import {MustAuth} from "../common/MustAuth";
import logoWhite from "../../images/logo-white.svg";
import {CommonColor, LaboratoryColor, LightCommonColor, LightLaboratoryColor, LightOperatorColor, OperatorColor} from "../common/Colors";
import menu01 from "../../images/menu-01.svg";
import menu02 from "../../images/menu-02.svg";
import menu03 from "../../images/menu-03.svg";
import menu04 from "../../images/menu-04.svg";
import menu05 from "../../images/menu-05.svg";
import menu06 from "../../images/menu-06.svg";
import menu07 from "../../images/menu-07.svg";
import menu08 from "../../images/menu-08.svg";
import menu09 from "../../images/menu-09.svg";
import menu10 from "../../images/menu-10.svg";
import menu11 from "../../images/menu-11.svg";
import {NavLink} from "react-router-dom";
import rightOperator from "../../images/right-operator.svg";
import rightLaboratory from "../../images/right-laboratory.svg";
import rightCommon from "../../images/right-common.svg";
import {SetPageTitle} from "../../utility/Utility";
import {AppContext} from "../../contexts/AppContext";
import {AccesstokenRole} from "../../generated";
import {LoginName} from "./LoginName";
import {Topics} from "../auth/Topics";

export const Home = () => {

    const {role} = useContext(AppContext);

    SetPageTitle("ホーム");

    return <MustAuth>

        <StyledHome>

            <NavLink to={"/logout"} className="logout">ログアウト</NavLink>

            <h1>PiTPET</h1>

            <LoginName/>

            <div className="menus">


                {[String(AccesstokenRole.RoleOperator), String(AccesstokenRole.RoleMaster)].indexOf(String(role ?? "")) !== -1 && <React.Fragment>
                    <div className="operator menu">
                        <h3>システム運用</h3>

                        <ul>
                            <li><NavLink to={"/sample_id"}>検体ID情報管理</NavLink></li>
                            <li><NavLink to={"/forced_test"}>強制検査受付</NavLink></li>
                            <li><NavLink to={"/system"}>システム設定</NavLink></li>
                        </ul>
                    </div>
                </React.Fragment>}

                {[String(AccesstokenRole.RoleMaster), String(AccesstokenRole.RoleOperator), String(AccesstokenRole.RoleLaboratory)].indexOf(String(role ?? "")) !== -1 && <React.Fragment>
                    <div className="laboratory menu">

                        <h3>検査技師</h3>

                        <ul>
                            <li><NavLink to={"/reception"}>検査受付</NavLink></li>
                            <li><NavLink to={"/metadata"}>メタデータ取得</NavLink></li>
                            <li><NavLink to={"/result"}>検査結果登録</NavLink></li>
                            <li><NavLink to={"/reference"}>試験番号情報照会</NavLink></li>
                        </ul>

                    </div>
                </React.Fragment>}

                <div className="common menu">

                    <h3>共通メニュー</h3>

                    <ul>
                        {[String(AccesstokenRole.RoleMaster), String(AccesstokenRole.RoleCs), String(AccesstokenRole.RoleOperator), String(AccesstokenRole.RoleLaboratory), String(AccesstokenRole.RoleMedical), String(AccesstokenRole.RolePartner)].indexOf(String(role ?? "")) !== -1 && <li><NavLink to={"/pp_test_status"}>検査情報照会</NavLink></li>}
                        {[String(AccesstokenRole.RoleMaster), String(AccesstokenRole.RoleCs), String(AccesstokenRole.RoleOperator), String(AccesstokenRole.RoleMedical)].indexOf(String(role ?? "")) !== -1 && <li><NavLink to={"/pp_user"}>会員検索</NavLink></li>}
                        {[String(AccesstokenRole.RoleMaster), String(AccesstokenRole.RoleOperator), String(AccesstokenRole.RoleMedical)].indexOf(String(role ?? "")) !== -1 && <li><NavLink to={"/pp_user/create"}>会員情報登録</NavLink></li>}
                        {[String(AccesstokenRole.RoleMaster), String(AccesstokenRole.RoleOperator), String(AccesstokenRole.RoleMedical)].indexOf(String(role ?? "")) !== -1 && <li><NavLink to={"/pp_test_kit"}>検査申込管理</NavLink></li>}
                    </ul>

                </div>

            </div>

            <Topics/>

        </StyledHome>


    </MustAuth>
};

const MenuHeight = 48;

const StyledHome = styled.div`

  padding: 50px 0;
  width: 880px;
  margin: auto;
  position: relative;

  .logout {
    position: absolute;
    right: 0;
    color: #fff;
    top: 60px;
    text-decoration: none;
    font-size: 12px;

    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    width: 200px;
    height: 57px;
    background-repeat: no-repeat;
    background-image: url(${logoWhite});
    background-size: auto 100%;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    margin: 0 auto 40px auto;
  }

  .menus {
    display: flex;
    margin: 0 auto 56px auto;
    justify-content: center;

    .menu {
      width: 272px;
      margin-right: 32px;
      background-color: #fff;
      border-radius: 8px;
      padding: 16px;

      &:last-child {
        margin-right: 0;
      }

      h3 {
        background-color: ${LightOperatorColor};
        font-size: 16px;
        text-align: center;
        font-weight: normal;
        line-height: 32px;
        border-radius: 4px;
        margin-bottom: 16px;
      }

      ul {
        list-style-type: none;

        li {
          margin-bottom: 16px;
          line-height: ${MenuHeight}px;
          border-radius: ${MenuHeight}px;
          background-color: ${OperatorColor};
          border: 1px solid ${OperatorColor};
          padding-left: 48px;
          font-size: 16px;
          position: relative;
          z-index: 1;
          overflow: hidden;
          background-repeat: no-repeat;
          background-position: top 50% left 18px;

          a {
            background-color: #fff;
            display: block;
            line-height: ${MenuHeight}px;
            padding-left: 14px;
            text-decoration: none;
            background-image: url(${rightOperator});
            background-repeat: no-repeat;
            background-position: top 50% right 14px;
            background-size: 6px auto;

            &:hover {
              text-decoration: underline;
            }
          }

        }
      }

      &.operator {
        ul {

          li a {
            color: ${OperatorColor};
          }

          li:nth-child(1) {
            background-image: url(${menu01});
          }

          li:nth-child(2) {
            background-image: url(${menu02});
            background-size: 18px auto;
          }

          li:nth-child(3) {
            background-image: url(${menu10});
            background-size: 20px auto;
          }
        }
      }

      &.laboratory {
        h3 {
          background-color: ${LightLaboratoryColor};
        }

        ul {

          li {
            background-color: ${LaboratoryColor};
            border-color: ${LaboratoryColor};

            a {
              background-image: url(${rightLaboratory});
              color: ${LaboratoryColor};
            }
          }

          li:nth-child(1) {
            background-position: top 50% left 22px;
            background-image: url(${menu03});
          }

          li:nth-child(2) {
            background-image: url(${menu04});
          }

          li:nth-child(3) {
            background-image: url(${menu05});
          }

          li:nth-child(4) {
            background-image: url(${menu11});
          }
        }
      }


      &.common {
        h3 {
          background-color: ${LightCommonColor};
        }

        ul {
          li {
            background-color: ${CommonColor};
            border-color: ${CommonColor};

            a {
              background-image: url(${rightCommon});
              color: ${CommonColor};
            }
          }

          li:nth-child(1) {
            background-image: url(${menu06});
          }

          li:nth-child(2) {
            background-image: url(${menu07});
          }

          li:nth-child(3) {
            background-image: url(${menu08});
          }

          li:nth-child(4) {
            background-image: url(${menu09});
          }
        }
      }
    }
  }

`;