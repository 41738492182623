import {useContext} from "react";
import {AppContext} from "../../contexts/AppContext";
import styled from "styled-components";
import {MainColor} from "../common/Colors";

export const LoginName = () => {

    const {ppMstUserAdmin, ppMstUserPartner} = useContext(AppContext);

    let id = 0;
    let name = "";

    if (ppMstUserAdmin !== null) {
        id = ppMstUserAdmin.id ?? 0;
        name = ppMstUserAdmin.name ?? "";
    }

    if (ppMstUserPartner !== null) {
        id = ppMstUserPartner.id ?? 0;
        name = ppMstUserPartner.name ?? "";
    }

    return <StyledLoginName>
        <div className="id">
            <span>ID</span>
            <span>{id}</span>
        </div>
        <span className="name">{name}</span>
        {ppMstUserPartner && ppMstUserPartner.identification_cd && <div className="identification_cd">
            <span>識別コード</span>
            <span>{ppMstUserPartner?.identification_cd}</span>
        </div>}

    </StyledLoginName>;
};

const StyledLoginName = styled.div`
  display: flex;
  width: 880px;
  margin: 0 auto 40px auto;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;

  .id {
    display: flex;
    margin-right: 1px;

    span:first-child {
      background-color: #F4E37D;
      font-size: 20px;
      color: ${MainColor};
      text-align: center;
      width: 50px;
      line-height: 40px;
    }

    span:last-child {
      width: 100px;
      text-align: center;
      color: ${MainColor};
      background-color: #fff;
      line-height: 40px;
      font-size: 16px;
    }
  }

  .role {
    padding: 0 10px;
    font-size: 16px;
    background-color: #fff;
    line-height: 40px;
  }

  .name {
    line-height: 40px;
    padding: 0 10px;
    flex: 1;
    background-color: #fff;
    font-size: 16px;
  }
  
  .identification_cd {
    display: flex;
    margin-right: 1px;

    span:first-child {
      background-color: #F4E37D;
      font-size: 16px;
      color: ${MainColor};
      text-align: center;
      line-height: 40px;
      padding: 0 15px;
    }

    span:last-child {
      padding: 0 15px;
      text-align: center;
      color: ${MainColor};
      background-color: #fff;
      line-height: 40px;
      font-size: 16px;
    }
  }
`;