import {Layout} from "../common/Layout";
import React, {useContext, useEffect, useState} from "react";
import {adminPpMstMeatureCommentApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";
import {PpMstMeatureCommentModelPagingRequest, TypesPpMstMeatureCommentSingleData} from "../../generated";
import {Table} from "../common/Table";
import {MeasureCategories, MeasureCommentPositions, Zerofill} from "../../utility/Utility";
import {H3} from "../common/Headline";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {MainColor} from "../common/Colors";
import rightWhite from "../../images/right-white.svg";
import {Modal, ModalColor} from "../result/Modal";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "../common/Button";
import {CompleteMessage} from "../common/CompleteMessage";
import {Counter} from "../common/Counter";
import {Paginator} from "../common/Paginator";

export const PpMstMeatureCommentsIndex = () => {

    const {setShowSpinner, setDangerMessage, setCompleteMessage} = useContext(AppContext);
    const [ppMstMeatureComments, setPpMstMeatureComments] = useState<TypesPpMstMeatureCommentSingleData[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [ppMstMeatureComment, setPpMstMeatureComment] = useState<TypesPpMstMeatureCommentSingleData | null>(null);
    const [isModal, setIsModal] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const size = 30;

    useEffect(() => {
        getPage();

        const params = new URLSearchParams(location.search);

        const complete = params.get("complete") ?? "";
        if (complete === "create") {
            setCompleteMessage("対策コメントを新規追加しました。");
        } else if (complete === "edit") {
            setCompleteMessage("対策コメントを編集しました。");
        }

    }, [location.search]);

    const getPage = () => {

        setShowSpinner(true);

        const params = new URLSearchParams(location.search);

        const req: PpMstMeatureCommentModelPagingRequest = {
            size: size,
            page: params.get("page") ? Number(params.get("page")) : 1,
        }

        adminPpMstMeatureCommentApi
            .v1AdminPpMstMeatureCommentPost(req)
            .then(({data}) => {
                setTotal(data.total);
                setPpMstMeatureComments(data.pp_mst_meature_comments ?? []);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });

    }

    const getCategory = (measureCategory: string): string => {
        let str = "";
        MeasureCategories.forEach((d) => {
            if (d.id === measureCategory) {
                str = `${d.id}：${d.name}`;
            }
        });

        return str;
    }

    const getPosition = (position: string): string => {
        let str = "";
        MeasureCommentPositions.forEach((d) => {
            if (d.id === position) {
                str = `${d.id}：${d.name}`;
            }
        });

        return str;
    }

    const onDeleteConfirm = (e: React.MouseEvent<HTMLSpanElement>, d: TypesPpMstMeatureCommentSingleData) => {
        e.preventDefault();
        setIsModal(true);
        setPpMstMeatureComment(d);
    }

    const onDeleteCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsModal(false);
        setPpMstMeatureComment(null);
    };

    const onDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        setShowSpinner(true);

        adminPpMstMeatureCommentApi
            .v1AdminPpMstMeatureCommentDeleteIdDelete(String(ppMstMeatureComment?.id))
            .then(() => {
                setCompleteMessage("対策コメントを削除しました。");
                setIsModal(false);
                getPage();
            })
            .catch((err) => {
                if (err.response.data && err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました。");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });

    };


    const onPageChange = (page: number) => {
        setPage(page);
        navigate(`/pp_mst_meature_comments?page=${page}`);
    }

    const onClickCreate = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate("/pp_mst_meature_comments/create");
    }

    return <Layout title={"対策コメント管理"}>

        <StyledPpMstMeatureCommentsIndex>

            <CompleteMessage/>

            <div className="box">

                <H3 title="コメント一覧" subtitle="list" counter={<Counter total={total} size={size} current={page}/>}>
                    <Button color={ButtonColor.Orange} size={ButtonSize.Small} icon={ButtonIcon.Arrow} onClick={onClickCreate}>新規追加</Button>
                </H3>

                <Table>

                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>対象カテゴリー</th>
                        <th>対策コメント表示位置コード</th>
                        <th>対策コメント内容</th>
                        <th>編集</th>
                        <th>削除</th>
                    </tr>
                    </thead>

                    <tbody>
                    {ppMstMeatureComments.map((d, i) => {

                        return <tr key={`row-${i}`}>
                            <td>{Zerofill(i + 1, 2)}</td>
                            <td>{getCategory(d.measure_category)}</td>
                            <td>{getPosition(d.measure_comment_position_id)}</td>
                            <td>{d.measure_comment_text !== "" ? d.measure_comment_text : "-"}</td>
                            <td style={{textAlign: "center"}}>
                                <NavLink to={`/pp_mst_meature_comments/edit/${d.id}`} className="btn-link">編集</NavLink>
                            </td>
                            <td style={{textAlign: "center"}}>
                                <span onClick={(e) => onDeleteConfirm(e, d)} className="btn-del">削除</span>
                            </td>
                        </tr>

                    })}
                    </tbody>

                </Table>

                <Paginator onClick={onPageChange} pages={Math.ceil(total / size)} page={page}/>

            </div>

            {isModal && ppMstMeatureComment && <Modal color={ModalColor.Danger}>
                <h4>対策コメントの削除</h4>
                <h5>以下のカテゴリのコメントを削除してよろしいですか？</h5>

                <div className="confirmation">
                    <span>対象カテゴリ</span>
                    <strong>{getCategory(ppMstMeatureComment.measure_category)}</strong>
                </div>

                <div className="btn-area">
                    <Button type="button" color={ButtonColor.Gray} icon={ButtonIcon.Arrow} onClick={onDeleteCancel}>いいえ</Button>
                    <Button type="button" color={ButtonColor.Danger} icon={ButtonIcon.Arrow} onClick={onDelete}>はい</Button>
                </div>
            </Modal>}

        </StyledPpMstMeatureCommentsIndex>

    </Layout>


}


const StyledPpMstMeatureCommentsIndex = styled.div`

    tr {
        td:nth-child(1) {
            text-align: center;
            width: 80px;
        }

        td:nth-child(4) {
            white-space: normal !important;
        }

        td:nth-child(5) {
            text-align: center !important;
            width: 120px;
        }
    }

    .btn-link, .btn-del {
        background-color: ${MainColor};
        color: #fff;
        font-size: 12px !important;
        line-height: 24px;
        display: inline-block;
        text-decoration: none !important;
        padding: 0 30px 0 20px;
        letter-spacing: 1px;
        border-radius: 24px;
        background-image: url(${rightWhite});
        background-repeat: no-repeat;
        background-position: top 50% right 15px;
        background-size: 5px auto;
    }

    .btn-del {
        background-color: #ccc;
    }


    .modal-inner {
        background-color: #fff;

        .confirmation {
            background-color: #fff;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: 22px 0 0 0;
            align-items: flex-end;
            margin: 0 20px;

            span {
                font-size: 14px;
                width: 50%;
                text-align: right;
                padding-right: 20px;
                margin-bottom: 20px;
            }

            strong {
                font-size: 24px;
                color: #FF9871;
                font-weight: 400;
                line-height: 1;
                width: 50%;
                margin-bottom: 20px;
            }
        }

        p {
            padding-top: 20px !important;

            span {
                background: linear-gradient(transparent 50%, rgba(217, 62, 76, 0.2) 20%);

                strong {
                    background: none !important;
                }
            }
        }
    }
`;
