import {StyledForm} from "../common/StyledForm";
import styled from "styled-components";
import {H2} from "../common/Headline";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import React, {useContext, useEffect, useRef, useState} from "react";
import rightOrange2 from "../../images/right-orange2.svg";
import {AppContext} from "../../contexts/AppContext";
import {CheckSampleId} from "../../utility/Utility";
import {adminPpTestKitApi} from "../../api/Api";
import {PpTestKitControllerFromTo} from "../../generated";

interface Props {
    onSearch: (values: PpTestKitControllerFromTo[], isFile: boolean) => void;
    onClear: () => void;
    onSet: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SearchForm = ({onSearch, onClear, onSet}: Props) => {

    const {setDangerMessage, setShowSpinner} = useContext(AppContext);
    const ref = useRef<HTMLInputElement>(null);
    const [from, setFrom] = useState<string>("");
    const [to, setTo] = useState<string>("");

    useEffect(() => {
        if (from !== "" && to !== "") {
            if (CheckSampleId(from) && CheckSampleId(to)) {
                // 両方整ったら検索自動実行
                onSearch([{from: from, to: to}], false);
            }
        }
    }, [from, to]);

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setFrom("");
        setTo("");
        onClear();
    }

    // 貼り付け処理
    const onPaste = (e: React.MouseEvent<HTMLSpanElement>): void => {

        e.preventDefault();

        if (typeof navigator.clipboard.readText === 'undefined') {
            alert("お使いのブラウザは、navigator.clipboard.readText() に対応しておりません。Google Chromeなどの対応ブラウザで実行してください。");
            return;
        }

        navigator.clipboard.readText()
            .then((text) => {

                const lines = text.split(/\r\n|\r|\n/);

                let hasError = false;

                const next: PpTestKitControllerFromTo[] = lines.map((line) => {
                    const parts = line.split(/\t/);

                    const tmp: PpTestKitControllerFromTo = {
                        from: "",
                        to: "",
                    };

                    if (parts.length === 1) {
                        // ひとつのみの選択の場合は、fromとtoに同じ値を入れる
                        if (!CheckSampleId(parts[0])) {
                            hasError = true;
                            return tmp;
                        }

                        tmp.from = parts[0].trim();
                        tmp.to = parts[0].trim();

                    } else {
                        if (!CheckSampleId(parts[0])) {
                            hasError = true;
                            return tmp;
                        }
                        if (!CheckSampleId(parts[1])) {
                            hasError = true;
                            return tmp;
                        }

                        tmp.from = parts[0].trim();
                        tmp.to = parts[1].trim();
                    }

                    return tmp;
                });

                if (hasError) {
                    setDangerMessage(`クリップボードの内容に正しくない検体IDが含まれています。`);
                } else {
                    onSearch(next, false);
                }
            });

    };

    const onClickFile = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        if (ref.current) {
            ref.current.click();
        }
    }

    const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>): void => {

        e.preventDefault();

        const files = e.currentTarget.files;
        if (!files) {
            return;
        }

        const file = files.item(0);
        if (!file) {
            return;
        }

        console.log(file.type);

        if (["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv"].indexOf(file.type) === -1) {
            alert("エクセルファイルかCSVファイルを選択してください。");
            return;
        }

        setShowSpinner(true);

        // サーバに送信してエクセルを解析して、FromToを受け取る
        adminPpTestKitApi.v1AdminPpTestKitParseXlsPost(file)
            .then(({data}) => {
                onSearch(data.sample_ids, true);
            })
            .catch((err) => {

                onClear();
                // エラー時はメッセージを表示する
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);

            });

    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.currentTarget.name) {
            case "from":
                setFrom(e.currentTarget.value);
                break;
            case "to":
                setTo(e.currentTarget.value);
                break;
        }
    };

    const onSubmitDummy = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    // フォーカス時選択
    const onFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.currentTarget.select();
    }

    return <StyledSearchForm onSubmit={onSubmitDummy}>

        <div className="box">

            <H2 title="検体情報の検索" subtitle="search"/>

            <div className="row">

                <div className="column">
                    <h5>検体IDで読み込む</h5>
                    <div>
                        <div className="form-group center">
                            <label>貼り付け</label>
                            <div>
                                <span className="paste-area" title="クリックすると貼り付けます" onClick={onPaste}>貼り付け欄</span>
                            </div>
                        </div>
                        <div className="form-group center">
                            <label>直接入力</label>
                            <div className="inline range-input">
                                <span className="from-label">from</span>
                                <span className="to-label">to</span>
                                <input type="text" name="from" value={from} placeholder="例：PC23-1000-NGS-1000" onChange={onChange} onFocus={onFocus}/>
                                <span>～</span>
                                <input type="text" name="to" value={to} placeholder="例：PC23-1000-NGS-1000" onChange={onChange} onFocus={onFocus}/>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="column">
                    <h5>ファイルで取り込む</h5>
                    <div>
                        <input type="file" ref={ref} accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv" onChange={onChangeFile}/>
                        <div className="btn-area">
                            <Button color={ButtonColor.Green} type="button" icon={ButtonIcon.Arrow} onClick={onClickFile}>ファイル取込</Button>
                        </div>
                    </div>
                </div>

            </div>

            <div className="btn-area mt40">
                <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                <Button color={ButtonColor.Orange} type="button" icon={ButtonIcon.Arrow} onClick={onSet}>設定する</Button>
            </div>

        </div>

    </StyledSearchForm>
};

const StyledSearchForm = styled(StyledForm)`

  .row {
    display: flex;
    justify-content: space-between;

    .column {
      width: 49%;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      overflow: hidden;
      border: 1px solid #EFEFEF;

      h5 {
        background-color: #EFEFEF;
        font-weight: 400;
        line-height: 36px;
        font-size: 14px;
        padding: 0 10px 0 30px;
        background-image: url(${rightOrange2});
        background-repeat: no-repeat;
        background-position: left 15px top 50%;
      }

      > div {
        background-color: #fff;
        padding: 15px;

        .form-group {
          padding: 10px 0;
          margin-bottom: 25px;

          &:last-child {
            margin-bottom: 0;
          }

          label {
            width: 80px !important;
            padding: 0;
            margin: 0;
          }

          > div {
            flex: 1;

            .paste-area {
              color: #80CBC4;
              background-color: #F4F9F8;
              border: 1px dotted #80CBC4;
              padding: 15px 30px;
              font-size: 14px;
              cursor: pointer;
            }

            input[type=text] {
              width: 200px !important;
            }

            &.range-input {
              position: relative;

              .from-label {
                position: absolute;
                top: -20px;
                left: 0;
                font-size: 12px;
              }

              .to-label {
                position: absolute;
                top: -20px;
                left: 235px;
                font-size: 12px;
              }
            }

          }
        }

        input[type=file] {
          display: none;
        }

      }

    }
  }

`;