import styled from "styled-components";
import {useContext} from "react";
import {AppContext} from "../../contexts/AppContext";

export const CompleteMessage = () => {

    const {completeMessage} = useContext(AppContext);

    if (!completeMessage) {
        return null;
    }

    return <StyledCompleteMessage>
        {completeMessage}
    </StyledCompleteMessage>

}

const StyledCompleteMessage = styled.div`
  background-color: rgba(217, 62, 76, 0.8);
  color: #fff;
  font-size: 18px;
  line-height: 1.8;
  padding: 4px 15px;
  margin-bottom: 22px;
`;