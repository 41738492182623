import {H3} from "../../common/Headline";
import {GetDateList, GetMonthList, GetWeight1, GetWeight2, GetYearList, Zerofill} from "../../../utility/Utility";
import {ErrorMessage} from "../../common/ErrorMessage";
import React, {useEffect, useState} from "react";
import {TypesPpTestKitData} from "../../../generated";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "../../common/Button";

interface Props {
    errors: { [key: string]: string };
    onUpdate: (next: TypesPpTestKitData) => void;
    ppTestKit: TypesPpTestKitData;
}

export const Basic = ({errors, onUpdate, ppTestKit}: Props) => {

    const [weight, setWeight] = useState<string>("");
    const [weight1, setWeight1] = useState<string>("");
    const [weight2, setWeight2] = useState<string>("");
    const [year, setYear] = useState<string>("");
    const [month, setMonth] = useState<string>("");
    const [day, setDay] = useState<string>("");
    const [ymd, setYmd] = useState<string>("");

    useEffect(() => {

        if (ppTestKit.is_pet_weight) {
            const p = String(ppTestKit.is_pet_weight).split(".");
            setWeight1(p[0]);
            setWeight2(p[1]);
            setWeight(ppTestKit.is_pet_weight);
        }

        if (ppTestKit.sampling_date) {
            const p = String(ppTestKit.sampling_date).split("-");
            setYear(p[0]);
            setMonth(p[1]);
            setDay(p[2]);
            setYmd(ppTestKit.sampling_date);
        }

    }, []);

    useEffect(() => {
        ppTestKit.sampling_date = ymd;
        ppTestKit.is_pet_weight = weight;
        onUpdate(ppTestKit);
    }, [weight, ymd]);

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

        switch (e.currentTarget.name) {
            case "weight1":
                setWeight1(e.currentTarget.value);
                setWeight(`${e.currentTarget.value}.${weight2}`);
                break;
            case "weight2":
                setWeight2(e.currentTarget.value);
                setWeight(`${weight1}.${e.currentTarget.value}`);
                break;
            case "year":
                setYear(e.currentTarget.value);
                setYmd(`${e.currentTarget.value}-${month}-${day}`);
                break;
            case "month":
                setMonth(e.currentTarget.value);
                setYmd(`${year}-${e.currentTarget.value}-${day}`);
                break;
            case "day":
                setDay(e.currentTarget.value);
                setYmd(`${year}-${month}-${e.currentTarget.value}`);
                break;
        }
    };

    // 「今日」ボタンクリック
    const onClickToday = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        const n = new Date();

        const today = `${n.getFullYear()}-${Zerofill(n.getMonth() + 1, 2)}-${Zerofill(n.getDate(), 2)}`;
        setYear(n.getFullYear().toString());
        setMonth(Zerofill(n.getMonth() + 1, 2));
        setDay(Zerofill(n.getDate(), 2));
        setYmd(today);
    }

    return <div className="box">

        <H3 title={"基本情報"} subtitle="base data"/>

        <div className="form-wrapper">

            <div className="form-group">
                <label className="req">体重</label>
                <div>
                    <div className="inline">
                        <select name="weight1" value={weight1} onChange={onChange}>
                            <option value="">--</option>
                            {GetWeight1().map((d, i) => {
                                return <option key={`w2-${i}`} value={d}>{d}</option>
                            })}
                        </select>

                        <span>.</span>

                        <select name="weight2" value={weight2} onChange={onChange}>
                            <option value="">--</option>
                            {GetWeight2().map((d, i) => {
                                return <option key={`w2-${i}`} value={d}>{d}</option>
                            })}
                        </select>
                        <span>kg</span>
                    </div>
                    <ErrorMessage message={errors["is_pet_weight"]}/>

                </div>
            </div>

            <div className="form-group">
                <label className="req">検体採取日</label>
                <div>
                    <div className="inline">
                        <select name="year" value={year} onChange={onChange}>
                            <option value="">----</option>
                            {GetYearList(true).map((d, i) => {
                                return <option key={`y-${i}`} value={d}>{d}</option>
                            })}
                        </select>
                        <span>年</span>
                        <select name="month" value={month} onChange={onChange}>
                            <option value="">----</option>
                            {GetMonthList().map((d, i) => {
                                return <option key={`m-${i}`} value={d}>{d}</option>
                            })}                            </select>
                        <span>月</span>
                        <select name="day" value={day} onChange={onChange}>
                            <option value="">----</option>
                            {GetDateList().map((d, i) => {
                                return <option key={`d-${i}`} value={d}>{d}</option>
                            })}
                        </select>
                        <span>日</span>
                        <Button type="button" color={ButtonColor.Green} size={ButtonSize.Small} icon={ButtonIcon.Arrow} onClick={onClickToday}>今日</Button>

                    </div>
                    <ErrorMessage message={errors["sampling_date"]}/>
                </div>
            </div>

        </div>

    </div>

}