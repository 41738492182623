import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import React, {useEffect, useState} from "react";
import {H2} from "../common/Headline";
import {useLocation, useNavigate} from "react-router-dom";
import {CheckSampleId} from "../../utility/Utility";

export const SearchForm = () => {

    const [sampleId, setSampleId] = useState<string>("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        const params = new URLSearchParams(location.search);
        const sampleId = params.get("sample_id") ?? "";

        if (sampleId) {
            setSampleId(sampleId);
        }

    }, []);

    useEffect(() => {
        if (CheckSampleId(sampleId)) {
            const params = new URLSearchParams();
            params.set("sample_id", sampleId);
            navigate(`/debug?${params.toString()}`);
        }
    }, [sampleId]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
        switch (e.currentTarget.name) {
            case "sample_id":
                setSampleId(e.currentTarget.value);
                break;
        }
    };

    const onSubmitDummy = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
    };

    return <StyledSearchForm noValidate={true} onSubmit={onSubmitDummy}>

        <div className="box">

            <H2 title="検体IDを指定" subtitle="search"/>

            <div className="form-group">
                <label>検体ID</label>
                <div>
                    <input type="text" name="sample_id" placeholder={"例：PC23-1000-NGS-1000"} value={sampleId} onChange={onChange} maxLength={18}/>
                </div>
            </div>

        </div>

    </StyledSearchForm>
};

export const StyledSearchForm = styled(StyledForm)`

`;