import {Layout} from "../common/Layout";
import {H1} from "../common/Headline";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {RegistrationSteps} from "./RegistrationSteps";
import {AppContext} from "../../contexts/AppContext";
import {TypesPpUserData} from "../../generated";
import {adminPpUserApi} from "../../api/Api";

export const PpUserCreateComplete = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppUser, setPpUser] = useState<TypesPpUserData | null>(null);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {

        getUser();

    }, []);

    const getUser = () => {

        setShowSpinner(true);

        adminPpUserApi.v1AdminPpUserGetIdGet(params.id ?? "")
            .then(({data}) => {
                setPpUser(data.pp_user ?? null);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
                navigate("/pp_user");
            })
            .finally(() => {
               setShowSpinner(false);
            });
    };

    if (!ppUser) {
        return null;
    }

    const onClick = (e: React.MouseEvent<HTMLButtonElement>, type: string) => {

        e.preventDefault();

        if (type === "pet") {
            navigate(`/pp_pet/create/${ppUser.id}`);
        } else if (type === "test") {
            navigate(`/pp_test_kit/apply/${ppUser.id}/${ppUser.pp_pets ? ppUser.pp_pets[0].id : ""}`);
        }

    };

    return <Layout title="会員情報登録">

        <H1 title="会員情報の新規登録" subtitle="registration"/>

        <RegistrationSteps offset={3}/>


        <StyledMembersCreateComplete>

            <div className="box">

                <p>
                    PiTPETへのご登録ありがとうございます。<br/>
                    登録が完了いたしました。<br/>
                    引き続き検査申し込みへお進みください。
                </p>

            </div>

            <div className="btn-area">
                <Button color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={(e) => onClick(e, "pet")}>ペット情報を追加</Button>
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow} onClick={(e) => onClick(e, "test")}>検査申し込みへ</Button>
            </div>


        </StyledMembersCreateComplete>

    </Layout>

};

const StyledMembersCreateComplete = styled(StyledForm)`

  p {
    font-size: 16px;
    line-height: 2;
  }
`;
