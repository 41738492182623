import {useLocation} from "react-router-dom";
import {useEffect} from "react";

export const ScrollTop = () => {

    const location = useLocation();

    // ページ遷移時にトップにスクロール
    useEffect(() => {
        // ただし、アンカーが指定されている場合は除外
        if (!location.hash) {
            window.scrollTo(0, 0);
        } else {

            // 開いて若干待ってからスクロール

            window.setTimeout(() => {
                const element = document.getElementById(location.hash.substring(1));

                if (element) {
                    element.scrollIntoView({
                        behavior: 'smooth',
                    });
                }

            }, 300);
        }


    }, [location.pathname, location.search]);


    return null;
}
