import React, {useContext, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {ErrorMessage} from "../common/ErrorMessage";
import {NavLink} from "react-router-dom";
import {Topics} from "./Topics";
import {StyledLogin} from "./Login";
import {SetPageTitle} from "../../utility/Utility";
import {Button, ButtonColor} from "../common/Button";
import {adminAuthApi} from "../../api/Api";
import {AdminAuthControllerForgotRequest} from "../../generated";

export const Forgot = () => {

    const {setShowSpinner, setDangerMessage, setSuccessMessage} = useContext(AppContext);
    const [email, setEmail] = useState<string>("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [failed, setFailed] = useState<string>("");

    SetPageTitle("パスワードを忘れた方");

    // 変更ハンドラー
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

        switch (e.currentTarget.name) {
            case "email":
                setEmail(e.currentTarget.value);
                break;
        }
    }

    // 送信ハンドラー
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const req: AdminAuthControllerForgotRequest = {
            email: email,
        }

        setShowSpinner(true);
        setErrors({});
        setFailed("");

        adminAuthApi.v1AdminAuthForgotPost(req)
            .then(() => {
                setEmail("");
                setSuccessMessage("パスワード再設定のリンクを送信しました。メールをご確認ください。");
            })
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setFailed(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    return (
        <StyledLogin>

            <form onSubmit={onSubmit}>

                <h1>PiTPET</h1>

                <Topics/>

                <div className="form-wrapper">

                    <p>
                        以下のフォームにご登録のメールアドレスを入力してください。<br/>
                        パスワード再設定のリンクが送付されます。
                    </p>

                    {failed && <p className="login-failed">{failed}</p>}

                    <div className="form-group">
                        <label>メールアドレス</label>
                        <div>
                            <input type="text" name="email" value={email} onChange={onChange}/>
                            <ErrorMessage message={errors["email"]}/>
                        </div>
                    </div>

                    <Button color={ButtonColor.Green}>送信する</Button>

                    <div className="links">
                        <NavLink to={"/login"}>ログイン画面に戻る</NavLink>
                    </div>

                </div>

            </form>

        </StyledLogin>
    );
};