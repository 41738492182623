import {TypesPpTestKitData} from "../../../generated";
import {GetAggression, GetAppetite, GetBodyFigure, GetBrushing, GetEnvironments, GetExerciseTime, GetFoodStr, GetFrequencyExcrement, GetFrequencyWalking, GetMainFood, GetShampoo, GetUneasiness, GetWorryStr, GetYesNoStr} from "../../../utility/Utility";
import React from "react";
import styled from "styled-components";

interface Props {
    ppTestKit: TypesPpTestKitData;
}

export const Habit = ({ppTestKit}: Props) => {

    return <StyledHabit className="form-wrapper">
        <div className="form-group center">
            <label className="req">飼育環境</label>
            <div>{GetEnvironments()[ppTestKit.is_environment ?? ""]}</div>
        </div>

        <div className="form-group center">
            <label className="req">散歩頻度</label>
            <div>{GetFrequencyWalking()[ppTestKit.is_frequency_walking ?? ""]}</div>
        </div>

        <div className="form-group center">
            <label className="req">1日の運動時間<small>（お散歩を含む）</small></label>
            <div>{GetExerciseTime()[ppTestKit.is_exercise_time_oneday ?? ""]}</div>
        </div>

        <div className="form-group center">
            <label className="req">1日のうんちの回数</label>
            <div>{GetFrequencyExcrement()[ppTestKit.is_frequency_excrement ?? ""]}</div>
        </div>

        <div className="form-group center">
            <label className="req">食事について</label>
            <div className="food-area">
                <div className="row">
                    <div>
                        {GetMainFood()[ppTestKit.is_main_food ?? ""]}
                    </div>
                    {ppTestKit.is_main_food === "3" && <div className="inline">
                        <label>具体的な食べ物</label>
                        <div>{GetFoodStr(ppTestKit)}</div>
                    </div>}
                    {ppTestKit.is_main_food === "4" && <div className="inline">
                        <label>具体的な食べ物</label>
                        <div>{ppTestKit.is_main_food_comment}</div>
                    </div>}
                </div>
            </div>
        </div>

        <div className="form-group center">
            <label className="req">食欲について</label>
            <div>{GetAppetite()[ppTestKit.is_appetite ?? ""]}</div>
        </div>

        <div className="form-group center">
            <label className="req">日常的な<br/>乳酸菌サプリの接種</label>
            <div>{GetYesNoStr(ppTestKit.is_lactic_supplement)}</div>
        </div>

        <div className="form-group center">
            <label className="req">体型</label>
            <div>{GetBodyFigure()[ppTestKit.is_body_figure ?? ""]}</div>
        </div>

        <div className="form-group center">
            <label className="req">攻撃性</label>
            <div className="food-area">
                <div className="row">
                    <div className="inline">
                        <label>家族に対して</label>
                        <div>{GetAggression()[ppTestKit.is_aggression_for_family ?? ""]}</div>
                    </div>
                    <div className="inline">
                        <label>他の人間に対して</label>
                        <div>{GetAggression()[ppTestKit.is_aggression_for_stranger ?? ""]}</div>
                    </div>
                </div>
            </div>
        </div>


        <div className="form-group center">
            <label className="req">不安の感じやすさ</label>
            <div className="food-area">
                <div className="row">
                    <div className="inline">
                        <span>飼い主と離れた時に、<br/>大きな声で鳴いたり、<br/>暴れたりする</span>
                        <div>{GetUneasiness().get(ppTestKit.is_uneasiness_separated_from_owner ?? "")}</div>
                    </div>
                    <div className="inline">
                        <span>飼い主が不在の時、<br/>物を壊したり、<br/>トイレを失敗する</span>
                        <div>{GetUneasiness().get(ppTestKit.is_uneasiness_damage_to_property ?? "")}</div>
                    </div>
                </div>
            </div>
        </div>

        <div className="form-group center">
            <label className="req">恐怖心</label>
            <div className="food-area">
                <div className="row">
                    <div className="inline">
                        <label>他の犬・猫に対して</label>
                        <div>{GetUneasiness().get(ppTestKit.is_fear_for_other_pets ?? "")}</div>
                    </div>
                    <div className="inline">
                        <label>他の人間に対して</label>
                        <div>{GetUneasiness().get(ppTestKit.is_fear_for_stranger ?? "")}</div>
                    </div>
                    <div className="inline">
                        <label>慣れない環境に対して</label>
                        <div>{GetUneasiness().get(ppTestKit.is_fear_unfamiliar_environment ?? "")}</div>
                    </div>
                </div>
            </div>

        </div>

        <div className="form-group center">
            <label className="req">ブラッシングの頻度</label>
            <div>{GetBrushing().get(ppTestKit.is_frequency_brushing ?? "")}</div>
        </div>

        <div className="form-group center">
            <label className="req">シャンプーの頻度</label>
            <div>{GetShampoo().get(ppTestKit.is_frequency_shampoo ?? "")}</div>
        </div>

        <div className="form-group center">
            <label className="req">気になること</label>
            <div>{GetWorryStr(ppTestKit)}</div>
        </div>

    </StyledHabit>

}

const StyledHabit = styled.div`
  // 食事
  .food-area {
    .row > div {
      border-bottom: 1px dotted #ddd;
      padding: 15px 0;

      &:first-child {
        padding: 0 0 15px 0;
      }

      &:last-child {
        padding: 15px 0 0 0;
        border-bottom: none;
      }
    }

    .inline {
      label {
        font-size: 14px;
        width: 13%;
      }

      span {
        width: 13%;
        font-size: 12px;
        line-height: 14px;
      }

      div {
        width: 87%;
      }
    }

  }

`;