/* tslint:disable */
/* eslint-disable */
/**
 * PiTPET API
 * PiTPET APIに関する仕様書 管理画面、フロントサイド、それぞれで使用するAPIです。<br> 管理画面は、/v1/admin/ から始まります。<br> フロントサイドは、/v1/front から始まります。<br> それ以外のルーティングは認証がかけられていない公開状態のAPIです。<br> 認証が必要なAPIではリクエストヘッダーに `Authorization: Bearer [ログイン時に発行されたトークン]` を指定してください。<br> なお、Basic認証がかけられているSTG環境では`Authorization`ヘッダーが重複してしまい正常に認証ができません。<br> そのため、STG環境では`X-API-KEY: [ログイン時に発行されたトークン]`ヘッダーを指定してください。<br> 2023/11/09追記、PITPET_SYS_2023-176 の自動ログアウト仕様追加により、管理画面はCookie認証に変更しています。<br>Cookieはログイン時に発行されているので、そのまま送れば認証されます。<br> なお、各認証を経由したAPIにおいては、`Sephirahモジュール`の共通の認証結果、`sephirahAuthCertificate`プロパティが追加で返却されます。<br> `sephirahAuthCertificate`のプロパティに関しては、admin_authのログイン認証のレスポンスを参照してください。<br>
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from "./configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';

export const BASE_PATH = "http://localhost:8080".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: AxiosRequestConfig;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}
