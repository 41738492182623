import {adminPpMstGenericRcmndGroupApi} from "../api/Api";
import {useContext, useEffect, useState} from "react";
import {PpMstGenericRcmndGroupModelGetListResponse} from "../generated";
import {AppContext} from "../contexts/AppContext";

// 汎用レコメンドグループリスト取得
export const useGenericRcmndGroup = () => {

    const {setDangerMessage, setShowSpinner} = useContext(AppContext);
    const [ppMstGenericRcmndGroups, setPpMstGenericRcmndGroups] = useState<PpMstGenericRcmndGroupModelGetListResponse[]>([]);

    useEffect(() => {

        setShowSpinner(true);

        adminPpMstGenericRcmndGroupApi
            .v1AdminPpMstGenericRcmndGroupListGet()
            .then(({data}) => {
                setPpMstGenericRcmndGroups(data.data);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }, []);

    return ppMstGenericRcmndGroups;
}