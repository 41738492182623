import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "../common/Button";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {H2} from "../common/Headline";
import {ConvertYmdToDate, GetDateList, GetHourList, GetMinuteList, GetMonthList, GetYearList, IsValidDate, Zerofill} from "../../utility/Utility";

interface Props {
    onClear: () => void;
}

export const SearchForm = ({onClear}: Props) => {

    const [fromYear, setFromYear] = useState<string>("");
    const [fromMonth, setFromMonth] = useState<string>("");
    const [fromDate, setFromDate] = useState<string>("");
    const [fromHour, setFromHour] = useState<string>("");
    const [fromMinute, setFromMinute] = useState<string>("");
    const [toYear, setToYear] = useState<string>("");
    const [toMonth, setToMonth] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");
    const [toHour, setToHour] = useState<string>("");
    const [toMinute, setToMinute] = useState<string>("");
    const [from, setFrom] = useState<string>("");
    const [to, setTo] = useState<string>("");
    const [isDateErr, setIsDateErr] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        const s = new URLSearchParams(location.search);

        setFrom(s.get("from") || "");
        setTo(s.get("to") || "");

        const from = s.get("from") || "";
        if (from !== "") {
            const parts = from.split(" ");
            const ymdParts = parts[0].split("-");
            const timeParts = parts[1].split(":");
            setFromYear(ymdParts[0]);
            setFromMonth(ymdParts[1]);
            setFromDate(ymdParts[2]);
            setFromHour(timeParts[0]);
            setFromMinute(timeParts[1]);
        }

        const to = s.get("to") || "";
        if (to !== "") {
            const parts = to.split(" ");
            const ymdParts = parts[0].split("-");
            const timeParts = parts[1].split(":");
            setToYear(ymdParts[0]);
            setToMonth(ymdParts[1]);
            setToDate(ymdParts[2]);
            setToHour(timeParts[0]);
            setToMinute(timeParts[1]);
        }

    }, []);

    useEffect(() => {
        if (IsValidDate(from) && IsValidDate(to)) {
            const fromDt = ConvertYmdToDate(from);
            const toDt = ConvertYmdToDate(to);
            setIsDateErr(fromDt.getTime() > toDt.getTime());
        } else {
            setIsDateErr(false);
        }
    }, [from, to])

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {

        switch (e.currentTarget.name) {
            case "from_year":
                setFromYear(e.currentTarget.value);
                setFrom(`${e.currentTarget.value}-${fromMonth}-${fromDate} ${fromHour}:${fromMinute}:00`);
                break;
            case "from_month":
                setFromMonth(e.currentTarget.value);
                setFrom(`${fromYear}-${e.currentTarget.value}-${fromDate} ${fromHour}:${fromMinute}:00`);
                break;
            case "from_date":
                setFromDate(e.currentTarget.value);
                setFrom(`${fromYear}-${fromMonth}-${e.currentTarget.value} ${fromHour}:${fromMinute}:00`);
                break;
            case "from_hour":
                setFromHour(e.currentTarget.value);
                setFrom(`${fromYear}-${fromMonth}-${fromDate} ${e.currentTarget.value}:${fromMinute}:00`);
                break;
            case "from_minute":
                setFromMinute(e.currentTarget.value);
                setFrom(`${fromYear}-${fromMonth}-${fromDate} ${fromHour}:${e.currentTarget.value}:00`);
                break;
            case "to_year":
                setToYear(e.currentTarget.value);
                setTo(`${e.currentTarget.value}-${toMonth}-${toDate} ${toHour}:${toMinute}:59`);
                break;
            case "to_month":
                setToMonth(e.currentTarget.value);
                setTo(`${toYear}-${e.currentTarget.value}-${toDate} ${toHour}:${toMinute}:59`);
                break;
            case "to_date":
                setToDate(e.currentTarget.value);
                setTo(`${toYear}-${toMonth}-${e.currentTarget.value} ${toHour}:${toMinute}:59`);
                break;
            case "to_hour":
                setToHour(e.currentTarget.value);
                setTo(`${toYear}-${toMonth}-${toDate} ${e.currentTarget.value}:${toMinute}:59`);
                break;
            case "to_minute":
                setToMinute(e.currentTarget.value);
                setTo(`${toYear}-${toMonth}-${toDate} ${toHour}:${e.currentTarget.value}:59`);
                break;
        }

    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        // URLを作成する
        const params = new URLSearchParams();
        params.append("from", from);
        params.append("to", to);
        navigate(`/logs?${params.toString()}`);
    };

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        setFrom("");
        setFromYear("");
        setFromMonth("");
        setFromDate("");
        setFromHour("");
        setFromMinute("");
        setTo("");
        setToYear("");
        setToMonth("");
        setToDate("");
        setToHour("");
        setToMinute("");
        navigate(`/logs`);
        onClear();
    }

    // 「今日」ボタンクリック
    const onClickToday = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        const n = new Date();

        const today = `${n.getFullYear()}-${Zerofill(n.getMonth() + 1, 2)}-${Zerofill(n.getDate(), 2)}`;
        setFromYear(n.getFullYear().toString());
        setToYear(n.getFullYear().toString());
        setFromMonth(Zerofill(n.getMonth() + 1, 2));
        setToMonth(Zerofill(n.getMonth() + 1, 2));
        setFromDate(Zerofill(n.getDate(), 2));
        setToDate(Zerofill(n.getDate(), 2));
        setFromHour("00");
        setToHour("23");
        setFromMinute("00");
        setToMinute("59");
        setFrom(today + " 00:00:00");
        setTo(today + " 23:59:59");
    }

    const btnEnabled = fromYear !== "" &&
        fromMonth !== "" &&
        fromDate !== "" &&
        fromHour !== "" &&
        fromMinute !== "" &&
        toYear !== "" &&
        toMonth !== "" &&
        toDate !== "" &&
        toHour !== "" &&
        toMinute !== "";

    return <StyledSearchForm noValidate={true} onSubmit={onSubmit} className={isDateErr ? "error" : ""}>

        <div className="box">

            <H2 title="検索内容の入力" subtitle="search"/>

            <div className="search-form">

                <div className="form-group with-error date-area">
                    <label>取得日</label>
                    <div>
                        <div className="inline">
                            <select name="from_year" value={fromYear} onChange={onChange}>
                                <option value="">----</option>
                                {GetYearList(true).map((d, i) => {
                                    return <option key={`from_year-${i}`} value={d}>{d}</option>
                                })}
                            </select>
                            <span>年</span>
                            <select name="from_month" value={fromMonth} onChange={onChange}>
                                <option value="">----</option>
                                {GetMonthList().map((d, i) => {
                                    return <option key={`from_month-${i}`} value={d}>{d}</option>
                                })}
                            </select>
                            <span>月</span>
                            <select name="from_date" value={fromDate} onChange={onChange}>
                                <option value="">----</option>
                                {GetDateList().map((d, i) => {
                                    return <option key={`from_date-${i}`} value={d}>{d}</option>
                                })}
                            </select>
                            <span>日</span>
                            <select name="from_hour" value={fromHour} onChange={onChange}>
                                <option value="">----</option>
                                {GetHourList().map((d, i) => {
                                    return <option key={`from_hour-${i}`} value={d}>{d}</option>
                                })}
                            </select>
                            <span>時</span>
                            <select name="from_minute" value={fromMinute} onChange={onChange}>
                                <option value="">----</option>
                                {GetMinuteList().map((d, i) => {
                                    return <option key={`from_hour-${i}`} value={d}>{d}</option>
                                })}
                            </select>
                            <span>分</span>

                            <span>〜</span>

                            <select name="to_year" value={toYear} onChange={onChange}>
                                <option value="">----</option>
                                {GetYearList(true).map((d, i) => {
                                    return <option key={`to_year-${i}`} value={d}>{d}</option>
                                })}
                            </select>
                            <span>年</span>
                            <select name="to_month" value={toMonth} onChange={onChange}>
                                <option value="">----</option>
                                {GetMonthList().map((d, i) => {
                                    return <option key={`to_month-${i}`} value={d}>{d}</option>
                                })}                            </select>
                            <span>月</span>
                            <select name="to_date" value={toDate} onChange={onChange}>
                                <option value="">----</option>
                                {GetDateList().map((d, i) => {
                                    return <option key={`to_date-${i}`} value={d}>{d}</option>
                                })}
                            </select>
                            <span>日</span>
                            <select name="to_hour" value={toHour} onChange={onChange}>
                                <option value="">----</option>
                                {GetHourList().map((d, i) => {
                                    return <option key={`from_hour-${i}`} value={d}>{d}</option>
                                })}
                            </select>
                            <span>時</span>
                            <select name="to_minute" value={toMinute} onChange={onChange}>
                                <option value="">----</option>
                                {GetMinuteList().map((d, i) => {
                                    return <option key={`from_hour-${i}`} value={d}>{d}</option>
                                })}
                            </select>
                            <span>分</span>

                            <Button type="button" color={ButtonColor.Green} size={ButtonSize.Small} icon={ButtonIcon.Arrow} onClick={onClickToday}>今日</Button>

                            {isDateErr && <span className="error">日付の指定が正しくありません</span>}

                        </div>
                    </div>
                </div>


                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                    <Button color={btnEnabled ? ButtonColor.Orange : ButtonColor.Gray} disabled={!btnEnabled} type="submit" icon={ButtonIcon.Arrow}>検索</Button>
                </div>

            </div>

        </div>

    </StyledSearchForm>
};

export const StyledSearchForm = styled(StyledForm)`

  &.error {
    input[type=text], select {
      background-color: rgba(217, 62, 76, 0.2);
    }
  }

  // inlineフォームのspanの幅を固定する
  .search-form {

    .form-group {

      label {
        width: auto;
      }

      .inline {
        span {
          &.w30 {
            width: 30px;
          }
        }
      }

      &.with-error {
        position: relative;

        &.date-area {
          .error {
            left: 750px;

          }
        }

        .error {
          background-color: #D93E4C;
          color: #fff;
          line-height: 47px;
          display: block;
          position: absolute;
          top: -2px;
          left: 320px;
          padding: 0 16px;
          border-radius: 4px;

          &:before {
            position: absolute;
            border: 8px solid transparent;
            border-right: 8px solid #D93E4C;
            top: 50%;
            left: -16px;
            transform: translateY(-50%);
            content: "";
            display: block;
          }
        }
      }
    }

  }
`;