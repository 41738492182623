import {useContext, useEffect, useState} from "react";
import {PpMstSkuControllerListRequest, PpMstSkuModelGetListResponse} from "../generated";
import {adminPpMstSkuApi} from "../api/Api";
import {AppContext} from "../contexts/AppContext";

// SKUリストの取得
export const useSkus = (onlyOption1: boolean) => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [skus, setSkus] = useState<PpMstSkuModelGetListResponse[]>([]);

    useEffect(() => {

        setShowSpinner(true);

        const req: PpMstSkuControllerListRequest = {
            only_option_1: onlyOption1,
        }

        adminPpMstSkuApi
            .v1AdminPpMstSkuListPost(req)
            .then(({data}) => {
                setSkus(data.data);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("SKUのリスト取得時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });

    }, []);

    return skus;

}