import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {AxiosResponse} from "axios";
import {ErrorMessage} from "../common/ErrorMessage";
import {adminPpMstIdentificationCdApi, adminPpMstPartnerInstApi, adminPpMstUserMedicalApi, adminPpMstUserPartnerApi} from "../../api/Api";
import {PpMstIdentificationCdControllerIndexRequest, PpMstUserPartnerControllerCreateRequest, PpMstUserPartnerControllerEditRequest, TypesPpMstIdentificationCdData, TypesPpMstUserPartnerData} from "../../generated";
import {GetAuthorityLevels, GetRandomStr, PartnerAccesses, PartnerInstDivisionMedical, PartnerInstDivisionPartner} from "../../utility/Utility";
import {EditEmailInput} from "../common/EditEmailInput";
import {EditPasswordInput} from "../common/EditPasswordInput";

interface PartnerInsts {
    id: number;
    name: string;
}

export const PpMstUserPartnerEdit = () => {

    const {setShowSpinner, setDangerMessage, setSuccessMessage} = useContext(AppContext);
    const [ppMstUserPartner, setPpMstUserPartner] = useState<TypesPpMstUserPartnerData | null>(null)
    const [access, setAccess] = useState<string>("");
    const [partnerInstDivision, setPartnerInstDivision] = useState<string>("1");
    const [partnerInstId, setPartnerInstId] = useState<number>(0);
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmationPassword, setConfirmationPassword] = useState<string>("");
    const [identificationCd, setIdentificationCd] = useState<string>("");
    const [identificationCds, setIdentificationCds] = useState<TypesPpMstIdentificationCdData[]>([]);
    const [groupingCode, setGroupingCode] = useState<string>("");
    const [codeNumber, setCodeNumber] = useState<string>("");
    const [isNewCd, setIsNewCd] = useState<boolean>(false);
    const params = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [partnerInsts, setPartnerInsts] = useState<PartnerInsts[]>([]);
    const [isEmailChange, setIsEmailChange] = useState<boolean>(false);
    const [isPasswordChange, setIsPasswordChange] = useState<boolean>(false);

    useEffect(() => {

        if (params.id) {
            // 編集時データを取得
            getUser(params.id);
        }

    }, [params]);

    useEffect(() => {
        console.log("再取得");
        getPartnerInsts();

    }, [partnerInstDivision]);

    const getPartnerInsts = () => {

        setShowSpinner(true);

        if (partnerInstDivision === "1") {
            // 病院の場合
            adminPpMstUserMedicalApi.v1AdminPpMstUserMedicalListGet()
                .then(({data}) => {
                    setPartnerInsts(data.data as PartnerInsts[]);
                })
                .catch((err) => {
                    if (err.response.data.message) {
                        setDangerMessage(err.response.data.message);
                        navigate("/pp_mst_user_partner");
                    } else {
                        setDangerMessage("通信時にエラーが発生しました");
                    }
                })
                .finally(() => {
                    setShowSpinner(false);
                })
        } else {
            // 提携法人の場合
            adminPpMstPartnerInstApi.v1AdminPpMstPartnerInstListGet()
                .then(({data}) => {
                    setPartnerInsts(data.data as PartnerInsts[]);
                })
                .catch((err) => {
                    if (err.response.data.message) {
                        setDangerMessage(err.response.data.message);
                        navigate("/pp_mst_user_partner");
                    } else {
                        setDangerMessage("通信時にエラーが発生しました");
                    }
                })
                .finally(() => {
                    setShowSpinner(false);
                })
        }
    };

    // 編集対象を取得
    const getUser = (id: string) => {

        setShowSpinner(true);

        adminPpMstUserPartnerApi.v1AdminPpMstUserPartnerGetIdGet(id)
            .then(({data}) => {
                setPpMstUserPartner(data.pp_mst_user_partner ?? null);

                setPartnerInstDivision(data.pp_mst_user_partner.partner_inst_division ?? "");
                setPartnerInstId(data.pp_mst_user_partner.partner_inst_id ?? 0);
                setAccess(data.pp_mst_user_partner.access ? String(data.pp_mst_user_partner.access) : "");
                setName(data.pp_mst_user_partner.name ?? "");
                setEmail(data.pp_mst_user_partner.email ?? "");

                if (data.pp_mst_user_partner.partner_inst_division === PartnerInstDivisionPartner) {
                    // 提携法人の場合
                    setIdentificationCd(data.pp_mst_user_partner.identification_cd ?? "");
                    getIdentificationCds(String(data.pp_mst_user_partner.partner_inst_id));
                }

            })
            .catch((err) => {
                console.log(err);

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/pp_mst_user_partner");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };


    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        setShowSpinner(true);

        let promise: Promise<void | AxiosResponse<any, any>>;

        if (ppMstUserPartner) {
            // 編集時
            const req: PpMstUserPartnerControllerEditRequest = {
                partner_inst_division: partnerInstDivision,
                partner_inst_id: partnerInstId,
                name: name,
                email: email,
                password: password,
                confirmation_password: confirmationPassword,
                access: parseInt(access ?? "0"),
                identification_cd: identificationCd !== "" ? identificationCd : null,
                is_new_cd: isNewCd,
                grouping_code: groupingCode,
                code_number: codeNumber,
                is_email_change: isEmailChange,
                is_password_change: isPasswordChange,
            };

            promise = adminPpMstUserPartnerApi.v1AdminPpMstUserPartnerEditIdPost(String(ppMstUserPartner.id), req)
                .then(() => {
                    setSuccessMessage("提携機関ユーザーの編集が完了しました。");
                    setErrors({});
                    navigate(-1);
                });
        } else {
            // 新規登録時
            const req: PpMstUserPartnerControllerCreateRequest = {
                partner_inst_division: partnerInstDivision,
                partner_inst_id: partnerInstId,
                name: name,
                email: email,
                password: password,
                confirmation_password: confirmationPassword,
                access: parseInt(access ?? "0"),
                identification_cd: identificationCd !== "" ? identificationCd : null,
                is_new_cd: isNewCd,
                grouping_code: groupingCode,
                code_number: codeNumber,
            };

            promise = adminPpMstUserPartnerApi.v1AdminPpMstUserPartnerCreatePost(req)
                .then(() => {
                    setSuccessMessage("提携機関ユーザーの新規追加が完了しました。");
                    setErrors({});
                    navigate(-1);
                });
        }

        promise
            .catch((err) => {
                if (err.response.status === 406) {
                    // バリデーションエラー
                    setDangerMessage("ご入力内容にエラーがありました。");
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {

        let isClearIdentificationCd = false;

        switch (e.currentTarget.name) {
            case "access":
                setAccess(e.currentTarget.value);
                break;
            case "partner_inst_division":
                setPartnerInstDivision(e.currentTarget.value);
                if (e.currentTarget.value === PartnerInstDivisionMedical) {
                    // 病院を選択したら識別コードをクリア
                    isClearIdentificationCd = true;
                }
                break;
            case "partner_inst_id":
                setPartnerInstId(Number(e.currentTarget.value));
                if (e.currentTarget.value === "") {
                    // 病院を選択したら識別コードをクリア
                    isClearIdentificationCd = true;
                }

                getIdentificationCds(e.currentTarget.value);
                break;
            case "name":
                setName(e.currentTarget.value);
                break;
            case "email":
                setEmail(e.currentTarget.value);
                break;
            case "password":
                setPassword(e.currentTarget.value);
                break;
            case "confirmation_password":
                setConfirmationPassword(e.currentTarget.value);
                break;
            case "identification_cd":
                setIdentificationCd(e.currentTarget.value);
                break;
            case "grouping_code":
                setGroupingCode(e.currentTarget.value);
                break;
            case "code_number":
                setCodeNumber(e.currentTarget.value);
                break;
        }

        if (isClearIdentificationCd) {
            // 識別コードをクリア
            setIdentificationCd("");
            setGroupingCode("");
            setCodeNumber("");
            setIsNewCd(false);
        }
    };

    // 識別コード一覧の取得
    const getIdentificationCds = (partnerInstId: string) => {

        if (partnerInstId === "") {
            // 未選択ならクリア
            setIdentificationCds([]);
            setIdentificationCd("");
            return;
        }

        setShowSpinner(true);

        const req: PpMstIdentificationCdControllerIndexRequest = {
            partner_inst_id: Number(partnerInstId),
            page: 1,
            size: 10000, // 事実上無制限とする
        };

        adminPpMstIdentificationCdApi
            .v1AdminPpMstIdentificationCdPost(req)
            .then(({data}) => {
                setIdentificationCds(data.pp_mst_identification_cds);
            })
            .catch((err) => {
                if (err.response.data && err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました。");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.currentTarget.name) {
            case "is_new_cd":
                setIsNewCd(e.currentTarget.checked);

                if (e.currentTarget.checked) {
                    // 新規登録をオンにした場合、現在の識別コードをクリア
                    setIdentificationCd("");
                }

                break;
        }
    }

    // 前の画面に戻る
    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(-1);
    };

    // ランダムな識別コード_番号を生成
    const generateRandomCode = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        setCodeNumber(GetRandomStr(6));
    }

    const toggleChangeableEmail = () => {
        setIsEmailChange(!isEmailChange);
        if (isEmailChange) {
            // 無効化する場合データをデフォルトに戻す
            setEmail(ppMstUserPartner?.email ?? "");
            setPassword("");
            setConfirmationPassword("");
            setErrors({});
        }
    }

    const toggleChangeablePassword = () => {
        setIsPasswordChange(!isPasswordChange);
        if (isPasswordChange) {
            // 無効化する場合データをデフォルトに戻す
            setEmail(ppMstUserPartner?.email ?? "");
            setErrors({});
        }
    }

    const isEdit = ppMstUserPartner !== null;

    // 識別コードエリアの入力可否
    const isIdentificationEnabled = partnerInstDivision === PartnerInstDivisionPartner && partnerInstId !== 0;

    const authorityLevels = GetAuthorityLevels();

    return <Layout title={"提携機関ユーザー"}>

        <div className="box">

            <H3 title={"提携機関ユーザー設定"}/>

            <StyledTopicsEdit onSubmit={onSubmit}>

                <div className="form-wrapper">

                    <div className="form-group check-area">
                        <label className="req">提携機関区分</label>
                        <div>
                            <div className="check">
                                <label>
                                    <input type="radio" name="partner_inst_division" value="1" onChange={onChange} checked={partnerInstDivision === PartnerInstDivisionMedical}/>
                                    病院
                                </label>
                                <label>
                                    <input type="radio" name="partner_inst_division" value="2" onChange={onChange} checked={partnerInstDivision === PartnerInstDivisionPartner}/>
                                    提携法人
                                </label>
                            </div>
                            <ErrorMessage message={errors["pet_sex"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">所属機関</label>
                        <div>
                            <select name="partner_inst_id" value={partnerInstId} onChange={onChange}>
                                <option value="">選択してください</option>
                                {partnerInsts.map((d, i) => {
                                    return <option value={d.id} key={`insts-${i}`}>{d.name}</option>;
                                })}
                            </select>
                            <ErrorMessage message={errors["partner_inst_id"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">名前</label>
                        <div>
                            <input type="text" name="name" value={name} onChange={onChange} placeholder={"氏名"}/>
                            <ErrorMessage message={errors["name"]}/>
                        </div>
                    </div>

                    {isEdit && <EditEmailInput
                        currentEmail={ppMstUserPartner?.email ?? ""}
                        email={email}
                        onChange={onChange}
                        errors={errors}
                        disabled={!isEmailChange}
                        toggleChangeable={toggleChangeableEmail}
                        isPasswordChange={isPasswordChange}
                    />}

                    {isEdit && <EditPasswordInput
                        password={password}
                        confirmationPassword={confirmationPassword}
                        onChange={onChange}
                        errors={errors}
                        disabled={!isPasswordChange}
                        toggleChangeable={toggleChangeablePassword}
                        isEmailChange={isEmailChange}
                    />}

                    {!isEdit && <div className="form-group">
                        <label className="req">メールアドレス</label>
                        <div>
                            <input type="email" name="email" value={email} onChange={onChange} placeholder={"半角英数"}/>
                            <ErrorMessage message={errors["email"]}/>
                        </div>
                    </div>}

                    {!isEdit && <>
                        <div className="form-group">
                            <label className={!isEdit ? "req" : ""}>パスワード</label>
                            <div>
                                <input type="password" name="password" value={password} onChange={onChange} placeholder={"パスワード"}/>
                                <ErrorMessage message={errors["password"]}/>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className={!isEdit ? "req" : ""}>パスワード（再入力）</label>
                            <div>
                                <input type="password" name="confirmation_password" value={confirmationPassword} onChange={onChange} placeholder={"パスワード"}/>
                                <ErrorMessage message={errors["confirmation_password"]}/>
                            </div>
                        </div>
                    </>}

                    {partnerInstDivision === PartnerInstDivisionPartner && < div className="form-group">
                        <label className="req">アクセス権限</label>
                        <div>
                            <select name="access" value={access} onChange={onChange}>
                                <option value="">--- 選択してください</option>
                                {PartnerAccesses.map((d, i) => {
                                    return <option key={`ac-${i}`} value={d.id}>{d.id}: {d.name}</option>;
                                })}
                            </select>
                            <ErrorMessage message={errors["access"]}/>
                        </div>
                    </div>}

                    {partnerInstDivision === PartnerInstDivisionPartner && <div className="form-group">
                        <label>識別コード</label>
                        <div>
                            <select name="identification_cd" disabled={!isIdentificationEnabled} value={identificationCd} onChange={onChange}>
                                <option value="">--- 選択してください</option>
                                {identificationCds.map((d, i) => {
                                    return <option key={`cd-${i}`} value={d.identification_cd}>{authorityLevels[d.authority_level]}: {d.identification_cd}</option>;
                                })}
                            </select>
                            <ErrorMessage message={errors["identification_cd"]}/>

                            <div className="new_cd">

                                <p>新規で識別コードを付与する際はこちらを選択し、入力してください。</p>

                                <div className="inline">

                                    <label>
                                        <input type="checkbox" name="is_new_cd" onChange={onCheckChange} checked={isNewCd} disabled={!isIdentificationEnabled}/>
                                        新規登録
                                    </label>

                                    <span>グルーピングコード</span>
                                    <input type="text" name="grouping_code" value={groupingCode} onChange={onChange} placeholder="例：TT" className="w110 mr" maxLength={2} disabled={!isNewCd}/>
                                    <span>番号</span>
                                    <input type="text" name="code_number" value={codeNumber} onChange={onChange} placeholder="例：123456" className="w110 mr" maxLength={6} disabled={!isNewCd}/>
                                    <Button color={ButtonColor.Green} icon={ButtonIcon.Arrow} type="button" onClick={generateRandomCode} disabled={!isNewCd}>番号自動生成</Button>
                                    <ErrorMessage message={errors["is_new_cd"]}/>
                                    <ErrorMessage message={errors["grouping_code"]} left={160} top={90}/>
                                </div>

                            </div>

                        </div>
                    </div>}

                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type={"button"} onClick={onClickBack} icon={ButtonIcon.Arrow}>前の画面に戻る</Button>
                    <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
                </div>

            </StyledTopicsEdit>

        </div>

    </Layout>

};

const StyledTopicsEdit = styled(StyledForm)`

    .new_cd {
        margin-top: 16px;
        background-color: rgba(255, 167, 11, .1);
        padding: 20px 15px;

        p {
            font-size: 14px;
            color: #D93E4C;
            text-decoration: underline;
            margin-bottom: 28px;
        }

        .inline {
            align-items: center;

            > label {
                margin-right: 40px;
                font-size: 16px;
                display: flex;
                align-items: center;
            }

            span {
                font-size: 12px;
            }

            .w110 {
                width: 110px;
            }

            button {
                margin-left: 15px;
            }


        }
    }

`;
