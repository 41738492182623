import {useContext} from "react";
import {AppContext} from "../../contexts/AppContext";
import styled from "styled-components";

export const Message = () => {

    const {dangerMessage, successMessage} = useContext(AppContext);

    if (!dangerMessage && !successMessage) {
        return null;
    }

    return <StyledMessage>
        {dangerMessage && <DangerMessage message={dangerMessage}/>}
        {successMessage && <SuccessMessage message={successMessage}/>}
    </StyledMessage>

}

interface Props {
    message: string;
}

const DangerMessage = ({message}: Props) => {
    const {setDangerMessage} = useContext(AppContext);
    return <div className="danger" onClick={() => setDangerMessage(null)}>{message}</div>
};


const SuccessMessage = ({message}: Props) => {
    const {setSuccessMessage} = useContext(AppContext);
    return <div className="success" onClick={() => setSuccessMessage(null)}>{message}</div>
};


const StyledMessage = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;

  > div {
    background-color: #fff;
    padding: 20px 40px 20px 20px;
    border-radius: 4px;
    font-size: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    min-width: 400px;
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;

    &:after {
      content: "\\00D7";
      font-size: 20px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }

    &.danger {
      color: #fff;
      background-color: #D93E4C;
    }

    &.success {
      color: #fff;
      background-color: #3954e7;
    }
  }

`;