import styled from "styled-components";
import {SlashedYmd} from "../../utility/Utility";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {TypesPpAnnouncementAdminData} from "../../generated";

interface Props {
    topic: TypesPpAnnouncementAdminData;
    onClose: () => void;
}

export const TopicDetail = (props: Props) => {

    return <StyledTopicDetail>
        <div className="inner">
            <h2>{props.topic.announcement_title}<span>{SlashedYmd(props.topic.display_ymd ?? "")}</span></h2>
            <p>
                {props.topic.announcement_detail}
            </p>

            <Button type="button" color={ButtonColor.Green} onClick={props.onClose} icon={ButtonIcon.Close}>閉じる</Button>

        </div>

    </StyledTopicDetail>
}

const StyledTopicDetail = styled.div`

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.6);
  z-index: 10;

  .inner {
    background-color: #fff;
    width: 838px;
    padding: 78px 86px 70px 86px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h2 {
      font-size: 24px;
      line-height: 1.7;
      font-weight: normal;
      margin-bottom: 40px;
      color: #707070;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: #707070;
        font-size: 16px;
        margin-left: 15px;
      }
    }

    p {
      font-size: 16px;
      line-height: 1.7;
      margin-bottom: 74px;
      color: #707070;
      white-space: pre-line;
    }

  }
`;
