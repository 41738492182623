import {Layout} from "../common/Layout";
import React, {useContext, useEffect, useState} from "react";
import {H1, H3} from "../common/Headline";
import {PpTestResultControllerBadBacteriaHistory, PpteststatusStatus, TypesPpMstMeatureCommentData, TypesPpTestKitData} from "../../generated";
import {AppContext} from "../../contexts/AppContext";
import {adminHistoryApi, adminPpMstMeatureCommentApi, adminPpTestKitApi} from "../../api/Api";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {DetailHeader} from "./DetailHeader";
import {CompleteMessage} from "../common/CompleteMessage";
import icoDown from "../../images/ico-down2.svg";
import icoUp from "../../images/ico-up2.svg";
import {BadBacteriaCardType1, BadBacteriaCardType2} from "./partial/BadBacteriaCard";
import {BadBacteriaComment} from "./partial/BadBacteriaComment";
import {Modal, ModalColor} from "../result/Modal";
import {GetMeasureCategory} from "../../utility/Utility";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {BadBacteriaLowerGraph} from "./partial/BadBacteriaLowerGraph";
import {BadBacteriaTabs} from "./partial/BadBacteriaTabs";

export const PpTestStatusBadBacteria = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppTestKit, setPpTestKit] = useState<TypesPpTestKitData | null>(null);
    const params = useParams();
    const navigate = useNavigate();
    const [histories, setHistories] = useState<PpTestResultControllerBadBacteriaHistory[]>([]);
    const [comments, setComments] = useState<TypesPpMstMeatureCommentData[]>([]);
    const [comment, setComment] = useState<TypesPpMstMeatureCommentData | null>(null);
    const [activeTab, setActiveTab] = useState<number>(1);
    const [showCardArea, setShowCardArea] = useState<boolean>(true);
    const [showLowerGraphArea, setLowerGraphArea] = useState<boolean>(true);

    useEffect(() => {
        if (params.sampleId) {
            getPpTestKit(params.sampleId);
        }
    }, []);

    const onReloadPpTestKit = () => {
        getPpTestKit(params.sampleId ?? "");
    }

    const getPpTestKit = (sampleId: string) => {

        setShowSpinner(true);

        adminPpTestKitApi
            .v1AdminPpTestKitGetSampleIdGet(sampleId)
            .then(({data}) => {

                if (data.pp_test_kit.status !== PpteststatusStatus.Status50) {
                    // 検査完了していない場合は、リダイレクト
                    setDangerMessage("まだ検査完了していませんので、検査情報は閲覧できません。");
                    navigate(`/pp_test_status/detail/${sampleId}`);
                    return;
                }
                setPpTestKit(data.pp_test_kit);

                getBadBacteriaHistory(sampleId);
                getMeatureComments();

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
                navigate("/pp_test_status");
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };


    const getMeatureComments = () => {
        setShowSpinner(true);

        adminPpMstMeatureCommentApi
            .v1AdminPpMstMeatureCommentGetAllGet()
            .then(({data}) => {
                setComments(data.pp_mst_meature_comments);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const getBadBacteriaHistory = (sampleId: string) => {

        setShowSpinner(true);

        adminHistoryApi
            .v1AdminHistoryBadBacteriaSampleIdGet(sampleId)
            .then(({data}) => {
                console.log(data);
                setHistories(data.histories);

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
                navigate(`/pp_test_status/detail/${sampleId}`);
            })
            .finally(() => {
                setShowSpinner(false);
            });

    }

    if (!ppTestKit || histories.length === 0) {
        return <Layout title="検査情報照会">
            <H1 title="会員の登録情報" subtitle="user data"/>
        </Layout>
    }

    const getCompletionDates = (): string[] => {
        const res = [];
        for (let i = 0; i < histories.length; i++) {
            res.push(histories[i].completion_date);
        }
        return res;
    }

    const getValues = (clm: string): number[] => {

        const res = [];
        for (let i = 0; i < histories.length; i++) {

            // // ダミー値を返す
            // const r = Math.floor(Math.random() * 600) / 100;
            // res.push(r);

            switch (clm) {
                case "diversity_index":
                    res.push(histories[i].diversity_index);
                    break;
                case "good_bacteria_bad_bacteria_index":
                    res.push(histories[i].good_bacteria_bad_bacteria_index);
                    break;
                case "similarity_index":
                    res.push(histories[i].similarity_index);
                    break;
                case "clostridium_perfringens":
                    res.push(histories[i].clostridium_perfringens);
                    break;
                case "clostridiumi_difficile":
                    res.push(histories[i].clostridiumi_difficile);
                    break;
                case "campylobacter_jejuni":
                    res.push(histories[i].campylobacter_jejuni);
                    break;
                case "campylobacter_coli":
                    res.push(histories[i].campylobacter_coli);
                    break;
                case "escherichia_coli":
                    res.push(histories[i].escherichia_coli);
                    break;
                case "staphylocccus_aureus":
                    res.push(histories[i].staphylocccus_aureus);
                    break;
                case "pseudomonas_aeruginosa":
                    res.push(histories[i].pseudomonas_aeruginosa);
                    break;
                case "clostridium_botulinum":
                    res.push(histories[i].clostridium_botulinum);
                    break;
                case "brucella_canis":
                    res.push(histories[i].brucella_canis);
                    break;
                case "listeria_monocytogenes":
                    res.push(histories[i].listeria_monocytogenes);
                    break;
                case "vibrio_parahaemolyticus":
                    res.push(histories[i].vibrio_parahaemolyticus);
                    break;
            }

        }
        return res;
    }

    const getComment = (measureCategory: string): TypesPpMstMeatureCommentData | null => {

        const filtered = comments.filter((comment) => {
            return comment.measure_category === measureCategory
        });

        if (filtered.length === 1) {
            return filtered[0];
        }

        return null;
    }

    const onClickComment = (e: React.MouseEvent<HTMLDivElement>, comment: TypesPpMstMeatureCommentData) => {
        e.preventDefault();
        setComment(comment);
    }

    const onCloseModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setComment(null);
    }

    // 検査完了日を抽出
    const completionDates = getCompletionDates();

    // 下部グラフの値配列
    let lowerGraphValues: number[];
    switch (activeTab) {
        case 1:
        default:
            lowerGraphValues = getValues("clostridium_perfringens");
            break;
        case 2:
            lowerGraphValues = getValues("clostridiumi_difficile");
            break;
        case 3:
            lowerGraphValues = getValues("campylobacter_jejuni");
            break;
        case 4:
            lowerGraphValues = getValues("campylobacter_coli");
            break;
        case 5:
            lowerGraphValues = getValues("escherichia_coli");
            break;
        case 6:
            lowerGraphValues = getValues("staphylocccus_aureus");
            break;
        case 7:
            lowerGraphValues = getValues("pseudomonas_aeruginosa");
            break;
        case 8:
            lowerGraphValues = getValues("clostridium_botulinum");
            break;
        case 9:
            lowerGraphValues = getValues("brucella_canis");
            break;
        case 10:
            lowerGraphValues = getValues("listeria_monocytogenes");
            break;
        case 11:
            lowerGraphValues = getValues("vibrio_parahaemolyticus");
            break;
    }

    const onToggleCardArea = (e: React.MouseEvent<HTMLHeadingElement>) => {
        e.preventDefault();
        setShowCardArea(!showCardArea);
    }

    const onToggleLowerGraphArea = (e: React.MouseEvent<HTMLHeadingElement>) => {
        e.preventDefault();
        setLowerGraphArea(!showLowerGraphArea);
    }

    return <Layout title="検査情報照会">

        <H1 title="会員の登録情報" subtitle="user data"/>

        <CompleteMessage/>

        <DetailHeader active={3} ppTestKit={ppTestKit} onReload={onReloadPpTestKit}/>

        <StyledPpTestStatusResult>

            <div className="box mt-minus">

                <H3 title="菌組成情報" subtitle="fungal tissue"/>

                <h6 onClick={onToggleCardArea} className={!showCardArea ? "hide" : ""}>全体構成</h6>

                {showCardArea && <div className="card-area">

                    <div className="card1-area">
                        <div>
                            <BadBacteriaCardType1 label={<>多様性スコア</>} completionDates={completionDates} values={getValues("diversity_index")}/>
                            <BadBacteriaComment comment={getComment("1")} onClick={onClickComment}/>
                        </div>
                        <div>
                            <BadBacteriaCardType1 label={<>善玉菌・悪玉菌<br/>スコア</>} completionDates={completionDates} values={getValues("good_bacteria_bad_bacteria_index")}/>
                            <BadBacteriaComment comment={getComment("2")} onClick={onClickComment}/>
                        </div>
                        <div>
                            <BadBacteriaCardType1 label={<>類似度スコア</>} completionDates={completionDates} values={getValues("similarity_index")}/>
                            <BadBacteriaComment comment={getComment("3")} onClick={onClickComment}/>
                        </div>
                    </div>

                    <hr/>

                    <div className="card2-area">

                        <div className="group">
                            <BadBacteriaCardType2 label={<>Clostridium<br/>perfringens</>} completionDates={completionDates} values={getValues("clostridium_perfringens")}/>
                            <BadBacteriaCardType2 label={<>Clostridiumi<br/>difficile</>} completionDates={completionDates} values={getValues("clostridiumi_difficile")}/>
                            <BadBacteriaCardType2 label={<>Campylobacter<br/>jejuni</>} completionDates={completionDates} values={getValues("campylobacter_jejuni")}/>
                            <BadBacteriaCardType2 label={<>Campylobacter<br/>coli</>} completionDates={completionDates} values={getValues("campylobacter_coli")}/>
                            <BadBacteriaCardType2 label={<>Escherichia<br/>coli</>} completionDates={completionDates} values={getValues("escherichia_coli")}/>
                            <BadBacteriaCardType2 label={<>Staphylocccus<br/>aureus</>} completionDates={completionDates} values={getValues("staphylocccus_aureus")}/>
                        </div>

                        <div className="group">
                            <BadBacteriaCardType2 label={<>Pseudomonas<br/>aeruginosa</>} completionDates={completionDates} values={getValues("pseudomonas_aeruginosa")}/>
                            <BadBacteriaCardType2 label={<>Clostridium<br/>botulinum</>} completionDates={completionDates} values={getValues("clostridium_botulinum")}/>
                            <BadBacteriaCardType2 label={<>Brucella<br/>canis</>} completionDates={completionDates} values={getValues("brucella_canis")}/>
                            <BadBacteriaCardType2 label={<>Listeria<br/>monocytogenes</>} completionDates={completionDates} values={getValues("listeria_monocytogenes")}/>
                            <BadBacteriaCardType2 label={<>Vibrio<br/>parahaemolyticus</>} completionDates={completionDates} values={getValues("vibrio_parahaemolyticus")}/>
                        </div>

                    </div>

                </div>}

                <h6 onClick={onToggleLowerGraphArea} className={!showLowerGraphArea ? "hide" : ""}>菌組成毎の推移詳細</h6>

                {showLowerGraphArea && <div className="lower-graph-area">
                    <BadBacteriaTabs activeTab={activeTab} setActiveTab={setActiveTab} values={lowerGraphValues}/>
                    <div className="graph-area">
                        <BadBacteriaLowerGraph dates={getCompletionDates()} values={lowerGraphValues}/>
                    </div>
                </div>}

            </div>

            {comment && <Modal color={ModalColor.Success}>
                <h4>{GetMeasureCategory(comment.measure_category)}</h4>
                <h5>{comment.comment_100}</h5>
                {comment.comment_101 !== "" && <p className="area-101">{comment.comment_101}</p>}
                {comment.comment_200 !== "" && <h5>{comment.comment_200}</h5>}
                {comment.comment_210 !== "" && <p className="area-210">{comment.comment_210}</p>}
                {comment.comment_211 !== "" && <p className="area-211">{comment.comment_211}</p>}
                {comment.comment_220 !== "" && <p className="area-220">{comment.comment_220}</p>}
                {comment.comment_221 !== "" && <p className="area-221">{comment.comment_221}</p>}
                {comment.comment_300 !== "" && <h5>{comment.comment_300}</h5>}
                {comment.comment_310 !== "" && <p className="area-310">{comment.comment_310}</p>}
                {comment.comment_311 !== "" && <p className="area-311">{comment.comment_311}</p>}

                <div className="btn-area">
                    <Button type="button" color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={onCloseModal}>元の画面に戻る</Button>
                </div>

            </Modal>}


        </StyledPpTestStatusResult>

    </Layout>

};


const StyledPpTestStatusResult = styled(StyledForm)`
    .mt-minus { // ヘッダー部分に被せる
        margin-top: -120px !important;
    }

    h3 {
        margin-bottom: 30px;
    }

    h6 {
        font-size: 16px;
        font-weight: normal;
        background-color: #EFEFEF;
        margin-top: 20px;
        line-height: 40px;
        padding: 0 10px;
        border-radius: 4px;
        background-image: url(${icoUp});
        background-repeat: no-repeat;
        background-position: top 50% right 15px;
        margin-bottom: 20px;
        cursor: pointer;

        &.hide {
            background-image: url(${icoDown});
        }
    }


    hr {
        border: none;
        border-bottom: 1px dashed #ccc;
        margin: 30px 0;
    }

    .card-area {
        margin-bottom: 40px;
    }

    .card1-area {
        > div {
            display: flex;
            margin-bottom: 15px;


        }
    }

    .card2-area {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .group {
            margin-right: 40px;

            &:last-child {
                margin-right: 0;
            }

        }
    }

    .modal-inner {

        width: 800px;

        .area-101, .area-211, .area-221, .area-311 { // モーダル内
            text-align: left !important;
            white-space: pre-wrap;
        }

        .area-210, .area-220, .area-310 { // モーダル内
            padding: 10px !important;
            color: #FE9871;
        }

        .area-220 {
            border-top: 10px solid #EFEFEF;
        }

        .area-211, .area-221, .area-311 { // モーダル内
            padding: 10px 40px 20px 40px !important;
        }

        .btn-area {
            margin-top: 0;
        }

    }

    .lower-graph-area {

        .graph-area {
            border: 1px solid #707070;
            padding: 20px;
        }
    }
`;
