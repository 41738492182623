import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {H2} from "../common/Headline";
import rightOrange2 from "../../images/right-orange2.svg";
import React, {useContext, useEffect, useState} from "react";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {TypesPpFaqCategoryData} from "../../generated";
import {AppContext} from "../../contexts/AppContext";
import {adminPpFaqCategoryApi} from "../../api/Api";

export const SearchForm = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [question, setQuestion] = useState<string>("");
    const [answer, setAnswer] = useState<string>("");
    const [ppFaqCategoryId, setPpFaqCategoryId] = useState<string>("");
    const [ppFaqCategories, setPpFaqCategories] = useState<TypesPpFaqCategoryData[]>([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setQuestion(params.get("question") ?? "");
        setAnswer(params.get("answer") ?? "");
        setPpFaqCategoryId(params.get("pp_faq_category_id") ?? "");

        // カテゴリリスト取得
        getFaqCategories();

    }, [location.search]);

    const getFaqCategories = () => {

        setShowSpinner(true);

        adminPpFaqCategoryApi
            .v1AdminPpFaqCategoryGet(1, 200)
            .then(({data}) => {
                setPpFaqCategories(data.pp_faq_categories);
            })
            .catch((err) => {

                if (err.response.data && err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました。");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        switch (e.currentTarget.name) {
            case "question":
                setQuestion(e.currentTarget.value);
                break;
            case "answer":
                setAnswer(e.currentTarget.value);
                break;
            case "pp_faq_category_id":
                setPpFaqCategoryId(e.currentTarget.value);
                break;
        }
    }

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/pp_faq`);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const params = new URLSearchParams();
        params.append("pp_faq_category_id", ppFaqCategoryId ?? "");
        params.append("question", question);
        params.append("answer", answer);
        params.append("page", "1");

        navigate(`/pp_faq?${params.toString()}`);
    }

    const onClickCreate = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/pp_faq/create`);
    };

    const onClickSort = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/pp_faq/sort`);
    };

    return <StyledSearchForm onSubmit={onSubmit}>

        <div className="box">

            <H2 title="検索情報の入力" subtitle="search"/>

            <div className="row">

                <div className="left">

                    <h5>情報を検索する</h5>

                    <div className="search-form">

                        <div className="form-group">
                            <label>カテゴリー</label>
                            <select name="pp_faq_category_id" value={ppFaqCategoryId} onChange={onChange}>
                                <option value="">-- 選択してください</option>
                                {ppFaqCategories.map((d, i) => {
                                    return <option key={`fc-${i}`} value={d.id}>{d.category_name}</option>
                                })}
                            </select>
                        </div>

                        <div className="form-group">
                            <label>質問</label>
                            <div>
                                <input type="text" name="question" placeholder="例：どのタイミングで採便したらいいですか？" value={question} onChange={onChange}/>
                            </div>
                        </div>

                        <div className="form-group">
                            <label>回答</label>
                            <div>
                                <input type="text" name="answer" placeholder="例：一日" value={answer} onChange={onChange}/>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="middle">
                    <h5>情報を新規登録する</h5>
                    <div>
                        <div className="btn-area">
                            <Button color={ButtonColor.Orange} type="button" icon={ButtonIcon.Arrow} onClick={onClickCreate}>新規登録</Button>
                        </div>
                    </div>
                </div>

                <div className="right">
                    <h5>表示順の変更</h5>
                    <div>
                        <div className="btn-area">
                            <Button color={ButtonColor.Orange} type="button" icon={ButtonIcon.Arrow} onClick={onClickSort}>表示順変更</Button>
                        </div>
                    </div>
                </div>

            </div>

            <div className="btn-area mt40">
                <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                <Button color={ButtonColor.Orange} type="submit" icon={ButtonIcon.Arrow}>検索</Button>
            </div>

        </div>


    </StyledSearchForm>

}

const StyledSearchForm = styled(StyledForm)`

  .row {
    display: flex;
    justify-content: space-between;

    h5 {
      background-color: #EFEFEF;
      font-weight: 400;
      line-height: 36px;
      font-size: 14px;
      padding: 0 10px 0 30px;
      background-image: url(${rightOrange2});
      background-repeat: no-repeat;
      background-position: left 15px top 50%;
    }

    .left {
      flex: 1;
      margin-right: 23px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      overflow: hidden;
      border: 1px solid #EFEFEF;

      .search-form {
        padding: 22px;
      }

      .form-group {
        padding: 6px 0;
        margin-bottom: 0;

        > div {
          flex: 1;
        }

        input {
          width: 100% !important;
        }
      }


      label {
        width: 150px;
        margin-right: 0;
      }

    }
    .middle {
      margin-right: 20px;
    }
    
    .middle, .right {
      width: 338px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      overflow: hidden;
      border: 1px solid #EFEFEF;

      .btn-area {
        margin-top: 80px;

        button {
          width: 240px;
        }
      }
    }

  }

`