import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {H2} from "../common/Headline";
import rightOrange2 from "../../images/right-orange2.svg";
import React, {useContext, useEffect, useState} from "react";
import {AccesstokenRole} from "../../generated";
import {AppContext} from "../../contexts/AppContext";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {GetIntestinalEnvironmentTypes} from "../../utility/Utility";

export const SearchForm = () => {

    const {role} = useContext(AppContext);
    const [animalType, setAnimalType] = useState<string>("");
    const [intestinalEnvironmentType, setIntestinalEnvironmentType] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [imageOverview, setImageOverview] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        setAnimalType(params.get("animal_type") ?? "");
        setIntestinalEnvironmentType(params.get("intestinal_environment_type") ?? "");
        setTitle(params.get("title") ?? "");
        setImageOverview(params.get("image_overview") ?? "");

    }, [location.search]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        switch (e.currentTarget.name) {
            case "animal_type":
                setAnimalType(e.currentTarget.value);
                break;
            case "intestinal_environment_type":
                setIntestinalEnvironmentType(e.currentTarget.value);
                break;
            case "title":
                setTitle(e.currentTarget.value);
                break;
            case "image_overview":
                setImageOverview(e.currentTarget.value);
                break;
        }
    }

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowError(false);
        setAnimalType("");
        setIntestinalEnvironmentType("");
        setTitle("");
        setImageOverview("");
        navigate(`/pp_mst_generic_rcmnd_image`);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const params = new URLSearchParams();
        params.append("animal_type", animalType);
        params.append("intestinal_environment_type", intestinalEnvironmentType);
        params.append("title", title);
        params.append("image_overview", imageOverview);
        params.append("page", "1");

        if (animalType === "" && intestinalEnvironmentType === "" && title === "" && imageOverview === "") {
            // 未入力で検索した場合
            setShowError(true);
            return;
        }

        setShowError(false);

        navigate(`/pp_mst_generic_rcmnd_image?${params.toString()}`);
    }

    const onClickCreate = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/pp_mst_generic_rcmnd_image/create`);
    };

    const intestinalEnvironments = GetIntestinalEnvironmentTypes();
    const intestinalEnvironmentKeys = Object.keys(intestinalEnvironments);

    return <StyledSearchForm onSubmit={onSubmit}>

        <div className="box">

            <H2 title="情報を検索する" subtitle="input"/>

            <div className="row">

                <div className="left">

                    <h5>情報を検索する</h5>

                    <div className={showError ? "search-form error" : "search-form"}>

                        {showError && <span className="error">検索内容を入力してください</span>}

                        <div className="flex">
                            <div className="form-group">
                                <label>動物種類</label>
                                <select name="animal_type" value={animalType} onChange={onChange}>
                                    <option value="">---選択してください</option>
                                    <option value="1">1：犬</option>
                                    <option value="2">2：猫</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label>腸内環境タイプ</label>
                                <select name="intestinal_environment_type" value={intestinalEnvironmentType} onChange={onChange}>
                                    <option value="">---選択してください</option>
                                    {intestinalEnvironmentKeys.map((d, i) => {
                                        return <option value={d} key={`iet-${i}`}>{d}：{intestinalEnvironments[d]}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="flex">
                            <div className="form-group">
                                <label>タイトル</label>
                                <input type="text" name="title" placeholder="例：オリゴ糖が多く含まれる" value={title} onChange={onChange}/>
                            </div>
                            <div className="form-group">
                                <label>イメージ概要</label>
                                <input type="text" name="image_overview" placeholder="例：小麦" value={imageOverview} onChange={onChange}/>
                            </div>
                        </div>

                    </div>

                </div>

                {role === AccesstokenRole.RoleMaster && <>
                    <div className="right">

                        <h5>情報を新規登録する</h5>

                        <div>
                            <div className="btn-area">
                                <Button color={ButtonColor.Orange} type="button" icon={ButtonIcon.Arrow} onClick={onClickCreate}>新規登録</Button>
                            </div>
                        </div>

                    </div>
                </>}

            </div>

            <div className="btn-area mt40">
                <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                <Button color={ButtonColor.Orange} type="submit" icon={ButtonIcon.Arrow}>検索</Button>
            </div>

        </div>


    </StyledSearchForm>

}

const StyledSearchForm = styled(StyledForm)`

    .row {
        display: flex;
        justify-content: space-between;

        h5 {
            background-color: #EFEFEF;
            font-weight: 400;
            line-height: 36px;
            font-size: 14px;
            padding: 0 10px 0 30px;
            background-image: url(${rightOrange2});
            background-repeat: no-repeat;
            background-position: left 15px top 50%;
        }

        .left {
            flex: 1;
            margin-right: 23px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            overflow: hidden;
            border: 1px solid #EFEFEF;

            .search-form {
                padding: 22px;

                &.error {
                    position: relative;

                    input, select {
                        background-color: #F7D7DA;
                    }
                }

                .error {
                    background-color: #D93E4C;
                    color: #fff;
                    line-height: 47px;
                    display: block;
                    position: absolute;
                    top: -30px;
                    left: 180px;
                    padding: 0 16px;
                    border-radius: 4px;

                    &:before {
                        position: absolute;
                        border: 8px solid transparent;
                        border-top: 8px solid #D93E4C;
                        bottom: -23px;
                        top: auto;
                        left: 10px;
                        transform: translateY(-50%);
                        content: "";
                        display: block;
                    }
                }

            }


            .form-group {
                padding: 6px 20px 0 0;
                margin-bottom: 0;
            }


            .flex {
                display: flex;
                justify-content: flex-start;

                .form-group {
                    width: auto;
                    min-width: 500px;
                }
            }


            label {
                margin-right: 0;
            }

            input, select {
                width: auto;
                flex: 1;
            }

        }

        .right {
            width: 338px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            overflow: hidden;
            border: 1px solid #EFEFEF;

            .btn-area {
                margin-top: 60px;

                button {
                    width: 240px;
                }
            }
        }

    }

`