import {Modal} from "../../result/Modal";
import {ModalColor} from "../../sample_id/Modal";
import {Button, ButtonColor, ButtonIcon} from "../../common/Button";
import React, {useContext, useState} from "react";
import styled from "styled-components";
import {StyledForm} from "../../common/StyledForm";
import {AppContext} from "../../../contexts/AppContext";
import {ErrorMessage} from "../../common/ErrorMessage";
import {adminPpTestKitApi} from "../../../api/Api";
import {PpTestKitControllerSetIdentificationCdRequest} from "../../../generated";

interface Props {
    sampleId: string;
    oldValue: string | null;
    onCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onComplete: () => void;
}

export const IdentificationCdModal = ({sampleId, oldValue, onCancel, onComplete}: Props) => {

    const {setShowSpinner, setDangerMessage, setCompleteMessage} = useContext(AppContext);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const [identificationCd, setIdentificationCd] = useState<string>(oldValue ?? "");

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setShowSpinner(true);

        const req: PpTestKitControllerSetIdentificationCdRequest = {
            sample_id: sampleId,
            identification_cd: identificationCd,
        };

        adminPpTestKitApi
            .v1AdminPpTestKitSetIdentificationCdPost(req)
            .then(() => {
                onComplete();
                setCompleteMessage("識別コードを変更いたしました。");
            })
            .catch((err) => {
                if (err.response.status === 406) {
                    setErrors(err.response.data);
                } else if (err.response.data && err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました。");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });

    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIdentificationCd(e.currentTarget.value);
    }

    return <Modal color={ModalColor.Success}>

        <StyledModal onSubmit={onSubmit}>

            <h4>識別コードの変更</h4>
            <h5>
                識別コードを変更する場合は、<br/>
                下記に識別コードを入力してください。
            </h5>

            <div className="form-group">
                <label>識別コード</label>
                <div>
                    <input type="text" placeholder="例：TS123456" value={identificationCd} onChange={onChange} maxLength={8}/>
                    <ErrorMessage message={errors["identification_cd"]} wrap={true} top={-50}/>
                </div>
            </div>

            <div className="btn-area">
                <Button type="button" color={ButtonColor.Gray} icon={ButtonIcon.Arrow} onClick={onCancel}>キャンセル</Button>
                <Button type="submit" color={ButtonColor.Green} icon={ButtonIcon.Arrow}>更新する</Button>
            </div>

        </StyledModal>

    </Modal>

}

const StyledModal = styled(StyledForm)`
    h5 {
        padding: 20px 0;
        line-height: 1.7 !important;
    }

    .form-group {
        padding: 40px 0;
        background-color: #fff;
        justify-content: center;

        label {
            text-align: right;
            padding-right: 0;
            width: 38%;
        }

        > div {
            flex: 1;
        }

        input {
            width: 240px;
        }
    }

    .btn-area {
        margin: 0;
    }

`;