import styled from "styled-components";
import React from "react";
import {MainColor} from "./Colors";

export const Table = ({children}: React.PropsWithChildren) => {

    return <StyledTable>
        <div className="table-wrapper">
            <table>
                {children}
            </table>
        </div>
    </StyledTable>;

};

const StyledTable = styled.div`

  .table-wrapper {
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 20px;

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 2px;

      thead {
        th {
          background-color: #fff;
          color: ${MainColor};
          padding: 10px 8px;
          font-weight: normal;
          text-align: left;
          font-size: 14px;
          vertical-align: bottom;

          span {
            color: ${MainColor};
            font-size: 14px;
          }

          a {
            font-size: 14px;
          }
        }
      }

      tbody {
        tr:nth-child(odd) {
          td {
            background-color: #F4F9F8;
          }
        }

        th {
          font-weight: 400;
          background-color: #eee;
          text-align: center;
        }

        td {
          padding: 10px 8px;
          font-size: 14px;
          white-space: nowrap;

          a, span {
            font-size: 14px;
            cursor: pointer;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }

        }

      }

      // 赤文字
      .red {
        color: red;
      }

    }

  }



`;
