import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {AxiosResponse} from "axios";
import {ErrorMessage} from "../common/ErrorMessage";
import {adminPpAnnouncementUserApi} from "../../api/Api";
import {PpAnnouncementUserControllerEditRequest, TypesPpAnnouncementUserData} from "../../generated";

export const PpAnnouncementUserEdit = () => {

    const {setShowSpinner, setDangerMessage, setSuccessMessage} = useContext(AppContext);
    const [ppAnnouncementUser, setPpAnnouncementUser] = useState<TypesPpAnnouncementUserData | null>(null)
    const [announcementTitle, setAnnouncementTitle] = useState<string>("");
    const [announcementDetail, setAnnouncementDetail] = useState<string>("");
    const [displayYmd, setDisplayYmd] = useState<string>("");
    const [displayPeriodFrom, setDisplayPeriodFrom] = useState<string>("");
    const [displayPeriodTo, setDisplayPeriodTo] = useState<string>("");
    const params = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {

        if (params.id) {
            // お知らせIDが指定されている場合は、お知らせを取得する
            getTopic(params.id);
        }

    }, [params]);

    const getTopic = (id: string) => {

        setShowSpinner(true);

        adminPpAnnouncementUserApi.v1AdminPpAnnouncementUserGetIdGet(id)
            .then(({data}) => {
                setPpAnnouncementUser(data.pp_announcement_user ?? null);

                setAnnouncementTitle(data.pp_announcement_user?.announcement_title ?? "");
                setAnnouncementDetail(data.pp_announcement_user?.announcement_detail ?? "");
                setDisplayYmd(data.pp_announcement_user?.display_ymd ?? "");
                setDisplayPeriodFrom(data.pp_announcement_user?.display_period_from ?? "");
                setDisplayPeriodTo(data.pp_announcement_user?.display_period_to ?? "");

            })
            .catch((err) => {
                console.log(err);

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/pp_announcement_user")
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        setShowSpinner(true);

        let promise: Promise<void | AxiosResponse<any, any>>;

        const req: PpAnnouncementUserControllerEditRequest = {
            display_ymd: displayYmd,
            announcement_title: announcementTitle,
            announcement_detail: announcementDetail,
            display_period_from: displayPeriodFrom,
            display_period_to: displayPeriodTo,
        };

        if (ppAnnouncementUser) {
            // 編集時

            promise = adminPpAnnouncementUserApi.v1AdminPpAnnouncementUserEditIdPost(String(ppAnnouncementUser.id), req)
                .then(() => {
                    setSuccessMessage("お知らせの編集が完了しました。");
                    setErrors({});
                    navigate(-1);
                });

        } else {
            // 新規登録時
            promise = adminPpAnnouncementUserApi.v1AdminPpAnnouncementUserCreatePost(req)
                .then(() => {
                    setSuccessMessage("お知らせの新規追加が完了しました。");
                    setErrors({});
                    navigate(-1);
                });
        }

        promise
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {

        switch (e.currentTarget.name) {
            case "display_ymd":
                setDisplayYmd(e.currentTarget.value);
                break;
            case "announcement_title":
                setAnnouncementTitle(e.currentTarget.value);
                break;
            case "announcement_detail":
                setAnnouncementDetail(e.currentTarget.value);
                break;
            case "display_period_from":
                setDisplayPeriodFrom(e.currentTarget.value);
                break;
            case "display_period_to":
                setDisplayPeriodTo(e.currentTarget.value);
                break;
        }
    };

    // 前の画面に戻る
    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(-1);
    };

    return <Layout title={"一般用お知らせ管理"}>

        <div className="box">

            <H3 title={"お知らせ設定"}/>

            <StyledTopicsEdit onSubmit={onSubmit}>

                <div className="form-wrapper">

                    <div className="form-group">
                        <label className="req">表示日付</label>
                        <div>
                            <input type="date" name="display_ymd" value={displayYmd} onChange={onChange} placeholder={"YYYY-MM-DD"}/>
                            <ErrorMessage message={errors["display_ymd"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">タイトル</label>
                        <div>
                            <input type="text" name="announcement_title" value={announcementTitle} className="long" onChange={onChange} placeholder={"100文字以内"} maxLength={100}/>
                            <ErrorMessage message={errors["announcement_title"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">本文</label>
                        <div>
                            <textarea name="announcement_detail" defaultValue={announcementDetail} onChange={onChange} placeholder={"お知らせ本文"}/>
                            <ErrorMessage message={errors["announcement_detail"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">表示期間</label>
                        <div>
                            <div className="inline">
                                <input type="date" name="display_period_from" value={displayPeriodFrom} onChange={onChange} placeholder={"YYYY-MM-DD"}/>
                                <span>〜</span>
                                <input type="date" name="display_period_to" value={displayPeriodTo} onChange={onChange} placeholder={"YYYY-MM-DD"}/>
                            </div>
                            <ErrorMessage message={errors["display_period_from"]}/>
                            <ErrorMessage message={errors["display_period_to"]}/>
                        </div>
                    </div>

                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type={"button"} onClick={onClickBack} icon={ButtonIcon.Arrow}>前の画面に戻る</Button>
                    <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
                </div>

            </StyledTopicsEdit>

        </div>

    </Layout>

};

const StyledTopicsEdit = styled(StyledForm)`

`;
