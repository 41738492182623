import {H3} from "../common/Headline";

interface Props {
    showReq: boolean;
    email: string;
}

export const LoginInfo = ({email, showReq}: Props) => {

    return <div className="box">

        <H3 title="ログイン情報" subtitle="login"/>

        <div className="form-wrapper">

            <div className="form-group center">
                <label className={showReq ? "req" : ""}>ログインID</label>
                <div>
                    {email}
                </div>
            </div>

            <div className="form-group center">
                <label className={showReq ? "req" : ""}>パスワード</label>
                <div>
                    ********
                </div>
            </div>

        </div>

    </div>
};