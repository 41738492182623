import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "../common/Button";
import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {H2} from "../common/Headline";
import downOrange from "../../images/down-orange.svg";
import upOrange from "../../images/up-orange.svg";
import {ConvertYmdToDate, GetDateList, GetMonthList, GetTestDivision, GetYearList, IsValidDate, Zerofill} from "../../utility/Utility";
import {AppContext} from "../../contexts/AppContext";
import {AccesstokenRole} from "../../generated";
import {PartnerInstInput} from "../common/PartnerInstInput";
import {MedicInstInput} from "../common/MedicInstInput";

interface Props {
    showError: boolean;
    onClear: () => void;
    statuses: Map<string, string>;
}

export const SearchForm = ({showError, onClear, statuses}: Props) => {

    const {role} = useContext(AppContext);
    const [sampleId, setSampleId] = useState<string>("");
    const [karteId, setKarteId] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [lastNameKana, setLastNameKana] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [firstNameKana, setFirstNameKana] = useState<string>("");
    const [testDivision, setTestDivision] = useState<string>("");
    const [petName, setPetName] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const [fromYear, setFromYear] = useState<string>("");
    const [fromMonth, setFromMonth] = useState<string>("");
    const [fromDate, setFromDate] = useState<string>("");
    const [toYear, setToYear] = useState<string>("");
    const [toMonth, setToMonth] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");
    const [from, setFrom] = useState<string>("");
    const [to, setTo] = useState<string>("");
    const [fromStartYear, setFromStartYear] = useState<string>("");
    const [fromStartMonth, setFromStartMonth] = useState<string>("");
    const [fromStartDate, setFromStartDate] = useState<string>("");
    const [toStartYear, setToStartYear] = useState<string>("");
    const [toStartMonth, setToStartMonth] = useState<string>("");
    const [toStartDate, setToStartDate] = useState<string>("");
    const [fromStart, setFromStart] = useState<string>("");
    const [toStart, setToStart] = useState<string>("");
    const [partnerInstId, setPartnerInstId] = useState<string>("");
    const [medicInstId, setMedicInstId] = useState<string>("");
    const [isFilterStatus, setIsFilterStatus] = useState<boolean>(false);
    const [isDateErr, setIsDateErr] = useState<boolean>(false);
    const [isStartDateErr, setIsStartDateErr] = useState<boolean>(false);
    const location = useLocation();

    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const sessionKey = "pp_test_status_search_form_menu_open";

    useEffect(() => {

        const s = new URLSearchParams(location.search);

        setSampleId(s.get("sample_id") || "");
        setKarteId(s.get("karte_id") || "");
        setLastName(s.get("last_name") || "");
        setLastNameKana(s.get("last_name_kana") || "");
        setFirstName(s.get("first_name") || "");
        setFirstNameKana(s.get("first_name_kana") || "");
        setPetName(s.get("pet_name") || "");
        setTestDivision(s.get("test_division") || "");
        setStatus(s.get("status") || "");
        setFrom(s.get("from") || "");
        setTo(s.get("to") || "");
        setFromStart(s.get("from_start") || "");
        setToStart(s.get("to_start") || "");
        setPartnerInstId(s.get("partner_inst_id") || "");
        setMedicInstId(s.get("medic_inst_id") || "");

        const from = s.get("from") || "";
        if (from !== "") {
            const parts = from.split("-");
            setFromYear(parts[0]);
            setFromMonth(parts[1]);
            setFromDate(parts[2]);
        }

        const to = s.get("to") || "";
        if (to !== "") {
            const parts = to.split("-");
            setToYear(parts[0]);
            setToMonth(parts[1]);
            setToDate(parts[2]);
        }

        const fromStart = s.get("from_start") || "";
        if (fromStart !== "") {
            const parts = fromStart.split("-");
            setFromStartYear(parts[0]);
            setFromStartMonth(parts[1]);
            setFromStartDate(parts[2]);
        }

        const toStart = s.get("to_start") || "";
        if (toStart !== "") {
            const parts = toStart.split("-");
            setToStartYear(parts[0]);
            setToStartMonth(parts[1]);
            setToStartDate(parts[2]);
        }

    }, []);

    useEffect(() => {
        // 以前の開閉状態を復元
        const oldMenuOpen = sessionStorage.getItem(sessionKey) === "1";
        setIsMenuOpen(oldMenuOpen);
    }, []);

    useEffect(() => {
        if (IsValidDate(from) && IsValidDate(to)) {
            const fromDt = ConvertYmdToDate(from);
            const toDt = ConvertYmdToDate(to);
            setIsDateErr(fromDt.getTime() > toDt.getTime());
        } else {
            setIsDateErr(false);
        }
    }, [from, to])

    useEffect(() => {
        if (IsValidDate(fromStart) && IsValidDate(toStart)) {
            const fromDt = ConvertYmdToDate(fromStart);
            const toDt = ConvertYmdToDate(toStart);
            setIsStartDateErr(fromDt.getTime() > toDt.getTime());
        } else {
            setIsStartDateErr(false);
        }
    }, [fromStart, toStart])

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {

        switch (e.currentTarget.name) {
            case "sample_id":
                setSampleId(e.currentTarget.value);
                break;
            case "karte_id":
                setKarteId(e.currentTarget.value);
                break;
            case "last_name":
                setLastName(e.currentTarget.value);
                break;
            case "last_name_kana":
                setLastNameKana(e.currentTarget.value);
                break;
            case "first_name":
                setFirstName(e.currentTarget.value);
                break;
            case "first_name_kana":
                setFirstNameKana(e.currentTarget.value);
                break;
            case "pet_name":
                setPetName(e.currentTarget.value);
                break;
            case "test_division":
                setTestDivision(e.currentTarget.value);
                break;
            case "status":
                setStatus(e.currentTarget.value);
                break;
            case "from_year":
                setFromYear(e.currentTarget.value);
                setFrom(`${e.currentTarget.value}-${fromMonth}-${fromDate}`);
                break;
            case "from_month":
                setFromMonth(e.currentTarget.value);
                setFrom(`${fromYear}-${e.currentTarget.value}-${fromDate}`);
                break;
            case "from_date":
                setFromDate(e.currentTarget.value);
                setFrom(`${fromYear}-${fromMonth}-${e.currentTarget.value}`);
                break;
            case "to_year":
                setToYear(e.currentTarget.value);
                setTo(`${e.currentTarget.value}-${toMonth}-${toDate}`);
                break;
            case "to_month":
                setToMonth(e.currentTarget.value);
                setTo(`${toYear}-${e.currentTarget.value}-${toDate}`);
                break;
            case "to_date":
                setToDate(e.currentTarget.value);
                setTo(`${toYear}-${toMonth}-${e.currentTarget.value}`);
                break;
            case "from_start_year":
                setFromStartYear(e.currentTarget.value);
                setFromStart(`${e.currentTarget.value}-${fromStartMonth}-${fromStartDate}`);
                break;
            case "from_start_month":
                setFromStartMonth(e.currentTarget.value);
                setFromStart(`${fromStartYear}-${e.currentTarget.value}-${fromStartDate}`);
                break;
            case "from_start_date":
                setFromStartDate(e.currentTarget.value);
                setFromStart(`${fromStartYear}-${fromStartMonth}-${e.currentTarget.value}`);
                break;
            case "to_start_year":
                setToStartYear(e.currentTarget.value);
                setToStart(`${e.currentTarget.value}-${toStartMonth}-${toStartDate}`);
                break;
            case "to_start_month":
                setToStartMonth(e.currentTarget.value);
                setToStart(`${toStartYear}-${e.currentTarget.value}-${toStartDate}`);
                break;
            case "to_start_date":
                setToStartDate(e.currentTarget.value);
                setToStart(`${toStartYear}-${toStartMonth}-${e.currentTarget.value}`);
                break;
            case "partner_inst_id":
                setPartnerInstId(e.currentTarget.value);
                break;
            case "medic_inst_id":
                setMedicInstId(e.currentTarget.value);
                break;
        }

    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        // URLを作成する
        const params = new URLSearchParams();
        params.append("sample_id", sampleId);
        params.append("karte_id", karteId);
        params.append("last_name", lastName);
        params.append("first_name", firstName);
        params.append("last_name_kana", lastNameKana);
        params.append("first_name_kana", firstNameKana);
        params.append("pet_name", petName);
        params.append("test_division", testDivision);
        params.append("status", status);
        params.append("from", from === "--" ? "" : from);
        params.append("to", to === "--" ? "" : to);
        params.append("from_start", fromStart === "--" ? "" : fromStart);
        params.append("to_start", toStart === "--" ? "" : toStart);
        params.append("partner_inst_id", partnerInstId);
        params.append("medic_inst_id", medicInstId);
        params.append("filter_status", isFilterStatus ? "1" : "0");
        params.append("page", "1");

        navigate(`/pp_test_status?${params.toString()}`);
    };

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        setSampleId("");
        setKarteId("");
        setLastName("");
        setFirstName("");
        setLastNameKana("");
        setFirstNameKana("");
        setPetName("");
        setTestDivision("");
        setStatus("");
        setFrom("");
        setFromYear("");
        setFromMonth("");
        setFromDate("");
        setTo("");
        setToYear("");
        setToMonth("");
        setToDate("");
        setFromStart("");
        setFromStartYear("");
        setFromStartMonth("");
        setFromStartDate("");
        setToStart("");
        setToStartYear("");
        setToStartMonth("");
        setToStartDate("");
        setPartnerInstId("");
        setMedicInstId("");
        setIsFilterStatus(false);
        navigate(`/pp_test_status`);
        onClear();
    }

    // メニュー開閉ハンドラー
    const onMenuToggle = (e: React.MouseEvent<HTMLSpanElement>): void => {
        e.preventDefault();
        setIsMenuOpen(!isMenuOpen);
        sessionStorage.setItem(sessionKey, isMenuOpen ? "0" : "1");
    };

    const getStatus = (): JSX.Element[] => {
        const elements: JSX.Element[] = [];
        statuses.forEach((val, key) => {

            if (isFilterStatus && key !== "22") {
                // isFilterStatusがチェックされたら、強制検査中のみにする
                return;
            }

            elements.push(<option value={key} key={`status-${key}`}>{val}</option>);
        });

        return elements;
    };

    const onChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsFilterStatus(e.currentTarget.checked);

        if (e.currentTarget.checked && status !== "22") {
            // isFilterStatusがチェックされて、強制検査中以外を選択時はクリアする
            setStatus("22");
        } else {
            setStatus("");
        }
    };

    const testDivisions = GetTestDivision();

    // 病院、提携法人プルダウン表示チェック
    const showPartnerPull = [String(AccesstokenRole.RoleOperator), String(AccesstokenRole.RoleMaster)].indexOf(role ?? "") !== -1;

    // 「今日」ボタンクリック
    const onClickToday = (e: React.MouseEvent<HTMLButtonElement>, type: string): void => {
        e.preventDefault();

        const n = new Date();
        const today = `${n.getFullYear()}-${Zerofill(n.getMonth() + 1, 2)}-${Zerofill(n.getDate(), 2)}`;

        if (type === "complete") {
            // 検査完了日の場合
            setFromYear(n.getFullYear().toString());
            setToYear(n.getFullYear().toString());
            setFromMonth(Zerofill(n.getMonth() + 1, 2));
            setToMonth(Zerofill(n.getMonth() + 1, 2));
            setFromDate(Zerofill(n.getDate(), 2));
            setToDate(Zerofill(n.getDate(), 2));
            setFrom(today);
            setTo(today);
        } else if (type === "start") {
            // 検査受付日の場合
            setFromStartYear(n.getFullYear().toString());
            setToStartYear(n.getFullYear().toString());
            setFromStartMonth(Zerofill(n.getMonth() + 1, 2));
            setToStartMonth(Zerofill(n.getMonth() + 1, 2));
            setFromStartDate(Zerofill(n.getDate(), 2));
            setToStartDate(Zerofill(n.getDate(), 2));
            setFromStart(today);
            setToStart(today);
        }
    }

    return <StyledSearchForm noValidate={true} onSubmit={onSubmit} className={showError ? "error" : ""}>

        <div className="box">

            <H2 title="検索内容の入力" subtitle="search"/>

            <div className="search-form">

                <div className="form-group with-error">
                    <label>検体ID</label>
                    <div>
                        <input type="text" name="sample_id" maxLength={18} placeholder={"例：PC23-1000-NGS-1000"} value={sampleId} onChange={onChange}/>
                        {showError && <span className="error">検索内容を入力してください</span>}
                    </div>
                </div>

                <div className="form-group">
                    <label>カルテID</label>
                    <div>
                        <input type="text" name="karte_id" maxLength={100} placeholder={"例：あ-1234-P1"} value={karteId} onChange={onChange}/>
                    </div>
                </div>

                <div className="toggle-area" style={{display: isMenuOpen ? "block" : "none"}}>

                    <div className="form-group">
                        <label>飼い主の名前</label>
                        <div className="inline">
                            <span className="w30">姓</span>
                            <input type="text" name="last_name" maxLength={40} placeholder={"例：プラウメッド"} value={lastName} onChange={onChange} style={{width: "240px"}}/>
                            <span className="w30">名</span>
                            <input type="text" name="first_name" maxLength={40} placeholder={"例：太郎"} value={firstName} onChange={onChange} style={{width: "240px"}}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>フリガナ</label>
                        <div className="inline">
                            <span className="w30">セイ</span>
                            <input type="text" name="last_name_kana" maxLength={60} placeholder={"例：プラウメッド"} value={lastNameKana} onChange={onChange} style={{width: "240px"}}/>
                            <span className="w30">メイ</span>
                            <input type="text" name="first_name_kana" maxLength={60} placeholder={"例：タロウ"} value={firstNameKana} onChange={onChange} style={{width: "240px"}}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>ペットの名前</label>
                        <div>
                            <div className="inline">
                                <input type="text" name="pet_name" maxLength={50} placeholder={"例：にゃんにゃん"} value={petName} onChange={onChange} style={{width: "240px"}}/>

                                <span>種類</span>
                                <select name="test_division" value={testDivision} onChange={onChange}>
                                    <option value="">-- 選択してください</option>
                                    {Object.keys(testDivisions).map((key, i) => {
                                        return <option value={key} key={`test_division-${i}`}>{testDivisions[key]}</option>

                                    })}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>ステータス</label>
                        <div className="status-area">
                            <select name="status" value={status} onChange={onChange}>
                                <option value="">-- 選択してください</option>
                                {getStatus()}
                            </select>
                            <label>
                                <input type="checkbox" checked={isFilterStatus} onChange={onChangeFilter}/>
                                検査の申込済
                            </label>
                        </div>
                    </div>

                    <div className="form-group with-error date-area">
                        <label>検査完了日</label>
                        <div>
                            <div className="inline">
                                <select name="from_year" value={fromYear} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetYearList(true).map((d, i) => {
                                        return <option key={`from_year-${i}`} value={d}>{d}</option>
                                    })}
                                </select>
                                <span>年</span>
                                <select name="from_month" value={fromMonth} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetMonthList().map((d, i) => {
                                        return <option key={`from_month-${i}`} value={d}>{d}</option>
                                    })}                            </select>
                                <span>月</span>
                                <select name="from_date" value={fromDate} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetDateList().map((d, i) => {
                                        return <option key={`from_date-${i}`} value={d}>{d}</option>
                                    })}
                                </select>
                                <span>日</span>

                                <span>〜</span>

                                <select name="to_year" value={toYear} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetYearList(true).map((d, i) => {
                                        return <option key={`to_year-${i}`} value={d}>{d}</option>
                                    })}
                                </select>
                                <span>年</span>
                                <select name="to_month" value={toMonth} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetMonthList().map((d, i) => {
                                        return <option key={`to_month-${i}`} value={d}>{d}</option>
                                    })}                            </select>
                                <span>月</span>
                                <select name="to_date" value={toDate} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetDateList().map((d, i) => {
                                        return <option key={`to_date-${i}`} value={d}>{d}</option>
                                    })}
                                </select>
                                <span>日</span>

                                <Button type="button" color={ButtonColor.Green} size={ButtonSize.Small} icon={ButtonIcon.Arrow} onClick={(e) => onClickToday(e, "complete")}>今日</Button>

                                {isDateErr && <span className="error">日付の指定が正しくありません</span>}

                            </div>
                        </div>
                    </div>

                    <div className="form-group with-error date-area">
                        <label>検査受付日</label>
                        <div>
                            <div className="inline">
                                <select name="from_start_year" value={fromStartYear} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetYearList(true).map((d, i) => {
                                        return <option key={`from_start_year-${i}`} value={d}>{d}</option>
                                    })}
                                </select>
                                <span>年</span>
                                <select name="from_start_month" value={fromStartMonth} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetMonthList().map((d, i) => {
                                        return <option key={`from_start_month-${i}`} value={d}>{d}</option>
                                    })}                            </select>
                                <span>月</span>
                                <select name="from_start_date" value={fromStartDate} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetDateList().map((d, i) => {
                                        return <option key={`from_start_date-${i}`} value={d}>{d}</option>
                                    })}
                                </select>
                                <span>日</span>

                                <span>〜</span>

                                <select name="to_start_year" value={toStartYear} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetYearList(true).map((d, i) => {
                                        return <option key={`to_start_year-${i}`} value={d}>{d}</option>
                                    })}
                                </select>
                                <span>年</span>
                                <select name="to_start_month" value={toStartMonth} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetMonthList().map((d, i) => {
                                        return <option key={`to_start_month-${i}`} value={d}>{d}</option>
                                    })}                            </select>
                                <span>月</span>
                                <select name="to_start_date" value={toStartDate} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetDateList().map((d, i) => {
                                        return <option key={`to_start_date-${i}`} value={d}>{d}</option>
                                    })}
                                </select>
                                <span>日</span>

                                <Button type="button" color={ButtonColor.Green} size={ButtonSize.Small} icon={ButtonIcon.Arrow} onClick={(e) => onClickToday(e, "start")}>今日</Button>

                                {isStartDateErr && <span className="error">日付の指定が正しくありません</span>}

                            </div>
                        </div>
                    </div>

                    {showPartnerPull && <div className="form-group">
                        <label>提携法人名</label>
                        <div>
                            <PartnerInstInput name="partner_inst_id" onChange={(name: string, n: number | null) => {
                                console.log(name); // linter対応なので本来は不要
                                setPartnerInstId(n ? String(n) : "");
                            }} value={partnerInstId ? Number(partnerInstId) : null}/>
                        </div>
                    </div>}

                    {showPartnerPull && <div className="form-group">
                        <label>病院名</label>
                        <div>
                            <MedicInstInput name="medic_inst_id" onChange={(name: string, n: number | null) => {
                                console.log(name); // linter対応なので本来は不要
                                setMedicInstId(n ? String(n) : "");
                            }} value={medicInstId ? Number(medicInstId) : null}/>
                        </div>
                    </div>}

                </div>

                <div className="btn-toggle">
                    <span onClick={onMenuToggle} className={isMenuOpen ? "opened" : "closed"}>{isMenuOpen ? "メニューを閉じる" : "メニューを開く"}</span>
                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                    <Button color={ButtonColor.Orange} type="submit" icon={ButtonIcon.Arrow}>検索</Button>
                </div>

            </div>

        </div>

    </StyledSearchForm>
};

export const StyledSearchForm = styled(StyledForm)`

    &.error {
        input[type=text], select {
            background-color: rgba(217, 62, 76, 0.2);
        }
    }

    // inlineフォームのspanの幅を固定する

    .search-form {

        .form-group {

            label {
                width: 90px;
            }

            .inline {
                span {
                    &.w30 {
                        width: 30px;
                    }
                }
            }

      &.with-error {
        position: relative;

        &.date-area {
          .error {
            left: 750px;

          }
        }

        .error {
          background-color: #D93E4C;
          color: #fff;
          line-height: 47px;
          display: block;
          position: absolute;
          top: -2px;
          left: 320px;
          padding: 0 16px;
          border-radius: 4px;

          &:before {
            position: absolute;
            border: 8px solid transparent;
            border-right: 8px solid #D93E4C;
            top: 50%;
            left: -16px;
            transform: translateY(-50%);
            content: "";
            display: block;
          }
        }
      }

      .status-area {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        label {
          margin-left: 15px;
          width: 140px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #EFEFEF;
          padding: 5px 0;
          text-align: center;
          position: relative;
          border-radius: 4px;

          &:before {
            content: "";
            display: block;
            border: 7px solid transparent;
            border-right: 7px solid #EFEFEF;
            top: 50%;
            left: -13px;
            position: absolute;
            transform: translateY(-50%);
          }

          input[type=checkbox] {
            margin-right: 5px;
          }
        }
      }
    }

    .toggle-area {
      border-top: 1px dashed #ccc;
      padding: 10px 0 0 0;
      margin-top: 20px;
    }

    .btn-toggle {
      border-top: 1px dashed #ccc;
      margin-top: 40px;

      span {
        display: block;
        text-align: center;
        margin: -1px auto 0 auto;
        background-color: #eee;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        font-size: 14px;
        width: 148px;
        cursor: pointer;
        line-height: 36px;
        background-image: url(${downOrange});
        background-repeat: no-repeat;
        background-position: right 13px top 50%;
        padding-right: 10px;

        &.opened {
          background-image: url(${upOrange});
        }
      }
    }

  }
`;