import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import {Counter} from "../common/Counter";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {adminPpMstGenericRcmndGroupApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";
import {AccesstokenRole, PpMstGenericRcmndGroupControllerIndexRequest, TypesPpMstGenericRcmndGroupData} from "../../generated";
import {Table} from "../common/Table";
import {Paginator} from "../common/Paginator";
import styled from "styled-components";
import {MainColor} from "../common/Colors";
import rightWhite from "../../images/right-white.svg";
import {CompleteMessage} from "../common/CompleteMessage";
import {SearchForm} from "./SearchForm";
import {GetGenericRcmndDisplayMethodStr} from "../../utility/Utility";

export const PpMstGenericRcmndGroupIndex = () => {

    const {setShowSpinner, setDangerMessage, setCompleteMessage, role} = useContext(AppContext);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [ppMstGenericRcmndGroups, setPpMstGenericRcmndGroups] = useState<TypesPpMstGenericRcmndGroupData[]>([]);
    const navigate = useNavigate();
    const location = useLocation();

    const size = 200;

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const messageType = params.get("message_type") ?? "";

        if (messageType === "create_complete") {
            setCompleteMessage(`リコメンド表示パターンの新規追加が完了いたしました。`);
        }

        if (messageType === "edit_complete") {
            setCompleteMessage(`リコメンド表示パターンの編集が完了いたしました`);
        }

    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get("page")) {
            setPage(Number(params.get("page")));
        }

        getData();
    }, [location.search]);

    const getData = () => {

        setShowSpinner(true);

        const params = new URLSearchParams(location.search);

        const genericRcmndGroupTitle = params.get("generic_rcmnd_group_title") ?? "";
        const genericRcmndDisplayMethod = params.get("generic_rcmnd_display_method") ?? "";

        const req: PpMstGenericRcmndGroupControllerIndexRequest = {
            page: page,
            size: size,
            generic_rcmnd_group_title: genericRcmndGroupTitle,
            generic_rcmnd_display_method: genericRcmndDisplayMethod,
        };

        adminPpMstGenericRcmndGroupApi
            .v1AdminPpMstGenericRcmndGroupPost(req)
            .then(({data}) => {
                setPpMstGenericRcmndGroups(data.pp_mst_generic_rcmnd_groups);
                setTotal(data.total ?? 0);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onDelete = (e: React.MouseEvent<HTMLSpanElement>, ppMstGenericRcmndGroup: TypesPpMstGenericRcmndGroupData) => {

        e.preventDefault();

        if (!window.confirm(`${ppMstGenericRcmndGroup.generic_rcmnd_group_title} を削除します。よろしいですか？`)) {
            return;
        }

        setShowSpinner(true);

        adminPpMstGenericRcmndGroupApi
            .v1AdminPpMstGenericRcmndGroupDeleteIdDelete(String(ppMstGenericRcmndGroup.id))
            .then(() => {
                setCompleteMessage(`汎用リコメンドグループ：${ppMstGenericRcmndGroup.generic_rcmnd_group_title}を削除いたしました。`);
                getData();
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onPageChange = (page: number) => {
        setPage(page);
        navigate(`/pp_mst_generic_rcmnd_group?page=${page}`);
    }

    return <Layout title="汎用リコメンドグループ管理">

        <SearchForm/>

        <CompleteMessage/>

        <StyledPpMstSkuIndex className="box">

            <H3 title="グループ一覧" subtitle="list" counter={<Counter total={total} size={size} current={page}/>}/>

            <Table>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>汎用リコメンド表示方式</th>
                    <th>汎用リコメンドグループタイトル</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>

                <tbody>

                {ppMstGenericRcmndGroups.map((d, i) => {

                    return <tr key={`sku-${i}`}>
                        <td>{d.id}</td>
                        <td>{GetGenericRcmndDisplayMethodStr(d.generic_rcmnd_display_method)}</td>
                        <td>{d.generic_rcmnd_group_title}</td>
                        {role === AccesstokenRole.RoleMaster && <>
                            <td><NavLink to={`/pp_mst_generic_rcmnd_group/edit/${d.id}`} className="btn-link">編集</NavLink></td>
                            <td><span onClick={(e) => onDelete(e, d)} className="btn-del">削除</span></td>
                        </>}
                    </tr>
                })}

                </tbody>

            </Table>

            <Paginator onClick={onPageChange} pages={Math.ceil(total / size)} page={page}/>

        </StyledPpMstSkuIndex>

    </Layout>
}


const StyledPpMstSkuIndex = styled.div`

    tr {
        td:nth-child(4), td:nth-child(5) {
            text-align: center !important;
            width: 120px;
        }
    }

    .btn-link, .btn-del {
        background-color: ${MainColor};
        color: #fff;
        font-size: 12px !important;
        line-height: 24px;
        display: inline-block;
        text-decoration: none !important;
        padding: 0 30px 0 20px;
        letter-spacing: 1px;
        border-radius: 24px;
        background-image: url(${rightWhite});
        background-repeat: no-repeat;
        background-position: top 50% right 15px;
        background-size: 5px auto;
    }

    .btn-del {
        background-color: #ccc;
    }

`;
