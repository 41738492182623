import {H3} from "../common/Headline";
import React, {useContext, useEffect, useState} from "react";
import {Layout} from "../common/Layout";
import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../contexts/AppContext";
import {adminPpFaqApi, adminPpFaqCategoryApi} from "../../api/Api";
import {AccesstokenRole, PpFaqControllerCreateRequest, TypesPpFaqCategoryData, TypesPpFaqData} from "../../generated";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {AxiosResponse} from "axios";
import {ErrorMessage} from "../common/ErrorMessage";

export const PpFaqEdit = () => {

    const {setShowSpinner, setDangerMessage, role} = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();
    const [ppFaqCategories, setPpFaqCategories] = useState<TypesPpFaqCategoryData[]>([]);
    const [faqCategoryId, setFaqCategoryId] = useState<string>("");
    const [question, setQuestion] = useState<string>("");
    const [answer, setAnswer] = useState<string>("");
    const [active, setActive] = useState<boolean>(true);
    const [ppFaq, setPpFaq] = useState<TypesPpFaqData | null>(null);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        if (role && role !== AccesstokenRole.RoleMaster) {
            navigate("/system");
        }
    }, [role]);

    useEffect(() => {

        if (params.id) {
            // IDが指定されている場合は、データを取得する
            getFaq(params.id);
        }

        getFaqCategories();

    }, [params]);
    const getFaqCategories = () => {

        setShowSpinner(true);

        adminPpFaqCategoryApi
            .v1AdminPpFaqCategoryGet(1, 200)
            .then(({data}) => {
                setPpFaqCategories(data.pp_faq_categories);
            })
            .catch((err) => {

                if (err.response.data && err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました。");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const getFaq = (id: string) => {

        setShowSpinner(true);

        adminPpFaqApi
            .v1AdminPpFaqGetIdGet(id)
            .then(({data}) => {

                const ppFaq = data.pp_faq;

                setPpFaq(ppFaq);

                setFaqCategoryId(String(ppFaq.faq_category_id));
                setQuestion(ppFaq.question);
                setAnswer(ppFaq.answer);
                setActive(ppFaq.active);

            })
            .catch((err) => {

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/pp_faq");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            })

    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {

        e.preventDefault();

        switch (e.currentTarget.name) {
            case "faq_category_id":
                setFaqCategoryId(e.currentTarget.value);
                break;
            case "question":
                setQuestion(e.currentTarget.value);
                break;
            case "answer":
                setAnswer(e.currentTarget.value);
                break;
        }
    }

    const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.currentTarget.name) {
            case "active":
                setActive(e.currentTarget.checked);
                break;
        }
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {

        e.preventDefault();

        setShowSpinner(true);
        setErrors({});

        const req: PpFaqControllerCreateRequest = {
            faq_category_id: Number(faqCategoryId),
            question: question,
            answer: answer,
            active: active,
        };

        let promise: Promise<void | AxiosResponse<any, any>>;

        if (ppFaq !== null) {
            // 編集時
            promise = adminPpFaqApi
                .v1AdminPpFaqEditIdPost(String(ppFaq.id), req);
        } else {
            // 新規登録時
            promise = adminPpFaqApi
                .v1AdminPpFaqCreatePost(req);
        }

        promise
            .then(() => {
                let messageType = "create_complete";
                if (ppFaq !== null) {
                    messageType = "edit_complete";
                }
                navigate(`/pp_faq?message_type=${messageType}`);
            })
            .catch((err) => {
                if (err.response.status === 406) {
                    // バリデーションエラー
                    setDangerMessage("ご入力内容にエラーがありました。");
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    // 前の画面に戻る
    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(-1);
    };


    return <Layout title={"FAQ管理"}>

        <StyledPpMstIdentificationCdEdit onSubmit={onSubmit}>

            <div className="box">

                <H3 title="よくある質問設定" subtitle="FAQ"/>

                <div className="form-wrapper">

                    <div className="form-group">
                        <label className="req">カテゴリー</label>

                        <div>
                            <select name="faq_category_id" value={faqCategoryId} onChange={onChange}>
                                <option value="">-- 選択してください</option>
                                {ppFaqCategories.map((d, i) => {
                                    return <option key={`fc-${i}`} value={d.id}>{d.category_name}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["faq_category_id"]}/>
                        </div>

                    </div>

                    <div className="form-group">
                        <label className="req">質問</label>
                        <div>
                            <p>改行を入れる場合は、&lt;br&gt;タグでご指定ください。</p>
                            <textarea name="question" value={question} onChange={onChange}/>
                            <ErrorMessage message={errors["question"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">回答</label>
                        <div>
                            <p>HTMLタグで入力してください。改行も&lt;br&gt;タグでご指定ください。</p>
                            <textarea name="answer" value={answer} onChange={onChange}/>
                            <ErrorMessage message={errors["answer"]}/>
                        </div>
                    </div>
                </div>

                <div className="form-group check-area">
                    <label>表示</label>
                    <div className="check">
                        <label>
                            <input type="checkbox" name="active" checked={active} onChange={onCheckChange}/>
                            このFAQを表示する
                        </label>
                    </div>
                </div>

            </div>

            <div className="btn-area">
                <Button color={ButtonColor.Gray} type={"button"} onClick={onClickBack} icon={ButtonIcon.Arrow}>前の画面に戻る</Button>
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
            </div>

        </StyledPpMstIdentificationCdEdit>

    </Layout>

}

const StyledPpMstIdentificationCdEdit = styled(StyledForm)`

  select {
    width: 400px !important;
  }

  textarea {
    width: 700px;
    height: 80px;

    &[name=answer] {
      height: 220px;
    }
  }

  p {
    font-size: 14px;
    color: #cc0000;
    margin-bottom: 5px;
  }
  
`