import {Layout} from "../common/Layout";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {SearchForm} from "./SearchForm";
import {adminResultApi} from "../../api/Api";
import {PpTestResultControllerRollbackRequest, TypesRollbackData} from "../../generated";
import {Table} from "../common/Table";
import {SlashedYmd} from "../../utility/Utility";
import {Modal, ModalColor} from "../result/Modal";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import styled from "styled-components";

export enum Types {
    SampleId = "sample_id",
    FlowcellId = "flowcell_id",
}

enum ModalType {
    Confirm = "confirm",
    Complete = "complete",
}

export const RollbackIndex = () => {

    const pathParams = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [type, setType] = useState<Types | null>(null);
    const {setDangerMessage, setShowSpinner} = useContext(AppContext);
    const [records, setRecords] = useState<TypesRollbackData[]>([]);
    const [modalType, setModalType] = useState<ModalType | null>(null);
    const [sampleId, setSampleId] = useState<string>("");
    const [flowcellId, setFlowcellId] = useState<string>("");

    useEffect(() => {

        const type = pathParams.type;

        if (!type || [String(Types.SampleId), String(Types.FlowcellId)].indexOf(type) === -1) {
            setDangerMessage("パスパラメータが不正です。");
            navigate("/");
            return;
        }

        setType(type as Types);

    }, []);

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        const flowcellId = params.get("flowcell_id") ?? "";
        const sampleId = params.get("sample_id") ?? "";

        if (flowcellId !== "" || sampleId !== "") {
            onSearch(flowcellId, sampleId);
        } else {
            // ない場合
            setRecords([]);
        }

    }, [location.search]);

    const onSearch = (flowcellId: string, sampleId: string) => {

        setShowSpinner(true);

        adminResultApi.v1AdminRollbackGet(flowcellId, sampleId)
            .then(({data}) => {
                setRecords(data.records);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onClear = () => {
        setRecords([]);
    }

    const onReset = () => {

        if (records.length === 0) {
            setDangerMessage("先に切り戻し対象を表示させてください。");
            return;
        }
        setModalType(ModalType.Confirm);
    }

    const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setModalType(null);
    };

    const onClickConfirmed = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        setShowSpinner(true);

        const req: PpTestResultControllerRollbackRequest = {
            sample_id: sampleId,
            flowcell_id: flowcellId,
        };

        adminResultApi.v1AdminRollbackProcessPost(req)
            .then(() => {
                setModalType(ModalType.Complete);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onTop = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setModalType(null);
        setSampleId("");
        setFlowcellId("");
        navigate(`/rollback/${type}`);
    };

    if (type === null) {
        return <Layout title={`結果完了検体リセット`}/>
    }

    return <Layout title={`結果完了検体リセット`}>
        <SearchForm
            type={type}
            onClear={onClear}
            btnEnabled={records.length > 0}
            onReset={onReset}
            sampleId={sampleId}
            flowcellId={flowcellId}
            setSampleId={setSampleId}
            setFlowcellId={setFlowcellId}
        />

        <div className="box">

            <Table>
                <thead>
                <tr>
                    <th>No.</th>
                    <th>検体ID</th>
                    <th>Flow Cell ID</th>
                    <th>位置</th>
                    <th>Labバーコード</th>
                    <th>結果通知日</th>
                    <th>飼い主氏名</th>
                    <th>ペット名前</th>
                </tr>
                </thead>

                <tbody>

                {records.map((d, i) => {

                    return <tr key={`record-${i}`}>
                        <td>{i + 1}</td>
                        <td>{d.sample_id}</td>
                        <td>{d.flowcell_id}</td>
                        <td>{d.position}</td>
                        <td>{d.lab_barcode_id}</td>
                        <td>{SlashedYmd(d.completion_ymd)}</td>
                        <td>{d.pp_user ? `${d.pp_user.user_family_name} ${d.pp_user.user_first_name}` : "-"}</td>
                        <td>{d.pp_pet ? `${d.pp_pet.pet_name}` : "-"}</td>
                    </tr>

                })}

                </tbody>

            </Table>

        </div>

        {modalType === ModalType.Confirm && <Modal color={ModalColor.Danger}>
            <h4>リセットの確認</h4>
            <h5>対象の検体をリセットしてよろしいですか？</h5>

            <StyledModalInner>

                <div className="total">
                    <span>検体リセット件数</span>
                    <span>{records.length}</span>
                    <span>件</span>
                </div>

                {type === Types.SampleId && <p>
                    検体IDのリセットを行なった場合、<br/>
                    お客様の画面では「検査中」に変わります。<br/>
                    <span><strong>検体IDに間違いがないか</strong>確認の上、リセットしてください。</span>
                </p>}

                {type === Types.FlowcellId && <p>
                    Flow Cell IDのリセットを行なった場合、<br/>
                    お客様の画面では「検査中」に変わります。<br/>
                    <span><strong>Flow Cell IDに間違いがないか</strong>確認の上、リセットしてください。</span>
                </p>}

            </StyledModalInner>

            <div className="btn-area">
                <Button type="button" color={ButtonColor.Gray} icon={ButtonIcon.Arrow} onClick={onModalClose}>いいえ</Button>
                <Button type="button" color={ButtonColor.Danger} icon={ButtonIcon.Arrow} onClick={onClickConfirmed}>はい</Button>
            </div>
        </Modal>}

        {modalType === ModalType.Complete && <Modal color={ModalColor.Success}>
            <h4>リセット完了</h4>
            <h5>検体ステータスのリセットが完了しました。</h5>

            <StyledModalInner>

                <div className="total">
                    <span>検体リセット件数</span>
                    <span>{records.length}</span>
                    <span>件</span>
                </div>

            </StyledModalInner>

            <div className="btn-area">
                <Button type="button" color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={onTop}>検体リセットトップに戻る</Button>
            </div>
        </Modal>}

    </Layout>

}

const StyledModalInner = styled.div`

  .total {
    background-color: #fff;
    padding: 23px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    span {
      &:nth-child(1) {
        font-size: 16px;
      }

      &:nth-child(2) {
        font-size: 40px;
        color: #FF9871;
        line-height: 1;
        width: 80px;
        text-align: right;
        margin-right: 15px;
      }

      &:nth-child(3) {
        font-size: 16px;
        color: #00BFA5;
      }
    }
  }

  p {
    padding-top: 20px !important;

    span {
      background: linear-gradient(transparent 50%, rgba(217, 62, 76, 0.2) 20%);

      strong {
        background: none !important;
      }
    }
  }

`;