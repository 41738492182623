import {Layout} from "../common/Layout";
import {H1} from "../common/Headline";
import {InterviewSteps} from "./interview/InterviewSteps";
import React, {useContext, useEffect} from "react";
import {P} from "../common/P";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {TypesPpTestKitData} from "../../generated";
import {adminPpTestKitApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";
import {useNavigate, useParams} from "react-router-dom";

export const PpTestKitComplete = () => {

    const params = useParams();
    const navigate = useNavigate();
    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppTestKit, setPpTestKit] = React.useState<TypesPpTestKitData | null>(null);

    useEffect(() => {

        if (params.sampleId) {
            getPpTestKit(params.sampleId);
        }

    }, []);

    const getPpTestKit = (sampleId: string) => {

        setShowSpinner(true);

        adminPpTestKitApi.v1AdminPpTestKitGetSampleIdGet(sampleId)
            .then(({data}) => {
                setPpTestKit(data.pp_test_kit ?? null);

                if (!data.pp_test_kit.pp_pet) {
                    setDangerMessage("ご指定の検体IDにはまた申し込みがされていません。");
                    navigate(`/pp_test_kit`);
                }

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

                navigate(`/pp_test_kit`);
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    if (!ppTestKit || !ppTestKit.pp_pet) {
        return <Layout title="検査申込管理"/>
    }

    const onClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(`/pp_test_kit/detail/${ppTestKit.pp_user_id}`);
    };

    return <Layout title="検査申込管理">

        <H1 title="ペット情報の新規登録" subtitle="registration"/>

        <InterviewSteps offset={3}/>

        <div className="box">
            <P>
                ご入力頂いた内容で検査申込を受け付けました。<br/>
                ご登録のメールアドレス宛に検査申込完了メールをお送りしてますので、ご確認ください。<br/>
                検査状況はホームの検査情報照会よりご確認ください。<br/>
            </P>
        </div>

        <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow} onClick={onClick}>別のペットの検査を申し込む</Button>

    </Layout>
};