import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {H1, H3} from "../common/Headline";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {ErrorMessage} from "../common/ErrorMessage";
import {CreateJaYmd, GetDateList, GetMonthList, GetPersonGender, GetPrefectureName, GetYearList} from "../../utility/Utility";
import {adminPpUserApi} from "../../api/Api";
import {PpUserControllerEditRequest, TypesPpUserData} from "../../generated";
import {useNavigate, useParams} from "react-router-dom";
import {AddressSet} from "../common/AddressSet";
import {PpUserDetailMessageType} from "./PpUserDetail";
import {EditUserEmailInput} from "../common/EditUserEmailInput";
import {EditUserPasswordInput} from "../common/EditUserPasswordInput";


export const PpUserEdit = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppUser, setPpUser] = useState<TypesPpUserData | null>(null);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmationPassword, setConfirmationPassword] = useState<string>("");
    const [userFamilyName, setUserFamilyName] = useState<string>("");
    const [userFirstName, setUserFirstName] = useState<string>("");
    const [userFamilyFurigana, setUserFamilyFurigana] = useState<string>("");
    const [userFirstFurigana, setUserFirstFurigana] = useState<string>("");
    const [birthYear, setBirthYear] = useState<string>("");
    const [birthMonth, setBirthMonth] = useState<string>("");
    const [birthDate, setBirthDate] = useState<string>("");
    const [birthday, setBirthday] = useState<string>("");
    const [gender, setGender] = useState<string>("");
    const [postalCode1, setPostalCode1] = useState<string>("");
    const [postalCode2, setPostalCode2] = useState<string>("");
    const [postalCode, setPostalCode] = useState<string>("");
    const [prefCode, setPrefCode] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [street, setStreet] = useState<string>("");
    const [building, setBuilding] = useState<string>("");
    const [telNo, setTelNo] = useState<string>("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [step, setStep] = useState<number>(1);
    const navigate = useNavigate();
    const params = useParams();
    const [isEmailChange, setIsEmailChange] = useState<boolean>(false);
    const [isPasswordChange, setIsPasswordChange] = useState<boolean>(false);

    useEffect(() => {

        if (params.id) {
            getPpUser(params.id);
        }

    }, []);

    // 会員情報を取得して、ステートセット
    const getPpUser = (id: string) => {

        setShowSpinner(true);

        adminPpUserApi.v1AdminPpUserGetIdGet(id)
            .then(({data}) => {
                setPpUser(data.pp_user ?? null);

                setUserFamilyName(data.pp_user?.user_family_name ?? "");
                setUserFirstName(data.pp_user?.user_first_name ?? "");
                setUserFamilyFurigana(data.pp_user?.user_family_furigana ?? "");
                setUserFirstFurigana(data.pp_user?.user_first_furigana ?? "");
                setEmail(data.pp_user?.email ?? "");
                setBirthday(data.pp_user?.birthday ?? "");
                setGender(data.pp_user?.gender ?? "");
                setPostalCode(data.pp_user?.postal_code ?? "");
                setPrefCode(data.pp_user?.pref_code ?? "");
                setCity(data.pp_user?.city ?? "");
                setStreet(data.pp_user?.street ?? "");
                setBuilding(data.pp_user?.building ?? "");
                setTelNo(data.pp_user?.tel_no ?? "");

                if (data.pp_user?.birthday) {
                    const parts = data.pp_user?.birthday.split("-");
                    if (parts.length === 3) {
                        setBirthYear(parts[0]);
                        setBirthMonth(parts[1]);
                        setBirthDate(parts[2]);
                    }
                }

                if (data.pp_user?.postal_code) {
                    const parts = data.pp_user?.postal_code.split("-");
                    if (parts.length === 2) {
                        setPostalCode1(parts[0]);
                        setPostalCode2(parts[1]);
                    }
                }

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/pp_user");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    if (!ppUser) {
        // 会員が取得できるまではダミー表示
        return <Layout title={"会員検索"}/>;
    }

    // 会員情報の送信
    const onSubmit = (e: React.FormEvent<HTMLFormElement>, isOnlyValidate: boolean) => {

        e.preventDefault();
        setShowSpinner(true);

        const req: PpUserControllerEditRequest = {
            email: email,
            user_family_name: userFamilyName,
            user_first_name: userFirstName,
            user_family_furigana: userFamilyFurigana,
            user_first_furigana: userFirstFurigana,
            birthday: birthday,
            gender: gender,
            postal_code: postalCode,
            pref_code: prefCode,
            city: city,
            street: street,
            building: building,
            tel_no: telNo,
            is_only_validate: isOnlyValidate,
            is_email_change: isEmailChange,
            is_password_change: isPasswordChange,
            password: password,
            confirmation_password: confirmationPassword,
        };

        adminPpUserApi.v1AdminPpUserEditIdPost(String(ppUser?.id), req)
            .then(() => {
                setErrors({});

                if (isOnlyValidate) {
                    // バリデーションエラーがなければ、次のステップへ
                    setStep(2);
                    window.scrollTo(0, 0);
                } else {
                    // 保存完了
                    navigate(`/pp_user/detail/${ppUser?.id}?message_type=${PpUserDetailMessageType.OwnerEditComplete}`);
                }

            })
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setDangerMessage("ご入力内容にエラーがありました。");
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {

        switch (e.currentTarget.name) {
            case "email":
                setEmail(e.currentTarget.value);
                break;
            case "password":
                setPassword(e.currentTarget.value);
                break;
            case "confirmation_password":
                setConfirmationPassword(e.currentTarget.value);
                break;
            case "user_family_name":
                setUserFamilyName(e.currentTarget.value);
                break;
            case "user_first_name":
                setUserFirstName(e.currentTarget.value);
                break;
            case "user_family_furigana":
                setUserFamilyFurigana(e.currentTarget.value);
                break;
            case "user_first_furigana":
                setUserFirstFurigana(e.currentTarget.value);
                break;
            case "birth_year":
                setBirthYear(e.currentTarget.value);
                setBirthday(`${e.currentTarget.value}-${birthMonth}-${birthDate}`);
                break;
            case "birth_month":
                setBirthMonth(e.currentTarget.value);
                setBirthday(`${birthYear}-${e.currentTarget.value}-${birthDate}`);
                break;
            case "birth_date":
                setBirthDate(e.currentTarget.value);
                setBirthday(`${birthYear}-${birthMonth}-${e.currentTarget.value}`);
                break;
            case "gender":
                setGender(e.currentTarget.value);
                break;
            case "postal_code1":
                setPostalCode1(e.currentTarget.value);
                setPostalCode(`${e.currentTarget.value}-${postalCode2}`);
                break;
            case "postal_code2":
                setPostalCode2(e.currentTarget.value);
                setPostalCode(`${postalCode1}-${e.currentTarget.value}`);
                break;
            case "pref_code":
                setPrefCode(e.currentTarget.value);
                break;
            case "city":
                setCity(e.currentTarget.value);
                break;
            case "street":
                setStreet(e.currentTarget.value);
                break;
            case "building":
                setBuilding(e.currentTarget.value);
                break;
            case "tel_no":
                setTelNo(e.currentTarget.value);
                break;
        }
    };

    const onBackStep1 = () => {
        setStep(1);
        window.scrollTo(0, 0);
    };

    const onCancel = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(`/pp_user/detail/${ppUser?.id}`);
    };

    const toggleChangeableEmail = () => {
        setIsEmailChange(!isEmailChange);
        if (isEmailChange) {
            // 無効化する場合データをデフォルトに戻す
            setEmail(ppUser?.email ?? "");
            setPassword("");
            setConfirmationPassword("");
            setErrors({});
        }
    }

    const toggleChangeablePassword = () => {
        setIsPasswordChange(!isPasswordChange);
        if (isPasswordChange) {
            // 無効化する場合データをデフォルトに戻す
            setEmail(ppUser?.email ?? "");
            setErrors({});
        }
    }
    // ---------------------------------------- step1 / 初期画面
    return <Layout title={"会員検索"}>

        <H1 title="オーナー情報の変更" subtitle="user data"/>

        {/*入力画面*/}
        {step === 1 && <StyledMemberEdit onSubmit={(e) => onSubmit(e, true)}>

            <div className="box">

                <H3 title={"オーナー情報"}/>

                <div className="form-wrapper">

                    <div className="form-group">
                        <label className="req">お名前</label>
                        <div>
                            <div className="inline">
                                <span>姓</span>
                                <input type="text" maxLength={40} name="user_family_name" value={userFamilyName} onChange={onChange} placeholder={"例：プラウメッド"}/>
                                <span>名</span>
                                <input type="text" maxLength={40} name="user_first_name" value={userFirstName} onChange={onChange} placeholder={"例：太郎"}/>
                            </div>
                            <ErrorMessage message={errors["user_family_name"]}/>
                            <ErrorMessage message={errors["user_first_name"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">お名前（フリガナ）</label>
                        <div>
                            <div className="inline">
                                <span>セイ</span>
                                <input type="text" maxLength={60} name="user_family_furigana" value={userFamilyFurigana} onChange={onChange} placeholder={"例：プラウメッド"}/>
                                <span>メイ</span>
                                <input type="text" maxLength={60} name="user_first_furigana" value={userFirstFurigana} onChange={onChange} placeholder={"例：タロウ"}/>
                            </div>
                            <ErrorMessage message={errors["user_family_furigana"]}/>
                            <ErrorMessage message={errors["user_first_furigana"]}/>
                        </div>
                    </div>


                    <EditUserEmailInput
                        currentEmail={ppUser?.email ?? ""}
                        email={email}
                        onChange={onChange}
                        errors={errors}
                        disabled={!isEmailChange}
                        toggleChangeable={toggleChangeableEmail}
                        isPasswordChange={isPasswordChange}
                    />

                    {!isEmailChange && <EditUserPasswordInput
                        password={password}
                        confirmationPassword={confirmationPassword}
                        onChange={onChange}
                        errors={errors}
                        disabled={!isPasswordChange}
                        toggleChangeable={toggleChangeablePassword}
                        isEmailChange={isEmailChange}
                    />}

                    <div className="form-group">
                        <label className="req">生年月日</label>
                        <div>
                            <div className="inline">
                                <select name="birth_year" value={birthYear} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetYearList(false).map((d, i) => {
                                        return <option key={i} value={d}>{d}</option>
                                    })}
                                </select>
                                <span>年</span>
                                <select name="birth_month" value={birthMonth} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetMonthList().map((d, i) => {
                                        return <option key={i} value={d}>{d}</option>
                                    })}                            </select>
                                <span>月</span>
                                <select name="birth_date" value={birthDate} onChange={onChange}>
                                    <option value="">----</option>
                                    {GetDateList().map((d, i) => {
                                        return <option key={i} value={d}>{d}</option>
                                    })}
                                </select>
                                <span>日</span>

                            </div>
                            <ErrorMessage message={errors["birthday"]}/>
                        </div>
                    </div>

                    <div className="form-group check-area">
                        <label className="req">性別</label>
                        <div>
                            <div className="check">
                                <label>
                                    <input type="radio" name="gender" value="1" onChange={onChange} checked={gender === "1"}/>
                                    男性
                                </label>
                                <label>
                                    <input type="radio" name="gender" value="2" onChange={onChange} checked={gender === "2"}/>
                                    女性
                                </label>
                                <label>
                                    <input type="radio" name="gender" value="3" onChange={onChange} checked={gender === "3"}/>
                                    その他
                                </label>
                            </div>
                            <ErrorMessage message={errors["gender"]}/>
                        </div>
                    </div>


                    <AddressSet
                        postalCode={postalCode}
                        postalCode1={postalCode1}
                        postalCode2={postalCode2}
                        prefCode={prefCode}
                        city={city}
                        street={street}
                        building={building}
                        onChange={onChange}
                        isRequired={true}
                        errors={errors}
                        setPrefCode={setPrefCode}
                        setCity={setCity}
                        setStreet={setStreet}
                        setBuilding={setBuilding}/>


                    <div className="form-group" style={{marginBottom: "60px"}}>
                        <label className="req">電話番号</label>
                        <div>
                            <input type="tel" name="tel_no" maxLength={12} value={telNo} onChange={onChange} placeholder={"半角数字"}/>
                            <div className="hint">※半角数字。ハイフンの入力は不要。</div>
                            <ErrorMessage message={errors["tel_no"]}/>
                        </div>
                    </div>

                </div>

            </div>

            <div className="btn-area">
                <Button color={ButtonColor.Gray} icon={ButtonIcon.Arrow} type="button" onClick={onCancel}>キャンセル</Button>
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>変更内容を確認</Button>
            </div>

        </StyledMemberEdit>}

        {/*変更内容の確認*/}
        {step === 2 && <StyledMemberEdit onSubmit={(e) => onSubmit(e, false)}>

            <p className="confirmation-note">オーナー情報の変更内容をご確認ください。</p>

            <div className="box">

                <H3 title="オーナー情報" subtitle="user data"/>

                <div className="form-wrapper">

                    <div className="form-group center">
                        <label className="req">お名前</label>
                        <div>
                            姓：{userFamilyName} &nbsp; &nbsp; 名：{userFirstName}
                        </div>
                    </div>

                    <div className="form-group center">
                        <label className="req">お名前（フリガナ）</label>
                        <div>
                            姓：{userFamilyFurigana} &nbsp; &nbsp; 名：{userFirstFurigana}
                        </div>
                    </div>

                    <div className="form-group center">
                        <label className="req">メールアドレス</label>
                        <div>
                            {email}
                        </div>
                    </div>

                    <div className="form-group center">
                        <label>パスワード</label>
                        <div>
                            {password !== "" ? "********" : "変更しない"}
                        </div>
                    </div>

                    <div className="form-group center">
                        <label className="req">生年月日</label>
                        <div>
                            {CreateJaYmd(birthday ?? "")}
                        </div>
                    </div>

                    <div className="form-group center">
                        <label className="req">性別</label>
                        <div>
                            {GetPersonGender(gender ?? "")}
                        </div>
                    </div>

                    <div className="form-group center">
                        <label className="req">郵便番号</label>
                        <div>
                            {postalCode}
                        </div>
                    </div>

                    <div className="form-group center">
                        <label className="req">都道府県</label>
                        <div>
                            {GetPrefectureName(prefCode)}
                        </div>
                    </div>


                    <div className="form-group center">
                        <label className="req">市区町村</label>
                        <div>
                            {city}
                        </div>
                    </div>

                    <div className="form-group center">
                        <label className="req">丁目・番地</label>
                        <div>
                            {street}
                        </div>
                    </div>

                    <div className="form-group center">
                        <label>建物名</label>
                        <div>
                            {building !== "" ? building : "-"}
                        </div>
                    </div>

                    <div className="form-group center">
                        <label className="req">電話番号</label>
                        <div>
                            {telNo}
                        </div>
                    </div>

                </div>

            </div>

            <div className="btn-area">
                <Button color={ButtonColor.Gray} icon={ButtonIcon.Arrow} type="button" onClick={onBackStep1}>前の画面に戻る</Button>
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>修正を保存する</Button>
            </div>

        </StyledMemberEdit>}

    </Layout>;

};

const StyledMemberEdit = styled(StyledForm)`

  // メール、パスワードエリア
  .email-area, .password-area {
    justify-content: flex-start;

    .form-object {
      width: 350px !important;
      margin-right: 30px;
      flex: 0 0 auto;

      input {
        width: 100% !important;
      }
    }

    .hint-balloon {
      position: relative;
      top: -15px;
      width: 430px;
      flex: 0 0 auto;
    }
  }

  .email-area {
    padding: 40px 15px 10px 15px;
  }

  // パスワードエリア
  .password-area {

    .hint-balloon {
      top: -10px;
      width: 500px;
      flex: 0 0 auto;
    }
  }

  // 確認画面テキスト
  .confirmation-note {
    line-height: 62px;
    margin: 0 -40px 30px -40px;
    color: #fff;
    padding: 0 64px;
    background-color: rgb(3, 171, 144);
  }
`;
