import {H3} from "../common/Headline";
import React, {useContext, useEffect, useState} from "react";
import {Layout} from "../common/Layout";
import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../contexts/AppContext";
import {adminPpMstIdentificationCdApi} from "../../api/Api";
import {PpmstidentificationcdAuthorityLevel, PpMstIdentificationCdControllerCreateRequest, TypesPpMstIdentificationCdData} from "../../generated";
import styled from "styled-components";
import {PartnerInstInput} from "../common/PartnerInstInput";
import {StyledForm} from "../common/StyledForm";
import {GetAuthorityLevels, GetRandomStr} from "../../utility/Utility";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {AxiosResponse} from "axios";
import {ErrorMessage} from "../common/ErrorMessage";

export const PpMstIdentificationCdEdit = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();
    const [ppMstIdentificationCd, setPpMstIdentificationCd] = useState<TypesPpMstIdentificationCdData | null>(null);
    const [partnerInstId, setPartnerInstId] = useState<string>("");
    const [groupingCode, setGroupingCode] = useState<string>("");
    const [codeNumber, setCodeNumber] = useState<string>("");
    const [authorityLevel, setAuthorityLevel] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});


    useEffect(() => {

        if (params.id) {
            // IDが指定されている場合は、データを取得する
            getPpMstIdentificationCd(params.id);
        }

    }, [params]);

    const getPpMstIdentificationCd = (id: string) => {

        setShowSpinner(true);

        adminPpMstIdentificationCdApi
            .v1AdminPpMstIdentificationCdGetIdGet(id)
            .then(({data}) => {

                const ppMstIdentificationCd = data.pp_mst_identification_cd;

                setPpMstIdentificationCd(ppMstIdentificationCd);

                setPartnerInstId(String(ppMstIdentificationCd.partner_inst_id));
                setGroupingCode(ppMstIdentificationCd.grouping_code);
                setCodeNumber(ppMstIdentificationCd.code_number);
                setAuthorityLevel(ppMstIdentificationCd.authority_level);
                setDescription(ppMstIdentificationCd.description ?? "");

            })
            .catch((err) => {

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/pp_mst_identification_cd");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            })

    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {

        e.preventDefault();

        switch (e.currentTarget.name) {
            case "grouping_code":
                setGroupingCode(e.currentTarget.value);
                break;
            case "code_number":
                setCodeNumber(e.currentTarget.value);
                break;
            case "authority_level":
                setAuthorityLevel(e.currentTarget.value);
                break;
            case "description":
                setDescription(e.currentTarget.value);
                break;
        }
    }

    const generateRandomCode = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        setCodeNumber(GetRandomStr(6));
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {

        e.preventDefault();

        setShowSpinner(true);
        setErrors({});

        const req: PpMstIdentificationCdControllerCreateRequest = {
            partner_inst_id: Number(partnerInstId),
            grouping_code: groupingCode,
            code_number: codeNumber,
            authority_level: authorityLevel as PpmstidentificationcdAuthorityLevel,
            description: description,
        };

        let promise: Promise<void | AxiosResponse<any, any>>;

        if (ppMstIdentificationCd !== null) {
            // 編集時
            promise = adminPpMstIdentificationCdApi
                .v1AdminPpMstIdentificationCdEditIdPost(String(ppMstIdentificationCd.id), req);
        } else {
            // 新規登録時
            promise = adminPpMstIdentificationCdApi
                .v1AdminPpMstIdentificationCdCreatePost(req);
        }

        promise
            .then(() => {
                let messageType = "create_complete";
                if (ppMstIdentificationCd !== null) {
                    messageType = "edit_complete";
                }
                navigate(`/pp_mst_identification_cd?message_type=${messageType}`);
            })
            .catch((err) => {
                if (err.response.status === 406) {
                    // バリデーションエラー
                    setDangerMessage("ご入力内容にエラーがありました。");
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    // 前の画面に戻る
    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(-1);
    };

    const authorityLevels = GetAuthorityLevels();
    const authorityLevelKeys = Object.keys(authorityLevels);

    return <Layout title={"識別コード管理"}>

        <StyledPpMstIdentificationCdEdit onSubmit={onSubmit}>

            <div className="box">

                <H3 title="識別コード設定" subtitle="Code"/>

                <div className="form-wrapper">

                    <div className="form-group">
                        <label className="req">提携法人</label>

                        <div>
                            <PartnerInstInput name="partner_inst_id" onChange={(name: string, n: number | null) => {
                                console.log(name); // linter対応なので本来は不要
                                setPartnerInstId(n ? String(n) : "");
                            }} value={partnerInstId ? Number(partnerInstId) : null}/>
                            <ErrorMessage message={errors["partner_inst_id"]}/>
                        </div>

                    </div>

                    <div className="form-group">
                        <label className="req">識別コード</label>

                        <div className="inline">
                            <span>グルーピングコード</span>
                            <input type="text" name="grouping_code" value={groupingCode} onChange={onChange} placeholder="例：TT" className="w110 mr" maxLength={2}/>
                            <span>番号</span>
                            <input type="text" name="code_number" value={codeNumber} onChange={onChange} placeholder="例：123456" className="w110 mr" maxLength={6}/>
                            <Button color={ButtonColor.Green} icon={ButtonIcon.Arrow} type="button" onClick={generateRandomCode}>番号自動生成</Button>
                            <ErrorMessage message={errors["grouping_code"]}/>
                            <ErrorMessage message={errors["code_number"]} left={250}/>
                        </div>

                    </div>

                    <div className="form-group">
                        <label className="req">アクセス権限レベル</label>
                        <div>
                            <select name="authority_level" value={authorityLevel} onChange={onChange} className="w240">
                                <option value="">---選択してください</option>
                                {authorityLevelKeys.map((d) => {
                                    return <option value={d} key={`al-${d}`}>{authorityLevels[d]}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["authority_level"]}/>
                        </div>

                    </div>

                    <div className="form-group">
                        <label>説明</label>
                        <div>
                            <textarea name="description" value={description} onChange={onChange}/>
                            <ErrorMessage message={errors["description"]}/>
                        </div>
                    </div>

                </div>
            </div>

            <div className="btn-area">
                <Button color={ButtonColor.Gray} type={"button"} onClick={onClickBack} icon={ButtonIcon.Arrow}>前の画面に戻る</Button>
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
            </div>

        </StyledPpMstIdentificationCdEdit>

    </Layout>

}

const StyledPpMstIdentificationCdEdit = styled(StyledForm)`

  .inline {
    span {
      font-size: 12px !important;
    }

    .mr {
      margin-right: 24px !important;
    }

    .w110 {
      width: 110px !important;
    }

    button {
      margin: 0 !important;
    }
  }

  select {
    width: 400px !important;
  }

  textarea {
    width: 560px;
    height: 120px;
  }

`