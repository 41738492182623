import {Button, ButtonColor, ButtonIcon} from "../../common/Button";
import cat1 from "../../../images/body/cat_1.svg";
import cat2 from "../../../images/body/cat_2.svg";
import cat3 from "../../../images/body/cat_3.svg";
import cat4 from "../../../images/body/cat_4.svg";
import cat5 from "../../../images/body/cat_5.svg";
import {StyledDog} from "./Dog";
import React from "react";

interface Props {
    onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Cat = ({onClose}: Props) => {

    return <StyledDog>

        <div className="modal">

            <h4>猫の体型の基準</h4>

            <div className="shape">
                <h5>痩せている</h5>
                <img src={cat1} alt="体型画像"/>

                <ul>
                    <li>
                        <span>肋骨</span>
                        <span>外から容易に見える</span>
                    </li>
                    <li>
                        <span>腰のくびれ</span>
                        <span>深い（首も細い）</span>
                    </li>
                    <li>
                        <span>腹部の吊り上がり</span>
                        <span>顕著</span>
                    </li>
                    <li>
                        <span>脇腹のひだ</span>
                        <span>ない</span>
                    </li>
                </ul>
            </div>


            <div className="shape">
                <h5>やや痩せている</h5>
                <img src={cat2} alt="体型画像"/>

                <ul>
                    <li>
                        <span>肋骨</span>
                        <span>容易に触れる</span>
                    </li>
                    <li>
                        <span>腰のくびれ</span>
                        <span>最小</span>
                    </li>
                    <li>
                        <span>腹部の吊り上がり</span>
                        <span>わずか</span>
                    </li>
                    <li>
                        <span>脇腹のひだ</span>
                        <span>ごくわずか</span>
                    </li>
                </ul>
            </div>


            <div className="shape">
                <h5>標準</h5>
                <img src={cat3} alt="体型画像"/>

                <ul>
                    <li>
                        <span>肋骨</span>
                        <span>触ることができる</span>
                    </li>
                    <li>
                        <span>腰のくびれ</span>
                        <span>肋骨のうしろに見られる</span>
                    </li>
                    <li>
                        <span>腹部の吊り上がり</span>
                        <span>ある</span>
                    </li>
                    <li>
                        <span>脇腹のひだ</span>
                        <span>わずか</span>
                    </li>
                </ul>
            </div>


            <div className="shape">
                <h5>やや肥満</h5>
                <img src={cat4} alt="体型画像"/>

                <ul>
                    <li>
                        <span>肋骨</span>
                        <span>脂肪はあるが触れる</span>
                    </li>
                    <li>
                        <span>腰のくびれ</span>
                        <span>ほとんどない</span>
                    </li>
                    <li>
                        <span>腹部の吊り上がり</span>
                        <span>まるくなる</span>
                    </li>
                    <li>
                        <span>脇腹のひだ</span>
                        <span>やや垂れ下がり、歩くとゆれる</span>
                    </li>
                </ul>
            </div>


            <div className="shape">
                <h5>肥満</h5>
                <img src={cat5} alt="体型画像"/>

                <ul>
                    <li>
                        <span>肋骨</span>
                        <span>容易に触ることができない</span>
                    </li>
                    <li>
                        <span>腰のくびれ</span>
                        <span>ほとんどない</span>
                    </li>
                    <li>
                        <span>腹部の吊り上がり</span>
                        <span>まるい</span>
                    </li>
                    <li>
                        <span>脇腹のひだ</span>
                        <span>目立ち、歩くと盛んにゆれる</span>
                    </li>
                </ul>
            </div>


            <div className="footer">
                <Button color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={onClose} type="button">元の画面に戻る</Button>
            </div>

        </div>


    </StyledDog>

}
