import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import {SearchForm} from "./SearchForm";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {AccesstokenRole, PpTestKitControllerIndexRequest, PpTestKitControllerIndexResponse, TypesPpTestKitData} from "../../generated";
import {Counter} from "../common/Counter";
import {Table} from "../common/Table";
import {GetAnimalType, GetTestStatus, SlashedYmd, Zerofill} from "../../utility/Utility";
import {Paginator} from "../common/Paginator";
import {adminPpTestKitApi} from "../../api/Api";
import styled from "styled-components";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "../common/Button";
import {AxiosRequestConfig} from "axios";

export const PpTestStatusIndex = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const {role, setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppTestKits, setPpTestKits] = useState<TypesPpTestKitData[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [showSearchError, setShowSearchError] = useState<boolean>(false);
    const [hasFromTo, setHasFromTo] = useState<boolean>(false);
    const size = 100;

    // URL変更時（ページ変更時もURLを変更すること）
    useEffect(() => {
        if (location.search !== "") {
            getPpTestKits(false, false);
        }
    }, [location.search]);

    const getPpTestKits = (isCsv: boolean, isOrderCsv: boolean) => {

        setShowSearchError(false);

        const params = new URLSearchParams(location.search);

        const sampleId = params.get("sample_id") ?? "";
        const karteId = params.get("karte_id") ?? "";
        const lastName = params.get("last_name") ?? "";
        const firstName = params.get("first_name") ?? "";
        const lastNameKana = params.get("last_name_kana") ?? "";
        const firstNameKana = params.get("first_name_kana") ?? "";
        const petName = params.get("pet_name") ?? "";
        const testDivision = params.get("test_division") ?? "";
        const status = params.get("status") ?? "";
        const from = params.get("from") ?? "";
        const to = params.get("to") ?? "";
        const partnerInstId = params.get("partner_inst_id") ?? "0";
        const medicInstId = params.get("medic_inst_id") ?? "0";
        const filterStatus = params.get("filter_status") ?? "0";
        const page = params.get("page") ?? "1";
        const fromStart = params.get("from_start") ?? "";
        const toStart = params.get("to_start") ?? "";

        const isAnyCondition = sampleId !== "" || karteId !== "" || lastName !== "" || firstName !== "" || lastNameKana !== "" || firstNameKana !== "" || petName !== "" || testDivision !== "" || status !== "" || from !== "" || to !== "" || partnerInstId !== "" || medicInstId !== "" || fromStart !== "" || toStart !== "";

        if (location.search === "" || !isAnyCondition) {
            // 検索条件が未指定の場合検索しない
            setPpTestKits([]);
            setTotal(0);
            setCurrentPage(1);
            setShowSearchError(true);
            return;
        }

        setShowSpinner(true);

        const req: PpTestKitControllerIndexRequest = {
            sample_id: sampleId,
            karte_id: karteId,
            last_name: lastName,
            first_name: firstName,
            last_name_kana: lastNameKana,
            first_name_kana: firstNameKana,
            pet_name: petName,
            test_division: testDivision,
            status: status,
            from: from,
            to: to,
            partner_inst_id: Number(partnerInstId),
            medic_inst_id: Number(medicInstId),
            page: Number(page),
            size: size,
            is_csv: isCsv,
            is_order_csv: isOrderCsv,
            is_confirmed: false,
            from_start: fromStart,
            to_start: toStart,
        };

        if (filterStatus === "1") {
            // 「検査の申込済」がチェックされている場合は、申込済のレコードに絞る
            req.is_confirmed = true;
        }

        if (req.from === "--") {
            delete req.from;
        }

        if (req.to === "--") {
            delete req.to;
        }

        if (req.from_start === "--") {
            delete req.from_start;
        }

        if (req.to_start === "--") {
            delete req.to_start;
        }

        // 依頼注文情報のダウンロードボタンの表示切り替え
        if (req.from && req.to) {
            setHasFromTo(true);
        } else {
            setHasFromTo(false);
        }

        const options: AxiosRequestConfig = {};

        if (isCsv || isOrderCsv) {
            options.responseType = "blob";
        }

        adminPpTestKitApi.v1AdminPpTestKitPost(req, options)
            .then((res) => {

                if (isCsv) {
                    // CSVダウンロード
                    const data = res.data as never;
                    const blob = new Blob([data], {type: "text/csv"});
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    const dt = new Date();
                    const today = `${dt.getFullYear()}${Zerofill(dt.getMonth() + 1, 2)}${Zerofill(dt.getDate(), 2)}`;
                    a.download = `検査情報照会-${today}.csv`;
                    a.click();

                } else if (isOrderCsv) {
                    // 依頼注文情報用CSVダウンロード
                    const data = res.data as never;
                    const blob = new Blob([data], {type: "text/csv"});
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    const dt = new Date();
                    const today = `${dt.getFullYear()}${Zerofill(dt.getMonth() + 1, 2)}${Zerofill(dt.getDate(), 2)}`;
                    a.download = `依頼注文情報-${today}.csv`;
                    a.click();
                } else {
                    const data = res.data as PpTestKitControllerIndexResponse;
                    setTotal(data.total);
                    setCurrentPage(Number(req.page));
                    setPpTestKits(data.pp_test_kits);
                }
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    console.log(err.response.data.message);
                    setShowSearchError(true);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onPageChange = (page: number): void => {

        const params = new URLSearchParams(location.search);
        params.set("page", String(page));

        navigate(`/pp_test_status?${params.toString()}`);
    };

    const onClickDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        getPpTestKits(true, false);
    }

    const onClear = (): void => {
        setPpTestKits([]);
        setTotal(0);
        setCurrentPage(1);
        setShowSearchError(false);
    }

    const onClickOrderDownload = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        getPpTestKits(false, true);
    };

    const statuses = GetTestStatus();

    if (role === AccesstokenRole.RoleCs) {
        // PITPET_SYS_2023-185 CS権限の場合、強制検査中の文言を変更する
        statuses.set("22", "到着済み未登録検体");
    }

    // 依頼注文情報のダウンロードボタンの表示切り替え(活性状態はhasFromToで管理)
    const isShowOrderCsv = (role === AccesstokenRole.RoleMaster || role === AccesstokenRole.RoleOperator);

    return <Layout title="検査情報照会">

        <SearchForm showError={showSearchError} onClear={onClear} statuses={statuses}/>

        <StyledPpTestStatusIndex className="box">

            <H3 title="検体情報一覧" subtitle="list" counter={<Counter total={total} size={size} current={currentPage}/>}>
                {isShowOrderCsv && <Button disabled={!hasFromTo} color={!hasFromTo ? ButtonColor.Gray : ButtonColor.Orange} size={ButtonSize.Small} icon={ButtonIcon.Arrow} type="button" onClick={onClickOrderDownload}>依頼注文情報用CSVダウンロード</Button>}
                <Button disabled={ppTestKits.length === 0} color={ppTestKits.length === 0 ? ButtonColor.Gray : ButtonColor.Orange} size={ButtonSize.Small} icon={ButtonIcon.Arrow} type="button" onClick={onClickDownload}>CSVダウンロード</Button>
            </H3>

            <Table>

                <thead>
                <tr>
                    <th>No.</th>
                    <th>検体ID</th>
                    <th>カルテID</th>
                    <th>ステータス</th>
                    <th>飼い主の名前</th>
                    <th>フリガナ</th>
                    <th>ペット名</th>
                    <th>ペット種類</th>
                    <th>病院名</th>
                    <th>提携法人名</th>
                    <th>検査受付日</th>
                    <th>検査完了日</th>
                </tr>
                </thead>

                <tbody>
                {ppTestKits.map((d, i) => {

                    let name = "";
                    if (d.pp_user?.user_family_name && d.pp_user?.user_first_name) {
                        name = `${d.pp_user.user_family_name} ${d.pp_user.user_first_name}`;
                    }

                    let furigana = "";
                    if (d.pp_user?.user_family_furigana && d.pp_user?.user_first_furigana) {
                        furigana = `${d.pp_user.user_family_furigana} ${d.pp_user.user_first_furigana}`;
                    }

                    return <tr key={`row-${i}`}>
                        <td>{Zerofill((currentPage - 1) * size + i + 1, 2)}</td>
                        <td><NavLink to={`/pp_test_status/detail/${d.sample_id}`}>{d.sample_id}</NavLink></td>
                        <td>{d.pp_pet?.karte_id ?? "-"}</td>
                        <td>{statuses.get(String(d.status))}</td>
                        <td>{name !== "" ? name : "-"}</td>
                        <td>{furigana !== "" ? furigana : "-"}</td>
                        <td>{d.bi_pet_name ?? "-"}</td>
                        <td>{d.bi_animal_type ? GetAnimalType(d.bi_animal_type) : "-"}</td>
                        <td>{d.medic_inst_name ?? "-"}</td>
                        <td>{d.partner_inst_name ?? "-"}</td>
                        <td>{d.start_ymd ? SlashedYmd(d.start_ymd) : "-"}</td>
                        <td>{d.completion_date_time ? SlashedYmd(d.completion_date_time.split(" ")[0]) : "-"}</td>
                    </tr>

                })}
                </tbody>

            </Table>

            <Paginator onClick={onPageChange} pages={Math.ceil(total / size)} page={currentPage}/>

        </StyledPpTestStatusIndex>

    </Layout>

};

const StyledPpTestStatusIndex = styled.div`

  table {
    td, th {
      font-size: 14px !important;
    }
  }

  a {
    color: #FF9871;
    text-decoration: none !important;

    &:hover {
      text-decoration: underline !important;
    }
  }
`;