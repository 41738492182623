import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {AxiosResponse} from "axios";
import {ErrorMessage} from "../common/ErrorMessage";
import {PpMstUserAdminAccesses} from "../../utility/Utility";
import {adminPpMstUserAdminApi} from "../../api/Api";
import {PpMstUserAdminControllerCreateRequest, PpMstUserAdminControllerCreateRequestAccessEnum, PpMstUserAdminControllerEditRequest, TypesPpMstUserAdminData} from "../../generated";
import {EditEmailInput} from "../common/EditEmailInput";
import {EditPasswordInput} from "../common/EditPasswordInput";

export const PpMstUserAdminEdit = () => {

    const {setShowSpinner, setDangerMessage, setSuccessMessage} = useContext(AppContext);
    const [ppMstUserAdmin, setPpMstUserAdmin] = useState<TypesPpMstUserAdminData | null>(null)
    const [access, setAccess] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmationPassword, setConfirmationPassword] = useState<string>("");
    const [belongs, setBelongs] = useState<string>("");
    const params = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isEmailChange, setIsEmailChange] = useState<boolean>(false);
    const [isPasswordChange, setIsPasswordChange] = useState<boolean>(false);

    useEffect(() => {

        if (params.id) {
            // ユーザーDが指定されている場合は、管理ユーザーを取得する
            getAdminUser(params.id);
        }

    }, [params]);

    const getAdminUser = (id: string) => {

        setShowSpinner(true);

        adminPpMstUserAdminApi.v1AdminPpMstUserAdminGetIdGet(id)
            .then(({data}) => {
                setPpMstUserAdmin(data.pp_mst_user_admin ?? null);

                setAccess(data.pp_mst_user_admin?.access ?? "");
                setName(data.pp_mst_user_admin?.name ?? "");
                setEmail(data.pp_mst_user_admin?.email ?? "");
                setBelongs(data.pp_mst_user_admin?.belongs ?? "");

            })
            .catch((err) => {
                console.log(err);

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/pp_mst_user_admin");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };


    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        setShowSpinner(true);

        let promise: Promise<void | AxiosResponse<any, any>>;

        if (ppMstUserAdmin) {
            // 編集時

            const req: PpMstUserAdminControllerEditRequest = {
                access: access as PpMstUserAdminControllerCreateRequestAccessEnum,
                name: name,
                email: email,
                password: password,
                confirmation_password: confirmationPassword,
                belongs: belongs,
                is_email_change: isEmailChange,
                is_password_change: isPasswordChange,
            };


            promise = adminPpMstUserAdminApi.v1AdminPpMstUserAdminEditIdPost(String(ppMstUserAdmin.id), req)
                .then(() => {
                    setSuccessMessage("管理ユーザーの編集が完了しました。");
                    setErrors({});
                    navigate(-1);
                });

        } else {
            // 新規登録時
            const req: PpMstUserAdminControllerCreateRequest = {
                access: access as PpMstUserAdminControllerCreateRequestAccessEnum,
                name: name,
                email: email,
                password: password,
                confirmation_password: confirmationPassword,
                belongs: belongs,
            };

            promise = adminPpMstUserAdminApi.v1AdminPpMstUserAdminCreatePost(req)
                .then(() => {
                    setSuccessMessage("管理ユーザーの新規追加が完了しました。");
                    setErrors({});
                    navigate(-1);
                });
        }

        promise
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {

        switch (e.currentTarget.name) {
            case "access":
                setAccess(e.currentTarget.value);
                break;
            case "name":
                setName(e.currentTarget.value);
                break;
            case "email":
                setEmail(e.currentTarget.value);
                break;
            case "password":
                setPassword(e.currentTarget.value);
                break;
            case "confirmation_password":
                setConfirmationPassword(e.currentTarget.value);
                break;
            case "belongs":
                setBelongs(e.currentTarget.value);
                break;
        }
    };

    // 前の画面に戻る
    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(-1);
    };

    const toggleChangeableEmail = () => {
        setIsEmailChange(!isEmailChange);
        if (isEmailChange) {
            // 無効化する場合データをデフォルトに戻す
            setEmail(ppMstUserAdmin?.email ?? "");
            setPassword("");
            setConfirmationPassword("");
            setErrors({});
        }
    }

    const toggleChangeablePassword = () => {
        setIsPasswordChange(!isPasswordChange);
        if (isPasswordChange) {
            // 無効化する場合データをデフォルトに戻す
            setErrors({});
        }
    }

    const accessKeys = Object.keys(PpMstUserAdminAccesses);

    const isEdit = ppMstUserAdmin !== null;

    return <Layout title={"運営管理"}>

        <div className="box">

            <H3 title={"運営ユーザー設定"}/>

            <StyledTopicsEdit onSubmit={onSubmit}>

                <div className="form-wrapper">

                    <div className="form-group">
                        <label className="req">アクセス権限</label>
                        <div>
                            <select name="access" value={access} onChange={onChange}>
                                <option value="">選択してください</option>
                                {accessKeys.map((d, i) => {
                                    return <option value={d} key={`role-${i}`}>{PpMstUserAdminAccesses[d]}</option>;
                                })}
                            </select>
                            <ErrorMessage message={errors["access"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">名前</label>
                        <div>
                            <input type="text" name="name" value={name} onChange={onChange} placeholder={"氏名"}/>
                            <ErrorMessage message={errors["name"]}/>
                        </div>
                    </div>

                    {isEdit && <EditEmailInput
                        currentEmail={ppMstUserAdmin?.email ?? ""}
                        email={email}
                        onChange={onChange}
                        errors={errors}
                        disabled={!isEmailChange}
                        toggleChangeable={toggleChangeableEmail}
                        isPasswordChange={isPasswordChange}
                    />}

                    {isEdit && <EditPasswordInput
                        password={password}
                        confirmationPassword={confirmationPassword}
                        onChange={onChange}
                        errors={errors}
                        disabled={!isPasswordChange}
                        toggleChangeable={toggleChangeablePassword}
                        isEmailChange={isEmailChange}
                    />}

                    {!isEdit && <div className="form-group">
                        <label className="req">メールアドレス</label>
                        <div>
                            <input type="email" name="email" value={email} onChange={onChange} placeholder={"半角英数"}/>
                            <ErrorMessage message={errors["email"]}/>
                        </div>
                    </div>}

                    {!isEdit && <>
                        <div className="form-group">
                            <label className="req">パスワード</label>
                            <div>
                                <input type="password" name="password" value={password} onChange={onChange} placeholder={"パスワード"}/>
                                <ErrorMessage message={errors["password"]}/>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="req">パスワード（再入力）</label>
                            <div>
                                <input type="password" name="confirmation_password" value={confirmationPassword} onChange={onChange} placeholder={"再入力"}/>
                                <ErrorMessage message={errors["confirmation_password"]}/>
                            </div>
                        </div>
                    </>}

                    <div className="form-group">
                        <label>所属</label>
                        <div>
                            <textarea name="belongs" value={belongs} onChange={onChange} placeholder={""}/>
                            <ErrorMessage message={errors["belongs"]}/>
                        </div>
                    </div>

                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type={"button"} onClick={onClickBack} icon={ButtonIcon.Arrow}>前の画面に戻る</Button>
                    <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
                </div>
            </StyledTopicsEdit>

        </div>

    </Layout>;

};

const StyledTopicsEdit = styled(StyledForm)`

`;
