import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {H1, H3} from "../common/Headline";
import {useNavigate, useParams} from "react-router-dom";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {AxiosResponse} from "axios";
import {adminPpMstRcmndProductsTitleApi} from "../../api/Api";
import {PpMstRcmndProductsTitleControllerCreateRequest, TypesPpMstRcmndProductsTitleData} from "../../generated";
import {RcmndTitleDisplayLocation} from "../../utility/Utility";
import {useRcmndMethod} from "../../hooks/useRcmndMethod";
import {ErrorMessage} from "../common/ErrorMessage";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";

export const PpMstRcmndProductsTitleEdit = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppMstRcmndProductsTitle, setPpMstRcmndProductsTitle] = useState<TypesPpMstRcmndProductsTitleData | null>(null);
    const [rcmndMethodId, setRcmndMethodId] = useState<string>("");
    const [displayLocation, setDisplayLocation] = useState<string>("");
    const [recommendTitle, setRecommendTitle] = useState<string>("");
    const params = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const rcmndMethods = useRcmndMethod(true);

    useEffect(() => {

        if (params.id) {
            // IDが指定されている場合はデータを取得する
            getData(params.id);
        }

    }, [params]);

    const getData = (id: string) => {

        setShowSpinner(true);

        adminPpMstRcmndProductsTitleApi
            .v1AdminPpMstRcmndProductsTitleGetIdGet(id)
            .then(({data}) => {
                setPpMstRcmndProductsTitle(data.pp_mst_rcmnd_products_title);
                setRcmndMethodId(String(data.pp_mst_rcmnd_products_title.rcmnd_method_id));
                setDisplayLocation(String(data.pp_mst_rcmnd_products_title.display_location));
                setRecommendTitle(String(data.pp_mst_rcmnd_products_title.recommend_title));
            })
            .catch((err) => {
                console.log(err);

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/pp_mst_rcmnd_products_title");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };


    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const req: PpMstRcmndProductsTitleControllerCreateRequest = {
            rcmnd_method_id: parseInt(rcmndMethodId),
            display_location: displayLocation,
            recommend_title: recommendTitle,
        };

        setShowSpinner(true);

        let promise: Promise<void | AxiosResponse<any, any>>;

        if (ppMstRcmndProductsTitle) {
            // 編集時
            promise = adminPpMstRcmndProductsTitleApi
                .v1AdminPpMstRcmndProductsTitleEditIdPost(String(ppMstRcmndProductsTitle.id), req)
                .then(() => {
                    setErrors({});
                    navigate(`/pp_mst_rcmnd_products_title?message_type=edit_complete`);
                });

        } else {
            // 新規登録時
            promise = adminPpMstRcmndProductsTitleApi
                .v1AdminPpMstRcmndProductsTitleCreatePost(req)
                .then(() => {
                    setErrors({});
                    navigate(`/pp_mst_rcmnd_products_title?message_type=create_complete`);
                });
        }

        promise
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {

        switch (e.currentTarget.name) {
            case "rcmnd_method_id":
                setRcmndMethodId(e.currentTarget.value);
                break;
            case "display_location":
                setDisplayLocation(e.currentTarget.value);
                break;
            case "recommend_title":
                setRecommendTitle(e.currentTarget.value);
                break;
        }
    };

    // 前の画面に戻る
    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(-1);
    };

    return <Layout title={"個別商品リコメンドタイトル管理"}>

        <H1 title={ppMstRcmndProductsTitle ? "個別商品リコメンドタイトルの編集" : "個別商品リコメンドタイトルの新規登録"} subtitle="data entry"/>

        <div className="box">

            <H3 title="個別商品リコメンドタイトル" subtitle="data"/>

            <StyledSkuEdit onSubmit={onSubmit}>

                <div className="form-group">
                    <label className="req">リコメンド方式ID</label>
                    <div>
                        <select name="rcmnd_method_id" value={rcmndMethodId} onChange={onChange} className={errors["rcmnd_method_id"] ? "error" : ""}>
                            <option value="">---選択してください</option>
                            {rcmndMethods.map((d, i) => {
                                return <option value={d.id} key={`rmi-${i}`}>{d.id}：{d.label ?? "ラベル未設定"}</option>
                            })}
                        </select>
                        <ErrorMessage message={errors["rcmnd_method_id"]}/>
                    </div>
                </div>

                <div className="form-group">
                    <label className="req">リコメンドタイトル表示箇所</label>
                    <div>
                        <select name="display_location" value={displayLocation} onChange={onChange} className={errors["display_location"] ? "error" : ""}>
                            <option value="">---選択してください</option>
                            {RcmndTitleDisplayLocation.map((d, i) => {
                                return <option value={d.id} key={`dl-${i}`}>{d.id}：{d.name}</option>
                            })}
                        </select>
                        <ErrorMessage message={errors["display_location"]}/>
                    </div>
                </div>

                <div className="form-group">
                    <label className="req">リコメンドタイトル</label>
                    <div>
                        <input name="recommend_title" type="text" placeholder="例：おすすめ商品" value={recommendTitle} maxLength={30} onChange={onChange} className={errors["recommend_title"] ? "error" : ""}/>
                        <ErrorMessage message={errors["recommend_title"]}/>
                    </div>
                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type={"button"} onClick={onClickBack} icon={ButtonIcon.Arrow}>前の画面に戻る</Button>
                    <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
                </div>
            </StyledSkuEdit>

        </div>

    </Layout>;

};

const StyledSkuEdit = styled(StyledForm)`

    label {
        width: 240px !important;
    }
    
    select, input {
        width: 400px !important;
    }

`;
