import {Modal, ModalColor} from "../sample_id/Modal";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import React, {useContext, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {adminPpTestKitApi} from "../../api/Api";
import {PpTestKitControllerAddReceptionRequest} from "../../generated";
import styled from "styled-components";

interface Props {
    wellplateId: string;
    flowcellId: string;
    toggleAddConfirm: () => void;
    sampleIds: string[];
    previousSampleIds: string[];
    onComplete: () => void;
}

export const AddRegister = ({wellplateId, flowcellId, toggleAddConfirm, sampleIds, previousSampleIds, onComplete}: Props) => {

    const {setDangerMessage, setShowSpinner} = useContext(AppContext);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [totalNum, setTotalNum] = useState<number>(0);
    const [addedNum, setAddedNum] = useState<number>(0);
    const [notConfirmedNum, setNotConfirmedNum] = useState<number>(0);

    const onAddComplete = () => {
        // 今回追加する検体IDを抽出
        const newSampleIds = sampleIds.filter((sampleId) => !previousSampleIds.includes(sampleId));

        if (newSampleIds.length === 0) {
            // 万が一ない場合
            setDangerMessage("追加対象の検体IDがありませんでした。");
            return;
        }

        setShowSpinner(true);

        const req: PpTestKitControllerAddReceptionRequest = {
            flowcell_id: flowcellId,
            wellplate_id: wellplateId,
            sample_ids: newSampleIds,
        };

        adminPpTestKitApi
            .v1AdminPpTestKitAddReceptionPost(req)
            .then(({data}) => {
                setTotalNum(data.total_num);
                setAddedNum(data.added_num);
                setNotConfirmedNum(data.not_confirmed_num);
                setIsComplete(true);
            })
            .catch((err) => {

                if (err.response.data && err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました。");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    console.log(isComplete);

    if (isComplete) {
        // 完了時モーダル
        return <Modal color={ModalColor.Success}>
            <StyledModal className="complete">
                <h4>試験情報の登録</h4>
                <h5>更新が完了しました。</h5>
                <ul>
                    <li>
                        <label className="small">登録対象件数</label>
                        <span className="value">{totalNum}</span>
                        <span className="postfix">件</span>
                    </li>
                    <li>
                        <label className="small">追加登録対象件数</label>
                        <span className="value">{addedNum}</span>
                        <span className="postfix">件</span>
                    </li>
                    <li>
                        <label className="small">検査未申込件数</label>
                        <span className="value">{notConfirmedNum}</span>
                        <span className="postfix">件</span>
                    </li>
                </ul>
                <div className="btn-area">
                    <Button type="button" color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={onComplete}>検査受付トップに戻る</Button>
                </div>
            </StyledModal>

        </Modal>
    }

    return <Modal color={ModalColor.Success}>
        <StyledModal>
            <h4>試験情報の登録</h4>
            <h5>以下のFlow Cell IDを更新しますか？</h5>
            <ul>
                <li><label className="small">PCR試験プレートID</label><span className="value">{wellplateId}</span></li>
                <li><label className="small">Flow Cell ID</label><span className="value">{flowcellId}</span></li>
            </ul>
            <div className="btn-area">
                <Button type="button" color={ButtonColor.Gray} icon={ButtonIcon.Arrow} onClick={toggleAddConfirm}>いいえ</Button>
                <Button type="button" color={ButtonColor.Orange} icon={ButtonIcon.Arrow} onClick={onAddComplete}>はい</Button>
            </div>
        </StyledModal>
    </Modal>

}

export const StyledModal = styled.div`
  &.complete {
    ul {
      li {
        
        .small {
          font-size: 16px !important;
        }
        
        .value {
          width: 80px !important;
          text-align: right;
          font-size: 40px;
        }

        .postfix {
        }
      }
    }

  }
`;
