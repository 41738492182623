import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import {NavLink} from "react-router-dom";
import styled from "styled-components";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {commonApi} from "../../api/Api";
import {AccesstokenRole} from "../../generated";

export const SystemIndex = () => {

    const {setShowSpinner, role} = useContext(AppContext);
    const [branch, setBranch] = useState<string>("");
    const [version, setVersion] = useState<string>("");
    const [revision, setRevision] = useState<string>("");

    useEffect(() => {
        getApiInfo();
    }, []);

    const getApiInfo = () => {
        setShowSpinner(true);

        commonApi.rootGet()
            .then(({data}) => {
                setBranch(data.branch);
                setVersion(data.version);
                setRevision(data.revision);
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
            setShowSpinner(false);

        });
    };

    return <Layout title={"システム管理"}>

        <div className="box">

            <StyledSystemIndex>

                <H3 title="システム管理メニュー"/>

                <ul>
                    {role === AccesstokenRole.RoleMaster && <li>
                        <NavLink to={"/pp_mst_user_admin"}>運営管理</NavLink>
                        <span>システム管理者、検査技師の管理を行います。</span>
                    </li>
                    }
                    <li>
                        <NavLink to={"/pp_mst_user_partner"}>提携機関ユーザー管理</NavLink>
                        <span>管理画面ログイン可能な、病院・提携法人ユーザーを管理します。</span>
                    </li>
                    <li>
                        <NavLink to={"/pp_mst_user_medical"}>病院管理</NavLink>
                        <span>病院の情報を管理します。</span>
                    </li>
                    <li>
                        <NavLink to={"/pp_mst_partner_inst"}>提携法人管理</NavLink>
                        <span>提携法人の情報を管理します。</span>
                    </li>
                    <li>
                        <NavLink to={"/pp_mst_sku"}>SKUコード管理</NavLink>
                        <span>検体情報に紐づけるSKUを管理します。</span>
                    </li>
                    <li>
                        <NavLink to={"/pp_mst_rcmnd_product"}>おすすめ商品管理</NavLink>
                        <span>おすすめの商品で表示する画像を設定します。</span>
                    </li>
                    <li>
                        <NavLink to={"/pp_announcement_admin"}>管理者用お知らせ管理</NavLink>
                        <span>管理画面のログイン画面、ログイン後のトップページに表示するお知らせを管理します。</span>
                    </li>
                    <li>
                        <NavLink to={"/pp_announcement_user"}>一般用お知らせ管理</NavLink>
                        <span>ユーザー画面に表示するお知らせを管理します。</span>
                    </li>
                    {role === AccesstokenRole.RoleMaster && <li>
                        <NavLink to={"/pp_maintenance"}>メンテナンス設定</NavLink>
                        <span>メンテナンス日時の設定、および設定の削除を行います。</span>
                    </li>}
                    <li>
                        <NavLink to={"/pp_mst_test_kit"}>検体ID登録</NavLink>
                        <span>検体ID、パスワードのリストを登録します。</span>
                    </li>
                    <li>
                        <NavLink to={"/debug"}>検体IDデバッグ</NavLink>
                        <span>検体IDからDBの状態を確認します。</span>
                    </li>
                    {role === AccesstokenRole.RoleMaster && <li>
                        <NavLink to={"/rollback/sample_id"}>検体切り戻し（検体単位）</NavLink>
                        <span>検査完了した検体を「検査中」まで切り戻します。</span>
                    </li>}

                    {role === AccesstokenRole.RoleMaster && <li>
                        <NavLink to={"/rollback/flowcell_id"}>検体切り戻し（FlowCell単位）</NavLink>
                        <span>検査完了した検体を「検査中」まで切り戻します。</span>
                    </li>}
                    {role === AccesstokenRole.RoleMaster && <li>
                        <NavLink to={"/logs"}>ログ表示</NavLink>
                        <span>CloudWatchに蓄積しているAPI操作ログを表示します。</span>
                    </li>}

                    {[String(AccesstokenRole.RoleMaster), String(AccesstokenRole.RoleOperator)].indexOf(String(role)) !== -1 && <li>
                        <NavLink to={"/pp_mst_identification_cd"}>識別コード管理</NavLink>
                        <span>提携法人に紐づく識別コードを管理します。</span>
                    </li>}

                    {role === AccesstokenRole.RoleMaster && <li>
                        <NavLink to={"/pp_faq"}>FAQ管理</NavLink>
                        <span>よくある質問の内容を管理します。</span>
                    </li>}

                    <li>
                        <NavLink to={"/pp_mst_rcmnd_method"}>リコメンド方式管理</NavLink>
                        <span>法人毎の表示パターンを設定します。</span>
                    </li>

                    <li>
                        <NavLink to={"/pp_mst_generic_rcmnd_group"}>汎用リコメンドグループ管理</NavLink>
                        <span>リコメンドイメージのタイトルを設定します。</span>
                    </li>

                    <li>
                        <NavLink to={"/pp_mst_rcmnd_products_title"}>個別商品リコメンドタイトル管理</NavLink>
                        <span>おすすめ商品管理のタイトルを設定します。</span>
                    </li>

                    <li>
                        <NavLink to={"/pp_mst_generic_rcmnd_image"}>汎用リコメンドイメージ管理</NavLink>
                        <span>おすすめ食材などの画像を管理します。</span>
                    </li>

                    {role === AccesstokenRole.RoleMaster && <li>
                        <NavLink to={"/pp_mst_meature_comments"}>対策コメント管理</NavLink>
                        <span>有害菌タブの対策コメントを管理します。</span>
                    </li>}

                </ul>

                <H3 title="管理画面リリース情報"/>

                <ul className="system-info">
                    <li>
                        Branch / {process.env.REACT_APP_GIT_BRANCH ?? "-"}
                    </li>
                    <li>
                        Commit / {process.env.REACT_APP_GIT_REVISION ?? "-"}
                    </li>
                    <li>
                        Version / {process.env.REACT_APP_GIT_VERSION ?? "-"}
                    </li>
                </ul>

                <H3 title="APIリリース情報"/>

                <ul className="system-info">
                    <li>
                        Branch / {branch ?? "-"}
                    </li>
                    <li>
                        Commit / {revision ?? "-"}
                    </li>
                    <li>
                        Version / {version ?? "-"}
                    </li>
                </ul>


            </StyledSystemIndex>

        </div>

    </Layout>

}

const StyledSystemIndex = styled.div`
    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 30px;

        li {
            width: 24%;
            margin-bottom: 20px;
            background-color: #eee;
            margin-right: 1%;
            padding: 30px 15px 20px 15px;
            border-radius: 8px;

            a {
                display: block;
                text-align: center;
                margin-bottom: 30px;

            }

            span {
                display: block;
                font-size: 14px;
                line-height: 1.7;
            }
        }

        &.system-info {
            margin-bottom: 30px;

            li {
                padding: 0;
                background-color: transparent;
                font-size: 12px;
                width: auto;
                margin-right: 30px;
            }
        }
    }

`
