import styled from "styled-components";

export enum BadBacteriaGraph1GraphColor {
    GREEN = "#00BFA5",
    RED = "#D93E4C",
    DEFAULT = "#8B8B8B"
}

interface BadBacteriaGraph1Props {
    value: number;
    color: BadBacteriaGraph1GraphColor;
}

const h = 16;
const w = 267;

// 100を最大値とするグラフ（スコア向け）
export const BadBacteriaGraph1 = ({value, color}: BadBacteriaGraph1Props) => {

    const w2 = w * (value / 100);

    return <StyledBadBacteriaGraph1 viewBox={`0 0 ${w} ${h}`}>
        <rect x={-10} y={0} width={w + 10} height={h} fill="#E8E8E8" rx={4} ry={4}/>
        <rect x={-10} y={0} width={w2 + 10} height={h} fill={color} rx={4} ry={4}/>
    </StyledBadBacteriaGraph1>

}

const StyledBadBacteriaGraph1 = styled.svg`
    height: ${h}px;
`;


interface BadBacteriaGraph2Props {
    value: number;
    color: BadBacteriaGraph1GraphColor;
    max: number;
}

const graph2H = 24;

// 100を最大値とするグラフ（有害菌向け）
export const BadBacteriaGraph2 = ({value, color, max}: BadBacteriaGraph2Props) => {

    // 最大値を超えたらmaxに揃える(で、角Rをなくす)
    let isOver = false;
    if (value > max) {
        value = max;
        isOver = true;
    }

    const w2 = w * (value / max);
    const y = (graph2H - h) / 2;

    return <StyledBadBacteriaGraph2 viewBox={`0 0 ${w} ${graph2H}`}>
        {/*5等分した線*/}
        <line x1={w / 5 + 1} y1={1} x2={w / 5 + 1} y2={graph2H - 1} strokeWidth={2} stroke="#D3D3D3"/>
        <line x1={w / 5 * 2 + 1} y1={1} x2={w / 5 * 2 + 1} y2={graph2H - 1} strokeWidth={2} stroke="#D3D3D3"/>
        <line x1={w / 5 * 3 + 1} y1={1} x2={w / 5 * 3 + 1} y2={graph2H - 1} strokeWidth={2} stroke="#D3D3D3"/>
        <line x1={w / 5 * 4 + 1} y1={1} x2={w / 5 * 4 + 1} y2={graph2H - 1} strokeWidth={2} stroke="#D3D3D3"/>

        {/*短形*/}
        <rect x={0} y={y} width={w} height={h} fill="#E8E8E8"/>
        <rect x={-10} y={y} width={w2 + 10} height={h} fill={color} rx={isOver ? 0 : 4} ry={isOver ? 0 : 4}/>

        {/*両端の線*/}
        <line x1={1} y1={0} x2={1} y2={graph2H} strokeWidth={2} stroke="#D3D3D3"/>
        <line x1={w - 1} y1={0} x2={w - 1} y2={graph2H} strokeWidth={2} stroke="#D3D3D3"/>
    </StyledBadBacteriaGraph2>
}

const StyledBadBacteriaGraph2 = styled.svg`
    height: ${graph2H}px;
`;