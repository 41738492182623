import styled from "styled-components";
import rightWhite from "../../../images/right-white.svg";
import {MainColor} from "../../common/Colors";

interface Props {
    offset: number
}

export const InterviewSteps = ({offset}: Props) => {

    const steps: string[] = [
        "入力1",
        "入力2",
        "確認",
        "完了",
    ];

    return <StyledRegistrationSteps>
        {steps.map((d, i) => {

            const classNames: string[] = [];

            if (i < offset) {
                classNames.push("done");
            }

            if (i === offset) {
                classNames.push("active");
            }

            return <li className={classNames.join(" ")} key={`step-${i}`}>{d}</li>
        })}
    </StyledRegistrationSteps>

};

export const StyledRegistrationSteps = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin-bottom: 32px;

  li {
    width: 78px;
    height: 78px;
    line-height: 78px;
    text-align: center;
    border: 1px solid #07997D;
    color: #07997D;
    border-radius: 50%;
    position: relative;
    margin-right: 30px;

    &:after {
      content: "";
      display: block;
      width: 30px;
      height: 20px;
      background-image: url(${rightWhite});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 5px auto;
      position: absolute;
      top: 50%;
      right: -30px;
      transform: translateY(-50%);
    }

    &:last-child {
      margin-right: 0;

      &:after {
        content: none;
      }
    }

    // 終わったもの
    &.done {
      color: #fff;
      border-color: #fff;
    }

    // 現在位置
    &.active {
      background-color: #fff;
      color: ${MainColor};
      border-color: #fff;
    }

  }

`;
