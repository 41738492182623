import React, {useState} from "react";
import styled from "styled-components";
import eyeOn from "../../images/eye-on.svg";
import eyeOff from "../../images/eye-off.svg";
import {MainColor} from "./Colors";

interface Props {
    name: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    width?: string;
}

export const PasswordInput = ({name, value, onChange, placeholder, width}: Props) => {

    const [isReveal, setIsReveal] = useState<boolean>(false);

    return <StyledPasswordInput style={width ? {width: width} : undefined}>
        <input type={isReveal ? "text" : "password"} name={name} value={value} className="medium" onChange={onChange} placeholder={placeholder ?? ""}/>
        <span className={isReveal ? "off" : ""} onClick={() => {
            setIsReveal(!isReveal);
        }}/>
    </StyledPasswordInput>
};

const StyledPasswordInput = styled.div`
  position: relative;
  width: auto;
  overflow: hidden;
  //max-width: 300px;

  input {
    width: 100% !important;
    padding-right: 55px !important;
    outline: none;
  }
  
  span {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
    display: block;
    background-size: 32px auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(${eyeOn});
    width: 48px;
    height: 100%;
    background-color: ${MainColor};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    &.off {
      background-image: url(${eyeOff});
    }
    
  }

  

`