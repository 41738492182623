import {PptestkitBiPetSex, TypesPpTestKitData} from "../../../generated";
import {H3} from "../../common/Headline";
import React from "react";
import {YesNo} from "./YesNo";
import {ErrorMessage} from "../../common/ErrorMessage";
import {YesNoUnknown} from "./YesNoUnknown";
import {Disease} from "./Disease";
import styled from "styled-components";
import {GetLactatedChildren, GetLactationExperience, GetPregnancyWeeks, GetSymptomsTimings, GetTotalLactationDuration} from "../../../utility/Utility";

interface Props {
    errors: { [key: string]: string };
    onUpdate: (next: TypesPpTestKitData) => void;
    ppTestKit: TypesPpTestKitData;
}

export const Condition = ({errors, onUpdate, ppTestKit}: Props) => {

    // チェックボックス変更
    const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const next: any = ppTestKit;
        // チェクボックスは、checked === true で文字列1、0を切り替える
        next[e.currentTarget.name] = e.currentTarget.checked ? "1" : "0";
        onUpdate(next);
    };

    // その他フォーム変更
    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const next: any = ppTestKit;
        next[e.currentTarget.name] = e.currentTarget.value;

        // 付随するデータも更新する
        switch (e.currentTarget.name) {
            case "is_vomit":
                if (e.currentTarget.value === "0") {
                    next.is_vomit_timing = "";
                }
                break;
            case "is_diarrhea":
                if (e.currentTarget.value === "0") {
                    next.is_diarrhea_timing = "";
                }
                break;
            case "is_skin_trouble":
                if (e.currentTarget.value === "0") {
                    next.is_skin_trouble_timing = "";
                    next.is_skin_trouble_symptoms_itch = "";
                    next.is_skin_trouble_symptoms_hairloss = "";
                    next.is_skin_trouble_symptoms_sore = "";
                    next.is_skin_trouble_symptoms_rash = "";
                    next.is_skin_trouble_symptoms_dry = "";
                    next.is_skin_trouble_symptoms_dandruff = "";
                    next.is_skin_trouble_symptoms_other = "";
                    next.is_skin_trouble_symptoms_comment = "";
                }
                break;
            case "is_lactation_experience":
                if (e.currentTarget.value !== "1") {
                    // なしの場合、子供数と期間をクリアする
                    next.is_lactated_children = "";
                    next.is_total_lactation_duration = "";
                }
                break;
            case "is_was_pregnant_past":
                if (e.currentTarget.value !== "1") {
                    next.is_lactation_experience = "";
                    next.is_lactated_children = "";
                    next.is_total_lactation_duration = "";
                }
                break;
            case "is_pregnancy":
                if (e.currentTarget.value !== "0") {
                    next.is_pregnancy_weeks = "0";
                }
                break;
        }

        onUpdate(next);
    };

    // ----- 各種リストを準備

    // 症状リスト
    const symptomTimings = GetSymptomsTimings();
    // 泌乳経験
    const lactationExperiences = GetLactationExperience();
    // 泌乳した子供の数
    const lactatedChildren = GetLactatedChildren();
    // 泌乳した期間
    const lactationDurations = GetTotalLactationDuration();
    // 妊娠期間
    const pregnancyWeeks = GetPregnancyWeeks();

    // オスフラグ
    const isMale = ppTestKit.bi_pet_sex === PptestkitBiPetSex.BiPetSex1;

    return <StyledCondition className="box">

        <H3 title={"体調について"} subtitle="condition"/>

        <div className="form-wrapper">

            <div className="form-group confirm">
                <label className="req">病歴<small>（直近3ヶ月以内）</small></label>
                <div>
                    <YesNo name="is_disease" value={ppTestKit.is_disease ?? ""} onChange={onChange}/>
                    <ErrorMessage message={errors["is_disease"]}/>
                    {/*疾患、症状の入力フォーム*/}
                    <Disease errors={errors} ppTestKit={ppTestKit} onUpdate={onUpdate} disabled={ppTestKit.is_disease !== "1"}/>
                </div>
            </div>

            <div className="form-group center">
                <label className="req">嘔吐<small>（直近3ヶ月以内）</small></label>
                <div>
                    <div className="column-2">
                        <div className="column">
                            <YesNo name="is_vomit" value={ppTestKit.is_vomit ?? ""} onChange={onChange}/>
                            <ErrorMessage message={errors["is_vomit"]}/>
                        </div>
                        <div className="column">
                            <div className="inline">
                                <label>時期</label>
                                <select name="is_vomit_timing" value={ppTestKit.is_vomit_timing ?? ""} onChange={onChange} disabled={ppTestKit.is_vomit !== "1"}>
                                    <option value="">--- 選択してください</option>
                                    {Object.keys(symptomTimings).map((key) => {
                                        return <option value={key} key={`is_vomit_timing-${key}`}>{symptomTimings[key]}</option>
                                    })}
                                </select>
                            </div>
                            <ErrorMessage message={errors["is_vomit_timing"]}/>

                        </div>
                    </div>
                </div>
            </div>

            <div className="form-group center">
                <label className="req">下痢<small>（直近3ヶ月以内）</small></label>
                <div>
                    <div className="column-2">
                        <div className="column">
                            <YesNo name="is_diarrhea" value={ppTestKit.is_diarrhea ?? ""} onChange={onChange}/>
                            <ErrorMessage message={errors["is_diarrhea"]}/>
                        </div>
                        <div className="column">
                            <div className="inline">
                                <label>時期</label>
                                <select name="is_diarrhea_timing" value={ppTestKit.is_diarrhea_timing ?? ""} onChange={onChange} disabled={ppTestKit.is_diarrhea !== "1"}>
                                    <option value="">--- 選択してください</option>
                                    {Object.keys(symptomTimings).map((key) => {
                                        return <option value={key} key={`is_diarrhea_timing-${key}`}>{symptomTimings[key]}</option>
                                    })}
                                </select>
                            </div>
                            <ErrorMessage message={errors["is_diarrhea_timing"]}/>

                        </div>
                    </div>
                </div>
            </div>

            <div className="form-group center">
                <label className="req">皮膚トラブル<small>（直近3ヶ月以内）</small></label>
                <div>
                    <div className="column-2">
                        <div className="column">
                            <YesNo name="is_skin_trouble" value={ppTestKit.is_skin_trouble ?? ""} onChange={onChange}/>
                            <ErrorMessage message={errors["is_skin_trouble"]}/>
                        </div>
                        <div className="column">
                            <div className="inline">
                                <label>時期</label>
                                <div>
                                    <select name="is_skin_trouble_timing" value={ppTestKit.is_skin_trouble_timing ?? ""} onChange={onChange} disabled={ppTestKit.is_skin_trouble !== "1"}>
                                        <option value="">--- 選択してください</option>
                                        {Object.keys(symptomTimings).map((key) => {
                                            return <option value={key} key={`is_skin_trouble_timing-${key}`}>{symptomTimings[key]}</option>
                                        })}
                                    </select>

                                    <div>

                                        <div className="row no-border border-top">
                                            <div className="check">
                                                <label>
                                                    <input type="checkbox" name="is_skin_trouble_symptoms_itch" checked={ppTestKit.is_skin_trouble_symptoms_itch === "1"} onChange={onCheckChange} disabled={ppTestKit.is_skin_trouble !== "1"}/>
                                                    かゆみ
                                                </label>
                                                <label>
                                                    <input type="checkbox" name="is_skin_trouble_symptoms_hairloss" checked={ppTestKit.is_skin_trouble_symptoms_hairloss === "1"} onChange={onCheckChange} disabled={ppTestKit.is_skin_trouble !== "1"}/>
                                                    脱毛
                                                </label>
                                                <label>
                                                    <input type="checkbox" name="is_skin_trouble_symptoms_sore" checked={ppTestKit.is_skin_trouble_symptoms_sore === "1"} onChange={onCheckChange} disabled={ppTestKit.is_skin_trouble !== "1"}/>
                                                    赤み
                                                </label>
                                                <label>
                                                    <input type="checkbox" name="is_skin_trouble_symptoms_rash" checked={ppTestKit.is_skin_trouble_symptoms_rash === "1"} onChange={onCheckChange} disabled={ppTestKit.is_skin_trouble !== "1"}/>
                                                    発疹
                                                </label>
                                                <label>
                                                    <input type="checkbox" name="is_skin_trouble_symptoms_dry" checked={ppTestKit.is_skin_trouble_symptoms_dry === "1"} onChange={onCheckChange} disabled={ppTestKit.is_skin_trouble !== "1"}/>
                                                    乾燥
                                                </label>
                                                <label>
                                                    <input type="checkbox" name="is_skin_trouble_symptoms_dandruff" checked={ppTestKit.is_skin_trouble_symptoms_dandruff === "1"} onChange={onCheckChange} disabled={ppTestKit.is_skin_trouble !== "1"}/>
                                                    フケ
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="inline">
                                                <div className="check">
                                                    <label>
                                                        <input type="checkbox" name="is_skin_trouble_symptoms_other" checked={ppTestKit.is_skin_trouble_symptoms_other === "1"} onChange={onCheckChange} disabled={ppTestKit.is_skin_trouble !== "1"}/>
                                                        その他：
                                                    </label>
                                                </div>
                                                <input type="text" maxLength={100} name="is_skin_trouble_symptoms_comment" value={ppTestKit.is_skin_trouble_symptoms_comment ?? ""} placeholder="疾患を記入してください" onChange={onChange} disabled={ppTestKit.is_skin_trouble !== "1"}/>
                                            </div>

                                            <ErrorMessage message={errors["is_skin_trouble_symptoms_comment"]} top={30}/>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <ErrorMessage message={errors["is_skin_trouble_timing"]} left={140}/>

                        </div>
                    </div>

                </div>
            </div>

            <div className="form-group center">
                <label className="req">目やにまたは<br/>涙やけ<small>（直近3ヶ月以内）</small></label>
                <div>
                    <YesNo name="is_eyemucus_tearsstain" value={ppTestKit.is_eyemucus_tearsstain ?? ""} onChange={onChange}/>
                    <ErrorMessage message={errors["is_eyemucus_tearsstain"]}/>

                </div>
            </div>

            <div className="form-group center">
                <label className="req">抗生物質の投与<small>（直近3ヶ月以内）</small></label>
                <div>
                    <YesNo name="is_medicine_antibiotics" value={ppTestKit.is_medicine_antibiotics ?? ""} onChange={onChange}/>
                    <ErrorMessage message={errors["is_medicine_antibiotics"]}/>
                </div>
            </div>

            <div className="form-group center">
                <label className="req">抗生物質以外<br/>薬の投与<small>（直近3ヶ月以内）</small></label>
                <div>
                    <YesNo name="is_medicine_except_antibiotics" value={ppTestKit.is_medicine_except_antibiotics ?? ""} onChange={onChange}/>
                    <ErrorMessage message={errors["is_medicine_except_antibiotics"]}/>

                </div>
            </div>

            <div className="form-group center">
                <label className="req">避妊・去勢の<br/>手術経験</label>
                <div>
                    <YesNoUnknown name="is_spayed_neutered" value={ppTestKit.is_spayed_neutered ?? ""} onChange={onChange} disabled={false}/>
                    <ErrorMessage message={errors["is_spayed_neutered"]}/>

                </div>
            </div>

            <div className="form-group center">
                <label className="req">過去の妊娠の<br/>有無</label>
                <div>
                    <div className="row">
                        <YesNoUnknown name="is_was_pregnant_past" value={ppTestKit.is_was_pregnant_past ?? ""} onChange={onChange} disabled={isMale}/>
                        <ErrorMessage message={errors["is_was_pregnant_past"]}/>
                    </div>

                    <div className="row">
                        <div className="inline">
                            <span className="fixed-1">泌乳経験</span>
                            <select name="is_lactation_experience" value={ppTestKit.is_lactation_experience ?? ""} style={{width: "120px"}} onChange={onChange} disabled={ppTestKit.is_was_pregnant_past !== "1"}>
                                <option value="">--- 選択</option>
                                {Object.keys(lactationExperiences).reverse().map((key) => {
                                    return <option value={key} key={`is_lactation_experience-${key}`}>{lactationExperiences[key]}</option>
                                })}
                            </select>
                            <span className="fixed-1">泌乳した<br/>子供の合計</span>
                            <select name="is_lactated_children" value={ppTestKit.is_lactated_children ?? ""} style={{width: "120px"}} onChange={onChange} disabled={ppTestKit.is_was_pregnant_past !== "1" || ppTestKit.is_lactation_experience !== "1"}>
                                <option value="">--- 選択</option>
                                {Object.keys(lactatedChildren).map((key) => {
                                    return <option value={key} key={`is_lactated_children-${key}`}>{lactatedChildren[key]}</option>
                                })}
                            </select>
                            <span className="fixed-1">泌乳した<br/>期間の合計</span>
                            <select name="is_total_lactation_duration" value={ppTestKit.is_total_lactation_duration ?? ""} style={{width: "120px"}} onChange={onChange} disabled={ppTestKit.is_was_pregnant_past !== "1" || ppTestKit.is_lactation_experience !== "1"}>
                                <option value="">--- 選択</option>
                                {Object.keys(lactationDurations).map((key) => {
                                    return <option value={key} key={`is_total_lactation_duration-${key}`}>{lactationDurations[key]}</option>
                                })}
                            </select>
                            <ErrorMessage message={errors["is_lactation_experience"]} top={25}/>
                            <ErrorMessage message={errors["is_lactated_children"]} top={25} left={250}/>
                            <ErrorMessage message={errors["is_total_lactation_duration"]} top={25} left={500}/>

                        </div>

                    </div>
                </div>
            </div>

            <div className="form-group center">
                <label className="req">現在の妊娠・<br/>妊娠の可能性</label>
                <div>

                    <div className="column-2">
                        <div className="column">
                            <YesNoUnknown name="is_pregnancy" value={ppTestKit.is_pregnancy ?? ""} onChange={onChange} disabled={isMale}/>
                            <ErrorMessage message={errors["is_pregnancy"]}/>
                        </div>
                        <div className="column">
                            <div className="inline">
                                <label>妊娠期間</label>
                                <select name="is_pregnancy_weeks" value={ppTestKit.is_pregnancy_weeks ?? ""} style={{width: "120px"}} onChange={onChange} disabled={ppTestKit.is_pregnancy !== "1"}>
                                    {Object.keys(pregnancyWeeks).map((key) => {
                                        return <option value={key} key={`is_pregnancy_weeks-${key}`}>{pregnancyWeeks[key]}</option>
                                    })}
                                </select>
                            </div>
                            <ErrorMessage message={errors["is_pregnancy_weeks"]}/>

                        </div>
                    </div>

                </div>
            </div>


        </div>

    </StyledCondition>

};


const StyledCondition = styled.div`

  .row {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px dotted #ccc;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }

    input[type=checkbox] {
      width: 16px;
    }

    .check {
      label {
        white-space: nowrap;
      }
    }

    input[type=text] {
      width: 600px;
      line-height: 40px;
    }

    &.no-border {
      border-bottom: none;
    }

    &.border-top {
      border-top: 1px dotted #ccc;
      padding-top: 15px;
      margin-top: 15px;
    }

    .inline {

      .fixed-1 {
        width: 110px;
        padding-left: 10px;
      }

    }
  }

`;