import React from "react";

interface Props {
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const YesNo = ({name, value, onChange}: Props) => {

    return <div className="check">
        <label>
            <input type="radio" value="1" name={name} onChange={onChange} checked={value === "1"}/>
            はい
        </label>
        <label>
            <input type="radio" value="0" name={name} onChange={onChange} checked={value === "0"}/>
            いいえ
        </label>
    </div>

}