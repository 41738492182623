import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {Table} from "../common/Table";
import {GetPartnerInstDivision, SlashedDatetime, SlashedYmd} from "../../utility/Utility";
import {H3} from "../common/Headline";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Links} from "../common/Links";
import {Paginator} from "../common/Paginator";
import {Counter} from "../common/Counter";
import {adminPpMstUserPartnerApi} from "../../api/Api";
import {TypesPpMstUserPartnerData} from "../../generated";

export const PpMstUserPartnerIndex = () => {

    const {setShowSpinner, setDangerMessage, setSuccessMessage} = useContext(AppContext);
    const [ppMstUserPartners, setPpMstUserPartners] = useState<TypesPpMstUserPartnerData[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const navigate = useNavigate();
    const location = useLocation();

    const size = 100;

    // URL変更時
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get("page")) {
            setPage(Number(params.get("page")));
        }
    }, [location]);

    // ページ変更時
    useEffect(() => {
        getAdminUsers();
    }, [page]);

    const getAdminUsers = () => {

        setShowSpinner(true);

        adminPpMstUserPartnerApi.v1AdminPpMstUserPartnerGet("", page, size)
            .then(({data}) => {
                setPpMstUserPartners(data.pp_mst_user_partners ?? []);
                setTotal(data.total ?? 0);
            })
            .catch((err) => {
                console.log(err);
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onDelete = (e: React.MouseEvent<HTMLSpanElement>, ppMstUserPartner: TypesPpMstUserPartnerData): void => {

        e.preventDefault();

        if (!window.confirm(`${ppMstUserPartner.name}さん を削除します。よろしいですか？`)) {
            return;
        }

        setShowSpinner(true);

        adminPpMstUserPartnerApi.v1AdminPpMstUserPartnerDeleteIdDelete(String(ppMstUserPartner.id))
            .then(() => {
                setSuccessMessage("提携機関ユーザーを削除しました。");
                getAdminUsers();
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onPageChange = (page: number): void => {
        navigate(`/pp_mst_user_partner?page=${page}`);
    };

    return <Layout title={"提携機関ユーザー"}>

        <div className="box">

            <H3 title="提携機関ユーザー一覧" subtitle="list" counter={<Counter total={total} size={size} current={page}/>}>
                <Links>
                    <NavLink to={"./create"}>新規追加</NavLink>
                </Links>
            </H3>

            <Table>

                <thead>
                <tr>
                    <th>ID</th>
                    <th>登録日</th>
                    <th>提携機関区分</th>
                    <th>提携機関名</th>
                    <th>名前</th>
                    <th>識別コード</th>
                    <th>メールアドレス</th>
                    <th>最終ログイン</th>
                    <th>最終ログインIP</th>
                    <th>編集</th>
                    <th>削除</th>
                </tr>
                </thead>

                <tbody>
                {ppMstUserPartners.map((d, i) => {

                    return <tr key={`row-${i}`}>
                        <td>{d.id}</td>
                        <td>{SlashedYmd(d.created ?? "")}</td>
                        <td>{GetPartnerInstDivision(d.partner_inst_division ?? "")}</td>
                        <td>{d.partner_inst_name}</td>
                        <td>{d.name}</td>
                        <td>{d.identification_cd ? d.identification_cd : "-"}</td>
                        <td>{d.email}</td>
                        <td>{d.last_login ? SlashedDatetime(d.last_login) : "-"}</td>
                        <td>{d.last_login_ip ? d.last_login_ip : "-"}</td>
                        <td><NavLink to={`./edit/${d.id}`}>編集</NavLink></td>
                        <td><span onClick={(e) => onDelete(e, d)}>削除</span></td>
                    </tr>

                })}
                </tbody>

            </Table>

            <Paginator onClick={onPageChange} pages={Math.ceil(total / size)} page={page}/>

        </div>

    </Layout>

};
