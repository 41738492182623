import Cropper, {ReactCropperElement} from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {useContext, useEffect, useRef, useState,} from 'react';

import styles from './IconCropModal.module.css';

import leftRotate from './images/toLeftRotate_icon.svg';
import rightRotate from './images/toRightRotate_icon.svg';

import next from './images/next_icon.svg';
import styled from "styled-components";
import {AppContext} from "../../../contexts/AppContext";

interface Props {
    className?: string;
    /**
     * File 型の画像データを指定する
     * file に値が入った（変更された）ときにモーダルが表示される
     */
    file: File | null | undefined;
    /**
     * crop した画像データを返却する
     */
    getCroppedPetIcon: (croppedImg: File | null | undefined) => void;
    /**
     * crop 作業を中止した際に発火する
     */
    onCancelCropping?: () => void;
}

const ObjectIconCropModal = ({
                                 className = '',
                                 file,
                                 getCroppedPetIcon,
                                 onCancelCropping,
                             }: Props) => {
    const [imgData, setImgData] = useState<string>('');
    const cropperRef = useRef<ReactCropperElement>(null);
    const {setShowSpinner} = useContext(AppContext);

    /**
     * BlobやFileを読み取って、base64にする、その後セット関数にセット
     */
    const readAndSetFile = async (fileSelf: File) => {
        let formattedFile = fileSelf;

        /**
         * heic2anyを通して、heicをjpgに変換し、それからbase64に変換する
         */
        if (fileSelf.type === 'image/heic' || fileSelf.type === 'image/heif') {
            setShowSpinner(true);

            // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
            const heic2any = require('heic2any');

            formattedFile = await heic2any({
                blob: fileSelf,
                toType: 'image/jpeg',
                quality: 1,
            });

            setShowSpinner(false);
        }

        const reader = new FileReader();
        const imgLoadPromise = new Promise(
            () => {
                reader.readAsDataURL(formattedFile);
            },
        );

        // fileのreadAsDataURLが完了したらonloadを発火
        imgLoadPromise.then(
            reader.onload = () => {
                if (reader.result) {
                    // readAsDataURLの場合stringしか返ってこないため
                    const data = reader.result as string;
                    setImgData(data);
                }
            },
        );
    };

    useEffect(() => {
        if (!file) return;

        readAndSetFile(file);
    }, [file]);

    const rotateRight = () => {
        if (cropperRef.current) {
            const imgElement = cropperRef.current.cropper;
            imgElement.rotate(90);
        }
    };

    const rotateLeft = () => {
        if (cropperRef.current) {
            const imgElement = cropperRef.current.cropper;
            imgElement.rotate(-90);
        }
    };

    const crop = () => {
        if (cropperRef.current && file) {
            const imgElement = cropperRef.current.cropper;

            const option = { maxHeight: 952, maxWidth: 1125 };

            const exchangeAndSetFile = (type = 'image/jpeg') => {
                imgElement.getCroppedCanvas(option).toBlob((blob) => {
                    // blobの型がBlob | nullのため
                    if (blob) {
                        const blobToFile = new File([blob], file.name, { type, lastModified: file.lastModified });
                        getCroppedPetIcon(blobToFile);

                        setImgData('');
                    }
                }, type);
            };

            if (file.type === 'image/heic' || file.type === 'image/heif') {
                exchangeAndSetFile();
            } else {
                exchangeAndSetFile(file.type);
            }
        }
    };

    const cancel = () => {
        setImgData('');

        if (onCancelCropping) onCancelCropping();
    };

    return (
        <StyledObjectIconCropModal className={`${className} ${styles.cropModal}`} aria-hidden={!imgData}>
            <div className={styles.cropModalBg} onClick={cancel}/>

            <dialog className={styles.cropModalContents} open>
                <header className={styles.cropModalHeader}>写真の設定</header>

                <div className={styles.cropper}>
                    <Cropper
                        ref={cropperRef}
                        src={imgData}
                        style={{height: '600px', width: '100%'}}
                        responsive={false}
                        autoCropArea={1}
                        checkOrientation={false}
                        guides={true}
                        aspectRatio={1124 / 952}
                        cropBoxMovable={false}
                        cropBoxResizable={false}
                        toggleDragModeOnDblclick={false}
                        dragMode='move'
                        viewMode={1}
                    />
                </div>

                <div className={styles.modalFooter}>
                    <div className={styles.cropperButtons}>
                        <button type='button' className={styles.cropperRotateButton} onClick={rotateRight}><img src={rightRotate} width={30} height={30} alt='右回転'/></button>
                        <p>回転</p>
                        <button type='button' className={styles.cropperRotateButton} onClick={rotateLeft}><img src={leftRotate} width={30} height={30} alt='左回転'/></button>
                    </div>

                    {/* backendでも同じコンポーネントを使用するためElementButtonは使わない */}
                    <button type='button' className={styles.submitButton} onClick={crop}>
                        反映する<img src={next} width={10} height={18} alt='反映する' className={styles.nextIcon}/>
                    </button>
                </div>
            </dialog>
        </StyledObjectIconCropModal>
    );
};

export default ObjectIconCropModal;


const StyledObjectIconCropModal = styled.div`
    .cropper-crop-box {
        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 100;
            aspect-ratio: 1/1;
            pointer-events: none;

            /**
              辺の短い方に合わせるためheightを基準に
              なぜかsafariの場合height:100%だと楕円になるため、
              min-height、max-heightを100%指定にする
            */
            min-height: 80%;
            max-height: 80%;
            border-radius: 50%;
            border-color: #f7e759;
            border-style: solid;
            border-width: 10px;

            @media (--media-md) {
                border-width: 5px;
            }
        }
    }

    .cropper-modal {
        opacity: .6 !important;
    }
`;