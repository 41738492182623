import styled from "styled-components";
import React, {useContext, useEffect, useRef, useState} from "react";
import {ErrorMessage} from "../../common/ErrorMessage";
import {BirthTypeConfirmed, BirthTypeEstimated, BirthTypeUnknown, GetAnimalType, GetDateList, GetMonthList, GetWeight1, GetWeight2, GetYearList} from "../../../utility/Utility";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "../../common/Button";
import {AppContext} from "../../../contexts/AppContext";
import {assetsApi, ppMstBreedApi} from "../../../api/Api";
import {PpMstBreedModelGetListResponse, PptestkitBiAccuracyOfPetBirthday, PptestkitBiAnimalType, PptestkitBiPetSex, TypesPpTestKitData} from "../../../generated";
import {PetModal} from "../../common/PetModal";
import {BreedInput} from "../../common/BreedInput";

interface Props extends React.PropsWithChildren {
    onUpdate: (next: TypesPpTestKitData) => void;
    ppTestKit: TypesPpTestKitData;
    errors: { [key: string]: string };
}

export const PetForm = ({ppTestKit, onUpdate, errors}: Props) => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [birthYear, setBirthYear] = useState<string>("");
    const [birthMonth, setBirthMonth] = useState<string>("");
    const [birthDate, setBirthDate] = useState<string>("");

    const [samplingYear, setSamplingYear] = useState<string>("");
    const [samplingMonth, setSamplingMonth] = useState<string>("");
    const [samplingDate, setSamplingDate] = useState<string>("");

    const [weight1, setWeight1] = useState<string>("");
    const [weight2, setWeight2] = useState<string>("");

    const [icon, setIcon] = useState<string>("");
    const [viewPath, setViewPath] = useState<string>("");
    const [filename, setFilename] = useState<string>("");
    const [isBirthdayActive, setIsBirthdayActive] = useState<boolean>(true);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isModalImg, setIsModalImg] = React.useState<boolean>(false);
    const [ppMstBreeds, setPpMstBreeds] = useState<PpMstBreedModelGetListResponse[]>([]);

    useEffect(() => {
        // 品種リスト取得
        getBreeds();

        if (ppTestKit?.bi_pet_birthday) {
            const parts = ppTestKit.bi_pet_birthday.split("-");
            setBirthYear(parts[0]);
            setBirthMonth(parts[1]);
            setBirthDate(parts[2]);
        }

        if (ppTestKit?.is_pet_weight) {
            const parts = String(ppTestKit.is_pet_weight).split(".");
            setWeight1(parts[0]);
            setWeight2(parts[1]);
        }

        if (ppTestKit?.sampling_date) {
            const parts = ppTestKit.sampling_date.split("-");
            setSamplingYear(parts[0]);
            setSamplingMonth(parts[1]);
            setSamplingDate(parts[2]);
        }

        if (ppTestKit?.bi_icon) {
            setIcon(ppTestKit.bi_icon);
            setViewPath(ppTestKit.bi_icon_url ?? "");
            setFilename(ppTestKit.bi_icon_name ?? "");
        }


    }, []);

    const getBreeds = () => {

        setShowSpinner(true);

        ppMstBreedApi.v1PpMstBreedListGet()
            .then(({data}) => {
                setPpMstBreeds(data);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        console.log(e.currentTarget.name);

        switch (e.currentTarget.name) {

            case "bi_pet_name":
                ppTestKit.bi_pet_name = e.currentTarget.value;
                break;
            case "bi_animal_type":
                ppTestKit.bi_animal_type = e.currentTarget.value as PptestkitBiAnimalType;
                break;
            case "bi_accuracy_of_pet_birthday":
                ppTestKit.bi_accuracy_of_pet_birthday = e.currentTarget.value as PptestkitBiAccuracyOfPetBirthday;
                setIsBirthdayActive(e.currentTarget.value !== BirthTypeUnknown);

                if (e.currentTarget.value === BirthTypeUnknown) {
                    // 不明の場合、生年月日をクリア
                    setBirthYear("");
                    setBirthMonth("");
                    setBirthDate("");
                    ppTestKit.bi_pet_birthday = "";
                }

                break;
            case "birth_year":
                setBirthYear(e.currentTarget.value);
                ppTestKit.bi_pet_birthday = `${e.currentTarget.value}-${birthMonth}-${birthDate}`;
                break;
            case "birth_month":
                setBirthMonth(e.currentTarget.value);
                ppTestKit.bi_pet_birthday = `${birthYear}-${e.currentTarget.value}-${birthDate}`;
                break;
            case "birth_date":
                setBirthDate(e.currentTarget.value);
                ppTestKit.bi_pet_birthday = `${birthYear}-${birthMonth}-${e.currentTarget.value}`;
                break;
            case "bi_pet_sex":
                ppTestKit.bi_pet_sex = e.currentTarget.value as PptestkitBiPetSex;
                break;

            case "sampling_year":
                setSamplingYear(e.currentTarget.value);
                ppTestKit.sampling_date = `${e.currentTarget.value}-${samplingMonth}-${samplingDate}`;
                break;
            case "sampling_month":
                setSamplingMonth(e.currentTarget.value);
                ppTestKit.sampling_date = `${samplingYear}-${e.currentTarget.value}-${samplingDate}`;
                break;
            case "sampling_date":
                setSamplingDate(e.currentTarget.value);
                ppTestKit.sampling_date = `${samplingYear}-${samplingMonth}-${e.currentTarget.value}`;
                break;

            case "weight1":
                setWeight1(e.currentTarget.value);
                ppTestKit.is_pet_weight = `${e.currentTarget.value}.${weight2}`;
                break;
            case "weight2":
                setWeight2(e.currentTarget.value);
                ppTestKit.is_pet_weight = `${weight1}.${e.currentTarget.value}`;
                break;
        }

        onUpdate(ppTestKit);
    }

    const onFileSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // 画像アップロード
    const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {

        e.preventDefault();

        const files = e.currentTarget.files;

        if (!files) {
            return;
        }

        if (files.length === 0) {
            setDangerMessage("ファイルが未選択です。");
            return;
        }

        const file = files.item(0);

        if (!file) {
            return;
        }

        const allowedMimeTypes = [
            "image/jpeg",
            "image/gif",
            "image/png",
        ];

        if (allowedMimeTypes.indexOf(file.type) === -1) {
            setDangerMessage(`ファイル形式が不正です。 ${file.type} はアップロードできません。`);
            return;
        }

        setShowSpinner(true);

        assetsApi.v1UploadImagePost(file)
            .then(({data}) => {
                setIcon(data.path ?? "");
                setViewPath(data.view_path ?? "");
                setFilename(data.original_filename ?? "");

                ppTestKit.bi_icon = data.path;
                onUpdate(ppTestKit);

                console.log(icon);

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const isMix = (breedId: number | null): boolean => {

        if (breedId === null) {
            return false;
        }

        let isMix = false;

        ppMstBreeds.forEach((d) => {
            if (d.id === breedId && d.breed_name?.toLowerCase().indexOf("mix") === 0) {
                isMix = true;
            }
        });

        return isMix;
    };

    const onBreedChange = (name: string, id: number | null) => {

        switch (name) {
            case "bi_breed":
                ppTestKit.bi_breed = id;
                if (!isMix(id)) {
                    ppTestKit.bi_breed_of_parents_1 = null;
                    ppTestKit.bi_breed_of_parents_2 = null;
                }
                break;
            case "bi_breed_of_parents_1":
                ppTestKit.bi_breed_of_parents_1 = id;
                break;
            case "bi_breed_of_parents_2":
                ppTestKit.bi_breed_of_parents_2 = id;
                break;
        }

        onUpdate(ppTestKit);
    };

    const onDeleteImg = (e: React.MouseEvent<HTMLDivElement>): void => {

        e.preventDefault();

        setIcon("");
        setViewPath( "");
        setFilename("");

        ppTestKit.bi_icon = null;
        ppTestKit.bi_icon_url = null;
        ppTestKit.bi_icon_name = null;

        onUpdate(ppTestKit);
    };

    return <StyledPetForm className="form-wrapper">

        <div className="form-group">
            <label className="req">お名前</label>
            <div>
                <input type="text" name="bi_pet_name" value={ppTestKit?.bi_pet_name ?? ""} onChange={onChange} placeholder={"例：にゃんにゃん"}/>
                <ErrorMessage message={errors["bi_pet_name"]}/>
            </div>
        </div>

        <div className="form-group center">
            <label className="req">種別</label>
            <div>
                {GetAnimalType(ppTestKit?.bi_animal_type ?? "")}
                （種別は変更できません）
            </div>
        </div>

        <div className="form-group">
            <label className="req">品種</label>
            <div>
                <BreedInput
                    name="bi_breed"
                    animalType={ppTestKit?.bi_animal_type ?? ""}
                    onChange={onBreedChange}
                    ppMstBreeds={ppMstBreeds}
                    value={ppTestKit?.bi_breed ? Number(ppTestKit?.bi_breed) : null}
                />

                <ErrorMessage message={errors["bi_breed"]}/>
            </div>
        </div>

        {isMix(ppTestKit?.bi_breed ?? null) && <div className="form-group">
            <label className="req">両親の品種1</label>
            <div>
                <BreedInput
                    name="bi_breed_of_parents_1"
                    animalType={ppTestKit?.bi_animal_type ?? ""}
                    onChange={onBreedChange}
                    ppMstBreeds={ppMstBreeds}
                    value={ppTestKit?.bi_breed_of_parents_1 ? Number(ppTestKit?.bi_breed_of_parents_1) : null}
                />

                <ErrorMessage message={errors["bi_breed_of_parents_1"]}/>
            </div>
        </div>}


        {isMix(ppTestKit?.bi_breed ?? null) && <div className="form-group">
            <label className="req">両親の品種1</label>
            <div>
                <BreedInput
                    name="bi_breed_of_parents_2"
                    animalType={ppTestKit?.bi_animal_type ?? ""}
                    onChange={onBreedChange}
                    ppMstBreeds={ppMstBreeds}
                    value={ppTestKit?.bi_breed_of_parents_2 ? Number(ppTestKit?.bi_breed_of_parents_2) : null}
                />

                <ErrorMessage message={errors["bi_breed_of_parents_2"]}/>
            </div>
        </div>}


        <div className="form-group check-area">
            <label className="req">生年月日</label>
            <div>
                <div className="check" style={{marginBottom: "15px"}}>
                    <label>
                        <input type="radio" name="bi_accuracy_of_pet_birthday" value={BirthTypeConfirmed} onChange={onChange} checked={ppTestKit?.bi_accuracy_of_pet_birthday === BirthTypeConfirmed}/>
                        確定
                    </label>
                    <label>
                        <input type="radio" name="bi_accuracy_of_pet_birthday" value={BirthTypeEstimated} onChange={onChange} checked={ppTestKit?.bi_accuracy_of_pet_birthday === BirthTypeEstimated}/>
                        推定
                    </label>
                    <label>
                        <input type="radio" name="bi_accuracy_of_pet_birthday" value={BirthTypeUnknown} onChange={onChange} checked={ppTestKit?.bi_accuracy_of_pet_birthday === BirthTypeUnknown}/>
                        不明
                    </label>
                </div>
                <ErrorMessage message={errors["bi_accuracy_of_pet_birthday"]}/>

                <div className="inline">
                    <select name="birth_year" value={birthYear} onChange={onChange} disabled={!isBirthdayActive}>
                        <option value="">----</option>
                        {GetYearList(false).map((d, i) => {
                            return <option key={i} value={d}>{d}</option>
                        })}
                    </select>
                    <span>年</span>
                    <select name="birth_month" value={birthMonth} onChange={onChange} disabled={!isBirthdayActive}>
                        <option value="">----</option>
                        {GetMonthList().map((d, i) => {
                            return <option key={i} value={d}>{d}</option>
                        })}                            </select>
                    <span>月</span>
                    <select name="birth_date" value={birthDate} onChange={onChange} disabled={!isBirthdayActive}>
                        <option value="">----</option>
                        {GetDateList().map((d, i) => {
                            return <option key={i} value={d}>{d}</option>
                        })}
                    </select>
                    <span>日</span>

                </div>
                <ErrorMessage message={errors["bi_pet_birthday"]}/>
            </div>

        </div>


        <div className="form-group check-area">
            <label className="req">性別</label>
            <div>
                <div className="check">
                    <label>
                        <input type="radio" name="bi_pet_sex" value="1" onChange={onChange} checked={ppTestKit?.bi_pet_sex === "1"}/>
                        オス
                    </label>
                    <label>
                        <input type="radio" name="bi_pet_sex" value="2" onChange={onChange} checked={ppTestKit?.bi_pet_sex === "2"}/>
                        メス
                    </label>
                </div>
                <ErrorMessage message={errors["bi_pet_sex"]}/>
            </div>
        </div>

        <div className="form-group check-area">
            <label>アイコン</label>
            <div>
                <Button type="button" color={ButtonColor.Green} size={ButtonSize.Small} icon={ButtonIcon.Arrow} onClick={onFileSelect}>画像をアップロード</Button>
                <input type="file" ref={fileInputRef} onChange={onUpload}/>

                {filename && <div className="filename">
                    <span onClick={() => setIsModalImg(true)}>{filename}（クリックして確認）</span>
                    <div className="btn-delete" onClick={onDeleteImg}>&times; 画像を削除</div>
                </div>}

                <div className="hint">
                    * アップロード可能な画像形式：jpg、png、gif<br/>
                    * アップロードする画像は5MB以下でお願いいたします。<br/>
                    * 画像をアップロードしない場合、アイコンはデフォルトの画像になります。<br/>
                </div>

                <ErrorMessage message={errors["img"]}/>
            </div>
        </div>
        <div className="form-group">
            <label className="req">体重</label>
            <div>
                <div className="inline">
                    <select name="weight1" value={weight1} onChange={onChange}>
                        <option value="">--</option>
                        {GetWeight1().map((d, i) => {
                            return <option key={`w2-${i}`} value={d}>{d}</option>
                        })}
                    </select>

                    <span>.</span>

                    <select name="weight2" value={weight2} onChange={onChange}>
                        <option value="">--</option>
                        {GetWeight2().map((d, i) => {
                            return <option key={`w2-${i}`} value={d}>{d}</option>
                        })}
                    </select>
                    <span>kg</span>
                </div>
                <ErrorMessage message={errors["is_pet_weight"]}/>

            </div>
        </div>

        <div className="form-group">
            <label className="req">検体採取日</label>
            <div>
                <div className="inline">
                    <select name="sampling_year" value={samplingYear} onChange={onChange}>
                        <option value="">----</option>
                        {GetYearList(true).map((d, i) => {
                            return <option key={`y-${i}`} value={d}>{d}</option>
                        })}
                    </select>
                    <span>年</span>
                    <select name="sampling_month" value={samplingMonth} onChange={onChange}>
                        <option value="">----</option>
                        {GetMonthList().map((d, i) => {
                            return <option key={`m-${i}`} value={d}>{d}</option>
                        })}                            </select>
                    <span>月</span>
                    <select name="sampling_date" value={samplingDate} onChange={onChange}>
                        <option value="">----</option>
                        {GetDateList().map((d, i) => {
                            return <option key={`d-${i}`} value={d}>{d}</option>
                        })}
                    </select>
                    <span>日</span>

                </div>
                <ErrorMessage message={errors["sampling_date"]}/>
            </div>
        </div>

        {isModalImg && viewPath && <PetModal img={viewPath} onClose={() => setIsModalImg(false)}/>}

    </StyledPetForm>


}

const StyledPetForm = styled.div`

  input[type=file] {
    display: none;
  }

`;