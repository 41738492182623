import React from "react";
import styled from "styled-components";
import {MainColor} from "./Colors";

export const Links = ({children}:React.PropsWithChildren) => {
    return <StyledLinks>
        {children}
    </StyledLinks>
}

const StyledLinks = styled.div`

  text-align: right;

  a {
    border: none;
    border-radius: 32px;
    line-height: 32px;
    display: inline-block;
    margin: auto;
    background-color: ${MainColor};
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    padding: 0 16px;
    color: #fff;
    
    &:hover {
      text-decoration: underline;
    }
  }

`;