import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {Table} from "../common/Table";
import {GetPersonGender, SlashedYmd, Zerofill} from "../../utility/Utility";
import {H3} from "../common/Headline";
import {useLocation, useNavigate} from "react-router-dom";
import {Paginator} from "../common/Paginator";
import {Counter} from "../common/Counter";
import {adminPpUserApi} from "../../api/Api";
import {PpUserControllerIndexRequest, TypesPpUserData} from "../../generated";
import {SearchForm} from "./SearchForm";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "../common/Button";

export const PpTestKitIndex = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppUsers, setPpUsers] = useState<TypesPpUserData[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const navigate = useNavigate();
    const location = useLocation();

    const size = 100;

    // URL変更時
    useEffect(() => {
        if (location.search !== "") {
            getPpUsers();
        } else {
            setTotal(0);
            setCurrentPage(1);
        }
    }, [location.search]);

    const getPpUsers = () => {

        const params = new URLSearchParams(location.search);

        const email = params.get("email") ?? "";
        const lastName = params.get("last_name") ?? "";
        const firstName = params.get("first_name") ?? "";
        const lastNameKana = params.get("last_name_kana") ?? "";
        const firstNameKana = params.get("first_name_kana") ?? "";
        const page = params.get("page") ?? "1";

        const isAnyCondition = email !== "" || lastName !== "" || firstName !== "" || lastNameKana !== "" || firstNameKana !== "";

        if (location.search === "" || !isAnyCondition) {
            // 検索条件が未指定の場合検索しない
            setPpUsers([]);
            setTotal(0);
            setCurrentPage(1);
            return;
        }

        setShowSpinner(true);

        const req: PpUserControllerIndexRequest = {
            email: email,
            first_name: firstName,
            first_name_kana: firstNameKana,
            last_name: lastName,
            last_name_kana: lastNameKana,
            page: Number(page),
            size: size,
            only_personal_auth: true, // 本人確認済みのみ
        };

        adminPpUserApi.v1AdminPpUserPost(req)
            .then(({data}) => {
                setPpUsers(data.pp_users ?? []);
                setTotal(data.total ?? 0);
                setCurrentPage(Number(page));
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onPageChange = (page: number): void => {

        const params = new URLSearchParams(location.search);
        params.set("page", String(page));

        navigate(`/pp_test_kit?${params.toString()}`);
    };

    const onClear = () => {
        setPpUsers([]);
    }

    // 詳細へ遷移
    const onDetail = (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
        navigate(`/pp_test_kit/detail/${id}`);
    };

    return <Layout title={"検査申込管理"}>

        <SearchForm onClear={onClear}/>

        <div className="box">

            <H3 title="会員情報一覧" subtitle="list" counter={<Counter total={total} size={size} current={currentPage}/>}/>

            <Table>

                <thead>
                <tr>
                    <th>No.</th>
                    <th>会員ID</th>
                    <th>飼い主の名前</th>
                    <th>フリガナ</th>
                    <th>性別</th>
                    <th>生年月日</th>
                    <th>詳細</th>
                </tr>
                </thead>

                <tbody>
                {ppUsers.map((d, i) => {

                    return <tr key={`row-${i}`}>
                        <td>{Zerofill((currentPage - 1) * size + i + 1, 2)}</td>
                        <td>{d.email}</td>
                        <td>{d.user_family_name} {d.user_first_name}</td>
                        <td>{d.user_family_furigana} {d.user_first_furigana}</td>
                        <td>
                            {GetPersonGender(d.gender)}
                        </td>
                        <td>{SlashedYmd(d.birthday ?? "")}</td>
                        <td style={{textAlign: "center"}}><Button color={ButtonColor.Green} size={ButtonSize.Small} icon={ButtonIcon.Arrow} onClick={(e) => onDetail(e, d.id)}>詳細</Button></td>
                    </tr>

                })}
                </tbody>

            </Table>

            <Paginator onClick={onPageChange} pages={Math.ceil(total / size)} page={currentPage}/>

        </div>

    </Layout>

};
