import React from "react";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "./Button";
import {ErrorMessage} from "./ErrorMessage";
import styled from "styled-components";

interface Props {
    password: string;
    confirmationPassword: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    errors: { [key: string]: string };
    disabled: boolean;
    isEmailChange: boolean;
    toggleChangeable: () => void;
}

export const EditUserPasswordInput = ({password, confirmationPassword, onChange, errors, disabled, toggleChangeable, isEmailChange}: Props) => {

    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        toggleChangeable();
    }

    const isInputDisabled = !isEmailChange && disabled;

    return <>

        <StyledEditUserPasswordInput className="form-group">
            {isEmailChange && <label className="req">現在のパスワード</label>}
            {(!isEmailChange && !disabled) && <label className="req">新しいパスワード</label>}
            {(!isEmailChange && disabled) && <label>パスワード</label>}
            <div className="form-object">
                <div className="inline">
                    <input type="password" name="password" disabled={isInputDisabled} value={password} onChange={onChange} placeholder="パスワード"/>
                    <Button color={disabled ? ButtonColor.Green : ButtonColor.Gray} type="button" size={ButtonSize.Small} icon={ButtonIcon.Arrow} disabled={isEmailChange} onClick={onClick}>
                        {disabled ? "パスワードを変更する" : "変更をキャンセル"}
                    </Button>
                </div>
                <p className="hint">＊半角英数字。</p>
                <ErrorMessage message={errors["password"]}/>
            </div>
            <div className="hint-balloon">
                * パスワードは<span className="red">半角英数字8文字以上</span>（大文字英字A〜Z、小文字英字a〜z、数字0〜9をそれぞれ1文字以上使用してください）。<br/>
                * パスワードは必ず<span className="red">メモやスクリーンショット</span>などでお控えのうえ、ご自身で管理をお願いいたします。<br/>
                * 変更されない場合は、空のままで送信してください。<br/>
            </div>

        </StyledEditUserPasswordInput>

        {!isInputDisabled && <StyledEditUserPasswordInput className="form-group">
            {isEmailChange && <label className="req">現在のパスワード<br/>（再入力）</label>}
            {(!isEmailChange && !disabled) && <label className="req">新しいパスワード<br/>（再入力）</label>}
            {(!isEmailChange && disabled) && <label>パスワード（再入力）</label>}
            <div>
                <input type="password" name="confirmation_password" disabled={isInputDisabled} value={confirmationPassword} onChange={onChange} placeholder="パスワード（再入力）"/>
                <p className="hint">＊半角英数字。</p>
                <ErrorMessage message={errors["confirmation_password"]}/>
            </div>
        </StyledEditUserPasswordInput>}
    </>
};

const StyledEditUserPasswordInput = styled.div`
  align-items: center !important;

  label {
    padding: 0 !important;

    &.req:after {
      top: 50% !important;
      transform: translateY(-50%) !important;
    }
  }

  .form-object {
    max-width: 490px;
    width: 490px !important;
  }

  .hint-balloon {
    left: 20px;
    width: 440px;
    top: 0;
  }

`;

