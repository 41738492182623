import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import React, {useContext, useEffect, useRef, useState} from "react";
import {H2} from "../common/Headline";
import {CheckSampleId} from "../../utility/Utility";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../contexts/AppContext";
import {PlateImage} from "./PlateImage";

interface Props {
    sessionKey: string;
    isAllFilled: boolean;
    onSearch: (sampleIds: string[] | null) => void;
    onClear: () => void;
    error: string;
    activeRecordNum: number;
}

export const NewForm = ({sessionKey, isAllFilled, onSearch, onClear, error, activeRecordNum}: Props) => {

    const {setDangerMessage} = useContext(AppContext);
    const [sampleId, setSampleId] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const ref = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        setErrorMessage(error);
    }, [error]);

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);

    const getSampleIds = (): string[] => {
        const old = sessionStorage.getItem(sessionKey);
        return JSON.parse(old ?? "[]");
    };

    useEffect(() => {

        if (CheckSampleId(sampleId)) {
            setErrorMessage("");

            const sampleIds = getSampleIds();

            if (isAllFilled) {
                setErrorMessage("それ以上受付できません");
                return;
            }

            if (sampleIds.includes(sampleId)) {
                setErrorMessage("すでに一覧に含まれています");
                return;
            }

            setSampleId("");

            // 検索実行
            onSearch([sampleId]);

        } else {
            if (!error) {
                setErrorMessage("IDを入力してください");
            }
            return;
        }

    }, [sampleId]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.currentTarget.name) {
            case "sample_id":
                setSampleId(e.currentTarget.value);
                break;
        }
    };

    // 試験情報を登録
    const onSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        const sampleIds = getSampleIds();

        if (sampleIds.length === 0) {
            setErrorMessage("先に検体IDを入力して一覧を表示してから実行してください。");
            return;
        }

        if (activeRecordNum === 0) {
            setDangerMessage("更新対象外の検体しかありません。検体を確認してもう一度受付作業を行なってください。");
            return;
        }

        navigate(`/reception/register`);
    };

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.currentTarget.select();
    };

    const onSubmitDummy = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (!window.confirm("一覧をクリアしてやり直します。よろしいですか？")) {
            return;
        }

        setSampleId("");
        onClear();
    }

    const onPaste = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();

        if (typeof navigator.clipboard.readText === 'undefined') {
            alert("お使いのブラウザは、navigator.clipboard.readText() に対応しておりません。Google Chromeなどの対応ブラウザで実行してください。");
            return;
        }

        navigator.clipboard.readText()
            .then((text) => {

                const lines = text.split(/\r\n|\r|\n/);

                let hasError = false;
                const next: string[] = [];

                lines.forEach((line) => {
                    if (line.trim() === "") {
                        // 空行は許容する
                        return;
                    }

                    if (!CheckSampleId(line)) {
                        // 不正な書式はエラー
                        hasError = true;
                        return;
                    }

                    next.push(line);
                });

                if(activeRecordNum + next.length > 94) {
                    setDangerMessage(`これ以上受付できません。${94 - activeRecordNum}個以下を貼り付けてください。`);
                    return;
                }

                if (hasError) {
                    setDangerMessage(`クリップボードの内容に正しくない検体IDが含まれています。`);
                } else {
                     onSearch(next);
                }
            });
    }

    return <StyledSearchForm noValidate={true} onSubmit={onSubmitDummy}>
        <div className="box">
            <H2 title="検体ID読み取り" subtitle="input"/>
            <div className="search-form">

                <div className="flex2">

                    <div className="form-group">
                        <label>検体ID</label>
                        <div>
                            <input type="text" name="sample_id" placeholder={"例：PC23-1000-NGS-1000"} value={sampleId} onChange={onChange} ref={ref} maxLength={18} onFocus={onFocus}/>
                        </div>
                    </div>

                    <div className="form-group paste">
                        <label>貼り付け</label>
                        <div>
                            <span className="paste-area" title="クリックすると貼り付けます" onClick={onPaste}>貼り付け欄</span>
                            {errorMessage && <span className="search-error">{errorMessage}</span>}
                        </div>
                    </div>

                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                    <Button color={ButtonColor.Orange} type="button" icon={ButtonIcon.Arrow} onClick={onSubmit}>試験情報を登録</Button>
                </div>
            </div>
        </div>

        <PlateImage currentOffset={activeRecordNum}/>

    </StyledSearchForm>
};

export const StyledSearchForm = styled(StyledForm)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .box {
        flex: 1;
        width: 100%;
        margin: 0 24px 0 0 !important;
        padding-bottom: 10px !important;

        .search-form {

            .flex2 {
                display: flex;
            }

            .form-group {
                position: relative;

                label {
                    width: 100px;
                    margin-right: 0;
                }


                &.paste {

                    > div {

                        .paste-area {
                            display: block;
                            color: #80CBC4;
                            background-color: #F4F9F8;
                            border: 1px dotted #80CBC4;
                            padding: 0;
                            font-size: 14px;
                            cursor: pointer;
                            line-height: 44px;
                            width: 132px;
                            text-align: center;
                        }
                    }

                }

                .search-error {
                    color: #fff;
                    background-color: #D93E4C;
                    font-size: 14px;
                    line-height: 47px;
                    display: block;
                    position: absolute;
                    padding: 0 10px;
                    border-radius: 6px;
                    top: 0;
                    left: 150px;
                    width: auto !important;
                    white-space: pre;
                    z-index: 3;

                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        left: -18px;
                        top: 50%;
                        transform: translateY(-50%);
                        border: 10px solid transparent;
                        border-right: 10px solid #D93E4C;
                    }
                }

            }

        }
    }
`;