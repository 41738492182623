import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import React, {useContext, useEffect, useRef, useState} from "react";
import {H2, H3} from "../common/Headline";
import {CheckSampleId} from "../../utility/Utility";
import {AppContext} from "../../contexts/AppContext";
import {PlateImage} from "./PlateImage";
import icoRightBlue from "../../images/right-lightblue.svg";
import {adminPpTestKitApi} from "../../api/Api";

interface Props {
    sessionKey: string;
    isAllFilled: boolean;
    onSearch: (sampleIds: string[] | null) => void;
    onClear: () => void;
    error: string;
    activeRecordNum: number; // この有効数には以前登録分も含まれる
    realActiveRecordNum: number; // こちらには追加分の検体での有効数が入る
    onFlowcellIDSuccess: (sampleIds: string[], flowcellId: string, wellplateId: string) => void;
    onAddConfirm: () => void;
}

export const AddForm = ({sessionKey, isAllFilled, onSearch, onClear, error, activeRecordNum, onFlowcellIDSuccess, realActiveRecordNum, onAddConfirm}: Props) => {

    const {setDangerMessage, setShowSpinner} = useContext(AppContext);
    const [sampleId, setSampleId] = useState<string>("");
    const [flowcellId, setFlowcellId] = useState<string>(sessionStorage.getItem("add_flowcell_id") ?? "");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [flowCellErrorMessage, setFlowCellErrorMessage] = useState<string>("");
    const ref = useRef<HTMLInputElement>(null);
    const [isFlowCellValid, setIsFlowCellValid] = useState<boolean>(false);

    useEffect(() => {
        setErrorMessage(error);
    }, [error]);

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);

    useEffect(() => {

        if (flowcellId.length === 8) {
            // FlowCell IDを検索する
            searchFlowCell();
            setFlowCellErrorMessage("");
        } else {
            setIsFlowCellValid(false);
            setFlowCellErrorMessage("IDを入力してください");
        }

    }, [flowcellId]);

    useEffect(() => {
        setErrorMessage(error);
    }, [error]);

    const searchFlowCell = () => {
        setShowSpinner(true);

        adminPpTestKitApi
            .v1AdminPpTestKitGetByFlowcellIdFlowcellIdGet(flowcellId)
            .then(({data}) => {
                setIsFlowCellValid(true);
                sessionStorage.setItem("add_flowcell_id", flowcellId);
                // コールバック実行
                onFlowcellIDSuccess(data.sample_ids, data.flowcell_id, data.wellplate_id);
            })
            .catch((err) => {
                if (err.response.data && err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    setFlowcellId("");
                    setIsFlowCellValid(false);
                    sessionStorage.removeItem("add_flowcell_id");
                    onClear();
                } else {
                    setDangerMessage("通信時にエラーが発生しました。");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });

    };

    const getSampleIds = (): string[] => {
        const old = sessionStorage.getItem(sessionKey);
        return JSON.parse(old ?? "[]");
    };

    useEffect(() => {

        if (CheckSampleId(sampleId)) {
            setErrorMessage("");

            const sampleIds = getSampleIds();

            if (isAllFilled) {
                setErrorMessage("それ以上受付できません");
                return;
            }

            if (sampleIds.includes(sampleId)) {
                setErrorMessage("すでに一覧に含まれています");
                return;
            }

            setSampleId("");

            // 検索実行
            onSearch([sampleId]);

        } else {
            if (!error) {
                if (flowcellId !== "") {
                    setErrorMessage("IDを入力してください");
                }
            }
            return;
        }

    }, [sampleId]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.currentTarget.name) {
            case "flowcell_id":
                setFlowcellId(e.currentTarget.value);
                break;
            case "sample_id":
                setSampleId(e.currentTarget.value);
                break;
        }
    };

    // 試験情報を登録
    const onSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        const sampleIds = getSampleIds();

        if (sampleIds.length === 0) {
            setErrorMessage("先に検体IDを入力して一覧を表示してから実行してください。");
            return;
        }

        if (realActiveRecordNum === 0) {
            setDangerMessage("追加受付可能な検体がありません。検体を確認してもう一度受付作業を行なってください。");
            return;
        }

        // 追加確認モーダル表示
        onAddConfirm();
    };

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.currentTarget.select();
    };

    const onSubmitDummy = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {

        e.preventDefault();

        setFlowcellId("");
        setSampleId("");
        setIsFlowCellValid(false);
        sessionStorage.removeItem("add_flowcell_id");

        onClear();
    }

    const onPaste = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();

        if (typeof navigator.clipboard.readText === 'undefined') {
            alert("お使いのブラウザは、navigator.clipboard.readText() に対応しておりません。Google Chromeなどの対応ブラウザで実行してください。");
            return;
        }

        navigator.clipboard.readText()
            .then((text) => {

                const lines = text.split(/\r\n|\r|\n/);

                let hasError = false;
                const next: string[] = [];

                lines.forEach((line) => {
                    if (line.trim() === "") {
                        // 空行は許容する
                        return;
                    }

                    if (!CheckSampleId(line)) {
                        // 不正な書式はエラー
                        hasError = true;
                        return;
                    }

                    next.push(line);
                });

                if(activeRecordNum + next.length > 94) {
                    setDangerMessage(`これ以上受付できません。${94 - activeRecordNum}個以下を貼り付けてください。`);
                    return;
                }

                if (hasError) {
                    setDangerMessage(`クリップボードの内容に正しくない検体IDが含まれています。`);
                } else {
                    onSearch(next);
                }
            });
    }

    return <StyledSearchForm noValidate={true} onSubmit={onSubmitDummy}>
        <div className="box">
            <H2 title="検体ID読み取り" subtitle="input"/>
            <div className="search-form">
                <div className="flex">
                    <div>
                        <H3 title="【1】Flow Cell IDを入力してください"/>
                        <div>
                            <input type="text" name="flowcell_id" placeholder={"例：ABC12345"} value={flowcellId} onChange={onChange} ref={ref} maxLength={8} onFocus={onFocus}/>
                            {flowCellErrorMessage && <span className="search-error flowcell">{flowCellErrorMessage}</span>}
                        </div>
                    </div>
                    <div>
                        <H3 title="【2】検体IDを入力してください"/>

                        <div className="form-group paste">
                            <label>貼り付け</label>
                            <div>
                                <span className="paste-area" title="クリックすると貼り付けます" onClick={onPaste}>貼り付け欄</span>
                            </div>
                        </div>

                        <div>
                            <input type="text" name="sample_id" placeholder={"例：PC23-1000-NGS-1000"} value={sampleId} onChange={onChange} maxLength={18} onFocus={onFocus} disabled={!isFlowCellValid}/>
                            {errorMessage && <span className="search-error">{errorMessage}</span>}
                        </div>
                    </div>
                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                    <Button color={ButtonColor.Orange} type="button" icon={ButtonIcon.Arrow} onClick={onSubmit}>試験情報を登録</Button>
                </div>
            </div>
        </div>

        <PlateImage currentOffset={activeRecordNum}/>

    </StyledSearchForm>
};

export const StyledSearchForm = styled(StyledForm)`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;

    .box {
        flex: 1;
        width: 100%;
        margin: 0 24px 0 0 !important;
        padding-bottom: 10px !important;

        .search-form {

            .flex {
                display: flex;
                justify-content: space-between;

                > div {
                    width: 44%;
                    position: relative;

                    .form-group.paste {
                        padding: 0;
                        margin-bottom: 20px;

                        label {
                            width: 80px;
                        }

                        > div {

                            .paste-area {
                                color: #80CBC4;
                                background-color: #F4F9F8;
                                border: 1px dotted #80CBC4;
                                padding: 0;
                                font-size: 14px;
                                cursor: pointer;
                                display: block;
                                line-height: 44px;
                                width: 132px;
                                text-align: center;
                            }
                        }

                    }
                }

                background-repeat: no-repeat;
                background-image: url(${icoRightBlue});
                background-position: left 50% top 60px;

                .search-error {
                    color: #fff;
                    background-color: #D93E4C;
                    font-size: 14px;
                    line-height: 47px;
                    display: inline-block;
                    position: absolute;
                    padding: 0 10px;
                    border-radius: 6px;
                    top: 55px;
                    left: 320px;
                    max-width: 330px;
                    width: auto !important;
                    white-space: nowrap;
                    z-index: 3;

                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        left: -18px;
                        top: 50%;
                        transform: translateY(-50%);
                        border: 10px solid transparent;
                        border-right: 10px solid #D93E4C;
                    }
                }

            }

        }
    }
`;