import {GetAllergyDiseaseStr, GetBrainDiseaseStr, GetDigestiveDiseaseStr, GetEyeDiseaseStr, GetHeartDiseaseStr, GetHormoneDiseaseStr, GetHypertension, GetKidneyDiseaseStr, GetLactatedChildren, GetLactationExperience, GetLiverDiseaseStr, GetOrthopedicDiseaseStr, GetPregnancyWeeks, GetRespiratoryDiseaseStr, GetSkinDiseaseStr, GetSkinTroubleStr, GetSymptomsTimings, GetTotalLactationDuration, GetUrologyDiseaseStr, GetYesNoStr, GetYesNoUnknownStr} from "../../../utility/Utility";
import React from "react";
import {TypesPpTestKitData} from "../../../generated";
import styled from "styled-components";

interface Props {
    ppTestKit: TypesPpTestKitData;
}

export const Condition = ({ppTestKit}: Props) => {
    return <StyledCondition className="form-wrapper">
        <div className="form-group confirm">
            <label className="req">病歴<small>（直近3ヶ月以内）</small></label>
            <div>
                {GetYesNoStr(ppTestKit.is_disease)}

                <div className="form-wrapper mt15">

                    <div className="form-group center">
                        <label>皮膚疾患</label>
                        <div>{GetSkinDiseaseStr(ppTestKit)}</div>
                    </div>

                    <div className="form-group center">
                        <label>心臓疾患</label>
                        <div>{GetHeartDiseaseStr(ppTestKit)}</div>
                    </div>

                    <div className="form-group center">
                        <label>腎臓疾患</label>
                        <div>{GetKidneyDiseaseStr(ppTestKit)}</div>
                    </div>

                    <div className="form-group center">
                        <label>泌尿器疾患</label>
                        <div>{GetUrologyDiseaseStr(ppTestKit)}</div>
                    </div>

                    <div className="form-group center">
                        <label>整形疾患</label>
                        <div>{GetOrthopedicDiseaseStr(ppTestKit)}</div>
                    </div>

                    <div className="form-group center">
                        <label>脳疾患/脊髄疾患</label>
                        <div>{GetBrainDiseaseStr(ppTestKit)}</div>
                    </div>

                    <div className="form-group center">
                        <label>呼吸器疾患</label>
                        <div>{GetRespiratoryDiseaseStr(ppTestKit)}</div>
                    </div>

                    <div className="form-group center">
                        <label>アレルギー</label>
                        <div>{GetAllergyDiseaseStr(ppTestKit)}</div>
                    </div>

                    <div className="form-group center">
                        <label>消化器疾患</label>
                        <div>{GetDigestiveDiseaseStr(ppTestKit)}</div>
                    </div>

                    <div className="form-group center">
                        <label>肝・胆道疾患</label>
                        <div>{GetLiverDiseaseStr(ppTestKit)}</div>
                    </div>

                    <div className="form-group center">
                        <label>高血圧</label>
                        <div>{GetHypertension()[ppTestKit.is_hypertension ?? ""]}</div>
                    </div>

                    <div className="form-group center">
                        <label>ホルモン・代謝異常</label>
                        <div>{GetHormoneDiseaseStr(ppTestKit)}</div>
                    </div>

                    <div className="form-group center">
                        <label>眼科疾患</label>
                        <div>{GetEyeDiseaseStr(ppTestKit)}</div>
                    </div>

                    <div className="form-group center">
                        <label>腫瘍</label>
                        <div>{ppTestKit.is_tumor_comment !== "" ? ppTestKit.is_tumor_comment : ""}</div>
                    </div>

                    <div className="form-group center">
                        <label>その他の疾患</label>
                        <div>{ppTestKit.is_other_comment !== "" ? ppTestKit.is_other_comment : ""}</div>
                    </div>

                </div>

            </div>
        </div>

        <div className="form-group center">
            <label className="req">嘔吐<small>（直近3ヶ月以内）</small></label>
            <div>

                <div className="column-2">
                    <div className="column">
                        {GetYesNoStr(ppTestKit.is_vomit)}
                    </div>
                    <div className="column">
                        <div className="inline">
                            <label>時期</label>
                            {GetSymptomsTimings()[ppTestKit.is_vomit_timing ?? ""]}
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className="form-group center">
            <label className="req">下痢<small>（直近3ヶ月以内）</small></label>
            <div>

                <div className="column-2">
                    <div className="column">
                        {GetYesNoStr(ppTestKit.is_diarrhea)}
                    </div>
                    <div className="column">
                        <div className="inline">
                            <label>時期</label>
                            {GetSymptomsTimings()[ppTestKit.is_diarrhea_timing ?? ""]}
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className="form-group center">
            <label className="req">皮膚トラブル<small>（直近3ヶ月以内）</small></label>
            <div className="skin-trouble-area">
                <div className="column-2">
                    <div className="column">
                        {GetYesNoStr(ppTestKit.is_skin_trouble)}
                    </div>
                    <div className="column">
                        <div className="inline">
                            <label>時期</label>
                            <div>{GetSymptomsTimings()[ppTestKit.is_skin_trouble_timing ?? ""]}</div>
                        </div>
                        <div className="inline">
                            <label>症状</label>
                            <div>{GetSkinTroubleStr(ppTestKit)}</div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

        <div className="form-group center">
            <label className="req">目やにまたは涙やけ<small>（直近3ヶ月以内）</small></label>
            <div>{GetYesNoStr(ppTestKit.is_eyemucus_tearsstain)}</div>
        </div>

        <div className="form-group center">
            <label className="req">抗生物質の投与<small>（直近3ヶ月以内）</small></label>
            <div>{GetYesNoStr(ppTestKit.is_medicine_antibiotics)}</div>
        </div>

        <div className="form-group center">
            <label className="req">抗生物質以外<br/>薬の投与<small>（直近3ヶ月以内）</small></label>
            <div>{GetYesNoStr(ppTestKit.is_medicine_except_antibiotics)}</div>
        </div>

        <div className="form-group center">
            <label className="req">避妊・去勢の手術経験</label>
            <div>{GetYesNoUnknownStr(ppTestKit.is_spayed_neutered)}</div>
        </div>

        <div className="form-group center">
            <label className="req">過去の妊娠の有無</label>
            <div className="past-pregnancy-area">
                <div className="row">
                    <div>
                        {GetYesNoUnknownStr(ppTestKit.is_was_pregnant_past)}
                    </div>
                    <div className="inline">
                        <label>泌乳経験</label>
                        <div>{GetLactationExperience()[ppTestKit.is_lactation_experience ?? ""]}</div>
                        <label>泌乳した<br/>子供の合計</label>
                        <div>{GetLactatedChildren()[ppTestKit.is_lactated_children ?? ""]}</div>
                        <label>泌乳した<br/>期間の合計</label>
                        <div>{GetTotalLactationDuration()[ppTestKit.is_total_lactation_duration ?? ""]}</div>
                    </div>
                </div>
            </div>
        </div>

        <div className="form-group center">
            <label className="req">現在の妊娠・<br/>妊娠の可能性</label>
            <div>
                <div className="column-2">
                    <div className="column">
                        {GetYesNoUnknownStr(ppTestKit.is_pregnancy)}
                    </div>
                    <div className="column">
                        <div className="inline">
                            <label>妊娠期間</label>
                            {GetPregnancyWeeks()[ppTestKit.is_pregnancy_weeks ?? ""]}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </StyledCondition>
};


const StyledCondition = styled.div`

  // 皮膚トラブルエリア
  .skin-trouble-area {

    .column-2 {

      .column {
        &:last-child {
          flex: 1;

        }
      }

      .inline {
        &:first-child {
          padding: 0 0 15px 0;
          border-bottom: 1px dotted #ddd;
        }

        &:last-child {
          padding: 15px 0 0 0;
        }
      }
    }
  }

  // 過去の妊娠経験
  .past-pregnancy-area {
    .row > div {
      &:first-child {
        padding: 0 0 15px 0;
        border-bottom: 1px dotted #ddd;
      }

      &:last-child {
        padding: 15px 0 0 0;
      }
    }

    .inline {
      label {
        font-size: 14px;
        width: 10%;
      }

      div {
        width: 15%;
      }
    }

  }
`;