import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import React, {useEffect, useRef} from "react";
import {H2} from "../common/Headline";
import {useLocation, useNavigate} from "react-router-dom";
import {Types} from "./RollbackIndex";
import {CheckSampleId} from "../../utility/Utility";

interface Props {
    type: Types;
    onClear: () => void;
    btnEnabled: boolean;
    onReset: (flowcellId: string, sampleId: string) => void;
    sampleId: string;
    flowcellId: string;
    setSampleId: (s: string) => void;
    setFlowcellId: (s: string) => void;
}

export const SearchForm = ({type, onClear, btnEnabled, onReset, sampleId, flowcellId, setSampleId, setFlowcellId}: Props) => {

    const ref = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        const flowcellId = params.get("flowcell_id") ?? "";
        const sampleId = params.get("sample_id") ?? "";

        if (type === Types.FlowcellId && flowcellId !== "") {
            setFlowcellId(flowcellId);
        }

        if (type === Types.SampleId && sampleId !== "") {
            setSampleId(sampleId);
        }

    }, [location.search]);

    useEffect(() => {
        if (flowcellId.length === 8) {
            navigate(`/rollback/${type}?flowcell_id=${flowcellId}`);
        }
        if (CheckSampleId(sampleId)) {
            navigate(`/rollback/${type}?sample_id=${sampleId}`);
        }
    }, [sampleId, flowcellId]);


    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.currentTarget.name) {
            case "flowcell_id":
                setFlowcellId(e.currentTarget.value);
                break;
            case "sample_id":
                setSampleId(e.currentTarget.value);
                break;
        }
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
    };

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        setFlowcellId("");
        setSampleId("");
        onClear();
        navigate(`/rollback/${type}`);

        if (ref.current) {
            ref.current.focus();
        }
    }

    const onFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.currentTarget.select();
    };

    const onClickReset = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onReset(flowcellId, sampleId);
    };

    return <StyledSearchForm noValidate={true} onSubmit={onSubmit}>

        <div className="box">

            <H2 title="検体ID読み取り" subtitle="input"/>

            <div className="search-form">

                <div className="row">

                    <div className="col">

                        {type === String(Types.SampleId) && <div className="form-group">
                            <label>
                                検体ID
                            </label>
                            <div>
                                <input type="text" name="sample_id" placeholder={"例：PC23-1000-NGS-1000"} ref={ref} value={sampleId} onChange={onChange} maxLength={18} onFocus={onFocus}/>
                            </div>

                        </div>}

                        {type === String(Types.FlowcellId) && <div className="form-group">
                            <label>
                                Flow Cell ID
                            </label>
                            <div>
                                <input type="text" name="flowcell_id" placeholder={"例：ABC12345"} value={flowcellId} ref={ref} onChange={onChange} maxLength={8} onFocus={onFocus}/>
                            </div>
                        </div>}

                    </div>

                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                    <Button color={btnEnabled ? ButtonColor.Orange : ButtonColor.Gray} type="submit" icon={ButtonIcon.Arrow} disabled={!btnEnabled} onClick={onClickReset}>リセット</Button>
                </div>

            </div>

        </div>

    </StyledSearchForm>
};

export const StyledSearchForm = styled(StyledForm)`
  .search-form {
    .form-group {
      label {
        width: auto;
      }
    }
  }
`;