import {TypesPpTestKitData} from "../../../generated";
import {CreateJaYmd, GetPersonGender, GetPrefectureName} from "../../../utility/Utility";
import React from "react";

interface Props {
    ppTestKit: TypesPpTestKitData;
}

export const Owner = ({ppTestKit}: Props) => {

    if(!ppTestKit.pp_user) {
        return  null;
    }

    return <div className="form-wrapper">

        <div className="form-group center">
            <label>お名前</label>
            <div>
                {ppTestKit.pp_user.user_family_name} {ppTestKit.pp_user.user_first_name}
            </div>
        </div>

        <div className="form-group center">
            <label>お名前（フリガナ）</label>
            <div>
                {ppTestKit.pp_user.user_family_furigana} {ppTestKit.pp_user.user_first_furigana}
            </div>
        </div>

        <div className="form-group center">
            <label>生年月日</label>
            <div>
                {CreateJaYmd(ppTestKit.pp_user.birthday ?? "")}
            </div>
        </div>

        <div className="form-group center">
            <label>性別</label>
            <div>
                {GetPersonGender(ppTestKit.pp_user.gender ?? "")}
            </div>
        </div>

        <div className="form-group center">
            <label>住所</label>
            <div>
                〒{ppTestKit.pp_user.postal_code}<br/>
                {GetPrefectureName(ppTestKit.pp_user.pref_code)}{ppTestKit.pp_user.city}{ppTestKit.pp_user.street}<br/>
                {ppTestKit.pp_user.building !== "" ? ppTestKit.pp_user.building : ""}
            </div>
        </div>

        <div className="form-group center">
            <label>電話番号</label>
            <div>
                {ppTestKit.pp_user.tel_no}
            </div>
        </div>

        <div className="form-group center">
            <label>メールアドレス</label>
            <div>
                {ppTestKit.pp_user.email}
            </div>
        </div>

    </div>

};