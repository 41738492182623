import {adminPpMstUserMedicalApi} from "../api/Api";
import {useContext, useEffect, useState} from "react";
import {PpMstUserMedicalModelGetListResponse} from "../generated";
import {AppContext} from "../contexts/AppContext";

// 病院リスト取得
export const useMedicalInsts = () => {

    const {setDangerMessage, setShowSpinner} = useContext(AppContext);
    const [medicalInsts, setMedicalInsts] = useState<PpMstUserMedicalModelGetListResponse[]>([]);

    useEffect(() => {

        setShowSpinner(true);

        adminPpMstUserMedicalApi
            .v1AdminPpMstUserMedicalListGet()
            .then(({data}) => {
                setMedicalInsts(data.data);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }, []);

    return medicalInsts;
}