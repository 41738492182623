import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import {Table} from "../common/Table";
import {ErrorType, SearchForm} from "./SearchForm";
import {adminMetadataApi} from "../../api/Api";
import {PpTestResultControllerMetadataDownloadRequest, PpTestResultControllerMetadataIndexRequest, PpteststatusStatus, TypesPpTestResultData} from "../../generated";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {CheckSampleId, GetTestStatus, SlashedDt, SlashedYmd, Zerofill} from "../../utility/Utility";
import {CounterSimple} from "../common/CounterSimple";
import {Modal, ModalColor} from "../sample_id/Modal";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {AxiosRequestConfig} from "axios";

enum ModalType {
    Confirm = 1,
    Complete = 2,
}

export const MetadataIndex = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [ppTestResults, setPpTestResults] = useState<TypesPpTestResultData[]>([]);
    const [modalType, setModalType] = useState<number | null>(null);
    const [num1, setNum1] = useState<number>(0); // ダウンロード対象件数
    const [num2, setNum2] = useState<number>(0); // メタデータなし数
    const location = useLocation();
    const navigate = useNavigate();
    const [sampleIds, setSampleIds] = useState<string[]>([]); // 検索結果の検体IDを保持
    const [flowcellId, setFlowcellId] = useState<string>("");
    const [sampleId, setSampleId] = useState<string>("");
    const [errorType, setErrorType] = useState<ErrorType>(ErrorType.None);

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        const flowcellId = params.get("flowcell_id") ?? "";
        const sampleId = params.get("sample_id") ?? "";

        setErrorType(ErrorType.None);

        if (flowcellId.length === 8) {

            if(ppTestResults.length >= 1 && flowcellId !== "")  {
                // この時、以前取得したデータがある場合は、エラー
                setErrorType(ErrorType.Duplicate);
                return;
            }

            setFlowcellId(flowcellId);
            setSampleId("");
            onSearch(flowcellId, "");
            return;
        }

        if (CheckSampleId(sampleId)) {

            if(ppTestResults.length >= 1 && sampleId !== "")  {
                // この時、以前取得したデータがある場合は、エラー
                setErrorType(ErrorType.Duplicate);
                return;
            }

            setFlowcellId("");
            setSampleId(sampleId);
            onSearch("", sampleId);
            return;
        }

    }, [location.search]);

    const onSearch = (flowcellId: string, sampleId: string) => {

        const req: PpTestResultControllerMetadataIndexRequest = {
            flowcell_id: flowcellId,
            sample_id: sampleId,
        };

        setShowSpinner(true);
        setErrorType(ErrorType.None);

        adminMetadataApi.v1AdminMetadataIndexPost(req)
            .then(({data}) => {
                setPpTestResults(data.pp_test_results);

                // データがなかった場合クリア
                if (data.pp_test_results.length === 0) {
                    setErrorType(ErrorType.NotFound);
                    return;
                }

                const sampleIds: string[] = [];

                const num1 = data.pp_test_results.length;
                let num2 = 0;
                data.pp_test_results.forEach((d) => {
                    if (d.status === PpteststatusStatus.Status22) {
                        // 22: 強制検査中はメタデータなしと判断する
                        // see A-05-016: https://docs.google.com/spreadsheets/d/1kefYWJqCq_a3wOx4BU0Y0-xwLnkUpPg5VGXhWsVHNXk/edit#gid=1030842939
                        num2++;
                    } else {
                        // ダウンロード対象を保持
                        sampleIds.push(d.sample_id);
                    }
                });

                setNum1(num1 - num2); // 全件数から強制検査中（メタデータなし）を引く
                setNum2(num2);
                setSampleIds(sampleIds);

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setModalType(ModalType.Confirm);
    }

    const onClear = () => {
        setPpTestResults([]);
        setSampleId("");
        setFlowcellId("");
        navigate("/metadata");
    };

    const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setModalType(null);
    };

    // ダウンロード処理実行
    const onComplete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowSpinner(true);

        const req: PpTestResultControllerMetadataDownloadRequest = {
            sample_ids: sampleIds,
        };

        const options: AxiosRequestConfig = {
            responseType: "blob",
        };

        adminMetadataApi.v1AdminMetadataDownloadPost(req, options)
            .then((res) => {

                const data = res.data as Blob;
                const blob = new Blob([data], {type: "text/tsv"});
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                const dt = new Date();
                const now = `${dt.getFullYear()}${Zerofill(dt.getMonth() + 1, 2)}${Zerofill(dt.getDate(), 2)}${Zerofill(dt.getHours(), 2)}${Zerofill(dt.getMinutes(), 2)}`;
                a.download = `${now}_${ppTestResults[0].flowcell_id}.tsv`;
                a.click();

                // 再読み込み
                onSearch(flowcellId, sampleId);

                // モーダルを変更
                setModalType(ModalType.Complete);

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            })

    };

    const statuses = GetTestStatus();

    return <Layout title="メタデータ取得">

        <SearchForm onClear={onClear} onConfirm={onConfirm} errorType={errorType} isDownloadable={ppTestResults.length >= 1}/>

        <div className="box">
            <H3 title="検体情報一覧" subtitle="list" counter={<CounterSimple total={ppTestResults.length}/>}/>

            <Table>
                <thead>
                <tr>
                    <th>No.</th>
                    <th>検体ID</th>
                    <th>Flow Cell ID</th>
                    <th>位置</th>
                    <th>Labバーコード</th>
                    <th>ステータス</th>
                    <th>メタデータ登録日</th>
                    <th>前回DL日時</th>
                    <th>前回DL実施者</th>
                    <th>状況</th>
                </tr>
                </thead>

                <tbody>

                {ppTestResults.map((d, i) => {

                    const statusClass = d.status === PpteststatusStatus.Status22 ? "red" : "";

                    return <tr key={`pp_test_result-${i}`}>
                        <td>{Zerofill(i + 1, 2)}</td>
                        <td>{d.sample_id}</td>
                        <td>{d.flowcell_id}</td>
                        <td>{d.position}</td>
                        <td>{d.lab_barcode_id}</td>
                        <td className={statusClass}>{statuses.get(d.status)}</td>
                        <td>{d.is_upd_ymd ? SlashedYmd(d.is_upd_ymd) : ""}</td>
                        <td>{d.metadata_out_dt ? SlashedDt(d.metadata_out_dt) : ""}</td>
                        <td>{d.metadata_out_user_name}</td>
                        <td>{d.metadata_out ? "出力済み" : " "}</td>
                    </tr>

                })}

                </tbody>

            </Table>

        </div>

        {modalType === ModalType.Confirm && <Modal color={ModalColor.Success}>
            <h4>メタデータの取得</h4>
            <h5>以下の内容でよろしいですか？</h5>
            <ul>
                <li><label className="w200">ダウンロード対象件数</label><span className="num w60">{num1}</span><span className="postfix">件</span></li>
                <li><label className="w200">メタデータなし件数</label><span className="num w60">{num2}</span><span className="postfix">件</span></li>
            </ul>
            <div className="btn-area">
                <Button type="button" color={ButtonColor.Gray} icon={ButtonIcon.Arrow} onClick={onModalClose}>いいえ</Button>
                <Button type="button" color={ButtonColor.Orange} icon={ButtonIcon.Arrow} onClick={onComplete}>はい</Button>
            </div>

        </Modal>}

        {modalType === ModalType.Complete && <Modal color={ModalColor.Success}>
            <h4>メタデータの取得</h4>
            <h5>ダウンロードが完了しました。</h5>
            <ul>
                <li><label className="w200">ダウンロード対象件数</label><span className="num w60">{num1}</span><span className="postfix">件</span></li>
                <li><label className="w200">メタデータなし件数</label><span className="num w60">{num2}</span><span className="postfix">件</span></li>
            </ul>
            <div className="btn-area">
                <Button type="button" color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={onModalClose}>メタデータ取得に戻る</Button>
            </div>

        </Modal>}

    </Layout>

}