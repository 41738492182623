import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {H3} from "../common/Headline";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {AxiosResponse} from "axios";
import {ErrorMessage} from "../common/ErrorMessage";
import {PpMstPartnerInstControllerCreateRequest, PpMstPartnerSupplierTypeModelGetListResponse, TypesPpMstPartnerInstData} from "../../generated";
import {adminPpMstPartnerInstApi, adminPpMstPartnerSupplierTypeApi} from "../../api/Api";
import {AddressSet} from "../common/AddressSet";

export const PpMstPartnerInstEdit = () => {

    const {setShowSpinner, setDangerMessage, setSuccessMessage} = useContext(AppContext);
    const [ppMstPartnerInst, setPpMstPartnerInst] = useState<TypesPpMstPartnerInstData | null>(null)
    const [partnerInstName, setPartnerInstName] = useState<string>("");
    const [partnerSupplierTypeId, setPartnerSupplierTypeId] = useState<number>(0);
    const [postalCode, setPostalCode] = useState<string>("");
    const [postalCode1, setPostalCode1] = useState<string>("");
    const [postalCode2, setPostalCode2] = useState<string>("");
    const [prefCode, setPrefCode] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [street, setStreet] = useState<string>("");
    const [building, setBuilding] = useState<string>("");
    const [telNo, setTelNo] = useState<string>("");
    const params = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [ppMstPartnerSupplierTypes, setPpMstPartnerSupplierTypes] = useState<PpMstPartnerSupplierTypeModelGetListResponse[]>([]);

    useEffect(() => {

        if (params.id) {
            // ユーザーDが指定されている場合は、企業を取得する
            getPartnerInst(params.id);
        }

        getSupplierTypes();

    }, [params]);

    const getSupplierTypes = () => {

        setShowSpinner(true);

        adminPpMstPartnerSupplierTypeApi.v1AdminPpMstPartnerSupplierTypeListGet()
            .then(({data}) => {
                setPpMstPartnerSupplierTypes(data.data);
            })
            .catch((err) => {
                console.log(err);
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/topics");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });

    };

    const getPartnerInst = (id: string) => {

        setShowSpinner(true);

        adminPpMstPartnerInstApi.v1AdminPpMstPartnerInstGetIdGet(id)
            .then(({data}) => {
                setPpMstPartnerInst(data.pp_mst_partner_inst ?? null);

                if (data.pp_mst_partner_inst) {
                    setPartnerInstName(data.pp_mst_partner_inst.partner_inst_name ?? "");
                    setPartnerSupplierTypeId(data.pp_mst_partner_inst.partner_supplier_type_id ?? 0);
                    setPostalCode(data.pp_mst_partner_inst.postal_code ?? "");
                    setPrefCode(data.pp_mst_partner_inst.pref_code ?? "");
                    setCity(data.pp_mst_partner_inst.city ?? "");
                    setStreet(data.pp_mst_partner_inst.street ?? "");
                    setBuilding(data.pp_mst_partner_inst.building ?? "");
                    setTelNo(data.pp_mst_partner_inst.tel_no ?? "");

                    if (data.pp_mst_partner_inst.postal_code) {
                        const parts = data.pp_mst_partner_inst.postal_code.split("-");
                        if (parts.length === 2) {
                            setPostalCode1(parts[0]);
                            setPostalCode2(parts[1]);
                        }
                    }
                }

            })
            .catch((err) => {
                console.log(err);

                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/topics");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const req: PpMstPartnerInstControllerCreateRequest = {
            partner_inst_name: partnerInstName,
            pp_mst_partner_supplier_type_id: partnerSupplierTypeId,
            postal_code: postalCode,
            pref_code: prefCode,
            city: city,
            street: street,
            building: building,
            tel_no: telNo,
        };

        setShowSpinner(true);

        let promise: Promise<void | AxiosResponse<any, any>>;

        if (ppMstPartnerInst) {
            // 編集時
            promise = adminPpMstPartnerInstApi.v1AdminPpMstPartnerInstEditIdPost(String(ppMstPartnerInst.id), req)
                .then(() => {
                    setSuccessMessage("企業の編集が完了しました。");
                    setErrors({});
                    navigate(-1);
                });

        } else {
            // 新規登録時
            promise = adminPpMstPartnerInstApi.v1AdminPpMstPartnerInstCreatePost(req)
                .then(() => {
                    setSuccessMessage("企業の新規追加が完了しました。");
                    setErrors({});
                    navigate(-1);
                });
        }

        promise
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {

        switch (e.currentTarget.name) {
            case "partner_inst_name":
                setPartnerInstName(e.currentTarget.value);
                break;
            case "partner_supplier_type_id":
                setPartnerSupplierTypeId(Number(e.currentTarget.value));
                break;
            case "postal_code1":
                setPostalCode1(e.currentTarget.value);
                setPostalCode(`${e.currentTarget.value}-${postalCode2}`);
                break;
            case "postal_code2":
                setPostalCode2(e.currentTarget.value);
                setPostalCode(`${postalCode1}-${e.currentTarget.value}`);
                break;
            case "pref_code":
                setPrefCode(e.currentTarget.value);
                break;
            case "city":
                setCity(e.currentTarget.value);
                break;
            case "street":
                setStreet(e.currentTarget.value);
                break;
            case "building":
                setBuilding(e.currentTarget.value);
                break;
            case "tel_no":
                setTelNo(e.currentTarget.value);
                break;
        }
    };

    // 前の画面に戻る
    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(-1);
    };

    return <Layout title={"提携法人管理"}>

        <div className="box">

        <H3 title={"提携法人の設定"}/>

        <StyledCompanyEdit onSubmit={onSubmit}>

            <div className="form-wrapper">

                <div className="form-group">
                    <label className="req">会社名</label>
                    <div>
                        <input type="text" name="partner_inst_name" value={partnerInstName} className="medium" onChange={onChange} placeholder={"会社名"}/>
                        <ErrorMessage message={errors["partner_inst_name"]}/>
                    </div>
                </div>

                <div className="form-group">
                    <label className="req">営業取引種別ID</label>
                    <div>
                        <select name="partner_supplier_type_id" value={partnerSupplierTypeId} onChange={onChange}>
                            <option value="">選択してください</option>
                            {ppMstPartnerSupplierTypes.map((d, i) => {
                                return <option key={`p-${i}`} value={d.id}>{d.partner_supplier_name}</option>
                            })}
                        </select>
                        <ErrorMessage message={errors["partner_supplier_type_id"]}/>
                    </div>
                </div>

                <AddressSet
                    postalCode={postalCode}
                    postalCode1={postalCode1}
                    postalCode2={postalCode2}
                    prefCode={prefCode}
                    city={city}
                    street={street}
                    building={building}
                    onChange={onChange}
                    isRequired={false}
                    errors={errors}
                    setPrefCode={setPrefCode}
                    setCity={setCity}
                    setStreet={setStreet}
                    setBuilding={setBuilding}/>

                <div className="form-group">
                    <label>電話番号</label>
                    <div>
                        <input type="tel" name="tel_no" value={telNo} onChange={onChange} placeholder={"半角数字"}/>
                        <div className="hint">※半角数字。ハイフンの入力は不要。</div>
                        <ErrorMessage message={errors["tel_no"]}/>
                    </div>
                </div>

            </div>

            <div className="btn-area">
                <Button color={ButtonColor.Gray} type={"button"} onClick={onClickBack} icon={ButtonIcon.Arrow}>前の画面に戻る</Button>
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
            </div>

        </StyledCompanyEdit>

        </div>

    </Layout>

};

const StyledCompanyEdit = styled(StyledForm)`

`;
