export const MainColor = "#00BFA5";
export const OperatorColor = "#f5867d";
export const OperatorColorLight = "rgba(245,134,125,0.13)";
export const LaboratoryColor = "#0098A5";
export const LaboratoryColorLight = "rgba(0,152,165,0.13)";
export const CommonColor = "#FFA70B";
export const CommonColorLight = "rgba(255,167,11,0.13)";

export const LightOperatorColor = "rgba(255,138,128,0.2)";
export const LightLaboratoryColor = "rgba(0,152,165,0.2)";
export const LightCommonColor = "rgba(255,167,11,0.2)";
