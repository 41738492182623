import {AdminAgencyLoginApi, AdminAuthApi, AdminHistoryApi, AdminLogApi, AdminMetadataApi, AdminPpAnnouncementAdminApi, AdminPpAnnouncementUserApi, AdminPpFaqApi, AdminPpFaqCategoryApi, AdminPpFlowcellApi, AdminPpMaintenanceApi, AdminPpMstGenericRcmndGroupApi, AdminPpMstGenericRcmndImageApi, AdminPpMstIdentificationCdApi, AdminPpMstMeatureCommentApi, AdminPpMstPartnerInstApi, AdminPpMstPartnerSupplierTypeApi, AdminPpMstRcmndMethodApi, AdminPpMstRcmndProductApi, AdminPpMstRcmndProductsTitleApi, AdminPpMstSkuApi, AdminPpMstTestKitApi, AdminPpMstUserAdminApi, AdminPpMstUserMedicalApi, AdminPpMstUserPartnerApi, AdminPpPetApi, AdminPpTestKitApi, AdminPpUserApi, AdminResultApi, AssetsApi, CommonApi, Configuration, PpMstBreedApi} from "../generated";
import axios, {AxiosHeaders} from "axios";

const {REACT_APP_API_ENDPOINT} = process.env;

const config = new Configuration({
    basePath: REACT_APP_API_ENDPOINT,
})

const axiosInstance = axios.create({
    baseURL: REACT_APP_API_ENDPOINT,
    withCredentials: true,
});

// 実行時のインターセプターでトークンを付与
axiosInstance.interceptors.request.use((_config) => {

    _config.withCredentials = true;

    if (!_config.headers) {
        _config.headers = new AxiosHeaders();
    }

    if (_config.headers.get("Content-Type") === undefined) {
        // Content-Typeが指定されていない場合は、application/jsonを設定する
        _config.headers.set("Content-Type", "application/json");
    }

    const spGKey = sessionStorage.getItem("sp_g_key") ?? "";
    const spGToken = sessionStorage.getItem("sp_g_token") ?? "";
    const spPKey = sessionStorage.getItem("sp_p_key") ?? "";
    const spPToken = sessionStorage.getItem("sp_p_token") ?? "";
    const spAKey = sessionStorage.getItem("sp_a_key") ?? "";
    const spAToken = sessionStorage.getItem("sp_a_token") ?? "";

    if (spGKey && spGToken) {
        _config.headers.set("X-Sp-Token-Key", spGKey);
        _config.headers.set("X-Sp-Api-Token", spGKey);
    }
    if (spPKey && spPToken) {
        _config.headers.set("X-Sp-Token-Key", spPKey);
        _config.headers.set("X-Sp-Api-Token", spPToken);
    }
    if (spAKey && spAToken) {
        _config.headers.set("X-Sp-Token-Key", spAKey);
        _config.headers.set("X-Sp-Api-Token", spAToken);
    }

    return _config;
});

// 受信時のインターセプター
axiosInstance.interceptors.response.use((response) => {
    if (typeof response.data === "object") {
        const keys = Object.keys(response.data);
        // 認証済みのAPIレスポンスには、常にsephirahAuthCertificateが含まれる
        if (keys.includes("sephirahAuthCertificate")) {
            switch (response.data.sephirahAuthCertificate.authGroup) {
                case "1":
                    // 一般ユーザー
                    sessionStorage.setItem("sp_g_key", response.data["sephirahAuthCertificate"]["tokenKey"]);
                    sessionStorage.setItem("sp_g_token", response.data["sephirahAuthCertificate"]["apiToken"]);
                    break;
                case "2":
                    // 病院・提携法人ユーザー
                    sessionStorage.setItem("sp_p_key", response.data["sephirahAuthCertificate"]["tokenKey"]);
                    sessionStorage.setItem("sp_p_token", response.data["sephirahAuthCertificate"]["apiToken"]);
                    break;
                case "3":
                    // Adminユーザー
                    sessionStorage.setItem("sp_a_key", response.data["sephirahAuthCertificate"]["tokenKey"]);
                    sessionStorage.setItem("sp_a_token", response.data["sephirahAuthCertificate"]["apiToken"]);
                    break;
            }
        }
    }
    return response;
});

export const commonApi = new CommonApi(config, "", axiosInstance);
export const adminAuthApi = new AdminAuthApi(config, "", axiosInstance);
export const adminPpMstUserAdminApi = new AdminPpMstUserAdminApi(config, "", axiosInstance);
export const adminPpMstUserPartnerApi = new AdminPpMstUserPartnerApi(config, "", axiosInstance);
export const adminPpAnnouncementAdminApi = new AdminPpAnnouncementAdminApi(config, "", axiosInstance);
export const adminPpAnnouncementUserApi = new AdminPpAnnouncementUserApi(config, "", axiosInstance);
export const adminPpMstPartnerInstApi = new AdminPpMstPartnerInstApi(config, "", axiosInstance);
export const adminPpUserApi = new AdminPpUserApi(config, "", axiosInstance);
export const adminPpPetApi = new AdminPpPetApi(config, "", axiosInstance);
export const adminPpMstPartnerSupplierTypeApi = new AdminPpMstPartnerSupplierTypeApi(config, "", axiosInstance);
export const adminPpMstUserMedicalApi = new AdminPpMstUserMedicalApi(config, "", axiosInstance);
export const ppMstBreedApi = new PpMstBreedApi(config, "", axiosInstance);
export const adminPpMaintenanceApi = new AdminPpMaintenanceApi(config, "", axiosInstance);
export const adminPpTestKitApi = new AdminPpTestKitApi(config, "", axiosInstance);
export const adminMetadataApi = new AdminMetadataApi(config, "", axiosInstance);
export const adminPpMstTestKitApi = new AdminPpMstTestKitApi(config, "", axiosInstance);
export const adminResultApi = new AdminResultApi(config, "", axiosInstance);
export const adminHistoryApi = new AdminHistoryApi(config, "", axiosInstance);
export const adminPpFlowcellApi = new AdminPpFlowcellApi(config, "", axiosInstance);
export const adminAgencyLoginApi = new AdminAgencyLoginApi(config, "", axiosInstance);
export const assetsApi = new AssetsApi(config, "", axiosInstance);
export const adminLogApi = new AdminLogApi(config, "", axiosInstance);
export const adminPpMstSkuApi = new AdminPpMstSkuApi(config, "", axiosInstance);
export const adminPpMstRcmndProductApi = new AdminPpMstRcmndProductApi(config, "", axiosInstance);
export const adminPpMstIdentificationCdApi = new AdminPpMstIdentificationCdApi(config, "", axiosInstance);
export const adminPpFaqCategoryApi = new AdminPpFaqCategoryApi(config, "", axiosInstance);
export const adminPpFaqApi = new AdminPpFaqApi(config, "", axiosInstance);
export const adminPpMstRcmndMethodApi = new AdminPpMstRcmndMethodApi(config, "", axiosInstance);
export const adminPpMstGenericRcmndGroupApi = new AdminPpMstGenericRcmndGroupApi(config, "", axiosInstance);
export const adminPpMstRcmndProductsTitleApi = new AdminPpMstRcmndProductsTitleApi(config, "", axiosInstance);
export const adminPpMstGenericRcmndImageApi = new AdminPpMstGenericRcmndImageApi(config, "", axiosInstance);
export const adminPpMstMeatureCommentApi = new AdminPpMstMeatureCommentApi(config, "", axiosInstance);
