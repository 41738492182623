import {Layout} from "../common/Layout";
import {H1} from "../common/Headline";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import React, {useContext} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {P} from "../common/P";
import {AppContext} from "../../contexts/AppContext";
import {AccesstokenRole} from "../../generated";

export const PpPetCreateComplete = () => {

    const navigate = useNavigate();
    const params = useParams();
    const {role} = useContext(AppContext);

    const onClick = (e: React.MouseEvent<HTMLButtonElement>, type: string) => {

        e.preventDefault();

        if (type === "pet") {
            navigate(`/pp_pet/create/${params.memberId}`);
        } else if (type === "test") {
            navigate(`/pp_test_kit/apply/${params.memberId}/${params.petId}`);
        }

    };

    return <Layout title={"会員検索"}>

        <H1 title="ペット情報の新規登録" subtitle="registration"/>

        <StyledMembersCreateComplete>

            <div className="box">
                <P>
                    ペット情報のご登録ありがとうございます。<br/>
                    引き続き検査申込へお進みください。
                </P>
            </div>

            <div className="btn-area">
                <Button color={ButtonColor.Green} icon={ButtonIcon.Arrow} onClick={(e) => onClick(e, "pet")}>ペット情報を追加</Button>
                {role !== AccesstokenRole.RoleCs && <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow} onClick={(e) => onClick(e, "test")}>検査申し込みへ</Button>}

            </div>


        </StyledMembersCreateComplete>

    </Layout>

};

const StyledMembersCreateComplete = styled(StyledForm)`

`;
