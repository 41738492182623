import {TypesPpMstMeatureCommentData} from "../../../generated";
import styled from "styled-components";
import {MainColor} from "../../common/Colors";
import React from "react";

interface Props {
    comment: TypesPpMstMeatureCommentData | null;
    onClick: (e: React.MouseEvent<HTMLDivElement>, comment: TypesPpMstMeatureCommentData) => void;
}

export const BadBacteriaComment = ({comment, onClick}: Props) => {

    if (!comment) {
        return null;
    }

    return <StyledBadBacteriaComment onClick={(e) => onClick(e, comment)}>
            【対策】<br/>
            {comment.comment_001}
    </StyledBadBacteriaComment>
}

const StyledBadBacteriaComment = styled.div`
    margin-left: 40px;
    border-radius: 29px;
    background-color: #EFEFEF;
    flex: 1;
    font-size: 15px;
    padding: 10px 20px;
    position: relative;
    
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: -20px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 30px solid #EFEFEF;
        transform: translateY(-50%);
    }
    
    &:hover {
        cursor: pointer;
        background-color: ${MainColor};
        color: #fff;

        &:before {
            border-right-color: ${MainColor};
            
        }
    }

`;