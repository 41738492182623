import styled from "styled-components";
import {MainColor} from "./Colors";
import rightWhite from "../../images/right-white.svg";
import leftWhite from "../../images/left-white.svg";

interface Props {
    onClick: (page: number) => void;
    page: number;
    pages: number;
}

export const Paginator = (props: Props) => {

    // 表示するページ番号を設定
    const maxNum = 10;

    const getModulus = (): JSX.Element[] => {

        const s = props.page - (maxNum / 2) < 1 ? 1 : props.page - (maxNum / 2);
        const e = s + maxNum > props.pages ? props.pages : s + maxNum;

        const elements: JSX.Element[] = [];

        for (let i = s; i <= e; i++) {
            if (props.page === i) {
                elements.push(<li key={`page-${i}`}><span className="current">{i}</span></li>);
            } else {
                elements.push(<li key={`page-${i}`}><span onClick={() => props.onClick(i)}>{i}</span></li>);

            }
        }

        return elements;

    }

    if (props.pages <= 1) {
        return null;
    }

    return <StyledPaginator>
        <ul>
            {props.page >= 2 && <li className="prev"><span onClick={() => props.onClick(props.page - 1)}>&lt;</span></li>}
            {getModulus()}
            {props.page < props.pages && <li className="next"><span onClick={() => props.onClick(props.page + 1)}>&gt;</span></li>}
        </ul>
    </StyledPaginator>

};

const wh = 32;

const StyledPaginator = styled.div`
  margin-bottom: 30px;

  ul {
    display: flex;
    list-style-type: none;
    justify-content: center;
    align-items: center;

    li {
      margin-right: 10px;

      span {
        cursor: pointer;
        font-size: 14px;
        text-decoration: none;
        padding: 0 5px;
        display: block;
        width: ${wh}px;
        height: ${wh}px;
        border: 1px solid ${MainColor};
        color: ${MainColor};
        border-radius: 32px;
        text-align: center;
        line-height: ${wh}px;

        &:hover {
          text-decoration: none;
        }

        &.current {
          cursor: default;
          text-decoration: none;
          background-color: ${MainColor};
          color: #fff;
        }
      }

      &.prev, &.next {
        span {
          width: 24px;
          height: 24px;
          border: none;
          background-color: ${MainColor};
          background-image: url(${rightWhite});
          background-size: auto 8px;
          background-repeat: no-repeat;
          background-position: center center;
          text-indent: 100%;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      
      &.prev {
        span {
          background-image: url(${leftWhite});
        }
      }

    }

  }

`;
