import styled from "styled-components";
import {BadBacteriaGraph1, BadBacteriaGraph1GraphColor, BadBacteriaGraph2} from "./BadBacteriaGraph";
import {SlashedDate} from "../../../utility/Utility";

interface Props {
    label: JSX.Element;
    completionDates: string[];
    values: number[];
}

// スコア向けのカード
export const BadBacteriaCardType1 = ({label, completionDates, values}: Props) => {
    return <StyledBadBacteriaCardType1>
        <label>{label}</label>
        <div className="values">
            {completionDates.map((date, i) => {

                if (i >= 3) {
                    return null;
                }

                let c = BadBacteriaGraph1GraphColor.DEFAULT;

                if (i === 0) {
                    // 一つ目が二つ目よりも大きい場合は、緑にする
                    if (values.length >= 2 && values[i] > values[i + 1]) {
                        c = BadBacteriaGraph1GraphColor.GREEN;
                    } else if (values.length >= 2 && values[i] <= values[i + 1]) {
                        c = BadBacteriaGraph1GraphColor.RED;
                    }
                }

                return <div key={`r-${i}`}>
                    <span className="date">{SlashedDate(date)}</span>
                    <BadBacteriaGraph1 value={values[i]} color={c}/>
                    <span className="value" style={{color: c === BadBacteriaGraph1GraphColor.DEFAULT ? "#000" : c}}>{values[i]}</span>
                </div>

            })}
        </div>
    </StyledBadBacteriaCardType1>
}

// 有害菌向けのカード
export const BadBacteriaCardType2 = ({label, completionDates, values}: Props) => {

    let diff = "";
    let diffCls = "";

    if (values.length >= 2) {
        // 推移を取得
        const diffValue = values[0] - values[1];
        if (diffValue < 0) {
            diff = `${Math.floor(diffValue * 100) / 100}%`;
            diffCls = "green";
        } else if(diffValue > 0) {
            diff = `+${Math.floor(diffValue * 100) / 100}%`;
            diffCls = "red";
        }
    }

    // グラフの上限値を決める
    // 2024/04/12 最大5%固定とする。
    // const maxValue = Math.max(...values);
    const max = 5;

    // if (maxValue >= 81) {
    //     max = 100;
    // } else if (maxValue >= 61) {
    //     max = 80;
    // } else if (maxValue >= 41) {
    //     max = 60;
    // } else if (maxValue >= 21) {
    //     max = 40;
    // } else {
    //     max = 20;
    // }

    return <StyledBadBacteriaCardType2>
        <label className={diffCls}><div>{label}<br/>{diff !== "" ? <span>{diff}</span> : ""}</div></label>
        <div className="values">
            <span className="label-zero">0</span>
            <span className="label-max">{max}(%)</span>
            {completionDates.map((date, i) => {

                if (i >= 3) {
                    return null;
                }

                let c = BadBacteriaGraph1GraphColor.DEFAULT;

                if (i === 0) {
                    // 一つ目が二つ目よりも小さい場合（改善している）は、緑にする
                    if (values.length >= 2 && values[i] <= values[i + 1]) {
                        c = BadBacteriaGraph1GraphColor.GREEN;
                    } else if (values.length >= 2 && values[i] > values[i + 1]) {
                        c = BadBacteriaGraph1GraphColor.RED;
                    }
                }

                return <div key={`r-${i}`}>
                    <span className="date">{SlashedDate(date)}</span>
                    <BadBacteriaGraph2 value={values[i]} color={c} max={max}/>
                    <span className="value" style={{color: c === BadBacteriaGraph1GraphColor.DEFAULT ? "#000" : c}}>{values[i]}%</span>
                </div>

            })}
        </div>
    </StyledBadBacteriaCardType2>
}

const StyledBadBacteriaCardType1 = styled.div`

    display: flex;
    align-items: center;

    label {
        text-align: center;
        font-size: 15px;
        background-color: #EFEFEF;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 88px;
        width: 139px;
        border-radius: 4px;
        margin-right: 15px;
    }

    .values {

        > div {
            display: flex;
            align-items: center;
            margin-bottom: 7px;

            &:last-child {
                margin-bottom: 0;
            }

            span.date {
                font-size: 11px;
                background-color: #EFEFEF;
                border-radius: 8px;
                line-height: 16px;
                padding: 0 4px;
                margin-right: 10px;
            }

            svg {
                margin-right: 10px;
            }

            span.value {
                font-size: 14px;
            }
        }
    }

`;

const StyledBadBacteriaCardType2 = styled.div`

    display: flex;
    margin-bottom: 15px;
    align-items: center;

    label {
        background-color: #EFEFEF;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 88px;
        width: 139px;
        border-radius: 4px;
        margin-right: 15px;

        div {
            text-align: center;
            font-size: 15px;
            line-height: 1.4;
            
            span {
                display: block;
                margin-top: 5px;
            }
        }
        
        &.red {
            background-color: #F8D8DB;

            div span {
                color: red;                
            }
        }
    }

    .values {
        position: relative;

        .label-zero, .label-max {
            font-size: 10px;
            color: #D3D3D3;
            position: absolute;
        }

        .label-zero {
            left: 81px;
            top: -14px;
        }

        .label-max {
            left: 340px;
            top: -14px;
        }


        > div {
            display: flex;
            align-items: center;
            //margin-bottom: 7px;

            &:last-child {
                margin-bottom: 0;
            }

            span.date {
                font-size: 11px;
                background-color: #EFEFEF;
                border-radius: 8px;
                line-height: 16px;
                padding: 0 4px;
                margin-right: 10px;
            }

            svg {
                margin-right: 10px;
            }

            span.value {
                font-size: 14px;
            }
        }
    }

`;