import {adminPpMstPartnerInstApi} from "../api/Api";
import {useContext, useEffect, useState} from "react";
import {PpMstPartnerInstModelGetListResponse} from "../generated";
import {AppContext} from "../contexts/AppContext";

// 提携法人リスト取得
export const usePartnerInsts = () => {

    const {setDangerMessage, setShowSpinner} = useContext(AppContext);
    const [partnerInsts, setPartnerInsts] = useState<PpMstPartnerInstModelGetListResponse[]>([]);

    useEffect(() => {

        setShowSpinner(true);

        adminPpMstPartnerInstApi
            .v1AdminPpMstPartnerInstListGet()
            .then(({data}) => {
                setPartnerInsts(data.data);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }, []);

    return partnerInsts;
}