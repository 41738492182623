import React, {useContext, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {ErrorMessage} from "../common/ErrorMessage";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {StyledForm} from "../common/StyledForm";
import {adminAuthApi} from "../../api/Api";
import {AdminAuthControllerChangePasswordRequest} from "../../generated";
import {H3} from "../common/Headline";

export const PpMstUserAdminChangePassword = () => {

    const {setShowSpinner, setDangerMessage, setSuccessMessage} = useContext(AppContext);
    const [oldPassword, setOldPassword] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmationPassword, setConfirmationPassword] = useState<string>("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

        switch (e.currentTarget.name) {
            case "old_password":
                setOldPassword(e.currentTarget.value);
                break;
            case "password":
                setPassword(e.currentTarget.value);
                break;
            case "confirmation_password":
                setConfirmationPassword(e.currentTarget.value);
                break;
        }
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        setShowSpinner(true);

        const req: AdminAuthControllerChangePasswordRequest = {
            old_password: oldPassword,
            password: password,
            confirmation_password: confirmationPassword,
        };

        adminAuthApi.v1AdminAuthChangePasswordPost(req)
            .then(() => {
                setSuccessMessage("パスワードを変更しました。次回以降新しいパスワードでログインしてください。");
                setOldPassword("");
                setPassword("");
                setConfirmationPassword("");
                setErrors({});
            })
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });

    };

    return <Layout title={"パスワード変更"}>

        <div className="box">

            <H3 title={"パスワード変更"}/>

            <StyledForm onSubmit={onSubmit}>

                <div className="form-wrapper">

                    <div className="form-group">
                        <label className="req">現在のパスワード</label>
                        <div>
                            <input type="password" name="old_password" value={oldPassword} onChange={onChange} placeholder={"現在のパスワード"}/>
                            <ErrorMessage message={errors["old_password"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">パスワード</label>
                        <div>
                            <input type="password" name="password" value={password} onChange={onChange} placeholder={"現在のパスワード"}/>
                            <ErrorMessage message={errors["password"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">パスワード（再入力）</label>
                        <div>
                            <input type="password" name="confirmation_password" value={confirmationPassword} onChange={onChange} placeholder={"再入力"}/>
                            <ErrorMessage message={errors["confirmation_password"]}/>
                        </div>
                    </div>

                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
                </div>

            </StyledForm>

        </div>

    </Layout>;

};
