import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {Layout} from "../common/Layout";
import {adminPpMaintenanceApi} from "../../api/Api";
import {PpMaintenanceControllerSetRequest, TypesPpMaintenanceData} from "../../generated";
import {useNavigate} from "react-router-dom";
import {H3} from "../common/Headline";
import {ErrorMessage} from "../common/ErrorMessage";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Zerofill} from "../../utility/Utility";

export const PpMaintenanceIndex = () => {

    const {setShowSpinner, setDangerMessage, setSuccessMessage} = useContext(AppContext);
    const [ppMaintenance, setPpMaintenance] = useState<TypesPpMaintenanceData | null>(null);
    const [mainteYmd, setMainteYmd] = useState<string>("");
    const [mainteHmsFrom, setMainteHmsFrom] = useState<string>("");
    const [mainteHmsTo, setMainteHmsTo] = useState<string>("");
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {

        getMaintenanceData();

    }, []);

    const getMaintenanceData = () => {

        setShowSpinner(true);

        adminPpMaintenanceApi.v1AdminPpMaintenanceGet()
            .then(({data}) => {

                if (data.pp_maintenance) {
                    setPpMaintenance(data.pp_maintenance);
                    setMainteYmd(data.pp_maintenance.mainte_ymd ?? "");
                    setMainteHmsFrom(data.pp_maintenance.mainte_from_hms ?? "");
                    setMainteHmsTo(data.pp_maintenance.mainte_to_hms ?? "");
                }

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/system")
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });

    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {

        switch (e.currentTarget.name) {
            case "mainte_ymd":
                setMainteYmd(e.currentTarget.value);
                break;
            case "mainte_hms_from":
                setMainteHmsFrom(e.currentTarget.value);
                break;
            case "mainte_hms_to":
                setMainteHmsTo(e.currentTarget.value);
                break;
        }
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {

        e.preventDefault()

        const req: PpMaintenanceControllerSetRequest = {
            mainte_ymd: mainteYmd,
            mainte_hms_from: mainteHmsFrom,
            mainte_hms_to: mainteHmsTo,
        }

        if (!window.confirm("メンテナンス設定が現在時刻の場合、即座にAPIが使用不可能となります。設定を行ってもよろしいですか？")) {
            return;
        }

        setShowSpinner(true);

        adminPpMaintenanceApi.v1AdminPpMaintenanceSetPost(req)
            .then(({data}) => {
                setSuccessMessage("メンテナンス設定を更新しました。");
                setPpMaintenance(data.pp_maintenance ?? null);
                setErrors({});
            })
            .catch((err) => {

                if (err.response.status === 406) {
                    // バリデーションエラー
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    // 時刻選択肢を返す
    const getTimes = (): string[] => {
        const res: string[] = [];
        for (let a = 0; a <= 29; a++) {
            for (let b = 0; b < 60; b += 15) {
                res.push(`${Zerofill(a, 2)}:${Zerofill(b, 2)}:00`);
            }
        }

        return res;
    };

    const onDelete = (e: React.MouseEvent<HTMLButtonElement>): void => {

        e.preventDefault();

        if (window.confirm(`メンテナンス設定をクリアします。よろしいですか？`)) {

            setShowSpinner(true);

            adminPpMaintenanceApi.v1AdminPpMaintenanceClearDelete()
                .then(() => {
                    setSuccessMessage("メンテナンス設定をクリアしました。");
                    setPpMaintenance(null);
                    setMainteYmd("");
                    setMainteHmsFrom("");
                    setMainteHmsTo("");

                    setErrors({});
                })
                .catch((err) => {
                    if (err.response.data.message) {
                        setDangerMessage(err.response.data.message);
                    } else {
                        setDangerMessage("通信時にエラーが発生しました");
                    }
                })
                .finally(() => {
                    setShowSpinner(false);
                });
        }

    }

    return <Layout title={"メンテナンス設定"}>


        <StyledPpMaintenanceIndex onSubmit={onSubmit}>

            <div className="box">

                <H3 title={"メンテナンス設定"}/>

                <div className="form-wrapper">

                    <div className="form-group">
                        <label className="req">日付</label>
                        <div>
                            <input type="date" name="mainte_ymd" value={mainteYmd} onChange={onChange} placeholder={"YYYY-MM-DD"}/>
                            <ErrorMessage message={errors["mainte_ymd"]}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">時刻設定</label>
                        <div>
                            <div className="inline">
                                <select name={"mainte_hms_from"} value={mainteHmsFrom} onChange={onChange}>
                                    <option value="">--:--:--</option>
                                    {getTimes().map((d, i) => <option key={`from-${i}`} value={d}>{d}</option>)}
                                </select>
                                <span>〜</span>
                                <select name={"mainte_hms_to"} value={mainteHmsTo} onChange={onChange}>
                                    <option value="">--:--:--</option>
                                    {getTimes().map((d, i) => <option key={`to-${i}`} value={d}>{d}</option>)}
                                </select>
                            </div>
                            <ErrorMessage message={errors["mainte_hms_from"]}/>
                            <ErrorMessage message={errors["mainte_hms_to"]}/>
                        </div>
                    </div>

                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
                </div>


            </div>


            {ppMaintenance?.mainte_ymd && <React.Fragment>
                <div className="box">
                    <H3 title={"メンテナンス設定をクリア"}/>

                    <div className="btn-area">
                        <Button color={ButtonColor.Danger} icon={ButtonIcon.Arrow} type="button" onClick={onDelete}>設定をクリアする</Button>
                    </div>
                </div>
            </React.Fragment>}


        </StyledPpMaintenanceIndex>

    </Layout>

}

const StyledPpMaintenanceIndex = styled(StyledForm)`


`;