import styled from "styled-components";
import React from "react";
import {MainColor} from "../common/Colors";

export enum ModalColor {
    Danger = "danger",
    Success = "success",
}

interface Props extends React.PropsWithChildren {
    color: ModalColor;
}

export const Modal = ({color, children}: Props) => {

    return <StyledModal>

        <div className={color + " modal-inner"}>
            {children}
        </div>

    </StyledModal>
}

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.6);
  z-index: 100;

  .modal-inner {
    width: 640px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 14px;
    overflow: hidden;

    &.danger {
      h4 {
        background-color: #D93E4C;
      }

      h5 {
        color: #D93E4C;
      }
    }

    &.success {
      h4 {
        background-color: ${MainColor};
      }

      h5 {
        color: #4D8E82;
      }
    }

    h4 {
      line-height: 48px;
      text-align: center;
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 1.2px;
    }

    h5 {
      text-align: center;
      font-size: 16px;
      line-height: 62px;
      background-color: #EFEFEF;
      color: #D93E4C;
      font-weight: 400;
      letter-spacing: 1.2px;
    }

    p {
      background-color: #fff;
      text-align: center;
      font-size: 16px;
      line-height: 2;
      padding: 40px;

      strong {
        font-weight: 400;
        color: #D93E4C;
        background: linear-gradient(transparent 50%, rgba(217, 62, 76, 0.2) 20%);
      }
    }

    // 確認モーダルの場合
    ul {
      list-style-type: none;
      background-color: #fff;
      padding: 25px;

      li {
        display: flex;
        justify-content: center;
        align-items: baseline;

        label {
          text-align: right;
          width: 150px;

          &.small {
            font-size: 14px;
          }

          &.w200 {
            width: 200px;
          }
        }

        .num {
          font-size: 34px;
          color: #FF9871;
          min-width: 100px;
          margin-right: 15px;
          text-align: right;

          &.w60 {
            min-width: auto;
            width: 60px;
          }
        }

        .value {
          font-size: 24px;
          color: #FF9871;
          min-width: 100px;
          margin-right: 15px;
          text-align: left;
          padding-left: 20px;
        }

        .postfix {
          color: #00BFA5;
          width: 50px;
        }
      }

    }

    .btn-area {
      background-color: #EFEFEF;
      padding: 24px;
      text-align: center;

      button {
        display: inline-block;
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .errors {
      background-color: #fff;
      padding: 20px 30px 0 30px;

      div {
        font-size: 14px;
        color: red;
        padding-bottom: 10px;
        
        &:last-child {
            padding-bottom: 0;
        }
      }
    }

  }
`;