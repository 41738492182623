import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import React, {useEffect, useRef, useState} from "react";
import {H2} from "../common/Headline";
import {CheckSampleId} from "../../utility/Utility";
import {useLocation, useNavigate} from "react-router-dom";

enum SearchType {
    FlowCellId = 1,
    SampleId = 2,
}

export enum ErrorType {
    None = 0, // エラーなし
    NoInput = 1, // 入力なし
    NotFound = 2, // データが見つからない
    Duplicate = 3, // すでに検索済み
    ErrorFlowCell = 4, // Flow Cell IDですでに検索している
}

interface Props {
    errorType: ErrorType,
    onClear: () => void;
    onGetData: (e: React.MouseEvent<HTMLButtonElement>) => void;
    isResultSetEnabled: boolean;
    onUpdateResult: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SearchForm = ({onClear, errorType, onGetData, isResultSetEnabled, onUpdateResult}: Props) => {

    const [sampleId, setSampleId] = useState<string>("");
    const [flowcellId, setFlowcellId] = useState<string>("");
    const [searchType, setSearchType] = useState<SearchType>(SearchType.FlowCellId);
    const ref = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);


    useEffect(() => {

        const params = new URLSearchParams(location.search);

        const flowcellId = params.get("flowcell_id");
        const sampleId = params.get("sample_id");

        if (flowcellId != null) {
            setFlowcellId(flowcellId);
            setSearchType(SearchType.FlowCellId);
        }

        if (sampleId != null) {
            setSampleId(sampleId);
            setSearchType(SearchType.SampleId);
        }

    }, [location.search]);

    useEffect(() => {
        if (flowcellId.length === 8) {
            navigate(`/result?flowcell_id=${flowcellId}`);
        }
        if (CheckSampleId(sampleId)) {
            navigate(`/result?sample_id=${sampleId}`);
        }
    }, [sampleId, flowcellId]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.currentTarget.name) {
            case "flowcell_id":
                setFlowcellId(e.currentTarget.value);
                break;
            case "sample_id":
                setSampleId(e.currentTarget.value);
                break;
            case "search_type":
                if (e.currentTarget.value === "1") {
                    setSearchType(SearchType.FlowCellId);
                    setSampleId("");
                } else {
                    setSearchType(SearchType.SampleId);
                    setFlowcellId("");
                }
                break;
        }
    };

    const onSubmitDummy = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
    };

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (!window.confirm("一覧をクリアします。よろしいですか？")) {
            return;
        }

        setSearchType(SearchType.FlowCellId);
        setFlowcellId("");
        setSampleId("");
        onClear();
        navigate("/result");

        if (ref.current) {
            ref.current.focus();
        }
    }

    const onFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.currentTarget.select();
    };

    return <StyledSearchForm noValidate={true} onSubmit={onSubmitDummy}>

        <div className="box">

            <H2 title="検体ID読み取り" subtitle="input"/>

            <div className="search-form">

                <div className="row">

                    <div className="col">

                        <div className="form-group">
                            <label>
                                <input type="radio" name="search_type" value="1" checked={searchType === SearchType.FlowCellId} onChange={onChange}/>
                                Flow Cell ID
                            </label>
                            <div>
                                <input type="text" name="flowcell_id" placeholder={"例：ABC12345"} value={flowcellId} onChange={onChange} maxLength={8} disabled={searchType === SearchType.SampleId} ref={ref} onFocus={onFocus}/>
                            </div>

                            {(searchType === SearchType.FlowCellId && errorType === ErrorType.NotFound) && <div className="search-error">検査結果登録対象の検体ではありません。<br />Flow Cell IDの入力内容をもう一度ご確認ください。</div>}
                            {(searchType === SearchType.FlowCellId && errorType === ErrorType.Duplicate) && <div className="search-error">登録済みのFlow Cell IDです</div>}
                            {(searchType === SearchType.FlowCellId && errorType === ErrorType.ErrorFlowCell) && <div className="search-error">同時に複数Flow Cell分の検査結果は登録できません</div>}

                            {errorType === ErrorType.NoInput && <div className="search-error no-input">IDを読み取ってください</div>}

                        </div>

                        <div className="form-group">
                            <label>
                                <input type="radio" name="search_type" value="2" checked={searchType === SearchType.SampleId} onChange={onChange}/>
                                検体ID
                            </label>
                            <div>
                                <input type="text" name="sample_id" placeholder={"例：PC23-1000-NGS-1000"} value={sampleId} onChange={onChange} maxLength={18} disabled={searchType === SearchType.FlowCellId} onFocus={onFocus}/>
                            </div>

                            {(searchType === SearchType.SampleId && errorType === ErrorType.NotFound) && <div className="search-error">検査結果登録対象の検体ではありません。<br />検体IDの入力内容をもう一度ご確認ください。</div>}

                        </div>

                    </div>

                    <div className="col">
                        <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                    </div>

                </div>


                <div className="btn-area">
                    <Button color={ButtonColor.Green} type="button" icon={ButtonIcon.Arrow} onClick={onGetData}>データ取得</Button>
                    <Button color={isResultSetEnabled ? ButtonColor.Orange : ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onUpdateResult} disabled={!isResultSetEnabled}>結果更新</Button>
                </div>

            </div>

        </div>

    </StyledSearchForm>
};

export const StyledSearchForm = styled(StyledForm)`

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .col {
      &:nth-child(2) {
        padding-right: 100px;
        button {
          padding: 0 84px;
          font-size: 18px;
          line-height: 44px;
          height: 44px;
        }
      }
    }
  }

  .search-form {

    .form-group {
      position: relative;

      label {
        width: 130px;
        margin-right: 0;
        display: flex;
        align-items: center;

        input[type="radio"] {
          margin: 0 10px 0 0;
        }
      }

      .search-error {
        color: #fff;
        background-color: #D93E4C;
        font-size: 14px;
        line-height: 1.8;
        display: inline-block;
        position: absolute;
        padding: 5px 10px;
        border-radius: 6px;
        width: 400px;
        top: 10px;
        left: 470px;

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: -18px;
          top: 50%;
          transform: translateY(-50%);
          border: 10px solid transparent;
          border-right: 10px solid #D93E4C;
        }
        
        &.no-input {
          top: 45px;
          width: 200px;
        }
      }

    }

  }

`;