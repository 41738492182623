import {PpflowcellStatus, PpteststatusStatus, TypesPpTestKitData} from "../generated";

import faceA from "../images/face_a.svg";
import faceB from "../images/face_b.svg";
import faceC from "../images/face_c.svg";
import faceD from "../images/face_d.svg";
import faceE from "../images/face_e.svg";


export const PpMstUserAdminAccesses: { [key: string]: string } = {
    "1": "運用保守",
    "2": "検査技師",
    "3": "CS",
    "4": "特権ユーザー",
};

// 管理者ユーザーのロールの文字列表現を取得する
export const GetPpMstUserAdminAccessStr = (access: string): string => {
    return PpMstUserAdminAccesses[access] ?? "";
}


// YYYY-MM-DD をDateに変換する
export const ConvertYmdToDate = (ymd: string): Date => {
    try {
        return new Date(ymd.replace(" ", "T"));
    } catch (e) {
        console.log(e);
    }

    // 解析できない場合、救いがない。
    return new Date();
};

// タイムスタンプをDateに変換する
export const ConvertTimestampToDate = (s: number): Date => {
    try {
        return new Date(s * 1000);
    } catch (e) {
        console.log(e);
    }

    // 解析できない場合、救いがない。
    return new Date();
};

// UNIXタイムスタンプをYYYY-MM-DD HH:MM:SSに変換する
export const HyphenDtFromTimeStamp = (s: number): string => {
    const dt = ConvertTimestampToDate(s);
    return `${dt.getFullYear()}-${Zerofill(dt.getMonth() + 1, 2)}-${Zerofill(dt.getDate(), 2)} ${Zerofill(dt.getHours(), 2)}:${Zerofill(dt.getMinutes(), 2)}:${Zerofill(dt.getSeconds(), 2)}`;
};

// UNIXタイムスタンプをYYYY/MM/DD HH:MMに変換する
export const SlashedDtFromTimeStamp = (s: number): string => {
    const dt = ConvertTimestampToDate(s);
    return `${dt.getFullYear()}/${Zerofill(dt.getMonth() + 1, 2)}/${Zerofill(dt.getDate(), 2)} ${Zerofill(dt.getHours(), 2)}:${Zerofill(dt.getMinutes(), 2)}`;
};

// YYYY-MM-DD をYYYY/MM/DDに変換する
export const SlashedYmd = (ymd: string): string => {
    const dt = ConvertYmdToDate(ymd);
    return `${dt.getFullYear()}/${Zerofill(dt.getMonth() + 1, 2)}/${Zerofill(dt.getDate(), 2)}`
};

// YYYY-MM-DD HH:MM:SS をYYYY/MM/DD HH:MMに変換する
export const SlashedDt = (ymd: string): string => {
    const dt = ConvertYmdToDate(ymd);
    return `${dt.getFullYear()}/${Zerofill(dt.getMonth() + 1, 2)}/${Zerofill(dt.getDate(), 2)} ${Zerofill(dt.getHours(), 2)}:${Zerofill(dt.getMinutes(), 2)}`;
};

// YYYY-MM-DD をYYYY年MM月DD日に変換する
export const CreateJaYmd = (ymd: string): string => {
    const dt = ConvertYmdToDate(ymd);
    return `${dt.getFullYear()}年 ${dt.getMonth() + 1}月 ${dt.getDate()}日`;
};

// YYYY-MM-DD HH:MM:SS をYYYY/MM/DD HH:MMに変換する
export const SlashedDatetime = (ymd: string): string => {
    const dt = ConvertYmdToDate(ymd);
    return `${dt.getFullYear()}/${Zerofill(dt.getMonth() + 1, 2)}/${Zerofill(dt.getDate(), 2)} ${Zerofill(dt.getHours(), 2)}:${Zerofill(dt.getMinutes(), 2)}`
};

// YYYY-MM-DD HH:MM:SS をYYYY/MM/DDに変換する
export const SlashedDate = (ymdhms: string): string => {
    const dt = ConvertYmdToDate(ymdhms);
    return `${dt.getFullYear()}/${Zerofill(dt.getMonth() + 1, 2)}/${Zerofill(dt.getDate(), 2)}`
};

// 数値のゼロパディング(とりあえず、必要な桁数のみ対応)
export const Zerofill = (i: number, digits: number): string => {
    switch (digits) {
        case 2:
            if (i < 10) {
                return `0${i}`;
            }
            break;
        case 3:
            if (i < 10) {
                return `00${i}`;
            }
            if (i < 100) {
                return `0${i}`;
            }
            break;
    }
    return `${i}`;
}

// ページタイトルを設定
export const SetPageTitle = (title: string) => {
    let ret = "";

    if (title !== "") {
        ret += title + " | "
    }

    ret += "PiTPET管理システム";

    document.title = ret;
}

// 都道府県リスト
// コードは国土交通省のリストから
// https://nlftp.mlit.go.jp/ksj/gml/codelist/PrefCd.html
export const Prefectures: { [key: string]: string } = {
    "1": "北海道",
    "2": "青森県",
    "3": "岩手県",
    "4": "宮城県",
    "5": "秋田県",
    "6": "山形県",
    "7": "福島県",
    "8": "茨城県",
    "9": "栃木県",
    "10": "群馬県",
    "11": "埼玉県",
    "12": "千葉県",
    "13": "東京都",
    "14": "神奈川県",
    "15": "新潟県",
    "16": "富山県",
    "17": "石川県",
    "18": "福井県",
    "19": "山梨県",
    "20": "長野県",
    "21": "岐阜県",
    "22": "静岡県",
    "23": "愛知県",
    "24": "三重県",
    "25": "滋賀県",
    "26": "京都府",
    "27": "大阪府",
    "28": "兵庫県",
    "29": "奈良県",
    "30": "和歌山県",
    "31": "鳥取県",
    "32": "島根県",
    "33": "岡山県",
    "34": "広島県",
    "35": "山口県",
    "36": "徳島県",
    "37": "香川県",
    "38": "愛媛県",
    "39": "高知県",
    "40": "福岡県",
    "41": "佐賀県",
    "42": "長崎県",
    "43": "熊本県",
    "44": "大分県",
    "45": "宮崎県",
    "46": "鹿児島県",
    "47": "沖縄県",
};

// 都道府県コードから都道府県名を返す
export const GetPrefectureName = (code: string): string => {
    return Prefectures[code] ?? "";
}

// 年プルダウンリストを返す
// 生年月日の場合は、1930〜今年までの年を返す
// それ以外の場合は、サービス開始年から今年までの年を返す(isLimit === true)
export const GetYearList = (isLimit: boolean): string[] => {
    const res = [];
    for (let i = new Date().getFullYear(); i >= 1930; i--) {

        if (isLimit && i < 2023) {
            break;
        }

        res.push(String(i));
    }
    return res;
}

// 月プルダウンリストを返す
export const GetMonthList = (): string[] => {
    const res = [];
    for (let i = 1; i <= 12; i++) {
        res.push(Zerofill(i, 2));
    }
    return res;
}

// 日プルダウンリストを返す
export const GetDateList = (): string[] => {
    const res = [];
    for (let i = 1; i <= 31; i++) {
        res.push(Zerofill(i, 2));
    }
    return res;
}

// 時間プルダウンリストを返す
export const GetHourList = (): string[] => {
    const res = [];
    for (let i = 0; i < 24; i++) {
        res.push(Zerofill(i, 2));
    }
    return res;
}

// 分プルダウンリストを返す
export const GetMinuteList = (): string[] => {
    const res = [];
    for (let i = 0; i < 60; i++) {
        res.push(Zerofill(i, 2));
    }
    return res;
}

// 各種定数
export const SpeciesDog = "1";
export const SpeciesCat = "2";

export const BirthTypeConfirmed = "1";
export const BirthTypeEstimated = "2";
export const BirthTypeUnknown = "3";

// 人間の性別表記を返す
export const GetPersonGender = (gender: string): string => {

    switch (gender) {
        case "1":
            return "男性";
        case "2":
            return "女性";
        case "3":
            return "その他";
    }

    return gender;
}

// ペットの性別表記を返す
export const GetPetGender = (gender: string): string => {

    switch (gender) {
        case "1":
            return "オス";
        case "2":
            return "メス";
    }

    return gender;
}

// ペットの種別表記を返す
export const GetAnimalType = (animalType: string): string => {

    switch (animalType) {
        case SpeciesDog:
            return "犬";
        case SpeciesCat:
            return "猫";
    }

    return animalType;
}

// ペットの生年月日種別表記を返す
export const GetBirthdayType = (birthdayType: string): string => {

    switch (birthdayType) {
        case BirthTypeConfirmed:
            return "確定";
        case BirthTypeEstimated:
            return "推定";
        case BirthTypeUnknown:
            return "不明";
    }

    return birthdayType;
}

// 住所を連結して返す
export const GetAddressStr = (prefCode: string | null, city: string | null, street: string | null, building: string | null): string => {

    let address = ""
    if (prefCode && Prefectures[prefCode]) {
        address += Prefectures[prefCode];
    }
    if (city) {
        address += city;
    }
    if (street) {
        address += street;
    }
    if (building) {
        address += building;
    }

    return address;
};

// 検査結果メール送信対象のリストを返す
export const ResultSendTargets: { [key: string]: string } = {
    "0": "対象外",
    "1": "病院のみ通知",
    "2": "ユーザーのみ通知",
    "3": "両方通知",
};


export const PartnerInstDivisionMedical = "1";
export const PartnerInstDivisionPartner = "2";

// 住所を連結して返す
export const GetPartnerInstDivision = (division: string): string => {
    switch (division) {
        case PartnerInstDivisionMedical:
            return "病院";
        case PartnerInstDivisionPartner:
            return "提携法人";
    }

    return division;
}

// 住所を連結して返す
export const ShortenStr = (str: string, len: number): string => {
    if (str.length <= len) {
        return str;
    }
    return str.substring(0, len) + "...";
}

// 症状が発生した時期のリストを返す
export const GetSymptomsTimings = (): { [key: string]: string } => {
    return {
        "1": "3日以内",
        "2": "1週間以内",
        "3": "2週間以内",
        "4": "1ヶ月以内",
        "5": "1ヶ月以上前",
    }
};

// 泌乳経験リストを返す
// これだけ0、1ではなく、1、2であるなしを表現、。。
export const GetLactationExperience = (): { [key: string]: string } => {
    return {
        "2": "なし",
        "1": "あり",
    }
}

// 泌乳した子供の合計リストを返す
export const GetLactatedChildren = (): { [key: string]: string } => {

    const res: { [key: string]: string } = {};

    for (let i = 0; i <= 99; i++) {
        if (i === 0) {
            res[String(i)] = `不明`;
        } else {
            res[String(i)] = `${i}匹`;
        }
    }

    return res;
}

// 泌乳した期間の合計リストを返す
export const GetTotalLactationDuration = (): { [key: string]: string } => {

    const res: { [key: string]: string } = {};

    for (let i = 0; i <= 99; i++) {
        if (i === 0) {
            res[String(i)] = `不明`;
        } else {
            res[String(i)] = `${i}週間`;
        }
    }

    return res;
}

// 妊娠期間のリストを返す
export const GetPregnancyWeeks = (): { [key: string]: string } => {

    const res: { [key: string]: string } = {};

    for (let i = 0; i <= 99; i++) {
        if (i === 0) {
            res[String(i)] = `不明`;
        } else {
            res[String(i)] = `${i}日`;
        }
    }

    return res;
}

// 高血圧のリストを返す
export const GetHypertension = (): { [key: string]: string } => {
    return {
        "0": "なし",
        "1": "基礎疾患による高血圧",
        "2": "原因の判明していない高血圧",
    }
}

// 飼育環境リストを返す
export const GetEnvironments = (): { [key: string]: string } => {
    return {
        "1": "屋外",
        "2": "屋内",
        "3": "自由に行き来している",
    };
};

// 散歩の頻度リストを返す
export const GetFrequencyWalking = (): { [key: string]: string } => {
    return {
        "1": "毎日",
        "2": "週3〜4回",
        "3": "週1〜2回",
        "4": "月1〜2回",
        "5": "ほとんどしない",
    }
}

// 運動時間リストを返す
export const GetExerciseTime = (): { [key: string]: string } => {
    return {
        "1": "3時間以上",
        "2": "2〜3時間ぐらい",
        "3": "1〜2時間ぐらい",
        "4": "1時間未満",
        "5": "ほとんどしない",
    }
}

// うんちの回数リストを返す
export const GetFrequencyExcrement = (): { [key: string]: string } => {
    return {
        "1": "4回以上",
        "2": "1〜3回",
        "3": "1回するかしないか",
        "4": "2日以上空く",
    };
}

// ごはんの種類リストを返す
export const GetMainFood = (): { [key: string]: string } => {
    return {
        "1": "ドライフード",
        "2": "ウェットフード",
        "3": "手作り",
        "4": "その他",
    };
}

// ごはんの種類リストを返す
export const GetAppetite = (): { [key: string]: string } => {
    return {
        "1": "完食する",
        "2": "完食するが食いつきが悪い",
        "3": "食べ残す",
        "4": "安定しない",
    };
}


// 体型リストを返す
export const GetBodyFigure = (): { [key: string]: string } => {
    return {
        "1": "痩せている",
        "2": "やや痩せている",
        "3": "標準",
        "4": "やや肥満",
        "5": "肥満",
    };
}

// 攻撃性のリストを返す
export const GetAggression = (): { [key: string]: string } => {
    return {
        "1": "よく攻撃する",
        "2": "時々攻撃する",
        "3": "ほとんど攻撃しない",
    }
}

// 分離不安のリストを返す
export const GetUneasiness = (): Map<string, string> => {
    // オブジェクトでは順番が保証されないので、Mapを利用。
    const map = new Map();
    map.set("1", "ある");
    map.set("2", "時々ある");
    map.set("3", "どちらとも言えない");
    map.set("4", "あまりない");
    map.set("0", "ない");

    return map;
}

// ブラッシング頻度リストを返す
export const GetBrushing = (): Map<string, string> => {

    // オブジェクトでは順番が保証されないので、Mapを利用。
    const map = new Map();
    map.set("5", "ほとんどしない");
    map.set("3", "週1〜2回");
    map.set("2", "週3〜4回");
    map.set("4", "月1〜2回");
    map.set("1", "毎日");
    return map;
}

// シャンプー頻度リストを返す
export const GetShampoo = (): Map<string, string> => {

    // オブジェクトでは順番が保証されないので、Mapを利用。
    const map = new Map();
    map.set("4", "ほとんどしない");
    map.set("1", "週1〜2回");
    map.set("2", "月1〜2回");
    map.set("3", "2〜3ヶ月に1回");
    return map;
}

// GetYesNoUnknownStr ははい、いいえ、不明の文字列を返す
export const GetYesNoUnknownStr = (value: string | null): string => {
    switch (value) {
        case "1":
            return "はい";
        case "2":
            return "いいえ";
        case "0":
            return "不明";
    }

    return value ?? "-";
};

// GetYesNoStr ははい、いいえの文字列を返す
export const GetYesNoStr = (value: string | null): string => {
    switch (value) {
        case "1":
            return "はい";
        case "0":
            return "いいえ";
    }

    return value ?? "-";
};

// 皮膚トラブルを文字列化
export const GetSkinTroubleStr = (ppTestKit: TypesPpTestKitData): string => {

    const res = [];

    if (ppTestKit.is_skin_trouble_symptoms_itch === "1") {
        res.push("かゆみ");
    }

    if (ppTestKit.is_skin_trouble_symptoms_hairloss === "1") {
        res.push("脱毛");
    }

    if (ppTestKit.is_skin_trouble_symptoms_sore === "1") {
        res.push("赤み");
    }

    if (ppTestKit.is_skin_trouble_symptoms_rash === "1") {
        res.push("発疹");
    }

    if (ppTestKit.is_skin_trouble_symptoms_dry === "1") {
        res.push("乾燥");
    }

    if (ppTestKit.is_skin_trouble_symptoms_dandruff === "1") {
        res.push("フケ");
    }

    if (ppTestKit.is_skin_trouble_symptoms_other === "1") {
        res.push(`その他（${ppTestKit.is_skin_trouble_symptoms_comment}）`);
    }

    return res.join("、");
}

// 皮膚疾患の文字列化
export const GetSkinDiseaseStr = (ppTestKit: TypesPpTestKitData): string => {
    const res = [];

    if (ppTestKit.is_skin_disease_pyoderma === "1") {
        res.push("膿皮症");
    }

    if (ppTestKit.is_skin_disease_seforrhoea === "1") {
        res.push("脂漏症");
    }

    if (ppTestKit.is_skin_disease_external_otitisis === "1") {
        res.push("外耳炎");
    }

    if (ppTestKit.is_skin_disease_eczema === "1") {
        res.push("皮膚炎（原因が判明していない）");
    }

    if (ppTestKit.is_skin_disease_other === "1") {
        res.push(`その他（${ppTestKit.is_skin_disease_comment}）`);
    }

    return res.join("、");

};

// 心臓疾患の文字列化
export const GetHeartDiseaseStr = (ppTestKit: TypesPpTestKitData): string => {
    const res = [];

    if (ppTestKit.is_heart_disease_mr === "1") {
        res.push("僧帽弁閉鎖不全症");
    }

    if (ppTestKit.is_heart_disease_cardiomyopathy === "1") {
        res.push("心筋症");
    }

    if (ppTestKit.is_heart_disease_ph === "1") {
        res.push("肺高血圧症");
    }

    if (ppTestKit.is_heart_disease_heart_failure === "1") {
        res.push("心不全（原因が判明していない）");
    }

    if (ppTestKit.is_heart_disease_other === "1") {
        res.push(`その他（${ppTestKit.is_heart_disease_comment}）`);
    }

    return res.join("、");
};

// 腎臓疾患の文字列化
export const GetKidneyDiseaseStr = (ppTestKit: TypesPpTestKitData): string => {
    const res = [];

    if (ppTestKit.is_kidney_disease_ckd === "1") {
        res.push("慢性腎不全");
    }

    if (ppTestKit.is_kidney_disease_pln === "1") {
        res.push("蛋白漏出性腎症");
    }

    if (ppTestKit.is_kidney_disease_nephritis === "1") {
        res.push("腎炎");
    }

    if (ppTestKit.is_kidney_disease_kidney_stone === "1") {
        res.push("腎結石");
    }

    if (ppTestKit.is_kidney_disease_other === "1") {
        res.push(`その他（${ppTestKit.is_kidney_disease_comment}）`);
    }

    return res.join("、");
};

// 泌尿器疾患の文字列化
export const GetUrologyDiseaseStr = (ppTestKit: TypesPpTestKitData): string => {
    const res = [];

    if (ppTestKit.is_urology_disease_idiopathic_cystitis === "1") {
        res.push("特発性膀胱炎");
    }

    if (ppTestKit.is_urology_disease_other_cystitis === "1") {
        res.push("細菌性/その他膀胱炎");
    }

    if (ppTestKit.is_urology_disease_bladder_stone === "1") {
        res.push("膀胱結石");
    }

    if (ppTestKit.is_urology_disease_other === "1") {
        res.push(`その他（${ppTestKit.is_urology_disease_comment}）`);
    }

    return res.join("、");
};

// 整形疾患の文字列化
export const GetOrthopedicDiseaseStr = (ppTestKit: TypesPpTestKitData): string => {
    const res = [];

    if (ppTestKit.is_orthopedic_disease_patella === "1") {
        res.push("膝蓋骨脱臼");
    }

    if (ppTestKit.is_orthopedic_disease_hd === "1") {
        res.push("股関節異形成");
    }

    if (ppTestKit.is_orthopedic_disease_arthritis === "1") {
        res.push("関節炎");
    }

    if (ppTestKit.is_orthopedic_disease_other === "1") {
        res.push(`その他（${ppTestKit.is_orthopedic_disease_comment}）`);
    }

    return res.join("、");
};

// 脳疾患/脊髄疾患の文字列化
export const GetBrainDiseaseStr = (ppTestKit: TypesPpTestKitData): string => {
    const res = [];

    if (ppTestKit.is_brain_spinal_disease_epilepsy === "1") {
        res.push("てんかん");
    }

    if (ppTestKit.is_brain_spinal_disease_herniated_disc === "1") {
        res.push("椎間板ヘルニア");
    }

    if (ppTestKit.is_brain_spinal_disease_brain_disease === "1") {
        res.push("脳疾患（原因が判明していない）");
    }

    if (ppTestKit.is_brain_spinal_disease_other === "1") {
        res.push(`その他（${ppTestKit.is_brain_spinal_disease_comment}）`);
    }

    return res.join("、");
};

// 脳疾患/脊髄疾患の文字列化
export const GetRespiratoryDiseaseStr = (ppTestKit: TypesPpTestKitData): string => {
    const res = [];

    if (ppTestKit.is_respiratory_disease_tracheal_collapse === "1") {
        res.push("気管虚脱");
    }

    if (ppTestKit.is_respiratory_disease_bronchitis === "1") {
        res.push("気管支炎");
    }

    if (ppTestKit.is_respiratory_disease_asthma === "1") {
        res.push("喘息");
    }

    if (ppTestKit.is_respiratory_disease_rhinitis === "1") {
        res.push("鼻炎");
    }

    if (ppTestKit.is_respiratory_disease_other === "1") {
        res.push(`その他（${ppTestKit.is_respiratory_disease_comment}）`);
    }

    return res.join("、");
};

// アレルギーの文字列化
export const GetAllergyDiseaseStr = (ppTestKit: TypesPpTestKitData): string => {
    const res = [];

    if (ppTestKit.is_allergy_food_skin === "1") {
        res.push("食物アレルギー（皮膚）");
    }

    if (ppTestKit.is_allergy_food_digestive_organ === "1") {
        res.push("食物アレルギー（消化器）");
    }

    if (ppTestKit.is_allergy_other === "1") {
        res.push(`その他（${ppTestKit.is_allergy_comment}）`);
    }

    return res.join("、");
};

// 消化器疾患の文字列化
export const GetDigestiveDiseaseStr = (ppTestKit: TypesPpTestKitData): string => {
    const res = [];

    if (ppTestKit.is_digestive_disease_ibd === "1") {
        res.push("慢性腸症/炎症性腸疾患");
    }

    if (ppTestKit.is_digestive_disease_pancreatitis === "1") {
        res.push("膵炎");
    }

    if (ppTestKit.is_digestive_disease_pei === "1") {
        res.push("膵外分泌不全");
    }

    if (ppTestKit.is_digestive_disease_other === "1") {
        res.push(`その他（${ppTestKit.is_digestive_disease_comment}）`);
    }

    return res.join("、");
};

// 肝・胆道疾患の文字列化
export const GetLiverDiseaseStr = (ppTestKit: TypesPpTestKitData): string => {
    const res = [];

    if (ppTestKit.is_liver_bile_disease_liver_damage === "1") {
        res.push("肝障害");
    }

    if (ppTestKit.is_liver_bile_disease_biliary_sludge === "1") {
        res.push("胆泥症");
    }

    if (ppTestKit.is_liver_bile_disease_gallbladder_mucocele === "1") {
        res.push("胆嚢粘液嚢腫");
    }

    if (ppTestKit.is_liver_bile_disease_other === "1") {
        res.push(`その他（${ppTestKit.is_liver_bile_disease_comment}）`);
    }

    return res.join("、");
};

// ホルモン・代謝異常の文字列化
export const GetHormoneDiseaseStr = (ppTestKit: TypesPpTestKitData): string => {
    const res = [];

    if (ppTestKit.is_hormone_metabolism_diabetes === "1") {
        res.push("糖尿病");
    }

    if (ppTestKit.is_hormone_metabolism_cushing_syndrome === "1") {
        res.push("クッシング症候群");
    }

    if (ppTestKit.is_hormone_metabolism_dl === "1") {
        res.push("高脂血症");
    }

    if (ppTestKit.is_hormone_metabolism_other === "1") {
        res.push(`その他（${ppTestKit.is_hormone_metabolic_disorder_comment}）`);
    }

    return res.join("、");
};

// 眼科疾患の文字列化
export const GetEyeDiseaseStr = (ppTestKit: TypesPpTestKitData): string => {
    const res = [];

    if (ppTestKit.is_eye_disease_cataract === "1") {
        res.push("白内障");
    }

    if (ppTestKit.is_eye_disease_glaucoma === "1") {
        res.push("緑内障");
    }

    if (ppTestKit.is_eye_disease_dry_eyes === "1") {
        res.push("乾性核結膜炎（ドライアイ）");
    }

    if (ppTestKit.is_eye_disease_other === "1") {
        res.push(`その他（${ppTestKit.is_eye_disease_comment}）`);
    }

    return res.join("、");
};

// 具体的な食べ物に関する文字列を返す
export const GetFoodStr = (ppTestKit: TypesPpTestKitData): string => {
    const res = [];

    if (ppTestKit.is_homemade_mainfood_type_meat === "1") {
        res.push("肉");
    }

    if (ppTestKit.is_homemade_mainfood_type_fish === "1") {
        res.push("魚");
    }

    if (ppTestKit.is_homemade_mainfood_type_vegetable === "1") {
        res.push("野菜");
    }

    if (ppTestKit.is_homemade_mainfood_type_fruit === "1") {
        res.push("果物");
    }

    if (ppTestKit.is_homemade_mainfood_type_other === "1") {
        res.push(`その他（${ppTestKit.is_homemade_mainfood_type_comment}）`);
    }

    return res.join("、");
}

// 気になることの文字列化
export const GetWorryStr = (ppTestKit: TypesPpTestKitData): string => {
    const res = [];

    if (ppTestKit.is_worry_nothing === "1") {
        res.push("特になし");
    }

    if (ppTestKit.is_worry_bad_hair === "1") {
        res.push("毛ヅヤが悪い気がする");
    }

    if (ppTestKit.is_worry_bad_breath === "1") {
        res.push("口臭が気になる");
    }

    if (ppTestKit.is_worry_body_odor === "1") {
        res.push("体臭が気になる");
    }

    if (ppTestKit.is_worry_eat_excrement === "1") {
        res.push("食糞する");
    }

    if (ppTestKit.is_worry_increased_salivation === "1") {
        res.push("よだれが垂れる");
    }

    if (ppTestKit.is_worry_smell_of_excrement === "1") {
        res.push("便が臭い");
    }

    if (ppTestKit.is_worry_dental_tartar === "1") {
        res.push("歯石が気になる");
    }

    if (ppTestKit.is_worry_frequent_urination === "1") {
        res.push("おしっこの回数が多い");
    }

    if (ppTestKit.is_worry_itchy_ears === "1") {
        res.push("耳を痒がっている");
    }

    if (ppTestKit.is_worry_smell_of_ears === "1") {
        res.push("耳の匂いが気になる");
    }

    if (ppTestKit.is_worry_weight_easily === "1") {
        res.push("体重が増えやすい");
    }

    if (ppTestKit.is_worry_lost_weight === "1") {
        res.push("体重が増えにくい/痩せている");
    }

    if (ppTestKit.is_worry_warts === "1") {
        res.push("イボがある");
    }

    if (ppTestKit.is_worry_any_other === "1") {
        res.push(`その他（${ppTestKit.is_worry_any_other_comment}）`);
    }

    return res.join("、");

}

// 検査区分の取得
export const GetTestDivision = (): { [key: string]: string } => {
    return {
        "1": "犬腸内検査",
        "2": "猫腸内検査",
    };
};


// 状況（管理側）の取得
export const GetTestStatus = (): Map<string, string> => {
    // 00：検査未依頼、01：検査未受付、02：検査中、12：再検査中、22：強制検査中、50：検査完了
    const map = new Map();
    map.set("00", "検査未依頼");
    map.set("01", "検査未受付");
    map.set("02", "検査中");
    map.set("12", "再検査中");
    map.set("22", "強制検査中");
    map.set("50", "検査完了");
    return map;
};

// 状況（ユーザー側）の取得
export const GetTestStatusForUser = (): Map<string, string> => {
    // 1：検査依頼済み、2：検査中、3：検査完了
    const map = new Map();
    map.set("1", "検査依頼済み");
    map.set("2", "検査中");
    map.set("3", "検査完了");
    return map;
};

// 体重の選択肢1を返す
export const GetWeight1 = (): string[] => {
    const res = [];
    for (let i = 0; i <= 99; i++) {
        res.push(String(i));
    }
    return res;
};

// 体重の選択肢2を返す
export const GetWeight2 = (): string[] => {
    const res = [];
    for (let i = 0; i <= 9; i++) {
        res.push(String(i));
    }
    return res;
};

// 検体IDの書式チェック
export const CheckSampleId = (sampleId: string): boolean => {
    const reg = /^P[C|D][A-Z0-9]{2}-[0-9]{4}-NGS-[0-9]{4}$/;
    return reg.test(sampleId);
};

// 検体位置のリストを返す
export const GetPlatePositions = () => {
    return [
        "A1",
        "B1",
        "C1",
        "D1",
        "E1",
        "F1",
        "G1",
        "H1",
        "A2",
        "B2",
        "C2",
        "D2",
        "E2",
        "F2",
        "G2",
        "H2",
        "A3",
        "B3",
        "C3",
        "D3",
        "E3",
        "F3",
        "G3",
        "H3",
        "A4",
        "B4",
        "C4",
        "D4",
        "E4",
        "F4",
        "G4",
        "H4",
        "A5",
        "B5",
        "C5",
        "D5",
        "E5",
        "F5",
        "G5",
        "H5",
        "A6",
        "B6",
        "C6",
        "D6",
        "E6",
        "F6",
        "G6",
        "H6",
        "A7",
        "B7",
        "C7",
        "D7",
        "E7",
        "F7",
        "G7",
        "H7",
        "A8",
        "B8",
        "C8",
        "D8",
        "E8",
        "F8",
        "G8",
        "H8",
        "A9",
        "B9",
        "C9",
        "D9",
        "E9",
        "F9",
        "G9",
        "H9",
        "A10",
        "B10",
        "C10",
        "D10",
        "E10",
        "F10",
        "G10",
        "H10",
        "A11",
        "B11",
        "C11",
        "D11",
        "E11",
        "F11",
        "G11",
        "H11",
        "A12",
        "B12",
        "C12",
        "D12",
        "E12",
        "F12",
    ];
};


// ステータスが受付対象かチェックする
export const CheckIsReceptionOK = (status: PpteststatusStatus | null): boolean => {
    // A004-12 5.検査未依頼、検査未受付の場合は受付対象
    return status === PpteststatusStatus.Status00 || status === PpteststatusStatus.Status01;
};

// リスク値を渡して顔アイコンを返す
export const GetRiskFaceIcon = (risk: number): string => {
    if (risk < 20) {
        return faceA;
    }
    if (risk < 40) {
        return faceB;
    }
    if (risk < 60) {
        return faceC;
    }
    if (risk < 80) {
        return faceD;
    }
    return faceE;
}

// 数字文字列をまるめて返す
export const Round = (v: string | undefined): number => {
    return Math.round(Number(v ?? "0"));
}

// 日付の簡易的チェック
export const IsValidDate = (str: string) => {
    return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(str);
};

// Flow Cellのステータス文字列表現を返す
export const GetFlowcellStatusStr = (status: PpflowcellStatus): string => {

    switch (status) {
        case PpflowcellStatus.Status0:
            return "未使用";
        case PpflowcellStatus.Status1:
            return "検査中";

        case PpflowcellStatus.Status2:
            return "検査完了";
        default:
            return `不明${String(status)}`;
    }

}

// リコメンド要否区分のリストを返す
export const GetRecommendTypes = (): { [key: string]: string } => {
    return {
        "0": "商品おすすめなし",
        "1": "腸内環境タイプ別商品おすすめ",
        "2": "腸内環境タイプ共通商品おすすめ",
    };
}

// 腸内環境のリストを返す
export const GetIntestinalEnvironmentTypes = (): { [key: string]: string } => {
    return {
        "A": "現状維持型",
        "B": "多様性補強型",
        "C": "善玉菌補強型",
        "D": "多様性／善玉菌補強型",
        "Z": "腸内環境タイプ不問",
    };
}

// アクセス権限のリストを返す
export const GetAuthorityLevels = (): { [key: string]: string } => {
    return {
        "1": "権限レベル1(営業担当)",
        "2": "権限レベル2(支部)",
        "3": "権限レベル3(全社統括)",
    };
}

// 指定した範囲の乱数を返す
export const GetRandomNumber = (min: number, max: number): number => {
    // maxは含まないので注意
    return Math.floor(Math.random() * (max - min)) + min;
}

// ランダムな文字列を指定桁数で作成
export const GetRandomStr = (digits: number): string => {
    const chars = "ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789";

    let str = "";

    for (let i = 0; i < digits; i++) {
        const o = GetRandomNumber(0, chars.length);
        str += chars[o];
    }

    return str;
}

// レコメンド表示パターン
// 0：表示なし、1：汎用のみ、2：個別おすすめ商品のみ(横表示)、3：個別おすすめ商品のみ(縦表示)、4：汎用＋個別おすすめ商品(横表示)、5：汎用＋個別おすすめ商品(縦表示)、6：個別おすすめ商品のみ(縦表示＋横表示)、7：フル表示
export const RcmndDisplayPattern: { id: string, name: string }[] = [
    {
        id: "0",
        name: "表示なし",
    },
    {
        id: "1",
        name: "汎用のみ",
    },
    {
        id: "2",
        name: "個別おすすめ商品のみ(横表示)",
    },
    {
        id: "3",
        name: "個別おすすめ商品のみ(縦表示)",
    },
    {
        id: "4",
        name: "汎用＋個別おすすめ商品(横表示)",
    },
    {
        id: "5",
        name: "汎用＋個別おすすめ商品(縦表示)",
    },
    {
        id: "6",
        name: "個別おすすめ商品のみ(縦表示＋横表示)",
    },
    {
        id: "7",
        name: "フル表示",
    },
];

// リコメンド表示パターンの文字列を返す
export const GetRcmndDisplayPatternStr = (d: string): string => {
    let res = "-";
    RcmndDisplayPattern.forEach((v) => {
        if (v.id === d) {
            res = `${v.id}：${v.name}`;
        }
    });
    return res;
}

// 汎用リコメンド表示方式 1：個別に表示、2：グループ単位にまとめて表示
export const GenericRcmndDisplayMethod: { id: string, name: string }[] = [
    {
        id: "1",
        name: "個別に表示",
    },
    {
        id: "2",
        name: "グループ単位にまとめて表示",
    },
];

// 汎用リコメンド表示方式の文字列を返す
export const GetGenericRcmndDisplayMethodStr = (d: string): string => {
    let res = "-";
    GenericRcmndDisplayMethod.forEach((v) => {
        if (v.id === d) {
            res = `${v.id}：${v.name}`;
        }
    });
    return res;
}

// 個別商品リコメンドタイトル表示箇所
// 1：おすすめ商品_縦、2：おすすめ商品_横1段目、3：おすすめ商品_横2段目、4：おすすめ商品_横3段目
export const RcmndTitleDisplayLocation: { id: string, name: string }[] = [
    {
        id: "1",
        name: "おすすめ商品_縦",
    },
    {
        id: "2",
        name: "おすすめ商品_横1段目",
    },
    {
        id: "3",
        name: "おすすめ商品_横2段目",
    },
    {
        id: "4",
        name: "おすすめ商品_横3段目",
    },
];

// 個別商品リコメンドタイトル表示箇所の文字列を返す
export const GetRcmndTitleDisplayLocationStr = (d: string): string => {
    let res = "-";
    RcmndTitleDisplayLocation.forEach((v) => {
        if (v.id === d) {
            res = `${v.id}：${v.name}`;
        }
    });
    return res;
}

// 対策コメント、対象カテゴリ
export const MeasureCategories: { id: string, name: string }[] = [
    {
        id: "1",
        name: "多様性スコア",
    },
    {
        id: "2",
        name: "善玉菌・悪玉菌スコア",
    },
    {
        id: "3",
        name: "類似度スコア",
    },
];

// 対策コメント、対象カテゴリ
export const MeasureCommentPositions: { id: string, name: string }[] = [
    {
        id: "001",
        name: "有害菌情報画面吹き出し",
    },
    {
        id: "100",
        name: "詳細_見出し1",
    },
    {
        id: "101",
        name: "詳細_テキスト1-1",
    },
    {
        id: "200",
        name: "詳細_見出し2",
    },
    {
        id: "210",
        name: "詳細_小見出し2-1",
    },
    {
        id: "211",
        name: "詳細_テキスト2-1-1",
    },
    {
        id: "220",
        name: "詳細_小見出し2-2",
    },
    {
        id: "221",
        name: "詳細_テキスト2-2-1",
    },
    {
        id: "300",
        name: "詳細_見出し3",
    },
    {
        id: "310",
        name: "詳細_小見出し3-1",
    },
    {
        id: "311",
        name: "詳細_テキスト3-1-1",
    },
];

// 対策コメント、対象カテゴリ の文字列を返す
export const GetMeasureCategory = (mc: string): string => {
    const res = MeasureCategories.filter((v) => v.id === mc);
    if (res.length ===0) {
        return "";
    }
    return res[0].name;
}

// 提携法人ユーザーのアクセス権限
export const PartnerAccesses: { id: string, name: string }[] = [
    {
        id: "1",
        name: "一般提携法人ユーザー",
    },
    {
        id: "2",
        name: "研究機関ユーザー",
    },
    {
        id: "3",
        name: "顧問研究者ユーザー",
    },
];
