import {Layout} from "../common/Layout";
import {SearchForm} from "./SearchForm";
import {useLocation} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {AwscloudwatchLogMessage, LogControllerIndexRequest} from "../../generated";
import {adminLogApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";
import {H3} from "../common/Headline";
import {Table} from "../common/Table";
import {HyphenDtFromTimeStamp, SlashedDtFromTimeStamp, Zerofill} from "../../utility/Utility";
import styled from "styled-components";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "../common/Button";
import {AxiosRequestConfig} from "axios/index";

export const LogsIndex = () => {

    const location = useLocation();
    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [logs, setLogs] = useState<AwscloudwatchLogMessage[]>([]);
    const [hideNext, setHideNext] = useState<boolean>(true);

    useEffect(() => {
        console.log(location.search);

        if (location.search !== "") {

            // 既存データをクリア
            setLogs([]);

            const params = new URLSearchParams(location.search);
            const from = params.get("from") ?? "";
            const to = params.get("to") ?? "";
            onSearch(from, to, false, false);
        }

    }, [location.search]);

    const onSearch = (from: string, to: string, isCsv: boolean, append: boolean) => {

        console.log(from, to);

        setShowSpinner(true);

        const req: LogControllerIndexRequest = {
            limit: 100,
            from: from,
            to: to,
            csv: isCsv,
        };

        const options: AxiosRequestConfig = {};

        if (isCsv) {
            options.responseType = "blob";
        }

        adminLogApi.v1AdminLogPost(req, options)
            .then((res) => {
                if (isCsv) {
                    const data = res.data as never;
                    const blob = new Blob([data], {type: "text/csv"});
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    const dt = new Date();
                    const today = `${dt.getFullYear()}${Zerofill(dt.getMonth() + 1, 2)}${Zerofill(dt.getDate(), 2)}`;
                    a.download = `ログ-${today}.csv`;
                    a.click();
                } else {
                    if (append) {
                        setLogs([...logs, ...res.data.logs]);
                    } else {
                        setLogs(res.data.logs);
                    }

                    if (res.data.logs.length === 0) {
                        setHideNext(true);
                    } else {
                        setHideNext(false);
                    }
                }
            })
            .catch(({response}) => {

                if (response.status === 406) {
                    // バリデーションエラー
                    setDangerMessage("検索条件の指定が正しくありません。");
                } else if (response.data.message) {
                    setDangerMessage(response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };


    const onClear = () => {
        console.log("onClear");
    }

    const onNext = (e: React.MouseEvent<HTMLButtonElement>): void => {

        e.preventDefault();

        // 次を読み込みという概念がないので、つまり時間をずらして検索する、ということになる。
        // 次の検索時の終了は最後のタイムスタンプの1秒前とする
        const nextTo = HyphenDtFromTimeStamp(logs[logs.length - 1].timestamp - 1);
        const params = new URLSearchParams(location.search);
        const from = params.get("from") ?? "";
        onSearch(from, nextTo, false, true);
    };

    const onClickDownload = (e: React.MouseEvent<HTMLButtonElement>) => {

        e.preventDefault();

        const params = new URLSearchParams(location.search);
        const from = params.get("from") ?? "";
        const to = params.get("to") ?? "";
        onSearch(from, to, true, false);

    };

    return <Layout title="ログ表示">

        <SearchForm onClear={onClear}/>

        <StyledLogIndex className="box">
            <H3 title="ログ一覧" subtitle="list">
                <Button
                    disabled={logs.length === 0}
                    color={logs.length > 0 ? ButtonColor.Orange : ButtonColor.Gray}
                    size={ButtonSize.Small}
                    icon={ButtonIcon.Arrow}
                    onClick={onClickDownload}
                    type="button">
                    CSVダウンロード
                </Button>
            </H3>

            <Table>
                <thead>
                <tr>
                    <th>日時</th>
                    <th>作業者名</th>
                    <th>論理処理名</th>
                    <th>リクエストURI</th>
                    <th>作業種別</th>
                    <th>ステータス</th>
                    <th>IPアドレス</th>
                </tr>
                </thead>

                <tbody>

                {logs.map((d, i) => {
                    return <tr key={`log-${i}`} className={d.status !== 200 ? "error" : ""}>
                        <td>{SlashedDtFromTimeStamp(d.timestamp)}</td>
                        <td>{d.user}</td>
                        <td>{d.logical_name}</td>
                        <td>{d.request_uri}</td>
                        <td>{d.type}</td>
                        <td>{d.status}</td>
                        <td>{d.ip_address}</td>
                    </tr>
                })}

                </tbody>

            </Table>

            {!hideNext && <div className="text-center">
                <Button type="button" color={ButtonColor.Green} onClick={onNext}>さらに読み込む</Button>
            </div>}

        </StyledLogIndex>

    </Layout>

}


const StyledLogIndex = styled.div`

  tr.error {

    td:nth-child(6) {
      font-weight: 500;
      color: red;
    }

  }

`;