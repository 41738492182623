import {useContext, useEffect, useState} from "react";
import {PpMstRcmndMethodModelGetListResponse} from "../generated";
import {adminPpMstRcmndMethodApi} from "../api/Api";
import {AppContext} from "../contexts/AppContext";

export const useRcmndMethod = (exclude: boolean) => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const [rcmndMethods, setRcmndMethods] = useState<PpMstRcmndMethodModelGetListResponse[]>([]);

    useEffect(() => {

        setShowSpinner(true);

        adminPpMstRcmndMethodApi
            .v1AdminPpMstRcmndMethodListGet(exclude ? "1" : "0")
            .then(({data}) => {
                setRcmndMethods(data.data);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });

    }, []);


    return rcmndMethods;
}