import styled from "styled-components";
import downGreen from "../../images/down-green.svg";
import radioActive from "../../images/radio-active.svg";
import checkActive from "../../images/check-active.svg";
import {MainColor} from "./Colors";

export const StyledForm = styled.form`

  .form-wrapper {
    .form-group {
      &:nth-child(odd) {
        background-color: rgba(255, 167, 11, .1);
      }
    }

    // ネストしたform-wrapper
    .form-wrapper {
      .form-group {
        &:nth-child(odd) {
          background-color: #fff;
        }

        &:nth-child(even) {
          background-color: rgba(255, 167, 11, 0.1);
        }
      }

    }

  }

  .form-group {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px 15px;

    &.check-area {
      padding: 23px 15px;

      > label {
        padding-top: 0;

        &.req:after {
          top: 3px;
        }
      }
    }

    &.confirm {
      padding: 28px 15px;

      // 確認時はパディングを削除
      > label {
        padding-top: 0;

        &:after {
          right: 0;
          top: -2px !important;
        }
      }

      > div {
        font-size: 16px;
        line-height: 1.8;
      }
    }

    &.center {
      align-items: center;
      padding: 20px 15px;

      // 確認時はパディングを削除
      > label {
        padding-top: 0;

        &.req:after {
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      > div {
        line-height: 1.8;
      }

    }

    > label {
      width: 180px;
      font-size: 14px;
      margin-right: 25px;
      padding: 10px 35px 0 0;
      white-space: nowrap;
      line-height: 18px;

      small {
        display: block;
        font-size: 12px;
        margin-top: 4px;
        line-height: 14px;

        &.ex-small {
          font-size: 10px;
          line-height: 14px;
          white-space: normal;
        }
      }

      &.req {
        position: relative;

        &:after {
          content: "必須";
          position: absolute;
          background-color: #FF9871;
          color: #fff;
          border-radius: 4px;
          right: 0;
          top: 9px;
          font-size: 10px;
          padding: 1px 5px 0 5px;
          line-height: 18px;
        }
      }
    }

    > div {
      flex: 1;
      width: 100%;
      position: relative;

      // メタデータフォームの多段2カラム
      .column-2 {
        display: flex;
        align-items: center;

        .column {

          &:first-child {
            min-width: 170px;
          }


          &:last-child {
            position: relative;

            &:before {
              content: "";
              border-left: 2px solid #fff;
              display: block;
              width: 1px;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
            }
          }

          .inline {
            > label {
              padding-left: 20px;
              width: 135px;
              font-size: 14px;
            }
          }
        }
      }

    }

    // ヒント
    .hint {
      padding-top: 7px;
      font-size: 9px;
      opacity: .8;
      line-height: 1.3;
    }

    .hint2 {
      padding-top: 7px;
      font-size: 12px;
      color: #333333;
      line-height: 1.3;
    }

    // ヒント（バルーン式）
    .hint-balloon {
      background-color: rgba(255, 167, 11, 0.1);
      font-size: 8px;
      line-height: 1.8;
      padding: 16px;
      border-radius: 8px;
      position: relative;

      br {
        font-size: inherit;
      }

      span {
        font-size: inherit;
        color: #D93E4C;
        line-height: inherit;
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: -30px;
        margin-top: -15px;
        border: 15px solid transparent;
        border-right: 15px solid rgba(255, 167, 11, 0.1);
      }
    }

    // インライン
    .inline {
      display: flex;
      align-items: center;

      label {
        font-size: 14px;

        small {
          font-size: 12px;
          line-height: 12px;

          br {
            line-height: 12px;
          }
        }
      }

      span {
        font-size: 14px;
      }

      input, select {
        width: auto;
        min-width: auto;
      }

      span, input, select {
        margin-right: 10px;
      }
    }

    // チェックボックス、ラジオボタン
    .check {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      label {
        margin-right: 25px;
        display: flex;
        align-items: center;
        font-size: 14px;

        input {
          margin-right: 7px;
        }
      }
    }
  }

  // ボタンエリア
  .btn-area {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 56px;

    button {
      margin: 0 33px 0 0;

      &:last-child {
        margin-right: 0;
      }
    }

  }


  // 検索フォーム
  // 検索フォームでは、若干スタイルを変更する
  .search-form {

    .form-group {
      background-color: transparent;
      padding: 10px 15px;

      label {
        width: 150px;
        padding: 10px 10px 0 0;
      }
    }

    .btn-area {
      margin-top: 35px;
      padding: 0;
    }
  }


  // アップロードした画像のリンク
  .filename {
    padding: 10px 0;
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      color: #FF9871;
      cursor: pointer;
      width: auto;

      &:hover {
        text-decoration: underline;
      }
    }

    .btn-delete {
      margin-left: 20px;
      border-radius: 20px;
      font-size: 12px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .upload-none {
    padding: 10px 0;
    font-size: 14px;
    color: #aaa;
  }

  // テキスト一行
  input[type=text], input[type=number], input[type=tel], input[type=email], input[type=date], input[type=password] {
    height: 44px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 8px;
    width: 300px;
    padding: 0 8px;
    font-size: 14px;

    &::placeholder {
      color: #aaa;
    }

    &.short {
      width: 150px;
    }

    &.long {
      width: 100%;
    }

    &.medium {
      width: 50%;
    }

    &:disabled {
      background-color: #ddd;
      opacity: .8;
    }
  }

  // プルダウン
  select {
    appearance: none;
    padding: 0 30px 0 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 8px;
    height: 44px;
    background-image: url(${downGreen});
    background-repeat: no-repeat;
    background-position: top 50% right 10px;
    font-size: 14px;
    line-height: 44px;

    &:disabled {
      opacity: .5;
    }
  }

  // テキストエリア
  textarea {
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 8px;
    padding: 8px;
    height: 200px;
    resize: vertical;
    font-size: 14px;

    &::placeholder {
      color: #aaa;
    }

    &:disabled {
      opacity: .5;
    }
  }

  // ラジオボタン
  input[type=radio] {
    appearance: none;
    border: 1px solid #ccc;
    width: 16px !important;
    height: 16px !important;
    border-radius: 50%;
    background-color: #fff;

    &:checked {
      border-color: ${MainColor};
      background-image: url(${radioActive});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 10px auto;
    }

    &:disabled {
      opacity: .5;
    }
  }

  // チェックボックス
  input[type=checkbox] {
    appearance: none;
    border: 1px solid #ccc;
    width: 16px !important;
    height: 16px !important;
    border-radius: 3px;
    background-color: #fff;

    &:checked {
      background-image: url(${checkActive});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 12px auto;
    }

    &:disabled {
      opacity: .5;
    }
  }
  
  // エラー時はエラーのあったフォーム項目にhas-errorを付与
  .has-error {
    background-color: #F7D7DA !important;
  }

`;