import {Layout} from "../common/Layout";
import React, {useContext, useEffect, useState} from "react";
import {adminPpMstMeatureCommentApi} from "../../api/Api";
import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../contexts/AppContext";
import {PpMstMeatureCommentsControllerCreateRequest, TypesPpMstMeatureCommentSingleData} from "../../generated";
import {H3} from "../common/Headline";
import {StyledForm} from "../common/StyledForm";
import styled from "styled-components";
import {MeasureCategories, MeasureCommentPositions} from "../../utility/Utility";
import {ErrorMessage} from "../common/ErrorMessage";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";

export const PpMstMeatureCommentsEdit = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();
    const [ppMstMeatureComment, setPpMstMeatureComment] = useState<TypesPpMstMeatureCommentSingleData | null>(null);
    const [measureCategory, setMeasureCategory] = useState<string>("");
    const [measureCommentPositionId, setMeasureCommentPositionId] = useState<string>("");
    const [measureCommentText, setMeasureCommentText] = useState<string>("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {

        if (params.measureCategory) {
            // IDが指定されている場合は、データを取得する
            getData(params.measureCategory ?? "");
        }

    }, [params]);

    const getData = (measureCategory: string) => {

        setShowSpinner(true);

        adminPpMstMeatureCommentApi
            .v1AdminPpMstMeatureCommentGetIdGet(measureCategory)
            .then(({data}) => {
                const entity = data.pp_mst_meature_comment;
                setPpMstMeatureComment(entity);
                setMeasureCategory(entity.measure_category);
                setMeasureCommentPositionId(entity.measure_comment_position_id);
                setMeasureCommentText(entity.measure_comment_text);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                    navigate("/pp_mst_meature_comments");
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {

        switch (e.currentTarget.name) {
            case "measure_category":
                setMeasureCategory(e.currentTarget.value);
                break;
            case "measure_comment_position_id":
                setMeasureCommentPositionId(e.currentTarget.value);
                break;
            case "measure_comment_text":
                setMeasureCommentText(e.currentTarget.value);
                break;
        }
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setErrors({});
        setShowSpinner(true);

        const req: PpMstMeatureCommentsControllerCreateRequest = {
            measure_category: measureCategory,
            measure_comment_position_id: measureCommentPositionId,
            measure_comment_text: measureCommentText,
        };

        let promise: Promise<any>;

        if (ppMstMeatureComment) {
            // 編集時
            promise = adminPpMstMeatureCommentApi
                .v1AdminPpMstMeatureCommentEditIdPost(String(ppMstMeatureComment.id), req)
                .then(() => {
                    navigate(`/pp_mst_meature_comments?complete=edit`);
                });
        } else {
            // 新規作成
            promise = adminPpMstMeatureCommentApi
                .v1AdminPpMstMeatureCommentCreatePost(req)
                .then(() => {
                    navigate(`/pp_mst_meature_comments?complete=create`);
                });
        }

        promise
            .catch((err) => {
                if (err.response.status === 406) {
                    // バリデーションエラー
                    setDangerMessage("ご入力内容にエラーがありました。");
                    setErrors(err.response.data);
                } else if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    }

    // 前の画面に戻る
    const onClickBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigate(-1);
    };

    return <Layout title={"対策コメント管理"}>

        <StyledPpMstMeatureCommentEdit onSubmit={onSubmit}>

            <div className="box">

                <H3 title="コメント情報" subtitle="data"/>

                <div className="form-wrapper">

                    <div className="form-group">
                        <label className="req">対象カテゴリ</label>
                        <div>
                            <select name="measure_category" value={measureCategory} onChange={onChange}>
                                <option value="">---選択してください</option>
                                {MeasureCategories.map((d, i) => {
                                    return <option value={d.id} key={`gr-${i}`}>{d.id}：{d.name}</option>
                                })}
                            </select>
                            {<ErrorMessage message={errors["measure_category"]}/>}
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="req">対策コメント<br />表示位置コード</label>
                        <div>
                            <select name="measure_comment_position_id" value={measureCommentPositionId} onChange={onChange}>
                                <option value="">---選択してください</option>
                                {MeasureCommentPositions.map((d, i) => {
                                    return <option value={d.id} key={`gr-${i}`}>{d.id}：{d.name}</option>
                                })}
                            </select>
                            {<ErrorMessage message={errors["measure_comment_position_id"]}/>}
                        </div>
                    </div>

                    <div className="form-group">
                        <label>対策コメント_内容</label>
                        <div>
                            <textarea name="measure_comment_text" value={measureCommentText} onChange={onChange} placeholder="例：多様性スコア"/>
                            {<ErrorMessage message={errors["measure_comment_text"]}/>}
                        </div>
                    </div>

                </div>

                <div className="btn-area">
                    <Button color={ButtonColor.Gray} type={"button"} onClick={onClickBack} icon={ButtonIcon.Arrow}>前の画面に戻る</Button>
                    <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>送信する</Button>
                </div>

            </div>

        </StyledPpMstMeatureCommentEdit>

    </Layout>


}

const StyledPpMstMeatureCommentEdit = styled(StyledForm)`

    textarea {
        width: 700px;
        height: 160px;
    }

    input[type=text], select {
        width: 600px;
    }

`;
