import {Layout} from "../common/Layout";
import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../contexts/AppContext";
import {adminPpPetApi, adminPpUserApi} from "../../api/Api";
import {AccesstokenRole, TypesPpPetData, TypesPpUserData} from "../../generated";
import {H1, H3, H4} from "../common/Headline";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {Button, ButtonColor, ButtonIcon, ButtonSize} from "../common/Button";
import {PartsPpUserDetail} from "./PartsPpUserDetail";
import {PartsPetDetail} from "../pp_pet/PartsPetDetail";
import {CompleteMessage} from "../common/CompleteMessage";

export enum PpUserDetailMessageType {
    OwnerEditComplete = "owner_edit_complete",
    PetEditComplete = "pet_edit_complete",
    None = "",
}

export const PpUserDetail = () => {

    const {setShowSpinner, setDangerMessage, setSuccessMessage, role, setCompleteMessage} = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [ppUser, setPpUser] = useState<TypesPpUserData | null>(null);

    useEffect(() => {

        if (params.id) {
            // ユーザーDが指定されている場合は、管理ユーザーを取得する
            getPpUser(params.id);
        }

        const urlParams = new URLSearchParams(location.search);
        const messageType = urlParams.get("message_type") ?? "";

        if (messageType === PpUserDetailMessageType.OwnerEditComplete) {
            setCompleteMessage("オーナー情報を変更いたしました");
        }
        if (messageType === PpUserDetailMessageType.PetEditComplete) {
            setCompleteMessage("ペット情報を変更いたしました");
        }

    }, []);

    const getPpUser = (id: string) => {

        setShowSpinner(true);

        adminPpUserApi.v1AdminPpUserGetIdGet(id)
            .then(({data}) => {
                setPpUser(data.pp_user ?? null);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
                navigate("/pp_user");
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    if (!ppUser) {
        // 会員が取得できるまではダミー表示
        return <Layout title={"会員検索"}/>;
    }

    // 会員編集ボタンクリック時
    const onClickEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/pp_user/edit/${ppUser.id}`);
    }

    // ペットボタンクリック時
    const onClickPet = (e: React.MouseEvent<HTMLButtonElement>, ppPetId: number | null) => {
        e.preventDefault();

        if (!ppPetId) {
            // 新規追加へ
            navigate(`/pp_pet/create/${ppUser.id}`);
        } else {
            // 編集へ
            navigate(`/pp_pet/edit/${ppUser.id}/${ppPetId}`);
        }
    }

    const onDelete = (e: React.MouseEvent<HTMLSpanElement>): void => {

        e.preventDefault();

        if (!window.confirm(`${ppUser.user_family_name} ${ppUser.user_first_name}さん を削除します。付随するデータにもアクセスできなくなります。この作業は取り消せません？`)) {
            return;
        }

        if (!window.confirm(`本当によろしいですか？`)) {
            return;
        }
        setShowSpinner(true);

        adminPpUserApi.v1AdminPpUserDeleteIdDelete(String(ppUser.id))
            .then(() => {
                setSuccessMessage("会員を削除しました。");
                navigate(`/pp_user`);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    // ペットの削除
    const onDeletePet = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, ppPet: TypesPpPetData) => {

        e.preventDefault();

        if ([String(AccesstokenRole.RoleOperator), String(AccesstokenRole.RoleMaster)].indexOf(String(role ?? "")) === -1) {
            setDangerMessage("権限がありません");
            return;
        }

        if (!window.confirm(`${ppPet.pet_name} を削除します。この作業は取り消せません。`)) {
            return;
        }

        if (!window.confirm(`本当によろしいですか？`)) {
            return;
        }

        setShowSpinner(true);

        adminPpPetApi.v1AdminPpPetDeleteIdDelete(String(ppPet.id))
            .then(() => {
                setSuccessMessage(`${ppPet.pet_name} を削除しました。`);
                // 情報を再取得
                getPpUser(String(ppUser.id));
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }

            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    return <Layout title={"会員検索"}>

        <H1 title="会員情報の詳細" subtitle="user data"/>

        <StyledMembersDetail>

            <CompleteMessage/>

            <div className="box">

                <H3 title="オーナー情報" subtitle="owner">
                    {role !== AccesstokenRole.RoleCs && <Button color={ButtonColor.Green} icon={ButtonIcon.Arrow} size={ButtonSize.Small} onClick={(e) => onClickEdit(e)}>オーナー情報を変更</Button>}
                    {[String(AccesstokenRole.RoleOperator), String(AccesstokenRole.RoleMaster)].indexOf(role ?? "") !== -1 && <Button color={ButtonColor.Danger} icon={ButtonIcon.Arrow} size={ButtonSize.Small} onClick={onDelete} className="ml30">会員情報を削除</Button>}
                </H3>

                <PartsPpUserDetail ppUser={ppUser}/>

            </div>

            <H4 title={"登録中のペット情報"}>
                {role !== AccesstokenRole.RoleCs && <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow} size={ButtonSize.Small} onClick={(e) => onClickPet(e, null)}>ペット情報の新規登録</Button>}
            </H4>

            {ppUser.pp_pets?.map((ppPet, i) => {

                return <div className="box mb40" key={`pet-${i}`}>

                    <H3 title={`ペット情報 ${i + 1}`} subtitle="pet">
                        {role !== AccesstokenRole.RoleCs && <Button color={ButtonColor.Green} icon={ButtonIcon.Arrow} size={ButtonSize.Small} onClick={(e) => onClickPet(e, ppPet.id ?? 0)}>ペット情報を変更</Button>}
                        {[String(AccesstokenRole.RoleOperator), String(AccesstokenRole.RoleMaster)].indexOf(role ?? "") !== -1 && <Button color={ButtonColor.Danger} icon={ButtonIcon.Arrow} size={ButtonSize.Small} onClick={(e) => onDeletePet(e, ppPet)} className="ml30">ペット情報を削除</Button>}
                    </H3>

                    <PartsPetDetail ppPet={ppPet}/>

                </div>

            })}


        </StyledMembersDetail>

    </Layout>

}

const StyledMembersDetail = styled(StyledForm)`

  .pet {

    .btn {
      text-align: right;
      margin-bottom: 10px;
    }

    .form-wrapper {
      border: 1px solid #ddd;
    }
  }
`;
