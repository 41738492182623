import {Layout} from "../common/Layout";
import {SearchForm} from "./SearchForm";
import {H3} from "../common/Headline";
import {Table} from "../common/Table";
import {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {adminResultApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";
import {PpTestResultControllerReferenceRequest, TypesReferenceData} from "../../generated";
import {GetFlowcellStatusStr} from "../../utility/Utility";
import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";

export const ReferenceIndex = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const location = useLocation();
    const [references, setReferences] = useState<TypesReferenceData[]>([]);

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        const flowcellId = params.get("flowcell_id") ?? "";
        const sampleId = params.get("sample_id") ?? "";
        const wellplateId = params.get("wellplate_id") ?? "";

        if (flowcellId !== "" || sampleId !== "" || wellplateId !== "") {
            onSearch(flowcellId, sampleId, wellplateId);
        } else {
            // 一覧をクリアする
            setReferences([]);
        }


    }, [location.search]);

    const onSearch = (flowcellId: string, sampleId: string, wellplateId: string): void => {

        console.log(onSearch);

        setShowSpinner(true);

        const req: PpTestResultControllerReferenceRequest = {
            flowcell_id: flowcellId,
            sample_id: sampleId,
            wellplate_id: wellplateId,
        };

        adminResultApi.v1AdminReferencePost(req)
            .then(({data}) => {
                setReferences(data.reference);
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);

            });
    }

    const onClear = (): void => {
        console.log("onClear - 一覧をクリア");
    }

    return <Layout title="試験番号情報照会">

        <SearchForm onClear={onClear}/>

        <StyledReferenceIndex className="box">

            <H3 title="検体情報一覧" subtitle="List"/>

            <Table>

                <thead>
                <tr>
                    <th>No.</th>
                    <th>検体ID</th>
                    <th>Flow Cell ID</th>
                    <th>PCR試験<br/>プレート番号</th>
                    <th>位置</th>
                    <th>Lab<br/>バーコード</th>
                    <th>ペットメタ<br/>データ有無</th>
                    <th>再検査要否</th>
                    <th>Flow Cell<br/>ステータス</th>
                </tr>
                </thead>

                <tbody>

                {references.map((d, i) => {

                    return <tr key={`reference-${i}`} className={!d.metadata_out ? "no-metadata" : ""}>
                        <td>{i + 1}</td>
                        <td>{d.sample_id}</td>
                        <td>{d.flowcell_id}</td>
                        <td>{d.wellplate_id}</td>
                        <td>{d.position}</td>
                        <td>{d.lab_barcode_id}</td>
                        <td>{d.metadata_out ? "あり" : "なし"}</td>
                        <td>{d.re_test && <input type="checkbox" checked={true} disabled={true}/>}</td>
                        <td>{GetFlowcellStatusStr(d.flowcell_status)}</td>
                    </tr>
                })}

                </tbody>

            </Table>

        </StyledReferenceIndex>

    </Layout>

}

const StyledReferenceIndex = styled(StyledForm)`

  table {

    td {
      &:nth-child(8) {
        text-align: center;
      }
    }

    .no-metadata {
      td {
        background-color: #ddd !important;
        
        &:nth-child(7) {
          color: #D93E4C;
        }
      }
    }
  }
  

`;