import {AssetsControllerUploadImageResponse, PpPetControllerCreateRequest, PpUserControllerCreateRequest} from "../../generated";
import {H1, H3} from "../common/Headline";
import {Layout} from "../common/Layout";
import React from "react";
import {StyledMemberCreate} from "./PpUserCreate";
import {CreateJaYmd, GetPersonGender, GetPrefectureName} from "../../utility/Utility";
import {Button, ButtonColor, ButtonSize, ButtonIcon} from "../common/Button";
import {PpPetConfirm} from "../pp_pet/PpPetConfirm";
import {LoginInfo} from "./LoginInfo";
import {RegistrationSteps} from "./RegistrationSteps";

interface Props {
    ppUser: PpUserControllerCreateRequest | null;
    ppPet: PpPetControllerCreateRequest | null;
    petIcon: AssetsControllerUploadImageResponse | null;
    onBack: (e: React.MouseEvent<HTMLButtonElement>, step: number) => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

export const PpUserConfirm = ({ppUser, ppPet, petIcon, onBack, onSubmit}: Props) => {

    if (!ppUser || !ppPet) {
        return null;
    }

    return <Layout title={"会員情報登録"}>

        <H1 title="登録内容のご確認" subtitle="registration"/>

        <RegistrationSteps offset={2}/>

        <StyledMemberCreate onSubmit={onSubmit}>

            <p className="confirmation-note">入力した内容を確認し、お間違いなければ登録へお進みください。</p>

            <LoginInfo email={ppUser.email ?? ""} showReq={true}/>

            <div className="box">

                <H3 title="オーナー情報" subtitle="owner">
                    <Button size={ButtonSize.Small} color={ButtonColor.Green} icon={ButtonIcon.Arrow} type="button" onClick={(e) => onBack(e, 1)}>オーナー情報を修正</Button>
                </H3>

                <div className="form-wrapper">

                    <div className="form-group confirm">
                        <label className="req">お名前</label>
                        <div>
                            姓：{ppUser.user_family_name} &nbsp; &nbsp; 名：{ppUser.user_first_name}
                        </div>
                    </div>

                    <div className="form-group confirm">
                        <label className="req">お名前（フリガナ）</label>
                        <div>
                            姓：{ppUser.user_family_furigana} &nbsp; &nbsp; 名：{ppUser.user_first_furigana}
                        </div>
                    </div>

                    <div className="form-group confirm">
                        <label className="req">生年月日</label>
                        <div>
                            {CreateJaYmd(ppUser.birthday ?? "")}
                        </div>
                    </div>

                    <div className="form-group confirm">
                        <label className="req">性別</label>
                        <div>
                            {GetPersonGender(ppUser.gender ?? "")}
                        </div>
                    </div>

                    <div className="form-group confirm">
                        <label className="req">郵便番号</label>
                        <div>
                            {ppUser.postal_code}
                        </div>
                    </div>


                    <div className="form-group confirm">
                        <label className="req">都道府県</label>
                        <div>
                            {GetPrefectureName(ppUser.pref_code ?? "")}
                        </div>
                    </div>


                    <div className="form-group confirm">
                        <label className="req">市区町村</label>
                        <div>
                            {ppUser.city}
                        </div>
                    </div>

                    <div className="form-group confirm">
                        <label className="req">丁目・番地</label>
                        <div>
                            {ppUser.street}
                        </div>
                    </div>

                    <div className="form-group confirm">
                        <label>建物名</label>
                        <div>
                            {ppUser.building !== "" ? ppUser.building : "-"}
                        </div>
                    </div>

                    <div className="form-group confirm  password-area">
                        <label className="req">電話番号</label>
                        <div>
                            {ppUser.tel_no}
                        </div>
                    </div>

                </div>

            </div>


            <div className="box">
                <H3 title="ペット情報" subtitle="pet">
                    <Button size={ButtonSize.Small} color={ButtonColor.Green} icon={ButtonIcon.Arrow} type="button" onClick={(e) => onBack(e, 2)}>ペット情報を修正</Button>
                </H3>
                <PpPetConfirm ppPet={ppPet} petIcon={petIcon}/>
            </div>

            <div className="btn-area">
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow}>登録する</Button>
            </div>


        </StyledMemberCreate>


    </Layout>

};