import styled from "styled-components";
import {StyledForm} from "../common/StyledForm";
import {H2} from "../common/Headline";
import rightOrange2 from "../../images/right-orange2.svg";
import React, {useContext, useEffect, useState} from "react";
import {AccesstokenRole} from "../../generated";
import {AppContext} from "../../contexts/AppContext";
import {RcmndTitleDisplayLocation, GetIntestinalEnvironmentTypes, GetRecommendTypes} from "../../utility/Utility";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {useRcmndProductTitle} from "../../hooks/useRcmndProductTitle";

export const SearchForm = () => {

    const {role} = useContext(AppContext);
    const [rcmndProductsTitleId, setRcmndProductsTitleId] = useState<string>("");
    const [displayLocation, setDisplayLocation] = useState<string>("");
    const [recommendType, setRecommendType] = useState<string>("");
    const [intestinalEnvironmentType, setIntestinalEnvironmentType] = useState<string>("");
    const [productCode, setProductCode] = useState<string>("");
    const [productName, setProductName] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const rcmndProductsTitles = useRcmndProductTitle();

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        setRcmndProductsTitleId(params.get("rcmnd_products_title_id") ?? "");
        setDisplayLocation(params.get("display_location") ?? "");
        setRecommendType(params.get("recommend_type") ?? "");
        setIntestinalEnvironmentType(params.get("intestinal_environment_type") ?? "");
        setProductCode(params.get("product_code") ?? "");
        setProductName(params.get("product_name") ?? "");

    }, [location.search]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        switch (e.currentTarget.name) {
            case "rcmnd_products_title_id":
                setRcmndProductsTitleId(e.currentTarget.value);
                break;
            case "display_location":
                setDisplayLocation(e.currentTarget.value);
                break;
            case "recommend_type":
                setRecommendType(e.currentTarget.value);
                break;
            case "intestinal_environment_type":
                setIntestinalEnvironmentType(e.currentTarget.value);
                break;
            case "product_code":
                setProductCode(e.currentTarget.value);
                break;
            case "product_name":
                setProductName(e.currentTarget.value);
                break;
        }
    }

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowError(false);
        navigate(`/pp_mst_rcmnd_product`);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const params = new URLSearchParams();
        params.append("rcmnd_products_title_id", rcmndProductsTitleId);
        params.append("display_location", displayLocation);
        params.append("recommend_type", recommendType);
        params.append("intestinal_environment_type", intestinalEnvironmentType);
        params.append("product_code", productCode);
        params.append("product_name", productName);
        params.append("page", "1");

        if (rcmndProductsTitleId === "" && displayLocation === "" && recommendType === "" && intestinalEnvironmentType === "" && productCode === "" && productName === "") {
            // 未入力で検索した場合
            setShowError(true);
            return;
        }

        setShowError(false);

        navigate(`/pp_mst_rcmnd_product?${params.toString()}`);
    }

    const onClickCreate = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/pp_mst_rcmnd_product/create`);
    };

    // レコメンド要否区分
    const recommendTypes = GetRecommendTypes();
    const recommendTypeKeys = Object.keys(recommendTypes);

    // 腸内環境タイプ
    const intestinalEnvironmentTypes = GetIntestinalEnvironmentTypes();
    const intestinalEnvironmentTypeKeys = Object.keys(intestinalEnvironmentTypes);

    return <StyledSearchForm onSubmit={onSubmit}>

        <div className="box">

            <H2 title="情報を検索する" subtitle="input"/>

            <div className="row">

                <div className="left">

                    <h5>情報を検索する</h5>

                    <div className={showError ? "search-form error" : "search-form"}>

                        {showError && <span className="error">検索内容を入力してください</span>}

                        <div className="flex">

                            <div className="form-group">
                                <label>リコメンド商品<br/>タイトルID</label>
                                <select name="rcmnd_products_title_id" value={rcmndProductsTitleId} onChange={onChange}>
                                    <option value="">---選択してください</option>
                                    {rcmndProductsTitles.map((d, i) => {
                                        return <option value={d.id} key={`rpt-${i}`}>{d.id}：{d.title}</option>
                                    })}
                                </select>
                            </div>

                            <div className="form-group">
                                <label className="pt3">リコメンドタイトル<br />表示箇所</label>
                                <select name="display_location" value={displayLocation} onChange={onChange}>
                                    <option value="">---選択してください</option>
                                    {RcmndTitleDisplayLocation.map((d, i) => {
                                        return <option value={d.id} key={`dft-${i}`}>{d.id}：{d.name}</option>
                                    })}
                                </select>
                            </div>

                        </div>

                        <div className="flex">
                            <div className="form-group">
                                <label>リコメンド要否区分</label>
                                <select name="recommend_type" value={recommendType} onChange={onChange}>
                                    <option value="">---選択してください</option>
                                    {recommendTypeKeys.map((key, i) => {
                                        return <option value={key} key={`rt-${i}`}>{key}：{recommendTypes[key]}</option>
                                    })}
                                </select>
                            </div>

                            <div className="form-group">
                                <label>腸内環境タイプ</label>
                                <select name="intestinal_environment_type" value={intestinalEnvironmentType} onChange={onChange}>
                                    <option value="">---選択してください</option>
                                    {intestinalEnvironmentTypeKeys.map((key, i) => {
                                        return <option value={key} key={`iet-${i}`}>{key}：{intestinalEnvironmentTypes[key]}</option>
                                    })}
                                </select>
                            </div>

                        </div>

                        <div className="flex">
                            <div className="form-group">
                                <label>商品コード</label>
                                <div>
                                    <input type="text" name="product_code" placeholder="例：DP001" value={productCode} onChange={onChange}/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>商品名</label>
                                <div>
                                    <input type="text" name="product_name" placeholder="例：Pミール犬用" value={productName} onChange={onChange}/>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                {role === AccesstokenRole.RoleMaster && <>
                    <div className="right">

                        <h5>情報を新規登録する</h5>

                        <div>
                            <div className="btn-area">
                                <Button color={ButtonColor.Orange} type="button" icon={ButtonIcon.Arrow} onClick={onClickCreate}>新規登録</Button>
                            </div>
                        </div>

                    </div>
                </>}

            </div>

            <div className="btn-area mt40">
                <Button color={ButtonColor.Gray} type="button" icon={ButtonIcon.Arrow} onClick={onClickClear}>クリア</Button>
                <Button color={ButtonColor.Orange} type="submit" icon={ButtonIcon.Arrow}>検索</Button>
            </div>

        </div>


    </StyledSearchForm>

}

const StyledSearchForm = styled(StyledForm)`

    .row {
        display: flex;
        justify-content: space-between;

        h5 {
            background-color: #EFEFEF;
            font-weight: 400;
            line-height: 36px;
            font-size: 14px;
            padding: 0 10px 0 30px;
            background-image: url(${rightOrange2});
            background-repeat: no-repeat;
            background-position: left 15px top 50%;
        }

        .left {
            flex: 1;
            margin-right: 23px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            overflow: hidden;
            border: 1px solid #EFEFEF;

            .search-form {
                padding: 22px;

                &.error {
                    position: relative;

                    input, select {
                        background-color: #F7D7DA;
                    }
                }

                .error {
                    background-color: #D93E4C;
                    color: #fff;
                    line-height: 47px;
                    display: block;
                    position: absolute;
                    top: 25px;
                    left: 450px;
                    padding: 0 16px;
                    border-radius: 4px;

                    &:before {
                        position: absolute;
                        border: 8px solid transparent;
                        border-right: 8px solid #D93E4C;
                        top: 50%;
                        left: -16px;
                        transform: translateY(-50%);
                        content: "";
                        display: block;
                    }
                }

            }


            .form-group {
                padding: 6px 0;
                margin-bottom: 0;
            }

            .flex {
                display: flex;

                .form-group {
                    width: 50%;
                }
            }

            label {
                width: 150px;
                margin-right: 0;
            }

            input, select {
                width: 240px !important;
            }

        }

        .right {
            width: 338px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            overflow: hidden;
            border: 1px solid #EFEFEF;

            .btn-area {
                margin-top: 80px;

                button {
                    width: 240px;
                }
            }
        }

    }

`