import {Layout} from "../common/Layout";
import {SearchForm} from "./SearchForm";
import {useLocation} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {CheckSampleId} from "../../utility/Utility";
import {adminPpTestKitApi} from "../../api/Api";
import {AppContext} from "../../contexts/AppContext";
import {EntPpTestKit, EntPpTestResult, EntPpTestStatus} from "../../generated";
import {H3} from "../common/Headline";

export const DebugIndex = () => {

    const {setShowSpinner, setDangerMessage} = useContext(AppContext);
    const location = useLocation();
    const [sampleId, setSampleId] = useState<string>("");
    const [ppTestKits, setPpTestKits] = useState<EntPpTestKit[]>([]);
    const [ppTestStatuses, setPpTestStatuses] = useState<EntPpTestStatus[]>([]);
    const [ppTestResults, setPpTestResults] = useState<EntPpTestResult[]>([]);

    useEffect(() => {

        const params = new URLSearchParams(location.search);
        const sampleId = params.get("sample_id") ?? "";

        if (sampleId !== "" && CheckSampleId(sampleId)) {
            setSampleId(sampleId);
        } else {
            setSampleId("");
        }

    }, [location.search]);

    useEffect(() => {

        if (sampleId !== "") {
            getDebugData();
        }

    }, [sampleId]);

    const getDebugData = () => {

        setShowSpinner(true);

        adminPpTestKitApi.v1AdminPpTestKitDebugPost({sample_id: sampleId})
            .then(({data}) => {

                console.log(data.pp_test_statuses);

                setPpTestKits(data.pp_test_kits);
                setPpTestStatuses(data.pp_test_statuses);
                setPpTestResults(data.pp_test_results);

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setDangerMessage(err.response.data.message);
                } else {
                    setDangerMessage("通信時にエラーが発生しました");
                }
            })
            .finally(() => {
                setShowSpinner(false);
            });
    };

    if (sampleId === "") {
        // 検索前
        return <Layout title="検体IDデバッグ">
            <SearchForm/>
        </Layout>
    }

    return <Layout title="検体IDデバッグ">

        <SearchForm/>

        {ppTestKits.length === 0 && <div className="box">
            <H3 title="管理明細"/>
            管理明細は登録されていません。
        </div>}

        {ppTestKits.length > 0 && ppTestKits.map((d, i) => {
            return <div className="box" key={`kit-${i}`}>
                <H3 title={`管理明細${i + 1}`}/>
                <pre>
                    {JSON.stringify(d, null, 4)}
                </pre>
            </div>
        })}

        {ppTestStatuses.length === 0 && <div className="box">
            <H3 title="検査状況"/>
            検査状況は登録されていません。
        </div>}

        {ppTestStatuses.length > 0 && ppTestStatuses.map((d, i) => {
            return <div className="box" key={`status-${i}`}>
                <H3 title={`検査状況${i + 1}`}/>
                <pre>
                    {JSON.stringify(d, null, 4)}
                </pre>
            </div>
        })}

        {ppTestResults.length === 0 && <div className="box">
            <H3 title="検査結果"/>
            検査結果は登録されていません。
        </div>}

        {ppTestResults.length > 0 && ppTestResults.map((d, i) => {
            return <div className="box" key={`result-${i}`}>
                <H3 title={`検査結果${i + 1}`}/>
                <pre>
                    {JSON.stringify(d, null, 4)}
                </pre>
            </div>
        })}

    </Layout>

}