import styled from "styled-components";
import {MainColor} from "./Colors";

interface Props {
    total: number;
    size: number;
    current: number;
}

export const Counter = ({total, size, current}: Props) => {

    let start = size * (current - 1) + 1;
    if (total === 0) {
        // 0の場合は開始を0にする
        start = 0;
    }

    return <StyledCounter className="counter">
        <span><strong>{start}〜{size * current > total ? total : size * current}</strong>件</span>
        <span className="border"/>
        <span>全<strong>{total}</strong>件</span>
    </StyledCounter>

};

const StyledCounter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  height: 27px;
  position: relative;

  span {
    margin: 0;

    &:first-child {
      background-color: #EFEFEF;
      font-size: 12px;
      line-height: 27px;
      border-top-left-radius: 27px;
      border-bottom-left-radius: 27px;
      padding: 0 15px;
      color: #333;

      strong {
        color: #333;
        display: inline-block;
        font-size: 12px;
        padding: 0 5px 0 0;
        font-weight: 500;
        line-height: 27px;
      }
    }

    &.border {
      display: block;
      height: 60px;
      width: 1px;
      background-color: transparent;
      border-left: 3px solid #fff;
      border-right: 19px solid ${MainColor};
      transform: rotate(30deg);
      position: relative;
      left: -7px;
      z-index: 1;
    }

    &:last-child {
      background-color: ${MainColor};
      font-size: 12px;
      color: #fff;
      padding: 0 15px 0 0;
      line-height: 27px;
      border-top-right-radius: 27px;
      border-bottom-right-radius: 27px;
      position: relative;
      left: -14px;
      top: 0;
      z-index: 1;

      strong {
        display: inline-block;
        font-size: 12px;
        padding: 0 5px;
        color: #fff;
        line-height: 27px;
        font-weight: 500;
      }
    }

  }

  padding: 5px;
  font-size: 14px;
`;