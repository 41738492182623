import {Layout} from "../common/Layout";
import {H2} from "../common/Headline";
import {Button, ButtonColor, ButtonIcon} from "../common/Button";
import styled from "styled-components";
import React from "react";
import {useNavigate} from "react-router-dom";

export const ReceptionIndex = () => {

    const navigate = useNavigate();

    const onClickButton = (e: React.MouseEvent<HTMLButtonElement>, type: string) => {
        e.preventDefault();
        switch (type) {
            case "new":
                navigate("/reception/new");
                break;
            case "add":
                navigate("/reception/add");
                break;
        }
    }

    return <Layout title="検査受付">

        <StyledReceptionIndex className="box">

            <H2 title="受付方法の選択" subtitle="Select"/>

            <div className="buttons">
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow} type="button" onClick={(e) => onClickButton(e, "new")}>通常受付</Button>
                <Button color={ButtonColor.Orange} icon={ButtonIcon.Arrow} type="button" onClick={(e) => onClickButton(e, "add")}>追加受付</Button>
            </div>

        </StyledReceptionIndex>

    </Layout>
}

const StyledReceptionIndex = styled.div`

  .buttons {
    display: flex;
    justify-content: center;

    padding: 20px 0;

    button {
      margin: 0 65px 0 0;
      width: 240px;

      &:last-child {
        margin-right: 0;
      }
    }

  }


`;
