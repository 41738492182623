import React from "react";
import {PpMstPartnerInstModelGetListResponse, PpMstUserMedicalModelGetListResponse} from "../../../generated";
import Select from "react-select";
import styled from "styled-components";
import {FilterOptionOption} from "react-select/dist/declarations/src/filters";

interface Props {
    name: string;
    sampleId: string;
    onChange: (type: string, sampleId: string, n: number | null) => void;
    value: number | null;
    listData: PpMstUserMedicalModelGetListResponse[] | PpMstPartnerInstModelGetListResponse[];
    changePlaceholderColor: boolean;
}

export const InstInput = ({name, onChange, value, listData, sampleId, changePlaceholderColor}: Props) => {

    if (listData.length === 0) {
        // リスト取得前はなにもしない
        return null;
    }

    const onUpdate = (newValue: any) => {
        onChange(name, sampleId, newValue ? newValue.value : null);
    };

    // 前方一致フィルター
    const customFilter = (option: FilterOptionOption<any>, inputValue: string): boolean => {
        // 大文字小文字は無視で前方一致とする
        return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) === 0;
    };

    let currentValue = {
        label: "",
        value: 0,
    };

    const options: any = listData.map((datum) => {

        if (value === datum.id) {
            currentValue = {
                label: datum.name ?? "",
                value: datum.id,
            };
        }

        return {
            label: datum.name,
            value: datum.id,
        };
    });


    let placeholder: JSX.Element = <div className="default-placeholder-color">未設定</div>;

    if(changePlaceholderColor) {
        placeholder = <div className="change-placeholder-color">未設定</div>;
    }


    return <StyledInput>
        {(value !== null && value !== 0) && <Select  isClearable={true} onChange={onUpdate} defaultValue={currentValue} options={options} placeholder={placeholder}/>}
        {!value && <Select isClearable={true} onChange={onUpdate} options={options} filterOption={customFilter} placeholder={placeholder}/>}
    </StyledInput>
};

const StyledInput = styled.div`
  width: 300px;

  input[type=text] {
    height: 30px !important;
  }

  * {
    font-size: 14px !important;
  }

  .default-placeholder-color {
    color: #888;
  }

  .change-placeholder-color {
    color: red;
  }
`;